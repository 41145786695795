import * as React from 'react';
import { Submittal, SubmittalDocument, SubmittalDocumentStatus } from '../interfaces/interfaces';
import { formatDateTime } from '../helpers/formatters';
import { isImage } from '../helpers/misc';

interface Props { submittal: Submittal }

export default class AcceptedSubmittalDocBox extends React.Component<Props, any> {

    render() {
        const { submittal } = this.props;
        const docs = submittal.responses ? submittal.responses.map(x => x.documents).reduce((x, acc) => acc.concat(x), []).filter(x => x.status === SubmittalDocumentStatus.Approved) : [] //.filter(x => x.status === SubmittalDocumentStatus.Approved) : [];
    
        if (docs.length == 0) return <div></div>

        const docEles = docs.map((x: SubmittalDocument) => {            
            const slashSplit = x.filePath.split('/'), fileName = slashSplit[slashSplit.length - 1],
                src = isImage(x.filePath) ? '/img/photoIcon.jpg' : '/img/pdficon.png'
            return (
                <div key={x.id} className='accepted-submittal-doc'>
                    <a href={x.filePath} target="_blank" title={fileName}>
                        <img style={{ height: '30px' }} src={src} /> <br />
                    </a>
                    <div className='truncate' title={fileName}>{fileName}</div>
                    <div>{x.userFullName} </div>
                    <span>{formatDateTime(x.timestamp)}</span>
                </div>
            )
        })

        return (
            <div className='accepted-doc-section'>
                <h5>Final-Approved Documents</h5>
                <hr />
                <div className='docs'>
                    {docEles}
                </div>
            </div>
        )
    }
}