import {
  createListenerMiddleware,
  TypedStartListening,
  addListener,
  TypedAddListener,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src';
import { addGraphDataListeners } from './graphData/graphData.listeners';
import { addProcessingNotificationListeners } from './processingNotifications/';
import { addPunchlistListeners } from './punchlist';
import { addKeyVaultListeners } from './keyvault';
import { addSafetyKPIListeners } from './safetyKPI/safetyKPI.listeners';
import { addWindowsListeners } from './window/window.listeners';
import { addCustomReportListeners } from './customReport';
import { addDFRListeners } from './dfr/dfr.listeners';
import { addVendorsListeners } from './vendors/vendors.listeners';
import { addIssuesListeners } from './issues';
import { addNCRListeners } from './ncrs';
import { addRFQListeners } from './rfqs/rfqs.listeners'; 

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  RootState,
  AppDispatch
>;

addProcessingNotificationListeners(startAppListening);
addGraphDataListeners(startAppListening);
addKeyVaultListeners(startAppListening);
addPunchlistListeners(startAppListening);
addSafetyKPIListeners(startAppListening);
addVendorsListeners(startAppListening);
addWindowsListeners(startAppListening);
addCustomReportListeners(startAppListening);
addDFRListeners(startAppListening);
addIssuesListeners(startAppListening);
addNCRListeners(startAppListening);
addRFQListeners(startAppListening);
