import * as React from 'react';

import { Modal2 } from '../components/Modal';
import { RFIDetailVm } from '../interfaces/interfaces';

interface Props {
    rfi: RFIDetailVm,
    onSuccess: () => void
}

export const ReissueComponent = (props: Props) => {
    const [showModal, setShowModal] = React.useState(false);

    const _save = () => {
        const reason = (document.getElementById('reissue-reason') as HTMLTextAreaElement).value,
            files = (document.getElementById('reissue-files') as HTMLInputElement).files,
            copyFiles = (document.getElementById('copy-original-docs') as HTMLInputElement).checked;

        if (reason.length < 20) alert('ALERT: You must provide a reason of at least twenty characters');
        else {
            const form = new FormData()
            if (files && files.length > 0) {
                for (let i = 0; i < files.length; ++i) //form.append(`doc${i}`, files[i], files[i].name)
                    form.append('files', files[i], files[i].name)
            }
            form.append("json", JSON.stringify({ id: props.rfi.id, projectId: props.rfi.projectId, reason: reason, copyOriginalDocs: copyFiles }));
            fetch(`api/rfi/requestreissue`,
                {
                    method: 'POST',
                    body: form
                })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setShowModal(false);
                        props.onSuccess()
                    }
                    else alert(data.message);
                })
        }
    }

    const RenderModal = () => (
        <Modal2 style={{ marginTop: '10vh' }}>
            <div className='modal-header'><h5>Reissue {props.rfi.rfiNumber}</h5></div>
            <div className='modal-body'>
                <p><small>NOTE: RFI's can only be reissued once. Reissuing this RFI will mark it as reissued and no longer approved. The reissue request must be approved by the project's listed Project Manager before the new RFI record is created.</small></p>
                <div className='form-group'>
                    <div className='inline-label'>Reason for reissue*</div>
                    <textarea id='reissue-reason' className='form-control' />
                </div>
                <div className='form-group'>
                    <div className='inline-label'>Copy original documents</div>
                    <input type='checkbox' id='copy-original-docs' />
                </div>
                <input type='file' id='reissue-files' multiple accept=".pdf,.jpg,.jpeg,.png,.bmp,.jiff" />
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-green' onClick={_save}>Submit</button>
                <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowModal(false)}>Close</button>
            </div>
        </Modal2>
    )

    return (
        <span className='rfi-reissue-component'>
            <span className='btn btn-sm btn-background-hover fas fa-copy' onClick={() => { setShowModal(true); }} title='Reissue this RFI'></span>
            {showModal ? RenderModal() : []}                
        </span>
    )
}