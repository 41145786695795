import * as React from "react";
import { ListItem } from "../interfaces/interfaces";
import { debounce } from "../helpers/misc";
import DataListInput, { Item } from "react-datalist-input";
import $ from "jquery";
import * as cx from "classnames";

interface Props {
  id: string;
  items: ListItem[];
  onChange?: (id: string) => void;
  onBlur?: (id: string) => void;
  defaultVal?: string | number;
  openField?: boolean; // Indicates whether an update is sent back without a dropdown item being selected
  readonly?: boolean;
  allowEmpty?: boolean;
  growUpwards?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
}

export default class FilterableSelect extends React.Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      hiddenVal: this.props.defaultVal,
      displayText:
        this._getTextForVal(this.props.items, this.props.defaultVal) ||
        this.props.defaultVal,
      debouncedChange: this.props.onChange ? debounce(this.props.onChange.bind(this), 1000) : [],
      debouncedBlur: this.props.onBlur ? debounce(this.props.onBlur.bind(this), 500) : []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.items != nextProps.items || this.props.defaultVal !== nextProps.defaultVal) {
      this.setState({
        displayText:
          this._getTextForVal(nextProps.items, nextProps.defaultVal) ||
          nextProps.defaultVal,
      });
    }
  }

  _getTextForVal = (items: ListItem[], val?: string | number) => {
    // if default val is provided we need the item so we can display it's text
    const item = items ? items.find((x) => x.id === val) : ({} as ListItem);
    if (val == "1528") {
      for (let i = 0; i < items.length; ++i) {
        if (i === 1) {
          console.log(items[i].id + " " + items[i].value);
          console.log(val);
        }
      }
    }
    if (item) return item.value;
    // If we don't find a matched item return undefined so caller can coalesce with fallback value
    return undefined;
  };

  _customFilter = (options, value) => {
    return value
      ? options.filter((o: Item) =>
        o.value?.toLowerCase().includes(value.toLowerCase())
      )
      : options;
  };

  _onInput = (e: React.FormEvent<HTMLDivElement>) => {
    const val =
      typeof e === "string" ? e : e.currentTarget.querySelector("input")!.value;

    this._change(val, false);

    // set focus
    $(
      `#${this.props.id} input[class="autocomplete-input form-control"]`
    ).focus();
  };

  _select = (item: Item) => {
    this._change(item.id, true);
  };

  _change = (id: string, selectInitiated: boolean) => {
    //const input = e;
    const hidden = document.querySelector(
      `input[name="${this.props.id}"]`
    ) as HTMLInputElement;
    // Get the typed or selected value from the displayed input
    const val = id.trim();
    //const displayVal = typeof input === 'string' ? input : input.label;

    // See if the entered/displayed value can be mapped to one of our options
    const selectedItem = selectInitiated ? this.props.items.find((x) => {
      if (x.id === null || x.id === "") return val === "";
      return x.id.toString() === val;
    }) :
      this.props.items.find((x) => {
        if (x.value === null || x.value === "") return val === "";
        return x.value === val
      });

    hidden.value = val;

    this.setState({
      hiddenVal: hidden.value,
      displayText: selectedItem ? selectedItem.value : this.state.displayText,
    });

    /* We only send the update back if a specific item was selected 
            or the caller indicated that the field can be open text */
    if ((selectInitiated || selectedItem) && this.props.onChange && (selectedItem !== undefined || (val === "" && this.props.allowEmpty))) {
      this.props.onChange(val);
    } else if (this.props.openField && this.state.debouncedChange) {
      /* We're going to want to debounce it at this point */
      this.state.debouncedBlur(val);
    }

    //if (typeof input !== 'string') {
    //    $('input[class="autocomplete-input form-control"]').focus()
    //}
  };

  render() {
    const { items, id, onBlur, defaultVal, required } = this.props;
    const { displayText, hiddenVal } = this.state;
    const listName = id + "_list";
    const defaultValue = displayText !== null ? displayText : "";
    const itemList = items.filter(
      (item) => item.id !== null && item.value !== null
    );
    const opts =
      itemList && itemList.length
        ? itemList.map((x) => ({
          id: x.id.toString(),
          value: x.value,
          label: x.value,
        }))
        : [];
    const handleBlur = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (
        e.relatedTarget &&
        e.relatedTarget.parentElement.className ==
        "datalist-items default-datalist-items"
      ) {
        //no-op
      } else {
        $(`input[name="${e.currentTarget.id}"]`).focus();
      }
    };

    return (
      <div
        id={id}
        tabIndex={-1}
        onBlur={(e) => handleBlur(e)}
        className={cx(
          "filterable-select",
          this.props.growUpwards && "grow-upwards"
        )}
        key={this.props.openField ? 'dont-rerender' : defaultVal}
        style={this.props.style}
      >
        <DataListInput
					/*(FROM V2) suppressReselect={false}*/ label=""
          showLabel={false}
          value={defaultValue}
          onInput={this._onInput}
          onSelect={this._select}
          items={opts}
          filters={[this._customFilter]}
          inputProps={{ className: "form-control", required: required }}
        />
        <input
          type="hidden"
          id={listName}
					/*style={{ width: 0, overflow: 'hidden', height: 0, opacity: 0, padding: 0, border: 0 }}*/ readOnly
          unselectable={"on"}
          onBlur={(e) => e.stopPropagation()}
					/*onFocus={onBlur}*/ name={id}
          defaultValue={hiddenVal}
        />
      </div>
    );
  }
}
