import { AxiosRequestConfig, AxiosResponse } from "axios";
import { config } from "process";
import {
	ChecklistConditionType,
	ChecklistDetail,
	ChecklistDetailVm,
	ChecklistFunction,
	ChecklistTemplateSection,
	ListItem,
	UpdateObject,
} from "src/interfaces/interfaces";
import { api } from "../api";

export const addSection = async (section: ChecklistTemplateSection) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.post(
			`api/ChecklistConfig/AddTemplateSection?templateId=${section.checklistTemplateId}`,
			section
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const removeSection = async (templateId: number, sectionId: number) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.delete(
			`api/ChecklistConfig/DeleteSection?templateId=${templateId}&id=${sectionId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const editSection = async (
	templateId: number,
	sectionId: number,
	update: UpdateObject
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.put(
			`api/ChecklistConfig/EditSection?templateId=${templateId}&id=${sectionId}`,
			update
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const canEditChecklist = async (listId: number) => {
	try {
		const response: AxiosResponse<{
			result: boolean;
			checklist: ChecklistDetailVm;
		}> = await api.get(`api/ChecklistConfig/CanEditList?listId=${listId}`);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const getChecklistFunctions = async () => {
	try {
		const response: AxiosResponse<ListItem[]> = await api.get(
			`api/ChecklistConfig/GetFunctions`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const addFunction = async (type: string) => {
	try {
		const response: AxiosResponse<ListItem[]> = await api.get(
			`api/ChecklistConfig/AddFunction?type=${type}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const editChecklistFunction = async (
	listId: number,
	functionId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.put(
			`api/ChecklistConfig/EditChecklistFunction?listId=${listId}&functionId=${functionId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const updateChecklistHeaderSectionAssignee = async (
	headerId: number,
	sectionId: number,
	userId: string,
	controllerType: string | undefined
) => {
	try {
		const response: AxiosResponse<ChecklistDetail> = await api.put(
			`api/${controllerType}/UpdateSectionAssignee?headerId=${headerId}&sectionId=${sectionId}&userId=${userId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const updateAllSectionAssignee = async (
	headerId: number,
	userId: string,
	controllerType: string | undefined
) => {
	try {
		const response: AxiosResponse<ChecklistDetail> = await api.put(
			`api/${controllerType}/UpdateAllSectionAssignee?headerId=${headerId}&userId=${userId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const inheritChecklistTemplate = async (
	parentTemplateIds: number[],
	childTemplateId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.post(
			`api/ChecklistConfig/InheritTemplate?childTemplateId=${childTemplateId}`,
			parentTemplateIds
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const deinheritChecklistTemplate = async (
	parentTemplateIds: number[],
	childTemplateId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.post(
			`api/ChecklistConfig/DeinheritTemplate?childTemplateId=${childTemplateId}`,
			parentTemplateIds
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const copyChecklistTemplate = async (templateId: number) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.get(
			`api/ChecklistConfig/CopyChecklistTemplate?templateId=${templateId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const addFieldOption = async (
	fieldId: number,
	sectionId: number,
	templateId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.post(
			`api/ChecklistConfig/AddFieldOption?fieldId=${fieldId}&sectionId=${sectionId}&templateId=${templateId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const updateFieldOption = async (
	fieldOptionId: number,
	sectionId: number,
	templateId: number,
	text: string
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.put(
			`api/ChecklistConfig/EditChecklistFieldOption?fieldOptionId=${fieldOptionId}&sectionId=${sectionId}&templateId=${templateId}`,
			{
				fieldName: "text",
				value: text,
			}
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const removeFieldOption = async (
	fieldOptionId: number,
	templateId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.delete(
			`api/ChecklistConfig/DeleteFieldOption?fieldOptionId=${fieldOptionId}&templateId=${templateId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const addFieldCondition = async (
	fieldId: number,
	sectionId: number,
	templateId: number,
	value: string,
	type: ChecklistConditionType
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.post(
			`api/ChecklistConfig/AddFieldCondition?fieldId=${fieldId}&sectionId=${sectionId}&templateId=${templateId}&type=${type}`,
			{
				fieldName: "text",
				value: value,
			}
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const updateFieldCondition = async (
	fieldConditionId: number,
	sectionId: number,
	templateId: number,
	value: string,
	type: ChecklistConditionType
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.put(
			`api/ChecklistConfig/EditFieldCondition?fieldConditionId=${fieldConditionId}&sectionId=${sectionId}&templateId=${templateId}&type=${type}`,
			{
				fieldName: "text",
				value: value,
			}
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};

export const removeFieldCondition = async (
	fieldConditionId: number,
	templateId: number
) => {
	try {
		const response: AxiosResponse<ChecklistDetailVm> = await api.delete(
			`api/ChecklistConfig/DeleteFieldCondition?fieldConditionId=${fieldConditionId}&templateId=${templateId}`
		);

		if (response.status == 200) {
			return response.data;
		}
	} catch {
		return false;
	}

	return false;
};
