//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from "redux";
import { AppThunkAction } from "./";
import { ProjectVm, MilestoneEntryVm } from "../interfaces/interfaces";

export interface Store {
	milestones: MilestoneEntryVm[];
	loading: boolean;
	saving: boolean;
	message?: string;
}

interface RequestMilestonesAction {
	type: "REQUEST_MILESTONES";
}

interface ReceiveMilestonesAction {
	type: "RECEIVE_MILESTONES";
	milestones: MilestoneEntryVm[];
}

interface AddMilestoneAction {
	type: "ADD_MILESTONE_DET";
	milestones: MilestoneEntryVm[];
}

interface EditEntryAction {
	type: "EDIT_ENTRY_DET";
	milestones: MilestoneEntryVm[];
}

interface SavingAction {
	type: "SAVING_DET";
}

interface ErrorAction {
	type: "ERROR_DET";
	message: string;
}

interface DetailSaveAction {
	type: "DETAIL_SAVED";
	message: string;
}

type KnownAction =
	| RequestMilestonesAction
	| ReceiveMilestonesAction
	| AddMilestoneAction
	| EditEntryAction
	| SavingAction
	| DetailSaveAction
	| ErrorAction;

export const actionCreators = {
	get:
		(projectId: number | undefined): AppThunkAction<KnownAction> =>
		(dispatch) => {
			if (projectId) {
				fetch(`api/Milestone/ForProject?id=${projectId}`)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							dispatch({ type: "RECEIVE_MILESTONES", milestones: data });
						} else dispatch({ type: "ERROR_DET", message: data.message });
					});
			} else {
				fetch(`api/Milestone/ForUser`)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							dispatch({ type: "RECEIVE_MILESTONES", milestones: data });
						} else dispatch({ type: "ERROR_DET", message: data.message });
					});
			}
			dispatch({ type: "REQUEST_MILESTONES" });
		},
	addEntryDet:
		(projectId: number, milestoneId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/AddEntry?projectId=${projectId}&milestoneId=${milestoneId}`,
				{
					method: "POST",
				}
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const newMs = getState().msList.milestones.slice();
						newMs.push(data);
						dispatch({ type: "EDIT_ENTRY_DET", milestones: newMs });
					} else {
						dispatch({ type: "ERROR_DET", message: data.message });
					}
				});
		},
	updateEntryDet:
		(
			entryId: number,
			field: string,
			value: string | boolean | number,
			projectId: number | undefined
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Milestone/UpdateEntry?entryId=${entryId}`, {
				method: "PUT",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					fieldName: field,
					value: value,
				}),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						// @ts-ignore
						dispatch(actionCreators.get(projectId));
						//const newMs = getState().msList.milestones.slice().map(m => {
						//    if (m.entryId === entryId) {
						//        m = data
						//    }
						//    return m;
						//})
						////newMs.push(data)
						//dispatch({ type: 'EDIT_ENTRY_DET', milestones: newMs });
					} else {
						dispatch({ type: "ERROR_DET", message: data.message });
					}
				});
			dispatch({ type: "SAVING_DET" });
		},
	uploadMsFiles:
		(mId, entryId, form: FormData): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/Upload?pid=${
					getState().detail.detail.id
				}&mId=${mId}&entryId=${entryId}`,
				{ method: "POST", body: form }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({ type: "DETAIL_SAVED", message: "File(s) Uploaded" });
					else dispatch({ type: "ERROR_DET", message: data.message });
				});
		},
	deleteEntryDet:
		(pId: number, entryId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Milestone/DeleteEntry?projectId=${pId}&entryId=${entryId}`, {
				method: "DELETE",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						dispatch({ type: "EDIT_ENTRY_DET", milestones: data });
					} else {
						dispatch({ type: "ERROR_DET", message: data.message });
					}
				});
		},
	addDependency:
		(
			projectId: number,
			entryId: number,
			dependencyId: number
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/Dependency?projectId=${projectId}&entryId=${entryId}&dependencyId=${dependencyId}`,
				{ method: "POST" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						dispatch({ type: "EDIT_ENTRY_DET", milestones: data });
					} else {
						dispatch({ type: "ERROR_DET", message: data.message });
					}
				});
		},
	removeDependency:
		(
			projectId: number,
			entryId: number,
			dependencyId: number
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/RemoveDependency?projectId=${projectId}&entryId=${entryId}&dependencyId=${dependencyId}`,
				{ method: "DELETE" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						dispatch({ type: "EDIT_ENTRY_DET", milestones: data });
					} else {
						dispatch({ type: "ERROR_DET", message: data.message });
					}
				});
		},
	clear: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "RECEIVE_MILESTONES", milestones: [] });
	},
	clearMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
		dispatch({
			type: "RECEIVE_MILESTONES",
			milestones: getState().msList.milestones.slice(),
		});
	},
};

const unloadedState: Store = {
	milestones: [],
	loading: true,
	saving: false,
};

//@ts-ignore
export const reducer: Reducer<Store> = (
	state: Store,
	incomingAction: Action
) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "REQUEST_MILESTONES":
			return { ...state, loading: !state.saving };
		case "RECEIVE_MILESTONES":
			return {
				...state,
				milestones: action.milestones,
				loading: false,
				saving: false,
				message: undefined,
			};
		case "ADD_MILESTONE_DET":
			return { ...state, milestones: action.milestones, saving: false };
		case "EDIT_ENTRY_DET":
			return {
				...state,
				milestones: action.milestones,
				saving: false,
				message: "Saved",
			};
		case "SAVING_DET":
			return { ...state, saving: true, message: undefined };
		case "ERROR_DET":
			return {
				...state,
				saving: false,
				loading: false,
				message: action.message,
			};
		case "DETAIL_SAVED":
			return { ...state, message: action.message, saving: false };
		default: {
			const exhaustiveCheck: never = action;
		}
	}
	return state || unloadedState;
};
