import * as React from 'react';
import { JoinedList } from '../components/JoinedList';
import { ListItem, MarketSector } from '../interfaces/interfaces';

export const VendorMarkets = (props: { vendorId?: number, markets?: MarketSector[] }) => {
  const [markets, setMarkets] = React.useState([] as MarketSector[]);
  const [editing, setEditing] = React.useState(false);
  const [allMarkets, setAllMarkets] = React.useState([] as MarketSector[]);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setMarkets(props.markets || []);
  }, [props.markets]);

  React.useEffect(() => {
    const getTrades = async () => {
      fetch(`api/GlobalConfig/Markets`)
        .then(res => res.json())
        .then(data => {
          setAllMarkets(data);
          setLoaded(true);
        });
    };
    getTrades();
  }, [])

  const unselectedMarkets = allMarkets.filter(x => markets.findIndex(y => y.id === x.id) === -1);
  const adding = props.vendorId === undefined;

  const _changeTrades = (items: ListItem[]) => {
    let url = 'api/Vendor/Markets?id=' + props.vendorId;
    items.forEach(x => url += `&marketIds=${x.id}`);
    fetch(url, { method: 'PUT' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setMarkets(data);
      })
  }

  if (loaded && (adding || editing)) {
    return (
      <div className='vendor-joined-list vendor-trades'>
        <JoinedList
          availableItems={unselectedMarkets.map(x => new ListItem(x.id, x.name))}
          selectedItems={markets.map(x => new ListItem(x.id, x.name))}
          title='Markets'
          onChange={adding ? undefined : _changeTrades}
          style={{ width: 'calc(100% - 15px)' }}
        />
        {!adding && <button className='btn btn-sm btn btn-outline-secondary fas fa-check' onClick={() => setEditing(!editing)} title='Done'></button>}
      </div>
    )
  }

  return (
    <div className='vendor-joined-list-text'>
      <span>{markets.length ? markets.map(x => x.name).join(', ') : ''}</span>
      <button className='btn btn-sm btn btn-outline-secondary fas fa-edit' onClick={() => setEditing(!editing)} title='Edit trades'></button>
    </div>
  )
}