import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilterableSelect from 'src/components/FilterableSelect';
import { getAllUsers, getActiveUsers } from 'src/services/users';
import {
  ListItem,
  TransferRecordPostModel,
  TransferRecordType,
  UserInfo,
} from 'src/interfaces/interfaces';
import { JoinedList } from 'src/components/JoinedList';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { actionCreators as ProjectActions } from '../store/projects';
import { transferRecords } from 'src/services/records';
import { MessageBox } from 'src/components/MessageBox';
import { Loader } from 'src/components/Loader';

export const RecordTransfer: React.FC = () => {
  const [allUsers, setAllUsers] = useState<ListItem[]>([]);
  const [activeUsers, setActiveUsers] = useState<ListItem[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const fromUserRef = useRef<FilterableSelect>(null);
  const toUserRef = useRef<FilterableSelect>(null);
  const allRef = useRef<HTMLInputElement>(null);
  const milestonesRef = useRef<HTMLInputElement>(null);
  const tasksRef = useRef<HTMLInputElement>(null);
  const rfisRef = useRef<HTMLInputElement>(null);
  const submittalsRef = useRef<HTMLInputElement>(null);
  const procurementManagerRef = useRef<HTMLInputElement>(null);
  const projectManagerRef = useRef<HTMLInputElement>(null);
  const siteSuperRef = useRef<HTMLInputElement>(null);
  const generalRef = useRef<HTMLInputElement>(null);
  const projectCordRef = useRef<HTMLInputElement>(null);
  const projects = useSelector((s: ApplicationState) => s.projects);
  const dispatch = useDispatch();
  const projectList: ListItem[] = projects.projects.map((project) => {
    return new ListItem(project.id, `${project.id} - ${project.name}`);
  });

  const onTransfer = async () => {
    if (validate()) {
      setLoading(true);
      const types: TransferRecordType[] = [];
      if (milestonesRef.current?.checked) {
        types.push(TransferRecordType.Milestones);
      }
      if (tasksRef.current?.checked) {
        types.push(TransferRecordType.ProjectTasks);
      }
      if (rfisRef.current?.checked) {
        types.push(TransferRecordType.RFIs);
      }
      if (submittalsRef.current?.checked) {
        types.push(TransferRecordType.Submittals);
      }
      if (procurementManagerRef.current?.checked) {
        types.push(TransferRecordType.ProcurementManager);
      }
      if (projectManagerRef.current?.checked) {
        types.push(TransferRecordType.ProjectManager);
      }
      if (siteSuperRef.current?.checked) {
        types.push(TransferRecordType.ProjectSiteSuper);
      }
      if (generalRef.current?.checked) {
        types.push(TransferRecordType.ProjectGeneralSuper);
      }
      if (projectCordRef.current?.checked) {
        types.push(TransferRecordType.ProjectCoordinator);
      }
      const request: TransferRecordPostModel = {
        fromUserId: fromUserRef.current?.state?.hiddenVal?.toString() ?? '',
        toUserId: toUserRef.current?.state?.hiddenVal?.toString() ?? '',
        recordTypes: types,
        projectIds:
          selectedProjects.length > 0
            ? selectedProjects
            : projectList.map((x) => +x.id),
      };
      const recordsUpdated = await transferRecords(request);
      if (!isNaN(recordsUpdated)) {
        if (recordsUpdated === 0) {
          setMessage(`0 records have been updated.`);
        } else {
          setMessage(`Sucessfully transferred ${recordsUpdated} records.`);
        }
        dispatch(ProjectActions.getProjects());
      } else {
        setMessage(
          'Error: There was an issue transferring records. Please try again later.'
        );
      }
      setLoading(false);
    }
  };

  const validate = () => {
    if (
      allUsers.some(
        (x) => x.id == fromUserRef.current?.state?.hiddenVal?.toString()
      ) &&
      activeUsers.some(
        (x) => x.id == toUserRef.current?.state?.hiddenVal?.toString()
      ) &&
      !(
        fromUserRef.current?.state?.hiddenVal?.toString() ==
        toUserRef.current?.state?.hiddenVal?.toString()
      )
    ) {
      if (
        milestonesRef.current?.checked ||
        tasksRef.current?.checked ||
        rfisRef.current?.checked ||
        submittalsRef.current?.checked ||
        procurementManagerRef.current?.checked ||
        projectManagerRef.current?.checked ||
        siteSuperRef.current?.checked ||
        generalRef.current?.checked ||
        projectCordRef.current?.checked
      ) {
        return true;
      } else {
        setMessage('Error: Please choose record types to transfer.');
      }
    } else {
      setMessage(
        'Error: Please choose two distinct users to transfer records.'
      );
    }

    return false;
  };

  const onAllChecked = () => {
    if (
      milestonesRef.current &&
      tasksRef.current &&
      rfisRef.current &&
      submittalsRef.current &&
      procurementManagerRef.current &&
      projectManagerRef.current &&
      siteSuperRef.current &&
      generalRef.current &&
      projectCordRef.current
    ) {
      if (allRef.current?.checked) {
        milestonesRef.current.checked = true;
        tasksRef.current.checked = true;
        rfisRef.current.checked = true;
        submittalsRef.current.checked = true;
        procurementManagerRef.current.checked = true;
        projectManagerRef.current.checked = true;
        siteSuperRef.current.checked = true;
        generalRef.current.checked = true;
        projectCordRef.current.checked = true;
      } else {
        milestonesRef.current.checked = false;
        tasksRef.current.checked = false;
        rfisRef.current.checked = false;
        submittalsRef.current.checked = false;
        procurementManagerRef.current.checked = false;
        projectManagerRef.current.checked = false;
        siteSuperRef.current.checked = false;
        generalRef.current.checked = false;
        projectCordRef.current.checked = false;
      }
    }
  };

  const onProjectSelect = (items: ListItem[]) => {
    setSelectedProjects(items.map((x) => +x.id));
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllUsers();
      setAllUsers(data);

      const activeData = await getActiveUsers();
      setActiveUsers(activeData);
    };
    fetchData();
    if (projects.projects.length < 1) {
      dispatch(ProjectActions.getProjects());
    }
  }, []);

  const onOptionClick = () => {
    if (
      milestonesRef.current &&
      tasksRef.current &&
      rfisRef.current &&
      submittalsRef.current &&
      procurementManagerRef.current &&
      projectManagerRef.current &&
      siteSuperRef.current &&
      generalRef.current &&
      projectCordRef.current &&
      allRef.current
    ) {
      allRef.current.checked =
        milestonesRef.current.checked &&
        tasksRef.current.checked &&
        rfisRef.current.checked &&
        submittalsRef.current.checked &&
        procurementManagerRef.current.checked &&
        projectManagerRef.current.checked &&
        siteSuperRef.current.checked &&
        generalRef.current.checked &&
        projectCordRef.current.checked;
    }
  };

  return (
    <div>
      <MessageBox message={message} clearMessage={() => setMessage('')} />
      <Loader loading={loading} />
      <div className="form-header">
        <h4 className="modal-title">Record Transfer</h4>
      </div>
      <div className="form-body" id="transfer-user-form">
        <br />
        <div className="form-row">
          <div className="form-group col-5">
            <label>
              <b>From User</b>
            </label>
            <FilterableSelect
              ref={fromUserRef}
              items={allUsers}
              id="user-from"
            />
          </div>
          <div className="form-group col-5">
            <label>
              <b>To User</b>
            </label>
            <FilterableSelect
              ref={toUserRef}
              items={activeUsers}
              id="user-to"
            />
          </div>
          <div className="form-row col-12">
            <label style={{ display: 'block' }}>
              <b>Record Types</b>
            </label>
          </div>
          <div
            style={{
              border: '1px solid lightgray',
              borderRadius: '16px',
              paddingTop: '16px',
              marginBottom: '20px',
            }}
            className="form-row"
          >
            <div className="form-group col-12">
              <label style={{ display: 'block' }}>All</label>
              <label className="switch">
                <input ref={allRef} onClick={onAllChecked} type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>Milestones</label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={milestonesRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>Tasks</label>
              <label className="switch">
                <input onClick={onOptionClick} ref={tasksRef} type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>RFIs</label>
              <label className="switch">
                <input onClick={onOptionClick} ref={rfisRef} type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>Submittals</label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={submittalsRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>Procurement Manager</label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={procurementManagerRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>
                Projects (Project Manager)
              </label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={projectManagerRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>Projects (Site Super)</label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={siteSuperRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>
                Projects (General Super)
              </label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={generalRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="form-group col-3">
              <label style={{ display: 'block' }}>
                Projects (Project Coordinator)
              </label>
              <label className="switch">
                <input
                  onClick={onOptionClick}
                  ref={projectCordRef}
                  type="checkbox"
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="form-group col-12">
            <label style={{ display: 'block' }}>
              <b>Projects</b> (Optional, if left empty this will apply to all
              projects)
            </label>
            <JoinedList
              availableItems={projectList}
              selectedItems={{} as ListItem[]}
              title=""
              style={{ height: '200px', marginBottom: '32px' }}
              onChange={onProjectSelect}
            />
          </div>
          <button className="btn btn-sm btn-green" onClick={onTransfer}>
            Transfer Records
          </button>
        </div>
      </div>
    </div>
  );
};
