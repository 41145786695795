import * as React from 'react'
import { helpers, K_HINT_HTML_DEFAULT_Z_INDEX } from './mapHelpers';
import cx from 'classnames';
import { getLuminance } from '../helpers/formulas';

interface RoundMarkerProps {
    //marker: Marker,
    key: string,
    id: string | number,
    lat: number,
    lng: number,
    label: string,
    bgColor: string,
    borderColor?: string,
    $hover?: boolean,
    $dimensionKey?: any,
    $getDimensions?: any,
    scale?: number,
    size?: any,
    image?: string,
    onHoverStateChange?: any,
    onClick?: any,
    onCloseClick?: any,
    onMouseAllow?: any,
    onDragEnd?: any,
    navigate?: (id: any) => void
    hoveredAtTable?: boolean,
    classes?: string,
    hoverClass?: string,
    hoverContent?: JSX.Element,
    zoom: number
}

export const RoundMarker = (props: RoundMarkerProps) => {

    const { bgColor, borderColor, $hover, hoverContent } = props;

    const hoverClass = $hover === true || props.hoveredAtTable ?
        'marker-hover' : ''

    const bgLuminance = getLuminance(bgColor), fontColor = bgLuminance > 186 ? '#000000' : '#ffffff'

    const imageStyle = {
        backgroundColor: bgColor,
        borderColor: borderColor || bgColor,
        color: fontColor
    };

    const zIndexStyle = {
        zIndex: Math.round((props.scale || 1) * 10000) + (props.$hover ? K_HINT_HTML_DEFAULT_Z_INDEX : 0)
    };

    //const pointerStyles = {
    //    borderColor: `transparent transparent transparent ${bgColor}`
    //}

    const markerStyle = helpers.calcRoundMarkerStyle(props.scale || 1, zIndexStyle, {}, imageStyle)
    //const markerStyle = { ...imageStyle, ...zIndexStyle }

    const labelText = props.label ?
        props.label.length > 19 ?
            props.label.substr(0, 17) + '...' : props.label :
        '';

    //console.log(`${props.lat}, ${props.lng}`);

    return (
        <React.Fragment key={`${props.lat}_${props.lng}`}>
            <div style={markerStyle} className={cx('round-marker', props.classes, hoverClass)} onClick={props.onClick}>
                <div>
                    {labelText}
                </div>
                {/*<span className='marker-pointer btm-left' style={pointerStyles}></span>*/}
            </div>
            {$hover && hoverContent}
            {/*{hoverContent}*/}
        </React.Fragment>
    )

}