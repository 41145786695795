import * as React from 'react';
import { ReportSetting } from '../interfaces/interfaces';

interface Props {

}

interface State {
    reports: string[]
    userOptions: ReportSetting[]
}

export default class UserReportSettingsView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            reports: [],
            userOptions: []
        }
    }

    componentWillMount() {
        this._getUserReportOptions()
    }

    _getUserReportOptions = () => {
        fetch(`api/Report/UserOptions`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    reports: data.reports,
                    userOptions: data.userOpts
                })
            })
    }

    _toggleOption = (e: React.ChangeEvent<HTMLInputElement>) => {
        const reportName = e.currentTarget.getAttribute('data-report-name');
        const frequency = e.currentTarget.getAttribute('data-frequency');
        const checked = e.currentTarget.checked;
        if (reportName) {
            fetch(`api/Report/ToggleReport?active=${checked}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        reportName: reportName,
                        frequency: frequency
                    })
                })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            userOptions: data
                        })
                    }
                })
        }
    }

    public render() {

        const rows = this.state.reports.map(x => this.renderReportRow(x))

        return (
            <div id='report-pref'>
                <p>The below options provide reports via email based on your project access or assignment. </p>
                <div id='report-pref-grid' className='col-sm-12'>
                    <div className='grid-header'>
                        <div className='pref-row col-sm-12'>
                            <div className='col-sm-2'><label>Report Name</label></div>
                            <div className='col-sm-1'><label>Sunday</label></div>
                            <div className='col-sm-1'><label>Monday</label></div>
                            <div className='col-sm-1'><label>Tuesday</label></div>
                            <div className='col-sm-1'><label>Wednesday</label></div>
                            <div className='col-sm-1'><label>Thursday</label></div>
                            <div className='col-sm-1'><label>Friday</label></div>
                            <div className='col-sm-1'><label>Saturday</label></div>
                        </div>
                    </div>
                    <hr />
                    <div className='grid-body'>                    
                        {rows}
                    </div>
                </div>
            </div>
        )
    }

    private renderReportRow(report: string) {
        const userOpts = this.state.userOptions
            .filter(x => x.reportName === report);

        return (
            <div key={report} className='col-sm-12 pref-row'>
                <div className='col-sm-2'><label>{report}</label></div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='sunday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'sunday')}
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='monday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'monday')}
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='tuesday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'tuesday')} 
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='wednesday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'wednesday')}
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='thursday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'thursday')} 
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='friday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'friday')} 
                        onChange={this._toggleOption}
                    />
                </div>
                <div className='col-sm-1'>
                    <input type='checkBox' data-frequency='saturday' data-report-name={report}
                        checked={this.checkForSelection(userOpts, 'saturday')} 
                        onChange={this._toggleOption}
                    />
                </div>
            </div>
        )
    }

    private checkForSelection(userOpts: ReportSetting[], frequency: string) {
        if (!userOpts || !userOpts.length) return false;

        return userOpts.findIndex(x => x.frequency.toUpperCase() === frequency.toUpperCase()) !== -1
    }
}