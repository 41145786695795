import * as React from 'react'
import { Chart, GoogleViz } from 'react-google-charts'
import cx from 'classnames'

export interface GanttTask {
    id: string,
    name: string,
    label: string
    startDate: string | Date,
    endDate: string | Date,
    duration?: number | null,
    progress?: number,
    dependencies: string | null,
}

export interface GanttLabel {
    type: string,
    label: string
}

interface Props {
    options?: any,
    columns: GanttLabel[],
    tasks: any[],
    graphId: string,
    hideTooltip?: boolean
}

export const Gantt = (props: Props) => {

    const colors = {
        black: {
            "color": "#888888",
            "dark": "#393939",
            "light": "#999999"
        },
        blue: {
            "color": "#5e97f6",
            "dark": "#2a56c6",
            "light": "#c6dafc"
        },
        red: {
            "color": "#db4437",
            "dark": "#a52714",
            "light": "#f4c7c3"
        },
        green: {
            "color": "#0f9d58",
            "dark": "#0b8043",
            "light": "#b7e1cd"
        }
    }

    //const _onLoad = (google: GoogleViz) => {
    //    if (!tasks || !tasks.length) return;
    //    const singleDateTasks = tasks.filter(t => t[3] === null);
        
    //    if (singleDateTasks.length) {
    //        setTimeout(() => {

    //            var container = document.getElementById(props.graphId);
    //            if (!container) return;
    //            var baseline;
    //            var baselineBounds;
    //            var chartElements;
    //            var svg;
    //            var timeline;
    //            var timelineUnit;
    //            var timelineWidth;
    //            var timespan;
    //            //var dataTable = google.visualization.DataTable;
    //            //var dataTableGroup: any = {} // google.visualization.data.group(dataTable, [0]);
    //            //var dateRangeStart = dataTable.getColumnRange(2);
    //            //var dateRangeEnd = dataTable.getColumnRange(3);
    //            const rowHeight = 44;
    //            const height = (tasks.length - singleDateTasks.length * rowHeight) + rowHeight

    //            baseline = null;
    //            timeline = null;
    //            svg = null;
    //            chartElements = container.getElementsByTagName('svg');
    //            if (chartElements.length > 0) {
    //                svg = chartElements[0];
    //            }
    //            chartElements = container.getElementsByTagName('rect');
    //            if (chartElements.length > 0) {
    //                timeline = chartElements[0];
    //            }
    //            chartElements = container.getElementsByTagName('path');
    //            if (chartElements.length > 0) {
    //                baseline = chartElements[0];
    //            }
    //            chartElements = container.getElementsByTagName('text');
                
    //            if ((svg === null) || (timeline === null) || (baseline === null)
    //                /*|| (dateRangeStart === null) || (dateRangeEnd === null) ||
    //                (markerDate.getTime() < dateRangeStart.min!) ||
    //                (markerDate.getTime() > dateRangeEnd.max!)*/) {
    //                return;
    //            }

    //            timelineWidth = parseFloat(timeline.getAttribute('width'));
    //            baselineBounds = baseline.getBBox();
    //            timespan = dateRangeEnd.max! - dateRangeStart.min!;
    //            timelineUnit = (timelineWidth - baselineBounds.x) / timespan;

    //            for (let i = 0; i < singleDateTasks.length; ++i) {
    //                const markerDate = singleDateTasks[i][4];                                       

    //                let markerLabel: any = null;
    //                let markerLine;
    //                let markerSpan;
    //                // calculate placement

    //                if (chartElements.length > 0) {
    //                    markerLabel = chartElements[0].cloneNode(true);
    //                }

    //                if (markerLabel === null) return;

    //                markerSpan = markerDate.getTime() //- dateRangeStart.min!;

    //                // add label
    //                markerLabel.setAttribute('fill', '#e91e63');
    //                markerLabel.setAttribute('y', height);
    //                markerLabel.setAttribute('x', (baselineBounds.x + (timelineUnit * markerSpan) - 4));
    //                markerLabel.textContent = markerDate.toDateString();
    //                svg.appendChild(markerLabel);

    //                // add line
    //                markerLine = timeline.cloneNode(true);
    //                markerLine.setAttribute('y', 0);
    //                markerLine.setAttribute('x', (baselineBounds.x + (timelineUnit * markerSpan)));
    //                markerLine.setAttribute('height', height);
    //                markerLine.setAttribute('width', 1);
    //                markerLine.setAttribute('stroke', 'none');
    //                markerLine.setAttribute('stroke-width', '0');
    //                markerLine.setAttribute('fill', '#e91e63');
    //                svg.appendChild(markerLine);
    //                addMarker(singleDateTasks[i][4], google, props.graphId, tasks.length - singleDateTasks.length)
    //            }
    //        }, 1000);
    //    }
    //}

    const _numSort = (a: any, b: any) => {
        return a.idx - b.idx;
    }

    const { columns, tasks } = props;
    //const myData = [columns, ...(tasks.filter(x => x[3] !== null))]
    const myData = [columns, ...tasks]
    let options: any = {}
    let palette: any = [];

    /* Check the resource value of the first item to determine 
     * if we should assign red or blue to the first resource group */
    if (tasks && tasks.length) {
        const lateIdx = tasks.findIndex(t => t[2] == "Late"),
            onTimeIdx = tasks.findIndex(t => t[2] == "On Time"),
            outOfRangeIdx = tasks.findIndex(t => t[2] == "Out Of Range")

        const rscColors = [
            { idx: lateIdx, color: colors.red },
            { idx: onTimeIdx, color: colors.blue },
            { idx: outOfRangeIdx, color: colors.black }
        ]
            .filter(n => n.idx >= 0)
            .sort(_numSort)

        palette = rscColors.map(c => c.color);        
    }

    const height = (50 + (props.tasks.length * 50)).toString() + 'px'

    options = {
        gantt: {
            palette: palette,
            criticalPathEnabled: false, // Critical path arrows will be the same as other arrows.
            arrow: {
                angle: 100,
                width: 3,
                color: "slategray",
                radius: 20,
            },
        }
    }

    return (
        <div className={cx('gantt-container', props.hideTooltip && 'no-tooltip')}>
            <Chart
                graphID={props.graphId}
                chartType='Gantt'
                data={myData}
                width="98%"
                height={height}
                options={options}
                //onLoad={_onLoad}
            />
        </div>
    )
}