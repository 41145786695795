import * as React from "react";
import * as DetailStore from "../store/projectDetail";
import { MaterialEntryVm, ListItem } from "../interfaces/interfaces";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import MaterialSelector from "./MaterialSelector";
import { KnownRoles, hasRole } from "../auth/auth";
import { MyAuthElement, NoAuthElement } from "../auth/Authorization";
import * as UserStore from "../store/user";
import Notepad from "./Notepad";
import { getViewportSize } from "../helpers/misc";

interface State {
	showMaterialSelector: boolean;
	vendorOpts: JSX.Element[];
}

interface ListProps {
	vendors?: ListItem[];
	disabled: boolean;
}

type Props = ListProps &
	UserStore.Store &
	DetailStore.Store &
	typeof DetailStore.actionCreators;

class MaterialList extends React.Component<Props, State> {
	pmComponent = MyAuthElement([
		KnownRoles.Admin,
		KnownRoles.CSGPM,
		KnownRoles.ClientAdmin,
		KnownRoles.ClientPM,
		KnownRoles.CoreSuperUser,
	]);
	//csgComponent = MyAuthElement([KnownRoles.Admin, KnownRoles.CSGPM])
	noVendorComponent = NoAuthElement([KnownRoles.Vendor]);
	isVendor = hasRole(this.props.user, [KnownRoles.Vendor]);

	viewport = getViewportSize();

	constructor(props: Props) {
		super(props);
		this.state = {
			showMaterialSelector: false,
			vendorOpts: this.props.vendors
				? this.props.vendors.map((x) => (
						<option key={x.id} value={x.id}>
							{x.value}
						</option>
				  ))
				: [],
		};
	}

	toggleMaterialSelector = () => {
		this.setState({
			showMaterialSelector: !this.state.showMaterialSelector,
		});
	};

	componentDidUpdate(prevProps: Props) {
		if (prevProps.vendors != this.props.vendors) {
			this.setState({
				vendorOpts: this.props.vendors
					? this.props.vendors.map((x) => (
							<option key={x.id} value={x.id}>
								{x.value}
							</option>
					  ))
					: [],
			});
		}
	}

	private handleBlur = (
		e: React.FocusEvent<HTMLInputElement>,
		entryId: number
	) => {
		const ele = e.currentTarget as HTMLInputElement;
		const field = ele.getAttribute("name") || "";
		this.props.updateMatEntry(entryId, field, ele.value);
	};

	private handleChange = (
		e: React.ChangeEvent<HTMLSelectElement>,
		entryId: number
	) => {
		const sel = e.currentTarget as HTMLSelectElement;
		const opt = sel.querySelector("option:checked") as HTMLOptionElement;
		const field = sel.getAttribute("name") || "";
		this.props.updateMatEntry(entryId, field, opt.value);
	};

	private handleCheck = (
		e: React.ChangeEvent<HTMLInputElement>,
		entryId: number
	) => {
		const ele = e.currentTarget as HTMLInputElement;
		const field = ele.getAttribute("name") || "";
		this.props.updateMatEntry(entryId, field, ele.checked.toString());
	};

	public render() {
		const key = this.props.detail.materials
			? this.props.detail.materials.length.toString()
			: //new Date().getTime().toString() :
			  "none_found";
		const header = this.renderGridHeader();
		const mLines =
			this.props.detail.materials && this.props.detail.materials.length
				? this.props.detail.materials
						.sort((a, b) => (new Date(a.poDate) > new Date(b.poDate) ? 1 : -1))
						.map((x, idx) => this.renderGridLine(x, idx))
				: [];
		return (
			<div key={key}>
				{this.state.showMaterialSelector && (
					<this.pmComponent>
						<MaterialSelector
							pId={this.props.detail.id}
							close={this.toggleMaterialSelector}
							clientId={this.props.detail.clientId}
						/>
					</this.pmComponent>
				)}
				<div className="material-grid">
					{header}
					<br />
					<hr />
					{mLines}
				</div>
			</div>
		);
	}

	private renderGridHeader() {
		return (
			<div className="col-sm-12 material-grid-row grid-row row">
				<div className="col-sm-tiny">Type</div>
				<div className="col-sm-1">Item</div>
				<this.noVendorComponent class="col-sm-x-tiny">
					Cost
				</this.noVendorComponent>
				<div className="col-sm-1 min-130">PO Date</div>
				<div className="col-sm-1 min-130">Due Date</div>
				<div className="col-sm-1 min-130">PO #</div>
				<div className="col-sm-x-tiny">Progress</div>
				<div className="col-sm-x-tiny">Lead Time</div>
				<div className="col-sm-tiny">Shipped</div>
				<div className="col-sm-1 min-130">Complete</div>
				<this.noVendorComponent class="col-sm-1 min-130">
					Vendor
				</this.noVendorComponent>
				<div className="col-sm-2 max-190">Notes</div>
				<div className="col-sm-tiny" title="Closed Out?">
					Punched
				</div>
				<this.pmComponent>
					<span
						title="Add Item"
						className="btn btn-sm btn-blue fas fa-plus"
						onClick={this.toggleMaterialSelector}
					></span>
				</this.pmComponent>
			</div>
		);
	}

	private renderGridLine(item: MaterialEntryVm, idx: number) {
		const vendDisable = this.props.disabled;
		const po =
				item.poDate && item.poDate.length
					? new Date(item.poDate).toISOString().substring(0, 10)
					: "",
			due =
				item.dueDate && item.dueDate.length
					? new Date(item.dueDate).toISOString().substring(0, 10)
					: "",
			complete =
				item.complete && item.complete.length
					? new Date(item.complete).toISOString().substring(0, 10)
					: "";
		return (
			<div
				/*key={idx.toString()}*/ key={item.entryId.toString()}
				className="col-sm-12 row material-grid-row"
			>
				<div className="col-sm-tiny">
					<select
						id="materialTypeDDL"
						name="type"
						className="form-control"
						defaultValue={item.type.toString()}
						onChange={(e) => this.handleChange(e, item.entryId)}
						disabled={vendDisable}
					>
						<option value="0"></option>
						<option value="1">Order</option>
						<option value="2">Install</option>
					</select>
				</div>
				<div className="col-sm-1">
					<input
						name="materialName"
						className="form-control"
						defaultValue={item.materialName}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				<this.noVendorComponent class="col-sm-x-tiny">
					<input
						type="number"
						name="cost"
						className="form-control"
						defaultValue={item.cost.toString()}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</this.noVendorComponent>
				<div className="col-sm-1 min-130">
					<input
						type="date"
						name="pODate"
						className="form-control"
						defaultValue={po}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
					/>
				</div>
				<div className="col-sm-1 min-130">
					<input
						type="date"
						name="dueDate"
						className="form-control"
						defaultValue={due}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				<div className="col-sm-1 min-130">
					<input
						name="pONumber"
						className="form-control"
						defaultValue={(item.poNumber || "").toString()}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
					/>
				</div>
				<div className="col-sm-x-tiny">
					<input
						type="number"
						name="progress"
						min="0"
						max="100"
						className="form-control"
						defaultValue={item.progress.toString()}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				<div className="col-sm-x-tiny">
					<input
						type="number"
						name="leadTime"
						className="form-control"
						defaultValue={item.leadTime.toString()}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				<div className="col-sm-tiny">
					<input
						disabled={this._isEqualish(item.type, 2) || vendDisable}
						type="checkbox"
						name="shipped"
						defaultChecked={item.shipped}
						onChange={(e) => this.handleCheck(e, item.entryId)}
					/>
				</div>
				<div className="col-sm-1 min-130">
					<input
						type="date"
						name="complete"
						className="form-control"
						defaultValue={complete}
						onBlur={(e) => this.handleBlur(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				<this.noVendorComponent class="col-sm-1 min-130">
					<select
						className="form-control"
						name="vendorId"
						defaultValue={(item.vendorId || "0").toString()}
						onChange={(e) => this.handleChange(e, item.entryId)}
					>
						<option value={0}></option>
						{this.state.vendorOpts}
					</select>
					{/*<input name='vendorName' className='form-control' defaultValue={item.vendorName} onBlur={(e) => this.handleBlur(e, item.entryId)} />*/}
				</this.noVendorComponent>
				<div className="col-sm-2 max-190">
					<Notepad
						notes={item.notes}
						maxDisplayLength={this.viewport.width <= 480 ? 17 : 35}
						disabled={this.isVendor}
						entityName={item.milestoneName}
						save={(newNotes: string) =>
							this.props.updateMatEntry(item.entryId, "notes", newNotes)
						}
					/>
				</div>
				<div className="col-sm-tiny">
					<input
						type="checkbox"
						name="closedOut"
						defaultChecked={item.closedOut}
						onChange={(e) => this.handleCheck(e, item.entryId)}
						disabled={vendDisable}
					/>
				</div>
				{/*<span className='btn btn-sm fas fa-plus' onClick={() => this.props.addEntry(item.entryId)}></span>*/}
				<this.pmComponent>
					<span
						className="btn btn-sm fas fa-times"
						onClick={() => this.props.deleteEntry(item.entryId)}
					></span>
				</this.pmComponent>
			</div>
		);
	}

	private _isEqualish(val: any, check: any) {
		return val == check; //? 'disabled' : '';
	}
}

export default connect<
	DetailStore.Store,
	typeof DetailStore.actionCreators,
	ListProps
>(
	//@ts-ignore
	(state: ApplicationState) => state.detail,
	DetailStore.actionCreators
)(MaterialList);
