import * as React from 'react';
import cx from 'classnames'
import { MessageBox } from '../../components/MessageBox';
import { ProjectLink } from '../../interfaces/interfaces';


export const ProjectLinksForm = () => {

    const [links, setLinks] = React.useState([] as ProjectLink[]);

    const [newLink, setNewLink] = React.useState({ url: '', displayName: '', id: 0, projectId: 0, } as ProjectLink);

    React.useEffect(() => {
        _getLinks()
    }, []);

    const _getLinks = () => {
        fetch(`api/GlobalConfig/ProjectLinks`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setLinks(data);
            })
    }

    const _addLink = () => {

        if (newLink.url !== '' && newLink.displayName !== null) {
            fetch(`api/GlobalConfig/ProjectLink?url=${newLink.url}&name=${newLink.displayName}`,
                { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setLinks(data);
                        setNewLink({ ...newLink, url: '', displayName: '' });
                    }
                    else alert(data.message);
                })
        }
    }


    const _removeLink = (id: number) => {
        fetch(`api/GlobalConfig/DeleteLink?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setLinks(data);
                else alert(data.message)
            })
    }



    const linkEles = links ?
        links.map(x => {
            return (
                <div key={`${x.displayName}_${x.id}`} className={cx('config-item-line')} data-id={x.id}>
                    <div>{x.displayName}</div>
                    <div className='truncate'><small><i><a href={x.url}>{x.url}</a></i></small></div>
                </div>
            )
        }) : []

    return (
        <div className='config-form config-flex max-350'>            
            <div className='config-new-item-form'>
                <div className="form-header">
                    <h4 className="modal-title">New Link</h4>
                </div>
                <div className="form-body">
                    <input className="form-control" value={newLink.displayName} onChange={(e) => setNewLink({ ...newLink, displayName: e.currentTarget.value })} />
                    <br />
                    <input className="form-control" value={newLink.url} onChange={(e) => setNewLink({ ...newLink, url: e.currentTarget.value })} />
                    <br/>
                    <div className='form-footer'>
                        <span className="btn btn-sm btn-blue" onClick={_addLink}>Add</span>
                    </div>
                </div>
            </div>
            <div className='config-item-list'>
                <div className="form-header" style={{ marginBottom: '15px' }}>
                    <h4 className="modal-title">Existing Links</h4>
                </div>
                <div className=''>
                    {linkEles}
                </div>
            </div>
        </div>
    )
}

