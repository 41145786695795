import * as React from "react";
import styled from "styled-components";

import Modal from "../../components/Modal";
import { SliderCheckbox } from "../../components/SliderCheckbox";
import {
	ChecklistConditionType,
	ChecklistFieldType,
	ChecklistTemplateField,
} from "../../interfaces/interfaces";
import { ChecklistFieldConditionItem } from "./ChecklistFieldConditionItem";
import { DetailModalContext } from "./ChecklistTemplateModal";

const StyledInput = styled.input({
	":invalid": {
		borderColor: "#dc3545",
		":focus": { boxShadow: "0 0 0 0.25rem rgb(220 53 69 / 25%)" },
	},
});

const StyledButton = styled.button`
	margin-top: 15px;
	padding: 6px 0px;
	border-radius: 2px;
	width: 112px;
	font-size: 12px;
`;

const StyledRequirementButton = styled.button`
	margin-top: 15px;
	padding: 5px;
	border-radius: 2px;
	font-size: 10px;
	& span {
		padding-right: 5px;
	}
`;

const OptionContainerDiv = styled.div`
	display: flex;
	width: 100%;
	max-width: calc(100% - 150px);
`;

export const ChecklistFieldInfo = (props: {
	field: ChecklistTemplateField;
	fieldCount: number;
}) => {
	const { field, fieldCount } = props;
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [showAddConditionalSection, setShowAddConditionalSection] =
		React.useState(field.allowConditions);
	const api = React.useContext(DetailModalContext);

	const handleAddCondition = (
		fieldId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => {
		api.addFieldCondition(fieldId, sectionId, templateId, value, type);
	};

	const handleUpdateCondition = (
		fieldConditionId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => {
		api.updateFieldCondition(
			fieldConditionId,
			sectionId,
			templateId,
			value,
			type
		);
	};

	const handleRemoveCondition = (
		fieldConditionId: number,
		templateId: number
	) => {
		api.removeFieldCondition(fieldConditionId, templateId);
	};

	const disableAllowFiles =
		field.allowFiles &&
		field.allowConditions &&
		field.conditions.some(
			(x) =>
				x.conditionType == ChecklistConditionType.Photo ||
				x.conditionType == ChecklistConditionType.PhotoComment
		);

	const renderDeleteModal = () => (
		<Modal>
			<div className="modal-header">
				<h4>{`Delete ${field.fieldName ?? "this field"}`}</h4>
			</div>
			<div className="modal-body">
				<span>Are you sure you want to delete this field?</span>
			</div>
			<div className="modal-footer">
				<div
					className="btn btn-sm btn-danger"
					onClick={() => {
						api.deleteField(field);
						setShowDeleteModal(false);
					}}
				>
					Delete
				</div>
				<div
					className="btn btn-sm btn-outline-secondary"
					onClick={() => setShowDeleteModal(false)}
				>
					Close
				</div>
			</div>
		</Modal>
	);

	return (
		<div
			className="checklist-field-info"
			key={`${field.id}_${field.checklistTemplateSectionId}`}
		>
			{showDeleteModal && renderDeleteModal()}
			<div className="top-row">
				<div className="form-group">
					<div className="inline-label">Field Type</div>
					<span style={{ "lineHeight": 2}}>{fieldTypeToString(field.type)}</span>
				</div>
				<div className="checklist-field-buttons" key={field.order}>
					{field.order > 1 && (
						<span
							style={{ borderRadius: "50%" }}
							className="btn btn-sm fas fa-arrow-up btn-background-hover"
							onClick={() =>
								api.updateField(
									field.id,
									"order",
									field.order - 1,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
						></span>
					)}
					{field.order < fieldCount && (
						<span
							style={{ borderRadius: "50%" }}
							className="btn btn-sm fas fa-arrow-down btn-background-hover"
							onClick={() =>
								api.updateField(
									field.id,
									"order",
									field.order + 1,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
						></span>
					)}
					{/*<span className='btn btn-sm fas fa-copy' title='Copy this field' onClick={() => api.copyField(field.id)}></span>*/}
					{field.isOriginal && (
						<span
							style={{ borderRadius: "50%" }}
							className="btn btn-sm fas fa-trash-alt btn-background-hover"
							title="Delete this field"
							onClick={() => setShowDeleteModal(true)}
						></span>
					)}
				</div>
			</div>
			<div className="form-group">
				<div className="inline-label">Field Name</div>
				<StyledInput
					disabled={!field.isOriginal}
					required
					className="form-control"
					onBlur={(e) =>
						api.updateField(
							field.id,
							"fieldName",
							e.currentTarget.value,
							field.checklistTemplateId,
							field.checklistTemplateSectionId ?? 0
						)
					}
					defaultValue={field.fieldName}
				/>
			</div>
			<div className="form-group">
				<div className="inline-label">Subtitle</div>
				<input
					disabled={!field.isOriginal}
					className="form-control"
					onBlur={(e) =>
						api.updateField(
							field.id,
							"subtitle",
							e.currentTarget.value,
							field.checklistTemplateId,
							field.checklistTemplateSectionId ?? 0
						)
					}
					defaultValue={field.subtitle}
				/>
			</div>
			{field.type === ChecklistFieldType.Option && (
				<>
					{field.options &&
						field.options.map((x, idx) => {
							return (
								<div key={x.id} className="form-group">
									<div className="inline-label">Option {idx + 1}</div>
									<OptionContainerDiv>
										<StyledInput
											key={x.id}
											style={{ maxWidth: "100%" }}
											disabled={!field.isOriginal}
											required
											className="form-control"
											defaultValue={x.text}
											onBlur={(e) =>
												api.updateFieldOption(
													x.id,
													field.checklistTemplateSectionId ?? 0,
													field.checklistTemplateId,
													e.currentTarget.value
												)
											}
										/>
										{field.isOriginal && (
											<span
												style={{ borderRadius: "50%" }}
												className="btn btn-sm fas fa-trash-alt btn-background-hover"
												title="Delete this option"
												onClick={() =>
													api.removeFieldOption(x.id, field.checklistTemplateId)
												}
											></span>
										)}
									</OptionContainerDiv>
								</div>
							);
						})}
				</>
			)}
			<div className="checklist-field-bottom-row">
				{field.type === ChecklistFieldType.Text && (
					<div className="field-option">
						<SliderCheckbox
							disabled={!field.isOriginal}
							fieldName={`${field.fieldName}_multiline`}
							selected={field.multiline}
							dataId={field.id}
							onChange={(e) =>
								api.updateField(
									field.id,
									"multiline",
									!field.multiline,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
						/>
						<span>Multi-line</span>
					</div>
				)}
				{field.type === ChecklistFieldType.Range && (
					<div className="field-option">
						<span>Min: </span>
						<input
							disabled={!field.isOriginal}
							key={field.minValue || 0}
							type="number"
							defaultValue={field.minValue || 0}
							onBlur={(e) =>
								api.updateField(
									field.id,
									"minValue",
									e.currentTarget.value || 0,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
							style={{ width: "50px" }}
						/>
						&nbsp;
						<span>Max: </span>
						<input
							disabled={!field.isOriginal}
							key={field.maxValue || 0}
							type="number"
							defaultValue={field.maxValue || ""}
							onBlur={(e) =>
								api.updateField(
									field.id,
									"maxValue",
									e.currentTarget.value || 0,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
							style={{ width: "50px" }}
						/>
					</div>
				)}
				<div className="field-option">
					<label className="switch" key={field.allowFiles.toString()}>
						<input
							disabled={!field.isOriginal || disableAllowFiles}
							type="checkbox"
							defaultChecked={field.allowFiles}
						/>
						<span
							className="slider round"
							title={
								disableAllowFiles
									? "Toggle is disabled because one or more requirements involve a file upload"
									: undefined
							}
							onClick={() => {
								if (field.isOriginal && !disableAllowFiles) {
									api.updateField(
										field.id,
										"allowFiles",
										!field.allowFiles,
										field.checklistTemplateId,
										field.checklistTemplateSectionId ?? 0
									);
								}
							}}
						></span>
					</label>
					<span>Allow files</span>
				</div>
				<div className="field-option">
					<label className="switch" key={field.required.toString()}>
						<input
							disabled={!field.isOriginal}
							type="checkbox"
							defaultChecked={field.required}
						/>
						<span
							className="slider round"
							onClick={() =>
								api.updateField(
									field.id,
									"required",
									!field.required,
									field.checklistTemplateId,
									field.checklistTemplateSectionId ?? 0
								)
							}
						></span>
					</label>
					<span>Required</span>
				</div>
				{(field.type === ChecklistFieldType.Option ||
					field.type === ChecklistFieldType.Checkbox) && (
					<div className="field-option">
						<label className="switch">
							<input
								disabled={!field.isOriginal}
								type="checkbox"
								checked={showAddConditionalSection}
							/>
							<span
								className="slider round"
								onClick={() => {
									if (field.isOriginal) {
										api.updateField(
											field.id,
											"allowConditions",
											!showAddConditionalSection,
											field.checklistTemplateId,
											field.checklistTemplateSectionId ?? 0
										);
										setShowAddConditionalSection(!showAddConditionalSection);
									}
								}}
							></span>
						</label>
						<span>Conditional Requirements</span>
					</div>
				)}
				{(field.type === ChecklistFieldType.Range ||
					field.type === ChecklistFieldType.Number) && (
					<div
						className="field-option"
						title="Indicate that this field provides an overall score or assessment for the checklist"
					>
						<label className="switch" key={field.overallScore.toString()}>
							<input
								disabled={!field.isOriginal}
								type="checkbox"
								defaultChecked={field.overallScore}
							/>
							<span
								className="slider round"
								onClick={() =>
									api.updateField(
										field.id,
										"overallScore",
										!field.overallScore,
										field.checklistTemplateId,
										field.checklistTemplateSectionId ?? 0
									)
								}
							></span>
						</label>
						<span>Overall Score</span>
					</div>
				)}
			</div>
			{field.type == ChecklistFieldType.Option && (
				<StyledButton
					disabled={!field.isOriginal}
					className="btn btn-sm btn-blue"
					onClick={() =>
						api.addFieldOption(
							field.id,
							field.checklistTemplateSectionId ?? 0,
							field.checklistTemplateId
						)
					}
				>
					Add Option
				</StyledButton>
			)}
			<div style={{ marginTop: "15px" }}>
				{field.allowConditions &&
					field.conditions.map((x) => {
						return (
							<ChecklistFieldConditionItem
								key={x.id}
								onUpdateCondition={handleUpdateCondition}
								field={field}
								condition={x}
								onRemoveCondition={handleRemoveCondition}
							/>
						);
					})}
				{field.allowConditions && field.conditions.length < 1 && (
					<ChecklistFieldConditionItem
						onAddCondition={handleAddCondition}
						field={field}
						onRemoveCondition={handleRemoveCondition}
					/>
				)}
				{field.allowConditions &&
					field.conditions.length > 0 &&
					((field.type == ChecklistFieldType.Option &&
						field.conditions.length < field.options.length) ||
						(field.type == ChecklistFieldType.Checkbox &&
							field.conditions.length < 3)) && (
						<StyledRequirementButton
							disabled={!field.isOriginal}
							className="btn btn-sm btn-blue"
							onClick={() =>
								api.addFieldCondition(
									field.id,
									field.checklistTemplateSectionId ?? 0,
									field.checklistTemplateId,
									"",
									ChecklistConditionType.None
								)
							}
						>
							Add New Requirement
						</StyledRequirementButton>
					)}
			</div>
		</div>
	);
};

const fieldTypeToString = (t: ChecklistFieldType) => {
	switch (t) {
		case ChecklistFieldType.Text:
			return "Text";
		case ChecklistFieldType.Date:
			return "Date";
		case ChecklistFieldType.Checkbox:
			return "Checkbox";
		case ChecklistFieldType.Range:
			return "Range";
		case ChecklistFieldType.Option:
			return "Option";
	}
};
