import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import * as cx from 'classnames';

import UserPropDisplay from './UserPropDisplay';
import { NoAuthElement, MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';

const logoPath = process.env.PUBLIC_URL + '/img/CoreSiteVue_Logo3_wht.png';

export const NavMenu = () => {
  const userStore = useSelector((s: ApplicationState) => s.user);
  const { user } = userStore;

  const [collapseSide, setCollapseSide] = React.useState(false);

  const _toggleCollapse = () => setCollapseSide(!collapseSide);

  const NoVendorComp = NoAuthElement([KnownRoles.Vendor, KnownRoles.OwnerLtd]);
  const AdminComp = MyAuthElement([KnownRoles.Admin]);
  const ConfigComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);
  const InternalUsersComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
    KnownRoles.SiteSuper,
  ]);

  return (
    <div className={cx('main-nav', collapseSide && 'side-collapse')}>
      <div className="navbar navbar-inverse">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggler mobile-show"
            onClick={_toggleCollapse}
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div
            className="btn sidebar-toggle mobile-hide"
            onClick={_toggleCollapse}
          >
            <span className="sr-only">Toggle navigation</span>
            {collapseSide ? (
              <i className="fa-solid fa-arrow-right-from-line"></i>
            ) : (
              <i className="fa-solid fa-arrow-left-from-line"></i>
            )}
          </div>
          <Link className="navbar-brand" to={'/'}>
            <img src={logoPath} />
          </Link>
        </div>
        {/*<hr/>*/}
        <div className={cx('navbar-collapse', collapseSide && 'collapsed')}>
          {!user ? (
            <span style={{ color: 'white' }}>Loading...</span>
          ) : (
            <ul className="nav navbar-nav">
              <li>
                <div>
                  <NavLink
                    to={'/'}
                    exact
                    activeClassName="active"
                    title="Dashboard"
                  >
                    <span className="fas fa-signal"></span>&nbsp;
                    <label className="collapse-hide"> Dashboard</label>
                  </NavLink>
                </div>
              </li>
              <li>
                <div>
                  <NavLink
                    to={'/projects'}
                    activeClassName="active"
                    title="Projects"
                  >
                    <span className="fas fa-list-alt"></span>&nbsp;
                    <label className="collapse-hide"> Projects</label>
                  </NavLink>
                </div>
              </li>
              <li>
                <InternalUsersComp>
                  <NavLink
                    to={'/subs'}
                    activeClassName="active"
                    title="Subcontractors"
                  >
                    <span className="fas fa-address-book"></span>&nbsp;
                    <label className="collapse-hide"> Subcontractors</label>
                  </NavLink>
                </InternalUsersComp>
              </li>
              <li>
                <InternalUsersComp>
                  <NavLink
                    to={'/reporting'}
                    activeClassName="active"
                    title="Redlines"
                  >
                    <span className="fas fa-chart-line"></span>&nbsp;
                    <label className="collapse-hide"> Reporting</label>
                  </NavLink>
                </InternalUsersComp>
              </li>
              <li>
                <ConfigComp>
                  <NavLink
                    to={'/config'}
                    activeClassName="active"
                    title="Config"
                  >
                    <span className="fas fa-cog"></span>&nbsp;
                    <label className="collapse-hide"> Config</label>
                  </NavLink>
                </ConfigComp>
              </li>
              <li>
                <NoVendorComp>
                  <NavLink
                    to={'/explorer'}
                    activeClassName="active"
                    title="SiteVue Explorer"
                  >
                    <span className="fas fa-folder-open"></span>&nbsp;
                    <label className="collapse-hide"> SiteVue Explorer</label>
                  </NavLink>
                </NoVendorComp>
              </li>
              <li>
                <ConfigComp>
                  <NavLink
                    to={'/fileshare'}
                    activeClassName="active"
                    title="FileShare"
                  >
                    <span className="fas fa-share-alt"></span>&nbsp;
                    <label className="collapse-hide"> Fileshare</label>
                  </NavLink>
                </ConfigComp>
              </li>
              <li>
                <AdminComp>
                  <NavLink to={'/admin'} activeClassName="active" title="Admin">
                    <span className="fas fa-user-shield"></span>&nbsp;
                    <label className="collapse-hide"> Admin</label>
                  </NavLink>
                </AdminComp>
              </li>
              {/*<li className='logoutForm company-logo mobile-hide'>
                                <img src='/img/CoreStatesGroup_PrimaryLogo_FullColor.png' />
                            </li>*/}
              <li className="logoutForm mobile-show" onClick={_toggleCollapse}>
                <div>
                  <a href="/Account/Logout">
                    <span className="fas fa-sign-out-alt"></span> Log out
                  </a>
                </div>
              </li>
              <li className="logoutForm greeting-item mobile-show">
                <div>
                  <NavLink
                    to={'/accPref'}
                    activeClassName="active"
                    onClick={_toggleCollapse}
                  >
                    <span>
                      Hello <UserPropDisplay prop="fullName" />
                    </span>
                  </NavLink>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

//export class NavMenu extends React.Component<any, any> {
//  constructor(props: any) {
//    super(props)
//    this.state = {
//      user: this.props.user,
//      collapseSide: true
//    }
//  }

//  componentWillReceiveProps(nextProps: any) {
//    if (this.state.user != nextProps.user) {
//      this.setState({ user: nextProps.user })
//    }
//  }

//  _collapseNav = () => {
//    //this.collapseNav();
//    this.setState({ collapseSide: !this.state.collapseSide })
//  }

//  //collapseNav = () => {
//  //    const nav = document.querySelector('.navbar-collapse') as HTMLDivElement

//  //    nav.classList.toggle('collapsed');
//  //}

//  _toggleCollapseSide = () => {
//    const { collapseSide } = this.state;
//    //const mainNav = document.querySelector('.main-nav') as HTMLDivElement

//    //if (collapseSide) mainNav.classList.remove('side-collapse')
//    //else mainNav.classList.add('side-collapse');

//    this.setState({ collapseSide: !this.state.collapseSide })

//  }

//  noVendorComp = NoAuthElement([KnownRoles.Vendor, KnownRoles.OwnerLtd])
//  adminComp = MyAuthElement([KnownRoles.Admin])
//  configComp = MyAuthElement([KnownRoles.Admin, KnownRoles.CoreSuperUser, KnownRoles.CSGPM])
//  internalUsersComp = MyAuthElement([KnownRoles.Admin, KnownRoles.CoreSuperUser, KnownRoles.CSGPM, KnownRoles.SiteSuper])

//  public render() {
//    return <div className={cx('main-nav', this.state.collapseSide && 'side-collapse')}>
//      <div className='navbar navbar-inverse'>
//        <div className='navbar-header'>
//          <button type='button' className='navbar-toggler mobile-show' onClick={this._collapseNav}>
//            <span className='sr-only'>Toggle navigation</span>
//            <span className='icon-bar'></span>
//            <span className='icon-bar'></span>
//            <span className='icon-bar'></span>
//          </button>
//          <div className='btn sidebar-toggle mobile-hide' onClick={this._toggleCollapseSide}>
//            <span className='sr-only'>Toggle navigation</span>
//            {this.state.collapseSide ? <i className="fa-solid fa-arrow-right-from-line"></i> : <i className="fa-solid fa-arrow-left-from-line"></i>}
//          </div>
//          <Link className='navbar-brand' to={'/'}>
//            <img src={logoPath} />
//          </Link>
//        </div>
//        {/*<hr/>*/}
//        <div className={cx('navbar-collapse', this.state.collapseSide && 'collapsed')}>
//          {this.state.user == "" ? <span style={{ 'color': 'white' }}>"Loading..."</span> :
//            <ul className='nav navbar-nav' ref='navbar'>
//              <li>
//                <div>
//                  <NavLink to={'/'} exact activeClassName='active' title='Dashboard'>
//                    <span className='fas fa-signal'></span>&nbsp;<label className='collapse-hide'> Dashboard</label>
//                  </NavLink>
//                </div>
//              </li>
//              <li>
//                <div>
//                  <NavLink to={'/projects'} activeClassName='active' title='Projects'>
//                    <span className='fas fa-list-alt'></span>&nbsp;<label className='collapse-hide'> Projects</label>
//                  </NavLink>
//                </div>
//              </li>
//              <li>
//                <this.internalUsersComp>
//                  <NavLink to={'/subs'} activeClassName='active' title='Subcontractors'>
//                    <span className='fas fa-address-book'></span>&nbsp;<label className='collapse-hide'> Subcontractors</label>
//                  </NavLink>
//                </this.internalUsersComp>
//              </li>
//              <li>
//                <this.noVendorComp>
//                  <NavLink to={'/redlines'} activeClassName='active' title='Redlines'>
//                    <span className='fas fa-list'></span>&nbsp;<label className='collapse-hide'> Redlines</label>
//                  </NavLink>
//                </this.noVendorComp>
//              </li>
//              <li>
//                <this.configComp>
//                  <NavLink to={'/config'} activeClassName='active' title='Config'>
//                    <span className='fas fa-cog'></span>&nbsp;<label className='collapse-hide'> Config</label>
//                  </NavLink>
//                </this.configComp>
//              </li>
//              <li>
//                <this.noVendorComp>
//                  <NavLink to={'/explorer'} activeClassName='active' title='SiteVue Explorer'>
//                    <span className='fas fa-folder-open'></span>&nbsp;<label className='collapse-hide'> SiteVue Explorer</label>
//                  </NavLink>
//                </this.noVendorComp>
//              </li>
//              <li>
//                <this.configComp>
//                  <NavLink to={'/fileshare'} activeClassName='active' title='FileShare'>
//                    <span className='fas fa-share-alt'></span>&nbsp;<label className='collapse-hide'> Fileshare</label>
//                  </NavLink>
//                </this.configComp>
//              </li>
//              <li>
//                <this.adminComp>
//                  <NavLink to={'/admin'} activeClassName='active' title='Admin'>
//                    <span className='fas fa-user-shield'></span>&nbsp;<label className='collapse-hide'> Admin</label>
//                  </NavLink>
//                </this.adminComp>
//              </li>
//              {/*<li className='logoutForm company-logo mobile-hide'>
//                                <img src='/img/CoreStatesGroup_PrimaryLogo_FullColor.png' />
//                            </li>*/}
//              <li className='logoutForm mobile-show' onClick={this._collapseNav}>
//                <div>
//                  <a href='/Account/Logout'>
//                    <span className='fas fa-sign-out-alt'></span> Log out
//                  </a>
//                </div>
//              </li>
//              <li className='logoutForm greeting-item mobile-show'>
//                <div>
//                  <NavLink to={'/accPref'} activeClassName='active' onClick={this._collapseNav}>
//                    <span>Hello <UserPropDisplay prop='fullName' /></span>
//                  </NavLink>
//                </div>
//              </li>
//            </ul>
//          }
//        </div>
//      </div>
//    </div>;
//  }
//}
