import React, { useMemo } from "react";
import DocIcon from "src/components/DocIcon";
import Notepad from "src/components/Notepad";
import { conditionTypeToString } from "src/config/checklists/ChecklistFieldConditionItem";
import { formatDateInputFriendly } from "src/helpers/formatters";
import { isImage } from "src/helpers/misc";
import {
    ChecklistConditionType,
    ChecklistDetailSection,
    ChecklistFieldEntry,
    ChecklistFieldType,
} from "src/interfaces/interfaces";
import styled from "styled-components";

const ContainerDiv = styled.div({
    width: "calc(100% - 425px)",
    minWidth: "225px",
});

const StyledSmall = styled.small({
    color: "red",
});

interface ChecklistEntryProps {
    entry: ChecklistFieldEntry;
    canEditFields: boolean;
    updateFieldEntry: (id: number, value: string) => void;
    updateNotes: (id: number, value: string) => void;
    deleteFieldAttachment: (id: number) => void;
    uploadFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
    section: ChecklistDetailSection;
}

export const ChecklistEntry: React.FC<ChecklistEntryProps> = ({
    entry,
    canEditFields,
    updateFieldEntry,
    updateNotes,
    deleteFieldAttachment,
    uploadFiles,
    section,
}) => {
    let value: JSX.Element = <div></div>;

    const requiredText = useMemo(() => {
        if (entry.conditions.length > 0) {
            const condition = entry.conditions?.find((x) => {
                return x.value == entry.fieldValue;
            });

            if (
                condition &&
                condition.conditionType !== ChecklistConditionType.None
            ) {
                let error = false;
                switch (condition.conditionType) {
                    case ChecklistConditionType.Comment:
                        if (!entry.notes) {
                            error = true;
                        }
                        break;
                    case ChecklistConditionType.Photo:
                        if (!entry.files.some((x) => isImage(x.filePath))) {
                            error = true;
                        }
                        break;
                    case ChecklistConditionType.PhotoComment:
                        if (!entry.files.some((x) => isImage(x.filePath)) || !entry.notes) {
                            error = true;
                        }                        
                        break;
                }
                if (error) {
                    return (
                        "This question requires a " +
                        conditionTypeToString(condition.conditionType).toLowerCase() +
                        "."
                    );
                }
            }
        }
    }, [entry]);

    switch (entry.type) {
        case ChecklistFieldType.Text: {
            if (entry.multiline) {
                value = (
                    <textarea
                        className="form-control"
                        defaultValue={entry.fieldValue}
                        onBlur={(e) => updateFieldEntry(entry.id, e.currentTarget.value)}
                        disabled={!canEditFields}
                    ></textarea>
                );
                break;
            } else {
                value = (
                    <input
                        className="r form-control"
                        defaultValue={entry.fieldValue}
                        onBlur={(e) => updateFieldEntry(entry.id, e.currentTarget.value)}
                        disabled={!canEditFields}
                    />
                );
                break;
            }
        }
        case ChecklistFieldType.Date: {
            value = (
                <input
                    className="form-control"
                    type="date"
                    defaultValue={formatDateInputFriendly(entry.fieldValue)}
                    onBlur={(e) => updateFieldEntry(entry.id, e.currentTarget.value)}
                    disabled={!canEditFields}
                />
            );
            break;
        }
        case ChecklistFieldType.Checkbox: {
            value = (
                <div>
                    <div className="checklist-checkbox">
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={entry.fieldValue === "true"}
                                disabled={!canEditFields}
                                onChange={
                                    canEditFields
                                        ? () => updateFieldEntry(entry.id, "true")
                                        : () => { }
                                }
                            />{" "}
                            <span>Yes</span>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={entry.fieldValue === "false"}
                                disabled={!canEditFields}
                                onChange={
                                    canEditFields
                                        ? () => updateFieldEntry(entry.id, "false")
                                        : () => { }
                                }
                            />{" "}
                            <span>No</span>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                defaultChecked={entry.fieldValue === "n/a"}
                                disabled={!canEditFields}
                                onChange={
                                    canEditFields
                                        ? () => updateFieldEntry(entry.id, "n/a")
                                        : () => { }
                                }
                            />{" "}
                            <span>N/A</span>
                        </div>
                    </div>
                    <StyledSmall style={{ color: "red" }} className="field-subtitle">
                        {requiredText}
                    </StyledSmall>
                </div>
            );
            break;
        }
        case ChecklistFieldType.Range: {
            const min = entry.minValue || 0,
                max = entry.maxValue || 5;
            value = (
                <div>
                    <input
                        className="form-control"
                        style={{ width: "75px" }}
                        type="number"
                        min={min}
                        max={max}
                        defaultValue={entry.fieldValue}
                        disabled={!canEditFields}
                        onBlur={
                            canEditFields
                                ? (e) => updateFieldEntry(entry.id, e.currentTarget.value)
                                : () => { }
                        }
                    />
                    <small>
                        Enter a number between {min} and {max}.
                    </small>
                </div>
            );
            break;
        }
        case ChecklistFieldType.Option: {
            value = (
                <ContainerDiv>
                    <select
                        className="form-control"
                        onChange={(e) => updateFieldEntry(entry.id, e.currentTarget.value)}
                        disabled={!canEditFields}
                        defaultValue={entry.fieldValue}
                    >
                        <option value=""></option>
                        {entry.options.map((x) => {
                            return (
                                <option key={x.id} className={"form-control"} value={x.text}>
                                    {x.text}
                                </option>
                            );
                        })}
                    </select>
                    <small style={{ color: "red" }} className="field-subtitle">
                        {requiredText}
                    </small>
                </ContainerDiv>
            );
            break;
        }
    }

    const requiredSign = entry.required ? "*" : "";

    return (
        <div className="checklist-field" key={entry.id + "_" + entry.fieldValue}>
            <div className="form-group">
                <div className="inline-label" title={entry.fieldName}>
                    {section.order + "." + entry.order} {entry.fieldName}
                    {requiredSign}
                    <small
                        style={{ float: "left", width: "100%" }}
                        className="field-subtitle"
                    >
                        {entry.subtitle}
                    </small>
                </div>
                {value}
                <Notepad
                    entityName={entry.fieldName}
                    notes={entry.notes}
                    save={(notes) => updateNotes(entry.id, notes)}
                    maxDisplayLength={0}
                    disabled={!canEditFields}
                />
                {entry.files.map((x) => (
                    <DocIcon
                        key={x.id}
                        doc={x}
                        delete={canEditFields ? deleteFieldAttachment : undefined}
                    />
                ))}
                {entry.allowFiles && canEditFields ? (
                    <input
                        type="file"
                        key={entry.files.length}
                        multiple
                        data-id={entry.id}
                        onChange={uploadFiles}
                    />
                ) : (
                    []
                )}
            </div>
        </div>
    );
};
