import * as React from 'react';

interface Props {
    height?: number;
}

export const MobileVerticalSpacer = (props: Props) => {
    const height = `${(props.height || 10)}px`;

    return <div className='mobile-show mobile-full-width' style={{height: height}}></div>
}