import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'src/components/Loader';
import Modal from 'src/components/Modal';
import {
  DailyFieldReportTemplate,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { StandardGridLoader } from 'src/loaders/StandardGridLoader';
import { TabContent, TabLink, Tabs } from 'src/tabs';
import styled from 'styled-components';

const ModalBodyDiv = styled.div`
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;

const StyledInput = styled.input({
  height: 'unset !important',
  width: '350px',
  marginBottom: '16px',
});

const StyledH4 = styled.h4({
  marginBottom: '3px',
});

const StyledDiv = styled.div({
  display: 'flex',
});

interface QualityReportModalProps {
  template: DailyFieldReportTemplate;
  onModalClose: () => void;
  onTemplateUpdate: (update: UpdateObject) => void;
  onTemplateCopy: () => void;
  onListAdd: (field: string) => void;
  isLoading: boolean;
}

export const QualityReportModal = ({
  template,
  onModalClose,
  onTemplateUpdate,
  onTemplateCopy,
  onListAdd,
  isLoading,
}: QualityReportModalProps) => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [topicList, setTopicList] = useState<string[]>(template.topicList);
  const [equipmentList, setEquipmentList] = useState<string[]>(
    template.equipmentList
  );
  const [photoList, setPhotoList] = useState<string[]>(
    template.photoDescriptionList
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setTopicList(template.topicList);
    setEquipmentList(template.equipmentList);
    setPhotoList(template.photoDescriptionList);
  }, [template]);

  const updateItems = useCallback(
    (items: string[], idx: number, item: string) => {
      const newItems = Array.from(items);
      newItems.splice(idx, 1, item);
      return newItems;
    },
    []
  );

  const removeItems = useCallback(
    (items: string[], idx: number, fieldName: string) => {
      const newItems = Array.from(items);
      newItems.splice(idx, 1);

      onTemplateUpdate({
        fieldName: fieldName,
        value: newItems.join('|'),
      });
    },
    []
  );

  return (
    <>
      <Modal>
        <div className="modal-header">
          {!isEditingTitle ? (
            <>
              <StyledDiv>
                <StyledH4>{template.name}</StyledH4>
                <span
                  style={{
                    fontSize: 'unset',
                    position: 'unset',
                    marginLeft: '10px',
                  }}
                  className="fas fa-edit"
                  onClick={() => setIsEditingTitle(true)}
                ></span>
              </StyledDiv>
              <span
                onClick={() => onTemplateCopy()}
                className="btn btn-sm btn-outline-secondary"
                title="Copy Template"
                style={{ fontSize: '12px' }}
              >
                <span className="fa fa-clipboard"></span>
                Copy
              </span>
            </>
          ) : (
            <>
              <StyledInput
                ref={inputRef}
                defaultValue={template.name}
                className="form-control"
              />
              <button
                className="btn btn-sm btn-blue"
                onClick={(e) => {
                  onTemplateUpdate({
                    fieldName: 'name',
                    value: inputRef.current?.value ?? '',
                  });
                  setIsEditingTitle(false);
                }}
                style={{
                  marginRight: '8px',
                  marginLeft: '8px',
                }}
              >
                Save
              </button>
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={() => setIsEditingTitle(false)}
              >
                Cancel
              </button>
            </>
          )}
        </div>
        <ModalBodyDiv className="modal-body custom-scrollbar">
          {isLoading ? (
            <StandardGridLoader></StandardGridLoader>
          ) : (
            <Tabs name="admin-tabs" renderActiveTabContentOnly={true}>
              <div className="tab-links">
                <TabLink to="description" component="span">
                  <span>Description</span>
                </TabLink>
                <TabLink to="personnel" component="span">
                  <span>Equipment</span>
                </TabLink>
                <TabLink to="photos" component="span">
                  <span>Photos</span>
                </TabLink>
              </div>
              <div className="content">
                <TabContent
                  style={{ display: 'flex', flexDirection: 'column' }}
                  for="description"
                >
                  {topicList.map((x, idx) => {
                    return (
                      <div key={'equipment' + idx}>
                        <h6>Topic {idx + 1}</h6>
                        <StyledInput
                          onChange={(e) => {
                            const items = updateItems(
                              topicList,
                              idx,
                              e.currentTarget.value
                            );
                            setTopicList(items);
                          }}
                          value={x}
                          onBlur={() => {
                            if (topicList.some((x) => x.length < 4)) {
                              dispatch({
                                type: 'CONFIG_ERROR',
                                message:
                                  'Error: Please enter valid a Topic with more than 3 characters',
                              });
                            } else {
                              onTemplateUpdate({
                                fieldName: 'topics',
                                value: topicList.join('|'),
                              });
                            }
                          }}
                        ></StyledInput>
                        <span
                          style={{ borderRadius: '50%' }}
                          className="btn btn-sm fas fa-trash-alt btn-background-hover"
                          title="Delete this item"
                          onClick={() => {
                            removeItems(topicList, idx, 'topics');
                          }}
                        ></span>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-sm btn-blue"
                    onClick={() => {
                      onListAdd('topics');
                    }}
                    style={{ maxWidth: '50px' }}
                  >
                    Add
                  </button>
                </TabContent>
                <TabContent
                  style={{ display: 'flex', flexDirection: 'column' }}
                  for="personnel"
                >
                  {equipmentList.map((x, idx) => {
                    return (
                      <div key={'equipment' + idx}>
                        <h6>Equipment Item {idx + 1}</h6>
                        <StyledInput
                          onChange={(e) => {
                            const items = updateItems(
                              equipmentList,
                              idx,
                              e.currentTarget.value
                            );
                            setEquipmentList(items);
                          }}
                          value={x}
                          onBlur={() => {
                            if (equipmentList.some((x) => x.length < 4)) {
                              dispatch({
                                type: 'CONFIG_ERROR',
                                message:
                                  'Error: Please enter valid Equipment with more than 3 characters ',
                              });
                            } else {
                              onTemplateUpdate({
                                fieldName: 'equipment',
                                value: equipmentList.join('|'),
                              });
                            }
                          }}
                        ></StyledInput>
                        <span
                          style={{ borderRadius: '50%' }}
                          className="btn btn-sm fas fa-trash-alt btn-background-hover"
                          title="Delete this item"
                          onClick={() => {
                            removeItems(equipmentList, idx, 'equipment');
                          }}
                        ></span>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-sm btn-blue"
                    onClick={() => {
                      onListAdd('equipment');
                    }}
                    style={{ maxWidth: '50px' }}
                  >
                    Add
                  </button>
                </TabContent>
                <TabContent
                  style={{ display: 'flex', flexDirection: 'column' }}
                  for="photos"
                >
                  {photoList.map((x, idx) => {
                    return (
                      <div key={'photoDescriptions' + idx}>
                        <h6>Photo {idx + 1}</h6>
                        <StyledInput
                          onChange={(e) => {
                            const items = updateItems(
                              photoList,
                              idx,
                              e.currentTarget.value
                            );
                            setPhotoList(items);
                          }}
                          value={x}
                          onBlur={() => {
                            if (photoList.some((x) => x.length < 4)) {
                              dispatch({
                                type: 'CONFIG_ERROR',
                                message:
                                  'Error: Please enter a valid Photo Description with more than 3 characters ',
                              });
                            } else {
                              onTemplateUpdate({
                                fieldName: 'photoDescriptions',
                                value: photoList.join('|'),
                              });
                            }
                          }}
                        ></StyledInput>
                        <span
                          style={{ borderRadius: '50%' }}
                          className="btn btn-sm fas fa-trash-alt btn-background-hover"
                          title="Delete this item"
                          onClick={() => {
                            removeItems(photoList, idx, 'photoDescriptions');
                          }}
                        ></span>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-sm btn-blue"
                    onClick={() => onListAdd('photoDescriptions')}
                    style={{ maxWidth: '50px' }}
                  >
                    Add
                  </button>
                </TabContent>
              </div>
            </Tabs>
          )}
        </ModalBodyDiv>
        <div className="modal-footer">
          <span
            className="btn btn-sm btn-outline-secondary"
            onClick={onModalClose}
          >
            Close
          </span>
        </div>
      </Modal>
    </>
  );
};
