import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import { formatDateTime } from '../helpers/formatters';
import { ProcurementComment, ProcurementItem } from '../interfaces/interfaces';
import { actionCreators as procurementActions } from '../store/procurement';
import { ApplicationState } from '../store';

export const ProcurementComments = (props: { item: ProcurementItem; close: () => void }) => {

    const { item } = props, { comments } = item;
    const userStore = useSelector((s: ApplicationState) => s.user);
    const dispatch = useDispatch();

    const userId = (userStore.user || { userId: '' }).userId;
    const canComment = item.userId === userId ||
        item.projectManagerId === userId ||
        item.reviewers && item.reviewers.findIndex(x => x.userId === userId) !== -1;

    const _addComment = () => {
        const comment = encodeURIComponent((document.getElementById('new-comment-textarea') as HTMLTextAreaElement).value);
        dispatch(procurementActions.addComment(item.id, comment));
        (document.getElementById('new-comment-textarea') as HTMLTextAreaElement).value = '';
    }

    return (
        <Modal modalClass='procurement-comments-modal'>
            <div className='modal-header'><h4>{item.itemDescription} Comments</h4></div>
            <div className='modal-body'>
                <div style={{ maxHeight: '50vh', overflowY: 'auto', marginBottom: '10px', paddingBottom: '10px' }}>
                    {comments.map(x => <CommentBox key={x.id} comment={x} />)}
                </div>
                {comments && comments.length ? <hr /> : []}
                {canComment ?
                    <div>
                        <div><b>New Comment</b></div>
                        <textarea className='form-control' id='new-comment-textarea'></textarea>
                        <button style={{ marginTop: '5px' }} className='btn btn-x-sm btn-green' onClick={_addComment}>Save</button>
                    </div>
                    : []
                }
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
            </div>
        </Modal>
    )
};

const CommentBox = (props: { comment: ProcurementComment }) => (
    <div className='recipient-comment comment-box'>
        <p className='commenter-name'><b>{props.comment.userFullName}</b> - <i>{formatDateTime(props.comment.timestamp)}</i></p>
        <div className='comment'>{props.comment.comment}</div>
    </div>
)