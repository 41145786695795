import { createNCRReport, downloadNCRReport } from 'src/services/ncrs';
import { AppStartListening } from '../listenerMiddleware';
import { NCRActions } from './ncrs.reducer';
import download from 'downloadjs';

export const addNCRListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: NCRActions.sendReport,
      effect: async (action, listenApi) => {
        listenApi.dispatch(NCRActions.setStatus('saving'));
        const response = await createNCRReport(
          action.payload.issueId,
          action.payload.report
        );
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            download(response[0], response[1]);
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Report Downloaded',
            });
          }
        }
        listenApi.dispatch(NCRActions.setStatus('completed'));
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
