import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as globalActions } from '../store/global';
import { Modal2 } from '../components/Modal';
import * as cx from 'classnames';
import { partnerRegKeySelector } from '../store/keyvault/keyvault.selectors';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';
import { Loader } from '../components/Loader';

export const ReviewPartnerPhaseTwoFormModal = (props: { partnerId: number, formType: 'F40' | 'Financial', close: (refresh: boolean) => void }) => {

  const [notes, setNotes] = React.useState('');
  const dispatch = useDispatch();

  const _markReviewed = () => {
    fetch(`api/vendor/reviewpartnerform`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ partnerId: props.partnerId, formType: props.formType, notes: notes })
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(globalActions.updateMessage('Review saved'));
          props.close(true);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  return (<Modal2 dialogStyle={{ marginTop: '38px' }}>
    <div className='modal-header'><h5 style={{marginBottom: '0px'}}>{props.formType} Review</h5></div>
    <div className='modal-body'>
      <div><b>Please enter any additional information or notes as part of your review.</b></div>
      <div>
        <textarea className='form-control' rows={3} onChange={(e) => setNotes(e.currentTarget.value)} value={notes}></textarea>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-green' onClick={_markReviewed}>Submit</button>
      <button className='btn btn-sm btn-outline-secondary' onClick={() => props.close(false)}>Close</button>
    </div>
  </Modal2>
  )
}

export const RejectPartnerPhaseTwoFormModal = (props: { partnerId: number, formType: 'F40' | 'Financial', close: (refresh: boolean) => void }) => {

  const [notes, setNotes] = React.useState('');
  const [notesAreValid, setNotesAreValid] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const dispatch = useDispatch();
  const apiKey = useSelector(partnerRegKeySelector);

  React.useEffect(() => {
    setNotesAreValid(notes.length >= 30);
  }, [notes])

  const _sendRejectionEmail = () => {
    const url = `${PartnerRegistrationUrl}/api/phasetwo/SendFormRejection`;

    fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ partnerId: props.partnerId, formType: props.formType, notes: notes })
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(globalActions.updateMessage('Rejection notice sent and form unlocked'));
          setSaving(false)
          props.close(true);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  const _reject = () => {
    setSaving(true);
    fetch(`api/vendor/rejectpartnerform`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ partnerId: props.partnerId, formType: props.formType, notes: notes })
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          _sendRejectionEmail();
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  return (<Modal2 dialogStyle={{ marginTop: '38px' }}>
    <Loader loading={saving} />
    <div className='modal-header'><h5 style={{ marginBottom: '0px' }}>{props.formType} Rejection</h5></div>
    <div className='modal-body'>
      <div><b>Please explain your reason for rejecting this form.</b></div>
      <div> This reason will be provided back to the partner. This will also unlock the form so they can continue editing.</div>
      <div>
        <textarea className={cx('form-control', !notesAreValid ? 'is-invalid' : '')} rows={3} onChange={(e) => setNotes(e.currentTarget.value)} value={notes}></textarea>
        {!notesAreValid && <small>{notes.length} / 30 characters minimum</small>}
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-red' onClick={_reject} disabled={!notesAreValid || saving}>Submit</button>
      <button className='btn btn-sm btn-outline-secondary' onClick={() => props.close(false)}>Close</button>
    </div>
  </Modal2>
  )
}
