import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Modal from '../../components/Modal';
import { CustomReportType } from '../../interfaces/enums';
import { CustomReportActions } from '../../store/customReport/customReport.reducer';

// Figure out how we should organize this to share easily
const FormGroupDiv = styled.div({
  display: 'flex',
  width: '100%',
  lineHeight: 1,
  '& .inline-label': {
    width: '150px',
    lineHeight: 2,
  },
  '& .inline-label.field-name': {
    width: '250px',
  },
  marginBottom: '15px',
});

export const AddReportForm = (props: { close: () => void; }) => {

  const [addForm, setAddForm] = React.useState({
    reportName: '',
    reportType: CustomReportType.Table
  })

  const dispatch = useDispatch();
  

  return (
    <Modal>
      <div className='modal-header'><h5>Create New Report</h5></div>
      <div className='modal-body'>
        <FormGroupDiv>
          <div className='inline-label'>Name</div>          
          <input className='form-control' value={addForm.reportName} onChange={(e) => setAddForm({ ...addForm, reportName: e.currentTarget.value }) } />
        </FormGroupDiv>
        <FormGroupDiv>
          <div className='inline-label'>Type</div>
          <select className='form-control' value={addForm.reportType.toString()} onChange={(e) => setAddForm({ ...addForm, reportType: parseInt(e.currentTarget.value) })}>
            <option className='form-control' value={CustomReportType.Table}>Table</option>
          </select>
        </FormGroupDiv>
      </div>
      <div className='modal-footer'>
        <div className='btn btn-sm btn-blue' onClick={() => dispatch(CustomReportActions.createReport(addForm)) }>Add</div>
        <div className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</div>
      </div>
    </Modal>
  )
}