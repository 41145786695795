import * as React from 'react'
import * as cx from 'classnames'

interface Props {
    className?: string;
    popupClass?: string;
    defaultEle?: JSX.Element;
    leftAlign?: boolean;
    children?: React.ReactNode
}

interface State {
    showDialog: boolean;
}

export default class PointedPopup extends React.PureComponent<Props, State> {
    private optionButton: HTMLDivElement | null
    private wrapperRef: HTMLDivElement | null

    constructor(props) {
        super(props)
        this.state = {
            showDialog: false
        }
        this.optionButton = null
        this.wrapperRef = null
        this.handleOutsideClick = this.handleOutsideClick.bind(this)
    }

    componentDidUpdate() {
        if (this.state.showDialog) {
            this._repositionDialog()
            document.addEventListener('mousedown', this.handleOutsideClick)
        }
        else document.removeEventListener('mousedown', this.handleOutsideClick)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick)
    }

    handleOutsideClick(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState({
                showDialog: false
            })
        }
    }

    _repositionDialog = () => {
        if (this.optionButton) {
            const boundingClientRect = this.optionButton.getBoundingClientRect()

            let posX = boundingClientRect.left, posY = boundingClientRect.top
            const btnWidth = this.optionButton.clientWidth, btnHeight = this.optionButton.clientHeight,
                dialog = document.querySelector('.popup-dialog') as HTMLDivElement,
                docWidth = window.innerWidth, docHeight = window.innerHeight;

            if (this.props.leftAlign) {
                if (posX + dialog.clientWidth >= docWidth) {
                    posX = posX - (dialog.clientWidth - btnWidth + 20);
                    dialog.classList.add('right-pointer')
                }
            }
            else {
                if (posX + btnWidth + dialog.clientWidth >= docWidth) posX = posX - dialog.clientWidth;// - 10;
                else posX = posX + btnWidth - 5 // Subtract 5 to match the 'left' property on the :before element
            }

            if (posY + btnHeight + dialog.clientHeight >= docHeight) posY = posY - dialog.clientHeight - 35;
            else posY = posY + btnHeight + 20 // Need to add the height of the :before property

            dialog.style.left = posX + 'px'
            dialog.style.top = posY + 'px';
        }
    }

    _toggleDialog = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        this.setState({ showDialog: !this.state.showDialog })
    }

    render() {
        const { className } = this.props;
        const { showDialog } = this.state;
            
        return (
            <div className={cx(className)} ref={r => this.wrapperRef = r}>
                {this.renderToggleEle()}                                    
                {showDialog && this.renderPopup()}
            </div>
        )
    }

    renderToggleEle() {
        const { defaultEle } = this.props;

        if (defaultEle) return (
            <div onClick={this._toggleDialog} ref={r => this.optionButton = r}>
                {defaultEle}
            </div>
        )
        const toggleEleClass = 'btn btn-sm btn-outline-secondary fas fa-option-horizontal'

        return (
            <div className={toggleEleClass}
                onClick={this._toggleDialog} ref={r => this.optionButton = r}
                style={{ top: '0px' }}></div>
        )
    }

    renderPopup() {
        const { children, popupClass } = this.props;
        return (
            <div className={cx('popup-dialog', popupClass)} >
                {children}
            </div>
        )
    }
}
