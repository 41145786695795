import * as React from 'react';
import { connect } from 'react-redux'
import { hasRole, isUserById, hasSubscription } from './auth';
import { RouteComponentProps } from 'react-router';
//import { myStore } from '../storeProvider';
import { ApplicationState } from '../store';
import * as UserStore from '../store/user'

/* Curried function that allows us to build role-based authorization components and pass components into it */
export const AuthorizationComponent = (allowedRoles: any) =>
    (WrappedComponent: any) => {
        class WithAuthorization extends React.Component<UserStore.Store & RouteComponentProps<any>, any> {

            render() {
                if (hasRole(this.props.user, allowedRoles)) {
                    return <WrappedComponent {...this.props} />
                } else {
                    return <div></div>
                }
            }
        }
        //@ts-ignore
        return connect(
            (state: ApplicationState) => state.user,
            UserStore.actionCreators
        // @ts-ignore
        )(WithAuthorization) as typeof WithAuthorization
    }

interface AuthEleProps {
    class?: string,
    style?: object,
    componentType?: string,
    onClick?: (e) => void,
    children?: React.ReactNode
}

type MyAuthEleProps =
    AuthEleProps
    & UserStore.Store

export const MyAuthElement = (allowedRoles: any, allowedIds?: string[], allowedSubs?: string[]) => {
    class AuthElement extends React.Component<MyAuthEleProps, any> {
        render() {
            const { componentType, user } = this.props;
            const className = this.props.class || '',
                style = this.props.style || {},
                component = componentType || 'div',
                onClick = this.props.onClick

            if (hasSubscription(user, allowedSubs) && (hasRole(user, allowedRoles) || isUserById(user, allowedIds || ""))) {
                return React.createElement(
                    component,
                    {                        
                        className: className,  
                        style: style,
                        onClick: onClick
                    } /*as Element*/,
                    this.props.children
                )
                //return <div className={this.props.class}>{this.props.children}</div>
            } else {
                return React.createElement(component)
            }
        }
    }
    return connect<UserStore.Store, any, AuthEleProps>(
        //@ts-ignore
        (state: ApplicationState) => state.user,
        {}
    )(AuthElement) //as typeof AuthElement
}

export const NoAuthElement = (forbiddenRoles: any) => {
    class AuthElement extends React.Component<MyAuthEleProps, any> {
        render() {
            const { componentType, user } = this.props;
            const className = this.props.class || '',
                component = componentType || 'div';
            if (!hasRole(user, forbiddenRoles)) {
                return React.createElement(
                    component, { className: className} as Element, this.props.children
                )
                //return <div className={this.props.class}>{this.props.children}</div>                
            } else {
                return React.createElement(component)
            }
        }
    }
    return connect<UserStore.Store, any, AuthEleProps>(
        //@ts-ignore
        (state: ApplicationState) => state.user,
        {}
    )(AuthElement) //as typeof AuthElement
}

export const UserAuthElement = (allowedUserIds: string[]) => {
    class AuthElement extends React.Component<MyAuthEleProps, any> {        
        render() {
            if (isUserById(this.props.user, allowedUserIds)) {
                return <div className={this.props.class}>{this.props.children}</div>
            }
            return <div></div>
        }
    }
    return connect<UserStore.Store, any, AuthEleProps>(
        //@ts-ignore
        (state: ApplicationState) => state.user,
        {}
    )(AuthElement)
}

interface RProps {
    roles?: any,
    subs?: any,
    allowedIds?: any,
    componentType?: string,
    children?: React.ReactNode
}

type RestrictedProps =
    RProps
    & UserStore.Store

class RComp extends React.Component<RestrictedProps, any> {
    render() {
        const { roles, subs, allowedIds, componentType, user } = this.props;
        const component = componentType || 'div';

        if (hasSubscription(user, subs || '') && (hasRole(user, roles || '') || isUserById(user, allowedIds || ''))) {
            return React.createElement(
                component,
                {
                
                } as Element,
                this.props.children
            )
        } else {
            return React.createElement(component)
        }        
    }
}

export const RestrictedComp = connect<UserStore.Store, any, RProps>(
    //@ts-ignore
    (state: ApplicationState) => state.user,
    {}
)(RComp)
