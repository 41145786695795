import React, { useRef, useState, useEffect } from "react"
import { MessageBox } from "src/components/MessageBox";
import { PurchaseOrderStatus } from "src/interfaces/enums";
import { ProcurementItem, ProjectDetailVm, PurchaseOrder } from "src/interfaces/interfaces";
import Modal, { Modal2 } from '../components/Modal'
import { SliderCheckbox } from "../components/SliderCheckbox";

interface PORequestProps {
  procurementItem: ProcurementItem;  
  onConfirm: () => void;
  onClose: () => void;  
}

export const ConfirmPORequestModal: React.FC<PORequestProps> = ({ procurementItem, onClose, onConfirm }) => {

  return <Modal2 dialogStyle={{marginTop: '3.5rem'}}>
    <div className='modal-header'><h4>Confirm PO Request</h4></div>
    <div className='modal-body'>
      <p>
        Please confirm that you would like to send a request to <b>{procurementItem.assigneeName}</b> for a purchase order for <b>{procurementItem.itemDescription}</b>.
      </p>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-green' onClick={onConfirm}>Confirm</button>
      <button className='btn btn-sm btn-outline-secondary' onClick={onClose}>Cancel</button>
    </div>
  </Modal2>
}

interface POAddModalProps {
  procurementItem: ProcurementItem;
  project: ProjectDetailVm;
  onClose: () => void;
  userId: string,
  onSave: (po: PurchaseOrder) => void;
}

export const POAddModal: React.FC<POAddModalProps> = ({ procurementItem, project, userId, onClose, onSave }) => {
    const [message, setMessage] = useState('');

    const poNumberRef = useRef<HTMLInputElement>(null);
    const releaseDateRef = useRef<HTMLInputElement>(null);
    const requestDateRef = useRef<HTMLInputElement>(null);
    const closedRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);

    const handleOnSave = () => {
        if (!poNumberRef.current?.value || !descriptionRef.current?.value) {
            setMessage("Error: Missing PO Number or Notes.")
        } else {
            const po: PurchaseOrder = {
                userId: userId,
                status: PurchaseOrderStatus.Draft,
                poNumber: poNumberRef.current.value,
                releaseDate: releaseDateRef.current?.value,
                requestDate: requestDateRef.current?.value,
                description: descriptionRef.current?.value,
                closed: closedRef.current?.checked ?? false,
                id: 0
            }
            onSave && onSave(po);
        }
    }


    return (
        <Modal style={{ display: "flex" }}>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
            <div className='modal-header'>
                <h4>Add Purchase Order</h4>
            </div>
            <div className='modal-body'>
                <div className='po-add-section'>
                    <div className='form-group'>
                        <span>PO Number</span>
                        <input ref={poNumberRef} id='po-number' name='po-number' className='form-control' />
                    </div>
                    <div className='form-group'>
                        <span>Request Date</span>
                        <input ref={requestDateRef} id='po-request' name='request' type={'date'} className='form-control' />
                    </div>
                    <div className='form-group'>
                        <span>Release Date</span>
                        <input ref={releaseDateRef} id='po-release' name='release' type={'date'} className='form-control' />
                    </div>
                    <div className='form-group'>
                        <span>Closed</span>
                        <br />
                        <label className='switch'>
                            <input ref={closedRef} id='po-closed' name='closed' type='checkbox' />
                            <span className='slider round' onClick={() => { }}></span>
                        </label>
                    </div>
                    <div className='form-group'>
                        <span>Status</span>
                        <br />
                        <label style={{ fontWeight: 700 }} id='po-status'>Draft</label>
                    </div>
                    <div className='form-group'>
                        <span>Notes</span>
                        <textarea ref={descriptionRef} id='po-notes' name='notes' className='form-control' />
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-green' onClick={handleOnSave}>Save</button>
                <span className='btn btn-sm btn-outline-secondary' onClick={onClose}>Close</span>
            </div>
        </Modal>

    )
}
