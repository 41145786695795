import React from 'react';
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
} from 'react-contexify';
import { KnownRoles } from 'src/auth/auth';
import { MyAuthElement } from 'src/auth/Authorization';
import PointedPopup from 'src/components/PointedPopup';

const MENU_ID = 'dfr-list-menu';

interface DfrListMenuProps {
  approvedNotification: boolean;
  onApproveNotification: () => void;
  submittedNotification: boolean;
  onSubmittedNotification: () => void;
  onLaborReportDownload: () => void;
  onReportDownload: () => void;
  onReportSearch: () => void;
  onPhotoAlbumView: () => void;
}

export const DfrListMenu = ({
  approvedNotification,
  onApproveNotification,
  submittedNotification,
  onSubmittedNotification,
  onLaborReportDownload,
  onReportDownload,
  onReportSearch,
  onPhotoAlbumView,
}: DfrListMenuProps) => {
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const CsgComponent = MyAuthElement([
    KnownRoles.SiteSuper,
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);

  return (
    <>
      <span
        style={{ fontSize: '11px', marginLeft: '10px' }}
        className="btn btn-sm fas btn-background-hover btn-outline-secondary fa-ellipsis"
        onClick={(e) =>
          show({
            event: e,
            position: {
              x: e.clientX - 200,
              y: e.clientY + 20,
            },
          })
        }
      ></span>
      <Menu style={{ marginRight: '100px' }} id={MENU_ID}>
        <Item title="Get Report" onClick={() => onReportDownload()}>
          <i
            style={{
              cursor: 'pointer !important',
              fontSize: 'unset',
              marginLeft: 'unset',
              height: 'unset',
            }}
            className="fas fa-download"
          ></i>
          &nbsp; Download Reports
        </Item>
        <Item title="Get Labor Report" onClick={() => onLaborReportDownload()}>
          <i className="fas fa-people-carry"></i>
          &nbsp; Download Labor Reports
        </Item>
        <Item title="Search Reports" onClick={() => onReportSearch()}>
          <i className="fas fa-search"></i>
          &nbsp; Search Reports
        </Item>
        <Item title="View Photo Album" onClick={() => onPhotoAlbumView()}>
          <i className="fas fa-camera"></i>
          &nbsp; View Photo Album
        </Item>
        <CsgComponent>
          <Item
            title={
              submittedNotification
                ? 'Stop receiving notifications when field reports are submitted on this project'
                : 'Receive email notifications when field reports on this project are submitted'
            }
            onClick={() => onSubmittedNotification()}
          >
            <i
              className={`fas ${
                submittedNotification ? 'fa-eye-slash' : 'fa-eye'
              }`}
            ></i>
            &nbsp; {submittedNotification ? 'Unsubscribe' : 'Subscribe'} to
            Submitted
          </Item>
        </CsgComponent>
        <Item
          title={
            approvedNotification
              ? 'Stop receiving notifications when field reports are approved on this project'
              : 'Receive email notifications when field reports on this project are approved'
          }
          onClick={() => onApproveNotification()}
        >
          <i
            className={`fas ${
              approvedNotification ? 'fa-eye-slash' : 'fa-eye'
            }`}
          ></i>
          &nbsp; {approvedNotification ? 'Unsubscribe' : 'Subscribe'} to
          Accepted
        </Item>
      </Menu>
    </>
  );
};
