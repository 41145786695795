import * as React from 'react';
import classnames from 'classnames';

export const styles = {
    hidden: {
        display: 'none'
    }
}

type TabContentProps = {
    for: string | number
    visibleStyle?: object,
    isVisible?: boolean,
    renderActiveTabContentOnly?: boolean,
    disableInlineStyles?: boolean,
    className?: string,
    visibleClassName?: string,
    style?: object,
    children?: React.ReactNode
}

export class TabContent extends React.Component<TabContentProps, any> {
    static displayName = 'TabContent';

    //static propTypes = { Do we need this?
    //    children: JSX.Element        
    //}

    canRenderChildren() {
        return this.props.isVisible || !this.props.renderActiveTabContentOnly
    }

    render() {
        const visibleStyle = this.props.visibleStyle || {};
        const displayStyle = this.props.isVisible ? visibleStyle : styles.hidden
        const className = this.props.className || 'tab-content'
        const visibleClassName = this.props.visibleClassName || 'tab-content-visible'

        return (
            <div
                id={`tabpanel-${this.props.for}`}
                role='tabpanel'
                aria-labelledby={`tab-${this.props.for}`}
                className={classnames({ [className]: true, [visibleClassName]: !!this.props.isVisible })}
                style={(!this.props.disableInlineStyles && { ...this.props.style, ...displayStyle }) || undefined }
            >
                {this.canRenderChildren() && this.props.children}
            </div>
        )
    }
}