import * as React from 'react';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as DetailStore from '../store/projectDetail';
import * as Dfr from '../store/dfr';
import { Tabs, TabLink, TabContent } from '../tabs';
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import DfrPersonnel from './DfrPersonnel';
import DfrEquipment from './DfrEquipment';
import DfrPhotos from './DfrPhotos';
import DfrDescription from './DfrDescription';
import DfrEvent from './DfrEvent';
import DfrVisitor from './DfrVisitor';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles, hasRole } from '../auth/auth';
import { FileDownloader } from '../components/FileDownloader';
import Modal from '../components/Modal';
import { ListItem, UserInfo } from '../interfaces/interfaces';
import { BackLink } from '../components/BackLink';
import { DfrExternalFile } from './DfrExternalFile';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { EventHistoryLog } from '../components/EventHistoryLog';
import PointedPopup from '../components/PointedPopup';
import { DfrSafetyKPI } from './DfrSafetyKPI';

interface State {
  popUpModal: boolean;
  showPhotoSelector: boolean;
  showRejectModal: boolean;
  showDeleteModal: boolean;
  showEditTitleModal: boolean;
  selectedPhotos: number[];
}

type Props = Dfr.Store &
  typeof Dfr.actionCreators & {
    dfrId: number;
    deleteCallback: () => any;
    refreshCallback: () => any;
    currentUser?: UserInfo;
  };

class DfrMain extends React.Component<Props, State> {
  activeTabStyle = {
    backgroundColor: 'rgb(0,99,66)',
    border: 'solid 0.5px rgb(0,56,119)',
    color: 'white',
  };

  constructor(props: any) {
    super(props);
    this.state = {
      popUpModal: false,
      showPhotoSelector: false,
      showRejectModal: false,
      showDeleteModal: false,
      showEditTitleModal: false,
      selectedPhotos: [],
    };
  }

  componentDidMount() {
    // grabbing the url and getting the dfr id
    this.props.getDfr(this.props.dfrId);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      /*prevProps this.props.id !== 0 && this.props.id.toString() */ prevProps.dfrId !==
      this.props.dfrId
    ) {
      this.props.getDfr(this.props.dfrId);
      this.setState({
        ...this.state,
        popUpModal: false,
        showPhotoSelector: false,
        showRejectModal: false,
        showDeleteModal: false,
        showEditTitleModal: false,
        selectedPhotos: [],
      });
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  _toggleConfirmationModal = () => {
    this.setState({
      popUpModal: !this.state.popUpModal,
    });
  };

  _togglePhotoSelector = () => {
    this.setState({
      showPhotoSelector: !this.state.showPhotoSelector,
    });
  };

  _toggleRejectModal = () => {
    this.setState({
      showRejectModal: !this.state.showRejectModal,
    });
  };

  _toggleEditTitleModal = () => {
    this.setState({
      showEditTitleModal: !this.state.showEditTitleModal,
    });
  };

  _approve = () => {
    const selector = document.getElementById(
      'photo-selector'
    ) as HTMLDivElement;
    const checkedPhotos = selector.querySelectorAll(
      'input[type="checkBox"]:checked'
    );

    const newIds: number[] = [];
    [].forEach.call(checkedPhotos, (checked: HTMLInputElement) => {
      const id = parseInt(checked.value);
      newIds.push(id);
    });

    this.setState({
      selectedPhotos: newIds,
      showPhotoSelector: false,
      popUpModal: true,
    });
  };

  _submit = () => {
    this.props.submit(this.props.id);
    if (this.props.refreshCallback) this.props.refreshCallback();
    this.setState({ popUpModal: false });
  };

  _lock = () => {
    this.props.lock(
      this.props.id,
      this.state.selectedPhotos,
      this.props.deleteCallback
    );
    this.setState({ popUpModal: false });
  };

  _reject = () => {
    const rejectReason = document.getElementById(
      'reject_reason'
    ) as HTMLTextAreaElement;
    this.props.reject(this.props.id, rejectReason.value);
    this.setState({ showRejectModal: false });
    setTimeout(() => {
      this.props.deleteCallback();
    }, 1500);
  };

  _updateSelectedPhotos = () => {
    const selector = document.getElementById(
      'photo-selector'
    ) as HTMLDivElement;
    const checkedPhotos = selector.querySelectorAll(
      'input[type="checkBox"]:checked'
    );

    const newIds: number[] = [];
    [].forEach.call(checkedPhotos, (checked: HTMLInputElement) => {
      const id = parseInt(checked.value);
      newIds.push(id);
    });

    this.props.lock(this.props.id, newIds);

    this.setState({
      selectedPhotos: newIds,
      showPhotoSelector: false,
    });
  };

  _toggleSelectAllPhotos = () => {
    const chkBxs = document.querySelectorAll(
      '#photo-selector .dfr_photoBorder input[type="checkbox"]'
    );
    const checked = document.querySelectorAll(
      '#photo-selector .dfr_photoBorder input[type="checkbox"]:checked'
    );
    if (chkBxs) {
      const newIds: number[] = [];
      // If any are checked we will just uncheck them all
      const unCheck = checked && checked.length > 0;
      [].forEach.call(chkBxs, (bx: HTMLInputElement) => {
        bx.checked = !unCheck;
        if (!unCheck) newIds.push(parseInt(bx.value));
      });

      this.setState({ selectedPhotos: newIds });
    }
  };

  _toggleDeleteModal = () =>
    this.setState({ showDeleteModal: !this.state.showDeleteModal });

  _editDFR = () => {
    const date = document.getElementById('new-dfr-date') as HTMLInputElement,
      reportName = document.getElementById(
        'new-report-name'
      ) as HTMLInputElement;

    const splitdate = date.value.split('-'),
      y = splitdate[0],
      m = splitdate[1],
      d = splitdate[2];
    const rearrangeddate = `${m}-${d}-${y}`;
    this.props.editDateAndReportName(rearrangeddate, reportName.value);
    console.log(rearrangeddate);
    this.setState({ showEditTitleModal: !this.state.showEditTitleModal });
  };

  _deleteDFR = () => {
    fetch(`api/DailyFieldReport/DeleteDFR?id=${this.props.id}`, {
      method: 'DELETE',
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.props.deleteCallback();
      });
  };

  render() {
    const ReporterComponent = MyAuthElement(
      [KnownRoles.Admin],
      [this.props.userId]
    );
    //const PMComponent = MyAuthElement([KnownRoles.Admin], [this.props.pmId, this.props.generalSuperId])
    const ApproverComponent = MyAuthElement(
        [''],
        [
          this.props.isLocked || this.props.pmApproved ? '' : this.props.pmId,
          this.props.isLocked ||
          this.props.generalSuperApproved ||
          this.props.dailyFieldReportTemplateId
            ? ''
            : this.props.generalSuperId,
          this.props.isLocked || this.props.creatorApproved
            ? ''
            : this.props.creatorId,
        ]
      ),
      PMComponent = MyAuthElement(
        [KnownRoles.Admin],
        [
          this.props.pmId,
          this.props.dailyFieldReportTemplateId
            ? ''
            : this.props.generalSuperId,
          this.props.creatorId,
        ]
      );

    return this.props.date ? (
      <div key={this.props.id}>
        {this.props.isLoading ? <Loader loading={this.props.isLoading} /> : ''}
        <MessageBox
          message={this.props.message}
          clearMessage={this.props.clearMessage}
        />
        {this.state.popUpModal && this._lockingModal()}
        {this.state.showPhotoSelector && this.renderPhotoSelector()}
        {this.state.showRejectModal && this.renderRejectModal()}
        {this.state.showDeleteModal && this.renderDeleteModal()}
        {this.state.showEditTitleModal && this.editDailyFieldTitleModal()}
        <div /*style={{ 'paddingRight': '2%' }}*/>
          <div className="pageHeader">
            {this.props.projectNumber} - Daily Field Report - {this.props.date}{' '}
            {this.props.reportName && this.props.reportName.length
              ? ` - ${this.props.reportName}`
              : ''}
            {this.editDailyFieldTitleBtn()}
          </div>
          <h5>{this.props.userFullName}</h5>
          <hr style={{ margin: '0.5rem 0' }} />
        </div>
        <div className="dfr-top-buttons">
          <div
            className="left-buttons"
            style={{ display: 'flex', left: '0px' }}
          >
            <PointedPopup
              popupClass="gray"
              defaultEle={
                <span className="btn btn-sm fas btn-background-hover fa-download"></span>
              }
            >
              <div style={{ display: 'flex', textAlign: 'center' }}>
                <div
                  style={{ padding: '2px 5px', borderRight: '1px solid white' }}
                >
                  <FileDownloader
                    actionPath="api/report/dfrPdf"
                    queryParams={{ id: this.props.id, includePhotos: false }}
                    fileType="PDF"
                    className="btn btn-sm btn-background-hover"
                  />
                  <div>
                    <small>
                      Summary <br /> (no photos)
                    </small>
                  </div>
                </div>
                <div style={{ padding: '2px 5px' }}>
                  <FileDownloader
                    actionPath="api/report/dfrPdf"
                    queryParams={{ id: this.props.id, includePhotos: true }}
                    fileType="PDF"
                    className="btn btn-sm btn-background-hover"
                  />
                  <div>
                    <small>
                      Detail <br /> (with photos)
                    </small>
                  </div>
                </div>
              </div>
            </PointedPopup>
            {this.props.previousId && !this.props.refreshCallback ? (
              <NavLink
                to={`/dfr/${this.props.previousId + ''}`}
                title="Go to previous report"
                style={{ left: '36px' }}
              >
                <span className="btn btn-sm btn-background-hover fas fa-arrow-left"></span>
              </NavLink>
            ) : (
              []
            )}
          </div>
          <div
            className="right-buttons"
            style={{ display: 'flex', right: '0px' }}
          >
            {this.props.nextId && !this.props.refreshCallback ? (
              <NavLink
                to={`/dfr/${this.props.nextId + ''}`}
                title="Go to next field report"
                style={{ right: '110px' }}
              >
                <span className="btn btn-sm btn-background-hover fas fa-arrow-right"></span>
              </NavLink>
            ) : (
              []
            )}
            {!this.props.isSubmitted && (
              <span
                className="btn btn-sm btn-background-hover far fa-trash-alt"
                onClick={this._toggleDeleteModal}
              ></span>
            )}

            {/*<NavLink to={`/dfrPrint/${this.props.id}`} activeClassName='active'>
                        <span id="dfr_print" className="btn btn-sm btn-info fas fa-print mobile-hide"></span>
                    </NavLink>*/}
            {this.props.isSubmitted ? (
              <React.Fragment>
                <PMComponent>
                  <span
                    id="approve_lockBtn"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={this._togglePhotoSelector}
                  >
                    <span id="approveText">Alter Selected Photos</span>
                  </span>
                </PMComponent>
                <ApproverComponent key={this.props.dfrId}>
                  <span
                    id="rejectBtn"
                    className="btn btn-sm btn-outline-danger"
                    onClick={this._toggleRejectModal}
                  >
                    <span id="rejectText">Reject</span>
                  </span>
                  <span
                    id="approve_lockBtn"
                    className="btn btn-sm btn-green inverse"
                    onClick={this._togglePhotoSelector}
                  >
                    <span id="approveText">Approve</span>
                  </span>
                </ApproverComponent>
              </React.Fragment>
            ) : (
              <ReporterComponent>
                <span
                  id="approve_lockBtn"
                  className="btn btn-sm btn-blue"
                  onClick={this._toggleConfirmationModal}
                >
                  <span id="submitText">Submit</span>
                </span>
              </ReporterComponent>
            )}
          </div>
        </div>
        <div>
          <Tabs
            name="admin-tabs"
            onChange={(tab: string) => {}}
            activeLinkStyle={this.activeTabStyle}
          >
            <div className="tab-links bordered">
              {!this.props.isExternal && (
                <TabLink to="description" component="span">
                  <span>Description</span>
                </TabLink>
              )}
              {!this.props.isExternal && (
                <TabLink to="personnel" component="span">
                  <span>Personnel & Equipment</span>
                </TabLink>
              )}
              {!this.props.isExternal && (
                <TabLink to="events" component="span">
                  <span>Events</span>
                </TabLink>
              )}
              {this.props.isExternal && (
                <TabLink to="externalFile" component="span">
                  <span>Report</span>
                </TabLink>
              )}
              <TabLink to="kpi" component="span">
                <span>Safety & Observations</span>
              </TabLink>
              <TabLink to="photos" component="span">
                <span>Photos</span>
              </TabLink>
              <TabLink to="log" component="span">
                <span>History Log</span>
              </TabLink>
            </div>
            <div
              className="content bordered custom-scrollbar"
              style={{ overflowY: 'auto', height: 'calc(100vh - 300px)' }}
            >
              <TabContent for="description">
                <div className="col-sm-12">
                  <DfrDescription />
                </div>
              </TabContent>
              <TabContent for="personnel">
                <div className="col-sm-12">
                  <DfrPersonnel />
                  <DfrVisitor />
                </div>
                <div className="col-sm-7">
                  <DfrEquipment />
                  <div style={{ height: '40px' }}></div>
                </div>
              </TabContent>
              <TabContent for="events">
                <div className="col-sm-9">
                  {/* @ts-ignore */}
                  <DfrEvent />
                </div>
              </TabContent>
              <TabContent for="externalFile">
                <div className="col-sm-9">
                  <DfrExternalFile />
                </div>
              </TabContent>
              <TabContent for="kpi">
                <div className="col-sm-12">
                  <DfrSafetyKPI
                    isDfrLocked={this.props.isLocked}
                    entries={this.props.kpiEntries}
                  />
                </div>
              </TabContent>
              <TabContent for="photos">
                <div className="col-sm-12">
                  <DfrPhotos currentUser={this.props.currentUser} />
                </div>
              </TabContent>
              <TabContent for="log">
                <div className="col-sm-12">
                  <EventHistoryLog
                    objectId={this.props.id}
                    objectName={'DailyFieldReport'}
                  />
                </div>
              </TabContent>
            </div>
          </Tabs>
        </div>
      </div>
    ) : (
      <>
        <StandardGridLoader rowContentHeight={55} rowCount={1} rowPadding={3} />
        <StandardGridLoader rowContentHeight={35} rowCount={2} rowPadding={3} />
        <StandardGridLoader
          rowContentHeight={450}
          rowCount={2}
          rowPadding={4}
        />
      </>
    );
  }

  private _lockingModal = () => {
    const canLock =
        new Date() > new Date(this.props.date) || new Date().getHours() >= 15,
      bodyText = canLock
        ? this.props.isSubmitted
          ? 'Are you sure you want to approve this field report?'
          : 'Are you sure you want to submit this field report?'
        : "Error: Field Reports cannot be submitted before 3pm of the report's date";
    return (
      <Modal id="dfrModal">
        <div className="modal-header">
          <h4>Lock Field Report</h4>
        </div>
        <div className="modal-body">
          <h5 className="dfr_modal-text">{bodyText}</h5>
        </div>
        <div className="modal-footer">
          {canLock && (
            <div
              className="btn btn-sm btn-green"
              onClick={() => {
                if (this.props.kpiEntries.some((x) => x.value == null)) {
                  this.props.error(
                    "Error: Please verify all Safety KPI's have a response."
                  );
                } else if (
                  this.props.kpiEntries.some(
                    (x) => x.value == 'Yes' && x.quantity <= 0
                  )
                ) {
                  this.props.error(
                    "Error: Please verify any Safety KPI's marked as 'Yes' have the correct Quantity reported."
                  );
                } else {
                  this.props.isSubmitted ? this._lock() : this._submit();
                }
              }}
            >
              {this.props.isSubmitted ? 'Approve' : 'Submit'}
            </div>
          )}
          <div
            className="btn btn-sm btn-outline-secondary"
            onClick={this._toggleConfirmationModal}
          >
            Close
          </div>
        </div>
      </Modal>
    );
  };

  private renderPhotoSelector = () => {
    const photos = this.props.photos.map((x, i) => {
      return (
        <div key={i} className="dfr_photoBorder">
          <div>
            <img className="dfr_ImgTag" src={x.filePath} alt="" />
          </div>
          <p>
            {x.fileName}
            {x.includeForClient && <span className="fas fa-check"></span>}
          </p>
          <label>{x.description || ''}</label> <br />
          <input
            type="checkbox"
            className="dfr-photo-select"
            value={x.id}
            defaultChecked={x.includeForClient}
          />
        </div>
      );
    });

    return (
      <Modal id="photo-selector">
        <div className="modal-header">
          <h4 style={{ display: 'inline-flex', width: '95%' }}>
            Select Approved Photos
          </h4>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-check"
            onClick={this._toggleSelectAllPhotos}
            title="Select / De-select All"
          ></span>
        </div>
        <div className="modal-body">
          <div>{photos}</div>
        </div>
        <div className="modal-footer">
          <span
            className="btn btn-sm btn-blue"
            onClick={
              this.props.isLocked ? this._updateSelectedPhotos : this._approve
            }
          >
            Continue
          </span>
          <span
            className="btn btn-sm btn-outline-secondary"
            onClick={this._togglePhotoSelector}
          >
            Cancel
          </span>
        </div>
      </Modal>
    );
  };

  private renderRejectModal = () => {
    return (
      <Modal id="photo-selector">
        <div className="modal-header">
          <h4>Field Report Rejection</h4>
        </div>
        <div className="modal-body">
          <h5>Please enter a reason for rejecting this report</h5>
          <textarea id="reject_reason" className="form-control"></textarea>
        </div>
        <div className="modal-footer">
          <span className="btn btn-sm btn-danger" onClick={this._reject}>
            Submit
          </span>
          <span
            className="btn btn-sm btn-outline-secondary"
            onClick={this._toggleRejectModal}
          >
            Cancel
          </span>
        </div>
      </Modal>
    );
  };

  private renderDeleteModal = () => {
    return (
      <Modal>
        <div className="modal-header">
          <h4>Delete Daily Field Report</h4>
        </div>
        <div className="modal-body">
          <strong>Are you sure you want to delete this report?</strong>
        </div>
        <div className="modal-footer">
          <span className="btn btn-sm btn-danger" onClick={this._deleteDFR}>
            Delete
          </span>
          <span
            className="btn btn-sm btn-outline-secondary"
            onClick={this._toggleDeleteModal}
          >
            Cancel
          </span>
        </div>
      </Modal>
    );
  };

  private editDailyFieldTitleBtn = () => {
    return (
      <span>
        <i
          className="far fa-edit"
          title="Edit Field Report Title"
          onClick={() => this._toggleEditTitleModal()}
        ></i>
      </span>
    );
  };

  private editDailyFieldTitleModal = () => {
    const styles = {
      minHeight: '7vh',
      maxHeight: '50vh',
    };

    const cd = new Date().toISOString().substr(0, 10);
    const rearrangedDate = new Date(this.props.date)
      .toISOString()
      .substr(0, 10);

    console.log('modal', rearrangedDate);

    return (
      <Modal>
        <div className="modal-header">
          <h4>Edit New Daily Field Report</h4>
        </div>
        <div className="modal-body" style={styles}>
          <label>Please select a date</label>
          <br />
          <input
            id="new-dfr-date"
            type="date"
            className="form-control"
            max={cd}
            defaultValue={rearrangedDate}
          />
          <br />
          <br />
          <label>Report Name / Description (optional)</label>
          <input
            className="form-control"
            id="new-report-name"
            defaultValue={this.props.reportName}
          />
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-sm btn-blue"
            onClick={() => this._editDFR()}
          >
            Edit
          </button>
          <button
            style={{ marginLeft: '1%' }}
            className="btn btn-sm btn-outline-secondary"
            onClick={() => this._toggleEditTitleModal()}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  };
}

//@ts-ignore
export default connect(
  (state: ApplicationState) => state.dfr,
  Dfr.actionCreators
)(DfrMain) as typeof DfrMain;
