import { AxiosResponse } from "axios";
import { ListItem, UserInfo } from "src/interfaces/interfaces";
import { api } from "../api";

export const getAllUsers = async () => {
  try {
    const response: AxiosResponse<ListItem[]> = await api.get(
      "api/User/GetAll?activeOnly=false"
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch { console.warn("error"); }
  return [];
};

export const getActiveUsers = async () => {
  try {
    const response: AxiosResponse<ListItem[]> = await api.get(
      "api/User/GetAll?activeOnly=true"
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch { console.warn("error"); }
  return [];
};
