import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RFQActions as rfqActions } from '../store/rfqs/index';
import { RFQPostModel, ProcurementItem, VendorVm, RFQRecipient, IDocumentInfo, ProcurementQuote, ListItem } from '../interfaces/interfaces';
import { JoinedList } from '../components/JoinedList';
import { FieldFilterableSelect, FieldInput, FieldTextArea } from '../components/FieldComponents/index';
import { Modal2 } from '../components/Modal';
import { Loader } from '../components/Loader';
import { ApplicationState } from '../store/index';
import { vendorsAndSubsSelector } from '../store/vendors/vendors.selectors';
import { VendorsActions } from '../store/vendors/index';

interface Props {
  item: ProcurementItem;
  close: () => void;
}

export const RFQAddModal: React.FC<Props> = ({ item, close }) => {
  const dispatch = useDispatch();

  const [rfq, setRfq] = React.useState<RFQPostModel>({
    recipients: [] as RFQRecipient[],
    rfqDescription: '',
    dueDate: item.rfqDueDate,
    specAttached: false,
    spec: null,
    deliveryRequiredBy: item.onSiteDueDate,
    specialInstructions: '',
    itemDescription: item.itemDescription,
    projectId: item.projectId,
    send: false,
    bccProcurementManager: true,
    itemId: item.id,
  });

  const [disabled, setDisabled] = React.useState(true);
  const [showRecipientModal, setShowRecipientModal] = React.useState(false);
  const [editingRecipientIdx, setEditingRecipientIdx] = React.useState<number | null>(null);



  React.useEffect(() => {
    const isDisabled = !rfq.rfqDescription || !rfq.dueDate || !rfq.itemDescription;
    setDisabled(isDisabled);
  }, [rfq]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.type === 'checkbox' ? (e.currentTarget as HTMLInputElement).checked : e.currentTarget.value;
    setRfq({ ...rfq, [field]: value });
  };

  const _handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.currentTarget.name;
    const files = e.currentTarget.files || null;
    setRfq({ ...rfq, [field]: files });
  };

  const _handleRecipientAdd = (recipient: RFQRecipient) => {
    const isDuplicate = rfq.recipients.some((r) => r.vendorId === recipient.vendorId);

    if (!isDuplicate) {
      setRfq({ ...rfq, recipients: rfq.recipients.concat([recipient]) });
    } else {
      alert("This recipient has already been added.")
    }
    // Close the recipient modal
    setShowRecipientModal(false)
  };

  const _handleRecipientEdit = (recipient: RFQRecipient) => {
    if (editingRecipientIdx !== null) {
      // Update the recipients array with the updated recipient
      const updatedRecipients = [...rfq.recipients];
      updatedRecipients[editingRecipientIdx] = recipient;

      // Update the RFQ state with the updated recipients array
      setRfq({ ...rfq, recipients: updatedRecipients });
      // Set the editing recipient index to null
      setEditingRecipientIdx(null)
    }
  };

  const _handleRemoveRecipient = (recipient: RFQRecipient) => {
    const updatedRecipients = rfq.recipients.filter((r) => r !== recipient);
    setRfq({ ...rfq, recipients: updatedRecipients });
  };

  const handleSave = (rfqPostModel: RFQPostModel) => {
    dispatch(rfqActions.createRFQ(rfqPostModel));
  };
  //Save and Send Handler

  const handleSubmit = (send: boolean) => {        
    if (!disabled) {
      const updatedRfq: RFQPostModel = { ...rfq, send: send };
      setRfq(updatedRfq);
      console.log("Updated RFQ:", updatedRfq);
      handleSave(updatedRfq);
      close();
    }
  };

  return (
    <Modal2>
      <Loader loading={false} />
      {(showRecipientModal || (editingRecipientIdx !== null)) && (

        <RFQRecipientModal
          r={editingRecipientIdx !== null ? rfq.recipients[editingRecipientIdx] : null}
          saveCallback={editingRecipientIdx ? _handleRecipientEdit : _handleRecipientAdd}
          close={() => {
            setShowRecipientModal(false)
            setEditingRecipientIdx(null)
          }}
        />
      )}
      <div className="modal-header">
        <h5>Add RFQ</h5>
      </div>
      <div className="modal-body">

        <div className="form-group">
          <label>Item Description*</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="text"
            field="itemDescription"
            value={rfq.itemDescription}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>RFQ Description*</label>
          <FieldTextArea
            id={0}
            canEdit={true}
            field="rfqDescription"
            value={rfq.rfqDescription}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Due Date*</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="date"
            field="dueDate"
            value={rfq.dueDate}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Spec Attached</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="checkbox"
            field="specAttached"
            value={rfq.specAttached}
            update={handleInputChange}
          />
        </div>
        {rfq.specAttached ? <div className="form-group">
          <label>Spec</label>
          <input
            type="file"
            name='spec'
            onChange={_handleFileInputChange}            
          />
        </div> : []}
        <div className="form-group">
          <label>Delivery Required By</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="date"
            field="deliveryRequiredBy"
            value={rfq.deliveryRequiredBy}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Special Instructions</label>
          <FieldTextArea
            id={0}
            canEdit={true}
            field="specialInstructions"
            value={rfq.specialInstructions}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Bcc Sender</label>
          <FieldInput
            id={0}
            canEdit={true}
            dataType="checkbox"
            field="bccProcurementManager"
            value={rfq.bccProcurementManager}
            update={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Recipients</label>
          <div className=''>
            {rfq.recipients.length > 0 && (
              rfq.recipients.map((r, idx) => (
                <div key={idx} className="recipient-item">
                  <div className="recipient-info" />
                  <span className="contact-name"><b>{r.contactName} </b> with </span>
                  <span className="company-name"><b>{r.companyName}</b> </span>
                  {/*<span className="edit-recipient" onClick={() => setEditingRecipientIdx(idx)}/>*/}
                  <span className="btn btn-x-sm fas fa-pencil btn-background-hover" onClick={() => setEditingRecipientIdx(idx)} />
                  {/*<span className="remove-recipient" onClick={() => _handleRemoveRecipient(r)}/>*/}
                  <span className="btn btn-x-sm fas fa-trash-alt btn-background-hover" onClick={() => _handleRemoveRecipient(r)} />


                  <div className="recipient-actions">
                    {/*<button className="edit-recipient" onClick={() => setEditingRecipientIdx(idx)}>*/}
                    {/*  <i className="fa fa-pencil"></i>*/}
                    {/*</button>*/}
                    {/*<button className="remove-recipient" onClick={() => _handleRemoveRecipient(r)}>*/}
                    {/*  <i className="fa fa-trash-alt"></i>*/}
                    {/*</button>*/}
                  </div>
                </div>
              ))
            )}
            <button className="btn btn-x-sm btn-blue" onClick={() => setShowRecipientModal(true)}>
              Add Recipient
            </button>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="modal-footer">
          <button className="btn btn-x-sm btn-blue" disabled={disabled} onClick={() => handleSubmit(true)}>Save and Send</button>
          <button className="btn btn-x-sm btn-blue" disabled={disabled} onClick={() => handleSubmit(false)}>Save</button>
          {/*// Close the modal without making any changes*/}
          {/*<button className="btn btn-sm btn-blue" disabled={disabled} onClick={(e) => {e.preventDefault(); */}
          {/*  closeModal();</button>*/}
          {/*}}>*/}
          <button className="btn btn-x-sm btn-outline-secondary" onClick={close}> Cancel
          </button>
        </div>
      </div>
    </Modal2>
  );
};

export const RFQRecipientModal = (props: {
  r: RFQRecipient | null;
  saveCallback: (r: RFQRecipient) => void;
  close: () => void;
}) => {
  const vendors: VendorVm[] = useSelector(vendorsAndSubsSelector);

  const { saveCallback, r, close } = props;

  const [recipient, setRecipient] = React.useState({
    vendorId: r ? r.vendorId : null,
    companyName: r ? r.companyName : '',
    contactEmail: r ? r.contactEmail : '',
    contactName: r ? r.contactName : '',
    contactPhone: r ? r.contactPhone : '',
  } as RFQRecipient);

  const _handleVendorChange = (id: string) => {
    const vendor = vendors.find(x => x.id.toString() === id);
    if (vendor !== undefined) {
      setRecipient({ ...recipient, vendorId: vendor.id, companyName: vendor.name, contactName: vendor.contactName, contactEmail: vendor.email, contactPhone: vendor.phone })
    }
  }
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (vendors.length === 0) {
      dispatch(VendorsActions.getVendorsAndSubs());
    }
  }, []);

  return (
    // 1. Build the modal (Modal2) with header, body, and footer for buttons
    <Modal2>
      <div className="modal-header">
        <h5>Add Vendors</h5>
      </div>


      <div className="modal-body">
        <form>
          <div className="form-group">
            <label>Vendor</label>
            <FieldFilterableSelect
              value={recipient.vendorId?.toString()}
              update={(f, v, i) => _handleVendorChange(v)}
              id={0}
              field="vendorId"
              canEdit={true}
              items={vendors.map((vendor) => new ListItem(vendor.id.toString(), vendor.name))} />

          </div>
          <div className="form-group">
            <label>Company Name*</label>
            <input type="text" className="form-control" value={recipient.companyName}
              onChange={(e) => setRecipient({ ...recipient, companyName: e.currentTarget.value })} />
          </div>
          <div className="form-group">
            <label>Contact Email*</label>
            <input type="email" className="form-control" value={recipient.contactEmail}
              onChange={(e) => setRecipient({ ...recipient, contactEmail: e.currentTarget.value })} />
          </div>
          <div className="form-group">
            <label>Contact Name*</label>
            <input type="text" className="form-control" value={recipient.contactName}
              onChange={(e) => setRecipient({ ...recipient, contactName: e.currentTarget.value })}
            />
          </div>
          <div className="form-group">
            <label>Contact Phone</label>
            <input type="tel" className="form-control" value={recipient.contactPhone}
              onChange={(e) => setRecipient({ ...recipient, contactPhone: e.currentTarget.value })} />
          </div>
        </form>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-x-sm btn-blue"
          disabled={recipient.companyName === '' || recipient.contactEmail === '' || recipient.contactName === ''}
          onClick={() => saveCallback(recipient)}>
          Save
        </button>
        <button className="btn btn-x-sm btn-outline-secondary" onClick={close}>
          Close
        </button>
      </div>
    </Modal2>
  );
}