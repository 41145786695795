import * as React from 'react';
import { NavLink } from 'react-router-dom'

interface Props {
    link: string,
    title?: string
}

export const BackLink = (props: Props) => {

    return (
        <NavLink className='back-link btn btn-sm btn-outline-secondary' to={props.link} title={props.title}>
            {'< Back'}
        </NavLink>
    )
}