import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';

import {FileExplorer} from '../components/FileExplorer';
import { MessageBox } from '../components/MessageBox';
import { ConnectedFileExplorer } from 'src/components/ConnectedFileExplorer';

interface State {
    message?: string;
}

type Props =
    RouteComponentProps<any>

export default class GlobalFileExplorer extends React.Component<Props, any> {

    startingDir = this.props.location.hash.substring(1);

    render() {
        
        return (
            <div className='global-file-explorer'>
                <div className='pageHeader'>SiteVue Explorer</div>
                <ConnectedFileExplorer
                    breadCrumbStartLevel={0}
                    canSubscribeUsers={false}
                    params={{}}
                    homeDir=''
                    startingDir={this.startingDir}
                    droppableId={'global-file-explorer-droppable'}
                    draggableId={'global-file-explorer-draggable'}
                    />
            </div>
        )
    }
}