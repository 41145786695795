import * as React from 'react';

interface Props {
  photos: string[];
  startingIndex: number;
  close: () => void;
}

interface State {
  displayIndex: number;
}

export default class PhotoCarousel extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      displayIndex: 0,
    };
  }

  componentDidMount() {
    this.setState({
      displayIndex: this.props.startingIndex,
    });
  }

  componentDidUpdate() {
    this._centerImageAndLabel();
  }

  _centerImageAndLabel = () => {
    // use setTimeout to allow time for pulling in the image if its not there yet
    setTimeout(() => {
      const carousel = document.querySelector(
          '.photo-carousel'
        ) as HTMLDivElement,
        container = document.querySelector(
          '.image-container'
        ) as HTMLDivElement,
        image = document.querySelector('#myImg') as HTMLImageElement,
        label = carousel.querySelector('label') as HTMLLabelElement,
        imageTop = (carousel.clientHeight - image.clientHeight) / 2,
        imageLeft = (carousel.clientWidth - image.clientWidth) / 2,
        containerHeight = Math.min(
          image.clientHeight,
          0.9 * carousel.clientHeight
        );

      container.style.height = containerHeight + 'px';
      container.style.top =
        Math.max(imageTop, 0.05 * carousel.clientHeight) + 'px';
      container.style.left = imageLeft + 'px';

      label.style.top =
        containerHeight +
        Math.max(imageTop, 0.05 * carousel.clientHeight) +
        10 +
        'px';
      label.style.left =
        imageLeft + image.clientWidth / 2 - label.clientWidth / 2 + 'px';
    }, 500);
  };

  _scrollPrevious = () => {
    const { displayIndex } = this.state;

    if (displayIndex === 0)
      this.setState({ displayIndex: this.props.photos.length - 1 });
    else this.setState({ displayIndex: displayIndex - 1 });
  };

  _scrollNext = () => {
    const { displayIndex } = this.state;

    if (displayIndex === this.props.photos.length - 1)
      this.setState({ displayIndex: 0 });
    else this.setState({ displayIndex: displayIndex + 1 });
  };

  render() {
    const { photos } = this.props,
      { displayIndex } = this.state,
      pathSplitChar = photos[displayIndex].indexOf('/') !== -1 ? '/' : '\\',
      imgSrc = pathSplitChar + decodeURI(photos[displayIndex]),
      fileName =
        imgSrc.split(pathSplitChar)[imgSrc.split(pathSplitChar).length - 1];

    return (
      <div className="photo-container">
        <div className="photo-carousel">
          {photos.length > 1 && (
            <div
              className="fas fa-chevron-left"
              onClick={this._scrollPrevious}
            ></div>
          )}
          <div className="image-container">
            <img id="myImg" src={photos[displayIndex]} alt={fileName} />
          </div>
          <label>{fileName}</label>
          {photos.length > 1 && (
            <div
              className="fas fa-chevron-right"
              onClick={this._scrollNext}
            ></div>
          )}
          <div className="fas fa-times" onClick={this.props.close}></div>
        </div>
      </div>
    );
  }
}
