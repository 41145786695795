import * as React from 'react'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import * as Dfr from '../store/dfr'
import { ListItem } from '../interfaces/interfaces'
import FilterableSelect from '../components/FilterableSelect'

interface Props { }

type ListProps = Props
    & Dfr.Store
    & typeof Dfr.actionCreators

interface State {
    enteredPersonnel: ListItem[],
    enteredCompanies: ListItem[]
}

class DfrPersonnel extends React.Component<ListProps, State> {
    constructor(props) {
        super(props);
        this.state = {
            enteredPersonnel: [],
            enteredCompanies: []
        }
    }

    componentDidMount() {
        this._getListItems();
    }

    _getListItems = () => {
        fetch(`api/DailyFieldReport/PriorEntries`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ enteredPersonnel: data.personnel, enteredCompanies: data.companies });
                else alert(data.message);
            })
    }


    _saveChanges = (e: React.FormEvent<HTMLInputElement>, field: string, entityId: number) => {
        const typeInput = e.currentTarget.value;
        this.props.updateChanges(entityId, field, typeInput, 2);
    }

    _listBlur = (entityId: number, field: string, val: string) => {
        this.props.updateChanges(entityId, field, val, 2);
    }    

    render() {     
        const { isLocked } = this.props
        const personnelList = this.props.personnel.map((x, i) => {
            return (
                <div className="dfr_rowP_E col-sm-12" key={x.id}>
                    <div className='col-sm-2 P_E_E_min-width'>
                        {isLocked ?
                            <input className="dfr_Personnel form-control" defaultValue={x.title || ""} disabled /> :
                            <FilterableSelect id={`title_${x.id}`} items={this.state.enteredPersonnel} openField={true} onBlur={(val) => this._listBlur(x.id, 'title', val)} defaultVal={x.title} />
                        }
                    </div>
                    <div className='col-sm-1 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={(x.count || "").toString()} onBlur={(e) => this._saveChanges(e, 'count', x.id)} disabled={this.props.isLocked} />
                    </div>
                    <div className='col-sm-2 P_E_E_min-width'>
                        {isLocked ?
                            <input className="dfr_Personnel form-control" defaultValue={x.company || ""} disabled /> :
                            <FilterableSelect id={`company_${x.id}`} items={this.state.enteredCompanies} openField={true} onBlur={(val) => this._listBlur(x.id, 'company', val)} defaultVal={x.company} />
                        }
                    </div>
                    <div className='col-sm-1 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.hours + "" || ""} onBlur={(e) => this._saveChanges(e, 'hours', x.id)} disabled={this.props.isLocked} type="number" />
                    </div>
                    <div className='col-sm-5 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.descriptionOfWork || ""} onBlur={(e) => this._saveChanges(e, 'descriptionOfWork', x.id)} disabled={this.props.isLocked} />
                    </div>

                    {!this.props.isLocked && <div className="col-sm-1"><span className="btn fas fa-times" onClick={() => this.props.deleteRow(x.id, 2)}></span></div>}
                </div>
            )
        })

        return (
            <div style={{ marginBottom: '10px' }} className='tile'>
                <h5>Personnel</h5>
                <hr />
                
                {this.props.personnel.length == 0 && this.props.isLocked ? <div><h5>No Personnel Listed</h5></div> :
                    <div className='col-sm-12 dfr_header_row'>
                        <div className='col-sm-2 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Title
                        </label>
                        </div>
                        <div className='col-sm-1 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Count
                        </label>
                        </div>
                        <div className='col-sm-2 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Company
                        </label>
                        </div>
                        <div className='col-sm-1 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Hours
                        </label>
                        </div>
                        <div className='col-sm-5 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Work Description
                        </label>
                        </div>
                        <div className="col-sm-1">
                            {this.props.isSubmitted ? [] : <span className="btn btn-sm btn-blue fas fa-plus" onClick={() => this.props.addPersonnel(this.props.id)}></span>}
                        </div>
                    </div>
                }
                {personnelList}
            </div>
        )
    }
}

export default connect<Dfr.Store, typeof Dfr.actionCreators, Props>(
    //@ts-ignore
    (state: ApplicationState) => state.dfr,
    Dfr.actionCreators
)(DfrPersonnel)