import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterableSelect from '../components/FilterableSelect';
import Modal, { Modal2 } from '../components/Modal';
import { formatDate, formatDateInputFriendly } from '../helpers/formatters';
import { ListItem, PunchItemPostModel } from '../interfaces/interfaces';
import { ApplicationState } from '../store';
import {
  punchItemsCategoriesSelect,
  PunchlistActions,
  templatesSelector,
  templateStatusSelector,
} from '../store/punchlist';
import { actionCreators as GlobalActions } from '../store/global';
import { Loader } from '../components/Loader';
import { SliderCheckbox } from 'src/components/SliderCheckbox';

interface ImportPostModel {
  projectId: number;
  assigneeId: string;
  ballInCourtId: string;
  rowStart: number;
  defaultDueDate: string;
  useTemplate: boolean;
}

export const PunchlistImportModal = (props: {
  projectId: number;
  close: () => void;
}) => {
  const { projectId, close } = props;

  const [loading, setLoading] = React.useState(false);
  const [postModel, setPostModel] = React.useState({
    projectId: projectId,
    assigneeId: '',
    ballInCourtId: '',
    rowStart: 2,
    defaultDueDate: new Date().toISOString(),
    useTemplate: false,
  } as ImportPostModel);
  const [file, setFile] = React.useState(null as File | null);
  const [templateId, setTemplateId] = React.useState<number | undefined>(
    undefined
  );

  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const templates = useSelector(templatesSelector);
  const templateStatus = useSelector(templateStatusSelector);

  const dispatch = useDispatch();

  const templateList = React.useMemo(() => {
    return templates.map((x) => new ListItem(x.id, x.title));
  }, [templates]);

  const _import = () => {
    if (loading) return;
    const missingFields: string[] = [];
    if (postModel.assigneeId === '') {
      missingFields.push('Assignee');
    }
    if (postModel.ballInCourtId === '') {
      missingFields.push('Ball In Court');
    }
    if (postModel.useTemplate) {
      if (!templateId) {
        missingFields.push('Template');
      }
    } else {
      if (postModel.rowStart <= 0) {
        missingFields.push('Row Start');
      }
      if (postModel.defaultDueDate === '') {
        missingFields.push('Default Due Date');
      }
    }

    if (missingFields.length > 0) {
      alert(
        'Please enter valid values for the following fields: \r' +
          missingFields.join('\r')
      );
      return;
    }

    setLoading(true);

    const form = new FormData();
    if (file) {
      form.append('files', file, file.name);
    }

    form.append('json', JSON.stringify(postModel));

    fetch(
      `api/punchlist/import${
        postModel.useTemplate && templateId ? '?templateId=' + templateId : ''
      }`,
      {
        method: 'POST',
        body: form,
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) {
          dispatch(PunchlistActions.getPunchItems(projectId));
          close();
        } else {
          dispatch(GlobalActions.updateMessage(data.message));
        }
      });
  };

  React.useEffect(() => {
    if (templateStatus == 'idle') {
      dispatch(PunchlistActions.getTemplates);
    }
  }, [templateStatus]);

  return (
    <Modal2>
      {<Loader loading={loading} />}
      <div className="modal-header">
        <h4>Import Punchlist</h4>
      </div>
      <div className="modal-body">
        <p>
          Imported file must be a comma-separated format or an excel file.
          Please ensure the following columns are included, in this order (per
          or row):
        </p>
        <p>
          <table>
            <tr style={{ width: '500px' }}>
              <th
                style={{
                  width: '100px',
                  paddingLeft: '5px',
                  border: '1px solid gray',
                }}
              >
                Title
              </th>
              <th
                style={{
                  width: '100px',
                  paddingLeft: '5px',
                  border: '1px solid gray',
                }}
              >
                Description
              </th>
              <th
                style={{
                  width: '100px',
                  paddingLeft: '5px',
                  border: '1px solid gray',
                }}
              >
                Category
              </th>
              <th
                style={{
                  width: '100px',
                  paddingLeft: '5px',
                  border: '1px solid gray',
                }}
              >
                Due Date
              </th>
            </tr>
          </table>
        </p>
        <p>
          Any additional columns to the right will be ignored. Additionally,
          fill out the form below. This will set the default values for created
          items. All items will be in draft status, so you they can be edited
          before being sent out.
        </p>
        <div className="form-group">
          <div className="form-label">
            <b>Assignee</b>*
          </div>
          <FilterableSelect
            id="punch-item-assignee"
            items={projectStore.detail.teamMembers.map(
              (x) => new ListItem(x.userId, x.userFullName)
            )}
            defaultVal={postModel.assigneeId}
            onChange={(id) => setPostModel({ ...postModel, assigneeId: id })}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Ball In Court</b>*
          </div>
          <FilterableSelect
            id="punch-item-ballincourt"
            items={projectStore.detail.teamMembers.map(
              (x) => new ListItem(x.userId, x.userFullName)
            )}
            defaultVal={postModel.ballInCourtId}
            onChange={(id) => setPostModel({ ...postModel, ballInCourtId: id })}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Default Due Date</b>*
          </div>
          <input
            type="date"
            className="form-control"
            value={formatDateInputFriendly(postModel.defaultDueDate)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPostModel({
                ...postModel,
                defaultDueDate: e.currentTarget.value,
              })
            }
          />
        </div>
        <div className="form-group">
          <label style={{ display: 'block' }}>Import from Template?</label>
          <SliderCheckbox
            fieldName="fromTemplate"
            selected={postModel.useTemplate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPostModel({
                ...postModel,
                useTemplate: !postModel.useTemplate,
              })
            }
          />
        </div>
        {!postModel.useTemplate ? (
          <>
            <div className="form-group">
              <div className="form-label">
                <b>Row Start</b>* - this is the line on which the items start in
                your spreadsheet
              </div>
              <input
                type="number"
                className="form-control"
                value={postModel.rowStart}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPostModel({
                    ...postModel,
                    rowStart: parseInt(e.currentTarget.value),
                  })
                }
              />
            </div>
            <div className="form-group">
              <div className="form-label">
                <b>File</b>*
              </div>
              <input
                type="file"
                accept=".txt,.csv,.xls,.xlsx"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFile(
                    e.currentTarget.files ? e.currentTarget.files[0] : null
                  )
                }
              />
            </div>
          </>
        ) : (
          <>
            {' '}
            <div className="form-group">
              <div className="form-label">
                <b>Template</b>*
              </div>
              <FilterableSelect
                id="punch-item-template"
                items={templateList}
                defaultVal={templateId}
                onChange={(id) => setTemplateId(+id)}
              />
            </div>
          </>
        )}
      </div>
      <div className="modal-footer">
        {(file !== null || (postModel.useTemplate && templateId)) && (
          <div className="btn btn-sm btn-blue" onClick={_import}>
            Import
          </div>
        )}
        <div className="btn btn-sm btn-outline-secondary" onClick={close}>
          Close
        </div>
      </div>
    </Modal2>
  );
};
