import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { actionCreators as projectActionCreators } from "../store/projectDetail";
import {
	ListItem,
	Submittal,
	UserInfo,
	SubmittalPostModel,
	SubmittalStatus,
	SubmittalReviewModel,
	SubmittalDocumentType,
	SubmittalResponseType,
	SubmittalDocumentStatus,
	TransferRecordType,
	ConstructionDivision,
} from "../interfaces/interfaces";
import { MessageBox } from "../components/MessageBox";
import { Loader } from "../components/Loader";
import { formatDateTime } from "../helpers/formatters";
import FilterableSelect from "../components/FilterableSelect";
import * as cx from "classnames";
import UserIdAuthElement from "../auth/UserIdAuthElement";
import AcceptedSubmittalDocBox from "./AcceptedSubmittalDocBox";
import {
	responseTypeToText,
	SubmittalResponseSection,
} from "./SubmittalResponseSection";
import DocIcon from "../components/DocIcon";
import { SubmittalCopyReference } from "./SubmittalCopyReference";
import SubmittalRevisionBox from "./SubmittalRevisionBox";
import { FileDownloader } from "../components/FileDownloader";
import Modal from "../components/Modal";
import { MobileVerticalSpacer } from "../components/MobileVerticalSpacer";
import { KnownRoles, hasRole } from "src/auth/auth";
import { TransferRecordModal } from "src/components/TransferRecordModal";
import { getSubmittal } from "../services/submittal";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { JoinedList } from "../components/JoinedList";
import { getTeamMembers } from "../services/project";
import { SubmittalGuideModal } from "../guides/SubmittalGuideModal";

//import { loadDataTableFromSpreadSheet } from 'react-google-charts/dist/ReactGoogleCharts';

interface State {
	submittal: Submittal;
	vendors: ListItem[];
	packageName: string;
	divisions: ListItem[];
	showUpdateBtn: boolean;
	showForwardBtn: boolean;
	loading: boolean;
	showSelectDocumentsModal: boolean;
	showCcedUsersModal: boolean;
	showCopyModal: boolean;
	showVoidModal: boolean;
	showCopyVoidReason: boolean;
	approvedDocuments: number[];
	signAndForward: boolean;
	forwardReviewerId?: string;
	signingOption?: number;
	showGuideModal: boolean;
	message?: string;
	projectTeamMembers: ListItem[];
}

const DefaultState: State = {
	submittal: {} as Submittal,
	vendors: [],
	divisions: [],
	packageName: "",
	showUpdateBtn: false,
	showForwardBtn: false,
	showSelectDocumentsModal: false,
	showCcedUsersModal: false,
	showCopyModal: false,
	showVoidModal: false,
	showCopyVoidReason: false,
	approvedDocuments: [],
	signAndForward: false,
	signingOption: -1,
	forwardReviewerId: undefined,
	showGuideModal: false,
	loading: true,
	projectTeamMembers: [] as ListItem[],
};

//class SubmittalDetail extends React.Component<Props, State> {
export const SubmittalDetail = (props: {
	id: number;
	deleteCallback?: () => void;
	refreshCallback?: () => void;
	updateCallback?: () => void;
}) => {
	const [state, setState] = React.useState(DefaultState);
	const [subdivisions, setSubdivisions] = React.useState(
		[] as ConstructionDivision[]
	);
	const [copysubdivisions, setCopySubdivisions] = React.useState(
		[] as ConstructionDivision[]
	);

	const [showTransferRecordModal, setShowTransferRecordModal] =
		React.useState(false);

	//const [projectTeamMembers, setProjectTeamMembers] = React.useState<
	//    ListItem[]
	//>([]);

	const userStore = useSelector((s: ApplicationState) => s.user);
	const projectStore = useSelector((s: ApplicationState) => s.detail);
	const globalStore = useSelector((s: ApplicationState) => s.global);
	const { user } = userStore;
	const dispatch = useDispatch();
	const isAdmin: boolean = hasRole(user, [KnownRoles.Admin]);

	React.useEffect(() => {
		return () => {
			setState(DefaultState);
		};
	}, []);

	React.useEffect(() => {
		setState(DefaultState);
		_getDetail(props.id);
	}, [props.id]);

	React.useEffect(() => {
		if (props.updateCallback) props.updateCallback();
	}, [state.submittal]);

	React.useEffect(() => {
		if (
			state.submittal.division !== null &&
			state.submittal.division !== undefined
		) {
			fetch(
				`api/globalconfig/subdivisions?divisionId=${state.submittal.division.id}`
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) setSubdivisions(data);
					else alert("Error looking up subdivisions");
				});
		}
	}, [state.submittal.division?.id]);

	React.useEffect(() => {
		const run = async () => {
			if (
				(!projectStore.detail ||
					Object.keys(projectStore.detail).length === 0 ||
					projectStore.detail.id !== submittal.projectId) &&
				submittal.projectId
			) {
				dispatch(projectActionCreators.getDetail(submittal.projectId));

				const response = await getTeamMembers(submittal.projectId);
				if (response) {
					if (response.status === 200) {
						setState({ ...state, projectTeamMembers: response.data });
					} else {
						setState({ ...state, message: response.data.message });
					}
				} else {
					setState({
						...state,
						message:
							"Error: There was an error loading the project's team members",
					});
				}
			}
		};
		run();
	}, [state.submittal.projectId]);

	const _getDetail = async (id: number) => {
		const res = await getSubmittal(props.id);
		if (res) {
			if (res.status === 200) {
				setState({
					...state,
					submittal: res.data.detail,
					divisions: res.data.divisions,
					vendors: res.data.vendors,
					packageName: res.data.packageName,
					loading: false,
				});
				//setState({ ...state, divisions: res.data.divsions })
			} else setState({ ...state, message: res.data.message, loading: false });
		} else
			setState({
				...state,
				message: "Error: Network or server error",
				loading: false,
			});
	};

	const _showUpdate = () => setState({ ...state, showUpdateBtn: true });

	const _showForward = (val: string) =>
		setState({ ...state, showForwardBtn: val !== "" });

	const _hideDocSelector = () =>
		setState({ ...state, showSelectDocumentsModal: false });

	const _forward = () => {
		setState({ ...state, loading: true });
		const reviewerId = (
			document.querySelector(
				'#submittal-reviewer input[name="submittal-reviewer"]'
			) as HTMLInputElement
		).value;
		if (reviewerId !== "") {
			fetch(
				`api/Submittal/Forward?id=${state.submittal.id}&newReviewerId=${reviewerId}`,
				{
					method: "PUT",
				}
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setState({
							...state,
							submittal: data,
							showForwardBtn: false,
							loading: false,
						});
					} else setState({ ...state, message: data.message, loading: false });
				});
		}
	};

	const _editCCedUsers = (ids: ListItem[]) => {
		const userIds = ids.map((x) => `userIds=${x.id}`).join("&");

		fetch(`api/submittal/editccedusers?id=${submittal.id}&${userIds}`, {
			method: "PUT",
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setState({ ...state, submittal: data, message: "Saved" });
				else setState({ ...state, message: data.message });
			});
	};

	const _update = () => {
		setState({ ...state, loading: true });
		const vendorSelect = document.querySelector(
				"#submittal-vendor"
			) as HTMLDivElement,
			subDivisionId =
				parseInt(
					(
						document.querySelector(
							'#select-subdivision input[type="hidden"]'
						) as HTMLOptionElement
					).value
				) || 0,
			reviewerId = (
				document.querySelector(
					'#submittal-reviewer input[name="submittal-reviewer"]'
				) as HTMLInputElement
			).value,
			description = (
				document.querySelector("#submittal-description") as HTMLInputElement
			).value,
			category = parseInt(
				(
					document.querySelector(
						"#submittal-category option:checked"
					) as HTMLOptionElement
				).value
			),
			fileInput = document.getElementById(
				"submittal-attachments"
			) as HTMLInputElement,
			files = fileInput.files;

		let vendorId = (
			vendorSelect.querySelector(
				'input[name="submittal-vendor"]'
			) as HTMLInputElement
		).value;
		const vendorText = (
			vendorSelect.querySelector("input.form-control") as HTMLInputElement
		).value;

		// If the hidden input matcnpmhes the displayed input we know they didn't pick a listed option
		if (vendorId === vendorText) vendorId = "";
		const model = {
			subDivisionId,
			vendorId,
			vendorEmail: vendorText,
			reviewerId,
			description,
			category,
		} as SubmittalPostModel;
		const form = new FormData();
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; ++i)
				form.append("files", files[i], files[i].name);
		}
		form.append("json", JSON.stringify(model));
		fetch(`api/Submittal/Update?id=${state.submittal.id}`, {
			method: "PUT",
			body: form,
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					fileInput.value = "";
					setState({
						...state,
						submittal: data,
						showUpdateBtn: false,
						loading: false,
					});
				} else setState({ ...state, message: data.message, loading: false });
			});
	};

	const _void = () => {
		setState({ ...state, loading: true });

		const reason = (document.getElementById("void-reason") as HTMLInputElement)
			.value;

		fetch(
			`api/Submittal/Void?id=${
				state.submittal.id
			}&voidReason=${encodeURIComponent(reason)}`,
			{ method: "PUT" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					if (props.deleteCallback) props.deleteCallback();
					else {
						setState({
							...state,
							submittal: data,
							message: "Submittal Voided",
							loading: false,
							showVoidModal: false,
						});
					}
				} else setState({ ...state, message: data.message, loading: false });
			});

		//setTimeout(() => this.props.history.push(`/project/${state.submittal.projectId}#submittals`), 1500);
	};

	const _copy = () => {
		setState({ ...state, loading: true });
		const divisionId = parseInt(
				(
					document.querySelector(
						"#submittal-division option:checked"
					) as HTMLOptionElement
				).value
			),
			subDivisionId = parseInt(
				(
					document.querySelector(
						"#submittal-sub-division input[type='hidden']"
					) as HTMLOptionElement
				).value
			),
			description = (
				document.getElementById(
					"submittal-description-copy"
				) as HTMLInputElement
			).value,
			dueDate = (
				document.getElementById("submittal-due-date") as HTMLInputElement
			).value,
			reviewerId = (
				document.querySelector(
					'#submittal-reviewer-select input[name="submittal-reviewer-select"]'
				) as HTMLInputElement
			).value,
			voidOriginal = (
				document.getElementById("void-original") as HTMLInputElement
			).checked,
			voidReasonInput = document.getElementById(
				"void-reason"
			) as HTMLInputElement,
			voidReason = voidReasonInput ? voidReasonInput.value : "",
			ignoreOriginalDocuments = (
				document.getElementById("ignore-original-documents") as HTMLInputElement
			).checked,
			files = (
				document.getElementById(
					"copy-submittal-attachments"
				) as HTMLInputElement
			).files;

		const model = {
			divisionId,
			subDivisionId,
			description,
			dueDate,
			reviewerId,
			voidReason,
			ignoreOriginalDocuments,
		} as SubmittalPostModel;
		const form = new FormData();
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; ++i)
				form.append("files", files[i], files[i].name);
		}
		form.append("json", JSON.stringify(model));
		fetch(
			`api/Submittal/Copy?id=${state.submittal.id}&voidOriginal=${voidOriginal}`,
			{
				method: "POST",
				body: form,
			}
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk)
					setState({
						...state,
						submittal: data,
						message: "Copied",
						loading: false,
						showCopyModal: false,
					});
				else setState({ ...state, message: data.message, loading: false });
			});
	};

	const _getCopySubdivisions = (e: React.ChangeEvent<HTMLSelectElement>) => {
		fetch(`api/globalconfig/subdivisions?divisionId=${e.currentTarget.value}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setCopySubdivisions(data);
				else alert("Error getting subdivisions");
			});
	};

	const _removeDocument = (id: number) => {
		fetch(`api/submittal/document?id=${id}`, { method: "DELETE" })
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setState({
						...state,
						submittal: {
							...state.submittal,
							documents: state.submittal.documents.filter((x) => x.id !== id),
						},
					});
				} else setState({ ...state, message: data.message });
			});
	};

	const _selectSignOption = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = parseInt(e.currentTarget.value);
		setState({ ...state, signingOption: value });
	};

	const _toggleCopyModal = () =>
		setState({ ...state, showCopyModal: !state.showCopyModal });
	const _toggleVoidModal = () =>
		setState({ ...state, showVoidModal: !state.showVoidModal });
	const _toggleShowVoidReason = () =>
		setState({ ...state, showCopyVoidReason: !state.showCopyVoidReason });

	const _submit = () => {
		const { submittal } = state;
		if (submittal.reviewerId === "")
			setState({
				...state,
				message:
					"Error: Must provider a reviewer for this item before submitting",
			});
		else {
			setState({ ...state, loading: true });
			fetch(`api/Submittal/Submit?id=${submittal.id}`, { method: "PUT" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						if (props.refreshCallback) {
							props.refreshCallback();
						} else setState({ ...state, submittal: data, loading: false });
					} else setState({ ...state, message: data.message, loading: false });
				});
		}
	};

	const _prepareSubmitReview = () => {
		const files = (
				document.querySelector(
					'.submittal-review-section input[type="file"]'
				) as HTMLInputElement
			).files,
			comments = (
				document.querySelector(
					".submittal-review-section .review-comment"
				) as HTMLTextAreaElement
			).value;
		const reviewType = state.signingOption;
		if (!reviewType)
			setState({ ...state, message: "Error: You must select a review option" });
		else {
			switch (reviewType) {
				case SubmittalResponseType.ApprovedWithExceptions:
				case SubmittalResponseType.ReviewAndResubmit:
					if (!comments || comments === "")
						setState({
							...state,
							message:
								"Error: Your review selection requires you to provide a comment",
						});
					else setState({ ...state, showSelectDocumentsModal: true });
					break;
				case SubmittalResponseType.SubmitSpecifiedItem:
					if (files == null || files.length == 0)
						setState({
							...state,
							message:
								"Error: Your review selection requires you upload at least one file",
						});
					else setState({ ...state, showSelectDocumentsModal: true });
					break;
				case SubmittalResponseType.NoExceptions: {
					const approvedIds = state.submittal.documents
						.filter((x) => x.status == SubmittalDocumentStatus.Unreviewed)
						.map((x) => x.id);
					setState({ ...state, approvedDocuments: approvedIds });
					_submitReview();
					break;
				}
				case SubmittalResponseType.Rejected:
					setState({ ...state, approvedDocuments: [] });
					_submitReview();
					break;
			}
		}
	};

	const _submitReview = () => {
		setState({ ...state, loading: true });
		const reviewType = state.signingOption,
			comment = (
				document.querySelector(
					".submittal-review-section .review-comment"
				) as HTMLTextAreaElement
			).value,
			files = (
				document.querySelector(
					'.submittal-review-section input[type="file"]'
				) as HTMLInputElement
			).files;
		let valid = true;
		if (!reviewType) {
			setState({
				...state,
				message: "Error: You must select a review option",
				loading: false,
			});
			valid = false;
		} else {
			if (reviewType !== 1) {
				if (comment === "") {
					valid = false;
					setState({
						...state,
						message:
							"Error: Your selected option requires a comment be supplied",
						loading: false,
					});
				}
			}
		}
		if (valid) {
			const { approvedDocuments, forwardReviewerId } = state;
			const model = {
				reviewType,
				comments: comment,
				approvedDocuments,
				forwardReviewerUserId: forwardReviewerId || "",
			} as SubmittalReviewModel;

			const form = new FormData();
			if (files && files.length > 0) {
				for (let i = 0; i < files.length; ++i)
					form.append("files", files[i], files[i].name);
			}
			form.append("json", JSON.stringify(model));
			fetch(`api/Submittal/Review?id=${state.submittal.id}`, {
				method: "PUT",
				body: form,
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						if (props.refreshCallback) {
							props.refreshCallback();
						} else
							setState({
								...state,
								submittal: data,
								showUpdateBtn: false,
								showSelectDocumentsModal: false,
								forwardReviewerId: undefined,
								signAndForward: false,
								loading: false,
							});
					} else
						setState({
							...state,
							message: data.message,
							showSelectDocumentsModal: false,
							loading: false,
						});
				});
		}
	};

	const { submittal } = state;
	let status = "",
		statusClass = "status-card";
	switch (submittal.status) {
		case SubmittalStatus.Draft:
			status = "Draft";
			statusClass += " draft";
			break;
		case SubmittalStatus.Submitted:
			status = "Awaiting Review";
			statusClass += " submitted";
			break;
		case SubmittalStatus.Approved:
			status = "Reviewed";
			statusClass += " approved";
			break;
		case SubmittalStatus.Rejected:
			status = "Rejected";
			statusClass += " rejected";
			break;
		case SubmittalStatus.SentToSub:
			status = "Sent To Sub";
			statusClass += " draft";
			break;
		case SubmittalStatus.CreatorReview:
			status = "Creator Review";
			statusClass += " draft";
			break;
		case SubmittalStatus.Canceled:
			status = "Canceled";
			statusClass += " rejected";
			break;
		case SubmittalStatus.Void:
			status = "Void";
			statusClass += " void";
			break;
	}

	const renderInfoSection = (submittal: Submittal, user?: UserInfo) => {
		const isSubmitter = user ? user.userId === submittal.userId : false,
			isVendor = user ? user.userId === submittal.vendorId : false,
			isReviewer = user ? user.userId === submittal.reviewerId : false,
			{ status } = submittal;

		let editable = false,
			attachable = false,
			reviewerEditable = false,
			forwardable = false;

		if (
			(status === SubmittalStatus.Draft ||
				status === SubmittalStatus.Submitted ||
				status === SubmittalStatus.CreatorReview) &&
			isSubmitter
		) {
			editable = true;
			attachable = true;
			reviewerEditable = true;
		}
		if (status === SubmittalStatus.Submitted && isReviewer) forwardable = true;

		if (status === SubmittalStatus.SentToSub && isVendor) attachable = true;

		//reviewerEditable = user ? user.userId == submittal.reviewerId : reviewerEditable

		return (
			<>
				<div style={{}}>
					<div className="submittal-info mobile-full-width">
						<div className="row">
							<div className="col-sm-3">
								<label>Creator</label>
							</div>
							<div className="col-sm-8">
								<label>{submittal.userFullName}</label>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Package</label>
							</div>
							<div className="col-sm-8">{}</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Spec Section/Division</label>
							</div>
							<div className="col-sm-8">
								<label>{submittal.division.name}</label>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Subdivision</label>
							</div>
							<div className="col-sm-8">
								{editable && subdivisions.length > 0 ? (
									// FilterableSelect won't render if the items are empty
									<FilterableSelect
										allowEmpty
										id="select-subdivision"
										items={subdivisions.map((x) => new ListItem(x.id, x.name))}
										onChange={_showUpdate}
										defaultVal={submittal.subDivision?.id}
									/>
								) : (
									<label>{submittal.subDivision?.name}</label>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Description</label>
							</div>
							<div className="col-sm-8">
								{editable ? (
									<textarea
										className="form-control"
										id="submittal-description"
										defaultValue={submittal.description}
										rows={5}
										onChange={_showUpdate}
									></textarea>
								) : (
									<label>{submittal.description}</label>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Due Date</label>
							</div>
							<div className="col-sm-8">
								<label>{formatDateTime(submittal.dueDate)}</label>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Vendor/Sub</label>
							</div>
							<div className="col-sm-8">
								{editable ? (
									<FilterableSelect
										id="submittal-vendor"
										items={state.vendors}
										onChange={_showUpdate}
										defaultVal={
											submittal.vendorId && submittal.vendorId.length
												? submittal.vendorId
												: submittal.vendorEmail
										}
									/>
								) : submittal.vendorName && submittal.vendorName ? (
									<label>{submittal.vendorName}</label>
								) : (
									<label>{submittal.vendorEmail}</label>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Reviewer</label>
							</div>
							<div className="col-sm-8">
								{reviewerEditable || forwardable ? (
									<FilterableSelect
										allowEmpty
										id="submittal-reviewer"
										items={globalStore.users.map(
											(x) => new ListItem(x.userId, x.fullName)
										)}
										onChange={forwardable ? _showForward : _showUpdate}
										defaultVal={submittal.reviewerId}
									/>
								) : (
									<label>{submittal.reviewerName}</label>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Category</label>
							</div>
							<div className="col-sm-8">
								{editable ? (
									<select
										id="submittal-category"
										className="form-control"
										disabled={!editable}
										defaultValue={submittal.category.toString()}
										onChange={_showUpdate}
									>
										<option value="0"></option>
										<option value="1">Material / Finish</option>
										<option value="2">Product Data</option>
										<option value="3">Samples</option>
										<option value="4">Shop Drawing</option>
										<option value="5">Spec Sheet</option>
										<option value="6">Test Reports</option>
									</select>
								) : (
									<label>{getCategory(submittal.category)}</label>
								)}
							</div>
						</div>
						<div className="row">
							<div className="col-sm-3">
								<label>Attachments</label>
							</div>
							<div className="col-sm-8">
								<div className="doc-icons custom-scrollbar">
									{submittal.documents && submittal.documents.length
										? submittal.documents
												.filter(
													(x) =>
														x.type === SubmittalDocumentType.SubmittalDocument
												)
												.map((x) => {
													const fileName =
														x.filePath.split("/")[
															x.filePath.split("/").length - 1
														];
													return (
														<div
															className="submittal-response-doc-container"
															key={x.id}
														>
															<DocIcon doc={x} />
															{[
																SubmittalStatus.Draft,
																SubmittalStatus.CreatorReview,
															].indexOf(submittal.status) !== -1 && (
																<span
																	className="fas fa-remove btn btn-sm btn-background-hover"
																	title="Remove this attachment"
																	onClick={() => _removeDocument(x.id)}
																></span>
															)}
															<div
																className="truncate centered"
																title={fileName}
															>
																{fileName}
															</div>
														</div>
													);
												})
										: []}
								</div>
								{attachable ? (
									<input
										id="submittal-attachments"
										type="file"
										multiple
										accept=".pdf,.jpg,.jpeg,.png,.bmp,.jiff"
										onChange={_showUpdate}
									/>
								) : (
									[]
								)}
							</div>
						</div>
						{renderButtonSection(submittal, user)}
					</div>
					{renderReviewSection(submittal, user)}
				</div>
				{showTransferRecordModal && isAdmin && (
					<TransferRecordModal
						type={TransferRecordType.Submittals}
						onClose={() => setShowTransferRecordModal(false)}
						fromUserId={state.submittal.userId}
						recordId={state.submittal.id}
						onTransfer={(message: string) => {
							_getDetail(props.id);
							setState({ ...state, message: message });
						}}
						users={projectStore.detail.teamMembers.map(
							(x) => new ListItem(x.id, x.userFullName)
						)}
					></TransferRecordModal>
				)}
			</>
		);
	};

	const renderButtonSection = (submittal: Submittal, user?: UserInfo) => {
		// Only show submit for certain statuses and for the creator
		const { showUpdateBtn, showForwardBtn } = state,
			{ documents } = submittal,
			showSubmitBtn = user
				? user.userId === submittal.userId &&
				  submittal.reviewerId != null &&
				  submittal.reviewerId !== "" &&
				  (submittal.status === SubmittalStatus.Draft ||
						submittal.status === SubmittalStatus.CreatorReview) &&
				  documents &&
				  documents.filter(
						(x) =>
							x.type === SubmittalDocumentType.SubmittalDocument &&
							x.status === SubmittalDocumentStatus.Unreviewed
				  ).length !== 0
				: false;

		return (
			<div className="submittal-btn-row">
				{showUpdateBtn && (
					<button className="btn btn-sm btn-warning" onClick={_update}>
						Save Changes
					</button>
				)}
				{showForwardBtn && (
					<button className="btn btn-sm btn-warning" onClick={_forward}>
						Forward
					</button>
				)}
				{showSubmitBtn && !showUpdateBtn && (
					<button
						className="btn btn-sm btn-green"
						onClick={_submit}
						title="Send this item to the indicated reviewer"
					>
						Submit
					</button>
				)}
			</div>
		);
	};

	const renderReviewSection = (submittal: Submittal, user?: UserInfo) => {
		const isReviewer = user ? user.userId === submittal.reviewerId : false,
			{ status } = submittal;

		if (!isReviewer || status !== SubmittalStatus.Submitted) return <div></div>;
		return (
			<div className="submittal-review-section mobile-full-width">
				<hr />
				<h3>Review Item</h3>
				<div className="submittal-stamp">
					{/* Logo image here? */}
					<div className="review-options">
						<div className="review-option">
							<input
								type="radio"
								name="review"
								value="1"
								onChange={_selectSignOption}
							/>{" "}
							No Exception Taken
						</div>
						<div className="review-option">
							<input
								type="radio"
								name="review"
								value="4"
								onChange={_selectSignOption}
							/>{" "}
							Rejected
						</div>
						<div className="review-option">
							<input
								type="radio"
								name="review"
								value="2"
								onChange={_selectSignOption}
							/>{" "}
							Approved as Noted
						</div>
						<div className="review-option">
							<input
								type="radio"
								name="review"
								value="5"
								onChange={_selectSignOption}
							/>{" "}
							Submit Specified Item
						</div>
						<div className="review-option">
							<input
								type="radio"
								name="review"
								value="3"
								onChange={_selectSignOption}
							/>{" "}
							Review and Resubmit
						</div>
					</div>
					<div className="stamp-disclaimer">
						Submittal is reviewed only as to the general conformity with design
						concept of the project and general compliance with the information
						given in the contract documents. Corrections and/or comments made as
						part of this submittal review do not relieve the contractor of
						responsibility from conformance with the contract documents,
						applicable codes, and laws – all of which have priority over this
						submittal. The design professional does not warrant or represent
						that the information within the submittal is either accurate or
						complete. Approval of a specific item shall not include approval of
						an assembly of which the item is a component. Sole responsibility
						for correct design, details, and dimensions shall remain with the
						party providing the submittal. Contractor is responsible for all
						dimensions, quantities and performance requirements to be confirmed
						and correlated at the job site; for all information that pertains
						solely to the fabrication processes or to techniques of
						construction; for all coordination of the work of all trades; for
						assuring consistency with the contract documents; and for performing
						the work in a safe and satisfactory manner.
					</div>
				</div>
				<div className="review-submit mobile-full-width">
					<textarea
						className="form-control review-comment mobile-full-width"
						rows={5}
					></textarea>
					<input
						type="file"
						multiple
						accept=".pdf,.jpg,.jpeg,.png,.bmp,.jiff"
					/>
				</div>
				<div style={{ display: "flex" }} key={state.signingOption}>
					<button
						className="btn btn-sm btn-green"
						onClick={_prepareSubmitReview}
					>
						Sign and Submit
					</button>{" "}
					&nbsp;
					{[
						SubmittalResponseType.NoExceptions,
						SubmittalResponseType.ApprovedWithExceptions,
						SubmittalResponseType.SubmitSpecifiedItem,
						SubmittalResponseType.ReviewAndResubmit,
					].indexOf(state.signingOption || -1) !== -1 ? (
						state.signAndForward ? (
							<>
								<FilterableSelect
									id="sign-and-forward-select"
									items={state.projectTeamMembers}
									onChange={(id) =>
										setState({ ...state, forwardReviewerId: id })
									}
									growUpwards={true}
								/>{" "}
								&nbsp;
								<button
									className="btn btn-sm btn-green"
									onClick={_prepareSubmitReview}
									disabled={state.forwardReviewerId === undefined}
								>
									Sign and Forward
								</button>{" "}
								&nbsp;
								<button
									className="btn btn-sm btn-outline-secondary"
									onClick={() =>
										setState({
											...state,
											signAndForward: false,
											forwardReviewerId: undefined,
										})
									}
								>
									Cancel
								</button>
							</>
						) : (
							<button
								className="btn btn-sm btn-green"
								onClick={() => setState({ ...state, signAndForward: true })}
								title="Finish your review and pass the submittal to an additional reviewer"
							>
								Sign and Forward
							</button>
						)
					) : (
						[]
					)}
				</div>
			</div>
		);
	};

	const renderSelectDocumentsModal = (submittal: Submittal) => {
		const { signingOption, signAndForward, forwardReviewerId } = state,
			unreviewed = submittal.documents
				.filter((x) => x.status === SubmittalDocumentStatus.Unreviewed)
				.map((x) => (
					<div key={x.id} style={{ maxWidth: "120px", marginRight: "8px" }}>
						<div style={{ marginLeft: "calc(50% - 35px)" }}>
							<DocIcon doc={x} height={60} />
						</div>{" "}
						<br />
						<div className="truncate">
							{x.filePath.split("/")[x.filePath.split("/").length - 1]}
						</div>
						<input
							type="checkbox"
							onChange={(e) =>
								e.currentTarget.checked
									? setState({
											...state,
											approvedDocuments: state.approvedDocuments.concat([x.id]),
									  })
									: setState({
											...state,
											approvedDocuments: state.approvedDocuments.filter(
												(y) => y !== x.id
											),
									  })
							}
							style={{ margin: "5px 0px 0px calc(50% - 6.5px)" }}
						/>
					</div>
				));

		let paraText: JSX.Element = <p></p>;
		switch (signingOption) {
			case SubmittalResponseType.ApprovedWithExceptions:
			case SubmittalResponseType.ReviewAndResubmit:
			case SubmittalResponseType.SubmitSpecifiedItem:
				paraText = (
					<p>
						You've selected{" "}
						<u>
							<i>{responseTypeToText(signingOption)}</i>
						</u>
						. Please select below from the submitted documents. Any selected
						documents will be attached to your response.
						{signAndForward && forwardReviewerId
							? "Because you've chosen to forward the submittal, all documents below will remain visible on the submittal for the next reviewer."
							: "Any documents not selected will be marked rejected."}
					</p>
				);
				break;
		}

		return (
			<Modal id="select-docs-modal">
				<div className="modal-header">
					<h4>Select Reviewed Documents</h4>
				</div>
				<div className="modal-body">
					{paraText}
					{/*<p>Select which documents you wish to accept. Those documents left unchecked will be rejected.</p>
                            <p>If you selected <u><i>Approved as Noted</i></u>, do not select the document you are correcting; your uploaded document will be taken as the accepted version.</p>*/}
					<br />
					<h5>Documents uploaded by {submittal.userFullName}:</h5>
					<div className="submittal-doc-items">{unreviewed}</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-green" onClick={_submitReview}>
						Sign and Submit
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_hideDocSelector}
					>
						Close
					</button>
				</div>
			</Modal>
		);
	};

	const renderCopyModal = () => {
		const { divisions } = state,
			divisionOpts = divisions
				? divisions.map((x) => (
						<option key={x.id} value={x.id}>
							{x.value}
						</option>
				  ))
				: [];

		return (
			<Modal id="select-docs-modal" dialogClass="submittal-modal">
				<div className="modal-header">
					<h4>Copy Submittal</h4>
				</div>
				<div className="modal-body">
					<div className="row">
						<div className="col-sm-3">
							<label>Void Original</label>
						</div>
						<div className="col-sm-9">
							<input
								type="checkbox"
								id="void-original"
								onChange={_toggleShowVoidReason}
							/>
						</div>
					</div>
					{state.showCopyVoidReason && (
						<div className="row">
							<div className="col-sm-3">
								<label>Void Reason</label>
							</div>
							<div className="col-sm-9">
								<textarea className="form-control" id="void-reason"></textarea>
							</div>
						</div>
					)}
					<div className="row">
						<div className="col-sm-3">
							<label>Spec Section / Division*</label>
						</div>
						<div className="col-sm-9">
							<select
								id="submittal-division"
								className="form-control"
								onChange={_getCopySubdivisions}
							>
								<option value="0"></option>
								{divisionOpts}
							</select>
						</div>
						<div className="col-sm-3">
							<label>Subdivision</label>
						</div>
						<div className="col-sm-9">
							<FilterableSelect
								id="submittal-sub-division"
								items={copysubdivisions.map((x) => new ListItem(x.id, x.name))}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3">
							<label>Description*</label>
						</div>
						<div className="col-sm-9">
							<textarea
								id="submittal-description-copy"
								className="form-control"
								rows={5}
								defaultValue={state.submittal.description}
							></textarea>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3">
							<label>Due*</label>
						</div>
						<div className="col-sm-9">
							<input
								id="submittal-due-date"
								type="date"
								className="form-control"
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3">
							<label title="This person will be required to respond to the submittal">
								Reviewer
							</label>
						</div>
						<div className="col-sm-9">
							<FilterableSelect
								id={`submittal-reviewer-select`}
								items={globalStore.users.map(
									(x) => new ListItem(x.userId, x.fullName)
								)}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3">
							<label title="This will prevent the original submittal's documents from being included in the new submittal">
								Ignore Original Documents
							</label>
						</div>
						<div className="col-sm-9">
							<input type="checkbox" id="ignore-original-documents" />
						</div>
					</div>
					<div className="row">
						<div className="col-sm-3">
							<label>Attachment(s)</label>
						</div>
						<div className="col-sm-9">
							<input
								type="file"
								id="copy-submittal-attachments"
								multiple
								accept=".pdf,.jpg,.jpeg,.png,.bmp,.jiff"
							/>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-green" onClick={_copy}>
						Copy
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_toggleCopyModal}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const renderVoidModal = () => {
		return (
			<Modal id="select-docs-modal" dialogClass="submittal-modal">
				<div className="modal-header">
					<h4>Void Submittal</h4>
				</div>
				<div className="modal-body">
					<div className="row">
						<div className="col-sm-3">
							<label>Void Reason</label>
						</div>
						<div className="col-sm-9">
							<textarea className="form-control" id="void-reason"></textarea>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-purple" onClick={_void}>
						Void
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_toggleVoidModal}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const renderCCedUsersDialog = () => {
		return (
			<Modal>
				<div className="modal-body">
					<JoinedList
						title="CCed Users"
						availableItems={state.projectTeamMembers.filter(
							(x) =>
								submittal.ccedUsers.findIndex((y) => y.userId === x.id) === -1
						)}
						selectedItems={submittal.ccedUsers.map(
							(x) => new ListItem(x.userId, x.userFullName)
						)}
						onChange={_editCCedUsers}
					/>
					<br />
				</div>
				<div className="modal-footer">
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => setState({ ...state, showCcedUsersModal: false })}
					>
						Close
					</button>
				</div>
			</Modal>
		);
	};

	const getCategory = (id: number) => {
		let cat = "";
		switch (id) {
			case 1:
				cat = "Material / Finish";
				break;
			case 2:
				cat = "Product Data";
				break;
			case 3:
				cat = "Samples";
				break;
			case 4:
				cat = "Shop Drawing";
				break;
			case 5:
				cat = "Spec Sheet";
				break;
			case 6:
				cat = "Test Reports";
				break;
		}
		return cat;
	};

	return submittal && submittal.id ? (
		<div className="submittal-detail custom-scrollbar" key={props.id}>
			<MessageBox
				message={state.message}
				clearMessage={() => setState({ ...state, message: undefined })}
			/>
			<Loader loading={state.loading} />
			<div className="top-row" style={{ display: "flex" }}>
				<span style={{ marginTop: "10px" }}>{submittal.submittalNumber}</span>
				<div className={statusClass}>{status}</div>
				{[
					SubmittalStatus.CreatorReview,
					SubmittalStatus.Submitted,
					SubmittalStatus.SentToSub,
				].indexOf(submittal.status) !== -1 ? (
					<UserIdAuthElement
						allowedUserIds={[
							submittal.userId,
							submittal.reviewerId,
							projectStore.detail.internalPmId,
						]}
					>
						<span
							className="btn btn-sm btn-background-hover fas fa-users"
							title="Edit CCed Users"
							onClick={() => setState({ ...state, showCcedUsersModal: true })}
						></span>
					</UserIdAuthElement>
				) : (
					[]
				)}
				{submittal.status !== SubmittalStatus.Void &&
				submittal.status !== SubmittalStatus.Canceled ? (
					<UserIdAuthElement
						allowedUserIds={[
							submittal.userId,
							projectStore.detail.internalPmId,
						]}
					>
						<span
							className="fas fa-ban btn btn-sm btn-background-hover"
							title="Void this submittal"
							onClick={_toggleVoidModal}
						></span>
					</UserIdAuthElement>
				) : (
					[]
				)}
				{!submittal.copiedTo && (
					<UserIdAuthElement
						allowedUserIds={[
							submittal.userId,
							projectStore.detail.internalPmId,
						]}
					>
						<span
							className="fas fa-copy btn btn-sm btn-background-hover"
							title="Copy this submittal"
							onClick={_toggleCopyModal}
						></span>
					</UserIdAuthElement>
				)}
				<FileDownloader
					actionPath="api/report/submittalPdf"
					queryParams={{ id: submittal.id }}
					fileType="PDF"
					className="btn btn-sm btn-background-hover"
				/>
				{submittal.status !== SubmittalStatus.Void &&
				submittal.status !== SubmittalStatus.Canceled &&
				submittal.status !== SubmittalStatus.Approved &&
				submittal.status !== SubmittalStatus.Rejected &&
				isAdmin ? (
					<span
						className="fas fa-exchange-alt btn btn-sm btn-background-hover"
						title="Transfer this submittal"
						onClick={() => setShowTransferRecordModal(true)}
					></span>
				) : (
					[]
				)}
				{
					<span
						className="fas fa-square-question btn btn-sm btn-background-hover"
						onClick={() => setState({ ...state, showGuideModal: true })}
						title="More info about submittals"
					></span>
				}
			</div>
			<h3 style={{ margin: "12px 0px 8px 0px" }}>{submittal.title}</h3>
			<div>
				<div className="submittal-body mobile-full-width">
					{submittal.copiedFrom && (
						<SubmittalCopyReference toFrom="from" info={submittal.sourceInfo} />
					)}
					{submittal.copiedTo && (
						<SubmittalCopyReference toFrom="to" info={submittal.copyInfo} />
					)}
					{/*<SubmittalRevisionBox submittal={submittal} />*/}
					{submittal.status === SubmittalStatus.Approved && (
						<AcceptedSubmittalDocBox submittal={submittal} />
					)}
					<SubmittalResponseSection submittal={submittal} />
					{submittal && submittal.id ? (
						<div style={{ marginBottom: "10px" }} className="mobile-block">
							{renderInfoSection(submittal, user)}
							<MobileVerticalSpacer />
						</div>
					) : (
						[]
					)}
				</div>
			</div>
			{state.showSelectDocumentsModal && renderSelectDocumentsModal(submittal)}
			{state.showCopyModal && renderCopyModal()}
			{state.showVoidModal && renderVoidModal()}
			{state.showCcedUsersModal && renderCCedUsersDialog()}
			{state.showGuideModal && (
				<SubmittalGuideModal
					close={() => setState({ ...state, showGuideModal: false })}
				/>
			)}
		</div>
	) : (
		<div className="submittal-detail">
			<StandardGridLoader rowContentHeight={200} rowCount={4} rowPadding={10} />
		</div>
	);
};
