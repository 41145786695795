import * as React from 'react';
import { JoinedList } from '../components/JoinedList';
import { ListItem, VendorTrade } from '../interfaces/interfaces';

export const VendorTrades = (props: { vendorId?: number, trades?: VendorTrade[] }) => {
  const [trades, setTrades] = React.useState([] as VendorTrade[]);
  const [editing, setEditing] = React.useState(false);
  const [allTrades, setAllTrades] = React.useState([] as VendorTrade[]);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setTrades(props.trades || []);
  }, [props.trades]);

  React.useEffect(() => {
    const getTrades = async () => {
      fetch(`api/VendorTrade/Get`)
        .then(res => res.json())
        .then(data => {
          setAllTrades(data);
          setLoaded(true);
        });
    };
    getTrades();
  }, [])

  const unselectedTrades = allTrades.filter(x => trades.findIndex(y => y.id === x.id) === -1);
  const adding = props.vendorId === undefined;

  const _changeTrades = (items: ListItem[]) => {
    let url = 'api/Vendor/Trades?id=' + props.vendorId;
    items.forEach(x => url += `&tradeIds=${x.id}`);
    fetch(url, { method: 'PUT' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setTrades(data);
      })
  }

  if (loaded && (adding || editing)) {
    return (
      <div className='vendor-joined-list vendor-trades'>
        <JoinedList
          availableItems={unselectedTrades.map(x => new ListItem(x.id, x.tradeName))}
          selectedItems={trades.map(x => new ListItem(x.id, x.tradeName))}
          title='Trades'
          onChange={adding ? undefined : _changeTrades}
          style={{ width: 'calc(100% - 15px)' }}
          
        />
        {!adding && <button className='btn btn-sm btn btn-outline-secondary fas fa-check' onClick={() => setEditing(!editing)} title='Done'></button>}
      </div>
    )
  }

  return (
    <div className='vendor-joined-list-text'>
      <span>{trades.length ? trades.map(x => x.tradeName).join(', ') : ''}</span>
      <button className='btn btn-sm btn btn-outline-secondary fas fa-edit' onClick={() => setEditing(!editing)} title='Edit trades'></button>
    </div>
  )
}