import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListItem, MilestoneEntryVm } from '../interfaces/interfaces';
import { ApplicationState } from '../store';

import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { actionCreators as milestoneActions } from '../store/milestones';
import PointedPopup from './PointedPopup';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

import Notepad from './Notepad';

import { GlobalMessageBox, MessageBox } from './MessageBox';
import { Gantt, GanttLabel, GanttTask } from '../charts/Gantt';
import FilterableSelect from './FilterableSelect';
import { Link } from 'react-router-dom';
import AttachmentViewer from './AttachmentViewer';
import { Loader } from './Loader';
import { SliderCheckbox } from './SliderCheckbox';
import MilestoneSelector from './MilestoneSelector';

interface State {
  //milestones: MilestoneEntryVm[],
  //attachmentProjectId: number | null,
  attachmentMilestoneId: number | null;
  showMilestoneSelector: boolean;
}

interface Props {
  projectId?: number;
  projectNumber?: string;
  clientName?: string;
  disabled: boolean;
  blockUpload?: boolean;
  showGantt?: boolean;
  includeProject?: boolean;
}

export const MilestoneList = (props: Props) => {
  const ganttColumns = [
    { type: 'string', label: 'Id' },
    { type: 'string', label: 'Name' },
    { type: 'string', label: 'Schedule' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'Due Date' },
    { type: 'number', label: 'Duration' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ] as GanttLabel[];

  const dispatch = useDispatch();
  const globalStore = useSelector((s: ApplicationState) => s.global);
  const milestoneStore = useSelector((s: ApplicationState) => s.msList);

  const PmComponent = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.ClientAdmin,
    KnownRoles.ClientPM,
    KnownRoles.CoreSuperUser,
  ]);
  //const isVendor = hasRole(this.props.user, [KnownRoles.Vendor]

  const [listState, setListState] = React.useState({
    /*milestones: [],*/ attachmentProjectId: null,
    attachmentMilestoneId: null,
    showMilestoneSelector: false,
  } as State);

  React.useEffect(() => {
    dispatch(milestoneActions.get(props.projectId));
  }, []);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    entryId: number
  ) => {
    const ele = e.currentTarget as HTMLInputElement;

    const field = ele.getAttribute('name') || '';
    dispatch(
      milestoneActions.updateEntryDet(
        entryId,
        field,
        ele.value,
        props.projectId
      )
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    entryId: number
  ) => {
    const sel = e.currentTarget as HTMLSelectElement;
    const opt = sel.querySelector('option:checked') as HTMLOptionElement;
    const field = sel.getAttribute('name') || '';
    dispatch(
      milestoneActions.updateEntryDet(
        entryId,
        field,
        opt.value,
        props.projectId
      )
    );
  };

  const handleChecked = (
    e: React.ChangeEvent<HTMLInputElement>,
    entryId: number
  ) => {
    const item = e.currentTarget as HTMLInputElement;
    const checked = e.currentTarget.checked;
    const field = item.getAttribute('name') || '';
    dispatch(
      milestoneActions.updateEntryDet(entryId, field, checked, props.projectId)
    );
  };

  const showAttachments = (entryId: number) => {
    setListState({
      ...listState,
      attachmentMilestoneId: entryId,
    });
  };

  const closeAttachments = () => {
    setListState({
      ...listState,
      attachmentMilestoneId: null,
    });
  };

  const toggleMilestoneSelector = () =>
    setListState({
      ...listState,
      showMilestoneSelector: !listState.showMilestoneSelector,
    });

  const _uploadDocs = (
    e: React.ChangeEvent<HTMLInputElement>,
    pId: number,
    mId: number,
    entryId: number
  ) => {
    const files = e.currentTarget.files;
    const form = new FormData();
    if (files && files.length > 0) {
      for (
        let i = 0;
        i < files.length;
        ++i //form.append(`doc${i}`, files[i], files[i].name)
      )
        form.append('files', files[i], files[i].name);
    }
    fetch(`api/Milestone/Upload?pid=${pId}&mId=${mId}&entryId=${entryId}`, {
      method: 'POST',
      body: form,
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          // do something
        } else alert(data.message);
      });
  };

  /* Column headers for milestone plus an add button to add a new entry */
  const gridHeader = (
    <div className="milestone-grid-row grid-header">
      {props.includeProject && <div className="col-sm-1">Project</div>}
      <div className="my-col-2">Milestone</div>
      <div className="my-col-2">Start Date</div>
      <div className="my-col-1">Duration</div>
      <div className="my-col-2">Due Date</div>
      <div className="my-col-2">Comp. Date</div>
      <div className="my-col-2">Status</div>
      <div className="my-col-2">Assignee</div>
      <div className="my-col-1">Billable</div>
      <div className="my-col-2">Value</div>
      <div className="my-col-2">Notes</div>
      {/*{!props.includeProject && <div className='my-col-2'>Dependencies</div>}*/}
      <div className="my-col-1"></div>
      {!props.includeProject && (
        <div className="my-col-1">
          <PmComponent>
            <span
              className="btn btn-sm btn-blue fas fa-plus"
              onClick={toggleMilestoneSelector}
            ></span>
          </PmComponent>
        </div>
      )}
    </div>
  );

  const renderGridLine = (milestone: MilestoneEntryVm) => {
    const vendDisabled = props.disabled;
    const start =
        milestone.startDate && milestone.startDate.length
          ? new Date(milestone.startDate).toISOString().substring(0, 10)
          : '',
      due =
        milestone.dueDate && milestone.dueDate.length
          ? new Date(milestone.dueDate).toISOString().substring(0, 10)
          : '',
      complete =
        milestone.completeDate && milestone.completeDate.length
          ? new Date(milestone.completeDate).toISOString().substring(0, 10)
          : '';
    return (
      <div key={milestone.entryId} className="milestone-grid-row">
        {props.includeProject && (
          <div className="col-sm-1 truncate">
            <Link to={`/project/${milestone.projectId}`}>
              {milestone.project.name}
            </Link>
          </div>
        )}
        <div className="my-col-2 truncate">
          <label>{milestone.milestoneName}</label>
        </div>
        <div className="my-col-2">
          <input
            key={start}
            type="date"
            name="startDate"
            className="form-control"
            defaultValue={start}
            onBlur={(e) => handleBlur(e, milestone.entryId)}
            disabled={vendDisabled}
          />
        </div>
        <div className="my-col-1">
          <input
            key={milestone.duration}
            type="number"
            name="duration"
            className="form-control"
            defaultValue={milestone.duration.toString()}
            onBlur={(e) => handleBlur(e, milestone.entryId)}
            disabled={vendDisabled}
          />
        </div>
        <div className="my-col-2">
          <input
            key={due}
            type="date"
            name="dueDate"
            className="form-control"
            defaultValue={due}
            onBlur={(e) => handleBlur(e, milestone.entryId)}
            disabled={vendDisabled}
          />
        </div>
        <div className="my-col-2">
          <input
            key={complete}
            type="date"
            name="completeDate"
            className="form-control"
            defaultValue={complete}
            onBlur={(e) => handleBlur(e, milestone.entryId)}
            disabled={vendDisabled}
          />
        </div>
        <div className="my-col-2">
          {renderStatusDDL(milestone.entryId, milestone.status)}
        </div>
        <div className="my-col-2">
          {renderAssignmentDDL(milestone.entryId, milestone.assignedToId)}
        </div>
        <div className="my-col-1">
          <SliderCheckbox
            dataId={milestone.entryId}
            fieldName="billable"
            selected={milestone.billable}
            onChange={(e) => handleChecked(e, milestone.entryId)}
            disabled={vendDisabled}
          />
          {/*<input type='checkbox' name="billable" className='form-control' defaultChecked={milestone.billable} onChange={(e) => handleChecked(e, milestone.entryId)} disabled={vendDisabled} />*/}
        </div>
        <div className="my-col-2 input-group">
          <div className="input-group-prepend">
            <span
              style={{ height: 'calc(1rem + 12px)', fontSize: '12px' }}
              className="input-group-text"
            >
              $
            </span>
          </div>
          <input
            key={milestone.dollarValue}
            type="text"
            name="dollarValue"
            className="form-control"
            onChange={(e) =>
              (e.currentTarget.value = e.currentTarget.value.replace(
                /[^\d,.]/g,
                ''
              ))
            }
            defaultValue={
              milestone.dollarValue
                ? milestone.dollarValue.toLocaleString()
                : ''
            }
            onBlur={(e) => {
              handleBlur(e, milestone.entryId);
            }}
            disabled={vendDisabled}
          />
        </div>
        <div className="my-col-2">
          <Notepad
            notes={milestone.notes}
            maxDisplayLength={50}
            disabled={vendDisabled}
            entityName={milestone.milestoneName}
            save={(newNotes: string) =>
              dispatch(
                milestoneActions.updateEntryDet(
                  milestone.entryId,
                  'notes',
                  newNotes,
                  props.projectId
                )
              )
            }
          />
        </div>
        {/*{!props.includeProject && <div className='my-col-2'>*/}
        {/*    <MilestoneDependencyPicker milestoneEntry={milestone} />*/}
        {/*</div>}*/}
        <div className="my-col-1">
          <span
            className="btn btn-sm btn-background-hover btn-round fas fa-folder-open"
            onClick={() => showAttachments(milestone.entryId)}
          ></span>
          <label
            htmlFor={`${milestone.milestoneName}-upload`}
            className="btn btn-sm btn-background-hover btn-round fas fa-download"
          >
            <input
              id={`${milestone.milestoneName}-upload`}
              type="file"
              onChange={(e) =>
                _uploadDocs(
                  e,
                  milestone.projectId,
                  milestone.milestoneId,
                  milestone.entryId
                )
              }
            />
          </label>
        </div>
        {!props.includeProject && (
          <div className="my-col-1">
            <PmComponent>
              <span
                className="btn btn-sm btn-background-hover btn-round fas fa-times"
                onClick={() =>
                  dispatch(
                    milestoneActions.deleteEntryDet(
                      //@ts-ignore
                      props.projectId,
                      milestone.entryId
                    )
                  )
                }
              ></span>
            </PmComponent>
          </div>
        )}
      </div>
    );
  };

  const renderStatusDDL = (entryId: number, status: string) => {
    return (
      <select
        key={status}
        className="form-control"
        name="status"
        defaultValue={status}
        onChange={(e) => handleChange(e, entryId)}
        disabled={props.disabled}
      >
        <option value="Pending" className="form-control">
          Pending
        </option>
        <option value="Started" className="form-control">
          Started
        </option>
        <option value="Completed" className="form-control">
          Complete
        </option>
      </select>
    );
  };

  const renderAssignmentDDL = (entryId: number, userId: string) => {
    const userItems = [{ id: 'NULL', value: '' } as ListItem].concat(
      globalStore.users.map((x) => new ListItem(x.userId, x.fullName))
    );
    return (
      <FilterableSelect
        id="assignedToId"
        defaultVal={userId}
        items={userItems}
        onChange={(val) =>
          dispatch(
            milestoneActions.updateEntryDet(
              entryId,
              'assignedToId',
              val,
              props.projectId
            )
          )
        }
        readonly={props.disabled}
      />
    );
  };

  const renderMilestoneGantt = () => {
    const chartData = milestones
      .sort((a, b) => (new Date(a.startDate) >= new Date(b.startDate) ? 1 : -1))
      .filter((m) => m.dueDate && m.dueDate.length) // && m.startDate && m.startDate.length)
      .map((m) => {
        const taskName = m.milestoneName;
        const dep = m.dependencies
          ? m.dependencies.map((x) => x.dependencyId.toString()).join(',')
          : null;
        const schedule =
          (!m.completeDate || !m.completeDate.length) &&
          new Date(m.dueDate) <= new Date()
            ? 'Late'
            : 'On Time';
        const st =
            m.startDate && m.startDate.length
              ? new Date(m.startDate)
              : new Date(m.dueDate),
          dd = new Date(m.dueDate);
        st.setHours(8);
        dd.setHours(17);
        return [
          m.entryId.toString(),
          taskName,
          schedule,
          st,
          dd,
          m.duration,
          0,
          dep,
        ];
      });

    return chartData.length ? (
      <Gantt
        columns={ganttColumns}
        tasks={chartData}
        graphId="milestone_gantt"
        hideTooltip={true}
      />
    ) : (
      []
    );
  };

  const { milestones, loading, saving } = milestoneStore;
  const gridLines = loading ? (
    <StandardGridLoader rowCount={5} rowContentHeight={33} rowPadding={5} />
  ) : milestones ? (
    milestones
      .sort((a: MilestoneEntryVm, b: MilestoneEntryVm) => {
        // Sort first by due dates
        // If due dates are equal, sort by earlier start date
        // null start should appear before filled out ones
        if (a.dueDate == b.dueDate) {
          if (a.startDate === null) return -1;
          if (b.startDate === null) return 1;
          return new Date(a.startDate) >= new Date(b.startDate) ? 1 : -1;
        }
        if (a.dueDate === null) return -1;
        else if (b.dueDate === null) return 1;
        return new Date(a.dueDate) > new Date(b.dueDate) ? 1 : -1;
      })
      .map((x) => renderGridLine(x))
  ) : (
    []
  );

  const attachmentMilestone = milestones.find(
    (x) => x.entryId === listState.attachmentMilestoneId
  );

  return (
    <div>
      <GlobalMessageBox />
      <Loader loading={saving} />
      {listState.showMilestoneSelector && (
        <PmComponent>
          {/*
                        @ts-ignore */}
          <MilestoneSelector
            projectId={props.projectId || 0}
            newEntry={(p, m) => dispatch(milestoneActions.addEntryDet(p, m))}
            close={toggleMilestoneSelector}
          />
        </PmComponent>
      )}
      {attachmentMilestone && (
        <AttachmentViewer
          pId={attachmentMilestone.projectId}
          milestoneId={attachmentMilestone.entryId}
          close={closeAttachments}
          title={attachmentMilestone.milestoneName}
          photos={[]}
          documents={[]}
          loading={false}
        />
      )}
      <div className="milestone-grid project-team-grid">
        {gridHeader}
        <div
          className="custom-scrollbar"
          style={{ maxHeight: '72vh', overflowY: 'auto' }}
        >
          {gridLines}
        </div>
      </div>
      <div className="section milestone-gantt mobile-hide">
        {props.showGantt && milestones.length ? renderMilestoneGantt() : []}
      </div>
    </div>
  );
};

export const MilestoneDependencyPicker = (props: {
  milestoneEntry: MilestoneEntryVm;
}) => {
  const milestoneStore = useSelector((s: ApplicationState) => s.msList);

  const dispatch = useDispatch();

  const { milestoneEntry } = props;

  const _update = (e: React.ChangeEvent<HTMLInputElement>) => {
    const entryId = e.currentTarget.getAttribute('data-id') || '';
    if (e.currentTarget.checked) {
      dispatch(
        milestoneActions.addDependency(
          milestoneEntry.projectId,
          milestoneEntry.entryId,
          parseInt(entryId)
        )
      );
    } else {
      dispatch(
        milestoneActions.removeDependency(
          milestoneEntry.projectId,
          milestoneEntry.entryId,
          parseInt(entryId)
        )
      );
    }
  };

  const possibleDependencies = milestoneStore.milestones
    ? milestoneStore.milestones.filter((x) => {
        if (x.entryId === milestoneEntry.entryId) return false;
        if (
          x.dependencies &&
          x.dependencies.findIndex(
            (d) => d.dependencyId === milestoneEntry.entryId
          ) !== -1
        )
          return false;
        return true;
      })
    : [];

  const dependencyOptions = milestoneStore.milestones
    ? possibleDependencies
        .filter((x) => {
          if (x.entryId === milestoneEntry.entryId) return false;
          if (
            x.dependencies &&
            x.dependencies.findIndex(
              (d) => d.dependencyId === milestoneEntry.entryId
            ) !== -1
          )
            return false;
          return true;
        })
        .map((x) => {
          const selected =
            milestoneEntry.dependencies &&
            milestoneEntry.dependencies.findIndex(
              (d) => d.dependencyId === x.entryId
            ) !== -1;
          return (
            <div className="milestone-dependency-option" key={x.entryId}>
              <SliderCheckbox
                fieldName="dependencyId"
                dataId={x.entryId}
                selected={selected}
                onChange={_update}
              />
              {/*<label className='switch' key={x.entryId + '_' + selected.toString()}>*/}
              {/*    <input type='checkbox' name='twoFactorEnabled' id={`dependency_slider_${x.entryId}`} defaultChecked={selected} onChange={_update} data-id={x.entryId} />*/}
              {/*    <span className='slider round'></span>*/}
              {/*</label>*/}
              <div className="dependency-label">{x.milestoneName}</div>
            </div>
          );
        })
    : [];

  return (
    <div className="milestone-dependency-picker">
      {milestoneEntry.dependencies && milestoneEntry.dependencies.length ? (
        <div className="truncate" style={{ maxWidth: 'calc(100% - 2.2rem)' }}>
          {milestoneEntry.dependencies.map((x) => x.dependencyName).join(', ')}
        </div>
      ) : (
        <span>
          <i>No dependencies</i>
        </span>
      )}
      <PointedPopup
        defaultEle={
          <span
            className="fas fa-edit btn btn-sm btn-background-hover btn-round"
            title="Edit dependencies"
          ></span>
        }
        leftAlign={true}
      >
        {dependencyOptions}
      </PointedPopup>
    </div>
  );
};
