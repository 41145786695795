import * as React from 'react';

import { VendorVm, VendorRating } from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import { NewRating } from './NewRating';

export const VendorRatings = (props: { vendor: VendorVm }) => {
    const [showAddRating, setShowAddRating] = React.useState(false);
    const [vendorRatings, setVendorRatings] = React.useState(props.vendor.ratings);

    React.useEffect(() => {
        setVendorRatings(props.vendor.ratings);
    }, [props.vendor.ratings])

    const { vendor } = props;

    const ratings = vendorRatings && vendorRatings.length ?
        vendorRatings.map(r => <Rating rating={r} key={r.id} />) :
        <div>No ratings exist for this vendor. Be the first to add one!</div>

    let remainingProjects = false;
    if (vendor.vendorProjects) {
        if (!vendorRatings) {
            remainingProjects = true;
        } else if (vendor.vendorProjects.filter(x => vendorRatings.findIndex(y => y.projectId == x.projectId) === -1).length) {
            remainingProjects = true;
        }
    }    

    const addBtn = remainingProjects ?
        <button className='btn btn-sm btn-blue' style={{ position: 'absolute', right: '5px', fontSize: '10px' }} onClick={() => setShowAddRating(true)}>Add Rating</button> :
        <button className='btn btn-sm btn-blue btn-disabled' style={{ position: 'absolute', right: '5px', fontSize: '10px' }} disabled={true} title='No unrated projects remaining for this project'>Add Rating</button>;

    return (
        <div className='vendor-ratings'>
            <h5>Ratings {addBtn}</h5>
            {showAddRating && <NewRating vendor={vendor} onSuccess={(r) => { setVendorRatings([...vendorRatings, r]); setShowAddRating(false); }} />}
            {ratings}
        </div>
    )
}

const Rating = (props: { rating: VendorRating }) => {
    const { rating } = props;
        
    return (
        <div key={rating.id} className='vendor-rating'>
            <div className='rater-info'>
                <b>{rating.userFullName}</b>                 
                <i> - {formatDate(rating.timestamp)}</i>
            </div>
            <div className='rating-title'>
                <Stars rating={rating.rating} /> &nbsp;
                <span><b>{rating.projectName}</b></span>
            </div>
            <div className='rating-timestamp'>
            </div>  
            <div className='rating-notes'>                
                {rating.notes}
            </div>
        </div>
    )
}

const Stars = (props: { rating: number }) => {

    const stars = Array.from(Array(5).keys()).map(i => {
        const className = i < props.rating ?
            'fas fa-star' :
            'far fa-star';
        return <span key={i} className={className}></span>
    })

    return (
        <div className='rating-stars'>
            {stars}
        </div>
    )
}