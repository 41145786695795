import { IssueStatus } from 'src/interfaces/enums';
import {
  addCCUser,
  addFiles,
  createIssue,
  getAll,
  getConfig,
  removeCCUsers,
  removeFile,
  updateIssue,
} from 'src/services/issues';
import { AppStartListening } from '../listenerMiddleware';
import { IssuesActions } from './issues.reducer';

export const addIssuesListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: IssuesActions.getIssues,
      effect: async (action, listenApi) => {
        listenApi.dispatch(IssuesActions.setStatus('loading'));
        const response = await getAll(action.payload);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(IssuesActions.setIssues(response));
          }
        }
        listenApi.dispatch(IssuesActions.setStatus('completed'));
      },
    }),
    startListening({
      actionCreator: IssuesActions.getConfig,
      effect: async (action, listenApi) => {
        const response = await getConfig();
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(IssuesActions.setConfig(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: IssuesActions.createIssue,
      effect: async (action, listenApi) => {
        listenApi.dispatch(IssuesActions.setStatus('saving'));
        const response = await createIssue(action.payload);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi.getState().issues.issues.slice();
            updated.push(response);
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Issue Created',
            });
          }
        }
      },
    }),
    startListening({
      actionCreator: IssuesActions.updateIssue,
      effect: async (action, listenApi) => {
        listenApi.dispatch(IssuesActions.setStatus('saving'));
        const response = await updateIssue(
          action.payload.id,
          action.payload.info
        );
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .issues.issues.slice()
              .map((x) => (x.id === response.id ? response : x))
              .filter((x) => x.status !== IssueStatus.Void);
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Issue Updated',
            });
          }
        }
        listenApi.dispatch(IssuesActions.setStatus('completed'));
      },
    }),
    startListening({
      actionCreator: IssuesActions.addCCUsers,
      effect: async (action, listenApi) => {
        const response = await addCCUser(
          action.payload.id,
          action.payload.userIds,
          action.payload.emails
        );
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .issues.issues.slice()
              .map((x) => (x.id === response.id ? response : x));
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'User(s) added',
            });
          }
        }
      },
    }),
    startListening({
      actionCreator: IssuesActions.addFiles,
      effect: async (action, listenApi) => {
        listenApi.dispatch(IssuesActions.setStatus('saving'));
        const response = await addFiles(action.payload.id, action.payload.files);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .issues.issues.slice()
              .map((x) => (x.id === response.id ? response : x));               
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Files added',
            });
          }
        }
        listenApi.dispatch(IssuesActions.setStatus('completed'));
      }
    }),
    startListening({
      actionCreator: IssuesActions.removeFile,
      effect: async (action, listenApi) => {
        const response = await removeFile(action.payload.fileId);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .issues.issues.slice()
              .map((x) => {
                if (x.id === action.payload.issueId) {
                  return ({ ...x, attachments: x.attachments.filter(y => y.id !== action.payload.fileId) })                  
                } 
                else return x;
              });
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'File removed',
            });
          }
        }
      }
    }),
    startListening({
      actionCreator: IssuesActions.removeCCUsers,
      effect: async (action, listenApi) => {
        const response = await removeCCUsers(
          action.payload.id,
          action.payload.userIds
        );
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .issues.issues.slice()
              .map((x) => (x.id === response.id ? response : x));
            listenApi.dispatch(IssuesActions.setIssues(updated));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'User(s) removed',
            });
          }
        }
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
