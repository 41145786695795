import * as React from "react";
import { formatDateTime } from "../helpers/formatters";

export enum EventLogType {
	RecordAdded,
	RecordModified,
	RecordDeleted,
	RecordShared,
	RecordVoided,
}

export interface EventLog {
	id: number;
	objectName: string;
	objectId: number;
	eventType: EventLogType;
	eventDescription: string;
	timestamp: string;
	userEmail: string;
	userFullName: string;
	userId: string;
}

interface Props {
	objectName: string;
	objectId: number;
	startDate?: string;
	endDate?: string;
	eventType?: EventLogType;
}

export const EventHistoryLog = (props: Props) => {
	const { objectName, objectId } = props;

	const [startDate, setStartDate] = React.useState(props.startDate);
	const [endDate, setEndDate] = React.useState(props.startDate);
	const [eventType, setEventType] = React.useState(props.eventType);
	const [logs, setLogs] = React.useState([] as EventLog[]);

	const getLogs = async () => {
		fetch(`api/eventlog/get?`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				objectName,
				objectId,
				eventType,
				startDate,
				endDate,
			}),
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setLogs(data);
			});
	};

	React.useEffect(() => {
		getLogs();
	}, [props.objectName, props.objectId]);

	return (
		<div className="event-history-log custom-scrollbar">
			{logs.map((x) => (
				<EventLogTile key={x.id} log={x} />
			))}
		</div>
	);
};

const EventLogTile = (props: { log: EventLog }) => {
	const { log } = props;
	return (
		<div className="event-log-tile" key={log.id}>
			<div className="tile-header">
				<b style={{ marginRight: "5px" }}>{log.userFullName}</b>
				<i className="event-date">{formatDateTime(log.timestamp)} - UTC</i>
				{/*<div><b>{log.userName}</b> &nbsp;</div>*/}
				{/*<div><i className='event-date'>{formatDateTime(log.timestamp)} - UTC</i></div>*/}
			</div>
			<hr />
			<div style={{ whiteSpace: "pre-wrap" }} className="tile-body">
				{log.eventDescription}
			</div>
		</div>
	);
};
