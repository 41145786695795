import { AppThunkAction } from "./index";
import { CustomGraphInfo } from "../charts/GraphPart";
import { GraphFilterOption, ProjectVm } from "../interfaces/interfaces";
import { DashboardScope, UserFilter } from "../dashboard/DashboardGraphs";
import { MetaObjectType } from "../interfaces/enums";
import { camelize } from "../helpers/formatters";

export interface GraphStore {
	graphParts: CustomGraphInfo[];
	userFilters: UserFilter[];
	scope: DashboardScope;
	filterOptions: GraphFilterOption[];
	filteredProjectIds: number[] | null;
	loading: boolean;
	message?: string;
}

interface RequestGraphsAction {
	type: "REQUEST_GRAPHS";
}

interface ReceiveGraphsAction {
	type: "RECEIVE_GRAPHS";
	graphs: CustomGraphInfo[];
	scope?: DashboardScope;
	message?: string;
}

interface ReceiveFiltersAction {
	type: "RECEIVE_FILTERS";
	userFilters: UserFilter[];
	filterOptions?: GraphFilterOption[];
	scope?: DashboardScope;
	message?: string;
}

interface UpdateFiltersAction {
	type: "UPDATE_GRAPH_FILTERS";
	filters: GraphFilterOption[];
	filteredProjectIds: number[] | null;
}

interface GraphErrorAction {
	type: "GRAPH_ERROR";
	message?: string;
}

const getFilteredProjectIds = (projects, filters) => {
	filters
		.filter((x) => x.global && x.dataSource === MetaObjectType.Project)
		.forEach((x: GraphFilterOption) => {
			projects = projects.filterByStringProp(x.prop.toString(), x.value);
		});

	const projectIds = projects
		.filter((x) => x !== undefined)
		.map((x: ProjectVm) => x.id);

	return projectIds;
};

type KnownAction =
	| RequestGraphsAction
	| ReceiveGraphsAction
	| ReceiveFiltersAction
	| UpdateFiltersAction
	| GraphErrorAction;

export const actionCreators = {
	getUserGraphsAndFilters:
		(scope: DashboardScope): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			if (
				getState().graphs.graphParts.length === 0 ||
				getState().graphs.scope !== scope
			) {
				fetch(`api/DashGraph/Graphs?scope=${scope}`)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							data = data.map((g: CustomGraphInfo) => {
								if (g.filters && g.filters.length) {
									g.appliedFilters = g.filters
										.filter(
											(x: UserFilter) => x.defaultValue && x.defaultValue !== ""
										)
										.map((x: UserFilter) => {
											// @ts-ignore
											return {
												prop: camelize(x.field),
												value: x.defaultValue,
												dataSource: x.dataSource,
												global: x.global,
												graphId: x.graphId,
											} as GraphFilterOption;
										});
								}
								return g;
							});
							dispatch({ type: "RECEIVE_GRAPHS", graphs: data, scope: scope });
						} else dispatch({ type: "GRAPH_ERROR", message: data.message });
					});
				dispatch({ type: "REQUEST_GRAPHS" });
			}
			if (
				getState().graphs.userFilters.length === 0 &&
				scope === DashboardScope.Global
			) {
				fetch(`api/DashGraph/Filters`)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						const filterOpts = data
							.filter(
								(x: UserFilter) => x.defaultValue && x.defaultValue !== ""
							)
							.map((x: UserFilter) => {
								return {
									prop: camelize(x.field),
									value: x.defaultValue,
									dataSource: x.dataSource,
									global: x.global,
								} as GraphFilterOption;
							});
						if (resOk)
							dispatch({
								type: "RECEIVE_FILTERS",
								userFilters: data,
								scope: scope,
								filterOptions: filterOpts,
							});
						else dispatch({ type: "GRAPH_ERROR", message: data.message });
					});
				dispatch({ type: "REQUEST_GRAPHS" });
			}
		},
	addGraph:
		(type: MetaObjectType): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/Add?type=${type}&scope=${getState().graphs.scope}`, {
				method: "POST",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = getState().graphs.graphParts.concat(data);
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	updateGraph:
		(graphId: number, prop: string, value: any): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/dashgraph/updategraph?id=${graphId}`, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ fieldName: prop, value: value }),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = getState()
							.graphs.graphParts.slice()
							.map((x: CustomGraphInfo) =>
								x.id === graphId
									? { ...data, appliedFilters: x.appliedFilters }
									: x
							);
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	deleteGraph:
		(graphId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/Delete?id=${graphId}`, { method: "Delete" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = getState()
							.graphs.graphParts.slice()
							.filter((x) => x.id !== graphId);
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	addGraphTableColumn:
		(graphId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/AddColumn?graphId=${graphId}`, { method: "POST" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = JSON.parse(
							JSON.stringify(getState().graphs.graphParts)
						).map((x: CustomGraphInfo) => {
							if (x.id === graphId) {
								if (x.tableColumns)
									x.tableColumns = x.tableColumns.concat(data);
								else x.tableColumns = [data];
							}
							return x;
						});
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	updateGraphTableColumn:
		(
			columnId: number,
			newField: string,
			objectType: MetaObjectType
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/DashGraph/Column?id=${columnId}&field=${newField}&objectType=${objectType}`,
				{ method: "PUT" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = JSON.parse(
							JSON.stringify(getState().graphs.graphParts)
						).map((g: CustomGraphInfo) => {
							g.tableColumns = g.tableColumns.map((c) => {
								if (c.id === columnId) c = data;
								return c;
							});
							return g;
						});
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	deleteGraphTableColumn:
		(columnId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/DeleteColumn?id=${columnId}`, { method: "DELETE" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const updated = JSON.parse(
							JSON.stringify(getState().graphs.graphParts)
						).map((g: CustomGraphInfo) => {
							g.tableColumns = g.tableColumns.filter((c) => c.id !== columnId);
							return g;
						});
						dispatch({ type: "RECEIVE_GRAPHS", graphs: updated });
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	addFilter:
		(
			filterField: string,
			dataSource: MetaObjectType,
			global: boolean,
			graphId?: number
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/Filter`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					graphId: graphId,
					field: filterField,
					objectType: dataSource,
					global: global,
				}),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						if (!graphId) {
							const filters = JSON.parse(
								JSON.stringify(getState().graphs.userFilters)
							).concat(data);
							dispatch({ type: "RECEIVE_FILTERS", userFilters: filters });
						} else {
							const graphs = getState()
								.graphs.graphParts.slice()
								.map((g) => {
									// @ts-ignore
									if (g.id === graphId) {
										g.filters = g.filters ? g.filters.concat([data]) : [data];
										const applied = {
											prop: data.field,
											value: "",
										} as GraphFilterOption;
										g.appliedFilters = g.appliedFilters
											? g.appliedFilters.concat([applied])
											: [applied];
									}
									return g;
								});
							dispatch({ type: "RECEIVE_GRAPHS", graphs: graphs });
						}
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	deleteFilter:
		(filterId: number, graphId?: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/DashGraph/DeleteFilter?id=${filterId}`, { method: "DELETE" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						if (!graphId) {
							const filters = JSON.parse(
								JSON.stringify(getState().graphs.userFilters)
							).filter((x) => x.id !== filterId);
							dispatch({ type: "RECEIVE_FILTERS", userFilters: filters });
						} else {
							const graphs = getState()
								.graphs.graphParts.slice()
								.map((g) => {
									if (g.id === graphId)
										g.filters = g.filters.filter((x) => x.id !== filterId);
									return g;
								});
							dispatch({ type: "RECEIVE_GRAPHS", graphs: graphs });
						}
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	updateFilters:
		(
			prop: string,
			value: any,
			dataSource: MetaObjectType,
			global: boolean
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let filterExists = false;
			let filters = JSON.parse(
				JSON.stringify(getState().graphs.filterOptions)
			).map((x: GraphFilterOption) => {
				if (x.prop === prop && x.dataSource === dataSource) {
					filterExists = true;
					x.value = value;
				}
				if (x.value !== "") return x;
			});

			if (!filterExists)
				filters.push({ dataSource, global, prop, value } as GraphFilterOption);
			filters = filters.filter((x) => x !== undefined);

			if (!filters || filters.length === 0) {
				dispatch({
					type: "UPDATE_GRAPH_FILTERS",
					filters: filters,
					filteredProjectIds: null,
				});
			} else {
				if (global) {
					const projects = JSON.parse(
						JSON.stringify(getState().graphData.projects)
					);
					const projectIds = getFilteredProjectIds(projects, filters);

					dispatch({
						type: "UPDATE_GRAPH_FILTERS",
						filters: filters,
						filteredProjectIds: projectIds,
					});
				} else {
					dispatch({
						type: "UPDATE_GRAPH_FILTERS",
						filters: filters,
						filteredProjectIds: getState().graphs.filteredProjectIds,
					});
				}
			}
		},
	updateGraphFilter:
		(graphId: number, prop: string, value: any): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let filterExists = false;
			// @ts-ignore
			let filters = JSON.parse(
				JSON.stringify(
					// @ts-ignore
					getState().graphs.graphParts.find((g) => g.id === graphId)
						.appliedFilters
				)
			).map((x: GraphFilterOption) => {
				if (x.prop === prop) {
					filterExists = true;
					x.value = value;
				}
				if (x.value !== "") return x;
			});

			if (!filterExists)
				filters.push({ prop, value, dataType: "string" } as GraphFilterOption);
			filters = filters.filter((x) => x !== undefined);

			const newGraphs = JSON.parse(
				JSON.stringify(getState().graphs.graphParts)
			).map((g: CustomGraphInfo) => {
				if (g.id === graphId) g.appliedFilters = filters;
				return g;
			});
			dispatch({ type: "RECEIVE_GRAPHS", graphs: newGraphs });
		},
	updateDefaultFilterValues:
		(
			values: { filterId; dataSource; field; defaultValue }[],
			graphId?: number
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/dashgraph/filterdefaults`, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ values: values }),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						if (!graphId) {
							dispatch({
								type: "RECEIVE_FILTERS",
								userFilters: data,
								message: "Defaults Saved",
							});
						} else {
							const graphs = getState()
								.graphs.graphParts.slice()
								.map((g) => {
									if (g.id === graphId) g.filters = data;
									return g;
								});
							dispatch({
								type: "RECEIVE_GRAPHS",
								graphs: graphs,
								message: "Defaults Saved",
							});
						}
					} else dispatch({ type: "GRAPH_ERROR", message: data.message });
				});
		},
	clearFilters: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({
			type: "UPDATE_GRAPH_FILTERS",
			filters: [],
			filteredProjectIds: null,
		});
	},
	clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "GRAPH_ERROR" });
	},
};

const unloadedStore: GraphStore = {
	graphParts: [],
	userFilters: [],
	scope: DashboardScope.Global,
	filterOptions: [],
	filteredProjectIds: null,
	loading: true,
};

//@ts-ignore
export const reducer: Reducer<GraphStore> = (
	state: GraphStore,
	//@ts-ignore
	incomingAction: Action
) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "REQUEST_GRAPHS":
			return { ...state, loading: true };
		case "RECEIVE_GRAPHS":
			return {
				...state,
				graphParts: action.graphs,
				message: action.message,
				scope: action.scope === undefined ? state.scope : action.scope,
				loading: false,
			};
		case "RECEIVE_FILTERS":
			return {
				...state,
				userFilters: action.userFilters,
				filterOptions: action.filterOptions || state.filterOptions,
				scope: action.scope === undefined ? state.scope : action.scope,
				message: action.message,
			};
		case "UPDATE_GRAPH_FILTERS":
			return {
				...state,
				filterOptions: action.filters,
				filteredProjectIds: action.filteredProjectIds,
			};
		case "GRAPH_ERROR":
			return { ...state, message: action.message, loading: false };
		default: {
			const exhaustiveCheck: never = action;
		}
	}
	return state || unloadedStore;
};
