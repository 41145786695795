import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import * as cx from 'classnames';


import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { ApplicationState } from '../store/index';
import { NotificationStream } from './NotificationStream';
import { useOutsideClickHandler } from '../helpers/customhooks';

export const NotificationTray = () => {

    const dispatch = useDispatch();
    const notificationStore = useSelector((s: ApplicationState) => s.notifications);
    const [isOpen, setIsOpen] = React.useState(false);
    const [connection, setConnection] = React.useState<null | HubConnection>(null);

    let { notifications } = notificationStore;
    notifications = notifications ? notifications.filter(x => x.status !== UserNotificationStatus.Dismissed) : [];

    React.useEffect(() => {
        dispatch(actionCreators.getNotifications(false));
        const connect = new HubConnectionBuilder()
            .withUrl('/hubs/notifications')
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, [])

    React.useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    connection.on("ReceiveMessage", (message) => {
                        dispatch(actionCreators.getNotifications(false));
                    })
                })
                .catch((error) => console.log(error));
        }
    }, [connection])
    

    //React.useEffect(() => {
    //    const unread = notifications.filter(x => x.status === UserNotificationStatus.Unread)
    //    if (isOpen && unread.length !== 0) {
    //        dispatch(actionCreators.markAsRead(unread.map(x => x.id), false))
    //    }
    //}, [isOpen])

    const count = notifications.filter(x => x.status === UserNotificationStatus.Unread).length > 99 ? '99+' : notifications.filter(x => x.status === UserNotificationStatus.Unread).length;

    const wrapperRef = React.useRef(null);
    useOutsideClickHandler(wrapperRef, () => setIsOpen(false));

    return (
        <div className='notification-tray' ref={wrapperRef}>
            <div className='tray-button' onClick={() => setIsOpen(!isOpen)} title={!isOpen ? `You have ${count} unread notifications` : ''}>
                <span className='fas fa-inbox'></span>
                {(!isOpen && count > 0) && <div className='notification-counter'>{count}</div>}
                {isOpen && <div className='stream-pointer'></div>}
            </div>
            {isOpen && <NotificationStream notifications={notifications} loading={notificationStore.isLoading} fullPage={false} />}
        </div>
    )
}