import { AppStartListening } from "../listenerMiddleware";
import { VendorsActions } from "./vendors.reducer";
import { getVendorsAndSubs } from '../../services/vendors';

export const addVendorsListeners = (
	startListening: AppStartListening
) => {
	const listeners = [
		startListening({
			actionCreator: VendorsActions.getVendorsAndSubs,
			effect: async (action, listenApi) => {
				const records = await getVendorsAndSubs();
				
				if (records) {					
					listenApi.dispatch(
						VendorsActions.setVendorsAndSubcs({ records: records })
					);
				}
			},
		}),		
	];
	return () => {
		listeners.forEach((unsubscribe) => unsubscribe());
	};
};
