import * as React from 'react';
import { SortInfo } from '../interfaces/interfaces';

export const SortIcon = (props: { sortInfo: SortInfo }) => {

    const { sortInfo } = props, { sortAsc, sortProp } = sortInfo;

    const sortIconClass = sortAsc ? 'sort-icon fas fa-arrow-up' : 'sort-icon fas fa-arrow-down'
    const sortIcon = sortProp ?
        <span className={sortIconClass}></span> :
        <span></span>

    return sortIcon;
}