import * as React from 'react';
import { MessageBox } from '../components/MessageBox';

import Modal from '../components/Modal';
import { CompanyDivision, VendorTrade, VendorVm, WorkRegion } from '../interfaces/interfaces';
import { VendorInfoForm } from './VendorInfoForm';
import { useDispatch } from 'react-redux';
import { Loader } from '../components/Loader';
import { actionCreators as globalActions } from '../store/global';

interface Props {
  addCallback: (v: VendorVm) => void;
}

export const VendorAddModal = (props: Props) => {
  const { addCallback } = props;

  const [showModal, setShowModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const dispatch = useDispatch();

  const _addVendor = () => {
    const fields = document.querySelectorAll('.vendor-info-form input');

    const model = { trades: [] as VendorTrade[], divisions: [] as CompanyDivision[], regions: [] as WorkRegion[], } as VendorVm;

    [].forEach.call(fields, (input: HTMLInputElement) => {
      model[input.name] = input.value;
    })

    //@ts-ignore
    const selectedTrades = document.querySelectorAll('.vendor-trades .joined-list .selected-list option') as HTMLElement[];
    //@ts-ignore
    const selectedDivisions = document.querySelectorAll('.vendor-divisions .joined-list .selected-list option') as HTMLElement[];
    //@ts-ignore
    const selectedRegions = document.querySelectorAll('.vendor-regions .joined-list .selected-list option') as HTMLElement[];

    [].forEach.call(selectedTrades, (opt: HTMLOptionElement) => {
      model.trades.push({ id: parseInt(opt.value), tradeName: '' } as VendorTrade)
    });

    [].forEach.call(selectedDivisions, (opt: HTMLOptionElement) => {
      model.divisions.push({ id: parseInt(opt.value), name: '' } as CompanyDivision)
    });

    [].forEach.call(selectedRegions, (opt: HTMLOptionElement) => {
      model.regions.push({ id: parseInt(opt.value), name: '' } as WorkRegion)
    });

    setSaving(true);

    fetch(`api/Vendor/Add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model)
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(globalActions.updateMessage(model.name + " successfully added"))
          setSaving(false);
          if (addCallback) addCallback(data);
          setShowModal(false);
        }
        else {
          dispatch(globalActions.updateMessage(data.message))
        }
      })
  }

  return (
    <span className='vendor-add-component'>
      <Loader loading={saving} />
      <span className='btn btn-sm btn-blue fas fa-plus' onClick={() => setShowModal(true)}></span>
      {showModal &&
        <Modal modalClass='vendor-info-modal' dialogStyle={{ maxWidth: '700px' }}>
          <div className='modal-body'>
            { // @ts-ignore 
              <VendorInfoForm addCallback={addCallback} />
            }
          </div>
          <div className='modal-footer'>
            <span className='btn btn-x-sm btn-blue' onClick={_addVendor}>Save</span>
            <span className='btn btn-x-sm btn-outline-secondary' onClick={() => setShowModal(false)}>Close</span>
          </div>
        </Modal>
      }
    </span>
  )
}