import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { CustomReportActions } from '../../store/customReport/customReport.reducer';
import { loadedReportSelector, reportsStatusSelector } from '../../store/customReport/customReport.selectors';
import { CustomReportConfiguration } from './CustomReportConfiguration';
import '../customReports.css';
import { CustomReportResults } from './CustomReportResults';
import { Loader } from '../../components/Loader';
import { PageHeaderDiv } from '../../styledComponents';
import { GlobalMessageBox } from '../../components/MessageBox';


export const CustomReportPage = () => {

  const routeParams = useParams();

  //const [report, setReport] = React.useState(undefined as CustomReportVm | undefined);
  const dispatch = useDispatch();
  const report = useSelector(loadedReportSelector);
  const status = useSelector(reportsStatusSelector);

  React.useEffect(() => {
    // @ts-ignore
    if (routeParams && routeParams.id) {
      // @ts-ignore
      dispatch(CustomReportActions.getLoadedReport(routeParams.id));
    }
    //@ts-ignore
  }, [routeParams?.id])

  return (
    <div className='custom-report-page'>
      <GlobalMessageBox />
      <Loader loading={status === 'running'} />
      <PageHeaderDiv>
        Custom Reporting
      </PageHeaderDiv>
      <hr />
      <div style={{ padding: '0px 8px' }}>
        <CustomReportConfiguration />
        <hr />
        <CustomReportResults />
      </div>
    </div>
  )


}