import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { NCRState } from './ncrs.reducer';

const ncrState = (state: ApplicationState): NCRState => state.ncrs;

export const ncrStatusSelector = createSelector(
  ncrState,
  (state) => state.status
);

export const isNCRSavingSelector = createSelector(
  ncrStatusSelector,
  (status) => status == 'saving'
);