import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SliderCheckbox } from '../components/SliderCheckbox';
import * as AdminStore from '../store/admin'
import { ApplicationState } from '../store/index';

export const LoginPolicies = () => {

    const dispatch = useDispatch();
    const store = useSelector((s: ApplicationState) => s.admin)
    const { loginPolicies } = store;

    React.useEffect(() => { dispatch(AdminStore.actionCreators.getLoginPolicies()) }, [])

    const _update = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.currentTarget.getAttribute('name');
        const value = e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value;
        fetch(`api/admin/loginpolicies`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fieldName: prop, value: value })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch(AdminStore.actionCreators.getLoginPolicies());
                else alert(data.message);
            })
    }

    return (
        <div className='col-sm-3'>
            <div className='form-header' style={{ marginBottom: '10px' }}><h4>Login Policies</h4></div>
            <hr/>
            <div className='form-body'>
                {/*<div className='form-group'>*/}
                {/*    <label style={{ display: 'block' }}>Password Length (Minimum)</label>*/}
                {/*    <input className='form-control' name='passwordMinimumLength' defaultValue={loginPolicies.passwordMinimumLength} onBlur={_update} style={{ width: '100px' }} />*/}
                {/*</div>*/}
                <div className='form-group'>
                    <label style={{ display: 'block' }}>Two Factor Authentication Required</label>
                    <SliderCheckbox fieldName='twoFactorEnforced' selected={loginPolicies.twoFactorEnforced} onChange={_update} />                                      
                </div>

            </div>
        </div>
    )
}
