import { Action, Reducer, ActionCreator } from 'redux';

export type SW_INIT = { type: 'SW_INIT' };
export type SW_UPDATE = { type: 'SW_UPDATE', payload: ServiceWorkerRegistration };

type KnownAction = SW_INIT | SW_UPDATE;

export interface Store {
    initialized: boolean;
    updated: boolean;
    registration: ServiceWorkerRegistration | null;
}

export const actionCreators = {
    initialize: () => (dispatch, getState) => {
        dispatch({ type: 'SW_INIT' })
    },
    update: (registration: ServiceWorkerRegistration) => (dispatch, getState) => {                
        dispatch({ type: 'SW_UPDATE', payload: registration })
    }
}

const initalState: Store = {
    initialized: false,
    updated: false,
    registration: null,
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'SW_INIT':
            return { initialized: !state.initialized };
        case 'SW_UPDATE':
            return { updated: !state.updated, registration: action.payload };
        //case 'SW_ERROR':
        //    return { }
        default:
            return state || initalState;
    }

}