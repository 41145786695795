import React, { useMemo } from 'react';
import { formatKPIValue } from 'src/charts/chartHelpers';
import { sum } from 'src/helpers/formulas';
import { IssueStatus, KPIGraphFormat } from 'src/interfaces/enums';
import { IssueVm } from 'src/interfaces/interfaces';
import { RectangleLoader } from 'src/loaders/RectangleLoader';
import styled from 'styled-components';
import { IssueBarChart } from './IssueBarChart';
import { IssuePieChart } from './IssuePieChart';

interface IssueChartsProps {
  issues: IssueVm[];
  loading: boolean;
}

const ChartContainer = styled.div(
  ({ width }: { width?: string; marginBottom?: string }) => ({
    padding: '10px',
    border: '1px solid lightgray',
    borderRadius: '3px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    width: width,
  })
);

const KPIContainer = styled(ChartContainer)(
  ({ marginBottom }: { marginBottom?: string }) => ({
    marginBottom: marginBottom,
    height: '130.5px',
    textAlign: 'center' as const,
    '& h2': {
      marginTop: '25px',
    },
  })
);

export const IssueCharts = ({ issues, loading }: IssueChartsProps) => {
  const TTCvalue = useMemo(() => {
    const responseTimes = issues
      .filter((x) => x.status === IssueStatus.Complete)
      .map((x) => {
        const diffTime =
          new Date(x.dateClosed).getTime() - new Date(x.createdDate).getTime();
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      });

    return responseTimes.length
      ? formatKPIValue(
          responseTimes.reduce(sum) / responseTimes.length,
          KPIGraphFormat.Number
        )
      : 'N/A';
  }, []);

  const openIssuesCount = issues.filter(
    (x) => x.status !== IssueStatus.Complete
  ).length;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <ChartContainer width="47%">
        {loading ? (
          <RectangleLoader height={'250px'} />
        ) : issues.length < 1 ? (
          <h5>No items to display in chart</h5>
        ) : (
          <IssueBarChart issues={issues} />
        )}
      </ChartContainer>
      <ChartContainer width="30%">
        {loading ? (
          <RectangleLoader height={'250px'} />
        ) : issues.length < 1 ? (
          <h5>No items to display in chart</h5>
        ) : (
          <IssuePieChart issues={issues} />
        )}
      </ChartContainer>
      <div
        style={{
          width: '20%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <KPIContainer>
          {loading ? (
            <RectangleLoader />
          ) : (
            <>
              <h5>Avg Time To Closed (days)</h5>
              <h2>{TTCvalue}</h2>
            </>
          )}
        </KPIContainer>
        <KPIContainer>
          {loading ? (
            <RectangleLoader />
          ) : (
            <>
              <h5>Total Open Items</h5>
              <h2>{formatKPIValue(openIssuesCount, KPIGraphFormat.Number)}</h2>
            </>
          )}
        </KPIContainer>
      </div>
    </div>
  );
};
