import * as React from 'react'
import { Chart } from 'react-google-charts'
import cx from 'classnames'

interface Props {
    options?: any,        
    graphId: string,   
    dataPoints: any[],
    columns?: string[],
    hideTooltip?: boolean,
    colors?: any
    
}

export default class PieChart extends React.Component<Props, any> {

    private colors = {
        black: {
            "color": "#888888",
            "dark": "#393939",
            "light": "#999999"
        },
        blue: {
            "color": "#5e97f6",
            "dark": "#2a56c6",
            "light": "#c6dafc"
        },
        red: {
            "color": "#db4437",
            "dark": "#a52714",
            "light": "#f4c7c3"
        },
        green: {
            "color": "#0f9d58",
            "dark": "#0b8043",
            "light": "#b7e1cd"
        }
    }

    render() {
        const { dataPoints, options, columns, hideTooltip } = this.props;
        const firstData = columns || ['Task', 'Items', { role: 'style' }],
            data = [firstData, ...dataPoints]
        
        return (
            <div className={cx('chart-container', hideTooltip && 'no-tooltip')}
                style={{ position: "absolute", width: '100%', top: '-50px', left: '-5%', bottom: '80px'}}
            >
                <Chart
                    chartType='PieChart'
                    width={'100%'}
                    height={'600px'}
                    loader={<div>Loading Chart...</div>}
                    data={data}
                    options={options}
                    />
            </div>
        )

    }
    
}