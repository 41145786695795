// File: SiteVue/wwwroot/ClientApp/src/store/rfqs/rfqs.listeners.ts

import { AppStartListening } from '../listenerMiddleware';
import { RFQActions } from './rfqs.reducer';
import { createRFQ } from '../../services/rfqs/index';

export const addRFQListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: RFQActions.createRFQ,
      effect: async (action, listenApi) => {
        listenApi.dispatch(RFQActions.setStatus('saving'));
        const response = await createRFQ(action.payload);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(RFQActions.setSelectedRFQ(response));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'RFQ Created',
            });
          }
        } else {
          listenApi.dispatch({
            type: 'UPDATE_MESSAGE_ACTION',
            message: 'Failed to create RFQ',
          });
        }
        listenApi.dispatch(RFQActions.setStatus('idle'));
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
