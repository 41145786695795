import * as React from 'react';
import styled from 'styled-components';

export const FlexedDiv = styled.div({
  display: 'flex',
});

export const FormGroupDiv = styled(FlexedDiv)({
  width: '100%',
  lineHeight: 1,
  '& .inline-label': {
    width: '150px',
    lineHeight: 2,
  },
  '& .inline-label.field-name': {
    width: '250px',
  },
});

export const PageHeaderDiv = styled.div({
  fontSize: '26px',
  margin: '10px 0 0',
  minHeight: '42px',
  position: 'relative',
});
