import * as React from 'react';
import * as cx from 'classnames';
import { FileDownloader } from './FileDownloader';

export const TableDataDownloadLink = (props: {
  data: any[],
  columns?: any[],
  filename: string,
  className?: string,
  style?: any
}) => {

  const { data, columns, filename, className, style } = props;

  const httpbody = {
    reportName: filename,
    reportPages: [
      {
        pageName: filename,
        columnInfos: columns ? columns : data[0],
        data: data.slice(1, data.length)
      } as ReportPageInfo
    ]
  } as ReportInfoWrapper;

  return <TableDataDownloadLinkFormatted data={httpbody} className={className} style={style} />
}

export const TableDataDownloadLinkFormatted = (props: { data: ReportInfoWrapper, className?: string, style?: any }) => {

  const { data, className, style } = props;

  return <FileDownloader
    //key={key}
    actionPath={'api/report/selfserve'}
    httpMethod='POST'
    httpBody={data}
    queryParams={{}}
    fileType='xlsx'
    className={cx('fa fa-download', className)}
    inlineStyles={style}
  />
}

export interface ReportInfoWrapper {
  reportName: string,
  reportPages: ReportPageInfo[]
}

export interface ReportPageInfo {
  pageName: string,
  columnInfos: { type: string, label: string, }[]
  data: string[][]
}