import * as React from 'react';
import { IRecordNotes } from '../interfaces/interfaces';

interface Props {
  notes?: IRecordNotes,
  label?: string,
  canEdit?: boolean,
  update: (e: React.FocusEvent<HTMLTextAreaElement>) => any
}

export const RecordNotes = (props: Props) => {
  let { label, canEdit } = props;
  const { notes, update } = props;
  label = label || 'Notes';

  canEdit = canEdit === undefined ? true : canEdit;

  return (
    <div className='form-group' style={{ display: 'flex', width: '100%' }}>
      <div className='inline-label' style={{ width: '150px' }}>{label || 'Notes'}</div>
      <div className='min-205' style={{ width: 'calc(100% - 150px)' }}>
        <textarea className='form-control' rows={3} defaultValue={notes ? notes.text : ''} onBlur={update} disabled={!canEdit} />
      </div>
    </div>
  )
}