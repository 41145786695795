import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { CustomizableGrid, MetaFieldInfo } from '../components/CustomizableGrid';
import { GlobalMessageBox } from '../components/MessageBox';
import { formatDate } from '../helpers/formatters';
import { CSharpTypeCode } from '../interfaces/enums';
import { CustomReportVm } from '../interfaces/interfaces';
import { CustomReportActions } from '../store/customReport/customReport.reducer';
import { reportsSelector, reportsStatusSelector } from '../store/customReport/customReport.selectors';
import { TabContent, TabLink, Tabs } from '../tabs';
import { AddReportForm } from './CustomReport/AddReportForm';
import { AddReportFormButtonWrapper } from './CustomReport/AddReportFormButtonWrapper';

export const ReportingHome = () => {

  const reports = useSelector(reportsSelector);
  const status = useSelector(reportsStatusSelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    console.log('page initialized');
    dispatch(CustomReportActions.getAllReports())
  }, [])  

  const cols: MetaFieldInfo[] = [
    { displayName: 'Name', propName: 'reportName', jsType: 'string', propType: CSharpTypeCode.String },
    { displayName: 'Report Type', propName: 'reportTypeName', jsType: 'string', propType: CSharpTypeCode.String },
    { displayName: 'Creator', propName: 'creatorName', jsType: 'string', propType: CSharpTypeCode.String },
    { displayName: 'Last Modified', propName: 'lastModifiedTimestamp', jsType: 'date', propType: CSharpTypeCode.DateTime },
  ]

  if (status === 'loading') {
    console.log('loading');
  }

  const _lineMap = React.useCallback(
    (report: CustomReportVm) => {
      return (
        <NavLink
          to={`/customReport/${report.id}`}
          className='col-sm-12 sv-grid-line'
          key={report.id}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <div className="my-col-5">{report.reportName}</div>
          <div className="my-col-5">{report.reportTypeName}</div>
          <div className="my-col-5">{report.creatorName}</div>
          <div className="my-col-5">{formatDate(report.lastModifiedTimestamp)}</div>
        </NavLink>
      )
    },
    [reports])

  return (
    <div className='reporting-home'>
    <GlobalMessageBox />
      <div className='pageHeader'>Reporting</div>        
      <Tabs name='vendor-tabs' onChange={(t: string) => { }}
        renderActiveTabContentOnly={true}>
        <div className='tab-links'>
          <TabLink to='list' component='span'>
            <span>Custom Reports</span>
          </TabLink>
          <TabLink to='map' component='span'><span>Queries</span></TabLink>
        </div>
        <div className='content'>
          <TabContent for='list'>
            <CustomizableGrid
              columns={cols}
              items={reports}
              mapCallback={_lineMap}
              className='custom-reporting-grid'
              loading={status === 'loading'}
              hideLoadingWhenEmpty={true}
              addComponent={<AddReportFormButtonWrapper />}
            />
          </TabContent>       
          <TabContent for='queries'>
            
          </TabContent>
        </div>
      </Tabs>
    </div>
  )
}