import * as React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as AdminStore from '../store/admin'
import { actionCreators } from '../store/user'
import { ApplicationState } from '../store/index';
import { check_phone_format } from '../helpers/formatters';
import { MessageBox } from '../components/MessageBox';
import { UserInfo, UserAuthenticationInfo } from '../interfaces/interfaces';
import Modal from '../components/Modal';
import { SliderCheckbox } from '../components/SliderCheckbox';

interface State {
    user: UserInfo;
}

type Props =
    AdminStore.Store
    & typeof AdminStore.actionCreators

export const AccountSettingss = () => {

    const userStore = useSelector((s: ApplicationState) => s.user);
    const adminStore = useSelector((s: ApplicationState) => s.admin);
    const dispatch = useDispatch();

    const [message, setMessage] = React.useState(undefined as string | undefined);
    const [verifyChannel, setVerifyChannel] = React.useState(undefined as string | undefined)

    React.useEffect(() => { dispatch(AdminStore.actionCreators.getLoginPolicies()) }, [])

    const _update = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const prop = e.currentTarget.getAttribute('name');
        const value = e.currentTarget.type === 'checkbox' ? (e.currentTarget as HTMLInputElement).checked : e.currentTarget.value;
        fetch(`account/update`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fieldName: prop, value: value })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch(actionCreators.getUser(true));
                else alert(data.message);
            })
    }

    const _requestCode = (channel: string) => {
        fetch(`account/RequestVerificationCode?channel=${channel}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setVerifyChannel(channel);
                else alert(data.message);
            })
    }

    const _verifyCode = () => {
        const codeInput = (document.getElementById('verificationCode') as HTMLInputElement), code = codeInput.value
        fetch(`account/verifycode?channel=${verifyChannel}&code=${code}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    if (data.verified) {
                        setVerifyChannel(undefined);
                        dispatch(actionCreators.getUser(true));
                    } else {
                        codeInput.value = '';
                        alert('Verification failed. Please try again or generate a new code.');
                    }
                }
                else alert(data.message);
            })
    }

    const _renderVerifyModal = () => {
        if (verifyChannel === undefined) return []
        const channelProper = verifyChannel === "sms" ? verifyChannel.toUpperCase() : verifyChannel[0].toUpperCase() + verifyChannel.substr(1);
        return (
            <Modal>
                <div className='modal-header'><h4>Verify {channelProper}</h4></div>
                <div className='modal-body'>
                    <p>Please enter the code sent to your {verifyChannel === "sms" ? 'phone' : 'email'}. This code will be valid for <u>ten minutes</u>.</p>
                    <input className='form-control' id='verificationCode' style={{ width: '120px' }} />
                    <hr />
                    <p>
                        Didn't receive a code? Please double check your {verifyChannel === "sms" ? 'phone number' : 'email'} and try again.
                    If you're sure your {verifyChannel === "sms" ? 'phone number' : 'email'} is correct, <a href='#' onClick={() => _requestCode(verifyChannel)}>click here to generate a new code</a>.
                </p>
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-sm btn-blue' onClick={_verifyCode}>Verify</button>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => setVerifyChannel(undefined)}>Close</button>
                </div>
            </Modal>
        )
    }

    const { user } = userStore
    if (!user) return <div className='col-sm-3 account-settings'><MessageBox message={message} clearMessage={() => setMessage(undefined)} /></div>;
    const { authenticationInfo } = user;

    return (
        <div className='col-sm-3 account-settings'>
            {_renderVerifyModal()}
            <MessageBox message={message} clearMessage={() => setMessage(undefined)} />
            <div className="form-header">
                <h4 className="modal-title">Edit Account Info</h4>
            </div>
            <div className="form-body" id="edit-user-form">
                <small>Changes are saved automatically</small>
                <br /><br />
                <div className='form-group'>
                    <label>Email</label>
                    <div style={{display: 'flex'}}>
                        <label key={user.email} style={{ width: 'calc(100% - 100px)' }}><b>{user.email}</b></label>
                        {!authenticationInfo.emailConfirmed && <button className='btn btn-sm btn-outline-secondary' style={{ height: '28px', fontSize: 'inherit', margin: '-10px 0px 0px 10px' }} onClick={() => _requestCode("email")}>Verify</button>}
                    </div>
                </div>
                <div className='form-group'>
                    <label>Full Name</label>
                    <input key={user.fullName} name='fullName' className='form-control' defaultValue={user.fullName} onBlur={_update} />
                </div>
                <div className='form-group'>
                    <label>Phone Number</label>
                    <div style={{ display: 'flex' }}>
                        <input type='tel' key={user.phoneNumber} name='phoneNumber' className='form-control formatted-phone'
                            defaultValue={user.phoneNumber} onKeyUp={check_phone_format} onBlur={_update} />
                        {!authenticationInfo.smsVerified && <button className='btn btn-sm btn-outline-secondary' style={{ height: '28px', fontSize: 'inherit', marginLeft: '10px' }} onClick={() => _requestCode("sms")}>Verify</button>}
                    </div>
                </div>
                <div className='form-group'>
                    <label style={{ display: 'block' }}>2FA Enabled</label>
                    <SliderCheckbox fieldName='twoFactorEnabled' selected={authenticationInfo.twoFactorEnabled} disabled={adminStore.loginPolicies.twoFactorEnforced} onChange={_update} />                   
                </div>
                <div className='form-group' title='This indicates the default tab that will load in when you go to the dashboard page'>
                    <label style={{ display: 'block' }}>Default Dashboard Tab</label>
                    <select className='form-control' onChange={_update} name='defaultDashboardTab' defaultValue={user.defaultDashboardTab}>
                        <option className='form-control' value='mywork'>My Work (Inbox)</option>
                        <option className='form-control' value='milestones'>My Milestones</option>
                        <option className='form-control' value='checklists'>My Checklists</option>
                        <option className='form-control' value='tasks'>My Tasks</option>
                        <option className='form-control' value='activity'>Activity</option>
                        <option className='form-control' value='graphs'>My Graphs</option>
                    </select>
                </div>
                <div className='form-group' title='This indicates the default tab that will load in when you initially navigate to a project'>
                    <label style={{ display: 'block' }}>Default Project Tab</label>
                    <select className='form-control' onChange={_update} name='defaultProjectTab' defaultValue={user.defaultProjectTab}>
                        <option className='form-control' value='overview'>Overview</option>
                        <option className='form-control' value='activity'>Activity</option>
                        <option className='form-control' value='checklists'>Checklists</option>
                        <option className='form-control' value='detail'>Detail</option>
                        <option className='form-control' value='dfr'>Field Reports</option>
                        <option className='form-control' value='explorer'>File Explorer</option>
                        <option className='form-control' value='procurement'>Procurement</option>
                        <option className='form-control' value='punchlist'>Punchlist</option>
                        <option className='form-control' value='rfi'>RFIs</option>
                        <option className='form-control' value='scheduling'>Scheduling</option>
                        <option className='form-control' value='subs'>Subcontractors</option>
                        <option className='form-control' value='submittals'>Submittals</option>
                        <option className='form-control' value='tasks'>Tasks</option>
                        <option className='form-control' value='team'>Team</option>
                        <option className='form-control' value='transmittals'>Transmittals</option>
                    </select>
                </div>
            </div>
            <div className="form-footer">
                {/*    <span className="btn btn-sm btn-green" onClick={this._save.bind(this)}>Save</span>*/}
            </div>

        </div>
    )
}

const ProjectTabOptions = [''];