import * as React from 'react';
import { Loader } from '../../components/Loader';
import { MessageBox } from '../../components/MessageBox';
import Modal from '../../components/Modal';

interface State {
  folders: DefaultFolder[],
  expandedPaths: string[],
  loading: boolean,
  hoveredFolderId?: number,
  deleteFolderId?: number,
  editFolderId?: number,
  addToFolderId?: number,
  message?: string
}

interface DefaultFolder {
  id: number,
  folderPath: string,
  editable: boolean
}

interface Props {
  rootPath: string
  params: any
  controller: string
  getAction: string
  postAction: string
  putAction: string
  deleteAction: string
  headerLabel: string
}

enum HttpMethodType {
  Get,
  Post,
  Put,
  Delete
}

export default class DefaultFolderForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      folders: [],
      loading: true,
      expandedPaths: []
    }
  }

  componentDidMount() {
    this._getDefaultFolders()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.params !== this.props.params) {
      console.log(prevProps.params.clientId)
      this._getDefaultFolders()
    }
  }

  _buildUrl = (method: HttpMethodType) => {
    const { controller, getAction, postAction, putAction, deleteAction, rootPath } = this.props
    const { params } = this.props;
    let basePath = `api/${controller}/`;

    switch (method) {
      case HttpMethodType.Get: basePath = basePath + getAction; params.rootPath = rootPath; break;
      case HttpMethodType.Post: basePath = basePath + postAction; break;
      case HttpMethodType.Put: basePath = basePath + putAction; break;
      case HttpMethodType.Delete: basePath = basePath + deleteAction; break;
    }
    basePath = basePath + '?'

    const qString: any[] = [];
    Object.keys(params).forEach((key: any) => {
      if (Array.isArray(params[key])) {
        params[key].forEach((val: any) => qString.push(encodeURIComponent(key) + '=' + encodeURIComponent(val)))
      }
      else qString.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    })
    basePath = basePath + qString.join('&')

    return basePath;
  }

  _getDefaultFolders = () => {
    const getPath = this._buildUrl(HttpMethodType.Get)
    fetch(getPath)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ folders: data, loading: false })
        else this.setState({ message: data.message })
      })
  }

  _clearMessage = () => this.setState({ message: undefined })

  _expand = (path: string) => {
    const newPaths = this.state.expandedPaths.slice();
    newPaths.push(path);
    this.setState({ expandedPaths: newPaths })
  }

  _collapse = (path: string) => {
    const newPaths = this.state.expandedPaths.filter(x => x.indexOf(path) === -1);
    this.setState({ expandedPaths: newPaths })
  }

  _addFolder = (e: React.MouseEvent<HTMLButtonElement>) => {
    const parent = e.currentTarget.parentElement as HTMLDivElement;
    const input = parent.querySelector('input') as HTMLInputElement;
    if (input.value.length > 0) {
      const path = ((input.getAttribute('data-path') || '').replace(/\/$/, '') + '/' + input.value).replace(/^\//, '');
      let postPath = this._buildUrl(HttpMethodType.Post);
      postPath = postPath + `&path=${path}`
      fetch(postPath, { method: 'POST' })
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) this.setState({ folders: data });
          else this.setState({ message: data.message });
        })

      input.value = '';
    }
  }

  _editFolder = () => {
    const newValue = (document.getElementById('new-folder-name') as HTMLInputElement).value,
      { editFolderId } = this.state;
    let editPath = this._buildUrl(HttpMethodType.Put)
    editPath = `${editPath}&id=${editFolderId}&newName=${newValue}`
    fetch(editPath, { method: 'PUT' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ folders: data, editFolderId: undefined, message: 'Saved' });
        else this.setState({ message: data.message });
      })
  }

  _deleteFolder = () => {
    const { deleteFolderId } = this.state;
    let deletePath = this._buildUrl(HttpMethodType.Delete)
    deletePath = `${deletePath}&id=${deleteFolderId}`
    fetch(deletePath, { method: 'DELETE' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ folders: data, deleteFolderId: undefined, message: 'Saved' });
        else this.setState({ message: data.message });
      })
  }

  _toggleAdd = (id?: number) => this.setState({ addToFolderId: id })

  _toggleEdit = (id?: number) => this.setState({ editFolderId: id })

  _toggleDelete = (id?: number) => this.setState({ deleteFolderId: id })

  _hover = (e: React.MouseEvent<HTMLDivElement>) => {
    const id = parseInt(e.currentTarget.getAttribute('data-id') || '0')
    this.setState({ hoveredFolderId: id });
  }

  _endHover = () => this.setState({ hoveredFolderId: undefined })

  render() {
    const { rootPath, headerLabel } = this.props
    const { folders, loading, message, deleteFolderId, addToFolderId, hoveredFolderId } = this.state,
      test = (folders && folders.length) ?
        folders.map(x => x.folderPath.split('/')[0])
          .filter(this.distinct)
          .filter(x => x.length > 0) : [],
      folderLines = (folders && folders.length) ?
        folders.map(x => x.folderPath.split('/')[0])
          .filter(this.distinct)
          .filter(x => x.length > 0)
          .map(x => this.renderNextLevelFolders(x, 0)) :
        [],
      hovered = hoveredFolderId === -1

    return (
      <div>
        <MessageBox message={message} clearMessage={this._clearMessage} />
        <Loader loading={loading} />
        <h5>{headerLabel}</h5>
        {/*<small>The below folder structure will be created for <u>all new projects</u></small>*/}
        <div className='default-folders'>
          <div className='default-folder-line'>
            <div className='foldername' data-id={-1} onMouseEnter={this._hover} onMouseLeave={this._endHover}>
              <span className='fas fa-chevron-down'></span>
              <span className='fas fa-folder-open'></span>
              {rootPath}
              {
                (hovered) &&
                <div className='hover-options'>
                  <span title='Add subfolder' className='fas fa-plus btn' onClick={this._toggleAdd.bind(null, -1)}></span>
                </div>
              }
            </div>
            {folderLines}
            {
              (addToFolderId === -1) &&
              <div className='new-folder-line'>
                <input data-path="" />
                <button title='Save' className='btn btn-sm btn-blue fas fa-check' onClick={this._addFolder}></button>
                <button title='Cancel' className='btn btn-sm btn-outline-secondary fas fa-ban' onClick={this._toggleAdd.bind(null, undefined)}></button>
              </div>
            }
          </div>
        </div>
        {deleteFolderId && this.renderDeleteModal(deleteFolderId)}
      </div>
    )
  }

  private renderNextLevelFolders(pathToLevel: string, n: number) {
    const { folders, expandedPaths, hoveredFolderId, editFolderId, addToFolderId } = this.state,
      nextLevel = folders
        .filter(x => x.folderPath
          .split('/')
          .slice(0, n + 1)
          .join('/')
          .indexOf(pathToLevel) === 0),
      nextLevelItems = nextLevel.map(x => {
        const pathName = x.folderPath,
          expanded = expandedPaths.indexOf(pathName) !== -1;
        if (x.folderPath.split('/').length - 1 > n) return [];
        const folderName = x.folderPath.split('/').slice(n, n + 1)[0],
          glyphClass = expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-right',
          onClick = expanded ? this._collapse.bind(null, pathName) : this._expand.bind(null, pathName),
          subFolders = expanded ? this.renderNextLevelFolders(pathName + '/', n + 1) : [],
          adding = addToFolderId === x.id,
          editing = editFolderId === x.id,
          hovered = !editing && hoveredFolderId === x.id
        return (
          <div id={`${pathName}_${n}`} className='default-folder-line' key={`${folderName}_${n}`}>
            <div className='foldername' data-id={x.id} onMouseEnter={this._hover} onMouseLeave={this._endHover}>
              <span className={glyphClass} onClick={onClick}></span>
              <span className='fas fa-folder-open'></span>
              {editing ?
                <div className='edit-folder-line'>
                  <input id='new-folder-name' data-path={pathName} defaultValue={folderName} />
                  <button title='Save' className='btn btn-sm btn-blue fas fa-check' onClick={this._editFolder}></button>
                  <button title='Cancel' className='btn btn-sm btn-outline-secondary fas fa-ban' onClick={this._toggleEdit.bind(null, undefined)}></button>
                </div>
                : folderName
              }
              {
                (x.editable && hovered) &&
                <div className='hover-options'>
                  <span title='Add subfolder' className='fas fa-plus btn' onClick={this._toggleAdd.bind(null, x.id)}></span>
                  <span title='Edit' className='fas fa-edit btn' onClick={this._toggleEdit.bind(null, x.id)}></span>
                  <span title='Delete' className='fas fa-trash btn' onClick={this._toggleDelete.bind(null, x.id)}></span>
                </div>
              }
            </div>
            {subFolders}
            {
              adding &&
              <div className='new-folder-line'>
                <input data-path={pathName} />
                <button title='Save' className='btn btn-sm btn-blue fas fa-check' onClick={this._addFolder}></button>
                <button title='Cancel' className='btn btn-sm btn-outline-secondary fas fa-ban' onClick={this._toggleAdd.bind(null, undefined)}></button>
              </div>
            }

          </div>
        )
      });

    return (
      <div className='default-folder-section' key={pathToLevel}>
        {nextLevelItems}
      </div>
    )
  }

  private renderDeleteModal(id: number) {
    const { folders } = this.state,
      split = (folders.find(x => x.id === id) as DefaultFolder).folderPath.split('/'),
      folderName = split[split.length - 1];
    return (
      <Modal>
        <div className='modal-header'>
          <h4>Delete Folder</h4>
        </div>
        <div className='modal-body'>
          <div style={{ fontSize: '14px' }}>Are you sure you want to delete {folderName}?</div>
          <p><b>Note:</b> This will delete all sub-folders under {folderName}</p>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-sm btn-danger' onClick={this._deleteFolder}>Delete</button>
          <button className='btn btn-sm btn-outline-secondary' onClick={this._toggleDelete.bind(null, undefined)}>Close</button>
        </div>
      </Modal>
    )
  }

  private distinct = (val, idx, self) => {
    return self.indexOf(val) === idx;
  }
}