//import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { ErrorAction, ListItem } from '../interfaces/interfaces';
import { AppThunkAction } from './index';

export interface LoginPolicies {
    twoFactorEnforced: boolean,
    passwordMinimumLength: number,
}

export interface Store {
    companies?: ListItem[]
    message?: string
    clientId?: string
    clientName?: string
    clientCode?: string
    clientContact?: string
    clientEmail?: string;
    loginPolicies: LoginPolicies
}

interface RequestClientAction {
    type: 'REQUEST_CLIENT',
    clientId: string
}

interface ReceiveClientAction {
    type: 'RECEIVE_CLIENT',
    clientContact: string,
    clientEmail: string,
    clientCode: string
}

interface SaveAction {
    type: 'SAVE'
    message: string
}

interface SavingAction {
    type: 'SAVING_ADMIN'
}

interface GetCompaniesAction {
    type: 'GET_COMPANIES',
    companies: ListItem[]
}

interface GetLoginPoliciesAction {
    type: 'RECEIVE_LOGIN_POLICIES',
    policies: LoginPolicies
}

interface ClearMessageAction {
    type: "CLEAR_ACCOUNT_PREF_MESSAGE"
}

type KnownAction = GetCompaniesAction | RequestClientAction | ReceiveClientAction | GetLoginPoliciesAction
    | SaveAction | SavingAction | ErrorAction | ClearMessageAction

export const actionCreators = {
    getClients: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/Get`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'GET_COMPANIES', companies: data })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    getVendors: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Vendor/GetAll`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'GET_COMPANIES', companies: data })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    getBrands: (): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Brand/Get`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'GET_COMPANIES', companies: data })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    getClientInfo: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (id !== getState().admin.clientId) {
            if (id != '0') {
                fetch(`api/Client/Detail?id=${id}`)
                    .then(res => Promise.all([res.ok, res.json()]))
                    .then(([resOk, data]) => {
                        if (resOk)
                            dispatch({ type: 'RECEIVE_CLIENT', clientContact: data.contact, clientCode: data.code, clientEmail: data.email })
                        else
                            dispatch({ type: 'ERROR', message: data.message })
                    })
                    .catch(err => dispatch({ type: 'ERROR', message: err }))
            }
            dispatch({ type: 'REQUEST_CLIENT', clientId: id });
        }
    },
    getLoginPolicies: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/admin/loginpolicies`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'RECEIVE_LOGIN_POLICIES', policies: data })
            })
    },
    addClient: (name: string, code: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const saveTask = fetch(`api/Client/Add`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                code: code
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE', message: 'SAVED' })
                else dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    addClientWithUser: (name: string, code: string, contactName: string, contactEmail: string, phone: string, ): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const saveTask = fetch(`api/Client/AddWithUser`, {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                code: code,
                contactName: contactName,
                contactEmail: contactEmail,
                phone: phone
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE', message: 'SAVED' })
                else dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    editClient: (clientId: string | number, contactName: string, contactEmail: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const saveTask = fetch(`api/Client/Edit?id=${clientId}`, {
            method: 'PUT',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                contactName: contactName,
                contactEmail: contactEmail
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE', message: 'SAVED' })
                else dispatch({ type: 'ERROR', message: data.message })
            })
            .catch(err => dispatch({ type: 'ERROR', message: err }))
    },
    register: (id: string, fullName: string, email: string, phone: string, roles: string[], isVendor: boolean, isBrandOrOwner: boolean, isApiUser: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`account/register`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                companyId: parseInt(id),
                email: email,
                fullName: fullName,
                phone: phone,
                isVendor: isVendor,
                isOwner: isBrandOrOwner,
                roles: roles,
                isApiUser: isApiUser
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'SAVE', message: data.message })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
    },
    editUser: (id: string, email: string, fullName: string, phone: string, roles: string[], status: string): AppThunkAction<KnownAction> => (dispatch) => {
        const saveTask = fetch(`Account/EditUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: id,
                email: email,
                fullName: fullName,
                phoneNumber: phone,
                roles: roles,
                status: status
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'SAVE', message: data.message })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
    },
    changePw: (email: string, old: string, newpass: string, newpass2: string): AppThunkAction<KnownAction> => (dispatch) => {
        const saveTask = fetch(`Account/ChangePasswordFetch`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                oldPassword: old,
                password: newpass,
                confirmPassword: newpass2
            })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk)
                    dispatch({ type: 'SAVE', message: data.message })
                else
                    dispatch({ type: 'ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING_ADMIN' })
    },
    clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_ACCOUNT_PREF_MESSAGE' });
    }
}

const unloadedState: Store = {
    loginPolicies: { twoFactorEnforced: false, passwordMinimumLength: 9 }
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'GET_COMPANIES':
            return { ...state, companies: action.companies }
        case 'REQUEST_CLIENT':
            return { ...state, clientId: action.clientId, message: undefined }
        case 'RECEIVE_CLIENT':
            return {
                ...state,
                clientCode: action.clientCode,
                clientContact: action.clientContact,
                clientEmail: action.clientEmail,
                message: undefined
            }
        case 'RECEIVE_LOGIN_POLICIES': return { ...state, loginPolicies: action.policies }
        case 'SAVE':
        case 'ERROR':
            return { ...state, message: action.message }
        case 'SAVING_ADMIN':
            return { ...state, message: undefined }
        case 'CLEAR_ACCOUNT_PREF_MESSAGE':
            return { ...state, message: undefined }
        default:{
            const exhaustiveCheck: never = action
        }
    }
    return state || unloadedState
}