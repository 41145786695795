import { AxiosError, AxiosResponse } from 'axios';
import { NCR, NCRPostModel, NCRVm } from 'src/interfaces/interfaces';
import { api } from '../api';

export const createNCRReport = async (
  issueId: number,
  report: NCRPostModel
) => {
  try {
    const form = new FormData();
    const capFiles = report.cAPFiles;
    const rcaFiles = report.rCAFiles;

    if (capFiles && capFiles.length > 0) {
      for (let i = 0; i < capFiles.length; ++i)
        form.append('cAPfiles', capFiles[i], capFiles[i].name);
    }

    if (rcaFiles && rcaFiles.length > 0) {
      for (let i = 0; i < rcaFiles.length; ++i)
        form.append('rCAfiles', rcaFiles[i], rcaFiles[i].name);
    }
    form.append(
      'json',
      JSON.stringify({ ...report, cAPFiles: null, rCAFiles: null })
    );

    const response: AxiosResponse<Blob> = await api.post(
      `api/NCR/Add?issueId=${issueId}`,
      form,
      {
        responseType: 'blob',
      }
    );

    if (response.status == 200) {
      const contentDisposition = response.headers['content-disposition'];
      const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
      const filename = match ? match[1] : 'NCR Report';
      const newResponse: [Blob, string] = [response.data, filename];

      return newResponse;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        const message = await (error.response.data as Blob).text();
        return JSON.parse(message) as { message: string };
      }
    }

    console.log(error);
  }
};

export const downloadNCRReport = async (file: string) => {
  try {
    const response: AxiosResponse<Blob> = await api.get(file);

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};
