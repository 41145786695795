import * as Admin from './admin';
import * as Attachments from './attachments';
import * as Config from './config';
import * as Detail from './projectDetail';
import * as Dfr from './dfr';
import * as Global from './global';
import * as Graphs from './graphs';
import * as GraphData from './graphDataStore';
import * as Import from './imports';
import * as MetaStore from './metaStore';
import * as Milestones from './milestones';
import * as Notifications from './notifications';
import * as KeyVault from './keyvault';
import {
  ProcessingNotificationReducer,
  ProcessingNotificationsState,
} from './processingNotifications';
import * as Procurement from './procurement';
import * as Project from './projects';
import { PunchlistState, PunchlistReducer } from './punchlist';
import * as Report from './report';
import * as RFI from './rfi';
import * as SWStore from './swStore';
import * as User from './user';
import SafetyKPIReducer, {
  SafetyKPIState,
} from './safetyKPI/safetyKPI.reducer';
import { VendorsState, VendorsReducer } from './vendors/vendors.reducer';
import WindowsReducer, { WindowsState } from './window/window.reducer';
import { CustomReportReducer, CustomReportState } from './customReport';
import { IssuesReducer, IssuesState } from './issues';
import { RFQReducer, RFQState } from './rfqs';
import { NCRReducer, NCRState } from './ncrs';

// The top-level state object
export interface ApplicationState {
  admin: Admin.Store;
  attachments: Attachments.Store;
  config: Config.Store;
  customReport: CustomReportState;
  detail: Detail.Store;
  dfr: Dfr.Store;
  global: Global.GlobalStore;
  graphs: Graphs.GraphStore;
  graphData: GraphData.GraphDataStore;
  imports: Import.ImportStore;
  issues: IssuesState;
  keyvault: KeyVault.KeyVaultState;
  meta: MetaStore.MetaStore;
  msList: Milestones.Store;
  ncrs: NCRState;
  notifications: Notifications.Store;
  processingNotifications: ProcessingNotificationsState;
  procurement: Procurement.Store;
  projects: Project.Store;
  punchlist: PunchlistState;
  report: Report.Store;
  rfi: RFI.Store;
  rfqs: RFQState;
  safetyKPI: SafetyKPIState;
  serviceWorker: SWStore.Store;
  user: User.Store;
  vendors: VendorsState;
  windows: WindowsState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  admin: Admin.reducer,
  attachments: Attachments.reducer,
  config: Config.reducer,
  customReport: CustomReportReducer,
  imports: Import.reducer,
  issues: IssuesReducer,
  global: Global.reducer,
  projects: Project.reducer,
  detail: Detail.reducer,
  graphs: Graphs.reducer,
  graphData: GraphData.reducer,
  keyvault: KeyVault.KeyVaultReducer,
  meta: MetaStore.reducer,
  msList: Milestones.reducer,
  ncrs: NCRReducer,
  notifications: Notifications.reducer,
  processingNotifications: ProcessingNotificationReducer,
  procurement: Procurement.reducer,
  punchlist: PunchlistReducer,
  report: Report.reducer,
  user: User.reducer,
  dfr: Dfr.reducer,
  rfi: RFI.reducer,
  rfqs: RFQReducer,
  safetyKPI: SafetyKPIReducer,
  serviceWorker: SWStore.reducer,
  vendors: VendorsReducer,
  windows: WindowsReducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
