import { AxiosError, AxiosResponse } from 'axios';
import { api } from '../api';
import { RFQ, RFQPostModel } from '../../interfaces/interfaces';


export const createRFQ = async (rfq: RFQPostModel) => {
  try {
    const form = new FormData();
    if (rfq.spec) {
      
        form.append('spec', rfq.spec, rfq.spec.name)
    }
    form.append("json", JSON.stringify(rfq))

    const response: AxiosResponse<RFQ> = await api.post(
      `/api/RFQ/Add`,
      form
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};