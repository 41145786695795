import * as React from "react";
import { ListItem } from "../interfaces/interfaces";

interface JoinedListProps {
	availableItems: ListItem[];
	selectedItems: ListItem[];
	title: string;
	disabled?: boolean;
	disabledMessage?: string;
	sort?: boolean;
	key?: string;
	style?: any;
	hideFilter?: boolean; // New prop to hide the filter input
	onChange?: (sel: ListItem[]) => void;
	receiveChange?: (ids: string[] | number[]) => void;
	onAdd?: (sel: ListItem[]) => void;
	onRemove?: (sel: ListItem[]) => void;
	
}

interface State {
	//filteredAvailableItems: ListItem[]
	selectedItems: ListItem[];
	filterText: string;
}

export class JoinedList extends React.Component<JoinedListProps, State> {
	constructor(props) {
		super(props);
		this.state = {
			//filteredAvailableItems: this.props.availableItems,
			filterText: "",
			selectedItems: this.props.selectedItems,
		};
	}

	componentDidUpdate(prevProps: JoinedListProps) {
		/* When the parent emits an updated set of items we need to update the state to get the changes */
		//if (nextProps.render) {
		if (
			prevProps.availableItems.length != this.props.availableItems.length ||
			JSON.stringify(prevProps.selectedItems) !==
				JSON.stringify(this.props.selectedItems)
		) {
			this.setState({
				//unfilteredItems: nextProps.availableItems,
				//filteredAvailableItems: this.props.availableItems,
				selectedItems: this.props.selectedItems,
			});
		}
	}

	// Create new arrays of the proper available and selected then set the new state
	private addItems(newItems: ListItem[]) {
		const oldSelected = this.state.selectedItems;
		const newList = oldSelected.length
			? newItems.length
				? oldSelected.concat(newItems)
				: oldSelected
			: newItems;
		let newSelected = !this.props.sort
			? newList
			: newList.sort((a, b) => {
					if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
					return 1;
			  });
		newSelected = newSelected.filter((x) => x.id !== undefined);
		//const newAvailable = this.state.filteredAvailableItems.filter(x => newItems.findIndex(i => i.id == x.id) === -1)
		this.setState({
			//filteredAvailableItems: newAvailable,
			selectedItems: newSelected,
		});

		if (this.props.onAdd) this.props.onAdd(newItems);
		if (this.props.onChange) this.props.onChange(newSelected);
	}
	// Create new arrays of the proper available and selected then set the new state
	private removeItems(removedItems: ListItem[]) {
		//let oldAvailable = this.state.filteredAvailableItems;
		//let oldAvailable = this.props.availableItems.filter(x => this.state.selectedItems.indexOf(x) === -1)
		//const newAvailable = [...oldAvailable, ...removedItems]
		//    .sort((a, b) => {
		//        if (a.value.toUpperCase() < b.value.toUpperCase()) return -1
		//        return 1
		//    })
		const newSelected = this.state.selectedItems.length
			? this.state.selectedItems.filter(
					(x) => removedItems.findIndex((i) => i.id == x.id) === -1
			  )
			: this.state.selectedItems;
		this.setState({
			//filteredAvailableItems: newAvailable,
			selectedItems: newSelected,
		});
		if (this.props.onRemove) this.props.onRemove(removedItems);
		if (this.props.onChange) this.props.onChange(newSelected);
	}

	// Get the selected items by querying the DOM and send them to function to update state
	addSelected(event: React.FormEvent<HTMLButtonElement>) {
		const btnContainer = event.currentTarget.parentElement;
		if (btnContainer) {
			const listContainer = btnContainer.parentElement;
			if (listContainer) {
				const list = listContainer.getElementsByClassName(
					"available-list"
				)[0] as HTMLSelectElement;
				const options = Array.prototype.slice.call(
					list.options
				) as Array<HTMLOptionElement>;
				const selected = options
					.filter((o) => o.selected)
					.map((i) => {
						//const deco = i.getAttribute('data-decoration')
						return new ListItem(i.value || 0, i.text);
					});
				this.addItems(selected);
			}
		}
	}

	// Get the removed items by querying the DOM and send them to function to update state
	removeSelected(event: React.FormEvent<HTMLButtonElement>) {
		const btnContainer = event.currentTarget.parentElement;
		if (btnContainer) {
			const listContainer = btnContainer.parentElement;
			if (listContainer) {
				const list = listContainer.getElementsByClassName(
					"selected-list"
				)[0] as HTMLSelectElement;
				const options = Array.prototype.slice.call(
					list.options
				) as Array<HTMLOptionElement>;
				const selected = options
					.filter((o) => o.selected)
					.map((i) => {
						//const deco = i.getAttribute('data-decoration')
						return new ListItem(i.value || 0, i.text);
					});
				this.removeItems(selected);
			}
		}
	}

	/* Takes the event target and uses its value to filter the available items and return a filtered list */
	private handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const filterText = e.currentTarget.value;
		//const filtered = this.props.availableItems
		//    .filter(i => i.value.toUpperCase().indexOf(filterText.toUpperCase()) !== -1);
		this.setState({ filterText: filterText });
	};

	render() {
		/* Filter down availables in case parent we are preserving selected but adding a new item */
		const availables = this.props.availableItems
			.filter((x) => {
				// if the filter text matches, remove it
				if (
					this.state.filterText.length &&
					x.value.toUpperCase().indexOf(this.state.filterText.toUpperCase()) ===
						-1
				)
					return false;
				// if the item is in the selected list, remove it
				if (
					this.state.selectedItems.length &&
					this.state.selectedItems.findIndex((y) => y.id == x.id) !== -1
				)
					return false;

				return true;
			})
			.slice();
		//if (this.state.selectedItems.length) availables = availables.filter(i => this.state.selectedItems.findIndex(s => s.id == i.id) == -1);
		const aItems = this.renderOptionList(availables),
			sItems = this.renderOptionList(this.state.selectedItems),
			key = aItems.length.toString();
		return (
			<div className="form-group joined-list" style={this.props.style}>
				{this.props.title.length ? (
					<label>Select {this.props.title}</label>
				) : (
					[]
				)}
				{!this.props.hideFilter && (
					<input
						id={`${this.props.title}_list_filter`}
						key="filter"
						className="form-control"
						placeholder={`Filter ${this.props.title}`}
						onChange={this.handleFilter}
						disabled={this.props.disabled}
						title={this.props.disabledMessage}
						value={this.state.filterText}
					/>
				)}
				
				<div className="joined-list-container" key={key}>
					<select
						key="available_list"
						disabled={this.props.disabled}
						title={this.props.disabledMessage}
						className="available-list form-control"
						multiple
					>
						{aItems}
					</select>
					<div className="button-container">
						<button
							key="button1"
							className="btn btn-sm btn-blue"
							onClick={this.addSelected.bind(this)}
							disabled={this.props.disabled}
							title={this.props.disabledMessage}
						>
							<span className="fas fa-chevron-right"></span>
						</button>
						<button
							key="button2"
							className="btn btn-sm btn-red"
							onClick={this.removeSelected.bind(this)}
							disabled={this.props.disabled}
							title={this.props.disabledMessage}
						>
							<span className="fas fa-chevron-left"></span>
						</button>
					</div>
					<select
						key="selected_list"
						disabled={this.props.disabled}
						title={this.props.disabledMessage}
						className="selected-list form-control"
						multiple
					>
						{sItems}
					</select>
				</div>
			</div>
		);
	}

	renderOptionList(items: ListItem[]) {
		return items && items.length
			? items.map((item, idx) => {
					const key = `${item.value}_${idx}`;
					//newClass = (item.message == 'new' ? 'new-item ' : ' ') + item.decorationClass;
					return (
						<option
							value={item.id}
							key={key}
							title={this.props.disabledMessage || item.value}
							//data-decoration={item.decorationClass}
						>
							{item.value}
						</option>
					);
			  })
			: [];
	}
}
