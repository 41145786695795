import * as React from 'react'
import cx from 'classnames';
import { FileExplorerItem } from '../interfaces/interfaces';
import Modal from '../components/Modal';

interface Props {
    projectId: number,
    assign: any,
    close: any,
    keyDocName: string,
}

interface State {
    targetDocPath?: string
    docs?: FileExplorerItem[]
    unfilteredDocs?: FileExplorerItem[]
}

export default class KeyDocAssigner extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentWillMount() {
        if (this.props.projectId)
            this._getProjectFiles(this.props.projectId)
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.projectId != this.props.projectId) {
            this._getProjectFiles(nextProps.projectId)
        }
    }

    private _getProjectFiles = (id: number) => {
        fetch(`api/FileExplorer/ProjectDir?id=${id}&subPath=${''}&searchText=${'.'}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    docs: data,
                    unfilteredDocs: data
                })
            })
    }

    private _setTargetedId = (e: React.MouseEvent<HTMLDivElement>) => {
        const targetPath = e.currentTarget.getAttribute('data-path');
        //const targetType = e.currentTarget.getAttribute('data-type');
        if (targetPath) this.setState({ targetDocPath: targetPath })
    }

    _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value.toUpperCase(),
            filtered = this.state.unfilteredDocs ?
                this.state.unfilteredDocs.slice()
                    .filter(x => x.path.toUpperCase().indexOf(val) !== -1)
                : []

        this.setState({ docs: filtered })
    }

    render() {
        const { targetDocPath } = this.state
        const fileItems = this.state.docs ?
            this.state.docs.sort((a, b) => {
                if (a.path.toUpperCase() > b.path.toUpperCase()) return 1;
                return -1;
            })
                .map(x => this.renderFileLine(x)) : []
        const listHeader = <div className='file-assign-header'><div className='col-sm-5'>File Name</div><div className='col-sm-4'>Path</div><div className='col-sm-3'><input className='form-control' placeholder='type to filter' onChange={(e) => this._filter(e)} /></div></div>
        return (
            <Modal id='test' dialogClass='file-assign-modal'>
                <div className="modal-header">
                    <h4 className="modal-title">Assign <b>{this.props.keyDocName}</b>
                    </h4>
                </div>
                <div className='modal-body viewer-body' style={{ 'minHeight': '7vh', 'maxHeight': '50vh' }}>
                    {listHeader}
                    {fileItems}
                </div>
                <div className='modal-footer'>
                    {targetDocPath && <span className="btn btn-sm btn-outline-secondary btn-green" onClick={this.props.assign.bind(null, targetDocPath)}>Assign</span>}
                    <span className="btn btn-sm btn-outline-secondary btn-outline-secondary" onClick={this.props.close}>Close</span>
                </div>
            </Modal>
        )
    }

    private renderFileLine(doc: FileExplorerItem) {
        const { targetDocPath } = this.state,
            pathSplit = doc.path.split('content')
        let folderPath = '/files' + pathSplit[pathSplit.length - 1].split(doc.name)[0].replace(/\\/g, ' > ');
        folderPath = folderPath.substr(0, folderPath.length - 3)

        return (
            <div key={doc.path} data-path={doc.path} className={cx('file-assign-line', targetDocPath == doc.path && 'active')} onClick={this._setTargetedId}>
                <div className='col-sm-5'>{doc.name}</div>
                <div className='col-sm-6'>{folderPath}</div>
            </div>
        )
    }
}