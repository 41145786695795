import * as React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../store'
import { ListItem, UserInfo, SubmittalStatus, SubmittalPackage, SubmittalPackageStatus, SubmittalPackagePostModel } from '../interfaces/interfaces'
import { MessageBox } from '../components/MessageBox'
import { RouteComponentProps } from 'react-router'
import { Loader } from '../components/Loader';
import { formatDateTime } from '../helpers/formatters';
import * as UserStore from '../store/user'
import { NavLink } from 'react-router-dom'
import * as cx from 'classnames'
import { JoinedList } from '../components/JoinedList'
import { SubmittalGrid } from './SubmittalGrid'
import PieChart from '../charts/Piechart'
import { submittalIsUnsent } from '../helpers/misc'
import { BackLink } from '../components/BackLink'

type Props =
    UserStore.Store
    & RouteComponentProps<{ id: string }>;

interface State {
    pkg: SubmittalPackage,
    unassignedSubmittals: ListItem[],
    projectNumber: string,     
    showDetails: boolean,
    showUpdateBtn: boolean,
    showSendBtn: boolean,
    loading: boolean,    
    approvedDocuments: number[]
    message?: string,
    errors?: any
}

class SubmittalPackageDetail extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            pkg: {} as SubmittalPackage, 
            unassignedSubmittals: [],
            projectNumber: '',
            showDetails: false,
            showUpdateBtn: false, 
            showSendBtn: false,
            approvedDocuments: [],
            loading: true
        }
    }

    componentDidMount() {        
        const id = parseInt(this.props.match.params.id) || 0
        this._getDetail(id)
    }

    private draftColor = '#F4D03F';
    private awaitingColor = '#1B57E6';
    private reviewedColor = '#009600';
    private rejectedColor = '#E66161';

    _getDetail = (id: number) => {
        fetch(`api/SubmittalPackage/Detail?id=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        pkg: data.package,
                        projectNumber: data.projectNumber,
                        unassignedSubmittals: data.submittals,
                        loading: false
                    })
                }
                else this.setState({ message: data.message, loading: false })
            })
    }

    _showUpdate = () => this.setState({ showUpdateBtn: true })

    _changeItems = (items: ListItem[]) => this._showUpdate()

    _toggleDetails = () => this.setState({ showDetails: !this.state.showDetails })

    _update = () => {
        this.setState({ loading: true })
        const desc = document.getElementById('package-desc') as HTMLTextAreaElement,
            submittals = (document.querySelectorAll('#submittal-list .selected-list>option'))
        const submittalIds: number[] = [];
        [].forEach.call(submittals, (i: HTMLOptionElement) => {
            submittalIds.push(parseInt(i.value));
        })
        const model = { description: desc ? desc.value : '', submittalIds } as SubmittalPackagePostModel
        fetch(`api/SubmittalPackage/Update?id=${this.state.pkg.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        pkg: data.package,
                        unassignedSubmittals: data.submittals,
                        showUpdateBtn: false,
                        loading: false
                    })
                }
                else this.setState({ message: data.message, loading: false })
            })
    }

    _send = () => {
        fetch(`api/SubmittalPackage/Send?id=${this.state.pkg.id}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        pkg: data.package,
                        message: 'Package Sent'
                    })
                }
                else this.setState({ errors: data.errors, loading: false })
            })
    }

    _clearMessage = () => this.setState({ message: undefined, errors: undefined })

    render() {
        const { projectNumber, pkg, showDetails } = this.state,            
            { user } = this.props;
        let status = '', statusClass = 'status-card';
        switch (pkg.status) {
            case SubmittalPackageStatus.Created: status = 'Draft'; statusClass += ' draft'; break;
            case SubmittalPackageStatus.Sent: status = 'Sent'; statusClass += ' awaiting'; break;
            case SubmittalPackageStatus.Complete: status = `Complete - ${formatDateTime(pkg.completedTimestamp)}`; statusClass += ' approved'; break;
        }
        const detailGlyph = showDetails ? 'fa-chevron-down' : 'fa-chevron-right'

        return (
            <div id='submittal-package-detail'>
                <BackLink link={`/project/${pkg.projectId}#submittals`} />                
                <MessageBox message={this.state.message} errors={this.state.errors} clearMessage={this._clearMessage} />
                <Loader loading={this.state.loading} />
                <h3>{projectNumber}</h3>
                <hr />
                {(pkg && pkg.id) ?
                    <div className='top-row' style={{ display: 'flex' }}>
                        <span style={{ marginTop: '10px' }}>{pkg.packageNumber}</span>
                        <div className={statusClass}>{status}</div>                        
                    </div>
                    : []
                }
                <h3 style={{ margin: '12px 0px 8px 0px' }}>{pkg.title}</h3>
                {(pkg && pkg.submittals && pkg.submittals.length) ? <div className='package-submittal-grid'><SubmittalGrid forPackage={true} /></div> : []}
                <div style={{ clear: 'both', height: '30px' }}></div>
                {(pkg && pkg.id) ? <div className='info-detail-header'>Details <span className={cx('fas', detailGlyph)} onClick={this._toggleDetails}></span></div> : []}
                {(showDetails) ? this.renderInfoSection(pkg, user) : []}                
                {/* Render a card showing completion time if the package is complete */}
            </div>
        )
    }

    private renderInfoSection(pkg: SubmittalPackage, user?: UserInfo) {
        const isCreator = user ? user.userId === pkg.userId : false,
            { status } = pkg,
            editable = (isCreator && status === SubmittalPackageStatus.Created),
            divisions = pkg.submittals ? [...new Set(pkg.submittals
                .map(x => x.division.name))]
                .sort((a, b) => a > b ? 1 : -1)
                .map(x => <div key={x}>{x}</div>)                
            : [],
            selList = pkg.submittals ? pkg.submittals.map(x => new ListItem(x.id, x.title)) : []
            
        return (
            <div className='detail-parent'>
            <div className='submittal-package-info'>
                <div className='row'>
                    <div className='col-sm-3'><label>Creator</label></div>
                    <div className='col-sm-8'>{pkg.userFullName}</div>
                </div>
                <div className='row'>
                    <div className='col-sm-3'><label>Divisions</label></div>
                    <div className='col-sm-8'>{divisions}</div>
                </div>
                <div className='row'>
                    <div className='col-sm-3'><label>Description</label></div>
                    <div className='col-sm-8'>
                            {editable ?
                                <textarea id='package-description' defaultValue={pkg.description} className='form-control' onChange={this._showUpdate} rows={3}></textarea> 
                                : pkg.description
                            }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3'><label>Submittals</label></div>
                    <div className='col-sm-8' id='submittal-list'>
                        <JoinedList selectedItems={selList} availableItems={this.state.unassignedSubmittals}
                            disabled={!editable} title='' onChange={this._changeItems} />
                    </div>
                </div>                
                {this.renderButtonSection(pkg, user)}
                </div>
                {this.renderChart(pkg)}
            </div>
        )
    }

    private renderButtonSection(pkg: SubmittalPackage, user?: UserInfo) {
        const { showUpdateBtn, showSendBtn } = this.state;
        const showSend = showSendBtn ? true :
            user ? (user.userId === pkg.userId && pkg.submittals.length >= 2 && pkg.submittals.filter(x => submittalIsUnsent(x)).length > 0) : false;

        return (
            <div className='submittal-btn-row'>
                {showUpdateBtn &&
                    <button className='btn btn-sm btn-warning' onClick={this._update}>Save Changes</button>
                }
                {showSend &&
                    <button className='btn btn-sm btn-green' onClick={this._send}
                        title='Sending this package will issue all the linked submittals to their respective reviewers'
                    >Send</button>
                }
            </div>
        )
    }

    private renderChart(pkg: SubmittalPackage) {
        let chart: JSX.Element = <div></div>;
        if (pkg.submittals && pkg.submittals.length) {
            const pts: any[][] = [], options: any = {};
            const draftCt = pkg.submittals.filter(x => submittalIsUnsent(x)).length;
            const revCt = pkg.submittals.filter(x => x.status === SubmittalStatus.Submitted).length;
            const doneCt = pkg.submittals.filter(x => x.status === SubmittalStatus.Approved).length;
            const rejCt = pkg.submittals.filter(x => x.status === SubmittalStatus.Rejected).length;
            pts.push(['Draft / Creator Review', draftCt, 'fill-opacity: 0.2'], ['Awaiting Review', revCt, 'opacity: 0.8'], ['Reviewed', doneCt, 'opacity: 0.8'], ['Rejected', rejCt, 'opacity: 0.8'])
            options.title = 'Submittal Items by Status';
            //options.is3D = true
            options.pieHole = 0.5
            options.opacity = 0.8
            options.colors = [this.draftColor, this.awaitingColor, this.reviewedColor, this.rejectedColor]
            //if (draftCt > 0) options.colors.push(this.draftColor)
            //if (revCt > 0) options.colors.push(this.awaitingColor)
            //if (doneCt > 0) options.colors.push(this.reviewedColor)
            //if (rejCt > 0) options.colors.push(this.rejectedColor)
            chart = <PieChart graphId='submittal-package-chart'
                dataPoints={pts} options={options}
            />
        }

        return (
            <div className='submittal-chart'>
                {chart}
            </div>
        )
    }
       
}

//@ts-ignore
export default connect(
    (state: ApplicationState) => state.user,
    UserStore.actionCreators
)(SubmittalPackageDetail) as typeof SubmittalPackageDetail