import * as React from 'react';
import { MessageBox } from '../../components/MessageBox';

interface Props {
    clientId: string | number
}

interface State {
    config: ClientGeneralConfig
    message?: string
}

export interface ClientGeneralConfig {
    id: number,
    clientId: number,
    allowUserEnteredRFIDate: boolean,
    rfiDueDateDaysOffset: number,
    rfiDueRollverHour: number,
    allowUserEnteredSubmittalDate: boolean,
    submittalDueDateDaysOffset: number,
    submittalDueRolloverHour: number

}

export default class GeneralConfigForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            config: { id: 0, clientId: 0, allowUserEnteredRFIDate: false, allowUserEnteredSubmittalDate: false, rfiDueDateDaysOffset: 0, submittalDueDateDaysOffset: 0 } as ClientGeneralConfig
        }
    }

    componentDidMount() {
        this._getGeneralConfig(this.props.clientId)
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.clientId != this.props.clientId)
            this._getGeneralConfig(this.props.clientId)
    }

    _getGeneralConfig = (clientId: number | string) => {
        if (clientId !== undefined && clientId != 0) {
            fetch(`api/ClientGeneralConfig/GetConfig?clientId=${clientId}`)
                // @ts-ignore
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    this.setState({ config: data })
                })
        }
    }

    _save = () => {
        const form = document.querySelector('div#generalClientConfig') as HTMLDivElement,
            allowRfi = (form.querySelector('#allowUserEnteredRFIDate') as HTMLInputElement).checked,
            allowSubbmital = (form.querySelector('#allowUserEnteredSubmittalDate') as HTMLInputElement).checked,
            rfiOffset = (form.querySelector('#rfiDueDateDaysOffset') as HTMLInputElement).value,
            submittalOffset = (form.querySelector('#submittalDueDateDaysOffset') as HTMLInputElement).value

        const postBody = {
            id: this.state.config.id,
            clientId: this.state.config.clientId,
            allowUserEnteredRFIDate: allowRfi,
            allowUserEnteredSubmittalDate: allowSubbmital,
            rfiDueDateDaysOffset: rfiOffset,
            submittalDueDateDaysOffset: submittalOffset
        };


        fetch(`api/ClientGeneralConfig/Update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ config: data, message: 'Saved' })
                else this.setState({ message: data.message })
            })
    }

    _toggleAllowRfi = () => {
        const { config } = this.state;
        config.allowUserEnteredRFIDate = !config.allowUserEnteredRFIDate
        this.setState({ config: config })
    }

    _toggleAllowSubmittal = () => {
        const { config } = this.state;
        config.allowUserEnteredSubmittalDate = !config.allowUserEnteredSubmittalDate
        this.setState({ config: config })
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { config, message } = this.state
        if (!config) return <div></div>
        return (
            <div className='config-form config-flex max-350'>
                <MessageBox message={message} clearMessage={this._clearMessage} />
                <div className='config-new-item-form' style={{ minWidth: '50%' }}>
                    <div id='generalClientConfig'>                        
                        <div className="form-header">
                            <h5 className="modal-title">General Config Options</h5>
                        </div>
                        <div className="form-body">
                            <div className='form-group'>
                                <label>Allow Users to Enter RFI Due Dates: </label> &nbsp;
                                <input key={config.id} id='allowUserEnteredRFIDate' name='allowUserEnteredRFIDate' type='checkbox' checked={config.allowUserEnteredRFIDate} onChange={this._toggleAllowRfi} />
                            </div>
                            <div className='form-group'>
                                <label>RFI Due Date Offset <small>(business days)</small></label>
                                <input key={config.id} id='rfiDueDateDaysOffset' name='rfiDueDateDaysOffset' className="form-control" type='number' defaultValue={config.rfiDueDateDaysOffset} disabled={config.allowUserEnteredRFIDate} />                                
                            </div>
                            <div className='form-group'>
                                <label>Allow Users to Enter RFI Due Dates: </label> &nbsp;
                                <input key={config.id} id='allowUserEnteredSubmittalDate' name='allowUserEnteredSubmittalDate' type='checkbox' checked={config.allowUserEnteredSubmittalDate} onChange={this._toggleAllowSubmittal} />
                            </div>
                            <div className='form-group'>
                                <label>Submittal Due Date Offset <small>(business days)</small></label>
                                <input key={config.id} id='submittalDueDateDaysOffset' name='submittalDueDateDaysOffset' className="form-control" type='number' defaultValue={config.submittalDueDateDaysOffset} disabled={config.allowUserEnteredSubmittalDate} />
                            </div>
                        </div>
                        <div className='form-footer'>
                            <button className="btn btn-sm btn-blue" onClick={this._save}>Save</button>
                        </div>
                    </div>
                </div>                
            </div>
        )
    }
}