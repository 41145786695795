import * as React from 'react';
import cx from 'classnames'

interface Props {
    label: string,
    value: string,
    size?: string,
    title?: string
}


export default class KPI extends React.Component<Props, any> {
 

    render() {
        const { size, title } = this.props        

        return (
            <div className={cx('kpi', size)} title={title}>
                <div className='kpi-label'>
                    <p>{this.props.label}</p>
                </div>
                <hr/>
                <div className='kpi-value'>
                    <p>{this.props.value}</p>
                </div>
            </div>
        )
    }
}