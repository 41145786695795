import * as React from 'react';
import * as cx from 'classnames';

import { formatDateTime } from '../helpers/formatters';
import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
    notification: UserNotification,
    dismissable: boolean,
    fullPage: boolean,
}

export const NotificationItem = (props: Props) => {

    const { notification, dismissable, fullPage } = props;
    const [hovered, setHovered] = React.useState(false);

    const dispatch = useDispatch();

    const _handleHover = (e: React.MouseEvent<HTMLElement>) => {
        if (notification.status === UserNotificationStatus.Unread && !fullPage) {
            dispatch(actionCreators.markAsRead([notification.id], false));
        }
        setHovered(true)
    }

    return (
        <div className={cx('notification-item', (!fullPage && notification.status === UserNotificationStatus.Unread) && 'unread')} onMouseEnter={_handleHover} onMouseLeave={() => setHovered(false)}>
            <div className='notification-timestamp'>{formatDateTime(notification.timestamp)}</div>
            <div className='notification-message'><span>{notification.message}</span>
                {notification.linkUri !== '' ?
                    <React.Fragment>
                        &nbsp; &#8226; &nbsp;
                        <Link to={notification.linkUri}>{notification.linkText}</Link>
                    </React.Fragment> : []
                }
            </div>
            {(hovered && dismissable) && <span className='fas fa-times' title='Dismiss this notification' onClick={() => dispatch(actionCreators.dismiss([notification.id]))}></span>}
        </div>
    )
}