import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const PieChartLoader = () => {

    return <ContentLoader 
        speed={2}
        interval={0}
        gradientRatio={0.5}
        width={'100%'}
        height={400}
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >
        <circle cx="316" cy="201" r="123"></circle>
    </ContentLoader>
}