import * as React from 'react';
import { connect } from 'react-redux';
import { QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants';
import * as UserStore from '../../store/user';
import { ApplicationState } from '../../store/index';
import { Loader } from '../Loader';
import { AxiosResponse } from 'axios';
import { api } from 'src/services/api';
import register from 'src/registerServiceWorker';
import styled from 'styled-components';

const StyledP = styled.p`
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(230, 97, 97, 0.9);
  color: white;
`;

const VerticalBar = styled.div({
  marginTop: '20px',
  marginBottom: '20px',
  borderRight: '1px solid lightgray',
  marginLeft: '30px',
  marginRight: '30px',
  '@media(max-width: 868px)': {
    marginLeft: '60px',
    marginRight: '60px',
    borderTop: '1px solid lightgray',
    width: '100%',
  },
});

interface State {
  email: string;
  pass: string;
  remember: boolean;
  valid: boolean;
  errMessage?: string;
  returnUrl: string;
  loading: boolean;
  pageType: LoginPageEnum;
  twoFactorChannel?: string;
  signInResult?: SignInResult;
  register: boolean;
}

type Props = typeof UserStore.actionCreators;

enum LoginPageEnum {
  Login,
  TwoFactorRequest,
  TwoFactorVerify,
  LockedOut,
  LoginFailed,
}

interface SignInResult {
  succeeded: boolean;
  isLockedOut: boolean;
  isNotAllowed: boolean;
  requiresTwoFactor: boolean;
  maskedPhone: string;
  maskedEmail: string;
  smsVerified: boolean;
  emailConfirmed: boolean;
}

class LoginPage extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      pass: '',
      valid: true,
      remember: false,
      loading: false,
      pageType: LoginPageEnum.Login,
      returnUrl: this._getReturnUrl(),
      register: false,
    };
  }

  componentWillUnmount() {
    this.setState({ loading: false });
  }

  componentWillMount() {
    const params = new URLSearchParams(window.location.search);
    const message = params.get('message');

    if (message == 'register') {
      this.setState({ register: true });
    }
  }

  _getReturnUrl() {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    //@ts-ignore
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      );
    }
    return (
      (this.state && this.state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}/`
    );
  }

  _updateEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: e.currentTarget.value });
  _updatePass = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ pass: e.currentTarget.value });
  _toggleRemember = () => this.setState({ remember: !this.state.remember });

  _login = () => {
    const { email, pass, remember, returnUrl } = this.state;
    this.setState({ loading: true, valid: true });
    fetch(`account/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email,
        password: pass,
        rememberMe: remember,
        returnUrl: returnUrl,
      }),
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        this.setState({ loading: false });
        if (!resOk) this.setState({ valid: false, errMessage: data.message });
        else {
          if (data.succeeded) {
            this.props.getUser();
          } else if (data.requiresTwoFactor) {
            this.setState({
              pageType: LoginPageEnum.TwoFactorRequest,
              signInResult: data,
            });
          } else if (data.isNotAllowed) {
            this.setState({
              pageType: LoginPageEnum.LoginFailed,
              signInResult: data,
            });
          } else if (data.isLockedOut) {
            this.setState({
              pageType: LoginPageEnum.LockedOut,
              signInResult: data,
            });
          } else {
            // If we get here the login failed but not due to lockout or 2fa, so the credentials much be wrong
            this.setState({
              pageType: LoginPageEnum.LoginFailed,
              signInResult: data,
              valid: false,
              errMessage:
                'Login failed. Please double check your credentials or reset your password.',
            });
          }
          //window.location.assign(this.state.returnUrl)
        }
      });
  };

  _requestCode = () => {
    const channelInput = document.querySelector(
      'input[name="channel"]:checked'
    ) as HTMLInputElement;
    if (channelInput === undefined)
      alert('You must select a verification channel to proceed');

    this.setState({ twoFactorChannel: channelInput.value });
    fetch(`account/RequestLoginCode?channel=${channelInput.value}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ pageType: LoginPageEnum.TwoFactorVerify });
        else alert(data.message);
      });
  };

  _verifyCode = () => {
    const codeInput = document.getElementById(
        'verificationCode'
      ) as HTMLInputElement,
      code = codeInput.value;
    fetch(
      `account/verifylogincode?channel=${this.state.twoFactorChannel}&code=${code}&rememberMe=${this.state.remember}`
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          if (data.verified) {
            this.props.getUser();
          } else {
            codeInput.value = '';
            alert(
              'Verification failed. Please try again or generate a new code.'
            );
          }
        } else alert(data.message);
      });
  };

  _coreLogin = async () => {
    window.location.href = '/account/corelogin';
  };

  render() {
    const {
      email,
      pass,
      remember,
      valid,
      errMessage,
      loading,
      pageType,
      twoFactorChannel,
      signInResult,
    } = this.state;

    let formBody = <div></div>;
    switch (pageType) {
      case LoginPageEnum.Login:
      case LoginPageEnum.LoginFailed:
        formBody = (
          <div style={{ width: '100%' }}>
            <div className="form-group">
              <label>Email</label>
              <input
                className="form-control"
                type="email"
                onChange={this._updateEmail}
                value={email}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                className="form-control"
                type="password"
                onChange={this._updatePass}
                value={pass}
              />
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                onChange={this._toggleRemember}
                checked={remember}
              />
              &nbsp; Remember Me?
            </div>
            <div className="form-group">
              <button
                className="btn btn-outline-secondary"
                onClick={this._login}
              >
                Log In
              </button>
            </div>
            <div className="form-group">
              <p>
                <a href="/forgot-password">Forgot my password</a>
              </p>
            </div>
          </div>
        );
        break;
      case LoginPageEnum.TwoFactorRequest:
        formBody = (
          <div>
            <div className="form-group">
              <p>
                This account requires two-factor authentication. Please select
                your preferred verification method below.
              </p>
            </div>
            <div className="form-group">
              <div>
                <label>
                  <b>Select Verification Channel</b>
                </label>
              </div>
              {signInResult && signInResult.smsVerified ? (
                <React.Fragment>
                  <input type="radio" name="channel" value="sms" />
                  <span>
                    {' '}
                    SMS (Text) {signInResult ? signInResult.maskedPhone : ''}
                  </span>{' '}
                  <br />
                </React.Fragment>
              ) : (
                []
              )}
              {signInResult && signInResult.emailConfirmed ? (
                <React.Fragment>
                  <input type="radio" name="channel" value="email" />
                  <span>
                    {' '}
                    Email {signInResult ? signInResult.maskedEmail : ''}
                  </span>
                </React.Fragment>
              ) : (
                []
              )}
            </div>
            <div className="form-group">
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={this._requestCode}
              >
                Request Code
              </button>
            </div>
          </div>
        );
        break;
      case LoginPageEnum.TwoFactorVerify:
        formBody = (
          <div>
            <div className="form-group">
              <p>
                Please enter your verification code below.{' '}
                <u>This code will expire ten minutes after delivery.</u>
              </p>
            </div>
            <div className="form-group">
              <label>Code</label>
              <input
                type="text"
                className="form-control"
                id="verificationCode"
              />
            </div>
            <div className="form-group">
              <button
                className="btn btn-sm btn-outline-secondary"
                onClick={this._verifyCode}
              >
                Submit
              </button>
            </div>
            <div className="form-group">
              <p>
                Didn't receive a code? Click{' '}
                <a
                  href="#"
                  onClick={() =>
                    this.setState({ pageType: LoginPageEnum.TwoFactorRequest })
                  }
                >
                  here to request a new one
                </a>
                .
              </p>
            </div>
          </div>
        );
        break;
    }

    return (
      <>
        {this.state.register && (
          <p style={{ textAlign: 'center' }} className="warning-note">
            A registration email has been sent to your email. Please follow the
            instructions in the email. If you do not initially see this email in
            your inbox, please verify your spam folder.
          </p>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div
            id="loginForm"
            className="login-page"
            style={{ maxWidth: '500px' }}
          >
            <Loader loading={loading} />
            <h2>Core SiteVue Login</h2>
            {!valid && <StyledP>{errMessage}</StyledP>}
            <div>
              <hr />
              {formBody}
            </div>
          </div>
          <VerticalBar />
          <div style={{ padding: '30px' }}>
            <h2>Single-Sign On</h2>
            <hr />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '25px',
              }}
            >
              <button
                className="btn btn-sm btn-pure-white"
                onClick={this._coreLogin}
                style={{ width: '100%', height: '40px', position: 'relative' }}
              >
                <img
                  src="/img/CSG-Logo-Secondary-Stacked.png"
                  height="30px"
                  style={{ left: '5px', position: 'absolute', top: '5px' }}
                />
                Core States Login
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state: ApplicationState) => state.user,
  UserStore.actionCreators
  //@ts-ignore
)(LoginPage);
