import * as React from 'react';
import { Link } from 'react-router-dom';
import { ListItem, Submittal } from '../interfaces/interfaces';

interface Props  {
    toFrom: string,
    info: ListItem;
}

export const SubmittalCopyReference = (props: Props) => (
       
    <div className='copy-reference-section'>
        <Link to={`/submittal/${props.info.id}`}>Copied {props.toFrom} {props.info.value}</Link>
    </div>
)


//export default class SubmittalCopyReference extends React.Component<Props, State> {
//    constructor(props) {
//        super(props)
//        this.state = {
          
//        }
//    }

//    componentDidMount() {
        
//    }

//    //_getSubmittal = (id: number) => {
//    //    fetch(`api/Submittal/SkinnyDetail?id=${id}`)
//    //        .then(res => Promise.all([res.ok, res.json()]))
//    //        .then(([resOk, data]) => {
//    //            if (resOk) {
//    //                this.setState({
//    //                    submittalNumber: data.submittalNumber,
//    //                    title: data.title
//    //                })
//    //            }
//    //        })
//    //}

//    render() {
//        const { submittalNumber, title } = this.state,
//            link = `/submittal/${this.props.submittalId}`

//        return (
//            <div className='copy-reference-section'>
//                <a href={link}>Copied from {submittalNumber} - {title}</a>
//            </div>
//        )
//    }


//}