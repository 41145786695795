import * as React from 'react';
import { ListItem } from '../../interfaces/interfaces';
import cx from 'classnames'
import Modal from '../../components/Modal';

interface Props {
    clientId?: number,
    templateId: number,
    templateName: string,
    close: any
}

interface State {
    allMilestones: ListItem[],
    selectedMilestones: ListItem[]
}

export default class MilestoneTemplater extends React.Component<Props, State> {

    constructor(props) {
        super(props)
        this.state = {
            allMilestones: [],
            selectedMilestones: []
        }
    }

    componentWillMount() {
        this._getMilestones(this.props.templateId, this.props.clientId)
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.clientId !== this.props.clientId || nextProps.templateId !== this.props.templateId) {
            this._getMilestones(this.props.templateId, this.props.clientId)
        }
    }

    _getMilestones = (templateId: number, clientId?: number, ) => {
        fetch(`api/Client/MSTemplate?templateId=${templateId}&clientId=${clientId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        allMilestones: data.allMilestones,
                        selectedMilestones: data.selectedMilestones
                    })
                }
            })
    }

    _save = () => {
        const checkedBoxes = document.querySelectorAll('#templatePopup .template_milestones > div > input:checked')
        const checkedIds: number[] = []
        if (checkedBoxes && checkedBoxes.length) {
            [].forEach.call(checkedBoxes, (box: HTMLInputElement) => {
                checkedIds.push(parseInt(box.value))
            })
        }
        fetch(`api/Client/SaveMSTemplate?templateId=${this.props.templateId}&clientId=${this.props.clientId}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(checkedIds)
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.props.close()
                else alert('Error occured: ' + data.message);
            })
    }

    render() {
        const milestoneChkBxs = this.renderCheckBoxes(this.state.allMilestones, this.state.selectedMilestones)

        return (
            <Modal id='templatePopup'>
                <div className='modal-header'>
                    Select Template Milestones for {this.props.templateName}
                </div>
                <div className='modal-body'>
                    {milestoneChkBxs}
                </div>
                <div className='modal-footer'>
                    <span className='btn btn-sm btn-green'
                        onClick={this._save}>Save</span>
                    <span className='btn btn-sm btn-outline-secondary'
                        onClick={this.props.close}>Close</span>

                </div>
            </Modal>
        )
    }

    renderCheckBoxes(all: ListItem[], selected: ListItem[]) {

        const boxes = all.map(x => {
            const checked = selected.findIndex(i => i.id === x.id) !== -1
            return (
                <div>
                    <input type='checkbox' value={x.id} defaultChecked={checked} />
                    {x.value}
                </div>
            )
        })

        return (
            <div className='template_milestones'>
                {boxes}
            </div>
        )
    }
}