import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MessageBox } from '../components/MessageBox';
import { Tabs, TabLink, TabContent } from '../tabs';
import { Loader } from '../components/Loader';
import { KnownRoles } from '../auth/auth';
import { MyAuthElement } from '../auth/Authorization';
import ClientConfig from './client/ClientConfig';
import GlobalConfig from './global/GlobalConfig';
import ImportHome from './imports/ImportHome';
import TrackerPipelineConfigView from './trackerpipeline/TrackerPipelineConfig';
import { CustomFieldHome } from './customFields/CustomFieldHome';
import { ChecklistHome } from './checklists/ChecklistHome';
import { WorkRegions } from './global/WorkRegions';
import { SafetyKPITemplateHome } from './safetyKPIs/SafetyKPITemplateHome';
import { QualityReportHome } from './qualityReports/QualityReportHome';
import { PunchlistHome } from './punchlist/PunchlistHome';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { actionCreators as ConfigActions } from '../store/config';
import { useDispatch } from 'react-redux';

type ConfigHomeProps = {
  selectedTab?: string;
  hideTabs?: boolean;
};

export const ConfigHome = (props: ConfigHomeProps) => {
  const configStore = useSelector((s: ApplicationState) => s.config);
  const dispatch = useDispatch();

  const CSGComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
  ]);

  const SuperConfigAuth = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
  ]);

  const activeTabStyle = {
    backgroundColor: 'rgb(0,99,66)',
    border: 'solid 0.5px rgb(0,56,119)',
    color: 'white',
  };

  return (
    <div>
      <MessageBox
        message={configStore.message}
        clearMessage={() => dispatch(ConfigActions.clearMessage())}
      />
      <Loader loading={configStore.isLoading || false} />
      <div className="pageHeader">Configuration</div>
      <div>
        <Tabs
          name="config-tabs"
          onChange={(tab: string) => console.log(`Tab selected: ${tab}`)}
          activeLinkStyle={activeTabStyle}
          renderActiveTabContentOnly={true}
          defaultTab={props.selectedTab ? props.selectedTab : undefined}
        >
          <div
            style={{ display: props.hideTabs ? 'none' : undefined }}
            className="tab-links bordered"
          >
            <CSGComp componentType="span">
              <TabLink to="client" component="span">
                <span>Client Config</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="customFields" component="span">
                <span>Custom Fields</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="checklists" component="span">
                <span>Checklists</span>
              </TabLink>
            </CSGComp>
            <SuperConfigAuth componentType="span">
              <TabLink to="kpis" component="span">
                <span>Safety KPIs</span>
              </TabLink>
            </SuperConfigAuth>
            <CSGComp componentType="span">
              <TabLink to="qualityReports" component="span">
                <span>Quality Reports</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="punchlists" component="span">
                <span>Punchlists</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="global" component="span">
                <span>Global Config</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="regions" component="span">
                <span>Regions</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="imports" component="span">
                <span>Imports</span>
              </TabLink>
            </CSGComp>
            <CSGComp componentType="span">
              <TabLink to="pipeline" component="span">
                <span>Tracker</span>
              </TabLink>
            </CSGComp>
          </div>
          <div
            className="content bordered custom-scrollbar"
            style={{ overflowY: 'auto', maxHeight: '75vh' }}
          >
            <TabContent for="client">
              <ClientConfig />
            </TabContent>
            <TabContent for="customFields">
              <CustomFieldHome />
            </TabContent>
            <TabContent for="checklists">
              <ChecklistHome />
            </TabContent>
            <TabContent for="kpis">
              <SafetyKPITemplateHome />
            </TabContent>
            <TabContent for="qualityReports">
              <QualityReportHome />
            </TabContent>
            <TabContent for="punchlists">
              <PunchlistHome />
            </TabContent>
            <TabContent for="global">
              <GlobalConfig />
            </TabContent>
            <TabContent for="regions">
              <WorkRegions />
            </TabContent>
            <TabContent for="imports">
              <ImportHome />
            </TabContent>
            <TabContent for="pipeline">
              <TrackerPipelineConfigView />
            </TabContent>
          </div>
        </Tabs>
      </div>
    </div>
  );
};
