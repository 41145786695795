import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPunchItems } from '../services/punchlist/index';
import { ApplicationState } from '../store/index';
import {
  PunchlistActions,
  addPunchItemRequestMessageSelector,
  addPunchItemRequestStatusSelector,
  filteredPunchItemsSelector,
  punchItemsSelector,
} from '../store/punchlist/index';
import { PunchlistFocusSection } from './PunchItemFocusSection';
import { PunchlistSummary } from './PunchlistSummary';
import * as cx from 'classnames';
import {
  mockPunchItem,
  mockPunchItem2,
  mockPunchItem3,
  mockPunchItem4,
} from '../services/punchlist/mocks';
import { PunchItemAddModal } from './PunchItemAddModal';
import { GlobalMessageBox } from 'src/components/MessageBox';
import { PunchlistDownloadModal } from './PunchlistDownloadModal';
import { PunchlistImportModal } from './PunchlistImportModal';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

enum PunchlistPageOptions {
  SummaryPage,
  FocusPage,
}

export const PunchlistHome = () => {
  const [activePage, setActivePage] = React.useState(
    PunchlistPageOptions.SummaryPage
  );
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showImportModal, setShowImportModal] = React.useState(false);

  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const userStore = useSelector((s: ApplicationState) => s.user);
  const items = useSelector(punchItemsSelector);
  const filteredItems = useSelector(filteredPunchItemsSelector);
  const addPunchItemMessage = useSelector(addPunchItemRequestMessageSelector);
  const addPunchItemStatus = useSelector(addPunchItemRequestStatusSelector);
  const [showDownloadModal, setShowDownloadModal] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(PunchlistActions.getPunchItems(projectStore.projId));
  }, [projectStore.projId]);

  React.useEffect(() => {
    if (addPunchItemStatus == 'success') {
      setShowAddModal(false);
    }
  }, [addPunchItemMessage, addPunchItemStatus]);

  const sendPunchItems = React.useCallback(() => {
    dispatch(PunchlistActions.sendPunchItems(projectStore.projId));
  }, [projectStore.projId]);

  const isPM = React.useMemo(() => {
    return projectStore.detail.internalPmId == userStore.user?.userId;
  }, [projectStore.detail.internalPmId, userStore.user?.userId]);

  return (
    <div>
      <GlobalMessageBox />
      {showAddModal && (
        <PunchItemAddModal
          close={() => setShowAddModal(false)}
          projectId={projectStore.projId}
        />
      )}
      {showImportModal && (
        <PunchlistImportModal
          projectId={projectStore.projId}
          close={() => setShowImportModal(false)}
        />
      )}
      {showDownloadModal && (
        <PunchlistDownloadModal close={() => setShowDownloadModal(false)} />
      )}
      {projectStore.detail.projectNumber ? (
        <div
          className="punchlist-btn-row"
          style={{ marginBottom: '10px', display: 'flex' }}
        >
          <button
            className="btn btn-sm btn-blue"
            style={{ fontSize: '12px' }}
            onClick={() => setShowAddModal(true)}
          >
            <span className="fas fa-plus"></span>&nbsp; Create New Punch Item
          </button>
          &nbsp;
          <button
            className="btn btn-sm btn-blue"
            style={{ fontSize: '12px' }}
            onClick={() => setShowImportModal(true)}
          >
            <span className="fas fa-upload"></span>&nbsp; Create from Template /
            Spreadsheet
          </button>
          &nbsp;
          {isPM && (
            <button
              className="btn btn-sm btn-green"
              style={{ fontSize: '12px' }}
              onClick={() => sendPunchItems()}
            >
              <span className="fas fa-share-from-square"></span>&nbsp; Send
              Punch Items
            </button>
          )}
          &nbsp;
          <div className="btn-group" role="group" aria-label="Page selection">
            <button
              type="button"
              className={cx(
                'btn btn-sm',
                activePage === PunchlistPageOptions.SummaryPage
                  ? 'btn btn-secondary'
                  : 'btn-outline-secondary'
              )}
              onClick={() => setActivePage(PunchlistPageOptions.SummaryPage)}
              style={{ fontSize: '12px' }}
            >
              Summary
            </button>
            <button
              type="button"
              className={cx(
                'btn btn-sm',
                activePage === PunchlistPageOptions.FocusPage
                  ? 'btn btn-secondary'
                  : 'btn-outline-secondary'
              )}
              onClick={() => setActivePage(PunchlistPageOptions.FocusPage)}
              style={{ fontSize: '12px' }}
            >
              Focus
            </button>
          </div>
          &nbsp;
          <button
            className="fas fa-download btn btn-sm btn-background-hover"
            onClick={() => setShowDownloadModal(true)}
            title="Show download options"
            style={{ borderRadius: '45%' }}
          ></button>
        </div>
      ) : (
        <StandardGridLoader rowCount={1} rowContentHeight={28} rowPadding={4} />
      )}
      {activePage === PunchlistPageOptions.SummaryPage && (
        <PunchlistSummary items={items} />
      )}
      {activePage === PunchlistPageOptions.FocusPage && (
        <PunchlistFocusSection items={filteredItems} />
      )}
    </div>
  );
};
