import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NoAuthElement, MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { useLocation } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import { SidebarMenuButtons } from './SidebarMenuButtons';
import { useOutsideClickHandler } from 'src/helpers/customhooks';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import * as cx from 'classnames';
import { actionCreators } from '../store/user';
import { useDispatch } from 'react-redux';

const IconDiv = styled.div(() => ({
  marginBottom: '32px',
  paddingBottom: '6px',
  cursor: 'pointer',
  '& i': {
    color: '#ffffff',
  },
  'a.active & i': {
    color: '#009ade',
  },
  '&.active i': {
    color: '#009ade',
  },
  '&:hover i': {
    color: '#ff9e18 !important',
  },
}));

const SidebarContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '100vh',
});

const NavbarContainer = styled.div({
  backgroundColor: '#353536',
  padding: '10px',
  paddingTop: '64px',
  zIndex: 4,
});
const SlideOutContainer = styled.div({
  zIndex: 3,
});

const slideOut = keyframes`
  0% { transform: translateX(-100%);  visibility: visible   
      }
  100% { transform: translateX(0%);    
      }
`;

const slideIn = keyframes`
  0% { transform: translateX(0%);    
  }
  100% { transform: translateX(-100%); visibility: hidden; }
`;

const SlideOutContent = styled.div<{
  isSelected: boolean;
  pinned: boolean;
  mounting: boolean;
}>(
  (props) =>
    css`
      visibility: ${props.mounting ? 'hidden' : 'visible'};
      animation: ${props.pinned
          ? 'unset'
          : props.isSelected
          ? slideOut
          : slideIn}
        0.4s ease-in-out forwards;
      position: ${props.pinned ? 'relative' : 'absolute'};
    `,
  {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgb(99, 101, 105, 1)',
    width: '200px',
    padding: '16px',
    height: '100%',
    zIndex: 4,
    '& h6': {
      fontWeight: 'bold',
      color: '#ffffff',
      alignSelf: 'center',
      marginBottom: '0px',
    },
    '& button': {
      fontSize: '.95rem',
    },
    '& a': {
      marginBottom: '16px',
    },
    '& hr': {
      borderBottom: '1px solid white',
      width: '100%',
      marginTop: '13px',
    },
  }
);

export const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined
  );
  const location = useLocation();

  const sidebarRef = useRef<HTMLDivElement>(null);
  const favoriteProjects = useSelector(
    (s: ApplicationState) => s.global.favoriteProjects
  );
  const recentProjects = useSelector(
    (s: ApplicationState) => s.global.recentProjects
  );
  const isSidebarPinned =
    useSelector((s: ApplicationState) => s.user.user?.isSidebarPinned) ?? false;

  const isMountingRef = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      isMountingRef.current = false;
    }, 150);
  }, []);

  const handleSetPinned = async (newPinnedState: boolean) => {
    // Call the new API endpoint to save the user's preference
    try {
      dispatch(actionCreators.toggleSidebarPinned());
      await fetch(`account/update`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fieldName: 'IsSidebarPinned',
          value: newPinnedState,
        }),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {          
          if (resOk) dispatch(actionCreators.getUser(true));
          else alert(data.message);
        });
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  useEffect(() => {
    if (isSidebarPinned && selectedItem === undefined)
      setSelectedItem('projects');
    //else if (!isSidebarPinned) setSelectedItem(undefined);
  }, [isSidebarPinned, selectedItem]);

  const NoVendorComp = NoAuthElement([KnownRoles.Vendor, KnownRoles.OwnerLtd]);
  const AdminComp = MyAuthElement([KnownRoles.Admin]);
  const ConfigComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);
  const InternalUsersComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
    KnownRoles.SiteSuper,
  ]);

  const isDashboardActive = useMemo(() => {
    return (
      location.pathname.includes('my-') ||
      location.pathname.includes('activity') ||
      location.pathname == '/'
    );
  }, [location]);

  const isProjectsActive = useMemo(() => {
    return location.pathname.includes('projectlist');
  }, [location]);

  const isSubsActive = useMemo(() => {
    return location.pathname.includes('subs');
  }, [location]);

  const isExplorerActive = useMemo(() => {
    return location.pathname.includes('explorer');
  }, [location]);

  const isFileShareActive = useMemo(() => {
    return location.pathname.includes('fileshare');
  }, [location]);

  const isReportingActive = useMemo(() => {
    return location.pathname.includes('reporting');
  }, [location]);

  const isAdminActive = useMemo(() => {
    return location.pathname.includes('admin');
  }, [location]);

  const isConfigActive = useMemo(() => {
    return location.pathname.includes('config-');
  }, [location]);

  useOutsideClickHandler(sidebarRef, () => {
    if (!isSidebarPinned) {
      setSelectedItem(undefined);
    }
  });

  useEffect(() => {
    if (!isSidebarPinned) {
      setSelectedItem(undefined);
    }
  }, [location.pathname, isSidebarPinned]);

  return (
    <SidebarContainer ref={sidebarRef} className="nav-sidebar">
      <NavbarContainer>
        <IconDiv
          id={'dashboard'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isDashboardActive ? 'active' : ''}
        >
          <i className="fa-solid fa-chart-pie fa-xl"></i>
        </IconDiv>
        <IconDiv
          id={'projects'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isProjectsActive ? 'active' : ''}
        >
          <i className="fa-solid fa-trowel-bricks fa-xl fa-flip-horizontal"></i>
        </IconDiv>
        <IconDiv
          id={'subcontractors'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isSubsActive ? 'active' : ''}
        >
          <InternalUsersComp>
            <i className="fa-solid fa-address-book fa-xl"></i>
          </InternalUsersComp>
        </IconDiv>
        <IconDiv
          id={'reporting'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isReportingActive ? 'active' : ''}
        >
          <InternalUsersComp>
            <i className="fa-solid fa-chart-line fa-xl"></i>
          </InternalUsersComp>
        </IconDiv>
        <IconDiv
          id={'config'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isConfigActive ? 'active' : ''}
        >
          <ConfigComp>
            <i className="fa-solid fa-cog fa-xl"></i>
          </ConfigComp>
        </IconDiv>

        <IconDiv
          id={'explorer'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isExplorerActive ? 'active' : ''}
        >
          <NoVendorComp>
            <i className="fa-solid fa-folder-open fa-xl"></i>
          </NoVendorComp>
        </IconDiv>

        <IconDiv
          id={'fileshare'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isFileShareActive ? 'active' : ''}
        >
          <ConfigComp>
            <i className="fa-brands fa-slideshare fa-xl"></i>
          </ConfigComp>
        </IconDiv>

        <IconDiv
          id={'admin'}
          onMouseEnter={(e) => {
            setSelectedItem(e.currentTarget.id);
          }}
          className={isAdminActive ? 'active' : ''}
        >
          <AdminComp>
            <i className="fa-solid fa-user-shield fa-xl"></i>
          </AdminComp>
        </IconDiv>
        <div className="sidebar-bottom">
          <hr style={{ borderColor: '#ffffff' }} />
          <IconDiv onClick={() => handleSetPinned(!isSidebarPinned)}>
            <i
              className={cx(
                'fa-solid fa-lg',
                isSidebarPinned ? 'fa-thumbtack-slash' : 'fa-thumbtack'
              )}
            ></i>
          </IconDiv>
        </div>
      </NavbarContainer>

      <SlideOutContainer>
        <SlideOutContent
          isSelected={selectedItem != undefined || isSidebarPinned}
          pinned={isSidebarPinned}
          mounting={isMountingRef.current}
        >
          <SidebarMenuButtons
            item={selectedItem}
            recentProjects={recentProjects}
            favoriteProjects={favoriteProjects}
          />
        </SlideOutContent>
      </SlideOutContainer>
    </SidebarContainer>
  );
};
