import React, { useEffect, useState, useRef, useCallback } from "react";
import { setupSignalRConnection } from "src/helpers/signalR";
import { ProcessingNotification } from "src/interfaces/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
	connectionSelector,
	isUploadNotificationSelector,
	notificationsSortedSelector,
	ProcessingNotificationActions,
	showNewNotificationSelector,
} from "src/store/processingNotifications";
import { HubConnectionState } from "@microsoft/signalr";
import { NotificationStream } from "./NotificationStream";
import { useOutsideClickHandler } from "src/helpers/customhooks";
import { NotificationAlert } from "./NotificationAlert";

export const ProcessingNotificationTray = () => {
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const connection = useSelector(connectionSelector);
	const notifications = useSelector(notificationsSortedSelector);
	const showNewNotification = useSelector(showNewNotificationSelector);
	const isUploadNotification = useSelector(isUploadNotificationSelector);

	const actions = [
		{
			action: "NewDownload",
			event: (item: ProcessingNotification) =>
				dispatch(ProcessingNotificationActions.addNotification(item)),
		},
		{
			action: "UpdateDownload",
			event: (item: ProcessingNotification) =>
				dispatch(ProcessingNotificationActions.updateNotification(item)),
		},
		{
			action: "NewUpload",
			event: (item: ProcessingNotification) =>
				dispatch(ProcessingNotificationActions.addUploadNotification(item)),
		},
		{
			action: "UpdateDownloadedStatus",
			event: (item: string) =>
				dispatch(ProcessingNotificationActions.setDownloadedNotification(item)),
		},
	];

	useEffect(() => {
		dispatch(ProcessingNotificationActions.getNotifications());
	}, []);

	useEffect(() => {
		if (!connection || connection.state == HubConnectionState.Disconnected) {
			const con = setupSignalRConnection("/hubs/documents", actions);
			dispatch(ProcessingNotificationActions.setConnection(con));
		}
	}, [connection]);

	const handleOnClose = useCallback(() => {
		dispatch(
			ProcessingNotificationActions.setShowNewNotification({
				show: false,
				isUpload: false,
			})
		);
	}, [dispatch]);

	const wrapperRef = useRef(null);
	useOutsideClickHandler(wrapperRef, () => setIsOpen(false));

	const unreadNotifications = notifications.filter((x) => x.isRead == false);

	return (
		<div className="notification-tray" ref={wrapperRef}>
			<div
				className="tray-button"
				style={{ margin: "auto", textAlign: "center" }}
				title={""}
				onClick={() => setIsOpen(!isOpen)}
			>
				<span
					style={{
						// @ts-ignore
						"--fa-animation-duration": "2s",
						"--fa-beat-fade-opacity": "0.3",
						color: "white",
					}}
					className={`fas fa-file ${showNewNotification && "fa-beat-fade"}`}
				></span>
				{!isOpen && unreadNotifications.length > 0 && (
					<div className="notification-counter">
						{unreadNotifications.length}
					</div>
				)}
				{(isOpen || showNewNotification) && (
					<div className="stream-pointer"></div>
				)}
			</div>
			<div>
				{isOpen && <NotificationStream notifications={notifications} />}
			</div>
			{showNewNotification && (
				<NotificationAlert
					onClose={handleOnClose}
					isUpload={isUploadNotification}
				/>
			)}
		</div>
	);
};
