import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "..";
import { ProcessingNotificationsState } from "./processingNotifications.reducer";
import moment from "moment";

const processingNotificationState = (
  state: ApplicationState
): ProcessingNotificationsState => state.processingNotifications;

export const notificationsSelector = createSelector(
  processingNotificationState,
  (state) => state.notifications
);

export const notificationsSortedSelector = createSelector(
  notificationsSelector,
  (notifications) =>
    notifications.slice().sort((a, b) => moment(b.timestamp).diff(a.timestamp))
);

export const connectionSelector = createSelector(
  processingNotificationState,
  (state) => state.connection
);

export const showNewNotificationSelector = createSelector(
  processingNotificationState,
  (state) => state.showNewNotification
);

export const isUploadNotificationSelector = createSelector(
  processingNotificationState,
  (state) => state.isUploadNotification
);
