//import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { ListItem, NotificationPreference } from '../interfaces/interfaces';

export interface UserNotification {
    id: number,
    userId: string,
    timestamp: string,
    type: UserNotificationType,
    message: string,
    linkUri: string,
    linkText: string,
    status: UserNotificationStatus
}

export enum UserNotificationType {

}

export enum UserNotificationStatus {
    Unread,
    Read,
    Dismissed
}

export interface Store {
    notifications: UserNotification[],    
    message?: string,
    isLoading: boolean
}

interface GetNotificationsAction {
    type: 'REQUEST_NOTIFICATIONS'
}

interface ReceiveNotificationsAction {
    type: 'RECEIVE_NOTIFICATIONS',
    notifications: UserNotification[]
}

interface ClearMessageAction {
    type: "CLEAR_NOTIFICATION_MESSAGE"
}

type KnownAction = GetNotificationsAction |  ReceiveNotificationsAction | ClearMessageAction

export const actionCreators = {
    getNotifications: (all: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/notification/get?showAll=${all}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                dispatch({ type: 'RECEIVE_NOTIFICATIONS', notifications: data })
            })
        dispatch({ type: 'REQUEST_NOTIFICATIONS' });
    },
    dismiss: (ids: number[]): AppThunkAction<KnownAction> => (dispatch) => {
        const query = ids.map(x => `ids=${x}`).join('&');
        fetch(`api/notification/dismiss?${query}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                dispatch({ type: 'RECEIVE_NOTIFICATIONS', notifications: data })
            })
    },
    markAsRead: (ids: number[], showAll: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        const query = ids.map(x => `ids=${x}`).join('&') + '&showAll=' + showAll;
        fetch(`api/notification/markasread?${query}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                dispatch({ type: 'RECEIVE_NOTIFICATIONS', notifications: data })
            })
    },
    clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_NOTIFICATION_MESSAGE' })
    }
}

const unloadedState: Store = {
    notifications: [],    
    isLoading: true
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'REQUEST_NOTIFICATIONS':
            return { ...state }        
        case 'RECEIVE_NOTIFICATIONS':
            return { ...state, notifications: action.notifications, isLoading: false }
        case 'CLEAR_NOTIFICATION_MESSAGE':
            return { ...state, message: undefined }
        default:{
            const exhaustiveCheck: never = action
        }
    }

    return state || unloadedState
}