import * as React from 'react';
import { LinkToken } from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as globalActions } from '../store/global';
import { partnerRegKeySelector } from '../store/keyvault/keyvault.selectors';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';
import { Modal2 } from '../components/Modal';
import { F40FormGroup } from './F40Detail';
import { SliderCheckbox } from '../components/SliderCheckbox';

interface TokenPostData {
  vendorId: number,
  f40Scope: boolean,
  financialsScope: boolean,
  insuranceScopes: string[],
}

export const UnvettedPartnerLinks = (props: { partnerId: number, links: LinkToken[] }) => {

  const { partnerId, links } = props;
  const [latestLink, setLatestLink] = React.useState(null as LinkToken | null);
  const [showSendModal, setShowSendModal] = React.useState(false);
  const [tokenPostData, setTokenPostData] = React.useState({ vendorId: partnerId, f40Scope: true, financialsScope: true, insuranceScopes: [] as string[], } as TokenPostData);
  const apiKey = useSelector(partnerRegKeySelector);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (links && links.length) {
      setLatestLink(links[links.length - 1]);
    }
  }, [links]);

  const _toggleForm = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This approach causes the slider checkbox to snap instead of slide
    // Not a big deal right now just leaving this comment here as a reminder
    const prop = e.currentTarget.name;
    const newData = JSON.parse(JSON.stringify(tokenPostData));
    newData[prop] = e.currentTarget.checked;
    setTokenPostData(newData);
  }

  const _toggleScope = (e: React.ChangeEvent<HTMLInputElement>) => {
    // This approach causes the slider checkbox to snap instead of slide
    // Not a big deal right now just leaving this comment here as a reminder
    const val = e.currentTarget.name;
    const newData = JSON.parse(JSON.stringify(tokenPostData.insuranceScopes)) as string[];
    if (e.currentTarget.checked) {
      if (newData.indexOf(val) === -1) newData.push(val);
    } else {
      if (newData.indexOf(val) !== -1) newData.remove(val);
    }
    setTokenPostData({ ...tokenPostData, insuranceScopes: newData });
  }

  const _resendLink = () => {
    const url = `${PartnerRegistrationUrl}/api/phasetwo/ResendLink?vendorId=${partnerId}`;

    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(globalActions.updateMessage('Link resent successfully'))
          setLatestLink(data);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  const _sendLink = () => {
    const url = `${PartnerRegistrationUrl}/api/phasetwo/SendLink`

    if (tokenPostData.insuranceScopes.length === 0) {
      alert('Please make sure to select at least one entity for insurance coverage.')
      return;
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      },
      //body: JSON.stringify({ vendorId: tokenPostData.vendorId, f40Scope: tokenPostData.f40Scope, financialsScope: tokenPostData.financialsScope })
      body: JSON.stringify(tokenPostData)
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(globalActions.updateMessage('Link sent successfully'))
          setLatestLink(data);
          setShowSendModal(false);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  const sendModal = showSendModal ? <Modal2 dialogStyle={{ marginTop: '40px', width: '33%' }} >
    <div className='modal-header'><h5>Send New Phase Two Form Token</h5></div>
    <div className='modal-body'>      
      <h6>Select the forms you want filled out</h6>
      <div>
        <F40FormGroup label='Financials' width='100%' labelWidth='100px'>
          <SliderCheckbox selected={tokenPostData.financialsScope} fieldName='financialsScope' onChange={_toggleForm} disabled={true} />
        </F40FormGroup>
        <F40FormGroup label='CSQF / F40' width='100%' labelWidth='100px'>
          <SliderCheckbox selected={tokenPostData.f40Scope} fieldName='f40Scope' onChange={_toggleForm} />
        </F40FormGroup>
      </div>
      <h6>Select the entities needed by insurance coverage</h6>
      <F40FormGroup label='Construction' width='100%' labelWidth='200px'>
        <SliderCheckbox selected={tokenPostData.insuranceScopes.indexOf('construction') !== -1} fieldName='construction' onChange={_toggleScope} />
      </F40FormGroup>
      <F40FormGroup label='Architecture & Engineering' width='100%' labelWidth='200px'>
        <SliderCheckbox selected={tokenPostData.insuranceScopes.indexOf('archeng') !== -1} fieldName='archeng' onChange={_toggleScope} />
      </F40FormGroup>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-x-sm btn-blue' onClick={_sendLink}>Send Link</button>
      <button className='btn btn-x-sm btn-outline-secondary' onClick={() => setShowSendModal(false)}>Cancel</button>
    </div>
  </Modal2> : []

  const resendBtn = links && links.length ?
    <button className='btn btn-x-sm btn-outline-secondary' onClick={_resendLink}>Resend Link</button> :
    [];

  const accessText = latestLink === null ? "" : latestLink.lastAccessed ? `Link last accessed on ${formatDate(latestLink.lastAccessed)}` : "Link never accessed by partner.";
  const linkText = latestLink === null ?
    <p style={{ margin: '5px 0px 5px 0px' }}>No links sent to this partner. Use the Send Link button above</p> :
    latestLink.isExpired ?
      <p style={{ margin: '5px 0px 5px 0px' }}>Link expired on <b>{`${formatDate(latestLink.expDate)}`}</b>. {accessText}</p> :
      <p style={{ margin: '5px 0px 5px 0px' }}>Link expires on <b>{`${formatDate(latestLink.expDate)}`}</b>. {accessText}</p>;
  const tokenLink = latestLink === null ? [] : <a href={`https://partner-registration.core-states.com/p2/${latestLink.token}`} target='_blank'>Access Link</a>;

  return <div style={{ display: 'flex', marginLeft: '15px' }}>
    {sendModal}
    <div style={{ width: '380 px' }}>
      <button className='btn btn-x-sm btn-blue' onClick={() => setShowSendModal(true)}>Send Link</button> &nbsp;
      {resendBtn}
      {linkText}
      {tokenLink}
    </div>
  </div>
}