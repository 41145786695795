import * as React from 'react';
import { Loader } from '../Loader';

interface State {
    email: string;
    pass: string;
    pass2: string;
    code: string;
    loading: boolean;
    errMessage?: string;
}

export default class ResetPassword extends React.Component<any, State> {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            pass: '',
            pass2: '',
            code: '',
            loading: false
        }
    }

    componentDidMount() {
        this._getValuesFromUrl();
    }

    componentWillUnmount() { this.setState({ loading: false }) }

    _getValuesFromUrl = () => {
        const qString = window.location.search;
        const params = new URLSearchParams(qString);

        const code = params.get('code') || '';
        const email = params.get('userId') || '';

        this.setState({ email: email, code: code })
    }

    _submit = () => {
        const { email, pass, pass2, code } = this.state;
        this.setState({ loading: false })
        fetch(`account/resetpassword`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email, password: pass, confirmPassword: pass2, code: code })
            }
        )
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (!resOk) this.setState({ errMessage: data.message, loading: false })
                else window.location.href = "/";
            })
    }

    _updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.currentTarget.value })

    _updatePass = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ pass: e.currentTarget.value })

    _updatePass2 = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ pass2: e.currentTarget.value })

    render() {
        const { email, pass, pass2, errMessage, loading } = this.state;

        return (
            <div id="loginForm">
                <Loader loading={loading} />
                <h3>Reset your password.</h3>
                {errMessage &&
                    <div className='error-message'>
                        <p className='danger text-danger'>{errMessage}</p>
                    </div>
                }
                <hr />
                <div>
                    <div className="col-md-8">
                        <div className='form-group'>
                            <label><b>Email</b></label>
                            <input className='form-control' type='email'
                                onChange={this._updateEmail} value={email} />
                        </div>
                        <div className='form-group'>
                            <label><b>New Password</b></label>
                            <p style={{ lineHeight: '1', width: '200%', marginBottom: '0.5em'}}><small>Password must be at least 9 characters and contain at least one capital letter, one lowercase letter, one number and one non-alphanumeric character (! @ # $ % ^ & *)</small></p>
                            <input className='form-control' type='password'
                                onChange={this._updatePass} value={pass} />
                        </div>

                        <div className='form-group'>
                            <label><b>Confirm Password</b></label>
                            <input className='form-control' type='password'
                                onChange={this._updatePass2} value={pass2} />
                        </div>
                        <div className='form-group'>
                            <button className='btn btn-outline-secondary' onClick={this._submit}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}