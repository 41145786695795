import React from 'react';
import Chart from 'react-google-charts';
import { Loader } from 'src/components/Loader';
import { IssueVm } from 'src/interfaces/interfaces';

interface IssueBarChartProps {
  issues: IssueVm[];
}

export const IssueBarChart = ({ issues }: IssueBarChartProps) => {
  const userStatusCounts = issues.reduce((prev, curr) => {
    const assigneeKey = curr['assigneeName'];
    const statusKey = curr['status'];

    prev[assigneeKey] = prev[assigneeKey] || [0, 0, 0, 0, 0];

    prev[assigneeKey][statusKey]++;

    return prev;
  }, {});

  const options = {
    title: 'Items by Assignee',
    chartArea: { width: '65%' },
    isStacked: true,
    colors: ['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030'],
    hAxis: {
      title: 'Item Count',
      minValue: 0,
      minorGridlines: {
        count: 0,
      },
    },
    bar: {
      groupWidth: '40%',
    },
    fontName: 'Montserrat',
  };

  const data = [
    [
      'Assignee',
      'Draft',
      'Sent',
      'In Progress',
      'In Review',
      'Complete',
      {
        sourceColumn: 0,
        role: 'annotation',
        type: 'string',
        calc: 'stringify',
      },
    ],
    ...Object.keys(userStatusCounts).map((x) => {
      return [x, ...userStatusCounts[x], null];
    }),
  ];

  return (
    <Chart
      key={`bar-chart-issues`}
      graphID={`assignee-bar-chart`}
      className=""
      chartType="BarChart"
      width={'100%'}
      height={'250px'}
      loader={<Loader loading={true} />}
      data={data}
      options={options}
    />
  );
};
