import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BackLink } from 'src/components/BackLink';
import { ApplicationState } from 'src/store';
import {
  IssuesActions,
  issuesStatusSelector,
  selectedIssueSelector,
} from 'src/store/issues';
import styled from 'styled-components';
import { ConnectedIssueDetail } from './ConnectedIssueDetail';
import { actionCreators as ProjectActions } from '../store/projectDetail';
import { RectangleLoader } from 'src/loaders/RectangleLoader';
import { GlobalMessageBox } from 'src/components/MessageBox';

const BodyDiv = styled.div({
  maxHeight: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '& .form-group:not(.joined-list)': {
    display: 'flex',
    width: '100%',
    lineHeight: 1,
  },
  '& .inline-label': { width: '150px', lineHeight: 2 },
  '& input, & select, & textarea': {
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select': {
    width: '100%',
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select input': {
    maxWidth: '100%',
  },
  '& .joined-list': {
    height: '100%',
    margin: 0,
  },
});

export const IssueDetailPage = ({
  pId,
  issueId,
}: {
  pId?: number;
  issueId?: number;
}) => {
  const { id, projectId } = useParams<{ id: string; projectId: string }>();
  const issuesStatus = useSelector(issuesStatusSelector);
  const selectedIssue = useSelector(selectedIssueSelector);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const dispatch = useDispatch();

  const projId = pId ?? +projectId;

  useEffect(() => {
    if (!projectStore.detail.id || projectStore.detail.id != projId) {
      dispatch(ProjectActions.getDetail(projId));
    }
  }, [projectStore]);

  useEffect(() => {
    if (projectStore.detail.id) {
      if (issuesStatus == 'idle' || projectStore.detail.id != projId) {
        dispatch(IssuesActions.getIssues(projId));
      }
    }
  }, [projectStore]);

  useEffect(() => {
    const issueItemId = issueId ?? +id;
    dispatch(IssuesActions.setSelectedIssueId(issueItemId));
    dispatch(IssuesActions.getConfig());
  }, [issueId, id]);

  return (
    <>
      <GlobalMessageBox />
      <div className="col-12">
        {projectId && <BackLink link={`/project/${projectId}#issues`} />}
        <div className="pageHeader">
          <h3>{selectedIssue?.title} Issue Details</h3>
        </div>
        <hr />
        {issueId ? (
          <BodyDiv className="custom-scrollbar">
            <ConnectedIssueDetail />
          </BodyDiv>
        ) : (
          <>
            <RectangleLoader style={{ marginBottom: '8px' }} />
            <RectangleLoader style={{ marginBottom: '8px' }} />
            <RectangleLoader style={{ marginBottom: '8px' }} />
            <RectangleLoader style={{ marginBottom: '8px' }} />
          </>
        )}
      </div>
    </>
  );
};
