import { AppStartListening } from '../listenerMiddleware';
import {
  addTemplate,
  copyTemplate,
  editTemplate,
  getTemplates,
} from 'src/services/dfrTemplates';

export const addDFRListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      type: 'REQUEST_TEMPLATES',
      effect: async (action, listenApi) => {
        const response = await getTemplates();
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            listenApi.dispatch({
              type: 'SET_TEMPLATES',
              templates: response,
            });
          }
        }
      },
    }),
    startListening({
      type: 'ADD_TEMPLATE',
      effect: async (action, listenApi) => {
        const response = await addTemplate();
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            listenApi.dispatch({
              type: 'SET_TEMPLATES',
              templates: response,
            });
          }
        }
      },
    }),
    startListening({
      type: 'EDIT_TEMPLATE',
      effect: async (action, listenApi) => {
        listenApi.dispatch({
          type: 'TOGGLE_LOADING',
        });
        const id = listenApi.getState().dfr.currentTemplateId;
        const update = listenApi.getState().dfr.currentTemplateUpdate;
        if (id && update) {
          const response = await editTemplate(id, update);
          if (response) {
            if (typeof response == 'string') {
              listenApi.dispatch({
                type: 'UPDATE_MESSAGE_ACTION',
                message: response,
              });
            } else {
              const updatedTemplates = listenApi
                .getState()
                .dfr.templates.slice()
                .map((x) => (x.id === response.id ? response : x));

              listenApi.dispatch({
                type: 'SET_TEMPLATES',
                templates: updatedTemplates,
              });
            }
          }
        }
        listenApi.dispatch({
          type: 'TOGGLE_LOADING',
        });
      },
    }),
    startListening({
      type: 'COPY_TEMPLATE',
      effect: async (action, listenApi) => {
        const id = listenApi.getState().dfr.currentTemplateId;

        if (id) {
          const response = await copyTemplate(id);
          if (response) {
            if (typeof response == 'string') {
              listenApi.dispatch({
                type: 'UPDATE_MESSAGE_ACTION',
                message: response,
              });
            } else {
              listenApi.dispatch({
                type: 'SET_TEMPLATES',
                templates: response,
              });
            }
          }
        }
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
