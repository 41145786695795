import { AxiosResponse } from "axios";
import { RFIDetailVm } from "../../interfaces/interfaces";
import { api } from "../api";


export const getRfi = async (id: number) => {
    try {
        const response: AxiosResponse = await api.get(`api/RFI/detail?id=${id}`);

        return response;
    } catch {
        console.warn("error");
    }
    return false;
};