import {
  FieldReportSafetyKpiEntry,
  SafetyKpiTemplate,
  SafetyKpiTemplateEntry,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { api } from '../api';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const getTemplates = async () => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate[]> = await api.get(
      `api/SafetyKPI/GetTemplates`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addTemplate = async () => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate[]> = await api.post(
      `api/SafetyKPI/AddTemplate`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const updateTemplate = async (id: number, info: UpdateObject) => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate> = await api.put(
      `api/SafetyKPI/UpdateTemplate?id=${id}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    if (axios.isAxiosError(err)) {
      if (err.response) {
        return err.response.data as string;
      }
    }

    return undefined;
  }

  return undefined;
};

export const copyTemplate = async (id: number) => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate[]> = await api.post(
      `api/SafetyKPI/CopyTemplate?templateId=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addTemplateEntry = async (templateId: number) => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate> = await api.post(
      `api/SafetyKPI/AddTemplateEntry?templateId=${templateId}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const updateTemplateEntry = async (id: number, info: UpdateObject) => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate> = await api.put(
      `api/SafetyKPI/UpdateTemplateEntry?id=${id}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const updateFieldReportSafetyKPIEntry = async (
  id: number,
  info: UpdateObject
) => {
  try {
    const response: AxiosResponse<FieldReportSafetyKpiEntry> = await api.put(
      `api/SafetyKPI/UpdateFieldReportKPIEntry?id=${id}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const removeTemplateEntry = async (id: number) => {
  try {
    const response: AxiosResponse<SafetyKpiTemplate> = await api.delete(
      `api/SafetyKPI/RemoveTemplateEntry?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};
