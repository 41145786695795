import React, { useRef, useState } from "react";
import {
	ChecklistDetailVm,
	ChecklistTemplateSection,
	UpdateObject,
} from "src/interfaces/interfaces";
import { editSection, removeSection } from "src/services/checklists";
import { ChecklistFieldInfo } from "./ChecklistFieldInfo";
import { NewFieldComponent } from "./NewChecklistFieldComponent";
import styled from "styled-components";

interface ChecklistSectionProps {
	section: ChecklistTemplateSection;
	onUpdate: (item: ChecklistDetailVm) => void;
	sectionCount: number;
}

const StyledInput = styled.input({
	height: "30px",
	width: "350px",
});

const StyledDiv = styled.div({
	display: "flex",
});

const SectionHeaderDiv = styled.div({
	display: "flex",
	width: "100%",
	justifyContent: "space-between",
});

const SectionContentDiv = styled.div({
	border: "1px solid lightgray",
	borderRadius: "2px",
	padding: "10px",
	marginBottom: "15px",
});

export const ChecklistSection: React.FC<ChecklistSectionProps> = ({
	section,
	onUpdate,
	sectionCount,
}) => {
	const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
	const titleRef = useRef<HTMLInputElement>(null);

	const handleOrderUpdate = async (moveUp: boolean) => {
		if (section.id) {
			const orderValue = moveUp ? section.order - 1 : section.order + 1;
			const updateObject: UpdateObject = {
				fieldName: "order",
				value: orderValue.toString(),
			};
			const newChecklist = await editSection(
				section.checklistTemplateId,
				section.id,
				updateObject
			);
			if (newChecklist) {
				onUpdate(newChecklist);
			}
		}
	};

	return (
		<div>
			<SectionHeaderDiv>
				{!isEditingTitle ? (
					<>
						<StyledDiv>
							<h5>Section -</h5>&nbsp;&nbsp;<h5>{section.title}</h5>
							{section.isOriginal && (
								<span
									style={{
										fontSize: "unset",
										position: "unset",
										marginLeft: "10px",
									}}
									className="fas fa-edit"
									onClick={() => setIsEditingTitle(true)}
								></span>
							)}
							{section.title == "" && (
								<span
									style={{
										marginLeft: "5px",
										color: "#dc3545",
										borderRadius: "50%",
									}}
									title="This section currently unnamed."
									className="fas fa-triangle-exclamation"
								></span>
							)}
						</StyledDiv>
						<StyledDiv>
							{sectionCount > 1 && (
								<div>
									{section.order > 1 && (
										<span
											style={{ borderRadius: "50%" }}
											onClick={() => handleOrderUpdate(true)}
											className="btn btn-sm fas fa-arrow-up btn-background-hover"
										></span>
									)}
									{section.order < sectionCount && (
										<span
											style={{ borderRadius: "50%" }}
											onClick={() => handleOrderUpdate(false)}
											className="btn btn-sm fas fa-arrow-down btn-background-hover"
										></span>
									)}
								</div>
							)}
							{section.isOriginal && (
								<span
									style={{ borderRadius: "50%" }}
									className="btn btn-sm fas fa-trash-alt btn-background-hover"
									title="Delete this section"
									onClick={async () => {
										if (section.id) {
											if (section.fields.length > 0) {
												if (
													window.confirm(
														"This section has existing fields associated that will also be deleted. Are you sure you want to continue?"
													)
												) {
													const newChecklist = await removeSection(
														section.checklistTemplateId,
														section.id
													);
													if (newChecklist) {
														onUpdate(newChecklist);
													}
												}
											} else {
												const newChecklist = await removeSection(
													section.checklistTemplateId,
													section.id
												);
												if (newChecklist) {
													onUpdate(newChecklist);
												}
											}
										}
									}}
								></span>
							)}
							<span
								data-toggle="collapse"
								data-target={`#section-content-${section.id}`}
								style={{ borderRadius: "50%" }}
								className={`btn btn-sm fas ${
									isCollapsed ? "fa-chevron-up" : "fa-chevron-down"
								} btn-background-hover`}
								onClick={() => {
									setIsCollapsed(!isCollapsed);
								}}
							></span>
						</StyledDiv>
					</>
				) : (
					<StyledDiv id={"editing-checklist-section"}>
						<>
							<h5 style={{ lineHeight: 1.5 }}>Section - </h5>
							<div
								style={{ width: "auto", marginLeft: "10px" }}
								className="form-group"
							>
								<StyledInput
									ref={titleRef}
									defaultValue={section.title}
									className="form-control"
								/>
								<button
									className="btn btn-sm btn-blue"
									onClick={async () => {
										if (section.id) {
											const updateObject: UpdateObject = {
												fieldName: "title",
												value: titleRef.current?.value ?? "",
											};
											const newChecklist = await editSection(
												section.checklistTemplateId,
												section.id,
												updateObject
											);
											if (newChecklist) {
												onUpdate(newChecklist);
											}
											setIsEditingTitle(false);
										}
									}}
									style={{
										marginRight: "8px",
										marginLeft: "8px",
									}}
								>
									Save
								</button>
								<button
									className="btn btn-sm btn-outline-secondary"
									onClick={() => setIsEditingTitle(false)}
								>
									Cancel
								</button>
							</div>
						</>
					</StyledDiv>
				)}
			</SectionHeaderDiv>
			<SectionContentDiv
				id={`section-content-${section.id}`}
				className="collapse show"
			>
				<div
					style={{ paddingLeft: "10px", paddingRight: "10px" }}
					className="form-group"
				>
					<div className="inline-label">Subtitle</div>
					<input
						disabled={!section.isOriginal}
						defaultValue={section.subtitle}
						className="form-control"
						onBlur={async (e) => {
							if (section.id) {
								const updateObject: UpdateObject = {
									fieldName: "subtitle",
									value: e.target.value ?? "",
								};
								const newChecklist = await editSection(
									section.checklistTemplateId,
									section.id,
									updateObject
								);
								if (newChecklist) {
									onUpdate(newChecklist);
								}
							}
						}}
					/>
				</div>
				{section.fields.map((x) => (
					<ChecklistFieldInfo
						field={x}
						fieldCount={section.fields.length}
						key={x.id}
					/>
				))}
				<NewFieldComponent
					sectionId={section.id ?? 1}
					templateId={section.checklistTemplateId}
				/>
			</SectionContentDiv>
		</div>
	);
};
