import React, { useState, useRef, useCallback } from 'react';
import Modal from '../components/Modal';
import { PunchItemStatus, PunchItemVm } from 'src/interfaces/interfaces';
import { formatDateTime } from 'src/helpers/formatters';
import styled from 'styled-components';
interface PunchItemCommentModalProps {
  item: PunchItemVm;
  onClose: () => void;
  onWorkRequiredUpdate: (id: number, value: string) => void;
}
const NewCommentDiv = styled.div({
  display: 'flex',
  borderRadius: '2px',
});
const ModalFooterDiv = styled.div({
  justifyContent: 'flex-end',
});
const ModalBodyDiv = styled.div({
  maxHeight: '400px',
  overflowY: 'auto',
});
export const WorkRequiredModal: React.FC<PunchItemCommentModalProps> = ({
  item,
  onWorkRequiredUpdate,
  onClose,
}) => {
  const [comment, setComment] = useState('');

  return (
    <Modal dialogStyle={{ width: '500px' }} style={{ display: 'flex' }}>
      <div className="modal-header">
        <h4>Work Required Comment</h4>
      </div>
      <ModalBodyDiv className="modal-body custom-scrollbar">
        <p>
          You have indicated that work is required for this item. Please provide
          an explanation below of the required work. Your comment will appear in
          the comment stream under your name.
        </p>

        <NewCommentDiv className="new-comment">
          <textarea
            onChange={(e) => setComment(e.currentTarget.value)}
            className="form-control"
          ></textarea>
        </NewCommentDiv>
      </ModalBodyDiv>
      <ModalFooterDiv className="modal-footer">
        <span
          className="btn btn-sm btn-blue"
          onClick={() => {
            if (comment !== '') {
              onWorkRequiredUpdate(item.id, 'Work Required: ' + comment);
            } else {
              alert('Please enter a comment.');
            }
          }}
        >
          Save
        </span>
        <span className="btn btn-sm btn-outline-secondary" onClick={onClose}>
          Close
        </span>
      </ModalFooterDiv>
    </Modal>
  );
};
