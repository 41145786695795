import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { EventHistoryLog } from '../components/EventHistoryLog';

import { TransmittalDetail } from './TransmittalDetail';

export const TransmittalPage = (props: RouteComponentProps) => {
    // @ts-ignore
    const projectId = props.match.params.projectId, transmittalId = props.match.params.transmittalId;

    return (
        <div style={{ display: 'flex' }}>
            <div className='transmittal-page-detail'>
                <TransmittalDetail
                    projectId={projectId}
                    transmittalId={transmittalId}
                    inModal={false}
                />
            </div>
            <div className='transmittal-log mobile-hide' style={{ position: 'relative' }}>
                <div className='log-header'>History</div>
                {(transmittalId !== undefined && transmittalId !== 0) ? <EventHistoryLog objectName='Transmittal' objectId={transmittalId} /> : []}
            </div>
        </div>
    )
}