import * as React from 'react';
import { connect } from 'react-redux';

import { Tabs, TabLink, TabContent } from '../tabs';
import DashboardComponent from './DashboardComponent';
import { WorkItemStream } from './WorkItemStreamComponent';
import { DashboardGraphs, DashboardScope } from './DashboardGraphs';
import ProjectTaskGrid from '../projects/ProjectTaskGrid';
import { ApplicationState } from '../store';
import * as UserStore from '../store/user';
import { NoAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { MilestoneList } from '../components/MilestoneList';
import { ProjectChecklistGrid } from '../projects/ProjectChecklistGrid';
import { ChecklistGrid } from '../checklists/ChecklistGrid';

type DashboardHomeProps = {
  selectedTab?: string;
  hideTabs?: boolean;
};

type Props = UserStore.Store & DashboardHomeProps;

class DashboardHome extends React.Component<Props, any> {
  noOwnertLtdComponent = NoAuthElement([KnownRoles.OwnerLtd]);

  render() {
    return (
      <div>
        <div className="pageHeader">Activity Vue</div>
        <Tabs
          name="project-tabs"
          onChange={() => {}}
          renderActiveTabContentOnly={true}
          defaultTab={
            this.props.selectedTab
              ? this.props.selectedTab
              : this.props.user
              ? this.props.user.defaultDashboardTab
              : ''
          }
        >
          <div
            style={{ display: this.props.hideTabs ? 'none' : undefined }}
            className="tab-links"
          >
            <TabLink to="mywork" component="span">
              <span>My Work</span>
            </TabLink>
            <TabLink to="milestones" component="span">
              <span>My Milestones</span>
            </TabLink>
            <TabLink to="checklists" component="span">
              <span>My Checklists</span>
            </TabLink>
            <TabLink to="tasks" component="span">
              <span>My Tasks</span>
            </TabLink>
            {/*
                        @ts-ignore */}
            <this.noOwnertLtdComponent componentType="span">
              <TabLink to="activity" component="span">
                <span>Activity</span>
              </TabLink>
            </this.noOwnertLtdComponent>
            <TabLink to="graphs" component="span">
              <span>My Graphs</span>
            </TabLink>
          </div>
          <div className="content">
            <TabContent for="mywork">
              <WorkItemStream />
            </TabContent>
            <TabContent for="milestones">
              <MilestoneList disabled={false} includeProject={true} />
            </TabContent>
            <TabContent for="checklists">
              <ChecklistGrid
                renderTimestamp={new Date().getMilliseconds()}
                toggleAddModal={() => {}}
                type={-1}
                parentRecordId={null}
              />
            </TabContent>
            <TabContent for="tasks">
              <ProjectTaskGrid />
            </TabContent>
            <TabContent for="activity">
              <DashboardComponent />
            </TabContent>
            <TabContent for="graphs">
              <DashboardGraphs scope={DashboardScope.Global} />
            </TabContent>
          </div>
        </Tabs>
      </div>
    );
  }
}

//@ts-ignore
export default connect(
  (state: ApplicationState) => state.user,
  null
  // @ts-ignore
)(DashboardHome) as typeof DashboardHome;
