import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as cx from 'classnames';

import { formatDate, camelize } from '../helpers/formatters';
import {
  ListItem,
  DateRangeFilter,
  ProcurementItem,
  PunchItemVm,
} from '../interfaces/interfaces';
import { MessageBox } from '../components/MessageBox';
import { Link } from 'react-router-dom';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import DfrMain from '../dfr/DfrMain';
import { RFIDetail } from '../rfi/RFIDetail';
import { SubmittalDetail } from '../submittals/SubmittalDetail';
import { ProcurementItemHome } from '../procurement/ProcurementItemDetail';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store';
import { actionCreators as procurementActions } from '../store/procurement';
import { actionCreators as userActions } from '../store/user';
import { ConnectedPunchItem } from 'src/punchlist/ConnectedPunchItem';
import {
  PunchlistActions,
  punchItemsProjectIdSelector,
  punchItemsSelector,
} from 'src/store/punchlist';
import { IssueDetailPage } from '../issues/IssueDetailPage';
import { IssueDetail } from '../issues/IssueDetail';

interface WorkItem {
  projectId: number;
  projectName: string;
  type: WorkItemType;
  dueDate: string;
  itemName: string;
  itemId: number;
  actionRequired: string;
}

enum WorkItemType {
  FieldReport,
  RFI,
  Submittal,
  Procurement,
  Quote,
  PunchItem,
  OpenIssue,
}

interface WorkItemStreamAPI {
  selectItem: (i: WorkItem) => void;
  removeItem: (i: WorkItem) => void;
  refreshList: () => void;
}

export const WorkItemStreamContext = React.createContext(
  {} as WorkItemStreamAPI
);

export const WorkItemStream = () => {
  const [loading, setLoading] = useState(false);
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<WorkItem | undefined>(
    undefined
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);
  const [message, setMessage] = useState('');

  const getWorkItems = () => {
    setLoading(true);
    fetch(`api/Dashboard/GetUserWorkItems`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setWorkItems(data);
          setSelectedItem(data[0]);
          setLoading(false);
        } else setLoading(false);
        setMessage(data.message);
      });
  };

  const _removeItem = (item: WorkItem) => {
    setScrollY(
      // @ts-ignore
      document.querySelector('.work-item-stream .card-list').scrollTop
    );
    const remaining = workItems.filter((x) => x !== item);
    const index = selectedIndex <= workItems.length - 1 ? selectedIndex : 0;
    setWorkItems(remaining);
    setSelectedItem(remaining[index]);
  };

  useEffect(() => {
    getWorkItems();
  }, []);

  useEffect(() => {
    const index = selectedIndex <= workItems.length - 1 ? selectedIndex : 0;
    setSelectedItem(workItems[index]);
  }, [workItems]);

  useEffect(() => {
    if (selectedItem) setSelectedIndex(workItems.indexOf(selectedItem));
  }, [selectedItem]);

  useLayoutEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      document
        .querySelector('.work-item-stream .card-list')
        .scrollTo(0, scrollY);
    }, 150);
  }, [workItems]);

  const api: WorkItemStreamAPI = {
    selectItem: (item) => setSelectedItem(item),
    removeItem: _removeItem,
    refreshList: () => {
      setScrollY(
        // @ts-ignore
        document.querySelector('.work-item-stream .card-list').scrollTop
      );
      getWorkItems();
    },
  };

  const cards = loading ? (
    <StandardGridLoader rowContentHeight={69} rowPadding={1} />
  ) : (
    workItems.map((x) => (
      <WorkItemCard
        key={`${x.itemId}_${x.itemName}_${x.actionRequired}`}
        item={x}
        selected={selectedItem === x}
      />
    ))
  );

  return (
    <div className="work-item-stream">
      <MessageBox message={message} clearMessage={() => setMessage('')} />
      <WorkItemStreamContext.Provider value={api}>
        <div style={{ display: 'flex' }}>
          <div className="work-inbox">
            <h5 style={{ marginLeft: '5px' }}>Inbox ({workItems.length})</h5>
            <div className="card-list custom-scrollbar">{cards}</div>
          </div>
          <div className="work-item-detail custom-scrollbar">
            <WorkItemDetail
              item={selectedItem}
              key={selectedItem?.itemId + '_' + selectedItem?.actionRequired}
            />
          </div>
        </div>
      </WorkItemStreamContext.Provider>
    </div>
  );
};

const getItemLink = (item: WorkItem) => {
  let itemUrl = '/';
  switch (item.type) {
    case WorkItemType.FieldReport:
      itemUrl = `/dfr/${item.itemId}`;
      break;
    case WorkItemType.Procurement:
      itemUrl = `/procurement/${item.projectId}/${item.itemId}`;
      break;
    case WorkItemType.RFI:
      itemUrl = `/rfi/${item.itemId}`;
      break;
    case WorkItemType.Submittal:
      itemUrl = `/submittal/${item.itemId}`;
      break;
    case WorkItemType.PunchItem:
      itemUrl = `/project/${item.projectId}#punchlist`;
      break;
    case WorkItemType.OpenIssue:
      itemUrl = `/issue/${item.projectId}/${item.itemId}`;
      break;
  }

  return itemUrl;
};

const WorkItemCard = (props: { item: WorkItem; selected: boolean }) => {
  const { item, selected } = props;
  const api = React.useContext(WorkItemStreamContext);

  return (
    <div
      className={cx('work-item-card', selected && 'selected')}
      onClick={() => api.selectItem(item)}
      key={`${item.itemId}_${item.itemName}`}
    >
      <WorkItemIcon itemType={item.type} dueDate={item.dueDate} />
      <div className="work-item-title truncate" title={item.itemName}>
        {item.itemName}
      </div>
      {/*<div className='work-item-project truncate'><Link to={`/project/${item.projectId}`} target="_blank">{item.projectName}</Link></div>*/}
      <div className="work-item-project truncate">{item.projectName}</div>
      <div className="work-item-status">{item.actionRequired}</div>
      <div className="work-item-timestamp">{formatDate(item.dueDate)}</div>
      <div className="work-item-link">
        <Link to={getItemLink(item)} target="_blank">
          Detail Page
        </Link>
      </div>
    </div>
  );
};

const WorkItemDetail = (props: { item: WorkItem | undefined }) => {
  const { item } = props;
  const api = React.useContext(WorkItemStreamContext);
  const [procurementItem, setProcurementItem] = React.useState<ProcurementItem>(
    {} as ProcurementItem
  );
  const [punchItem, setPunchItem] = React.useState<PunchItemVm>();

  const dispatch = useDispatch();

  if (item === undefined) return <div></div>;

  const _deleteCallback = () => api.removeItem(item);

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);
  const userStore = useSelector((s: ApplicationState) => s.user);
  const punchItems = useSelector(punchItemsSelector);
  const punchItemProjectId = useSelector(punchItemsProjectIdSelector);

  React.useEffect(() => {
    const foundItem = procurementStore.procurementItems.find(
      (x) => x.id === item.itemId
    );
    if (foundItem) setProcurementItem(foundItem);
  }, [procurementStore.procurementItems]);

  React.useEffect(() => {
    if (item.type === WorkItemType.Procurement) {
      const foundItem = procurementStore.procurementItems.find(
        (x) => x.id === item.itemId
      );
      if (!foundItem) {
        dispatch(procurementActions.getItems(item.projectId));
        dispatch(procurementActions.getQuotes(item.projectId));
      }
    }
  }, []);

  React.useEffect(() => {
    const foundItem = punchItems.find((x) => x.id === item.itemId);
    if (foundItem) setPunchItem(foundItem);
  }, [punchItems]);

  React.useEffect(() => {
    if (
      item.type === WorkItemType.PunchItem &&
      item.projectId !== punchItemProjectId
    ) {
      dispatch(PunchlistActions.getPunchItems(item.projectId));
    }
  }, []);

  switch (item.type) {
    case WorkItemType.FieldReport:
      return (
        // @ts-ignore
        <DfrMain
          dfrId={item.itemId}
          deleteCallback={_deleteCallback}
          refreshCallback={api.refreshList}
          currentUser={userStore.user}
        />
      );
    case WorkItemType.RFI:
      return (
        <RFIDetail
          rfiId={item.itemId}
          deleteCallback={_deleteCallback}
          refreshCallback={api.refreshList}
        />
      );
    case WorkItemType.Submittal:
      return (
        <SubmittalDetail
          id={item.itemId}
          deleteCallback={_deleteCallback}
          refreshCallback={api.refreshList}
        />
      );
    case WorkItemType.Procurement:
      return <ProcurementItemHome item={procurementItem} modalScroll={false} />;
    case WorkItemType.PunchItem:
      return (
        <>
          {punchItem && (
            <ConnectedPunchItem
              isWorkItem={true}
              key={item.itemId}
              item={punchItem}
            />
          )}
        </>
      );
    case WorkItemType.OpenIssue:
      return (
        <IssueDetailPage
          issueId={item.itemId}
          key={item.itemId}
          pId={item.projectId}
        />
      )

    default:
      return <div></div>;
  }
};

const WorkItemIcon = (props: { itemType: WorkItemType; dueDate: string }) => {
  const { itemType, dueDate } = props;
  let icon;

  const colorClass = new Date(dueDate) <= new Date() ? ' danger-red' : '';

  switch (itemType) {
    case WorkItemType.FieldReport:
      icon = <span className="fa-solid fa-helmet-safety"></span>;
      break;
    case WorkItemType.RFI:
      icon = <span className="fas fa-badge-check"></span>;
      break;
    case WorkItemType.Submittal:
      icon = <span className="fas fa-file-check"></span>;
      break;
    case WorkItemType.Procurement:
      icon = <span className="fa-light fa-money-check-dollar"></span>;
      break;
    case WorkItemType.PunchItem:
      icon = <span className="fa-light fa-clipboard-list-check"></span>;
      break;
    case WorkItemType.OpenIssue:
      icon = <span className="fa-light fa-circle-exclamation"></span>;
      break;
    default:
      icon = <span></span>;
      break;
  }

  return <div className={cx('work-item-icon', colorClass)}>{icon}</div>;
};
