import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import {
	ChecklistConditionType,
	ChecklistFieldCondition,
	ChecklistFieldType,
	ChecklistTemplateField,
} from "../../interfaces/interfaces";

interface ChecklistFieldConditionProps {
	field: ChecklistTemplateField;
	condition?: ChecklistFieldCondition;
	onAddCondition?: (
		fieldId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => void;
	onUpdateCondition?: (
		fieldConditionId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => void;
	onRemoveCondition: (fieldConditionId: number, templateId: number) => void;
}

const ConditionRow = styled.div({
	justifyContent: "space-around",
	alignItems: "center",
	"& select": {
		width: "30%",
	},
});

export const ChecklistFieldConditionItem: React.FC<
	ChecklistFieldConditionProps
> = ({
	field,
	condition,
	onAddCondition,
	onUpdateCondition,
	onRemoveCondition,
}) => {
	const conditionValueRef = useRef<HTMLSelectElement>(null);
	const conditionTypeRef = useRef<HTMLSelectElement>(null);

	const selectedConditions = field.conditions
		.filter((x) => !(x.id == condition?.id))
		.map((x) => x.value);

	const checkboxOptions = [
		{ value: "true", text: "Yes" },
		{ value: "false", text: "No" },
		{ value: "n/a", text: "N/A" },
	];

	const handleConditionChange = useCallback(() => {
		const conditionValue = conditionValueRef.current?.value;
		const conditionType = conditionTypeRef.current?.value;

		if (
			conditionValue !== "" &&
			conditionValue !== undefined &&
			conditionType !== "" &&
			conditionType !== undefined
		) {
			onAddCondition &&
				onAddCondition(
					field.id,
					field.checklistTemplateSectionId ?? 0,
					field.checklistTemplateId,
					conditionValue,
					+conditionType as ChecklistConditionType
				);
			onUpdateCondition &&
				condition &&
				onUpdateCondition(
					condition.id,
					field.checklistTemplateSectionId ?? 0,
					field.checklistTemplateId,
					conditionValue,
					+conditionType as ChecklistConditionType
				);
		}
	}, []);

	return (
		<ConditionRow className="form-group">
			<span>If</span>
			<select
				className="form-control"
				disabled={!field.isOriginal}
				onChange={() => {
					handleConditionChange();
				}}
				ref={conditionValueRef}
				defaultValue={condition?.value}
			>
				<option value=""></option>
				{field.type == ChecklistFieldType.Option &&
					field.options
						.filter((v) => !selectedConditions.some((x) => x == v.text))
						.map((x) => {
							return (
								<option key={x.id} className={"form-control"} value={x.text}>
									{x.text == "" ? "Option text not set" : x.text}
								</option>
							);
						})}
				{field.type == ChecklistFieldType.Checkbox &&
					checkboxOptions
						.filter((v) => !selectedConditions.some((x) => x == v.value))
						.map((x) => {
							return (
								<option
									key={x.value}
									className={"form-control"}
									value={x.value}
								>
									{x.text}
								</option>
							);
						})}
			</select>
			<span>then requirement equals</span>
			<select
				onChange={() => {
					handleConditionChange();
				}}
				ref={conditionTypeRef}
				className="form-control"
				disabled={!field.isOriginal}
				defaultValue={condition?.conditionType}
			>
				<option value=""></option>
				{Object.values(ChecklistConditionType)
					.filter((v) => !isNaN(Number(v)))
					.map((x) => {
						return (
							<option key={x.toString()} className={"form-control"} value={x}>
								{conditionTypeToString(+x)}
							</option>
						);
					})}
			</select>
			{condition && field.isOriginal && (
				<span
					style={{ borderRadius: "50%" }}
					className="btn btn-sm fas fa-trash-alt btn-background-hover"
					title="Delete this condition"
					onClick={() =>
						onRemoveCondition(condition.id, field.checklistTemplateId)
					}
				></span>
			)}
		</ConditionRow>
	);
};

export const conditionTypeToString = (t: ChecklistConditionType) => {
	switch (t) {
		case ChecklistConditionType.None:
			return "None";
		case ChecklistConditionType.Photo:
			return "Photo";
		case ChecklistConditionType.Comment:
			return "Comment";
		case ChecklistConditionType.PhotoComment:
			return "Photo and Comment";
	}
};
