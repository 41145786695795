import * as React from 'react'
import { ContactCard, ListItem } from '../interfaces/interfaces';
import FilterableSelect from './FilterableSelect';

interface Props {
    defaultValue?: string
    name: string
    changeHandler?: (name: string, val: string) => any
    onBlur?: (val: string) => any
    contacts: ContactCard[]
    emailSubject?: string
    emailBody?: string
    disabled?: boolean,
    openField?: boolean,
    allowEmpty?: boolean,
}

export default class UserCard extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props)
    }   

    _userChange = (val: string) => {
        if (this.props.changeHandler) this.props.changeHandler(this.props.name, val);
    }

    render() {
        const { contacts, defaultValue, name } = this.props,
            contactItems = contacts.map(x => new ListItem(x.userId, x.name));
        
        const currentUser = defaultValue ? 
            this.props.contacts.find(u => u.userId == this.props.defaultValue)
            : null
        let currentPhone = 'phone', currentEmail = 'email'
        if (currentUser) {
            currentPhone = currentUser.phone
            currentEmail = currentUser.email
        }

        const encodedSubject = encodeURIComponent((this.props.emailSubject || '').trim())
        const encodedBody = encodeURIComponent((this.props.emailBody || '').trim())

        const selUser = contacts.find(u => u.userId == defaultValue) as ContactCard;
        const userEle = this.props.disabled ?
            <input className='form-control' disabled value={selUser ? selUser.name : ''} /> :
            <FilterableSelect items={contactItems} defaultVal={defaultValue}
                id={name} onChange={this._userChange} onBlur={this.props.onBlur}
                openField={this.props.openField} allowEmpty={this.props.allowEmpty}
            />            
        
        return (
            <div className='userCard'>
                {userEle}
                <a key={currentPhone} href={`tel:${currentPhone}`}>
                    <span key={currentPhone} title={currentPhone} className='fas fa-phone'></span>
                </a>
                <a key={currentEmail} href={`mailto:${currentEmail}?Subject=${encodedSubject}&body=${encodedBody}`}>
                    <span key={currentEmail} className='fas fa-envelope'></span>
                </a>
            </div>

        )        
    }
}