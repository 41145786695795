import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  FieldFilterableSelect,
  FieldInput,
  FieldTextArea,
} from "../components/FieldComponents";
import { ProcurementItemStatus } from "../interfaces/enums";

import {
  ListItem,
  ProcurementItem,
  ProjectDetailVm,
} from "../interfaces/interfaces";
import { ApplicationState } from "../store";
import { actionCreators } from "../store/config";
import { actionCreators as procurementActions } from "../store/procurement";
import { AddQuoteComponent } from "./AddQuoteComponent";
import { ProcurementComments } from "./ProcurementComments";
import { ProcurementItemDetailModal } from "./ProcurementItemDetail";
import { QuoteSummaryLine } from "./QuoteSummaryLine";
import $ from "jquery";
import { FilterableSelectContainer } from "src/components/FilterableSelectContainer";

interface Props {
  item: ProcurementItem;
  project: ProjectDetailVm;
}

export const ProcurementItemLine = (props: Props) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const [showAddReviewer, setShowAddReviewer] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  const [managerWidth, setManagerWidth] = React.useState("100px");
  const [reviewerWidth, setReviewerWidth] = React.useState("100px");
  const [managerTop, setManagerTop] = React.useState("0px");
  const [reviewerTop, setReviewerTop] = React.useState("0px");
  const [bodyScroll, setBodyScroll] = React.useState(0);

  const managerRef = React.useRef<HTMLDivElement>(null);
  const reviewerRef = React.useRef<HTMLDivElement>(null);

  const userStore = useSelector((s: ApplicationState) => s.user);
  const globalStore = useSelector((s: ApplicationState) => s.global);
  const dispatch = useDispatch();

  const { user } = userStore;
  const { item, project } = props;

  const userItems = globalStore.users.map(
    (x) => new ListItem(x.userId, x.fullName)
  );
  const userId = (userStore.user || { userId: "" }).userId;
  const hasApprovedQuote = item.quoteSummaries.some((x) => x.approved === true);

  const canEdit =
    user !== undefined &&
    item.status !== ProcurementItemStatus.Approved &&
    item.status !== ProcurementItemStatus.Canceled &&
    (user.userId === item.procurementManagerId ||
      user.userId === item.projectManagerId ||
      user.userId === item.userId);

  const _update = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    const field = e.currentTarget.name,
      value = e.currentTarget.value,
      id = e.currentTarget.getAttribute("data-id");
    dispatch(procurementActions.updateItem(id || 0, field, value));
  };

  React.useEffect(() => {
    if (managerRef.current?.firstChild && reviewerRef.current?.lastChild) {
      setManagerWidth(
        (managerRef.current?.firstChild as HTMLDivElement).offsetWidth + "px"
      );
      setReviewerWidth(
        (reviewerRef.current?.lastChild as HTMLDivElement).offsetWidth + "px"
      );
    }
  }, [managerRef.current, reviewerRef.current]);

  React.useEffect(() => {
    if (managerRef.current?.firstChild && reviewerRef.current?.lastChild) {
      const managerValue =
        ((
          managerRef.current?.firstChild as HTMLDivElement
        ).getBoundingClientRect().top ?? 0) +
        ((managerRef.current?.firstChild as HTMLDivElement).offsetHeight ?? 0) +
        "px";
      setManagerTop(managerValue);

      const reviewerValue =
        ((
          reviewerRef.current?.lastChild as HTMLDivElement
        ).getBoundingClientRect().top ?? 0) +
        ((reviewerRef.current?.lastChild as HTMLDivElement).offsetHeight ?? 0) +
        "px";
      setReviewerTop(reviewerValue);
    }

    $("body")[0].click();
  }, [bodyScroll, showAddReviewer]);

  React.useEffect(() => {
    $(".col-sm-12.custom-scrollbar").on("scroll", () => {
      setBodyScroll($(".col-sm-12.custom-scrollbar").scrollTop() ?? 0);
    });
  }, []);

  return (
    <React.Fragment>
      <div key={item.id} className="procurement-item-line sv-grid-line">
        <div className="my-col-4 small-pad">
          <FieldInput
            id={item.id}
            field="itemDescription"
            value={item.itemDescription}
            canEdit={canEdit}
            update={_update}
          />
        </div>
        <FilterableSelectContainer
          top={managerTop}
          ulWidth={managerWidth}
          ref={managerRef}
          className="my-col-3 small-pad"
        >
          <FieldFilterableSelect
            id={item.id}
            field="procurementManagerId"
            value={item.procurementManagerId}
            canEdit={canEdit}
            items={userItems}
            update={(f, v, i) =>
              dispatch(procurementActions.updateItem(i, f, v))
            }
          />
        </FilterableSelectContainer>
        <div className="my-col-2 small-pad">
          <FieldInput
            id={item.id}
            field="rfqDueDate"
            value={item.rfqDueDate}
            canEdit={canEdit}
            update={_update}
            dataType="date"
          />
        </div>
        <div className='my-col-2 small-pad'>
          <FieldInput id={item.id} field='onSiteDueDate'
            value={item.onSiteDueDate}
            canEdit={canEdit}
            update={_update}
            dataType='date' />
        </div>
        {/*<div className="my-col-2 small-pad">*/}
        {/*  <FieldInput*/}
        {/*    id={item.id}*/}
        {/*    field="onSiteDueDate"*/}
        {/*    value={item.onSiteDueDate}*/}
        {/*    canEdit={canEdit}*/}
        {/*    update={_update}*/}
        {/*    dataType="number"*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="my-col-2 small-pad">
          <FieldInput
            id={item.id}
            field="budget"
            value={item.budget}
            canEdit={canEdit}
            update={_update}
            dataType="money"
          />
        </div>
        <div className="my-col-3 small-pad">
          {item.quoteSummaries.map((x) => (
            <QuoteSummaryLine
              showDetailButton={false}
              key={x.id}
              quote={x}
              detailCallback={() => { }}
            />
          ))}
          {!hasApprovedQuote && userId == item.procurementManagerId && (
            <AddQuoteComponent item={item} />
          )}
        </div>
        <FilterableSelectContainer
          top={reviewerTop}
          ulWidth={reviewerWidth}
          ref={reviewerRef}
          style={
            {
              /*marginLeft:"20px"*/
            }
          }
          className="my-col-3 small-pad"
        >
          {item.reviewers.map((x) => (
            <div key={x.id} className="reviewer-line">
              {x.userFullName}{" "}
              {canEdit && (
                <span
                  className="fas fa-times"
                  onClick={() =>
                    dispatch(procurementActions.removeReviewer(item.id, x.id))
                  }
                ></span>
              )}
            </div>
          ))}
          {canEdit && !showAddReviewer ? (
            <div className="reviewer-line">
              <button
                className="btn btn-x-sm btn-blue"
                onClick={() => setShowAddReviewer(true)}
              >
                Add
              </button>
            </div>
          ) : (
            []
          )}
          {showAddReviewer && (
            <FieldFilterableSelect
              id={item.id}
              field="reviewerId"
              value={""}
              canEdit={canEdit}
              items={userItems}
              update={(f, v, i) => {
                dispatch(procurementActions.addReviewer(i, v));
                setShowAddReviewer(false);
              }}
            />
          )}
        </FilterableSelectContainer>
        <div className="my-col-1 small-pad">
          <button
            className="btn btn-x-sm btn-blue fas fa-list"
            onClick={() => setShowDetail(true)}
          ></button>{" "}
          &nbsp;
          <button
            className="btn btn-x-sm btn-outline-secondary fas fa-comment"
            onClick={() => setShowComments(true)}
          ></button>
        </div>
      </div>
      {showDetail && (
        <ProcurementItemDetailModal
          onPOAdd={() => setShowDetail(true)}
          project={project}
          key={`${item.status}_${item.reviewers.length}`}
          item={item}
          close={() => setShowDetail(false)}
        />
      )}
      {showComments && (
        <ProcurementComments item={item} close={() => setShowComments(false)} />
      )}
    </React.Fragment>
  );
};
