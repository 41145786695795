import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IssueConfig,
  IssuePostModel,
  IssueVm,
  UpdateObject,
} from 'src/interfaces/interfaces';

export interface IssuesState {
  issues: IssueVm[];
  status: 'idle' | 'loading' | 'saving' | 'completed';
  selectedIssueId?: number;
  issueConfig: IssueConfig;
}

export const initialState: IssuesState = {
  issues: [],
  status: 'idle',
  issueConfig: {
    issueTypes: [],
    rootCauses: [],
    contributorFactors: [],
    dispositions: [],
  },
};

const { actions: IssuesActions, reducer: IssuesReducer } = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    getIssues(_state, _action: PayloadAction<number>) { },
    setIssues(state, action: PayloadAction<IssueVm[]>) {
      state.issues = action.payload;
    },
    setSelectedIssueId(state, action: PayloadAction<number | undefined>) {
      state.selectedIssueId = action.payload;
    },
    getConfig() { },
    setConfig(state, action: PayloadAction<IssueConfig>) {
      state.issueConfig = action.payload;
    },
    createIssue(_state, _action: PayloadAction<IssuePostModel>) { },
    setStatus(state, action: PayloadAction<'idle' | 'loading' | 'saving' | 'completed'>) {
      state.status = action.payload;
    },
    updateIssue(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) { },
    addFiles(_state, _action: PayloadAction<{ id: number, files: FileList }>) { },
    removeFile(_state, _action: PayloadAction<{ issueId: number, fileId: number }>) { },
    addCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds?: string; emails?: string }>
    ) { },
    removeCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds: string }>
    ) { },
  },
});

export { IssuesActions, IssuesReducer };

export default IssuesReducer;
