import * as React from 'react';
import { WhatsNewLogVm } from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';

export const WhatsNew = () => {

  const [items, setItems] = React.useState([] as WhatsNewLogVm[])

  React.useEffect(() => {
    fetch(`api/whatsnew/get`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setItems(data);
      })
  }, [])

  return (
    <div>
      <div className="pageHeader">What's New</div>
      <div
        style={{ overflow: 'auto', maxHeight: '80vh' }}
        className="whats-new-table custom-scrollbar"
      >
        {items.map(x => {
          return (
            <>
              <b>{formatDate(x.timestamp)}</b>
              <ul style={{ marginTop: '10px', marginBottom: '15px' }}>
                {x.items.map(y => {
                  return <li>
                    {y.note}
                    {y.subnotes && y.subnotes.length ? <ul>{y.subnotes.map(z => <li>{z}</li>)}</ul> : []}
                  </li>
                })}
              </ul>
            </>
          )
        })}        
      </div>
    </div>
  );
}

