import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import { CSharpTypeCode } from 'src/interfaces/enums';
import { SafetyKpiTemplate, UpdateObject } from 'src/interfaces/interfaces';
import { SafetyKPIActions } from 'src/store/safetyKPI/safetyKPI.reducer';
import {
  canRetrieveTemplatesSelector,
  isTemplatesLoadingSelector,
  newDuplicateTemplateSelector,
  safetyKpiTemplatesSelector,
} from 'src/store/safetyKPI/safetyKPI.selectors';
import { SafetyKPITemplateModal } from './SafetyKPITemplateModal';

export const SafetyKPITemplateHome: React.FC = () => {
  const [chosenTemplate, setChosenTemplate] = useState<SafetyKpiTemplate>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const kpiTemplates = useSelector(safetyKpiTemplatesSelector);
  const canRetrieveTemplates = useSelector(canRetrieveTemplatesSelector);
  const isLoading = useSelector(isTemplatesLoadingSelector);
  const newDuplicateTemplate = useSelector(newDuplicateTemplateSelector);
  const dispatch = useDispatch();

  const columns: MetaFieldInfo[] = [
    {
      displayName: 'Name',
      propName: 'name',
      jsType: 'string',
      propType: CSharpTypeCode.String,
    },
    {
      displayName: 'Created By',
      propName: 'userFullName',
      jsType: 'string',
      propType: CSharpTypeCode.String,
    },
  ];

  const renderItem = useCallback(
    (template: SafetyKpiTemplate) => {
      return (
        <div
          onClick={() => onRowClick(template)}
          className="sv-grid-line"
          key={template.id}
        >
          <div className="col">{template.name}</div>
          <div className="col">{template.userFullName}</div>
        </div>
      );
    },
    [kpiTemplates]
  );

  useEffect(() => {
    if (canRetrieveTemplates) {
      dispatch(SafetyKPIActions.getTemplates());
    }
  }, [canRetrieveTemplates, kpiTemplates]);

  const onRowClick = (template: SafetyKpiTemplate) => {
    setChosenTemplate(template);
    setIsModalVisible(true);
  };

  const foundTemplate = useMemo(() => {
    return kpiTemplates.find((x) => x.id == chosenTemplate?.id);
  }, [kpiTemplates, chosenTemplate]);

  const templateEntryUpdate = (id: number, info: UpdateObject) => {
    dispatch(SafetyKPIActions.updateTemplateEntry({ id, info }));
  };

  const templateEntryRemove = (id: number) => {
    dispatch(SafetyKPIActions.removeTemplateEntry(id));
  };

  const templateUpdate = (id: number, info: UpdateObject) => {
    dispatch(SafetyKPIActions.updateTemplate({ id, info }));
  };

  const templateAdd = () => {
    dispatch(SafetyKPIActions.addTemplate());
  };

  const templateCopy = (id: number) => {
    dispatch(SafetyKPIActions.copyTemplate(id));
    setIsModalVisible(false);
  };

  useEffect(() => {
    setChosenTemplate(newDuplicateTemplate);
    setIsModalVisible(true);
  }, [newDuplicateTemplate]);

  return (
    <>
      {foundTemplate && isModalVisible && (
        <SafetyKPITemplateModal
          template={foundTemplate}
          onModalClose={() => setIsModalVisible(false)}
          onEntryUpdate={templateEntryUpdate}
          onTemplateUpdate={templateUpdate}
          onEntryRemove={templateEntryRemove}
          onTemplateCopy={templateCopy}
        />
      )}
      <CustomizableGrid
        items={kpiTemplates}
        columns={columns}
        loading={isLoading}
        hideLoadingWhenEmpty={true}
        mapCallback={renderItem}
        evenColumnSpacing={true}
        addCallback={templateAdd}
      />
    </>
  );
};
