import * as React from 'react';
import FilterableSelect from '../components/FilterableSelect';
import { MessageBox } from '../components/MessageBox';
import { ListItem, VendorRating, VendorVm } from '../interfaces/interfaces';

export const NewRating = (props: { vendor: VendorVm, onSuccess: (r: VendorRating) => void }) => {

    const [message, setMessage] = React.useState('');

    const _save = () => {
        const rating = (document.querySelector('#new-rating-input') as HTMLInputElement).value;
        const projectId = (document.querySelector('#rating-project-id input[name="rating-project-id"]') as HTMLSelectElement).value;
        const notes = (document.querySelector('#rating-notes') as HTMLTextAreaElement).value;

        if (!projectId || projectId === '') {
            setMessage('Error: You must select a project for a rating');
            return;
        }

        fetch(`api/Vendor/Rating`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ vendorId: props.vendor.id, projectId: projectId, rating: rating, notes: notes })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) props.onSuccess(data);
                else setMessage(data.message);
            })
    }

    const { ratings, vendorProjects } = props.vendor;

    const remainingProjects = vendorProjects
        .filter(x => ratings.findIndex(y => y.projectId == x.projectId) === -1)
        .map(x => new ListItem(x.projectId, x.projectName));

    return (
        <div className='new-vendor-rating'>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
            <div className='rating-title'>
                <div className='project-select'>
                    <div><b>Project</b></div>
                    <FilterableSelect id='rating-project-id' items={remainingProjects} />
                </div>
                <NewRatingStars />
            </div>      
            <div className='rating-notes'>
                <b>Comments</b>
                <textarea id='rating-notes' className='form-control'></textarea>
            </div>
            <button className='btn btn-sm btn-green' onClick={_save} style={{ fontSize: '10px'}}>Save</button>
        </div>
    )
}

const NewRatingStars = () => {

    const [rating, setRating] = React.useState(1);

    const stars = Array.from(Array(5).keys()).map(i => {
        const className = i < rating ?
            'fas fa-star' :
            'far fa-star';
        return <span key={i} className={className} onClick={() => setRating(i+1)}></span>
    })

    return (
        <div className='rating-stars'>
            {stars}
            <input type='hidden' id='new-rating-input' value={rating} />
        </div>
    )
}