import { camelize } from "./formatters";

export { }
//declare module global {
declare global {
    interface Array<T> {
        filterByStringProp(prop: string, val: string): Array<T>;
        filterByMultiStringProp(props: string[], val: string): Array<T>;
        filterByNestedProp(propPath: string, val: string): Array<T>;
        splitFilter(prop: string, val: string): [Array<T>, Array<T>];
        remove(item: T): Array<T>;
    }

    interface String {
        splitOnCase(): string
    }
}
/* Splits an array into two arrays: one where the filter matched and one where it didn't */
Array.prototype.splitFilter = function (prop: string, val: string) {
    const hit:any[] = [], noHit:any[] = [];
    for (let i = 0; i < this.length; ++i) {
        const strPropVal = this[i][prop] as string
        if (strPropVal) {
            if (strPropVal.toUpperCase().indexOf(val.toUpperCase()) !== -1) {
                hit.push(this[i])
            } else {
                noHit.push(this[i])
            }
        } else {
            noHit.push(this[i])
        }
    }
    return [hit, noHit]
}

Array.prototype.filterByStringProp = function (prop: string, val: string) {
    return this.filter(i => {
        if (val === "") return true; // If the entry is empty we want to fully restore the list

      // to allow for filtering of object based on a nested path to the filterable field
        const strPropVal = prop.split('.').reduce((p, c) => {
            return (p && (p[c] !== undefined && p[c] !== null)) ? p[c].toString() : undefined
        }, i);

        if (strPropVal !== undefined) {
            if (typeof strPropVal === 'number') return strPropVal === parseInt(val)
            return (!(strPropVal && strPropVal.length)) ? false : strPropVal.toUpperCase().indexOf(val.toUpperCase()) !== -1
        }
        return false;
    })
}

/* Uses array-splitting filter to set aside hits and do a next-check on the remaining un-hit items  */
Array.prototype.filterByMultiStringProp = function <T>(props: string[], val: string) {
    let remaining = this.slice();
    const retArr = new Array<T>();
    for (let i = 0; i < props.length; ++i) {
        const split: any[] = remaining.splitFilter(props[i], val);
        retArr.push(...split[0])
        remaining = split[1]
    }
    //props.forEach(p => filtered = filtered.filterByStringProp(p, val))    
    return retArr;
}

Array.prototype.filterByNestedProp = function <T>(propPath: string, val: string) {
    if (val === '') return this;

    const props = propPath.split('.');

    return props.length === 1 ?
        this.filterByStringProp(props[0], val) :
        this.filter(x => {
            let evaluated: any = x;
            for (let i = 0; i < props.length -1; ++i) {
                evaluated = evaluated[camelize(props[i])];
            }
            return evaluated === null ? false :
                Array.isArray(evaluated) ?
                    evaluated.filterByStringProp(camelize(props[props.length - 1]), val).length !== 0 :
                    (evaluated[camelize(props[props.length - 1])] || '').toUpperCase().indexOf(val.toUpperCase()) !== -1;
        });

    //if (props.length === 1) {
    //    return this.filterByStringProp(propPath, val);
    //}

    //for (let i = 0; i < filtered.length; ++i) {
    //    /* loop over each path in the property tree */
    //    for (let j = 0; j < props.length; ++j) {
    //        /* reduce the prop path by one from the front so we can drill further down */
    //        if (Array.isArray(filtered[i][props[j]])) {
    //            const newTreePath = props.slice(j + 1).join('.');
    //            filtered[i][props[j]] = filtered[i][props[j]].filterByNestedProp(newTreePath, val);
    //        }
    //        else if (j !== props.length - 1) filtered[i][props[j]] = props.reduce((p, c) => p && p[c] || undefined, filtered[i]);
    //    }
    //}

    //return filtered;
}

Array.prototype.remove = function <T>(item: T) {
    const i = this.length - 1;
    while (i >= 0) {
        if (this[i] === item) this.splice(i, 1)
    }

    return this;
}

String.prototype.splitOnCase = function () {
    let res = this[0]
    for (let i = 1; i < this.length; ++i) {
        if (this[i] === this[i].toUpperCase())
            res += ' ';
        res += this[i]
    }

    return res;
}
