import React, { useState } from "react"
import FilterableSelect from "src/components/FilterableSelect";
import { MessageBox } from "src/components/MessageBox";
import { ListItem } from "src/interfaces/interfaces";
import Modal from '../components/Modal'


interface RFISwapReviewerModalProps {
    onSave: (discipline:string, reviewerId:string) => void;
    onClose: () => void;
    disciplines: ListItem[],
    reviewers: ListItem[],
    swapReviewer: string,
    swapDiscipline: string,
}



const RFISwapReviewerModal:React.FC<RFISwapReviewerModalProps> = ({onSave, onClose, disciplines, reviewers, swapReviewer, swapDiscipline}) => {
    const [message, setMessage] = useState('');
    
    
    const handleOnSave = () =>{
        const discipline = (document.getElementsByName('swap-discipline-select')[0] as HTMLInputElement) ? (document.getElementsByName('swap-discipline-select')[0] as HTMLInputElement).value : ''
        const reviewer = (document.getElementsByName('swap-reviewer-select')[0] as HTMLInputElement) ? (document.getElementsByName('swap-reviewer-select')[0] as HTMLInputElement).value : ''

        if(discipline && reviewer){
            if (discipline === swapDiscipline && reviewer == swapReviewer){
                setMessage('Error: Please swap to a new discipline and reviewer combination.')
            }else{
                onSave && onSave(discipline, reviewer);
            }
        }else{
            setMessage('Error: Please select a proper discipline and reviewer to swap to.')
        }
    }
    
    return (
        <Modal style={{display: "flex" }}>
                <MessageBox message={message} clearMessage={() => setMessage('')} />
                <div className='modal-header'>
                    <h4>Swap Reviewer/Discipline</h4>
                </div>
                <div className='modal-body'>
                <div className='swap-reviewer-section'>
                        <div className='form-group'>
                            <span>Discipline</span>
                            <FilterableSelect defaultVal={swapDiscipline} id='swap-discipline-select' items={disciplines} openField={false} />
                        </div>
                        <div className='form-group'>
                            <span>Reviewer</span>
                            <FilterableSelect defaultVal={swapReviewer} id='swap-reviewer-select'
                                items={reviewers} openField={false} />
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-sm btn-green' onClick={() => handleOnSave()}>Save</button>
                    <span className='btn btn-sm btn-outline-secondary' onClick={onClose}>Close</span>
                </div>
        </Modal>
        
        )
}

export default RFISwapReviewerModal;