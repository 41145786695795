import * as React from 'react';
import { ProcessingNotificationActions } from 'src/store/processingNotifications';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ProcessingNotification,
  ProcessingStatus,
} from 'src/interfaces/interfaces';
import { formatDateTime } from 'src/helpers/formatters';
import { off } from 'process';
import { FileDownloader } from '../FileDownloader';

interface NotificationItemProps {
  notification: ProcessingNotification;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const fileDownloaderRef = React.useRef<HTMLSpanElement>(null);
  const dispatch = useDispatch();
  const isProcessing = notification.status == ProcessingStatus.Processing;
  const progressBarStyle = isProcessing
    ? 'progress-bar progress-bar-striped progress-bar-animated bg-success'
    : 'progress-bar bg-success';

  let status = '';
  switch (notification.status) {
    case ProcessingStatus.Completed:
      status = 'Complete';
      break;
    case ProcessingStatus.Downloaded:
      status = 'Downloaded';
      break;
  }

  const handleOnHover = () => {
    if (!notification.isRead) {
      dispatch(ProcessingNotificationActions.setReadNotification(notification));
    }
  };

  return (
    <div
      onMouseEnter={handleOnHover}
      className={
        notification.isRead ? 'notification-item' : 'notification-item unread'
      }
    >
      <div className="notification-timestamp">
        {formatDateTime(notification.timestamp)}
      </div>
      <div style={{ marginBottom: '5px' }}>{notification.documentName}</div>
      <div style={{ height: '15px', marginBottom: '5px' }} className="progress">
        <div
          className={progressBarStyle}
          role="progressbar"
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: '100%' }}
        >
          {status}
        </div>
      </div>
      {(notification.downloadLink || notification.runLink) && (
        <div>
          {notification.downloadLink && (
            <a target={'_blank'} href={notification.downloadLink} download>
              Download
              {notification.status == ProcessingStatus.Downloaded
                ? ' Again'
                : null}
            </a>
          )}
          {notification.runLink && (
            <>
              <a
                target={'_blank'}
                download
                onClick={() => fileDownloaderRef.current?.click()}
              >
                Run Again
              </a>
              <FileDownloader
                actionPath={notification.runLink}
                fileType={''}
                queryParams={''}
                ref={fileDownloaderRef}
                inlineStyles={{ display: 'none' }}
              />
            </>
          )}
        </div>
      )}
      <span
        className="fas fa-times"
        title="Dismiss this notification"
        onClick={() =>
          dispatch(
            ProcessingNotificationActions.deleteNotification(notification)
          )
        }
      ></span>
    </div>
  );
};
