import * as React from "react";
import { connect } from "react-redux";
import {
    RFIDetailVm,
} from "../interfaces/interfaces";
import { RouteComponentProps } from "react-router";
import { Loader } from "../components/Loader";
import * as UserStore from "../store/user";
import { ApplicationState } from "../store";
import { BackLink } from "../components/BackLink";
import { EventHistoryLog } from "../components/EventHistoryLog";
import { RFIDetail } from "./RFIDetail";
import { getRfi } from "../services/rfi";
import { RectangleLoader } from "../loaders/RectangleLoader";

type Props = UserStore.Store & RouteComponentProps<{ id: string }>;

interface State {
    record: RFIDetailVm;
    projectNumber: string;
}

class RFIPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            record: {} as RFIDetailVm,
            projectNumber: "",
        };
    }

    componentDidMount() {
        const id = parseInt(this.props.match.params.id) || 0;
        const get = async () => {
            const res = await getRfi(id)
            if (res && res.status === 200) {
                this.setState({ record: res.data.rfi, projectNumber: res.data.projectNumber });
            }
        }
        get();
    }

    render() {
        const {
            record,
            projectNumber,
        } = this.state;

        return (
            <div className='rfi-page'>
                <BackLink link={`/project/${record.projectId}#rfi`} />
                {projectNumber ? <h3>{projectNumber}</h3> : <RectangleLoader height='33px' width='250px' style={{ display: 'block' }} />}
                <hr />
                <div style={{ display: "flex" }}>
                    <RFIDetail rfiId={parseInt(this.props.match.params.id) || 0} />
                    <div
                        className="rfi-logs mobile-hide"
                        style={{ position: "relative" }}
                    >
                        <div className="logs-header">History Logs</div>
                        {record.id !== undefined && record.id !== 0 ? (
                            <EventHistoryLog objectName="RFI" objectId={record.id} />
                        ) : (
                            []
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

//@ts-ignore
export default connect(
    (state: ApplicationState) => state.user,
    UserStore.actionCreators
    // @ts-ignore
)(RFIPage) as typeof RFIPage;
