import * as React from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';
import { formatKPIValue, getDataPoints, getNestedPropValue } from '../charts/chartHelpers';
import { GraphMeasureType } from '../charts/GraphPart';
import { Loader } from '../components/Loader';
import { sum } from '../helpers/formulas';
import { KPIGraphFormat } from '../interfaces/enums';
import { PunchItemStatus, PunchItemVm } from '../interfaces/interfaces';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { punchItemsLoadingSelector } from '../store/punchlist';

import './punchlist-charts.css';

const KnownStatuses = [
  "Draft",
  "Sent",
  "In Review",
  "Work Required",
  "Closed"
];

export const PunchlistCharts = (props: { items: PunchItemVm[] }) => {

  const { items } = props;
  const loading = useSelector(punchItemsLoadingSelector) === 'loading';
  //const loading = false;


  return <div className='punchlist-charts'>
    <div className='punchlist-chart-container horizontal-bar-chart'>
      {loading ? <RectangleLoader height={'250px'} /> : <PunchItemsByAssigneeBarChart items={items} />}
    </div>
    <div className='punchlist-chart-container pie-chart'>
      {loading ? <RectangleLoader height={'250px'} /> : <PunchItemsByStatusPieChart items={items} />}
    </div>
    <div className='punchlist-kpi-column'>
      <div className='punchlist-chart-container kpi-chart'>
        {loading ? <RectangleLoader /> : <PunchItemsResponseTimeKPI items={items} />}
      </div>
      <div className='punchlist-chart-container kpi-chart'>
        {loading ? <RectangleLoader /> : <PunchItemsOpenKPI items={items} dueDate={new Date()} />}
      </div>
    </div>
  </div>
};

const PunchItemsByAssigneeBarChart = (props: { items: PunchItemVm[] }) => {

  const { items } = props;

  if (items.length === 0) return <h5>No items to display in chart</h5>

  const format = [
    [
      "Ball In Court",
      "Draft",
      "Sent",
      "In Review",
      "Work Required",
      "Closed",
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ]
  ];

  const counts = items.reduce((p, c) => {
    const name = getNestedPropValue(c, "ballInCourtName");
    const status = getNestedPropValue(c, "status");
    if (!Object.prototype.hasOwnProperty.call(p, name)) {
      p[name] = [0, 0, 0, 0, 0];
    }
    p[name][status]++;
    return p;
  }, {});

  const data = Object.keys(counts).map((x) => {
    return [x, ...counts[x], null];
  });

  const dataPts = format.concat(data);

  const options = {
    title: "Items by Ball In Court",
    chartArea: { width: "65%" },
    isStacked: true,
    colors: ["#f4d053", "#2aaaaa", "#2864e6", "#d9534f", "#303030"],
    hAxis: {
      title: "Item Count",
      minValue: 0,
      minorGridlines: {
        count: 0,
      }
    },
    bar: {
      groupWidth: "40%"
    },
    fontName: "Montserrat",
  };

  return <div>
    <Chart
      key={`bar-chart-${props.items.length}`}
      graphID={`assignee-bar-chart`}
      className=""
      chartType="BarChart"
      width={"100%"}
      height={"250px"}
      loader={<Loader loading={true} />}
      data={dataPts}
      options={options}
    />
  </div>
}

const PunchItemsByStatusPieChart = (props: { items: PunchItemVm[] }) => {
  const { items } = props;

  if (items.length === 0) return <h5>No items to display in chart</h5>

  const format = [
    [
      "Status",
      "Item Count",
      { role: "style" }
    ]
  ];

  const data: any[][] = KnownStatuses.map(x => {
    const ct = items.filter(i => i.statusName === x).length;
    return [x, ct, null]
  })

  const dataPts = format.concat(data);

  const options = {
    title: "Items by Status",
    pieHole: 0.5,
    is3D: false,
    pieSliceText: "value",
    chartArea: { left: '25%', width: '65%', height: '75%' },
    colors: ["#f4d053", "#2aaaaa", "#2864e6", "#d9534f", "#303030"],
  };

  return <div>
    <Chart
      key={`status-pie-chart-${props.items.length}`}
      graphID={`status-pie-chart`}
      className=""
      chartType="PieChart"
      width={"100%"}
      height={"250px"}
      loader={<Loader loading={true} />}
      data={dataPts}
      options={options}
    />
  </div>
}

const PunchItemsResponseTimeKPI = (props: { items: PunchItemVm[] }) => {

  const responseTimes = props.items
    .filter(x => x.status === PunchItemStatus.Closed)
    .map(x => {
      //if (x.status === PunchItemStatus.Closed) {
      const diffTime = new Date(x.dateClosed).getTime() - new Date(x.createdDate).getTime()
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //}
      //else {
      //    const diffTime = new Date().getTime() - new Date(x.createdDate).getTime()
      //    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      //}
    });


  const value = responseTimes.length ? formatKPIValue(responseTimes.reduce(sum) / responseTimes.length, KPIGraphFormat.Number) : "N/A";

  return <div className="graph-kpi-value">
    <h5>Avg Time To Closed (days)</h5>
    <h2>{value}</h2>
  </div>
}

const PunchItemsOpenKPI = (props: { items: PunchItemVm[], dueDate: Date }) => {

  //const value = props.dueDate > new Date() ? 0 : props.items.filter(x => x.status !== PunchItemStatus.Closed).length;
  const value = props.items.filter(x => x.status !== PunchItemStatus.Closed).length;

  return <div className="graph-kpi-value">
    <h5>Total Open Items</h5>
    <h2>{formatKPIValue(value, KPIGraphFormat.Number)}</h2>
  </div>
}

// Using memo here because this can be a pure component
//export default React.memo(PunchlistCharts);