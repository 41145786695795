import React, { useState }  from "react";
import { FieldInput, FieldTextArea } from "src/components/FieldComponents";
import { Loader } from "src/components/Loader";
import Modal from "src/components/Modal";
import { PurchaseOrderStatus } from "src/interfaces/enums";
import { PurchaseOrder } from "src/interfaces/interfaces";
import { updatePurchaseOrder } from "src/services/purchaseOrders";

 interface PurchaseOrderProps {
    po: PurchaseOrder;
    canEdit: boolean;
    onUpdate?: () => void;
}

export const PurchaseOrderComponent: React.FC<PurchaseOrderProps> = ({po, canEdit, onUpdate}) =>{
    const [isLoading, setIsLoading] = useState(false);
    const _update = async (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);

        if(e.currentTarget.name === "closed" && e.currentTarget instanceof HTMLInputElement){
            if (window.confirm("Are you sure you want to close this purchase order? Doing so will lock it from editing.")){
                const success = await updatePurchaseOrder(po.id, e.currentTarget.name, e.currentTarget.value);

                if (success){
                    onUpdate && onUpdate()
                }
            }else{
                e.currentTarget.checked = false;
            }
            e.preventDefault();
            e.stopPropagation();
        }else
        {
            const success = await updatePurchaseOrder(po.id, e.currentTarget.name, e.currentTarget.value);

            if (success){
                onUpdate && onUpdate()
            }
        }

        setIsLoading(false);
    }
    
    const purchaseOrderStatus = (s: PurchaseOrderStatus) => {
        switch (s) {
            case PurchaseOrderStatus.Draft: return 'Draft';
            case PurchaseOrderStatus.Request: return 'Requested';
            case PurchaseOrderStatus.Release: return 'Released';
            case PurchaseOrderStatus.Closed: return 'Closed';    
        }}
    
    
    return (
    <div className='procurement-form'>
    <Loader loading={isLoading} />
    <div className='form-group'>
        <label>PO Number</label>
        <FieldInput id={po.id} field='PONumber' value={po.poNumber} canEdit={canEdit} update={_update} />
    </div>
    <div className='form-group'>
        <label>Request Date</label>
        <FieldInput id={po.id} dataType={'date'} field='requestDate' value={po.requestDate} canEdit={canEdit} update={_update} />
    </div>
    <div className='form-group'>
        <label>Release Date</label>
        <FieldInput id={po.id} dataType={'date'} field='releaseDate' value={po.releaseDate} canEdit={canEdit} update={_update} />
    </div>
    {canEdit &&
    <div className='form-group'>
        <label>Closed</label>
        <FieldInput id={po.id} dataType={'checkbox'} field='closed' value={po.closed} canEdit={canEdit} update={_update} />
    </div>
    }
    <div className='form-group'>
        <label>Status</label>
            <br />
            <label style={{fontWeight: 700}} id='po-status'>{purchaseOrderStatus(po.status)}</label>
    </div>
    <div className='form-group'>
        <label>Notes</label>
        <FieldTextArea id={po.id} field='description' value={po.description} canEdit={canEdit} update={_update} />
    </div>
    </div>
    )
}