import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';

import { MessageBox } from '../components/MessageBox';
import { Modal2 } from '../components/Modal';
import { formatDateTime } from '../helpers/formatters';
import { isImage } from '../helpers/misc';
import { ListItem, ReissueRequestFile, RFIReissueRequest, RFIReissueRequestStatus } from '../interfaces/interfaces';
import { ApplicationState } from '../store';
import { BackLink } from '../components/BackLink';
import { Link } from 'react-router-dom';

type Props =    
    RouteComponentProps<{ id: string }>;

enum ModalStatus {
    None,
    Approve,
    Reject
}

export const RFIReissueRequestDetail = (props: Props) => {
    const [request, setRequest] = React.useState({} as RFIReissueRequest);
    const [message, setMessage] = React.useState('')
    const [modalStatus, setModalStatus] = React.useState(ModalStatus.None)    
    const userStore = useSelector((state: ApplicationState) => state.user);

    React.useEffect(() => {
        const getRequest = async () => {
            const id = props.match.params.id;
            fetch(`api/rfi/reissuerequest?id=${id}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setRequest(data)
                    else setMessage(data.message);
                })
        };
        getRequest();
    }, [])

    const _save = () => {
        const comments = (document.getElementById('request-comments') as HTMLTextAreaElement).value;
        if (modalStatus === ModalStatus.Reject && comments.length < 20) {
            alert('To reject this request please provide a response of at least 20 characters');
        }
        else {
            let url = modalStatus === ModalStatus.Approve ? 'api/rfi/approvereissue' : 'api/rfi/rejectreissue';
            url += '?id=' + request.id + '&comment=' + comments;
            fetch(url, { method: 'PUT' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setRequest(data);
                        setModalStatus(ModalStatus.None)
                    }
                    else setMessage(data.message);
                })
        }
    }

    const RenderRequestBody = (request: RFIReissueRequest) => {
        return (
            <div className='rfi-reissue-request-detail'>
                <div className='form-group'>
                    <div className='inline-label'>Original RFI</div>
                    <Link to={`/rfi/${request.rfiId}`}>{request.rfiNumber} - {request.rfiSubject}</Link>
                </div>
                <div className='form-group'>
                    <div className='inline-label'>Reason for reissue</div>
                    <span>{request.reason}</span>
                </div>
                <div className='form-group'>
                    <div className='inline-label'>Requested By</div>
                    <span>{request.userFullName}</span>
                </div>
                <div className='form-group'>
                    <div className='inline-label'>Requested On</div>
                    <span>{formatDateTime(request.createdTimestamp)}</span>
                </div>
                <div className='form-group'>
                    <div className='inline-label'>File(s)</div>
                    <span>{request.files.map(x => RenderDocIcon(x))}</span>
                </div>
                {
                    (userStore.user !== undefined && request.reviewerId === userStore.user.userId && request.status === RFIReissueRequestStatus.Open) && 
                    <div className='form-group'>
                        <button className='btn btn-green btn-sm' onClick={() => setModalStatus(ModalStatus.Approve)}>Approve</button>
                        &nbsp;
                        <button className='btn btn-red btn-sm' onClick={() => setModalStatus(ModalStatus.Reject)}>Reject</button>
                    </div>
                }
                {
                    request.status === RFIReissueRequestStatus.Approved &&
                    <div className='form-group'>
                        <div className='inline-label'>Approved by</div>
                        <span>{request.reviewerName}</span>
                    </div>
                }
                {
                    request.status === RFIReissueRequestStatus.Rejected &&
                    <div className='form-group'>
                        <div className='inline-label'>Rejected by</div>
                        <span>{request.reviewerName}</span>
                    </div>
                }
                {
                    (request.status === RFIReissueRequestStatus.Approved || request.status === RFIReissueRequestStatus.Rejected) &&
                    <div className='form-group'>
                        <div className='inline-label'>Comments</div>
                        <span>{request.comments}</span>
                    </div>
                }
            </div>
        )
    }

    const RenderModal = () => {
        const header = modalStatus === ModalStatus.Approve ? 'Approve Request' : 'Reject Request',
            saveBtn = modalStatus === ModalStatus.Approve ?
                <button className='btn btn-sm btn-green' onClick={_save}>Approve</button> :
                <button className='btn btn-sm btn-red' onClick={_save}>Reject</button>,
            commentLabel = modalStatus === ModalStatus.Approve ? 'Comments (optional)' : 'Reason for rejection*';

        return (
            <Modal2>
                <div className='modal-header'><h5>{header}</h5></div>
                <div className='modal-body'>
                    <div className='form-group'>
                        <div className='inline-label'>{commentLabel}</div>
                        <textarea id='request-comments' className='form-control' rows={3} />
                    </div>
                </div>
                <div className='modal-footer'>
                    {saveBtn}                    
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => setModalStatus(ModalStatus.None)}>Close</button>
                </div>
            </Modal2>    
        )
    }

    return (
        <div className='reissue-request-detail'>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
            <BackLink link={`/project/${request.projectId}#rfi` } />
            <div className='pageHeader'>RFI Reissue Request</div>
            {
                request.id &&
                RenderRequestBody(request)
            }
            {
                modalStatus != ModalStatus.None && 
                <RenderModal />
            }
        </div>
    )
}

const RenderDocIcon = (doc: ReissueRequestFile) => {
    const split = doc.filePath.split('content');
    const relPath = '/files' + split[split.length - 1].replace(/#/g, '%23');
    const slashSplit = relPath.split('/'), fileName = slashSplit[slashSplit.length - 1]
    const src = isImage(fileName) ? '/img/photoIcon.jpg' : '/img/pdficon.png';
    return (
        <a key={doc.id} className='response-doc-icon' href={relPath} target="_blank" title={fileName}>
            <img style={{ height: '25px' }} src={src} /> <br />
            {/*fileName*/}
        </a>
    )
}
