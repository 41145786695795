import * as React from "react";
import { ProjectRole } from "../config/global/ProjectRoles";
import { ProjectTeamMember, ListItem } from "../interfaces/interfaces";
import { MessageBox } from "../components/MessageBox";
import { Loader } from "../components/Loader";
import * as UserStore from "../store/user";
import { connect, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store/index";
import { actionCreators as projectActionCreators } from "../store/projects";
import { hasRole, KnownRoles } from "../auth/auth";
import FilterableSelect from "../components/FilterableSelect";
import Modal from "../components/Modal";
import Projects from "./Projects";

interface MyProps {
	projectId: number;
	pmId: string;
}

type Props = MyProps;

interface State {
	loading: boolean;
	showAddMember: boolean;
	showCopyModal: boolean;
	message?: string;
}

export const ProjectTeamGrid = (props: Props) => {
	const [state, setState] = React.useState({
		loading: true,
		showAddMember: false,
		showCopyModal: false,
	} as State);

	const [team, setTeam] = React.useState([] as ProjectTeamMember[]);
	const [roles, setRoles] = React.useState([] as ProjectRole[]);
	const [users, setUsers] = React.useState([] as ListItem[]);

	const projectStore = useSelector((s: ApplicationState) => s.projects);
	const userStore = useSelector((s: ApplicationState) => s.user);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (projectStore.projects.length === 0) {
			dispatch(projectActionCreators.getProjects());
		}

		_getItems().then((_) => setState({ ...state, loading: false }));
	}, []);

	/* Handle the resolved values from the fetch promise */
	const _refreshTeam = ([resOk, data]) => {
		if (resOk) setTeam(data);
		else setState({ ...state, message: data.message });
		setState({ ...state, loading: false });
	};

	/* Make all API calls and wait for them to finish then turn off the loader */
	const _getItems = () => {
		return Promise.all([_getRoles(), _getTeam(), _getUsers()]);
	};

	const _getRoles = () => {
		return fetch(`api/GlobalConfig/ProjectRoles`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setRoles(data);
				else setState({ ...state, message: data.message });
			});
	};

	const _getTeam = () => {
		return fetch(`api/ProjectTeam/GetTeam?id=${props.projectId}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(_refreshTeam);
	};

	const _getUsers = () => {
		return fetch(`api/User/GetAll`)
			.then((res) => res.json())
			.then((data) => setUsers(data));
	};

	const _addUser = () => {
		const newUserId = (
				document.querySelector(
					'#newUserDD input[name="newUserDD"]'
				) as HTMLInputElement
			).value,
			newRoleId = (
				document.querySelector(
					"#newRoleDD select option:checked"
				) as HTMLOptionElement
			).value;

		if (newUserId == "")
			setState({ ...state, message: "Error: must select a user" });
		setState({ ...state, loading: true });

		fetch(
			`api/ProjectTeam/AddMember?id=${props.projectId}&userId=${newUserId}&roleId=${newRoleId}`,
			{ method: "POST" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(_refreshTeam)
			.then((_) => setState({ ...state, showAddMember: false }));
	};

	const _changeRole = (
		e: React.ChangeEvent<HTMLSelectElement>,
		userId: string
	) => {
		const roleId = (
			e.currentTarget.querySelector("option:checked") as HTMLOptionElement
		).value;
		fetch(
			`api/ProjectTeam/ChangeRole?id=${props.projectId}&userId=${userId}&roleId=${roleId}`,
			{ method: "PUT" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(_refreshTeam);
	};

	const _removeUser = (userId: string) => {
		fetch(
			`api/ProjectTeam/RemoveMember?id=${props.projectId}&userId=${userId}`,
			{ method: "DELETE" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(_refreshTeam);
	};

	const _copy = () => {
		const copyId = (
			document.querySelector(
				'#copy-project-team input[name="copy-project-team"]'
			) as HTMLInputElement
		).value;
		if (copyId) {
			fetch(
				`api/ProjectTeam/CopyTeam?id=${props.projectId}&sourceProjectId=${copyId}`,
				{ method: "PUT" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then((d) => {
					_refreshTeam(d);
					if (d[0]) {
						setState({
							...state,
							showCopyModal: false,
							message: "Team copied!",
						});
					}
				});
		}
	};

	const _toggleShowAddMember = () =>
		setState({ ...state, showAddMember: !state.showAddMember });
	const _toggleShowCopyModal = () =>
		setState({ ...state, showCopyModal: !state.showCopyModal });

	const _clearMessage = () => setState({ ...state, message: undefined });

	const renderHeader = (canEdit: boolean) => {
		return (
			<div className="team-grid-header my-col-20">
				<div className="my-col-7 mobile-full-width">
					<label>Team Member</label>
				</div>
				<div className="my-col-3 mobile-hide">
					<label>Company</label>
				</div>
				<div className="my-col-5 mobile-hide">
					<label>Role</label>
				</div>
				<div className="right-button">
					{canEdit && (
						<React.Fragment>
							<button
								className="fas fa-plus btn btn-sm btn-blue"
								onClick={_toggleShowAddMember}
							></button>
							<button
								className="fas fa-copy btn btn-sm btn-outline-secondary"
								onClick={_toggleShowCopyModal}
							></button>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	};

	const renderTeamLines = (team: ProjectTeamMember[], canEdit: boolean) => {
		const lines = team.map((x) => {
			return (
				<div key={x.userId} className="team-grid-line my-col-20">
					<div className="my-col-7 pad-top mobile-full-width">
						{x.userFullName}
					</div>
					<div className="my-col-3 pad-top mobile-hide">{x.company}</div>
					<div className="my-col-3 mobile-hide">
						{renderRoleDropdown(canEdit, x.userId, x.roleId.toString())}
					</div>
					<div className="right-button" style={{ top: "8px" }}>
						{canEdit && (
							<button
								className="fas fa-times btn btn-sm btn-outline-secondary"
								onClick={() => _removeUser(x.userId)}
							></button>
						)}
					</div>
				</div>
			);
		});

		return lines;
	};

	const renderRoleDropdown = (
		canEdit: boolean,
		userId?: string,
		defaultVal?: string
	) => {
		const r = roles.map((x) => (
			<option key={x.id} value={x.id}>
				{x.roleName}
			</option>
		));

		return (
			<select
				className="form-control"
				onChange={userId ? (e) => _changeRole(e, userId) : () => {}}
				defaultValue={defaultVal}
				disabled={!canEdit}
			>
				<option value="0"></option>
				{r}
			</select>
		);
	};

	const renderAddLine = () => {
		return (
			<div key="add" className="add-user-line my-col-20">
				<div className="my-col-7">
					<FilterableSelect id="newUserDD" items={users} />
				</div>
				<div className="my-col-3"></div>
				<div id="newRoleDD" className="my-col-3">
					{renderRoleDropdown(true, undefined, undefined)}
				</div>
				<div className="right-button" style={{ top: "8px" }}>
					<button
						className="fas fa-check btn btn-sm btn-blue"
						onClick={_addUser}
						title="Add user to team"
					></button>
				</div>
			</div>
		);
	};

	const renderCopyModal = () => {
		return (
			<Modal>
				<div className="modal-header">
					<h4>Copy Project Team</h4>
				</div>
				<div className="modal-body">
					<span>Project To Copy From</span>
					<div>
						<FilterableSelect
							id="copy-project-team"
							items={projectStore.projects.map(
								(p) => new ListItem(p.id, `${p.name} (${p.clientName})`)
							)}
						/>
						.
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-blue" onClick={_copy}>
						Copy
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_toggleShowCopyModal}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const { loading, message, showAddMember, showCopyModal } = state,
		{ user } = userStore,
		canEdit = user
			? hasRole(user, [
					KnownRoles.Admin,
					KnownRoles.CoreSuperUser,
					KnownRoles.CSGPM,
			  ]) || user.userId === props.pmId
			: false;

	const teamLines = team && team.length ? renderTeamLines(team, canEdit) : [];

	return (
		<div className="project-team-grid grid">
			{showCopyModal && renderCopyModal()}
			{renderHeader(canEdit)}
			<div
				className="grid-body custom-scrollbar"
				style={{ minHeight: "65vh", maxHeight: "72vh" }}
			>
				{showAddMember && renderAddLine()}
				{teamLines}
			</div>
			<Loader loading={loading} />
			<MessageBox message={message} clearMessage={_clearMessage} />
		</div>
	);
};

export default connect<UserStore.Store, any, MyProps>(
	//@ts-ignore
	(state: ApplicationState) => state.user,
	{}
)(ProjectTeamGrid);
