import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SafetyKpiCategory, SafetyKpiQuantityType } from 'src/interfaces/enums';
import {
  SafetyKpiTemplate,
  SafetyKpiTemplateEntry,
  UpdateObject,
} from 'src/interfaces/interfaces';

export interface SafetyKPIState {
  templates: SafetyKpiTemplate[];
  templateStatus: 'idle' | 'loading' | 'completed' | 'failed';
  fieldReportEntryUpdateId: number | undefined;
  newDuplicateTemplate: SafetyKpiTemplate | undefined;
}

export const initialState: SafetyKPIState = {
  templates: [],
  templateStatus: 'idle',
  fieldReportEntryUpdateId: undefined,
  newDuplicateTemplate: undefined,
};

const { actions: SafetyKPIActions, reducer: SafetyKPIReducer } = createSlice({
  name: 'safetyKPI',
  initialState,
  reducers: {
    getTemplates(state) {
      state.templateStatus = 'loading';
    },
    setTemplates(state, action: PayloadAction<SafetyKpiTemplate[]>) {
      state.templates = action.payload;
      state.templateStatus = 'completed';
    },
    replaceTemplate(state, action: PayloadAction<SafetyKpiTemplate>) {
      const templates = state.templates.slice();
      const idx = templates.findIndex((x) => x.id == action.payload.id);
      templates.splice(idx, 1, action.payload);
      state.templates = templates;
    },
    setStatus(
      state,
      action: PayloadAction<'idle' | 'loading' | 'completed' | 'failed'>
    ) {
      state.templateStatus = action.payload;
    },
    addTemplate() {},
    addTemplateEntry(_state, _action: PayloadAction<number>) {},
    updateTemplate(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) {},
    copyTemplate(_state, _action: PayloadAction<number>) {},
    updateTemplateEntry(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) {},
    updateFieldReportSafetyKPIEntry(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) {},
    updateFieldReportSafetyKPIEntryQuantity(
      state,
      action: PayloadAction<{
        id: number;
        info: UpdateObject;
        type: SafetyKpiQuantityType;
      }>
    ) {
      state.fieldReportEntryUpdateId = action.payload.id;
    },
    removeTemplateEntry(_state, _action: PayloadAction<number>) {},
    removeFieldReportEntryUpdateId(state) {
      state.fieldReportEntryUpdateId = undefined;
    },
    setNewDuplicateTemplate(
      state,
      action: PayloadAction<SafetyKpiTemplate | undefined>
    ) {
      state.newDuplicateTemplate = action.payload;
    },
  },
});

export { SafetyKPIActions, SafetyKPIReducer };

export default SafetyKPIReducer;
