import { actionCreators as GraphDataActions } from "../graphDataStore";
import { AppStartListening } from "../listenerMiddleware";

const listenerDelay = 500;
export const addGraphDataListeners = (startListening: AppStartListening) => {
	const listeners = [
		startListening({
			actionCreator: GraphDataActions.getProjects.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);
				if (
					!listenApi.getState().graphData.projects ||
					!listenApi.getState().graphData.projects.length
				) {
					fetch(`api/Project/Get?getComplete=${action.meta.arg}
                    `)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk)
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_PROJECTS",
									projects: data,
								});
							else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({ type: "REQUEST_GRAPH_DATA" });
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getVendors.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);
				if (listenApi.getState().graphData.vendors.length === 0) {
					fetch(`api/Vendor/Get`)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_VENDORS",
									vendors: data,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({ type: "REQUEST_GRAPH_DATA" });
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getMilestones.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.milestones.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(
						`api/Milestone/AllEntries?projectId=${action.meta.arg.projectId}`
					)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_MILESTONES",
									milestones: data,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getFieldReports.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.fieldReports.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(
						`api/DailyFieldReport/GetAll?projectId=${action.meta.arg.projectId}`
					)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_FIELD_REPORTS",
									fieldReports: data.dfrs,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getRfis.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.rfis.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(`api/RFI/Get?projectId=${action.meta.arg.projectId}`)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_RFIS",
									rfis: data.rfis,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getSubmittals.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.submittals.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(`api/Submittal/All?projectId=${action.meta.arg.projectId}`)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_SUBMITTALS",
									submittals: data,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getChecklists.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.checklists.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(
						`api/UserChecklist/GetAll?projectId=${action.meta.arg.projectId}`
					)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_CHECKLISTS",
									checklists: data,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
		startListening({
			actionCreator: GraphDataActions.getChecklistEntries.pending,
			effect: async (action, listenApi) => {
				// Cancel any in-progress instances of this listener
				listenApi.cancelActiveListeners();

				// Delay before starting actual work
				await listenApi.delay(listenerDelay);

				if (
					listenApi.getState().graphData.checklistEntries.length === 0 ||
					listenApi.getState().graphData.serverFilters !== action.meta.arg
				) {
					fetch(
						`api/UserChecklist/GetAllEntries?projectId=${action.meta.arg.projectId}`
					)
						.then((res) => Promise.all([res.ok, res.json()]))
						.then(([resOk, data]) => {
							if (resOk) {
								listenApi.dispatch({
									type: "RECEIVE_GRAPH_CHECKLIST_ENTRIES",
									checklistEntries: data,
								});
							} else
								listenApi.dispatch({
									type: "GRAPH_DATA_ERROR",
									message: data.message,
								});
						});
					listenApi.dispatch({
						type: "REQUEST_GRAPH_DATA",
						serverFilters: action.meta.arg,
					});
				}
			},
		}),
	];
	return () => {
		listeners.forEach((unsubscribe) => unsubscribe());
	};
};
