import * as React from 'react'
import { isImage } from '../helpers/misc';

interface Props {
    previewPath: string | undefined,
    //hoverX: number | undefined,
    //hoverY: number | undefined,
    style?: any
}

export default class HoverImagePreview extends React.Component<Props, any> {

    render() {
        const { previewPath,/* hoverX, hoverY,*/ style } = this.props;
        if (previewPath === undefined) return <div></div>
        if (!isImage(previewPath)) return <div></div>

        const myStyle = style ? style : {}
        //{
        //    left: hoverX ? `${(hoverX+30).toString()}px` : 'initial',
        //    top: hoverY ? `${(hoverY).toString()}px` : 'initial'
        //}
        return (
            <div className='hover-image-preview' style={myStyle}>
                <img src={previewPath}></img>
            </div>
        )
    }
}