import React from 'react';
import { PunchlistTemplateItem, UpdateObject } from 'src/interfaces/interfaces';
import { StandardGridLoader } from 'src/loaders/StandardGridLoader';
import styled from 'styled-components';

interface PunchlistTemplateItemProps {
  item: PunchlistTemplateItem;
  onItemUpdate: (id: number, info: UpdateObject) => void;
  onItemRemove: (id: number) => void;
  isDisabled: boolean;
}

const StyledDiv = styled.div({
  display: 'flex',
  width: '100%',
  '& .inline-label': {
    lineHeight: 2,
    width: '150px',
  },
  '& input': {
    maxWidth: 'calc(100% - 180px)',
  },
  '& label': {
    alignSelf: 'center',
    marginRight: '16px',
  },
});

const ContainerDiv = styled.div({
  border: '1px solid lightgray',
  borderRadius: '2px',
  padding: '10px',
  marginBottom: '15px',
  '& .form-group:last-of-type': {
    marginBottom: 0,
  },
});

export const PunchlistTemplateItemRow = ({
  item,
  onItemUpdate,
  onItemRemove,
  isDisabled,
}: PunchlistTemplateItemProps) => {
  return (
    <ContainerDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Title</div>
        <input
          required
          className="form-control"
          onBlur={(e) =>
            onItemUpdate(item.id, {
              fieldName: 'title',
              value: e.currentTarget.value,
            })
          }
          defaultValue={item.title}
          disabled={isDisabled}
        />
        {!isDisabled && (
          <span
            style={{ borderRadius: '50%' }}
            className="btn btn-sm fas fa-trash-alt btn-background-hover"
            title="Delete this entry"
            onClick={() => onItemRemove(item.id)}
          ></span>
        )}
      </StyledDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Category</div>
        <input
          required
          className="form-control"
          onBlur={(e) =>
            onItemUpdate(item.id, {
              fieldName: 'category',
              value: e.currentTarget.value,
            })
          }
          defaultValue={item.category}
          disabled={isDisabled}
        />
      </StyledDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Description</div>
        <input
          required
          className="form-control"
          onBlur={(e) =>
            onItemUpdate(item.id, {
              fieldName: 'description',
              value: e.currentTarget.value,
            })
          }
          defaultValue={item.description}
          disabled={isDisabled}
        />
      </StyledDiv>
    </ContainerDiv>
  );
};
