import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPunchItems } from '../services/punchlist';
import { ApplicationState } from '../store';
import {
  PunchlistActions,
  punchItemsSelector,
} from '../store/punchlist';
import { PunchlistFocusSection } from './PunchItemFocusSection';
import { PunchlistSummary } from './PunchlistSummary';
import * as cx from 'classnames';
import { PunchItemAddModal } from './PunchItemAddModal';
import { GlobalMessageBox } from 'src/components/MessageBox';
import Modal from '../components/Modal';
import { FilterOption, PunchItemVm } from '../interfaces/interfaces';
import { FileDownloader } from '../components/FileDownloader';
import { distinct } from '../helpers/formulas';

export const PunchlistDownloadModal = (props: { close: () => void }) => {

  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const items = useSelector(punchItemsSelector);

  const [filters, setFilters] = React.useState([] as FilterOption[])
  const [checkedIds, setCheckedIds] = React.useState([] as number[]);
  const [filteredItems, setFilteredItems] = React.useState([] as PunchItemVm[]);

  const filterFields = [{ field: 'statusName', label: 'Status' }, { field: 'ballInCourtName', label: 'Ball-in-Court' }, { field: 'assigneeName', label: 'Assignee' }, { field: 'category', label: 'Category' }];

  const filterOptions = filterFields.map(f => {
    const values = items.map(i => i[f.field]).filter(distinct);
    return { field: f.field, label: f.label, values: values };
  })

  React.useEffect(() => {
    setCheckedIds(filteredItems.map(x => x.id));
  }, [filteredItems])

  React.useEffect(() => {
    let newFiltered = JSON.parse(JSON.stringify(items));
    filters.forEach((f) => {
      newFiltered = newFiltered.filterByStringProp(f.prop, f.value.toString());
    })

    setFilteredItems(newFiltered);
  }, [filters, items])

  const _toggleCheck = (id: number) => {
    if (checkedIds.indexOf(id) !== -1) {
      setCheckedIds(checkedIds.filter(x => x !== id))
    } else {
      setCheckedIds([...checkedIds, id])
    }
  }

  const _clearFilters = () => {
    setCheckedIds(items.map(x => x.id));
    setFilters([] as FilterOption[]);
  }

  const _updateFilter = (field: string, value: string) => {
    let filterExists = false;
    let newFilters = JSON.parse(JSON.stringify(filters)).map(
      (x: FilterOption) => {
        if (x.prop === field) {
          filterExists = true;
          x.value = value;
        }
        return x;
      }
    );

    if (!filterExists) newFilters.push({ prop: field, value: value } as FilterOption);

    newFilters = newFilters.filter((x) => x !== undefined);

    setFilters(newFilters);
  }

  return <Modal>
    <div className='modal-header'><h4>Generate Download</h4></div>
    <div className='modal-body'>
      <div style={{ fontSize: '14px', fontWeight: 500 }}>Use the form below to select the items you want to download. All selected items will be included in a single PDF download.</div>
      <hr />
      <div>
        <button className='btn btn-x-sm btn-outline-secondary' onClick={_clearFilters}>Clear Filters</button>
        <div style={{ display: 'flex', padding: '10px 0px' }}>
          {filterOptions.map(x => {
            return <div style={{ marginRight: '10px', width: `${100 / filterOptions.length}%` }}>
              <b>{x.label}</b>
              <select className='form-control' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => _updateFilter(x.field, e.currentTarget.value)} style={{width: '100%'}}>
                <option className='form-control' value=''></option>
                {x.values.map(y => <option className='form-control' value={y}>{y}</option>)}
              </select>
            </div>
          })}
        </div>
        <div><FileDownloader fileType='pdf' actionPath='api/punchlist/pdf' queryParams={{ ids: checkedIds }} /></div>
      </div>
      <hr />
      <div>
        <div className='grid sv-grid'>
          <div className='col-sm-12 grid-header sv-grid-header'>
            <div className='my-col-1'></div>
            <div className='my-col-4'><b>Title</b></div>
            {filterOptions.map(x => <div className='my-col-3'><b>{x.label}</b></div>)}
          </div>
          <div className='grid-body sv-grid-body custom-scrollbar' style={{ overflowX: 'hidden', maxHeight: '300px' }}>
            {filteredItems.map(i => {
              return (
                <div className='sv-grid-line'>
                  <div className='my-col-1'><input type='checkbox' checked={checkedIds.indexOf(i.id) !== -1} onClick={(e) => _toggleCheck(i.id)} /></div>
                  <div className='my-col-4'>{i.title}</div>
                  {filterOptions.map(x => <div className='my-col-3'>{i[x.field]}</div>)}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
    </div>
  </Modal>

}