import * as React from 'react';
import { RouteComponentProps, Link, NavLink } from 'react-router-dom';
import { ApplicationState } from '../store';
import { connect, useDispatch } from 'react-redux';
import { MessageBox } from '../components/MessageBox';
import * as Dfr from '../store/dfr';
import * as DetailStore from '../store/projectDetail';
import { Loader } from '../components/Loader';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles, hasRole } from '../auth/auth';
import DfrSearcher from './DfrSearcher';
import * as cx from 'classnames';
import { FileDownloader } from '../components/FileDownloader';
import Modal from '../components/Modal';
import { ListItem, UserProjectNotifications } from '../interfaces/interfaces';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { ConnectedDfrPhotoPaginationModal } from './ConnectedDfrPhotoPaginationModal';
import PointedPopup from 'src/components/PointedPopup';
import { DfrListMenu } from './DfrListMenu';
import FilterableSelect from 'src/components/FilterableSelect';
import { getActiveUsers } from 'src/services/users';

interface State {
  showAddModal: boolean;
  CWU_count: number;
  showSearcher: boolean;
  showReportModal: boolean;
  showLaborReportModal: boolean;
  showDFRPhotoModal: boolean;
  userNotifications: UserProjectNotifications;
  selectedTemplate: number | undefined;
  assignee: string | undefined;
}

type Props = { dfr: Dfr.Store } & {
  projectId: number;
  projectTeamMembers: ListItem[];
} & typeof Dfr.actionCreators &
  RouteComponentProps<any>;

class DfrList extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      showAddModal: false,
      CWU_count: 0,
      showSearcher: false,
      showReportModal: false,
      showLaborReportModal: false,
      showDFRPhotoModal: false,
      userNotifications: {} as UserProjectNotifications,
      selectedTemplate: undefined,
      assignee: undefined,
    };
  }

  componentDidMount() {
    //const split = window.location.href.split("/");
    //const pId = parseInt(split[split.length - 1]) || 0;

    this.props.getAllProjectDfrs(this.props.projectId);
    this._getUserNotifications(this.props.projectId);
    this.props.getTemplates();
    //else {
    //  const split = window.location.href.split("/");
    //  pId = parseInt(split[split.length - 1]) || 0;
    //}
  }

  componentWillUnmount() {
    this.props.clearMessage();
  }

  _getUserNotifications = (id: number) => {
    fetch(`api/ProjectNotification/Get?id=${id}`)
      .then((res) => res.json())
      .then((data) => this.setState({ userNotifications: data }));
  };

  _toggleSubmittedNotification = () => {
    fetch(
      `api/ProjectNotification/DfrSubmitted?id=${this.props.dfr.projectId}`,
      { method: 'PUT' }
    )
      .then((res) => res.json())
      .then((data) => this.setState({ userNotifications: data }));
  };

  _toggleApprovedNotification = () => {
    fetch(
      `api/ProjectNotification/DfrApproved?id=${this.props.dfr.projectId}`,
      { method: 'PUT' }
    )
      .then((res) => res.json())
      .then((data) => this.setState({ userNotifications: data }));
  };

  toggleAddModal = () =>
    this.setState({ showAddModal: !this.state.showAddModal });

  toggleDfrPhotoModal = () =>
    this.setState({ showDFRPhotoModal: !this.state.showDFRPhotoModal });

  addDfr = (projId: number) => {
    const dateInput = document.getElementById(
      'new-dfr-date'
    ) as HTMLInputElement;
    const dueDateInput = document.getElementById(
      'new-dfr-due-date'
    ) as HTMLInputElement;
    const reportName = document.getElementById(
      'new-report-name'
    ) as HTMLInputElement;
    const externalChkbx = document.getElementById(
      'is-external-report'
    ) as HTMLInputElement;
    if (dateInput && reportName && externalChkbx) {
      if (!dateInput.value || !dateInput.value.length) {
        alert('You must select a date');
      } else if (reportName.value.indexOf('/') !== -1) {
        alert(
          'Please do not include any forward (/) or backward (\\) slashes in the report name'
        );
      } else {
        this.props.addDfr(
          projId,
          dateInput.value,
          reportName.value,
          externalChkbx.checked,
          this.state.selectedTemplate,
          this.state.assignee,
          dueDateInput?.value
        );
      }
    }
  };

  _toggleSearcher = () =>
    this.setState({ showSearcher: !this.state.showSearcher });

  _toggleReportModal = () =>
    this.setState({ showReportModal: !this.state.showReportModal });

  _toggleLaborReportModal = () =>
    this.setState({ showLaborReportModal: !this.state.showLaborReportModal });

  render() {
    const { userNotifications } = this.state;
    const ReporterComponent = MyAuthElement(
        [KnownRoles.SiteSuper, KnownRoles.Admin],
        [this.props.dfr.pmId]
      ),
      CsgComponent = MyAuthElement([
        KnownRoles.SiteSuper,
        KnownRoles.Admin,
        KnownRoles.CoreSuperUser,
        KnownRoles.CSGPM,
      ]);

    const dfrs = this.props.dfr.dfrs.map((x, i) => {
      let commentDisplay = x.formalDescription
        ? x.formalDescription.substr(0, 60)
        : '';
      if (x.formalDescription && x.formalDescription.length > 60)
        commentDisplay += '...';
      let statusClass = 'left-tab-yellow',
        statusText = 'Draft';
      if (x.isLocked) {
        statusClass = 'left-tab-green';
        statusText = 'Locked';
      } else if (x.isSubmitted) {
        statusClass = 'left-tab-blue';
        statusText = 'Submitted';
      }
      return (
        <div key={i} className={cx('dfr_link', 'col-sm-12', statusClass)}>
          <NavLink to={`/dfr/${x.id + ''}`} className="row">
            {/* @ts-ignore */}
            <div className="col-sm-2 min-85-pct">
              {x.dailyFieldReportTemplateId && (
                <span
                  id="dfr_report"
                  title="Quality Inspection Report"
                  className="fas fa-award"
                  style={{ marginRight: '8px' }}
                ></span>
              )}
              {x.date.toString()}
              {x.reportName && x.reportName.length ? ` - ${x.reportName}` : ''}
            </div>
            <div className="col-sm-4 mobile-hide">
              {x.formalDescription == null ? (
                <span className="fas fa-minus"></span>
              ) : (
                commentDisplay
              )}
            </div>
            <div className="col-sm-3 mobile-hide">
              {x.summary != null ? (
                x.summary
              ) : (
                <span className="fas fa-minus"></span>
              )}
            </div>
            <div className="col-sm-1 mobile-hide">
              {x.high != null ? (
                <span>{x.high.toFixed(0)} &#x2109; </span>
              ) : (
                <span className="fas fa-minus"></span>
              )}
            </div>
            <div className="col-sm-1 mobile-hide">
              {x.low != null ? (
                <span>{x.low.toFixed(0)} &#x2109; </span>
              ) : (
                <span className="fas fa-minus"></span>
              )}
            </div>
            {x.isLocked ? (
              <div className="col-sm-1 mobile-float">
                <span
                  title="Approved and locked"
                  className="fas fa-lock"
                ></span>
              </div>
            ) : x.isSubmitted ? (
              <div className="col-sm-1 mobile-float">
                <span
                  id="dfr_inbox"
                  title="Submitted awaiting approval"
                  className="fas fa-inbox"
                ></span>
              </div>
            ) : (
              []
            )}
          </NavLink>
        </div>
      );
    });

    return (
      <div>
        <Loader loading={this.props.dfr.isSaving} />
        <MessageBox
          message={this.props.dfr.message}
          clearMessage={this.props.clearMessage}
        />
        {/* 
                  //@ts-ignore */}
        <ReporterComponent class="mobile-show">
          <div style={{ position: 'relative', padding: '0%', width: '98%' }}>
            <span
              id="dfr-addBtn"
              className="btn btn-sm dfr_AddDFR_btn btn-blue"
              onClick={this.toggleDfrPhotoModal}
            >
              +
            </span>
          </div>
        </ReporterComponent>
        <div>
          <div className="col-sm-12 dfr_Row_header row mobile-hide">
            <div className="col-sm-2">
              <label>DFR Date</label>
            </div>
            <div className="col-sm-4">
              <label>Comments</label>
            </div>
            <div className="col-sm-3">
              <label>Weather Summary</label>
            </div>
            <div className="col-sm-1">
              <label>High</label>
            </div>
            <div className="col-sm-1">
              <label>Low</label>
            </div>

            <div className="col-sm-1" style={{ display: 'flex', top: '-5px' }}>
              <ReporterComponent class="mobile-hide">
                <span
                  id="dfr-addBtn"
                  className="btn btn-sm btn-blue fas fa-plus"
                  onClick={this.toggleAddModal}
                ></span>
              </ReporterComponent>
              <DfrListMenu
                approvedNotification={
                  userNotifications.fieldReportApprovedNotification
                }
                onApproveNotification={this._toggleApprovedNotification}
                submittedNotification={
                  userNotifications.fieldReportSubmittedNotification
                }
                onSubmittedNotification={this._toggleSubmittedNotification}
                onLaborReportDownload={this._toggleLaborReportModal}
                onReportDownload={this._toggleReportModal}
                onReportSearch={this._toggleSearcher}
                onPhotoAlbumView={this.toggleDfrPhotoModal}
              />
            </div>
          </div>
          <div
            style={{ overflowY: 'auto', maxHeight: '70vh' }}
            className="custom-scrollbar"
          >
            {this.props.dfr.isLoading ||
            this.props.dfr.projectId !== this.props.projectId ? (
              <StandardGridLoader
                rowPadding={12}
                rowCount={14}
                rowContentHeight={20}
              />
            ) : (
              dfrs
            )}
          </div>
        </div>

        <br />
        {this.state.showSearcher && (
          <DfrSearcher
            projectId={this.props.dfr.projectId}
            close={this._toggleSearcher}
          />
        )}
        {this.state.showAddModal && this.datePickerModal()}
        {this.state.showDFRPhotoModal && (
          <ConnectedDfrPhotoPaginationModal
            onClose={this.toggleDfrPhotoModal}
            projectId={this.props.dfr.projectId}
            pageSize={20}
          ></ConnectedDfrPhotoPaginationModal>
        )}
        {this.state.showReportModal && (
          <DateRangeReportModal
            close={this._toggleReportModal}
            projectId={this.props.projectId}
          />
        )}
        {this.state.showLaborReportModal && (
          <LaborReportModal
            close={this._toggleLaborReportModal}
            projectId={this.props.projectId}
          />
        )}
      </div>
    );
  }

  private datePickerModal = () => {
    const styles = {
      minHeight: '7vh',
      maxHeight: '80vh',
    };

    const pad = (a, c) => (a < 9 ? '0' + (a + c) : a + c);
    const cd = new Date();
    const fd = `${cd.getUTCFullYear()}-${pad(cd.getUTCMonth(), 1)}-${pad(
        cd.getUTCDate(),
        0
      )}`,
      minDate = `${cd.getUTCFullYear() - 1}-${pad(cd.getUTCMonth(), 1)}-${pad(
        cd.getUTCDate(),
        0
      )}`;

    const templateList = this.props.dfr.templates.map(
      (x) => new ListItem(x.id, x.name)
    );

    return (
      <Modal dialogStyle={{ maxWidth: '500px' }}>
        <div className="modal-header">
          <h4>Create New Daily Field Report</h4>
        </div>
        <div className="modal-body" style={styles}>
          <label>Please select a date</label>
          <br />
          <input
            id="new-dfr-date"
            type="date"
            className="form-control"
            max={fd}
            min={minDate}
          />
          <br />
          <label>Report Name / Description (optional)</label>
          <input className="form-control" id="new-report-name" />
          <br />
          <label>Is External</label> <br />
          <label className="switch">
            <input
              type="checkbox"
              id="is-external-report"
              defaultChecked={false}
            />
            <span className="slider round"></span>
          </label>
          <br />
          <br />
          <label>Quality Inspection Report Template (optional)</label>
          <FilterableSelect
            onChange={(x) =>
              this.setState({
                selectedTemplate:
                  x == '' ? undefined : isNaN(+x) ? undefined : +x,
              })
            }
            allowEmpty={true}
            openField={true}
            id={'fs-template'}
            items={templateList}
          ></FilterableSelect>
          {this.state.selectedTemplate && this.props.projectTeamMembers && (
            <>
              <br />
              <label>Assignee (optional)</label>
              <FilterableSelect
                onChange={(x) =>
                  this.setState({
                    assignee: x == '' ? undefined : x,
                  })
                }
                allowEmpty={true}
                openField={true}
                id={'fs-users'}
                items={this.props.projectTeamMembers}
              ></FilterableSelect>
              <br />
              <label>Due Date (optional)</label>
              <br />
              <input
                id="new-dfr-due-date"
                type="date"
                className="form-control"
                min={minDate}
              />
            </>
          )}
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-sm btn-blue"
            onClick={() => this.addDfr(this.props.projectId)}
          >
            Add
          </button>
          <button
            style={{ marginLeft: '1%' }}
            className="btn btn-sm btn-outline-secondary"
            onClick={this.toggleAddModal}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  };
}

// @ts-ignore
export default connect((state: ApplicationState) => {
  return { dfr: state.dfr };
}, Dfr.actionCreators)(DfrList) as typeof DfrList;

export const DateRangeReportModal = (props: {
  projectId: number;
  close: () => void;
}) => {
  const { close, projectId } = props;

  const [form, setForm] = React.useState({
    startDate: '',
    endDate: '',
    saveReport: false,
    includePhotos: true,
  });
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    if (
      form.startDate &&
      form.endDate &&
      new Date(form.startDate) <= new Date(form.endDate)
    ) {
      if (!valid) setValid(true);
    } else {
      if (valid) setValid(false);
    }
  }, [form.startDate, form.endDate]);

  return (
    <Modal>
      <div className="modal-header">
        <h4>Download Field Reports</h4>
      </div>
      <div className="modal-body">
        <p>
          <small>Select a date range to pull field reports for</small>
        </p>
        <div style={{ display: 'flex' }}>
          <div
            className="form-group"
            style={{ width: '50%', paddingRight: '20px' }}
          >
            <label>Start Date*</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) =>
                setForm({ ...form, startDate: e.currentTarget.value })
              }
            />
          </div>
          <div className="form-group" style={{ width: '50%' }}>
            <label>End Date*</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) =>
                setForm({ ...form, endDate: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <p style={{ marginBottom: '5px' }}>
          <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={form.includePhotos}
            onChange={(e) =>
              setForm({ ...form, includePhotos: e.currentTarget.checked })
            }
          />
          Include Photos? &nbsp; &nbsp;
          <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={form.saveReport}
            onChange={(e) =>
              setForm({ ...form, saveReport: e.currentTarget.checked })
            }
          />
          Save report?
        </p>
        <p>
          <small>
            This will save the report in the project's Field Reports folder
          </small>
        </p>
      </div>
      <div className="modal-footer">
        {valid ? (
          <FileDownloader
            actionPath="api/Report/DFRRangePdf"
            queryParams={{
              projectId: props.projectId,
              start: form.startDate,
              end: form.endDate,
              includePhotos: form.includePhotos,
              saveReport: form.saveReport,
            }}
            fileType="pdf"
          />
        ) : (
          []
        )}
        <button className="btn btn-sm btn-outline-secondary" onClick={close}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export const LaborReportModal = (props: {
  projectId: number;
  close: () => void;
}) => {
  const { close, projectId } = props;

  const [form, setForm] = React.useState({
    startDate: '',
    endDate: '',
    saveReport: false,
    fileType: '0',
    key: '0',
  });
  const [valid, setValid] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (
      form.startDate &&
      form.endDate &&
      new Date(form.startDate) <= new Date(form.endDate)
    ) {
      if (!valid) setValid(true);
    } else {
      if (valid) setValid(false);
    }
  }, [form.startDate, form.endDate]);

  return (
    <Modal>
      <div className="modal-header">
        <h4>Download Labor Report</h4>
      </div>
      <div className="modal-body">
        <p>
          <small>Select a date range to pull labor report for</small>
        </p>
        <div style={{ display: 'flex' }}>
          <div
            className="form-group"
            style={{ width: '50%', paddingRight: '20px' }}
          >
            <label>Start Date*</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) =>
                setForm({ ...form, startDate: e.currentTarget.value })
              }
            />
          </div>
          <div className="form-group" style={{ width: '50%' }}>
            <label>End Date*</label>
            <input
              type="date"
              className="form-control"
              onChange={(e) =>
                setForm({ ...form, endDate: e.currentTarget.value })
              }
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            className="form-group"
            style={{ width: '50%', paddingRight: '20px' }}
          >
            <label>Report File Type</label>
            <select
              className="form-control"
              onChange={(e) =>
                setForm({ ...form, fileType: e.currentTarget.value })
              }
            >
              <option className="form-control" value="0">
                PDF
              </option>
              <option className="form-control" value="1">
                Excel
              </option>
            </select>
          </div>
          <div className="form-group" style={{ width: '50%' }}>
            <label>Key Column Grouping</label>
            <select
              className="form-control"
              value={form.key}
              onChange={(e) => setForm({ ...form, key: e.currentTarget.value })}
            >
              <option className="form-control" value="0">
                Trade/Class
              </option>
              <option className="form-control" value="1">
                Company
              </option>
            </select>
          </div>
        </div>
        <p style={{ marginBottom: '0px' }}>
          <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={form.saveReport}
            onChange={(e) =>
              setForm({ ...form, saveReport: e.currentTarget.checked })
            }
          />
          Save report?
        </p>
        <p>
          <small>
            This will save the report in the project's Field Reports folder
          </small>
        </p>
      </div>
      <div className="modal-footer">
        {valid ? (
          <FileDownloader
            actionPath="api/Report/DFRLaborReport"
            queryParams={{
              projectId: projectId,
              start: form.startDate,
              end: form.endDate,
              fileType: form.fileType,
              saveReport: form.saveReport,
              grouping: form.key,
            }}
            fileType="pdf"
          />
        ) : (
          []
        )}
        <button className="btn btn-sm btn-outline-secondary" onClick={close}>
          Close
        </button>
      </div>
    </Modal>
  );
};
