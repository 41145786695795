import { AxiosError, AxiosResponse } from 'axios';
import {
  IssueConfig,
  IssuePostModel,
  IssueVm,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { api } from '../api';

export const getAll = async (projectId: number) => {
  try {
    const response: AxiosResponse<IssueVm[]> = await api.get(
      `/api/Issue/Project?id=${projectId}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};

export const getConfig = async () => {
  try {
    const response: AxiosResponse<IssueConfig> = await api.get(
      `/api/Issue/Config`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};

export const createIssue = async (issue: IssuePostModel) => {
  try {
    const form = new FormData();
    const files = issue.fileList;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; ++i) //form.append(`doc${i}`, files[i], files[i].name)
        form.append('files', files[i], files[i].name)
    }    
    form.append("json", JSON.stringify(issue))

    const response: AxiosResponse<IssueVm> = await api.post(
      `/api/Issue/Add`,
      form
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};

export const addCCUser = async (
  id: number,
  userIds?: string,
  emails?: string
) => {
  try {
    const response: AxiosResponse<IssueVm> = await api.post(
      `api/Issue/AddCC?id=${id}${userIds ? '&userIds=' + userIds : ''}${emails ? '&emails=' + emails : ''
      }`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};

export const updateIssue = async (id: number, updateObject: UpdateObject) => {
  try {
    const response: AxiosResponse<IssueVm> = await api.put(
      `/api/Issue/Update?id=${id}`,
      updateObject
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};

export const addFiles = async (id: number, files: FileList) => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].name.replace(/#/g, ''));
    }
    const response: AxiosResponse<IssueVm> = await api.post(
      `api/issue/addfiles?id=${id}`,
      formData
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }
  }
}

export const removeFile = async (id: number) => {
  try {
    const response: AxiosResponse<any> = await api.delete(
      `api/issue/removefile?id=${id}`
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }
  }
}

export const removeCCUsers = async (id: number, userIds: string) => {
  try {
    const response: AxiosResponse<IssueVm> = await api.delete(
      `api/Issue/RemoveCC?id=${id}&userIds=${userIds}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      if (typeof error.response.data == 'string') {
        return { message: error.response.data };
      } else {
        return error.response.data as { message: string };
      }
    }

    console.log(error);
  }
};
