import * as React from "react";
import * as cx from "classnames";

import { CSharpTypeCode, MetaObjectType } from "../interfaces/enums";
import { FilterOption, SortInfo } from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { SVDateRangePicker } from "./SVDateRangePicker";
import { DateRange } from "moment-range";
import { TableDataDownloadLink } from "./TableDataDownloadLink";
import { camelize } from "../helpers/formatters";
import { getData } from "ajv/dist/compile/validate";

export interface MetaFieldInfo {
  propName: string;
  displayName: string;
  propType: CSharpTypeCode;
  jsType: string;
  columnWidth?: number;
}

interface Props {
  type?: MetaObjectType;
  columns: MetaFieldInfo[];
  items: any[];
  mapCallback: (value: any, index: number, array: any[]) => JSX.Element;
  className?: string;
  // Used if the parent will handle the filtering, negates any filtering occurring in this element
  filterCallback?: (f: FilterOption[]) => void;
  loading?: boolean;
  addCallback?: () => void;
  addComponent?: JSX.Element;
  evenColumnSpacing?: boolean;
  hideLoadingWhenEmpty?: boolean;
  redlineSpacing?: boolean;
  hideFiltering?: boolean;
}

interface CustomizableGridApi {
  getSortInfo: () => SortInfo;
  getFilters: () => FilterOption[];
  clearFilters: () => void;
  updateFilter: (f: FilterOption) => void;
  updateSortInfo: (i: SortInfo) => void;
}

const CustomizableGridContext = React.createContext({} as CustomizableGridApi);

export const CustomizableGrid = (props: Props) => {
  const [allFields, setAllFields] = React.useState([] as MetaFieldInfo[]);
  const [showFilters, setShowFilters] = React.useState(false);
  const [filters, setFilters] = React.useState([] as FilterOption[]);
  const [sortInfo, setSortInfo] = React.useState({
    sortProp: "",
    sortAsc: false,
  } as SortInfo);

  const getFilters = () => filters;
  const getSortInfo = () => sortInfo;
  const updateSortInfo = (sortInfo: SortInfo) => setSortInfo(sortInfo);

  const _clearFilters = () => {
    setFilters([]);
    if (props.filterCallback) {
      props.filterCallback([]);
    }

    const filterInputs = document.querySelectorAll(
      ".grid-header.sv-grid-header input"
    );
    [].forEach.call(filterInputs, (i: HTMLInputElement) => {
      i.value = "";
    });
  };
  const colWidth = props.evenColumnSpacing
    ? "col"
    : `my-col-${Math.floor(20 / props.columns.length)}`;

  const _updateFilter = (f: FilterOption) => {
    let filterExists = false;
    let newFilters = JSON.parse(JSON.stringify(filters)).map(
      (x: FilterOption) => {
        if (x.prop === f.prop) {
          filterExists = true;
          x = f;
        }
        return x;
      }
    );

    if (!filterExists) newFilters.push(f);

    newFilters = newFilters.filter((x) => x !== undefined);

    if (props.filterCallback) {
      props.filterCallback(newFilters);
    }

    setFilters(newFilters);
  };

  const _getSortedFilteredItems = () => {
    let result = JSON.parse(JSON.stringify(props.items));

    if (!props.filterCallback) {
      filters.forEach((f) => {
        switch (f.dataType) {
          case "number":
            result = result.filter((x) => x[f.prop] >= f.value);
            break;
          case "boolean":
            result = result.filter((x) => x[f.prop] === Boolean(f.value));
            break;
          case "nested":
            result = result.filterByNestedProp(f.prop, f.value.toString());
            break;
          case "date":
            result = result.filter((x) => {
              if (f.value === "") return true;
              if (!x[f.prop]) return false;

              const dateValue = new Date(x[f.prop]);
              //@ts-ignore
              return dateValue >= (f.value).start.toDate() && dateValue <= (f.value).end.toDate();
            })
            break;
          //case "array":
          //  result = result.filterBy
          //  break;
          case "string":
          default:
            result = result.filterByStringProp(f.prop, f.value.toString());
            break;
        }
      });

      result = result.filter((x) => x !== undefined);
    }

    if (sortInfo.sortProp.length) {
      //@ts-ignore
      const sortDataType = props.columns.find(
        (x) => x.propName === sortInfo.sortProp
      ).jsType;
      result = result.sort((a, b) => {
        const aVal =
          sortDataType === "nested"
            ? getNestedPropVal(a, sortInfo.sortProp)
            : a[sortInfo.sortProp],
          bVal =
            sortDataType === "nested"
              ? getNestedPropVal(b, sortInfo.sortProp)
              : b[sortInfo.sortProp];
        if (bVal === undefined || bVal === null || bVal === "") return -1;
        if (aVal === undefined || aVal === null || aVal === "") return 1;
        if (aVal === bVal) return 0;
        if (sortInfo.sortAsc) return aVal > bVal ? 1 : -1;
        else return aVal > bVal ? -1 : 1;
      });
    }

    return result;
  };

  const getNestedPropVal = (a, prop: string) => {
    const split = prop.split("."),
      level1 = split[0],
      level2 = split[1];
    if (a[level1]) {
      if (a[level1].constructor === Array) {
        return a[level1].map((x) => x[level2]).join("");
      } else {
        return a[level1][level2];
      }
    }
  };

  /*   React.useEffect(() => {
    fetch(`api/meta/fields?objectType=${props.type}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setAllFields(data);
      });
  }, []); */

  const api = {
    getFilters,
    getSortInfo,
    clearFilters: _clearFilters,
    updateFilter: _updateFilter,
    updateSortInfo,
  } as CustomizableGridApi;

  const { sortProp, sortAsc } = sortInfo;

  const sortIconClass = sortAsc
    ? "sort-icon fas fa-arrow-up"
    : "sort-icon fas fa-arrow-down";
  const sortIcon = sortProp ? <span className={sortIconClass}></span> : [];

  const itemLines = props.loading ?
    <StandardGridLoader /> :
    props.items && props.items.length ? (
      _getSortedFilteredItems().map(props.mapCallback)
    ) : !props.hideLoadingWhenEmpty ? (
      <StandardGridLoader />
    ) : (
      <></>
    );
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (headerRef.current && bodyRef.current) {
      if ((innerHeight * 65) / 100 < bodyRef.current.scrollHeight) {
        const diff =
          headerRef.current.clientWidth - bodyRef.current.clientWidth;
        headerRef.current.style.paddingRight = (diff + 8) + "px"; // We add 8 because that's the default padding; we need to adjust extra padding on top of that

        //if (filterRow != null) filterRow.style.paddingRight = diff + 'px';
      }
    }
  }, [innerHeight, itemLines]);

  const headerPadding = props.redlineSpacing ? 10 : 0;

  const exportData = getTableData(_getSortedFilteredItems(), props.columns);

  return (
    <div className={cx("grid sv-grid", props.className)}>
      <CustomizableGridContext.Provider value={api}>
        <div
          //{/*style={{ paddingLeft: `${headerPadding}px`, paddingRight: "0px" }}*/}
          className="col-sm-12 grid-header sv-grid-header"
          ref={headerRef}
        >
          <React.Fragment>
            {props.columns.map((x) => {
              return (
                <div
                  key={x.propName}
                  className={cx('sortable', x.columnWidth ? `my-col-${x.columnWidth}` : colWidth)}
                  onClick={() =>
                    setSortInfo({
                      sortProp: x.propName,
                      sortAsc: !sortInfo.sortAsc,
                    })
                  }
                >
                  <b>{x.displayName}</b> {sortProp === "state" && sortIcon}
                </div>
              );
            })}
            <div className="sv-grid-header-buttons">
              {!props.hideFiltering && <span
                className="fas fa-filter"
                onClick={() => setShowFilters(!showFilters)}
              ></span>}
              <TableDataDownloadLink key={itemLines.length} data={exportData} filename={'export'} />
              {props.addCallback && (
                <button
                  className="btn btn-sm btn-blue fas fa-plus"
                  onClick={props.addCallback}
                  style={{ marginTop: '0px' }}
                ></button>
              )}
              {props.addComponent || []}
            </div>
          </React.Fragment>
          {showFilters && (
            <RenderFilter
              evenSpacing={props.evenColumnSpacing}
              columns={props.columns}
            />
          )}
        </div>
        <div
          style={{ overflowX: "hidden" }}
          ref={bodyRef}
          id={"thisisatest"}
          className="grid-body sv-grid-body custom-scrollbar"
        >
          {itemLines}
        </div>
      </CustomizableGridContext.Provider>
    </div>
  );
};

const RenderFilter = (props: {
  columns: MetaFieldInfo[];
  evenSpacing?: boolean;
}) => {
  const api = React.useContext(CustomizableGridContext);

  const _handleFilter = (e: React.ChangeEvent<HTMLElement>) => {
    // @ts-ignore
    const prop = e.currentTarget.name,
      // @ts-ignore
      value = e.currentTarget.value,
      dataType = e.currentTarget.getAttribute("data-type");

    api.updateFilter({ prop, value, dataType } as FilterOption);
  };

  const _handleDateFilter = (prop: string, range?: DateRange) => {
    if (range) {
      api.updateFilter({ prop: prop, value: range, dataType: 'date' } as FilterOption);
    }
    else {
      api.updateFilter({ prop: prop, value: '', dataType: 'date' });
    }
  }

  const colWidth = props.evenSpacing
    ? "col"
    : `my-col-${Math.floor(20 / props.columns.length)}`;

  return (
    <div className="col-sm-12 grid-filter sv-grid-filter">
      {props.columns.map((x) => {
        const style =
          x.jsType === "number"
            ? { width: "50%" }
            : { width: "calc(100% - 40px)" };

        return (
          <div key={x.propName} className={`${x.columnWidth ? "my-col-" + x.columnWidth : colWidth}`}>
            {
              x.jsType === "date" ?
                <SVDateRangePicker onChange={(range) => _handleDateFilter(x.propName, range)} className='form-control' /> :
                <input
                  name={x.propName}
                  data-type={x.jsType}
                  className="form-control"
                  onChange={_handleFilter}
                  style={style}
                />
            }
          </div>
        );
      })}
      <div className="sv-grid-filter-buttons">
        <button
          className="btn btn-sm btn-outline-secondary fas fa-redo"
          title="Clear filters"
          style={{ fontSize: "10px", marginTop: "0.25em" }}
          onClick={() => api.clearFilters()}
        ></button>
      </div>
    </div>
  );
};

export const getTableData = (data: any[], columns: MetaFieldInfo[]) => {
  if (!columns || !columns.length) return []

  const colInfo = columns.map(c => {
    return { type: c.propType, label: c.displayName }
  });
  const rows = data.map(x => {
    const row: any[] = [];
    columns.forEach(col => {
      const levels = col.propName.split('.');
      let value: any = 'N/A';
      for (let i = 0; i < levels.length; ++i) {
        const currentLevel = camelize(levels[i])
        const isFinal = i === levels.length - 1;
        value = Array.isArray(value) ?
          isFinal ?
            value.map(x => x[currentLevel]).join(', ') :
            value.map(x => x[currentLevel]) :
          (x[currentLevel] === undefined || x[currentLevel] === null ? 'N/A' : x[currentLevel]);

        if (isFinal) row.push(value.toString());
      }
    })
    return row;
  })

  rows.unshift(colInfo);

  return rows;
}
