import * as React from 'react';
import { ListItem } from '../../interfaces/interfaces';
import cx from 'classnames'
import MilestoneTemplater from './ProjectMilestoneTemplater';
import { MessageBox } from '../../components/MessageBox';

interface Props {
    addProjectType: any,
    clientId?: number | string
}

interface State {
    types: ListItem[]
    editTypeId?: number
    editTemplateId?: number,
    editTemplateName?: string,
    message?: string
}

export default class ProjectTypeForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            types: []
        }
    }

    componentWillMount() {
        this._getProjectTypes(this.props.clientId)
    }

    componentWillUpdate(nextProps: Props) {
        if (nextProps.clientId != this.props.clientId)
            this._getProjectTypes(nextProps.clientId)
    }

    _getProjectTypes = (clientId?: number | string) => {
        fetch(`api/Client/ProjectTypes?clientId=${clientId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    types: data
                })
            })
    }

    private _save = () => {
        const input = document.getElementById('newProjectType') as HTMLInputElement;
        if (input) {
            this.props.addProjectType(input.value, this.props.clientId)
            setTimeout(() => {
                this._getProjectTypes(this.props.clientId)
            }, 500)
            input.value = ''
        }
    }

    private _editType = (id: number, newVal: string) => {
        const editing = this.state.types && this.state.types.find(kd => kd.id == id) || {} as ListItem;
        if (editing.value == newVal) {
            this.setState({
                editTypeId: undefined
            })
        }
        else if (this.state.types && this.state.types.findIndex(kd => kd.id !== id && kd.value.toUpperCase() == newVal.toUpperCase()) !== -1) {
            alert('Oops! A brand by that name already exists');
        }
        else {
            fetch(`api/Client/ProjectType?id=${id}&newVal=${newVal}&clientId=${this.props.clientId}`, { method: 'PUT' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            types: data,
                            editTypeId: undefined
                        })
                    }
                    else alert('Error: ' + data.message)
                })
        }
    }

    private _saveEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const newVal = (parentDiv.querySelector('input.form-control') as HTMLInputElement).value

        if (newVal && this.state.editTypeId)
            this._editType(this.state.editTypeId, newVal)
    }

    private _toggleEditType = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const typeId = parentDiv.getAttribute('data-id');

        if (typeId) {
            this.setState({
                editTypeId: parseInt(typeId)
            })
        }
    }

    _removeType = (id: number | string) => {
        fetch(`api/Client/ProjectType?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ types: this.state.types.filter(x => x.id != id)})
                else this.setState({ message: data.message })
            })
    }

    private _toggleTemplatePopup = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const typeId = parentDiv.getAttribute('data-id');

        if (typeId) {
            const typeName = this.state.types.find(x => x.id === parseInt(typeId));
            this.setState({
                editTemplateId: parseInt(typeId),
                editTemplateName: typeName ? typeName.value : 'Unknown'
            })
        }
    }

    private _closeTemplatePopup = () => {
        this.setState({
            editTemplateId: undefined,
            editTemplateName: undefined
        })
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { editTypeId, types, editTemplateId, editTemplateName } = this.state
        const clientId = this.props.clientId ? this.props.clientId : 0
        const typeCards = (types && types.length) ?
            types.map(x => {
                const editing = x.id == editTypeId
                return (
                    <div key={`${x.value}_${x.id}`} className={cx('config-item-line', editing && 'editing')} data-id={x.id}>
                        {editing ?
                            <input className='form-control' defaultValue={x.value} />
                            :
                            <span onClick={this._toggleTemplatePopup} style={{ cursor: 'pointer' }}>{x.value}</span>}
                        {!editing && <span className={cx('fas fa-times status-remove', editing && 'editing')}
                            onClick={() => this._removeType(x.id)}
                        ></span>
                        }
                        {editing ?
                            <span className='fas fa-check status-edit editing'
                                title='Save change'
                                onClick={this._saveEdit}
                            ></span> :
                            <span className='fas fa-edit status-edit'
                                title='Edit status'
                                onClick={this._toggleEditType}
                            ></span>
                        }
                    </div>
                )
            }) : []

        return (            
            <div className='config-form config-flex max-350'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                {(editTemplateId && editTemplateName) &&
                    <MilestoneTemplater clientId={parseInt(clientId.toString())}
                        templateId={editTemplateId} templateName={editTemplateName}
                        close={this._closeTemplatePopup} />
                }
                <div className='config-new-item-form'>
                    <div className="form-header">
                        <h5 className="modal-title">New Project Type</h5>
                    </div>
                    <div className="form-body">
                        <input id='newProjectType' className="form-control" />
                    </div>
                    <div className='form-footer'>
                        <span className="btn btn-sm btn-blue" onClick={this._save}>Add</span>
                    </div>
                </div>
                <div className='config-item-list'>
                    <div className="form-header">
                        <h5 className="modal-title">Existing Project Types</h5>
                    </div>
                    <div className=''>
                        {typeCards}
                    </div>
                </div>
            </div>
        )
    }
}
