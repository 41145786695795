import React, { useEffect, useRef, useState } from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/dist/themes/base/jquery-ui.css';
import { useOutsideClickHandler } from 'src/helpers/customhooks';

const ModalDiv = styled.div<{ isClicked: boolean }>(({ isClicked }) => ({
  display: 'block',
  position: 'absolute',
  width: 'unset',
  height: 'unset',
  zIndex: isClicked ? 1051 : 1050,
  overflow: 'unset',
  '&>.modal-dialog': {
    margin: 'auto',
  },
  '&>.modal-dialog>.modal-content>.modal-body': {
    height: '100%',
  },
  '&>.modal-dialog>.modal-content>.modal-header': {
    padding: '4px',
    paddingRight: '12px',
    backgroundColor: 'lightgrey',
    cursor: 'move',
  },
  '&>.modal-dialog>.modal-content>.modal-header>h5': {
    marginBottom: 0,
  },
}));

type DraggableModalProps = PropsWithChildren<{
  onClose: () => void;
  title?: string;
}>;

export const DraggableModal = ({
  children,
  title,
  onClose,
}: DraggableModalProps) => {
  const [isLastClicked, setIsLastClicked] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClickHandler(modalRef, () => setIsLastClicked(false));

  useEffect(() => {
    $('.draggable-modal').draggable({ handle: '.modal-header' });
    $('.modal-content').resizable({
      grid: 25,
      minHeight: 300,
    });
  });

  return (
    <ModalDiv
      className="modal draggable-modal"
      data-backdrop="static"
      tabIndex={-1}
      isClicked={isLastClicked}
      ref={modalRef}
      onMouseDown={() => setIsLastClicked(true)}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div style={{padding: '4px 0px 0px 15px'} }>{title}</div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </ModalDiv>
  );
};
