import React from "react";
import { Activity } from "../interfaces/interfaces";
import { toMoney, formatDate } from "../helpers/formatters";

import { Link } from "react-router-dom";
import {
    CustomizableGrid,
    MetaFieldInfo,
} from "src/components/CustomizableGrid";
import { CSharpTypeCode } from "src/interfaces/enums";
import { useSelector } from "react-redux";
import { ApplicationState } from "../store";

interface ActivityStreamProps {
    activities: Activity[];
}

export const ActivityStream: React.FC<ActivityStreamProps> = ({
    activities,
}) => {
    const cols: MetaFieldInfo[] = [
        {
            displayName: "Project",
            propName: "projectNumber",
            jsType: "string",
            propType: CSharpTypeCode.String,
        },
        {
            displayName: "Date",
            propName: "date",
            jsType: "date",
            propType: CSharpTypeCode.DateTime,
        },
        {
            displayName: "User",
            propName: "user",
            jsType: "string",
            propType: CSharpTypeCode.String,
        },
        {
            displayName: "Company",
            propName: "company",
            jsType: "string",
            propType: CSharpTypeCode.String,
        },
        {
            displayName: "Action",
            propName: "action",
            jsType: "string",
            propType: CSharpTypeCode.String,
        },
        {
            displayName: "New Value",
            propName: "value",
            jsType: "string",
            propType: CSharpTypeCode.String,
        },
    ];

    const userStore = useSelector((s: ApplicationState) => s.user);

    const formatValue = (action: string, value: string) => {
        const normalizedAction = action.toUpperCase();
        if (normalizedAction.indexOf("COST") !== -1) {
            return toMoney(parseFloat(value), 2);
        } else if (normalizedAction.indexOf("PROGRESS") !== -1) {
            return value + "%";
        } else if (normalizedAction.indexOf("LEAD TIME") !== -1) {
            return value + " days";
        } else return value;
    };

    const renderItem = (activity: Activity) => {
        return (
            <div key={activity.value} className="grid-line">
                <div className="col mobile-preserve">
                    <Link to={`/project/${activity.projectId}`}>
                        {activity.projectNumber}
                    </Link>
                </div>
                <div className="col mobile-preserve">{formatDate(activity.date)}</div>
                <div className="col mobile-preserve">{activity.user}</div>
                <div className="col mobile-preserve">{activity.company}</div>
                <div className="col mobile-preserve">{activity.action}</div>
                <div className="col mobile-preserve">
                    {activity.link ? (
                        <Link to={activity.link}>{activity.value}</Link>
                    ) : (
                        formatValue(activity.action, activity.value)
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="activity-stream grid" style={{ overflowX: "auto" }}>
                <h4>Activity Stream</h4>
                <hr />
                <div style={{ minWidth: "800px" }}>
                    <CustomizableGrid
                        columns={cols}
                        items={activities}
                        mapCallback={renderItem}
                        className="report-grid"
                        evenColumnSpacing={true}
                        hideLoadingWhenEmpty={true}
                    />
                </div>
            </div>
        </>
    );
};
