import {
  addCCUser,
  addComment,
  addPhoto,
  addPunchItem,
  getAllPunchItems,
  removeCCUsers,
  rotatePhoto,
  sendPunchItems,
  updatePunchItem,
} from 'src/services/punchlist';
import { AppStartListening } from '../listenerMiddleware';
import { PunchlistActions } from './punchlist.reducer';
import { removePhoto } from 'src/services/punchlist';
import { PunchItemStatus } from '../../interfaces/interfaces';
import {
  addTemplate,
  addTemplateItem,
  copyTemplate,
  getTemplates,
  removeTemplateItem,
  updateTemplate,
  updateTemplateItem,
} from 'src/services/punchlistTemplates';

export const addPunchlistListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: PunchlistActions.getPunchItems,
      effect: async (action, listenApi) => {
        const response = await getAllPunchItems(action.payload);
        if (response) {
          listenApi.dispatch(PunchlistActions.setPunchItems(response));
          listenApi.dispatch(PunchlistActions.setProjectId(action.payload));
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addPunchItem,
      effect: async (action, listenApi) => {
        const response = await addPunchItem(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch(
              PunchlistActions.handlePunchItemResponse({
                status: 'failed',
                message: response.message,
              })
            );
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(
              PunchlistActions.handlePunchItemResponse({ status: 'success' })
            );
            const updated = listenApi.getState().punchlist.items.slice();
            updated.push(response);
            listenApi.dispatch(PunchlistActions.setPunchItems(updated));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.updatePunchItem,
      effect: async (action, listenApi) => {
        listenApi.dispatch(PunchlistActions.setIsItemLoading(true));
        const response = await updatePunchItem(
          action.payload.id,
          action.payload.updateObject
        );
        if (response) {
          const updated = listenApi
            .getState()
            .punchlist.items.slice()
            .map((x) => (x.id === response.id ? response : x))
            .filter((x) => x.status !== PunchItemStatus.Void);
          listenApi.dispatch(PunchlistActions.setPunchItems(updated));
        }
        listenApi.dispatch(PunchlistActions.setIsItemLoading(false));
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addCCUsers,
      effect: async (action, listenApi) => {
        const response = await addCCUser(
          action.payload.id,
          action.payload.userIds,
          action.payload.emails
        );
        if (response) {
          const updated = listenApi
            .getState()
            .punchlist.items.slice()
            .map((x) => (x.id === response.id ? response : x));
          listenApi.dispatch(PunchlistActions.setPunchItems(updated));
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.removeCCUsers,
      effect: async (action, listenApi) => {
        const response = await removeCCUsers(
          action.payload.id,
          action.payload.userIds
        );
        if (response) {
          const updated = listenApi
            .getState()
            .punchlist.items.slice()
            .map((x) => (x.id === response.id ? response : x));
          listenApi.dispatch(PunchlistActions.setPunchItems(updated));
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addComment,
      effect: async (action, listenApi) => {
        const response = await addComment(action.payload);
        if (response) {
          const updated = listenApi
            .getState()
            .punchlist.items.slice()
            .map((x) => (x.id === response.id ? response : x));
          listenApi.dispatch(PunchlistActions.setPunchItems(updated));
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addPhoto,
      effect: async (action, listenApi) => {
        listenApi.dispatch(PunchlistActions.setIsItemLoading(true));
        const form = new FormData();
        const files = action.payload.files;
        for (let i = 0; i < files.length; i++) {
          form.append('files', files[i], files[i].name.replace(/#/g, ''));
        }

        const response = await addPhoto(action.payload.id, form);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch(
              PunchlistActions.handleAddPhotoResponse({
                status: 'failed',
                message: response.message,
              })
            );
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .punchlist.items.slice()
              .map((x) => (x.id === response.id ? response : x));
            listenApi.dispatch(PunchlistActions.setPunchItems(updated));
          }
        }
        listenApi.dispatch(PunchlistActions.setIsItemLoading(false));
      },
    }),
    startListening({
      actionCreator: PunchlistActions.removePhoto,
      effect: async (action, listenApi) => {
        const response = await removePhoto(action.payload);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch(
              PunchlistActions.handleRemovePhotoResponse({
                status: 'failed',
                message: response.message,
              })
            );
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .punchlist.items.slice()
              .map((x) => (x.id === response.id ? response : x));
            listenApi.dispatch(PunchlistActions.setPunchItems(updated));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.rotatePhoto,
      effect: async (action, listenApi) => {
        listenApi.dispatch(PunchlistActions.setIsItemLoading(true));
        const response = await rotatePhoto(
          action.payload.id,
          action.payload.clockwise
        );
        if (response) {
          if ('message' in response) {
            listenApi.dispatch(
              PunchlistActions.handleRotatePhotoResponse({
                status: 'failed',
                message: response.message,
              })
            );
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            const updated = listenApi
              .getState()
              .punchlist.items.slice()
              .map((x) => (x.id === response.id ? response : x));
            listenApi.dispatch(PunchlistActions.setPunchItems(updated));
          }
        }
        listenApi.dispatch(PunchlistActions.setIsItemLoading(false));
      },
    }),
    startListening({
      actionCreator: PunchlistActions.toggleCCedModal,
      effect: (action, listenApi) => {
        listenApi.dispatch(PunchlistActions.setCCedModalId(action.payload));
      },
    }),
    startListening({
      actionCreator: PunchlistActions.sendPunchItems,
      effect: async (action, listenApi) => {
        const response = await sendPunchItems(action.payload);
        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(PunchlistActions.setPunchItems(response));
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Punch Items Sent',
            });
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.getTemplates,
      effect: async (action, listenApi) => {
        const response = await getTemplates();
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            listenApi.dispatch(PunchlistActions.setTemplates(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addTemplate,
      effect: async (action, listenApi) => {
        const response = await addTemplate();
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            listenApi.dispatch(PunchlistActions.setTemplates(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.copyTemplate,
      effect: async (action, listenApi) => {
        const response = await copyTemplate(action.payload);
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            listenApi.dispatch(PunchlistActions.setTemplates(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.updateTemplate,
      effect: async (action, listenApi) => {
        const response = await updateTemplate(
          action.payload.id,
          action.payload.info
        );
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            const updatedTemplates = listenApi
              .getState()
              .punchlist.templates.slice()
              .map((x) => (x.id === response.id ? response : x));

            listenApi.dispatch(PunchlistActions.setTemplates(updatedTemplates));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.addTemplateItem,
      effect: async (action, listenApi) => {
        const response = await addTemplateItem(action.payload);
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            const updatedTemplates = listenApi
              .getState()
              .punchlist.templates.slice()
              .map((x) => (x.id === response.id ? response : x));

            listenApi.dispatch(PunchlistActions.setTemplates(updatedTemplates));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.updateTemplateItem,
      effect: async (action, listenApi) => {
        const response = await updateTemplateItem(
          action.payload.id,
          action.payload.info
        );
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            const updatedTemplates = listenApi
              .getState()
              .punchlist.templates.slice()
              .map((x) => (x.id === response.id ? response : x));

            listenApi.dispatch(PunchlistActions.setTemplates(updatedTemplates));
          }
        }
      },
    }),
    startListening({
      actionCreator: PunchlistActions.removeTemplateItem,
      effect: async (action, listenApi) => {
        const response = await removeTemplateItem(action.payload);
        if (response) {
          if (typeof response == 'string') {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response,
            });
          } else {
            const updatedTemplates = listenApi
              .getState()
              .punchlist.templates.slice()
              .map((x) => (x.id === response.id ? response : x));

            listenApi.dispatch(PunchlistActions.setTemplates(updatedTemplates));
          }
        }
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
