import React, { useCallback } from 'react';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import {
  CSharpTypeCode,
  SafetyKpiCategory,
  SafetyKpiQuantityType,
} from 'src/interfaces/enums';
import {
  FieldReportSafetyKpiEntry,
  SafetyKpiTemplate,
  UpdateObject,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';
import Notepad from '../components/Notepad';
import { useDispatch } from 'react-redux';
import { SafetyKPIActions } from 'src/store/safetyKPI/safetyKPI.reducer';

interface DfrSafetyKPIProps {
  entries: FieldReportSafetyKpiEntry[];
  isDfrLocked: boolean;
}
const RadioContainerDiv = styled.form({
  width: '100%',
  alignSelf: 'center',
  '& input[type="radio"]': {
    marginLeft: 0,
    marginRight: '2px',
  },
  '& label': {
    marginLeft: 0,
    marginRight: '12px',
  },
});

export const DfrSafetyKPI: React.FC<DfrSafetyKPIProps> = ({
  entries,
  isDfrLocked,
}) => {
  const dispatch = useDispatch();

  const columns: MetaFieldInfo[] = [
    {
      displayName: 'Title',
      propName: 'kpiText',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 5,
    },
    {
      displayName: 'Indicator',
      propName: 'value',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 2,
    },
    {
      displayName: 'Quantity',
      propName: 'quantity',
      jsType: 'number',
      propType: CSharpTypeCode.Decimal,
      columnWidth: 2,
    },
    {
      displayName: 'Comments',
      propName: 'comments',
      jsType: 'string',
      propType: CSharpTypeCode.String,
    },
  ];

  const renderItem = useCallback(
    (entry: FieldReportSafetyKpiEntry) => {
      return (
        <div onClick={() => {}} className="sv-grid-line" key={entry.id}>
          <div className="my-col-5">{entry.kpiText}</div>
          <div className="my-col-2">
            <RadioContainerDiv>
              <input
                disabled={isDfrLocked}
                type="radio"
                name="kpi-indicator"
                value={'Yes'}
                defaultChecked={entry.value === 'Yes'}
                onChange={(e) => {
                  const info: UpdateObject = {
                    fieldName: 'value',
                    value: 'Yes',
                  };
                  dispatch(
                    SafetyKPIActions.updateFieldReportSafetyKPIEntry({
                      id: entry.id,
                      info,
                    })
                  );
                }}
              />
              <label htmlFor="kpi-category">Yes</label>
              <input
                disabled={isDfrLocked}
                type="radio"
                name="kpi-indicator"
                value={'No'}
                defaultChecked={entry.value === 'No'}
                onChange={() => {
                  const info: UpdateObject = {
                    fieldName: 'value',
                    value: 'No',
                  };
                  dispatch(
                    SafetyKPIActions.updateFieldReportSafetyKPIEntry({
                      id: entry.id,
                      info,
                    })
                  );
                }}
              />
              <label htmlFor="kpi-category">No</label>
              <input
                disabled={isDfrLocked}
                type="radio"
                name="kpi-indicator"
                value={'N/A'}
                defaultChecked={entry.value === 'N/A'}
                onChange={() => {
                  const info: UpdateObject = {
                    fieldName: 'value',
                    value: 'N/A',
                  };
                  dispatch(
                    SafetyKPIActions.updateFieldReportSafetyKPIEntry({
                      id: entry.id,
                      info,
                    })
                  );
                }}
              />
              <label htmlFor="kpi-category">N/A</label>
            </RadioContainerDiv>
          </div>
          <div className="my-col-2">
            <input
              style={{ width: '50px', textAlign: 'right' }}
              disabled={isDfrLocked || entry.value !== 'Yes'}
              size={4}
              step={
                entry.quantityType == SafetyKpiQuantityType.Hours ? 0.25 : 1
              }
              min={0}
              type={'number'}
              defaultValue={
                entry.value !== 'Yes' ? 'undefined' : entry.quantity
              }
              value={entry.value !== 'Yes' ? 'undefined' : undefined}
              onChange={(e) => {
                const info: UpdateObject = {
                  fieldName: 'quantity',
                  value: e.currentTarget.value,
                };
                dispatch(
                  SafetyKPIActions.updateFieldReportSafetyKPIEntryQuantity({
                    id: entry.id,
                    info,
                    type: entry.quantityType,
                  })
                );
              }}
            ></input>
            {entry.quantityType == SafetyKpiQuantityType.Hours
              ? '  Hours'
              : '  Count'}
          </div>
          <div className="col">
            <Notepad
              maxDisplayLength={500}
              disabled={isDfrLocked}
              notes={entry.comments}
              save={(notes: string) => {
                const info: UpdateObject = {
                  fieldName: 'comments',
                  value: notes,
                };
                dispatch(
                  SafetyKPIActions.updateFieldReportSafetyKPIEntry({
                    id: entry.id,
                    info,
                  })
                );
              }}
            />
          </div>
        </div>
      );
    },
    [entries]
  );

  return (
    <CustomizableGrid
      items={entries}
      columns={columns}
      loading={false}
      hideLoadingWhenEmpty={true}
      mapCallback={renderItem}
      evenColumnSpacing
    />
  );
};
