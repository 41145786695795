import React from "react";
import {
  ProcessingNotification,
  ProcessingStatus,
} from "src/interfaces/interfaces";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";

export interface ProcessingNotificationsState {
  connection: HubConnection | undefined;
  notifications: ProcessingNotification[];
  showNewNotification: boolean;
  isUploadNotification: boolean;
}

export const initialState: ProcessingNotificationsState = {
  connection: undefined,
  notifications: [],
  showNewNotification: false,
  isUploadNotification: false,
};

const {
  actions: ProcessingNotificationActions,
  reducer: ProcessingNotificationReducer,
} = createSlice({
  name: "processingNotification",
  initialState,
  reducers: {
    setConnection(state, action: PayloadAction<HubConnection>) {
      state.connection = action.payload;
    },
    setNotifications(state, action: PayloadAction<ProcessingNotification[]>) {
      state.notifications = action.payload;
    },
    addNotification(state, action: PayloadAction<ProcessingNotification>) {
      state.notifications.push(action.payload);
    },
    addUploadNotification(
      state,
      action: PayloadAction<ProcessingNotification>
    ) {
      state.notifications.push(action.payload);
    },
    updateNotification(state, action: PayloadAction<ProcessingNotification>) {
      state.notifications = state.notifications.filter(
        (item) => item.documentName !== action.payload.documentName
      );
      state.notifications.push(action.payload);
    },
    setDownloadedNotification(state, action: PayloadAction<string>) {
      const index = state.notifications.findIndex(
        (item) => item.documentName == action.payload
      );
      if (index > -1) {
        state.notifications[index].status = ProcessingStatus.Downloaded;
      }
    },
    setReadNotification(state, action: PayloadAction<ProcessingNotification>) {
      const index = state.notifications.findIndex(
        (item) => item.documentName == action.payload.documentName
      );
      state.notifications[index].isRead = true;
    },
    deleteNotification(state, action: PayloadAction<ProcessingNotification>) {
      state.notifications = state.notifications.filter(
        (item) => item.documentName !== action.payload.documentName
      );
    },
    deleteAllNotifications(state) {
      state.notifications = [];
    },
    getNotifications() {},
    setShowNewNotification(
      state,
      action: PayloadAction<{ show: boolean; isUpload: boolean }>
    ) {
      state.showNewNotification = action.payload.show;
      state.isUploadNotification = action.payload.isUpload;
    },
  },
});

export { ProcessingNotificationActions, ProcessingNotificationReducer };

export default ProcessingNotificationReducer;
