import * as React from 'react';
import * as cx from 'classnames';
import { Modal2 } from './Modal';
import { UpdateAnnouncement, UpdateAnnouncementPage } from '../interfaces/interfaces';
import { isImage } from '../helpers/misc';
import { formatDate } from '../helpers/formatters';

export const UpdateAnnouncementModal = (props: { announcements: UpdateAnnouncement[], dismiss: (id: number) => void, dismissAll: () => void, close: () => void }) => {

  const { announcements } = props;

  const [activeIdx, setActiveIdx] = React.useState(0);
  const [activeAnnouncement, setActiveAnnouncement] = React.useState(announcements[activeIdx])
  const [pageNumber, setPageNumber] = React.useState(1);
  const [activePage, setActivePage] = React.useState((activeAnnouncement.pages[pageNumber - 1] as UpdateAnnouncementPage))

  React.useEffect(() => {
    if (announcements.length === activeIdx) {
      // We dismissed the last one
      setActiveIdx(activeIdx - 1);
    } else {
      setActiveAnnouncement(announcements[activeIdx]);      
    }
  }, [announcements])

  React.useEffect(() => {
    setPageNumber(1);
    setActivePage(activeAnnouncement.pages[pageNumber - 1]);
  }, [activeAnnouncement])

  React.useEffect(() => {
    setActiveAnnouncement(announcements[activeIdx]);
  }, [activeIdx])

  React.useEffect(() => {
    setActivePage(activeAnnouncement.pages[pageNumber - 1]);
  }, [pageNumber])

  const updatePageNo = (no: number) => {
    if (no > 0) {
      if (pageNumber + no > activeAnnouncement.pages.length) {
        setPageNumber(1);
      } else {
        setPageNumber(pageNumber + no);
      }
    }
    else {
      if (pageNumber + no < 1) {
        setPageNumber(activeAnnouncement.pages.length);
      } else {
        setPageNumber(pageNumber + no);
      }
    }
  }


  const pageIndicators = activeAnnouncement.pages.length > 1 ? <div className='page-indicators' style={{ width: `${activeAnnouncement.pages.length * 15}px` }}>{
    activeAnnouncement.pages.map(x => <div className={cx('page-indicator', x.pageNo === pageNumber ? 'active' : '')}></div>)
  }</div>
    : []

  return <Modal2>
    <div className='modal-header'><h4 style={{marginBottom: '0px'}}>Upcoming SiteVue Updates</h4></div>
    <div className='modal-body'>
      <div key={announcements.length} style={{ display: 'flex' }} className='update-announcement-body'>
        <div className='announcement-list'>
          <div>
            {announcements.map((x,i) => {
              return <div onClick={() => setActiveIdx(i)} key={x.id} className={cx('announcement-title', x.id === activeAnnouncement.id ? 'active' : '')}>
                {x.title} - {formatDate(x.deploymentDate)}
              </div>
            })}
          </div>
        </div>
        <div className='announcement-page-content' key={activeIdx}>
          <div className='content-container custom-scrollbar' key={activePage.contentPath}>
            {
              isImage(activePage.contentPath) ? <img style={{ maxWidth: '100%' }} key={activePage.contentPath} src={activePage.contentPath} /> : <embed src={activePage.contentPath} />
            }
          </div>
          {activeAnnouncement.pages.length > 1 && <div className='fas fa-chevron-left' onClick={() => updatePageNo(-1)}></div>}
          {activeAnnouncement.pages.length > 1 && <div className='fas fa-chevron-right' onClick={() => updatePageNo(1)}></div>}
          {pageIndicators}
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-outline-secondary' onClick={() => props.dismiss(activeAnnouncement.id)}>Dismiss</button>
      {announcements.length > 1 && <button className='btn btn-sm btn-outline-secondary' onClick={props.dismissAll}>Dismiss All</button>}
      <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
    </div>
  </Modal2>
}