//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction } from './';

export interface Store {
    message?: string
    isLoading?: boolean
}

interface ClearMessageAction {
    type: 'CLEAR_MESSAGE'
}

interface SavingAction {
    type: 'SAVING',
}

interface SaveAction {
    type: 'SAVE_CONFIG',
    message?: string
}

interface LoadingAction {
    type: 'LOADING_CONFIG'
}

interface ErrorAction {
    type: 'CONFIG_ERROR',
    message: string
}

type KnownAction = ClearMessageAction | SavingAction | SaveAction | LoadingAction | ErrorAction

export const actionCreators = {
    addMilestone: (name: string, clientId?: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Milestone/Post?name=${name}&clientId=${clientId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    addMaterial: (name: string, milestoneId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Material/Post?name=${name}&milestoneId=${milestoneId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    addVendor: (name: string, email: string, phone: string, clientId?: string | number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Vendor/Post?clientId=${clientId}`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: name, email: email, phone: phone })
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    editVendor: (id: number, name: string, email: string, phone: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Vendor/Edit`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: id, name: name, email: email, phone: phone })
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    addBrand: (name: string, code: string, clientId?: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Brand/Post?name=${name}&code=${code}&clientId=${clientId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    addProjectType: (type: string, clientId?: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/ProjectType?name=${type}&clientId=${clientId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    addStatus: (status: string, clientId: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/ProjectStatus?status=${status}&clientId=${clientId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    editStatus: (statusId: string, newVal: string, clientId: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/ProjectStatus?id=${statusId}&newVal=${newVal}&clientId=${clientId}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    removeStatus: (statusId: number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/ProjectStatus?id=${statusId}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'SAVING' })
    },
    reorderStatus: (oldOrder: number, newOrder: number, clientId: string | number): AppThunkAction<KnownAction> => (dispatch) => {
        fetch(`api/Client/ReorderStatus?oldOrder=${oldOrder}&newOrder=${newOrder}&clientId=${clientId}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) dispatch({ type: 'SAVE_CONFIG', message: 'SAVED' })
                else
                    dispatch({ type: 'CONFIG_ERROR', message: data.message })
            })
        dispatch({ type: 'LOADING_CONFIG' })
    },   
    clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_MESSAGE' })
    }
}

const unloadedState = {

}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'SAVING':
            return { ...state, message: undefined }
        case 'LOADING_CONFIG':
            return { ...state, isLoading: true }
        case 'SAVE_CONFIG':
        case 'CONFIG_ERROR':
            return { ...state, message: action.message, isLoading: false }
        case 'CLEAR_MESSAGE':
            return { ...state, message: undefined, isLoading: false }
        default:{
            const exhaustiveCheck: never = action
        }
    }

    return state || unloadedState
}
