import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ProcurementItem, ProjectDetailVm } from '../interfaces/interfaces';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { ApplicationState } from '../store/index';
import { actionCreators as procurementActions } from '../store/procurement';
import { ProcurementItemDetail } from './ProcurementItemDetail';
import { actionCreators as projectActions } from '../store/projectDetail';
import { BackLink } from '../components/BackLink';

type Props = any
  & RouteComponentProps<{ projectId: string, itemId: string }>

export const ProcurementDetailPage = (props: Props) => {

  const [item, setItem] = React.useState({} as ProcurementItem);
  const [projectId, setProjectId] = React.useState(parseInt(props.match.params.projectId));
  const [itemId, setItemId] = React.useState(parseInt(props.match.params.itemId));
  const [project, setProject] = React.useState({} as ProjectDetailVm);

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);
  const projectDetailStore = useSelector((state: ApplicationState) => state.detail)
  const dispatch = useDispatch();

  React.useEffect(() => {
    _getItemFromStore();
    dispatch(projectActions.getDetail(projectId));
  }, [])

  React.useEffect(() => {
    _getItemFromStore();
  }, [procurementStore.procurementItems])

  React.useEffect(() => {
    setProject(projectDetailStore.detail)
  }, [projectDetailStore])

  const _getItemFromStore = () => {
    if (procurementStore.procurementItems.length === 0) {
      _loadStore();
    } else {
      const item = procurementStore.procurementItems.find(x => x.id === itemId);
      if (!item) { //Do something
      } //alert('Unable to find item');
      else setItem(item);
    }
  }

  const _loadStore = () => {
    if (!projectId) return;
    dispatch(procurementActions.getItems(projectId));
  }

  const body = (!item || !item.id) ?
    <StandardGridLoader rowContentHeight={30} rowPadding={5} /> :
    <ProcurementItemDetail project={project} key={item.lastModifiedTimestamp} item={item} modalScroll={false} />;

  return <div>
    <BackLink link={`/project/${projectId}#procurement`} />
    <div className='pageHeader'><h3>Procurement Item Details</h3></div>
    <hr />
    {body}
  </div>
}