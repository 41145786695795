import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { DfrPhoto } from 'src/interfaces/interfaces';
import { getProjectPhotos } from 'src/services/dfrs';
import Modal from '../components/Modal';
import PhotoCarousel from '../components/PhotoCarousel';
import { DfrPhotoPaginationModal } from './DfrPhotoPaginationModal';

interface ConnectedDfrPhotoPaginationModalProps {
  onClose: () => void;
  projectId: number;
  pageSize: number;
}

export const ConnectedDfrPhotoPaginationModal = ({
  onClose,
  projectId,
  pageSize,
}: ConnectedDfrPhotoPaginationModalProps) => {
  const [photos, setPhotos] = useState<DfrPhoto[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getPhotos(1);
  }, []);

  const getPhotos = useCallback(async (page: number) => {
    setLoading(true);
    const response = await getProjectPhotos(projectId, pageSize, page);
    if (response) {
      if ('message' in response) {
        dispatch({
          type: 'ERROR_EDIT_DATE_REPORTNAME',
          message: response.message,
        });
      } else {
        setPhotos(response.photos);
        setPageCount(Math.ceil(response.total / pageSize));
        console.log(Math.ceil(response.total / pageSize));
      }
    }
    setLoading(false);
  }, []);

  return (
    <DfrPhotoPaginationModal
      pageCount={pageCount}
      onPageChange={getPhotos}
      photos={photos}
      onClose={onClose}
      loading={loading}
    ></DfrPhotoPaginationModal>
  );
};
