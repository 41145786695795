import {
  PunchItemCommentPostModel,
  PunchItemPostModel,
  PunchItemVm,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { api } from '../api';
import { AxiosError, AxiosResponse } from 'axios';

export const getAllPunchItems = async (id: number) => {
  try {
    const response: AxiosResponse<PunchItemVm[]> = await api.get(
      `api/Punchlist/Project?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addPunchItem = async (model: PunchItemPostModel) => {
  const formData = new FormData();

  formData.append('json', JSON.stringify({ ...model, files: null }));
  if (model.files) {
    for (let i = 0; i < model.files.length; i++) {
      formData.append(
        'files',
        model.files[i],
        model.files[i].name.replace(/#/g, '')
      );
    }
  }
  try {
    const response: AxiosResponse<PunchItemVm | { message: string }> =
      await api.post(`api/Punchlist/Add`, formData);

    if (response.status == 200) {
      return response.data as PunchItemVm;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const addCCUser = async (
  id: number,
  userIds?: string,
  emails?: string
) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.post(
      `api/Punchlist/AddCC?id=${id}${userIds ? '&userIds=' + userIds : ''}${
        emails ? '&emails=' + emails : ''
      }`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addComment = async (model: PunchItemCommentPostModel) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.post(
      `api/Punchlist/AddComment`,
      model
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const addPhoto = async (id: number, form: FormData) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.post(
      `api/Punchlist/Photos?id=${id}`,
      form
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const updatePunchItem = async (
  id: number,
  updateObject: UpdateObject
) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.put(
      `api/Punchlist/Update?id=${id}`,
      updateObject
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const removePhoto = async (id: number) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.delete(
      `api/Punchlist/Photo?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const rotatePhoto = async (id: number, clockwise: boolean) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.put(
      `api/Punchlist/RotatePhoto?id=${id}&clockwise=${clockwise}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const removeCCUsers = async (id: number, userIds: string) => {
  try {
    const response: AxiosResponse<PunchItemVm> = await api.delete(
      `api/Punchlist/RemoveCC?id=${id}&userIds=${userIds}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const sendPunchItems = async (projectId: number) => {
  try {
    const response: AxiosResponse<PunchItemVm[]> = await api.put(
      `api/Punchlist/SendPunchItems?projectId=${projectId}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};
