import { AxiosError, AxiosResponse } from 'axios';
import { api } from '../api';
import {
  CustomReport,
  CustomReportVm,
  ReportDataQuery,
  ReportResult,
  SchemaPropertyInfo,
  SchemaTableInfo,
} from 'src/interfaces/interfaces';
import { mockQueryResponse } from './mocks';
import { CustomReportType } from '../../interfaces/enums';

export const getQuery = async (id: number) => {
  try {
    const response: AxiosResponse<ReportDataQuery> = await api.get(
      `api/CustomReport/GetQuery?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getAllQueries = async () => {
  try {
    const response: AxiosResponse<ReportDataQuery[]> = await api.get(
      `api/CustomReport/GetQueries?`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getReport = async (id: number) => {
  try {
    const response: AxiosResponse<CustomReportVm> = await api.get(
      `api/CustomReport/GetReport?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getAllReports = async () => {
  console.log('reports requested');
  try {
    const response: AxiosResponse<CustomReportVm[]> = await api.get(
      `api/CustomReport/GetReports`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const runReport = async (id: number) => {
  try {
    const response: AxiosResponse<ReportResult> = await api.get(
      `api/CustomReport/Run?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const addQuery = async (query: ReportDataQuery) => {
  try {
    const response: AxiosResponse<ReportDataQuery> = await api.post(
      `api/CustomReport/AddQuery`,
      query
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const addReport = async (payload: {
  reportName: string;
  reportType: CustomReportType;
}) => {
  try {
    const response: AxiosResponse<CustomReportVm> = await api.post(
      `api/CustomReport/AddReport?name=${payload.reportName}&type=${payload.reportType}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};

export const updateQuery = async (query: ReportDataQuery) => {
  try {
    const response: AxiosResponse<ReportDataQuery> = await api.put(
      `api/CustomReport/UpdateQuery`,
      query
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }
};

export const updateReport = async (report: CustomReportVm) => {
  try {
    const response: AxiosResponse<CustomReportVm> = await api.put(
      `api/CustomReport/UpdateReport`,
      report
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const getAllTables = async () => {
  try {
    const response: AxiosResponse<SchemaTableInfo[]> = await api.get(
      `api/Meta/ReportTables`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getRelatedTables = async (qualifiedTableName: string) => {
  try {
    const response: AxiosResponse<SchemaTableInfo[]> = await api.get(
      `api/Meta/RelatedTables?qualifiedTableName=${qualifiedTableName}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};

export const getFields = async (qualifiedTableName: string) => {
  try {
    const response: AxiosResponse<SchemaPropertyInfo[]> = await api.get(
      `api/Meta/TableProperties?qualifiedTableName=${qualifiedTableName}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch {
    return undefined;
  }

  return undefined;
};
