import {
  FileExplorerGetResponse,
  FileExplorerItem,
} from 'src/interfaces/interfaces';
import { api } from '../api';
import { AxiosError, AxiosResponse } from 'axios';

const FileExplorerPaths = Object.freeze({
  Get: 'api/fileexplorer/get',
  Upload: 'api/fileexplorer/upload',
  Add: 'api/fileexplorer/addfolder',
  Edit: 'api/fileexplorer/editfolder',
  Delete: 'api/fileexplorer/deleteitem',
  MoveItem: 'api/fileexplorer/moveitem',
  CopyItem: 'api/fileexplorer/copy',
});

const buildUrl = (
  path: string,
  params: any,
  subPathText: string | undefined
) => {
  let url = path + '?';
  const qString: any[] = [];
  Object.keys(params).forEach((key: any) => {
    if (Array.isArray(params[key])) {
      params[key].forEach((val: any) =>
        qString.push(encodeURIComponent(key) + '=' + encodeURIComponent(val))
      );
    } else
      qString.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      );
  });
  url = url + qString.join('&');
  if (subPathText) url = url + `&subPath=${subPathText}`;

  return url;
};

export const getFileExplorerItems = async (
  subPath?: string,
  searchText?: string
) => {
  try {
    let url = buildUrl(FileExplorerPaths.Get, {}, subPath);
    if (searchText) {
      url = url + `&searchText=${searchText}`;
    }

    const response: AxiosResponse<FileExplorerGetResponse> = await api.get(
      `${url}`,
      {
        headers: { credentials: 'same-origin' },
      }
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const copyFileExplorerItem = async (
  startingDir: string,
  item: FileExplorerItem
) => {
  try {
    const url = buildUrl(
      FileExplorerPaths.CopyItem,
      {},
      startingDir + '/Closeout'
    );

    const response: AxiosResponse = await api.post(
      `${url}`,
      JSON.stringify(item)
    );

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const uploadFileExplorerItem = async (
  subPath: string | undefined,
  form: FormData
) => {
  try {
    const url = buildUrl(FileExplorerPaths.Upload, {}, subPath);

    const response: AxiosResponse = await api.post(`${url}`, form);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const createFolder = async (
  newSubPath: string | undefined,
  newFolder: string,
  inherit: boolean
) => {
  try {
    let url = buildUrl(FileExplorerPaths.Add, {}, newSubPath);
    url =
      url +
      '&newFolder=' +
      encodeURIComponent(newFolder) +
      `&inheritPermissions=${inherit}`;

    const response: AxiosResponse = await api.post(`${url}`);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const modifyFolder = async (
  subPath: string | undefined,
  editFolderName: string | undefined,
  newName: string
) => {
  try {
    let url = buildUrl(FileExplorerPaths.Edit, {}, subPath);
    url = url + '&oldName=' + encodeURIComponent(editFolderName!);
    url = url + '&newName=' + encodeURIComponent(newName);

    const response: AxiosResponse = await api.put(`${url}`);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const removeItem = async (delSubPath: string | undefined) => {
  try {
    const url = buildUrl(
      FileExplorerPaths.Delete,
      {},
      encodeURIComponent(delSubPath!)
    );

    const response: AxiosResponse = await api.delete(`${url}`);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const moveFileExplorerItem = async (
  subPath: string | undefined,
  copiedForMovingItem: FileExplorerItem
) => {
  try {
    const url = buildUrl(FileExplorerPaths.MoveItem, {}, subPath);

    const response: AxiosResponse = await api.put(
      `${url}`,
      JSON.stringify(copiedForMovingItem)
    );

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const subscribeToItem = async (path: string) => {
  try {
    const url = buildUrl(`api/FolderSubscription/SubscribeMe`, {}, path);

    const response: AxiosResponse = await api.post(`${url}`);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};

export const unsubscribeToItem = async (path: string) => {
  try {
    const url = buildUrl(`api/FolderSubscription/UnsubscribeMe`, {}, path);
    const response: AxiosResponse = await api.delete(`${url}`);

    if (response.status == 200) {
      return true;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }
  }

  return undefined;
};
