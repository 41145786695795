//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { UserInfo } from '../interfaces/interfaces';

export interface Store {
  user?: UserInfo,
  authChecked?: boolean
}

type GetUserAction = { type: 'GET_USER', info?: UserInfo };

type ClearUserAction = { type: 'CLEAR_USER' };

type FailedAuthAction = { type: 'FAILED_AUTH' };

type KnownAction = GetUserAction | ClearUserAction | FailedAuthAction

export const actionCreators = {
  getUser: (update?: boolean) => async (dispatch, getState) => {
    //alert('In Method')
    if (!getState().user.user || update) {
      await fetch(`api/User/Get`, { credentials: 'same-origin' })
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, user]) => {
          if (resOk) dispatch({ type: 'GET_USER', info: user });
          else dispatch({ type: 'FAILED_AUTH' })
        })
    } else {
      await dispatch({ type: 'GET_USER', info: getState().user.user });
    }
  },
  toggleSidebarPinned: () => async (dispatch, getState) => {
    dispatch({ type: 'GET_USER', info: { ...getState().user.user, isSidebarPinned: !getState().user.user.isSidebarPinned } })
  },
  clearUser: (dispatch) => {
    dispatch({ type: 'CLEAR_USER' })
  }
}

const unloadedUser = { authChecked: false };

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'GET_USER':
      return { user: action.info, authChecked: true };
    case 'CLEAR_USER':
      return { user: undefined, authChecked: false };
    case 'FAILED_AUTH':
      return { user: undefined, authChecked: true }
    default:
      return state || unloadedUser;
  }

}