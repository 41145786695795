import { AxiosResponse } from "axios";
import {
  ListItem,
  TransferRecordPostModel,
  TransferSingleRecordPostModel,
} from "src/interfaces/interfaces";
import { api } from "../api";

export const transferRecords = async (request: TransferRecordPostModel) => {
  try {
    const response: AxiosResponse = await api.post(
      "api/Record/TransferRecords",
      request
    );

    if (response.status == 200) {
      return response.data;
    }
    return false;
  } catch {
      console.warn("error");
  }
  return false;
};

export const transferRecord = async (
  request: TransferSingleRecordPostModel
) => {
  try {
    const response: AxiosResponse = await api.post(
      "api/Record/TransferRecord",
      request
    );

    if (response.status == 200) {
      return true;
    }
    return false;
  } catch {
      console.warn("error");
  }
  return false;
};
