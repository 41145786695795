import * as React from 'react';
import { QuoteSummary } from '../interfaces/interfaces';

interface QuoteSummaryLineProps {
    quote: QuoteSummary, 
    detailCallback: (id: number) => void, 
    showDetailButton?: boolean
}

export const QuoteSummaryLine: React.FC<QuoteSummaryLineProps> = ( {quote, detailCallback, showDetailButton = true} ) => (
    <div key={quote.id} className='quote-line'>
        <div>
            {quote.title}
            {quote.approved && <React.Fragment>&nbsp; <span className='fas fa-check'></span></React.Fragment>}
        </div>
        { showDetailButton && <button className='btn btn-x-sm btn-blue details fas fa-list' onClick={() => detailCallback(quote.id)}></button>}
    </div>
)