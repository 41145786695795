import * as React from 'react';
import { JoinedList } from '../components/JoinedList';
import { ListItem, WorkRegion } from '../interfaces/interfaces';

export const VendorRegions = (props: { vendorId?: number, regions?: WorkRegion[] }) => {
  const [regions, setRegions] = React.useState([] as WorkRegion[]);
  const [editing, setEditing] = React.useState(false);
  const [allRegions, setAllRegions] = React.useState([] as WorkRegion[]);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setRegions(props.regions || []);
  }, [props.regions]);

  React.useEffect(() => {
    const getTrades = async () => {
      fetch(`api/globalconfig/workregions`)
        .then(res => res.json())
        .then(data => {
          setAllRegions(data);
          setLoaded(true);
        });
    };
    getTrades();
  }, [])

  const unselectedRegions = allRegions.filter(x => regions.findIndex(y => y.id === x.id) === -1);
  const adding = props.vendorId === undefined;

  const _changeRegions = (items: ListItem[]) => {
    let url = 'api/Vendor/Regions?id=' + props.vendorId;
    items.forEach(x => url += `&regionIds=${x.id}`);
    fetch(url, { method: 'PUT' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setRegions(data);
      })
  }

  if (loaded && (adding || editing)) {
    return (
      <div className='vendor-joined-list vendor-regions'>
        <JoinedList
          availableItems={unselectedRegions.map(x => new ListItem(x.id, x.name))}
          selectedItems={regions.map(x => new ListItem(x.id, x.name))}
          title='Regions'
          onChange={adding ? undefined : _changeRegions}
          style={{ width: 'calc(100% - 15px)' }}
        />
        {!adding && <button className='btn btn-sm btn btn-outline-secondary fas fa-check' onClick={() => setEditing(!editing)} title='Done'></button>}
      </div>
    )
  }

  return (
    <div className='vendor-joined-list-text'>
      <span>{regions.length ? regions.map(x => x.name).join(', ') : ''}</span>
      <button className='btn btn-sm btn btn-outline-secondary fas fa-edit' onClick={() => setEditing(!editing)} title='Edit regions'></button>
    </div>
  )
}