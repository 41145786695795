import * as React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
    rowCount?: number,
    rowPadding?: number,
    rowContentHeight?: number,
}

export const StandardGridLoader = (props: Props) => {
    let { rowCount, rowPadding, rowContentHeight } = props;

    if (rowCount === undefined) rowCount = 14;
    if (rowPadding === undefined) rowPadding = 10;
    if (rowContentHeight === undefined) rowContentHeight = 15;

    const rows: JSX.Element[] = [], dividers: JSX.Element[] = []

    let i = 0;
    while (i < rowCount) {
        rows.push(<StandardRow key={i} rowNo={i} contentHeight={rowContentHeight} padding={rowPadding} />)
        dividers.push(<StandardDivider key={i} rowNo={i} contentHeight={rowContentHeight} padding={rowPadding} /> )
        ++i;
    }

    return (
        <ContentLoader
            speed={2}
            interval={0}
            gradientRatio={0.5}
            width={'100%'}
            height={Math.min(700, (rowContentHeight * rowCount) + (rowPadding * (rowCount + 2)))}
            backgroundColor="#ddd"
            foregroundColor="#eee"
        >
            {rows}
            {/*dividers*/}
        </ContentLoader>

    )
}

interface RowProps {
    rowNo: number,
    contentHeight: number,
    padding: number
}

const StandardRow = (props: RowProps) => {
    // Each row height is the padding on both sides, plus the content
    const startY = props.rowNo * (props.contentHeight + props.padding * 2) + props.padding;

    return (
        <rect x='0' y={startY} rx='3' ry='3' width='100%' height={props.contentHeight} />
    )

}

const StandardDivider = (props: RowProps) => {
    // Each row height is the padding on both sides, plus the content, plus 2 for the border line
    const y = (props.rowNo + 1) * (props.contentHeight + props.padding * 2 + 2) - 2;

    return (
        <rect x='0' y={y} rx='3' ry='3' width='100%' height='2' />
    )
}

/**/