import * as React from 'react'
import { ListItem, SubmittalPackagePostModel, SubmittalPackage } from '../interfaces/interfaces'
import { MessageBox } from '../components/MessageBox'
import { JoinedList } from '../components/JoinedList'
import Modal from '../components/Modal'

interface Props {
    projectId: number
    addCallback: (item: SubmittalPackage) => void,
    close: () => void
}

interface State {
    submittals: ListItem[]
    showSubmitButton: boolean
    message?: string
}

export default class AddPackageModal extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            submittals: [],
            showSubmitButton: false,
        }
    }

    componentDidMount() {
        this._getSubmittals(this.props.projectId)
        //this._getProjectMembers()
    }

    validateModel = (model: SubmittalPackagePostModel) => {
        if (!model.description || !model.description.length || !model.title || !model.title.length) {
            this.setState({ message: 'Error: Please make sure all required fields are filled out before submitting.' })
            return false;
        }

        if (model.submit && model.submittalIds.length < 5) {
            this.setState({ message: 'Error: Before submitting you select at least five submittal items' });
            return false;
        }
        return true;
    }

    _getSubmittals = (id: number) => {
        fetch(`api/Submittal/AllUnpackaged?projectId=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    const items = data.map(x => new ListItem(x.id, x.title))
                    this.setState({ submittals: items })
                }
                else {
                    this.setState({ message: data.message })
                }
            })
    }

    _saveDraft = () => this._save(false)

    _saveAndSubmit = () => this._save(true)

    _save = (submit: boolean) => {
        const title = (document.getElementById('package-title') as HTMLInputElement).value,
            description = (document.getElementById('package-description') as HTMLInputElement).value,
            submittals = (document.querySelectorAll('#submittal-list .selected-list>option'))
        // If they match, the user clearly didn't pick a listed item
        // In that case clear out vendorId so back-end knows to treat it as external vendor        

        const submittalIds: number[] = [];
        [].forEach.call(submittals, (item: HTMLOptionElement) => {
            const id = parseInt(item.value);
            submittalIds.push(id);
        })

        const model = {
            projectId: this.props.projectId, title, description, submittalIds, submit
        } as SubmittalPackagePostModel

        if (this.validateModel(model)) {
            fetch(`api/SubmittalPackage/Add`,
                {
                    method: 'POST',
                    body: JSON.stringify(model),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.props.addCallback(data)
                    }
                    else this.setState({ message: data.message })
                })
        }
        else {
            this.setState({ message: 'Error: Please review the form and ensure that all required fields are filled out' })
        }
    }

    _toggleSubmitBtn = (sel: ListItem[]) => {
        if (sel && sel.length >= 2) this.setState({ showSubmitButton: true })
        else this.setState({ showSubmitButton: false })
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { submittals, showSubmitButton } = this.state;

        return (
            <Modal dialogClass='submittal-modal'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                <div className='modal-header'>
                    <h4>Create Submittal</h4>
                </div>
                <div className='modal-body'>
                    <div className='row'>
                        <div className='col-sm-3'><label>Title*</label></div>
                        <div className='col-sm-9'>
                            <input id='package-title' className='form-control' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-3'><label>Description*</label></div>
                        <div className='col-sm-9'>
                            <textarea id='package-description' className='form-control'></textarea>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-3'><label>Submittals</label></div>
                        <div className='col-sm-9' id='submittal-list' style={{ paddingLeft: '25px' }}>
                            <JoinedList title='' availableItems={submittals}
                                selectedItems={{} as ListItem[]} onChange={this._toggleSubmitBtn} />
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    {showSubmitButton && <span className='btn btn-sm btn-green' onClick={this._saveAndSubmit}>Submit</span>}
                    <button className='btn btn-sm btn-blue' onClick={this._saveDraft}>Save</button>
                    <span className='btn btn-sm btn-outline-secondary' onClick={this.props.close}>Close</span>
                </div>
            </Modal>
        )
    }
}