import React from 'react';
import { MessageBox } from './MessageBox';

interface Props {
    selector: string;
    style?: any;
}

export const Clipboard = (props: Props) => {
    const [message, setMessage] = React.useState('')

    const _copy = () => {
        const element = document.querySelector(props.selector) as HTMLInputElement | HTMLTextAreaElement;
        if (!element) {
            alert('Unable to find element to copy');
            return;
        }

        const value = element.value; 
        if (!value || value === '') {
            alert('No text to copy');
            return;
        }

        // @ts-ignore
        navigator.clipboard.writeText(value).then(
            () => setMessage('Text copied!'),
            () => setMessage('Error: unable to copy text')
        )
    }

    const style = props.style || {}

    return (
        <div className='btn btn-sm btn-outline-secondary far fa-clipboard' onClick={_copy} style={style} title='Copy text to clipboard'>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
        </div>
    )

}