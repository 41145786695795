import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import * as cx from 'classnames';

import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { NotificationItem } from './NotificationItem';


interface Props {
    notifications: UserNotification[],
    loading: boolean,
    fullPage: boolean,
}

export const NotificationStream = (props: Props) => {

    const { notifications, fullPage } = props;

    const dispatch = useDispatch();

    const hasNotifications = (notifications && notifications.length !== 0)

    const streamBody =
        <div>
            {!fullPage &&
                <div className='stream-tray-banner'><b>{notifications.length} Notification(s).</b>
                    <div className='banner-links'>
                        <Link to='/my-notifications'>View history</Link>
                        {hasNotifications &&
                            <React.Fragment>
                                &#8226;
                                <a onClick={() => { dispatch(actionCreators.dismiss(notifications.filter(x => x.status !== UserNotificationStatus.Dismissed).map(x => x.id))); return false }}>Dismiss all</a>
                            </React.Fragment>
                        }
                    </div>
                </div>
            }
            {hasNotifications ?
                notifications.map(x => <NotificationItem notification={x} dismissable={!fullPage && x.status !== UserNotificationStatus.Dismissed} fullPage={fullPage} />) :
                <div className='notification-item'><b>No notifications to display</b></div>
            }
        </div>


    return (
        <div className={cx('notification-stream', fullPage ? 'fullpage' : 'tray', !hasNotifications && 'empty-stream')}>
            {streamBody}
        </div>
    )
}