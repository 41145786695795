import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CustomReportType } from '../../interfaces/enums';
import { CustomReportVm, ReportResult } from '../../interfaces/interfaces';
import { RectangleLoader } from '../../loaders/RectangleLoader';
import { loadedReportSelector, reportResultSelector, reportsStatusSelector } from '../../store/customReport';


export const CustomReportResults = () => {

  const reportResult = useSelector(reportResultSelector);
  const loadedReport = useSelector(loadedReportSelector);
  const status = useSelector(reportsStatusSelector);

  if (reportResult === null || !loadedReport) return <div></div>

  if (status === 'running') return <RectangleLoader />
  let reportDisplay: JSX.Element = <div></div>;

  switch (loadedReport?.reportType) {
    case CustomReportType.Table:
      reportDisplay = <TableReportResultsBody report={loadedReport} result={reportResult} />;
      break;
    default:
      break;
  }

  return <div className='report-result-body'>
    {reportDisplay}
  </div>
}

const ReportResultTable = styled.div({
  borderBottom: '2px solid rgba(15, 120, 200, 0.5)',
  boxShadow: '0px 0px 5px rgba(200, 200, 200, 0.3)',  
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(auto, 1fr))',
  //display: 'grid',
  //gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 500px))',
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '100%',
  width: '100%',
})

const ReportResuiltTableBody = styled.div({
  maxHeight: '65vh',
  overflowY: 'auto',
  overflowX: 'visible',
  '& .table-row:nth-of-type(2n)': {
    backgroundColor: 'rgba(200, 200, 200, 0.3)'
  }
})

const ReportResultTableRow = styled.div({
  //display: 'flex',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  //gridTemplateColumns: 'repeat(auto-fill, 1fr)',
  overflowX: 'visible',
  padding: '15px 0px',
})

const ReportResultTableHeaderRow = styled(ReportResultTableRow)`
  border-radius: 5px 5px 0px 0px;
  background-color: rgba(15, 120, 200, 0.5);
`

const ReportResultTableCell = styled.div({
  //borderCollapse: 'collapse',
  padding: '0px 10px',
  //flex: 1,
  //width: '70%',
  //flexShrink: '0',
})

const ReportResultHeaderCell = styled(ReportResultTableCell)`
  font-weight: bold;
`

export const TableReportResultsBody = (props: { report: CustomReportVm, result: ReportResult }) => {

  const { report, result } = props;

  const columnHeaders = report.columns.map((x, i) => {
    return <ReportResultHeaderCell key={i} className='report-result-column-header table-cell'>
      {x.displayName}
    </ReportResultHeaderCell>
  })

  const rows = result.data.map(row => {
    return <ReportResultTableRow className='report-result-row table-row'>
      {row.map((x, i) => <ReportResultTableCell key={i} className='table-cell truncate' title={x}>{x}</ReportResultTableCell>)}
    </ReportResultTableRow>
  })

  return <ReportResultTable className='report-result table-report custom-scrollbar'>
    <ReportResultTableHeaderRow className='report-result-header table-header'>
      {columnHeaders}
    </ReportResultTableHeaderRow>
    <ReportResuiltTableBody className='custom-scrollbar'>
      {rows}
    </ReportResuiltTableBody>
  </ReportResultTable>
}