import React, { useState, useRef } from "react";
import { MessageBox } from "src/components/MessageBox";
import { ListItem } from "src/interfaces/interfaces";
import Modal from "../../components/Modal";

interface ChecklistFunctionAddModalProps {
	onFunctionAdd: (type: string) => void;
	onClose: () => void;
	functions: ListItem[];
}

const ChecklistFunctionAddModal: React.FC<ChecklistFunctionAddModalProps> = ({
	onFunctionAdd,
	onClose,
	functions,
}) => {
	const [message, setMessage] = useState("");
	const functionRef = useRef<HTMLInputElement>(null);

	const handleOnSave = () => {
		if (functionRef.current?.value) {
			if (functions.some((x) => x.value === functionRef.current?.value)) {
				setMessage("Error: Function already exists.");
			} else {
				onFunctionAdd(functionRef.current.value);
				onClose();
			}
		} else {
			setMessage("Error: Please enter a function to add.");
		}
	};

	return (
		<Modal dialogStyle={{ width: "500px" }} style={{ display: "flex" }}>
			<MessageBox message={message} clearMessage={() => setMessage("")} />
			<div className="modal-header">
				<h4>Add Function</h4>
			</div>
			<div className="modal-body">
				<div className={"form-group"}>
					<span className="inline-label">Function Name</span>
					<input style={{ width: "100%" }} ref={functionRef} />
				</div>
			</div>
			<div className="modal-footer">
				<button className="btn btn-sm btn-blue" onClick={() => handleOnSave()}>
					Add
				</button>
				<span className="btn btn-sm btn-outline-secondary" onClick={onClose}>
					Close
				</span>
			</div>
		</Modal>
	);
};

export default ChecklistFunctionAddModal;
