import * as React from 'react'
import { ListItem, RFIResponse, RFIResponseDocument, RFIResponseStatus, RFIStatus } from '../interfaces/interfaces'
import { formatDate, formatDateTime } from '../helpers/formatters';
import * as cx from 'classnames'
import RFICommentStream from './RFICommentStream';
import Modal from '../components/Modal';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import DocIcon from '../components/DocIcon';

interface Props {
    response: RFIResponse,
    rfiStatus?: number,
    isSubmitter: boolean,
    addComment?: (id: number, c: string) => void
    closeResponse?: (id: number, approved: boolean, reason?: string) => void,
    updateResponse?: (form: FormData, button: HTMLButtonElement) => void;
    forPrint?: boolean,
    locked?: boolean, // for condensed history on reissued
}

interface State {
    showComments: boolean;
    showRejectModal: boolean;
}

export const RFIResponseCard = (props: Props) => {

    const { response, rfiStatus, forPrint, locked, isSubmitter } = props

    const [showComments, setShowComments] = React.useState(false);
    const [showRejectModal, setShowRejectModal] = React.useState(false);

    const userStore = useSelector((s: ApplicationState) => s.user);

    const isResponder = userStore.user && userStore.user.userId === response.userId;

    const _toggleComments = () => {
        setShowComments(!showComments);
    }

    const _addComment = () => {
        const textArea = document.getElementById('new-response-comment') as HTMLTextAreaElement
        if (textArea) {
            if (props.addComment) props.addComment(props.response.id, textArea.value)
            textArea.value = '';
        }
    }

    const _closeResponse = (approved: boolean) => {
        if (props.closeResponse)
            if (approved) props.closeResponse(props.response.id, approved)
            else {
                const reason = document.querySelector('#reject-reason') as HTMLTextAreaElement;
                if (reason.value.length < 20) {
                    alert('Oops! Please enter a longer reason (at least 20 characters)');
                }
                else {
                    props.closeResponse(props.response.id, approved, reason.value);
                    setShowRejectModal(false);
                }
            }
    }

    const _toggleRejectModal = () => {
        setShowRejectModal(!showRejectModal);
    }

    const _updateResponse = (e: React.MouseEvent<HTMLButtonElement>) => {
        const button = e.currentTarget;
        button.disabled = true;
        const text = (document.getElementById('response-textarea') as HTMLTextAreaElement).value;
        const files = (document.getElementById('update-response-attachments') as HTMLInputElement).files;
        const form = new FormData();
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; ++i) form.append(`files`, files[i], files[i].name)
        }
        form.append('json', JSON.stringify({ text: text, responseId: props.response.id }));

        if (props.updateResponse) props.updateResponse(form, button);
    }

    //_showDoc = (doc: RFIResponseDocument) => { setState({ displayedDoc: doc }) }

    //_hideDoc = () => { setState({ displayedDoc: undefined }) }

    const renderApproveRejectTab = () => {
        let classString = '';
        switch (props.response.status) {
            case 0: classString = 'response-awaiting'; break;
            case 1: classString = 'response-approved'; break;
            case 2: classString = 'response-rejected'; break;
            case 3: classString = 'response-reissued'; break;
        }
        if (response.status === 0) {
            if (!isSubmitter) return <div className='rfi-response-tab' style={{ visibility: 'hidden' }}></div>
            else {
                return (
                    <div className='rfi-response-tabs'>
                        <div className='approve-tab' onClick={() => _closeResponse(true)}>
                            <span className='fas fa-check'></span>
                        </div>
                        <div className='reject-tab' onClick={_toggleRejectModal} title='Need More Info'>
                            <span className='fas fa-question-sign'></span>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div className={cx('rfi-response-tab', classString)}>
            </div>
        )
    }


    const renderRejectModal = () => {
        return (
            <Modal>
                <div className='modal-header'>
                    <h5>Please supply a comment describing the type of information you still need</h5>
                </div>
                <div className='modal-body'>
                    <textarea id='reject-reason' className='form-control' rows={4}></textarea>
                </div>
                <div className='modal-footer'>
                    <span className='btn btn-sm btn-blue' onClick={() => _closeResponse(false)}>Submit</span>
                    <span className='btn btn-sm btn-outline-secondary' onClick={_toggleRejectModal}>Close</span>
                </div>
            </Modal>
        )
    }

    //const encodedSubject = encodeURIComponent(`${projectNumber} - ${rfiNumber}`.trim())
    //const encodedBody = encodeURIComponent(''.trim())
    const editable = props.updateResponse && isResponder && response.status === RFIResponseStatus.Submitted && (rfiStatus === RFIStatus.Answered || rfiStatus === RFIStatus.AwaitingResponse);

    return (
        <div className={cx('rfi-response-card', response.status === RFIResponseStatus.Rejected ? 'rejected' : '', editable && 'editing')}>
            <div className='response-name-timestamp'>
                <span>{response.userFullName} - {formatDateTime(response.timestamp)} {response.lastModifiedTimestamp ? <small>(last edited: {formatDateTime(response.lastModifiedTimestamp)})</small> : []}</span>
                {/*<a key={response.reviewerEmail} href={`mailto:${response.reviewerEmail}?Subject=${encodedSubject}&body=${encodedBody}`}>
                        <span key={response.reviewerEmail} className='fas fa-envelope'></span>
                    </a>*/}
            </div>
            <div className='response-card-response'>
                {renderApproveRejectTab()}
                <div className='rfi-response-body'>                    
                    {editable ? <textarea id='response-textarea' className='form-control' defaultValue={response.response} /> : <div className={cx(response.documents.length !== 0 && 'response-text-with-documents')} style={{  }}>{response.response}</div>}
                    {(!forPrint && response.documents.length) ? <div className='response-doc-icons'>{response.documents.map(x => <DocIcon doc={x} />)}</div> : []}
                    {editable && <div className='response-edit-options'>
                        <input type='file' multiple id='update-response-attachments' />
                        <button className='btn btn-x-sm btn-green' onClick={_updateResponse}>Save Changes</button>
                    </div>}
                </div>
            </div>
            {(!forPrint && !locked) && <div className='rfi-response-comments'>
                <span className={cx('fas fa-comment', response.comments.length ? 'orange' : '')}
                    title={rfiStatus && rfiStatus < RFIStatus.Accepted ? "click to view comments or add" : "comments locked, click to view"}
                    onClick={_toggleComments}
                >
                </span>
                <span className='comment-count'>- {`${response.comments.length} comment${response.comments.length !== 1 ? 's' : ''}`}</span>
            </div>
            }
            {showComments &&
                // Close the response for comment if the RFI is closed or response is rejected
                <RFICommentStream open={!locked && rfiStatus !== 2 && response.status !== 2}
                    responseId={response.id} addComment={_addComment}
                    comments={response.comments} />
            }
            {showRejectModal && renderRejectModal()}
        </div>
    )
}

