import * as React from 'react';
import { formatDateTime } from '../helpers/formatters';
import { TransmittalRecipient } from './TransmittalGrid';

export const TransmittalRecipientComment = (props: { recipient: TransmittalRecipient }) => {
    const { recipient } = props;

    if (!recipient.comments || !recipient.comments.length) return <div></div>

    return (
        <div className='recipient-comment'>
            <p className='commenter-name'>{recipient.userFullName} - <i>{formatDateTime(recipient.commentTimestamp)}</i></p>
            <div className='comment'>{recipient.comments}</div>
        </div>
    )
}