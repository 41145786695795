import {
  addQuery,
  addReport,
  getAllQueries,
  getAllReports,
  getAllTables,
  getFields,
  getRelatedTables,
  getReport,
  updateReport,
  updateQuery,
  getQuery,
  runReport,
} from 'src/services/customReports';
import { AppStartListening } from '../listenerMiddleware';
import { CustomReportActions } from './';
import { SchemaPropertyInfo, SchemaTableInfo } from 'src/interfaces/interfaces';

export const addCustomReportListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: CustomReportActions.createReport,
      effect: async (action, listenApi) => {
        const response = await addReport(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(CustomReportActions.addReport(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.runReport,
      effect: async (action, listenApi) => {
        const response = await runReport(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(CustomReportActions.setReportResults(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.updateReport,
      effect: async (action, listenApi) => {
        const response = await updateReport(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(CustomReportActions.setLoadedReport(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getLoadedReport,
      effect: async (action, listenApi) => {
        const response = await getReport(action.payload);
        if (response) {
          listenApi.dispatch(CustomReportActions.setLoadedReport(response));
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getAllTables,
      effect: async (action, listenApi) => {
        const response = await getAllTables();

        if (response) {
          listenApi.dispatch(CustomReportActions.setAllTables(response));
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getAllQueries,
      effect: async (action, listenApi) => {
        const response = await getAllQueries();

        if (response) {
          listenApi.dispatch(CustomReportActions.setAllQueries(response));
        }
        listenApi.dispatch(CustomReportActions.setQueriesStatus('idle'));
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getAllReports,
      effect: async (action, listenApi) => {
        const response = await getAllReports();

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(CustomReportActions.setAllReports(response));
          }
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getRelatedTables,
      effect: async (action, listenApi) => {
        const response = await getRelatedTables(action.payload);

        if (response) {
          const key = action.payload;
          const newRecord: Record<string, SchemaTableInfo[]> = {
            [key]: response,
          };

          listenApi.dispatch(CustomReportActions.addRelatedTables(newRecord));
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getFields,
      effect: async (action, listenApi) => {
        const response = await getFields(action.payload);

        if (response) {
          const key = action.payload;
          const newRecord: Record<string, SchemaPropertyInfo[]> = {
            [key]: response,
          };

          listenApi.dispatch(CustomReportActions.addFields(newRecord));
        }
      },
    }),
    startListening({
      actionCreator: CustomReportActions.getQuery,
      effect: async (action, listenApi) => {
        const response = await getQuery(action.payload);

        if (response) {
          listenApi.dispatch(CustomReportActions.setLoadedQuery(response));
          listenApi.dispatch(
            CustomReportActions.getFields(response.rootTableAssemblyName)
          );
          listenApi.dispatch(
            CustomReportActions.getRelatedTables(response.rootTableAssemblyName)
          );
          response.includes.map((x) => {
            listenApi.dispatch(
              CustomReportActions.getFields(x.assmeblyQualifiedName)
            );
          });
        }
        listenApi.dispatch(CustomReportActions.setQueriesStatus('idle'));
      },
    }),
    startListening({
      actionCreator: CustomReportActions.addQuery,
      effect: async (action, listenApi) => {
        const response = await addQuery(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Success',
            });
            listenApi.dispatch(CustomReportActions.addQueryToAll(response));
            listenApi.dispatch(
              CustomReportActions.setIsSuccessfulAddQuery(true)
            );
          }
        }
        listenApi.dispatch(CustomReportActions.setQueriesStatus('idle'));
      },
    }),
    startListening({
      actionCreator: CustomReportActions.updateQuery,
      effect: async (action, listenApi) => {
        const response = await updateQuery(action.payload);

        if (response) {
          if ('message' in response) {
            listenApi.dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: response.message,
            });
          } else {
            listenApi.dispatch(CustomReportActions.setLoadedQuery(response));

            const updatedQueries = listenApi
              .getState()
              .customReport.queries.slice()
              .map((x) => (x.id === response.id ? response : x));

            listenApi.dispatch(
              CustomReportActions.setAllQueries(updatedQueries)
            );

            const report = listenApi.getState().customReport.loadedReport;

            if (report) {
              listenApi.dispatch(
                CustomReportActions.getLoadedReport(report.id)
              );
            }
          }
        }
        listenApi.dispatch(CustomReportActions.setQueriesStatus('idle'));
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
