import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MessageBox } from '../components/MessageBox';
import Modal from '../components/Modal'
import { cleanDateString } from '../helpers/misc';

export enum FileShareAccessType {
    ReadOnly,
    ReadWrite,
    ReadOnlyAllLevels,
    ReadWriteAllLevels
}

interface FileShareToken {
    token: string;
    sharePath: string;
    accessType: FileShareAccessType,
    creatorEmail: string,
    creatorName: string,
    createdAt: string,
    fileShareUsers: FileShareUser[],
    expanded: boolean
}

interface FileShareUser {
    token: string;
    userEmail: string;
    lastAccessTime: string;
}

export const FileShare = (props: RouteComponentProps<any>) => {

    const [tokens, setTokens] = React.useState([] as FileShareToken[])
    const [expanded, setExpanded] = React.useState([] as string[])
    const [message, setMessage] = React.useState('');
    const [editToken, setEditToken] = React.useState('');

    const clearMessage = () => setMessage('');
    const toggleExpanded = (x: string) => {
        let e = [...expanded];
        if (expanded.indexOf(x) !== -1) e = e.filter(y => y !== x)
        else e.push(x);
        setExpanded(e);
    }

    const getTokens = async () => {
        fetch(`FileShare/GetTokens`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setTokens(data);
                else setMessage(data.message);
            })
    }

    const editTokenAccess = (e: React.ChangeEvent<HTMLSelectElement>, token: string) => {
        fetch(`fileshare/editaccess?token=${token}&accessType=${e.currentTarget.value}`, { method: 'PUT' })
            .then(res => getTokens())
    }

    const removeUser = (token: string, email: string) => {
        fetch(`fileshare/removeuser?token=${token}&email=${email}`, { method: 'PUT' })
            .then(res => getTokens())
    }

    const expireToken = (token: string) => {
        fetch(`fileshare/expire?token=${token}`, { method: 'PUT' })
            .then(res => getTokens())
    }

    React.useEffect(() => { getTokens() }, [])

    const FileShareTokenLine = (t: FileShareToken) => {

        const tokenExpanded = expanded.indexOf(t.token) !== -1;
        const chevClass = tokenExpanded ? 'fas fa-chevron-down btn' : 'fas fa-chevron-right btn'
        const key = `${t.token}_${tokenExpanded}_${t.accessType}`,
            toggleEdit = editToken === t.token ? () => setEditToken('') : () => setEditToken(t.token)

        return (
            <React.Fragment key={key}>
                <div className='token-item' style={{ display: 'flex' }}>
                    <div className='col-sm-3'>{pathItemName(t.sharePath)}</div>
                    <div className='col-sm-3'>
                        {editToken === t.token ?
                            <select className='form-control' onChange={(e) => editTokenAccess(e, t.token)} defaultValue={t.accessType}>
                                <option className='form-control' value={-1}></option>
                                <option className='form-control' value={FileShareAccessType.ReadOnly}>{stringifyAccessType(FileShareAccessType.ReadOnly)}</option>
                                <option className='form-control' value={FileShareAccessType.ReadOnlyAllLevels}>{stringifyAccessType(FileShareAccessType.ReadOnlyAllLevels)}</option>
                                {/*<option className='form-control' value={FileShareAccessType.ReadWrite}>{stringifyAccessType(FileShareAccessType.ReadWrite)}</option>
                                <option className='form-control' value={FileShareAccessType.ReadWriteAllLevels}>{stringifyAccessType(FileShareAccessType.ReadWriteAllLevels)}</option>*/}
                            </select>
                            :
                            stringifyAccessType(t.accessType)
                        }
                    </div>
                    <div className='col-sm-3'>{t.creatorName}</div>
                    <div className='col-sm-2'>{cleanDateString(t.createdAt)}</div>
                    <div className='col-sm-1'>
                        <span className='fas fa-edit btn' onClick={toggleEdit}></span>
                        <span className={chevClass} onClick={() => toggleExpanded(t.token)}></span>
                        <span className='fas fa-trash btn' onClick={() => expireToken(t.token)}></span>
                    </div>
                </div>
                {tokenExpanded && FileShareUserList(t.fileShareUsers, removeUser)}
            </React.Fragment>
        )
    }


    return (
        <div key={`${tokens.length}_${expanded.length}`}>
            <div className='pageHeader'>Fileshare Tokens</div>
            <hr />
            <MessageBox message={message} clearMessage={clearMessage} />
            <div className='token-list'>
                <div className='token-list-header' style={{ display: 'flex' }}>
                    <div className='col-sm-3'><b>Item Name</b></div>
                    <div className='col-sm-3'><b>Access Type</b></div>
                    <div className='col-sm-3'><b>Creator</b></div>
                    <div className='col-sm-3'><b>Shared At</b></div>
                </div>
                {tokens.map(x => FileShareTokenLine(x))}
            </div>
        </div>
    )
}

const FileShareUserList = (users: FileShareUser[], removeUser) => (
    <div className='token-users'>
        <div className='token-user-header' style={{ display: 'flex' }}>            
            <div className='col-sm-4'><b>Email</b></div>
            <div className='col-sm-8'><b>Last Access Timestamp</b></div>
        </div>
        {users.map(u => (
            <div className='token-user-line' key={u.userEmail} style={{ display: 'flex' }}>                
                <div className='col-sm-4'>{u.userEmail}</div>
                <div className='col-sm-7'>{u.lastAccessTime ? cleanDateString(u.lastAccessTime) : 'N/A'}</div>
                <div className='col-sm-1'>
                    <span className='fas fa-trash btn' onClick={() => removeUser(u.token, u.userEmail)}></span>
                </div>
            </div>
        ))}
    </div>
)

export const pathItemName = (path: string) => {
    const split = path.split('/');
    return split[split.length - 1];
}

export const stringifyAccessType = (accessType: FileShareAccessType) => {
    let str = '';
    switch (accessType) {
        case FileShareAccessType.ReadOnly:
            str = 'Read Only';
            break;
        case FileShareAccessType.ReadOnlyAllLevels:
            str = 'Read Only All Levels';
            break;
        case FileShareAccessType.ReadWrite:
            str = 'Read/Write';
            break;
        case FileShareAccessType.ReadWriteAllLevels:
            str = 'Read/Write All Levels';
            break;
    }

    return str;
}