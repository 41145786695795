import { AxiosError, AxiosResponse } from 'axios';
import { DfrPhoto } from 'src/interfaces/interfaces';
import { api } from '../api';

export const getProjectPhotos = async (
  projectId: number,
  pageSize: number,
  pageNumber: number
) => {
  try {
    const response: AxiosResponse<{ photos: DfrPhoto[]; total: number }> =
      await api.get(
        `api/DailyFieldReport/GetProjectPhotos?projectid=${projectId}&pageSize=${pageSize}&pageNumber=${pageNumber}`
      );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response) {
      return error.response.data as { message: string };
    }

    return undefined;
  }

  return undefined;
};
