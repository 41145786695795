import { AxiosResponse } from "axios";
import {
    ListItem,
   
} from "src/interfaces/interfaces";
import { api } from "../api";

export const getTeamMembers = async (projectId: number) => {
    try {
        const response: AxiosResponse = await api.get(`api/ProjectTeam/GetTeam?id=${projectId}&asListItem=true`);

        return response;        
    } catch {
        console.warn("error");
    }
    return false;
};
