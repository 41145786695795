import * as React from 'react';
import FilterableSelect from '../../components/FilterableSelect';
import { GlobalMessageBox } from '../../components/MessageBox';

import { ChecklistVm, ClientChecklistItemVm, ListItem } from '../../interfaces/interfaces';

export const ClientChecklistConfig = (props: { id: number }) => {
    const { id } = props;
    const [checklists, setChecklists] = React.useState([] as ClientChecklistItemVm[]);
    const [allLists, setAllLists] = React.useState([] as ChecklistVm[])
    const [showAddLine, setShowAddLine] = React.useState(false);
    const [editId, setEditId] = React.useState(null as number | null);

    const _getClientChecklists = () => {
        fetch(`api/Client/Checklists?id=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setChecklists(data);
            })
    }

    const _getAllLists = () => {
        fetch(`api/ChecklistConfig/Get`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setAllLists(data);
            })
    }

    const _add = () => {
        const selected = (document.querySelector('#add-checklist-select input[name="add-checklist-select"]') as HTMLInputElement).value;
        fetch(`api/Client/Checklist?clientId=${id}&listId=${selected}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setChecklists(data);
                    setShowAddLine(false);
                }
            })
    }

    const _delete = (id: number) => {
        fetch(`api/Client/Checklist?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setChecklists(data);
            })
    }

    React.useEffect(() => {
        _getAllLists();
    }, [])

    React.useEffect(() => {
        _getClientChecklists();
    }, [id])

    const gridLines = checklists.map(x => {
        if (x.id === editId) {
            //Do nothing
        } else {
            return (
                <div className='sv-grid-line'>
                    <div className='col-sm-11 truncate'>{x.title}</div>
                    <div className='sv-grid-buttons'>
                        {/*<span className='fas fa-pencil-alt' onClick={() => setEditId(x.id)}></span>*/}
                        <span className='fas fa-trash-alt' style={{ margin: '4px 8px' }} title='Delete this list from the client. All existing and active instances of this list on any projects will NOT be deleted.' onClick={() => _delete(x.id)}></span>
                    </div>
                </div>
            )
        }
    })

    const addLine = (
        <div className='sv-grid-line'>
            <div className='col-sm-5'>
                <FilterableSelect
                    items={allLists.filter(x => checklists.findIndex(y => y.id === x.id) === -1).map(x => new ListItem(x.id, x.title))}
                    id='add-checklist-select'
                />
            </div>
            <div className='col-sm-5'></div>
            <div className='sv-grid-buttons'>
                <div className='btn btn-sm btn-blue' onClick={_add}>Save</div>
                <div className='btn btn-sm btn-outline-secondary' onClick={() => setShowAddLine(false)}>Cancel</div>
            </div>
        </div>
    )

    return (
        <div className='config-form max-350'>
            <GlobalMessageBox />
            <div>
                <div className="form-header">
                    <h5 className="modal-title">Checklists</h5>
                </div>
                <div className='sv-grid'>
                    <div className='sv-grid-header'>
                        <div className='col-sm-5'>Title</div>
                        {/*<div className='col-sm-3'>Frequency</div>
                        <div className='col-sm-4'>Lock Timer</div>*/}
                        <div className='sv-grid-header-buttons'>
                            {id ? <span className='fas fa-plus btn btn-sm btn-blue' onClick={() => setShowAddLine(true)}></span> : []}
                        </div>
                    </div>
                    <div className='sv-grid-body'>
                        {gridLines}
                        {showAddLine && addLine}
                    </div>
                </div>
            </div>
        </div>
    )
}