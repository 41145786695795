import React, { useState, useEffect } from "react";
import FilterableSelect from "src/components/FilterableSelect";
import {
	ChecklistDetail,
	ChecklistDetailSection,
	ChecklistStatus,
	ListItem,
	ProjectTeamMember,
	UserInfo,
} from "src/interfaces/interfaces";
import styled from "styled-components";
import { ChecklistEntry } from "./ChecklistEntry";
import $ from "jquery";

interface ChecklistSectionProps {
	section: ChecklistDetailSection;
	updateFieldEntry: (id: number, value: string) => void;
	updateNotes: (id: number, value: string) => void;
	deleteFieldAttachment: (id: number) => void;
	uploadFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onSectionAssigneeAdd: (sectionId: number, userId: string) => void;
	projectTeamMembers: ProjectTeamMember[];
	user: UserInfo | undefined;
	checklist: ChecklistDetail;
	isProjectManager: boolean;
	isAllCollapsed: boolean;
}

const StyledDiv = styled.div({
	display: "flex",
	alignItems: "center",
});

const SectionHeaderDiv = styled.div({
	display: "flex",
	width: "100%",
	justifyContent: "space-between",
});

const SectionContentDiv = styled.div({});

const SectionDiv = styled.div({
	background: "#f5f5f5",
	padding: "10px",
	borderRadius: "10px",
	marginBottom: "10px",
});

const AssigneeDiv = styled.div({
	display: "flex",
	width: "100%",
	maxWidth: "calc(100% - 150px)",
	alignItems: "baseline",
});

const StyledH5 = styled.h5({
	marginBottom: "0px",
});

export const ChecklistSection: React.FC<ChecklistSectionProps> = ({
	section,
	updateFieldEntry,
	updateNotes,
	deleteFieldAttachment,
	uploadFiles,
	projectTeamMembers,
	onSectionAssigneeAdd,
	user,
	checklist,
	isProjectManager,
	isAllCollapsed,
}) => {
	const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

	const isChecklistLocked = checklist.status == ChecklistStatus.Approved;

	const canEditFields = user
		? user.userId == section.userId && !isChecklistLocked
		: false;

	useEffect(() => {
		setIsCollapsed(isAllCollapsed);
		if (isAllCollapsed) {
			$(".collapse").collapse("hide");
		} else {
			$(".collapse").collapse("show");
		}
	}, [isAllCollapsed]);
	return (
		<SectionDiv>
			<SectionHeaderDiv>
				<>
					<StyledDiv>
						<StyledH5>Section -</StyledH5>&nbsp;&nbsp;
						<StyledH5>{section.title}</StyledH5>
					</StyledDiv>
					<StyledDiv>
						<span
							data-toggle="collapse"
							data-target={`#section-content-${section.id}`}
							style={{ borderRadius: "50%" }}
							className={`btn btn-sm fas ${
								isCollapsed ? "fa-chevron-up" : "fa-chevron-down"
							} btn-background-hover`}
							onClick={() => {
								setIsCollapsed(!isCollapsed);
							}}
						></span>
					</StyledDiv>
				</>
			</SectionHeaderDiv>

			<SectionContentDiv
				id={`section-content-${section.id}`}
				className="collapse show"
			>
				<div className="form-group">
					<small>{section.subtitle}</small>
				</div>
				<div className="checklist-field">
					{" "}
					<AssigneeDiv className="form-group">
						<div className="inline-label">Assigned To</div>
						{(user?.userId === checklist.userId || isProjectManager) &&
						!isChecklistLocked ? (
							<FilterableSelect
								id={`section-assigned-to-${section.id}`}
								items={projectTeamMembers.map(
									(x) => new ListItem(x.userId, x.userFullName)
								)}
								onChange={(id) => onSectionAssigneeAdd(section.id, id)}
								defaultVal={section.userId ?? undefined}
							/>
						) : (
							<input
								disabled
								className="form-control"
								defaultValue={
									projectTeamMembers.find((x) => x.userId == section.userId)
										?.userFullName
								}
							></input>
						)}
					</AssigneeDiv>
				</div>
				{section.fieldEntries.map((x) => (
					<ChecklistEntry
						key={x.id}
						section={section}
						entry={x}
						canEditFields={canEditFields}
						updateFieldEntry={updateFieldEntry}
						updateNotes={updateNotes}
						deleteFieldAttachment={deleteFieldAttachment}
						uploadFiles={uploadFiles}
					/>
				))}
			</SectionContentDiv>
		</SectionDiv>
	);
};
