import * as React from 'react';
import Modal from '../components/Modal';

export interface GuideModalProps {
    close: () => void;
}

export const SubmittalGuideModal = (props: GuideModalProps) => {

    return (
        <Modal modalClass='guide-modal'>
            <div className='modal-body custom-scrollbar' style={{maxHeight: '65vh', overflowY: 'auto' }}>
                <h5>Statuses</h5>
                <p>When a submittal is in <b style={{ color: 'rgba(244, 208, 63, 0.8)' }}>Draft</b>, you must make sure both a document and reviewer are provided. If you wish to change the file or reviewer, first hit the 'Save Changes' button</p>
                <img style={{ border: '1px solid gray', marginBottom: '10px', padding: '5px' }} src='images/SubmittalChanges_Guide.png' />
                <p>and then subsequently hit the 'Submit' button</p>
                <img style={{ border: '1px solid gray', marginBottom: '10px', padding: '5px' }} src='images/SubmittalSubmit_Guide.png' /> 
                <p>When a submittal is in <b style={{ color: 'rgba(27, 87, 230, 0.8)' }}>Submitted</b> or <b style={{ color: 'rgba(244, 208, 63, 0.8)' }}>Creator Review</b>, the owner of the submittal can edit the contents, including the documents and reviewer.</p>
                <p>If you are the assigned reviewer, you can forward the submittal to another party by changing the reviewer and hitting the 'Forward' button</p>
                <img style={{ border: '1px solid gray', marginBottom: '10px', padding: '5px' }} src='images/SubmittalForward_Guide.png' /> 
                <p>A submittal that has been marked <b style={{ color: 'rgba(0, 150, 0, 0.8)' }}>Reviewed</b>, <b style={{ color: 'rgba(230, 97, 97, 0.8)' }}>Rejected</b>, or <b style={{ color: 'rgba(170, 97, 230, 0.8)' }}>Voided</b> <u>cannot be edited or re-opened</u>. Reviewed or Rejected submittals can be voided, and all submittals can be copied into a new submittal at any time. The new, copied submittal will provide a label indicating that it was copied from another submittal.</p>
                <hr />
                <h5>Review Options</h5>
                <p>When reviewing the submittal, you can either 'Sign and Submit' or 'Sign and Forward'; use the latter when you wish to provide your response but also need to pass the submittal along to another party for additional review. If you choose to forward the submittal, the status will remain 'Submitted'.</p>
                <p>Please be aware of the following requirements and workflows associated with a few signing options</p>
                <ul>
                    <li><b>Submit Specified Item:</b> This option requires that you upload at least one response document, and can be used to either Submit or Forward to another party. </li>
                    <li><b>Review and Resubmit:</b> This option will send the submittal back to the originator (unless you select 'Sign and Forward'), and the status will say 'Creator Review'.</li>
                </ul>                  
                <hr />
                <h5>Responses</h5>
                <hr />
                <p>Responses will appear in a table on the submittal detail page. Each response will include the documents the reviewer uploads. Additionally, the reviewer will have the option to include original submittal documents on their response. These documents will also appear in the response table.</p>                                
                <h5>Revisions</h5>
                <p>When a reviewer selects <b>Review and Resubmit</b>, the submittal remains open and is sent back to the owner in the <b style={{ color: 'rgba(244, 208, 63, 0.8)' }}>Creator Review</b> status. The previous documents, as well as any supporting documents the reviewer uploaded, are attached to an earlier revision response. These responses will appear in a table at the top of the submittal detail page. </p>
                <p>The original response will have a revision number of zero. Every subsequent version of the submittal will be reflected in the submittal number, in the format of "SUB-[[Submittal Number]]-[[Revision Number]]</p>
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
            </div>
        </Modal>
    )
}