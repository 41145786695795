import styled from "styled-components";

export const FilterableSelectContainer = styled.div<{
	ulWidth: string;
	top: string;
}>((props) => ({
	"& ul": {
		position: "fixed",
		top: props.top,
		width: props.ulWidth,
	},
}));


//export const FilterableSelectContainer = styled.div.attrs((props: { ulWidth: string, top: string }) => ({
//	export const FilterableSelectContainer = styled.div((props: { ulWidth: string, top: string }) => ({
//	//style: {
//	/*	"& ul": {*/
//			//position: "fixed",
//			//top: props.top,
//			//width: props.ulWidth,
//		//},
//	//}
//}));