import * as React from 'react'
import * as DetailStore from '../store/projectDetail'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import { ProjectLink } from '../interfaces/interfaces';
import { UserAuthElement } from '../auth/Authorization';
import Modal from '../components/Modal';

type Props =
    any
    & DetailStore.Store
    & typeof DetailStore.actionCreators;

interface State {
    showAddLinkModal: boolean,
    hoveredLinkId?: number,
    editLinkId?: number
}

class ProjectLinks extends React.Component<any, State> {
    constructor(props) {
        super(props)
        this.state = {
            showAddLinkModal: false
        }
    }

    _addNew = () => {
        const url = (document.getElementById('new-link-url') as HTMLInputElement).value,
            display = (document.getElementById('new-link-display') as HTMLInputElement).value

        if (url.length === 0) alert('You must enter a url');
        else {
            this.props.addLink(url, display);
            this.setState({ showAddLinkModal: false })
        }
    }

    _edit = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const id = this.state.editLinkId,
            url = (document.getElementById('edit-link-url') as HTMLInputElement).value,
            display = (document.getElementById('edit-link-display') as HTMLInputElement).value

        if (url.length === 0) alert('You must enter a url');
        else {
            this.props.editLink(id, url, display)
            this.setState({ editLinkId: undefined })
        }
    }

    _deleteLink = () => {

    }

    _toggleAddModal = () => this.setState({ showAddLinkModal: !this.state.showAddLinkModal })

    _toggleEdit = (e: React.MouseEvent<HTMLElement>, id?: number) => {
        e.stopPropagation();
        this.setState({ editLinkId: id })
    }

    _hover = (id: number) => this.setState({ hoveredLinkId: id })

    _endHover = () => this.setState({ hoveredLinkId: undefined })

    render() {
        const { links } = this.props.detail,
            { showAddLinkModal } = this.state

        const linkList = links ? this.renderLinkList(links) : []

        return (
            <div className='project-links'>
                <div className='links-header'>
                    Project Links
                    <span className='btn btn-sm btn-blue fas fa-plus' onClick={this._toggleAddModal} title='Add New Link'></span>
                </div>
                <hr />
                {linkList}
                {showAddLinkModal && this.renderAddLinkModal()}
            </div>
        )

    }

    renderLinkList(links: ProjectLink[]) {
        const { hoveredLinkId, editLinkId } = this.state,
            PmComponent = UserAuthElement([this.props.detail.internalPmId]),
            linkEles = links.map(x => {
                const hovered = hoveredLinkId === x.id,
                    editing = editLinkId === x.id
                return (
                    <div key={x.id} className='link-row' onMouseEnter={this._hover.bind(null, x.id)}
                        onMouseLeave={this._endHover.bind(null, x.id)}
                    >
                        {
                            editing ?
                                <div className='edit-link'>
                                    <input id='edit-link-url' defaultValue={x.url} />
                                    <input id='edit-link-display' defaultValue={x.displayName} />
                                    <button title='Save' className='btn btn-sm btn-blue fas fa-check' onClick={(e) => this._edit(e)}></button>
                                    <button title='Cancel' className='btn btn-sm btn-outline-secondary fas fa-ban' onClick={(e) => this._toggleEdit(e, undefined)}></button>
                                </div> :
                                <a target="_blank" href={x.url} data-id={x.id}>{x.displayName}</a>
                        }
                        {
                            (hovered && !editing) &&
                            <PmComponent>
                                <div className='hover-options'>
                                    <span className='fas fa-edit btn' title='Edit' onClick={(e) => this._toggleEdit(e, x.id)}></span>
                                    {/*<button className='glyphico fa-trash' title='Delete'></button>*/}
                                </div>
                            </PmComponent>
                        }
                    </div>
                )
            })

        return (
            <div className='link-list'>
                {linkEles}
            </div>
        )
    }

    renderAddLinkModal() {
        return (
            <Modal>
                <div className="modal-header">
                    <div style={{ fontSize: '18px' }}>Add New Project Link</div>
                </div>
                <div className='modal-body'>
                    <label>URL</label>
                    <input className='form-control' id='new-link-url' />
                    <br />
                    <label>Display</label><br /><small>Text for the link to display as</small>
                    <input className='form-control' id='new-link-display' />
                </div>
                <div className='modal-footer'>
                    <span className="btn btn-sm btn-outline-secondary btn-blue" onClick={this._addNew}>Add</span>
                    <span className="btn btn-sm btn-outline-secondary btn-outline-secondary" onClick={this._toggleAddModal}>Close</span>
                </div>
            </Modal>
        )
    }
}
    //@ts-ignore
export default connect<DetailStore.Store, typeof DetailStore.actionCreators, any>(
        //@ts-ignore
    (state: ApplicationState) => state.detail,
    DetailStore.actionCreators
)(ProjectLinks) as typeof ProjectLinks