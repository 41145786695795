
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface KeyVaultState {
  partnerRegKey: string | undefined;
}

export const initialState: KeyVaultState = {
  partnerRegKey: undefined,
};

const {
  actions: KeyVaultActions,
  reducer: KeyVaultReducer,
} = createSlice({
  name: "keyvault",
  initialState,
  reducers: {
    getPartnerRegistrationKey() { },
    setPartnerRegistrationKey(
      state,
      action: PayloadAction<{ key: string }>
    ) {
      state.partnerRegKey = action.payload.key;
    },
  },
});

export { KeyVaultActions, KeyVaultReducer };

export default KeyVaultReducer;
