import * as React from "react";
import cx from "classnames";
import { BBox } from "geojson";

import { K_CIRCLE_SIZE, helpers } from "./mapHelpers";
import { ListItem, ProjectVm, WorkRegion } from "../interfaces/interfaces";
import SimpleMarker from "./SimpleMarker";
import { Map } from "./Map";
import useSupercluster from "./useSupercluster";
import { MarkerCluster } from "./MarkerCluster";
import Modal from "../components/Modal";
import { actionCreators as projectActions } from "../store/projects";
import { useDispatch, useSelector } from "react-redux";
import {
	SVDateRangePicker,
	dateRangeToString,
} from "../components/SVDateRangePicker";
import { DateRange } from "moment-range";
import { ApplicationState } from "../store";

interface Props {
	projects: ProjectVm[];
	navigate?: (id: any) => void;
	filter: (e: React.ChangeEvent<HTMLInputElement>) => void;
	globalFilter?: any;
}

interface State {
	firstVisibleRow: number;
	lastVisibleRow: number;
	hoveredRowIndex: number;
}

export const MapWithTable = (props: Props) => {
	//export default class MapWithTable extends React.Component <Props, State> {

	const [tableState, setTableState] = React.useState({
		firstVisibleRow: 0,
		lastVisibleRow: 7,
		hoveredRowIndex: -1,
	} as State);
	const [hoveredId, setHoveredId] = React.useState(0);
	const [regions, setRegions] = React.useState([] as WorkRegion[]);
	const [bounds, setBounds] = React.useState<BBox>(null);
	const [showFilters, setShowFilters] = React.useState(false);
	const [zoom, setZoom] = React.useState(4);

	const dispatch = useDispatch();
	const projectStore = useSelector((s: ApplicationState) => s.projects);

	const _clearFilters = () => {
		// This query selector is written specifically to skip SVDateRangePicker elements, as those will handle clearing on their own
		//const filterInputs = document.querySelectorAll('.project-filter-modal .form-group > div > .form-control');
		//[].forEach.call(filterInputs, (i: HTMLInputElement) => {
		//    i.value = '';
		//})
		dispatch(projectActions.clearFilters());
	};

	const _handleDateFilter = (dateProp: string, range?: DateRange) => {
		dispatch(projectActions.filter(dateProp, range || null, "date"));
	};

	const _navigate = (id: number) => {
		if (props.navigate) props.navigate(id);
	};

	const _tableRowMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget) {
			setTableState({
				...tableState,
				hoveredRowIndex: parseInt(
					e.currentTarget.getAttribute("data-key") || "0"
				),
			});
		}
	};

	const _tableRowMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
		setTableState({
			...tableState,
			hoveredRowIndex: -1,
		});
	};

	const _markerHovered = (id: number) => {
		setTableState({ ...tableState, hoveredRowIndex: id });
	};
	const { projects } = props;
	const mapPoints =
		projects &&
		projects.map((p) => ({
			type: "Feature",
			properties: {
				cluster: false,
				id: p.id,
				label: p.name,
				bgColor: p.clientColor || "#005a78",
			},
			geometry: {
				type: "Point",
				coordinates: [p.longitude, p.latitude],
			},
		}));

	// get clusters
	const { clusters, supercluster } = useSupercluster({
		points: mapPoints,
		bounds,
		zoom,
		options: { radius: 100, maxZoom: 12 },
	});

	const mapMarkers = clusters.map((x) => {
		const [longitude, latitude] = x.geometry.coordinates;
		const {
			cluster: isCluster,
			point_count: pointcount,
			id: id,
			label: label,
			bgColor: bgColor,
		} = x.properties;
		if (isCluster) {
			return (
				<MarkerCluster
					key={x.id}
					id={x.id}
					lat={latitude}
					lng={longitude}
					pointCount={pointcount}
					totalPointCount={mapPoints.length}
				/>
			);
		}
		return (
			<SimpleMarker
				key={id}
				id={id}
				label={label}
				lat={latitude}
				lng={longitude}
				hoveredAtTable={id === hoveredId}
				zoom={zoom}
				bgColor={bgColor}
			/>
		);
	});

	const renderMapTableRow = (project: ProjectVm) => {
		const hoverClass =
			tableState.hoveredRowIndex == project.id ? "map-table-row-hovered" : "";

		return (
			<div
				className={cx("project-row row detail-link", hoverClass)}
				key={project.projectNumber}
				data-key={project.id.toString()}
				onMouseEnter={_tableRowMouseEnter}
				onMouseLeave={_tableRowMouseLeave}
				onClick={() => _navigate(project.id)}
			>
				<div className="project-info">
					<div className="project-number-label truncate">
						<b>{project.projectNumber}</b>
					</div>
					<div className="project-detail-label truncate">
						{project.name}, {project.city}, {project.state}
					</div>
				</div>
			</div>
		);
	};

	const filterModal = (
		<Modal modalClass="project-filter-modal">
			<div className="modal-header">
				<h5>Filter Projects</h5>
			</div>
			<div className="modal-body">
				{FilterProps.map((x) => {
					let defaultValue: any = x.type == "date" ? null : "";
					if (projectStore.filterOptions) {
						const thisFilter = projectStore.filterOptions.find(
							(f) => f.prop === x.id
						);
						if (thisFilter) defaultValue = thisFilter.value;
					}
					return (
						<div className="form-group" key={x.id}>
							<div>
								<b>{x.value}</b>
							</div>
							<div>
								{x.type === "string" ? (
									<input
										className="form-control"
										name={x.id}
										onChange={props.filter}
										value={defaultValue?.toString()}
									/>
								) : (
									<SVDateRangePicker
										onChange={(range) => _handleDateFilter(x.id, range)}
										className="form-control"
										defaultRange={
											defaultValue ? (defaultValue as DateRange) : undefined
										}
									/>
								)}
							</div>
						</div>
					);
				})}
			</div>
			<div className="modal-footer">
				<button
					className="btn btn-sm btn-outline-secondary"
					onClick={_clearFilters}
				>
					Clear
				</button>
				<button
					className="btn btn-sm btn-outline-secondary"
					onClick={() => setShowFilters(false)}
				>
					Close
				</button>
			</div>
		</Modal>
	);

	//const tableHoveredIndex = -1 // figure out how to get table hoverered index or 0
	const tableRows = projects && projects.map((p) => renderMapTableRow(p));
	return (
		<div style={{ height: "72vh", display: "flex" }}>
			<div
				style={{ height: "100%", width: "75%" }}
				className="mobile-full-width"
			>
				<Map
					mapMarkers={mapMarkers}
					handleChange={(z, b) => {
						setZoom(z);
						setBounds([b.nw.lng, b.se.lat, b.se.lng, b.nw.lat]);
					}}
					zoom={4}
					hoveredRowKey={tableState.hoveredRowIndex}
					maxVisibleRows={12}
					visibleRowFirst={tableState.firstVisibleRow}
					visibleRowLast={tableState.lastVisibleRow}
					navigate={props.navigate}
					onMarkerHover={_markerHovered}
				/>
			</div>
			<div style={{ width: "25%" }}>
				<div
					style={{
						display: "flex",
						padding: "10px 0px 10px 25px",
						borderBottom: "1px solid gray",
						marginLeft: "10px",
					}}
				>
					<div style={{ width: "calc(100% - 60px)" }}>
						{projectStore.filterOptions && projectStore.filterOptions.length ? (
							projectStore.filterOptions.map((x) => (
								<div
									style={{ marginBottom: "0px", lineHeight: "3" }}
									className="truncate"
								>
									<b>{FilterProps.find((f) => f.id === x.prop)?.value}:</b>{" "}
									&nbsp;
									<span>
										{x.dataType === "date" && x.value
											? dateRangeToString(x.value as DateRange)
											: x.value.toString()}
									</span>
								</div>
							))
						) : (
							<p style={{ marginBottom: "0px", lineHeight: "3" }}>
								No filters applied
							</p>
						)}
					</div>
					<p
						className="truncate"
						style={{
							width: "calc(100% - 60px)",
							marginBottom: "0px",
							lineHeight: "3",
						}}
					></p>
					<button
						className="btn btn-x-sm btn-outline-secondary"
						onClick={() => setShowFilters(true)}
						style={{ height: "36px" }}
					>
						Filters
					</button>
				</div>
				<div className="map-table mobile-hide custom-scrollbar">
					{tableRows}
				</div>
			</div>
			{showFilters && filterModal}
		</div>
	);
};

const FilterProps = [
	{ id: "projectNumber", value: "Project Number", type: "string" },
	{ id: "name", value: "Project Name", type: "string" },
	{ id: "clientName", value: "Client Name", type: "string" },
	{ id: "division", value: "Division", type: "string" },
	{ id: "internalPM", value: "Project Manager", type: "string" },
	{ id: "startDate", value: "Start Date", type: "date " },
	{ id: "endDate", value: "End Date", type: "date " },
];
