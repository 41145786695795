import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../store/global';
import { ApplicationState } from '../store';

interface Props {
    message: string | undefined,
    errors?: any[]
    clearMessage: any
}

interface State {
    isError: boolean
    display: boolean
}

export class MessageBox extends React.Component<Props, State> {

    private messageDiv: HTMLDivElement | null | undefined;
    private _isMounted: boolean;

    constructor(props: Props) {
        super(props);
        this.state = {           
            isError: false,
            display: true
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillReceiveProps(nextProps: Props) {
        /* When the parent emits an updated message we need to update the state to get the change */
        this.setState({
            isError: nextProps.errors ? true :
                nextProps.message ?
                    nextProps.message.toUpperCase().indexOf("ERROR") !== -1 :
                    false,
            display: (nextProps.message !== undefined && nextProps.message.length > 0)
        })
    }

    componentDidUpdate(prevProps: Props) {
        // Message property changes between SAVING message and SAVED message; A change in this value indicates we need to display the message
        if (this.props.message && this.props.message.length) {
            if (this.messageDiv) {
                this.messageDiv.style.opacity = "1";
                if (!this.state.isError) { // Non-error messages will fade out
                    const myTimer = setInterval(this.fadeEffect.bind(null, this.messageDiv), 35);
                    setTimeout(() => {
                        clearInterval(myTimer);
                        this._clear();
                        this.setState({
                            display: false
                        })
                    }, 1500);
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _clear = (e?: React.MouseEvent<HTMLSpanElement>) => {
        if (this._isMounted) {
            this.props.clearMessage(e)
        }
    }

    private fadeEffect(fadeDiv: HTMLDivElement) {
        if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0.66) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.01).toString();
        } else if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0.33) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.03).toString();
        } else if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.05).toString();
        } 
    }

    render() {
        const { message, errors } = this.props;
        if ((message || errors) && this.state.display) {
            let myStyle = {};
            if (this.state.isError) {
                myStyle = { 'backgroundColor': 'red' };
                let innerContent: any;
                if (errors) {
                    const errList: JSX.Element[] = [];
                    for (const key in errors) {
                        errList.push(<div key={key}>{key}: {errors[key]}</div>)
                    }                    
                    innerContent = <div><div>One or more errors were encountered: </div>{errList}</div>
                }
                else innerContent = this.props.message;
                return (
                    <div style={myStyle} className='state-message'
                        ref={c => this.messageDiv = c}
                    >
                        <span className='fas fa-times' onClick={this._clear}></span>
                        {innerContent}
                    </div>)
            }
            return <div className='state-message' ref={c => this.messageDiv = c}>{this.props.message}</div>
        }
        return <div ref={c => this.messageDiv = c}></div>
    }
}


export const GlobalMessageBox = () => {
    const [state, setState] = React.useState({ isError: false, display: true } as State)
    const store = useSelector((s: ApplicationState) => s.global);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const { message } = store;
        const err = message ? message.toUpperCase().indexOf("ERROR") !== -1 : false;
        setState({ isError: err, display: message !== undefined && message.length > 0 })

        if (message && message.length) {
            if (messageDiv) {
                messageDiv.style.opacity = "1";
                if (!err) { // Non-error messages will fade out
                    const myTimer = setInterval(_fadeEffect.bind(null, messageDiv), 35);
                    setTimeout(() => {
                        clearInterval(myTimer);
                        _clear();
                        setState({ ...state, display: false });
                    }, 1500);
                }
            }
        }
    }, [store.message])

    const _clear = (e?: React.MouseEvent<HTMLSpanElement>) => {
        dispatch(actionCreators.clearMessage());
    }

    const _fadeEffect = (fadeDiv: HTMLDivElement) => {
        if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0.66) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.01).toString();
        } else if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0.33) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.03).toString();
        } else if (fadeDiv.style.opacity !== null && parseFloat(fadeDiv.style.opacity) > 0) {
            fadeDiv.style.opacity = (parseFloat(fadeDiv.style.opacity) - 0.05).toString();
        }
    }

    let messageDiv: HTMLDivElement | null | undefined;
    const _isMounted = false;

    const { message } = store;
    if ((message) && state.display) {
        let myStyle = {};
        if (state.isError) {
            myStyle = { 'backgroundColor': 'red' };
            const innerContent: any = message;
            //if (errors) {
            //    let errList: JSX.Element[] = [];
            //    for (let key in errors) {
            //        errList.push(<div key={key}>{key}: {errors[key]}</div>)
            //    }
            //    innerContent = <div><div>One or more errors were encountered: </div>{errList}</div>
            //}
            //else innerContent = message;
            return (
                <div style={myStyle} className='state-message'
                    ref={c => messageDiv = c}
                >
                    <span className='fas fa-times' onClick={_clear}></span>
                    {innerContent}
                </div>)
        }
        return <div className='state-message' ref={c => messageDiv = c}>{message}</div>
    }
    return <div ref={c => messageDiv = c}></div>

}