import * as React from "react";
import Modal from "../../components/Modal";
import {
	ChecklistConditionType,
	ChecklistDetail,
	ChecklistDetailVm,
	ChecklistFieldType,
	ChecklistFunction,
	ChecklistTemplateField,
	ChecklistType,
	ChecklistVm,
	ListItem,
} from "../../interfaces/interfaces";
import { ChecklistContext } from "./ChecklistHome";
import { JoinedList } from "../../components/JoinedList";
import { ProjectRole } from "../global/ProjectRoles";
import { NewChecklistSectionComponent } from "./NewChecklistSectionComponent";
import styled from "styled-components";
import { MessageBox } from "src/components/MessageBox";
import FilterableSelect from "src/components/FilterableSelect";
import ChecklistFunctionAddModal from "./ChecklistFunctionAddModal";
import {
	addFieldCondition,
	addFieldOption,
	deinheritChecklistTemplate,
	inheritChecklistTemplate,
	removeFieldCondition,
	removeFieldOption,
	updateFieldCondition,
	updateFieldOption,
} from "src/services/checklists";
import { Loader } from "src/components/Loader";

export interface DetailModalApi {
	addField: (
		t: ChecklistFieldType,
		sectionId: number,
		templateId: number
	) => void;
	copyField: (id: number) => void;
	updateField: (
		id: number,
		prop: string,
		value: any,
		templateId: number,
		sectionId: number
	) => void;
	deleteField: (field: ChecklistTemplateField) => void;
	addFieldOption: (
		fieldId: number,
		sectionId: number,
		templateId: number
	) => void;
	updateFieldOption: (
		fieldOptionId: number,
		sectionId: number,
		templateId: number,
		text: string
	) => void;
	removeFieldOption: (fieldOptionId: number, templateId: number) => void;
	addFieldCondition: (
		fieldId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => void;
	updateFieldCondition: (
		fieldConditionId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => void;
	removeFieldCondition: (fieldConditionId: number, templateId: number) => void;
	fields: ChecklistTemplateField[];
}

export const DetailModalContext = React.createContext({} as DetailModalApi);

const StyledInput = styled.input({
	":invalid": {
		borderColor: "#dc3545",
		":focus": { boxShadow: "0 0 0 0.25rem rgb(220 53 69 / 25%)" },
	},
});

const StyledDiv = styled.div({
	display: "flex",
	width: "100%",
	maxWidth: "calc(100% - 150px)",
	"& #function-type": {
		width: "100%",
	},
});

export const DetailModal = (props: {
	id: number | null;
	users: ListItem[];
	chosenChecklist: ChecklistDetailVm;
	checklistFunctions: ListItem[];
	onFunctionAdd: (type: string) => void;
	onEditChecklistFunction: (listId: number, functionId: number) => void;
	checklists: ChecklistVm[];
	onChecklistInherit: () => void;
	onChecklistCopy: (id: number) => void;
}) => {
	const api = React.useContext(ChecklistContext);
	const editing = props.id !== null;

	//const dispatch = useDispatch();
	const [checklist, setChecklistData] = React.useState({} as ChecklistDetailVm);
	const [projectRoles, setProjectRoles] = React.useState([] as ProjectRole[]);
	const [message, setMessage] = React.useState<string>("");
	const [showFunctionAddModal, setShowFunctionAddModal] =
		React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(false);

	const _getProjectRoles = () => {
		fetch(`api/GlobalConfig/ProjectRoles`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setProjectRoles(data);
			});
	};

	const _save = () => {
		api.refreshLists();
		if (editing) api.toggleEditId(null);
	};

	const _close = () => {
		if (editing) api.toggleEditId(null);
	};

	const _addField = (
		t: ChecklistFieldType,
		sectionId: number,
		templateId: number
	) => {
		fetch(
			`api/ChecklistConfig/AddField?sectionId=${sectionId}&templateId=${templateId}&type=${t}`,
			{
				method: "POST",
			}
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setChecklistData(data);
				}
			});
	};

	const _copyField = (id: number) => {
		fetch(`api/ChecklistConfig/CopyField?id=${props.id}`, { method: "POST" })
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setChecklistData(data);
			});
	};

	const _updateField = (
		id: number,
		prop: string,
		value: any,
		templateId: number,
		sectionId: number
	) => {
		fetch(
			`api/ChecklistConfig/EditField?id=${id}&templateId=${templateId}&sectionId=${sectionId}`,
			{
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ fieldName: prop, value: value }),
			}
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setChecklistData(data);
				else {
					alert(data.message);
				}
			});
	};

	const _updateList = (prop: string, value: any) => {
		fetch(`api/ChecklistConfig/EditList?id=${props.id}`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ fieldName: prop, value: value }),
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setChecklistData(data);
					api.refreshLists();
				}
			});
	};

	const _updateEditors = (items: ListItem[]) => {
		const ids = items.map((x) => x.id).join(",");

		if (ids === "") {
			setMessage("Error: Each checklist must have at least one editor");
		} else {
			fetch(`api/ChecklistConfig/EditList?id=${props.id}`, {
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ fieldName: "editorIds", value: ids }),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setChecklistData(data);
						api.refreshLists();
					} else setMessage(data.message);
				});
		}
	};

	const _inheritTemplates = async (items: ListItem[]) => {
		setLoading(true);
		const ids = items.map((x) => +x.id);

		const parentWithGrandParentTitles = props.checklists
			.filter((x) => ids.includes(+x.id))
			.reduce((arr2: string[], x) => {
				if (x.parentTemplateIds.length > 0) arr2.push(x.title);
				return arr2;
			}, []);

		const childChecklist = props.checklists.find((x) => x.id == checklist.id);

		if (
			childChecklist &&
			childChecklist.childTemplateIds.length > 0 &&
			parentWithGrandParentTitles.length > 0
		) {
			setMessage(
				`Error: The chosen checklists can not be inherited due to existing inheritance relationships. Please remove ${parentWithGrandParentTitles.join(
					", "
				)} and try again.`
			);
		} else {
			const newChecklist = await inheritChecklistTemplate(ids, checklist.id);

			if (newChecklist) {
				setChecklistData(newChecklist);
				props.onChecklistInherit();
			} else {
				setMessage(
					"Error: Unable to inherit template(s). Please try again later."
				);
			}
		}

		setLoading(false);
	};

	const _deinheritTemplates = async (items: ListItem[]) => {
		setLoading(true);

		const ids = items.map((x) => +x.id);

		const childIds = props.checklists
			.filter((x) => ids.includes(+x.id))
			.reduce((arr2: number[], x) => {
				arr2.push(...x.childTemplateIds);
				return arr2;
			}, []);

		const missingChecklists = selectedChecklists
			.filter((x) => !ids.includes(+x.id))
			.reduce((arr: string[], x) => {
				if (childIds.includes(+x.id)) {
					arr.push(x.value);
				}
				return arr;
			}, []);

		if (missingChecklists.length > 0) {
			setMessage(
				`Error: Not a direct inheritance. Please remove ${missingChecklists.join(
					", "
				)} as well.`
			);
		} else {
			const newChecklist = await deinheritChecklistTemplate(ids, checklist.id);

			if (newChecklist) {
				setChecklistData(newChecklist);
				props.onChecklistInherit();
			} else {
				setMessage(
					"Error: Unable to deinherit template(s). Please try again later."
				);
			}
		}
		setLoading(false);
	};

	const _updateRoles = (roles: ListItem[]) => {
		const qString =
			`listId=${checklist.id}&roleIds=` +
			roles.map((x) => x.id).join("&roleIds=");
		fetch(`api/ChecklistConfig/EditRoles?${qString}`, { method: "PUT" })
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setChecklistData({ ...checklist, roles: data });
					api.refreshLists();
				}
			});
	};

	const _deleteField = (field: ChecklistTemplateField) => {
		fetch(
			`api/ChecklistConfig/DeleteField?templatedId=${props.id}&sectionId=${field.checklistTemplateSectionId}&fieldId=${field.id}`,
			{ method: "DELETE" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setChecklistData(data);
			});
	};

	const _handleOnFunctionAdd = (type: string) => {
		props.onFunctionAdd(type);
		setMessage("Sucessfully Added");
	};

	const _handleOnChecklistCopy = (id: number) => {
		_close();
		props.onChecklistCopy(id);
	};

	const _handleOnFieldOptionAdd = async (
		fieldId: number,
		sectionId: number,
		templateId: number
	) => {
		const checklist = await addFieldOption(fieldId, sectionId, templateId);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	const _handleOnFieldOptionRemove = async (
		fieldOptionId: number,
		templateId: number
	) => {
		const checklist = await removeFieldOption(fieldOptionId, templateId);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	const _handleOnFieldOptionUpdate = async (
		fieldOptionId: number,
		sectionId: number,
		templateId: number,
		text: string
	) => {
		const checklist = await updateFieldOption(
			fieldOptionId,
			sectionId,
			templateId,
			text
		);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	const _handleOnFieldConditionAdd = async (
		fieldId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => {
		const checklist = await addFieldCondition(
			fieldId,
			sectionId,
			templateId,
			value,
			type
		);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	const _handleOnFieldConditionUpdate = async (
		fieldConditionId: number,
		sectionId: number,
		templateId: number,
		value: string,
		type: ChecklistConditionType
	) => {
		const checklist = await updateFieldCondition(
			fieldConditionId,
			sectionId,
			templateId,
			value,
			type
		);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	const _handleOnFieldConditionRemove = async (
		fieldConditionId: number,
		templateId: number
	) => {
		const checklist = await removeFieldCondition(fieldConditionId, templateId);
		if (checklist) {
			setChecklistData(checklist);
		}
	};

	React.useEffect(() => {
		_getProjectRoles();
		if (editing) {
			setChecklistData(props.chosenChecklist);
		}
	}, []);

	const detailApi = {
		addField: _addField,
		copyField: _copyField,
		updateField: _updateField,
		deleteField: _deleteField,
		addFieldOption: _handleOnFieldOptionAdd,
		removeFieldOption: _handleOnFieldOptionRemove,
		updateFieldOption: _handleOnFieldOptionUpdate,
		addFieldCondition: _handleOnFieldConditionAdd,
		updateFieldCondition: _handleOnFieldConditionUpdate,
		removeFieldCondition: _handleOnFieldConditionRemove,

		fields: checklist.fields,
	} as DetailModalApi;

	if (!checklist || !checklist.id) {
		return <Modal></Modal>;
	}

	const selectedRoles = checklist.roles
		? checklist.roles.map((x) => new ListItem(x.id, x.roleName))
		: [];
	const availableRoles = projectRoles
		.filter((x) => selectedRoles.findIndex((y) => y.id === x.id) === -1)
		.map((x) => new ListItem(x.id, x.roleName));

	const selectedEditors = checklist.editors;

	const availableEditors = props.users.filter(
		(x) => !selectedEditors.includes(x)
	);

	const availableChecklists = props.checklists
		.filter(
			(x) =>
				x.isInheritable === true &&
				x.id != checklist.id &&
				!checklist.childTemplateIds.includes(x.id) &&
				!(
					checklist.childTemplateIds.length > 0 &&
					x.parentTemplateIds.length > 0
				)
		)
		.map((x) => new ListItem(x.id, x.title));

	const selectedChecklists = availableChecklists.filter((x) =>
		checklist.parentTemplateIds.includes(+x.id)
	);

	return (
		<Modal modalClass="checklist-template-modal">
			<Loader loading={loading} />
			<MessageBox message={message} clearMessage={() => setMessage("")} />
			<DetailModalContext.Provider value={detailApi}>
				<div className="modal-header">
					<h5>{editing ? `Edit ${checklist.title}` : "New Checklist"}</h5>
					<span
						onClick={() => _handleOnChecklistCopy(checklist.id)}
						className="btn btn-sm btn-outline-secondary"
						title="Copy checklist"
						style={{ fontSize: "12px" }}
					>
						<span className="fa fa-clipboard"></span>
						{" "}
						Copy
					</span>
				</div>
				<div className="modal-body custom-scrollbar">
					<div className="form-group">
						<div className="inline-label">Title</div>
						<StyledInput
							required
							className="form-control"
							onBlur={(e) => _updateList("title", e.currentTarget.value)}
							defaultValue={checklist.title}
						/>
					</div>
					<div className="form-group">
						<div className="inline-label">Renamable</div>
						<label className="switch">
							<input type="checkbox" defaultChecked={checklist.renamable} />
							<span
								className="slider round"
								onClick={() => _updateList("renamable", !checklist.renamable)}
							></span>
						</label>
					</div>
					<div className="form-group">
						<div className="inline-label">Module</div>
						<select
							className="form-control"
							onChange={(e) => _updateList("type", e.currentTarget.value)}
							defaultValue={checklist.type}
						>
							<option
								className="form-control"
								value={ChecklistType.Unspecified}
							></option>
							<option className="form-control" value={ChecklistType.Project}>
								Project
							</option>
							<option className="form-control" value={ChecklistType.Vendor}>
								Vendor
							</option>
						</select>
					</div>
					<div className="form-group">
						<div className="inline-label">Function</div>
						<StyledDiv>
							<FilterableSelect
								defaultVal={
									checklist.functionType ? checklist.functionType.id : undefined
								}
								items={props.checklistFunctions}
								id="function-type"
								onChange={(id) =>
									props.onEditChecklistFunction(checklist.id, +id)
								}
							/>
							<div
								className="btn btn-sm btn-blue"
								style={{ marginLeft: "8px" }}
								onClick={() => setShowFunctionAddModal(true)}
							>
								Add
							</div>
						</StyledDiv>
					</div>
					{checklist.type === ChecklistType.Project ? (
						<div
							className="form-group"
							key={checklist.isGlobalChecklist.toString()}
						>
							<div className="inline-label">Global Checklist</div>
							<label
								className="switch"
								key={
									checklist.isGlobalChecklist
										? checklist.isGlobalChecklist.toString()
										: ""
								}
							>
								<input
									type="checkbox"
									defaultChecked={checklist.isGlobalChecklist}
								/>
								<span
									className="slider round"
									onClick={() =>
										_updateList(
											"isGlobalChecklist",
											!checklist.isGlobalChecklist
										)
									}
								></span>
							</label>
						</div>
					) : (
						[]
					)}
					{checklist.type !== ChecklistType.Vendor ? (
						<div className="form-group" key={checklist.title}>
							<div className="inline-label">Role-Restricted</div>
							<label
								className="switch"
								key={
									checklist.roleRestriction
										? checklist.roleRestriction.toString()
										: ""
								}
							>
								<input
									type="checkbox"
									defaultChecked={checklist.roleRestriction}
								/>
								<span
									className="slider round"
									onClick={() =>
										_updateList("roleRestriction", !checklist.roleRestriction)
									}
								></span>
							</label>
						</div>
					) : (
						[]
					)}
					{checklist.roleRestriction &&
					checklist.type !== ChecklistType.Vendor ? (
						<JoinedList
							title="Roles"
							availableItems={availableRoles}
							selectedItems={selectedRoles}
							onChange={(roleIds) => _updateRoles(roleIds)}
						/>
					) : (
						[]
					)}
					<div
						style={{ marginBottom: "1rem", marginTop: "1rem", height: "260px" }}
						className="form-group"
					>
						<JoinedList
							key={Date.now().toString()}
							title="Editors"
							availableItems={availableEditors}
							selectedItems={selectedEditors}
							onChange={(items) => _updateEditors(items)}
						/>
					</div>
					<div
						style={{ marginBottom: "1rem", marginTop: "1rem", height: "260px" }}
						className="form-group"
					>
						<JoinedList
							key={Date.now().toString()}
							title="Checklists to inherit"
							availableItems={availableChecklists}
							selectedItems={selectedChecklists}
							onAdd={(items) => _inheritTemplates(items)}
							onRemove={(items) => _deinheritTemplates(items)}
							disabled={!checklist.canInherit}
						/>
					</div>
					<NewChecklistSectionComponent checklist={checklist} />
				</div>
				<div className="modal-footer">
					{/*<div className='btn btn-sm btn-green' onClick={_save}>Save</div>*/}
					<div className="btn btn-sm btn-outline-secondary" onClick={_close}>
						Done
					</div>
				</div>
			</DetailModalContext.Provider>
			{showFunctionAddModal && (
				<ChecklistFunctionAddModal
					onClose={() => setShowFunctionAddModal(false)}
					onFunctionAdd={_handleOnFunctionAdd}
					functions={props.checklistFunctions}
				/>
			)}
		</Modal>
	);
};
