import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  RFQ,
  RFQPostModel,
  UpdateObject,
} from 'src/interfaces/interfaces';

export interface RFQState {
  selectedRFQ: RFQ | null;
  status: 'idle' | 'loading' | 'saving' | 'completed';
}

export const initialState: RFQState = {
  selectedRFQ: null,
  status: 'idle',
};

const { actions: RFQActions, reducer: RFQReducer } = createSlice({
  name: 'rfqs',
  initialState,
  reducers: {
    setSelectedRFQ(state, action: PayloadAction<RFQ | null>) {
      state.selectedRFQ = action.payload;
    },
    createRFQ(_state, _action: PayloadAction<RFQPostModel>) { },
    setStatus(state, action: PayloadAction<'idle' | 'loading' | 'saving' | 'completed'>) {
      state.status = action.payload;
    },
    updateRFQ(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) { },
    addFiles(_state, _action: PayloadAction<{ id: number, files: FileList }>) { },
    removeFile(_state, _action: PayloadAction<{ issueId: number, fileId: number }>) { },
    addCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds?: string; emails?: string }>
    ) { },
    removeCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds: string }>
    ) { },
  },
});

export { RFQActions, RFQReducer };

export default RFQReducer;
