import { camelize, formatDate, toMoney } from "../helpers/formatters";
import { KPIGraphFormat } from "../interfaces/enums";
import { GraphMeasureType } from "./GraphPart";

export const getDataPoints = (data: any[], dimension: string, measure: string, measureType: GraphMeasureType, includeFormat: boolean) => {
	const dimensionStr = camelize(dimension);
	if (measureType === GraphMeasureType.Sum) {
		if (measure == null || measure === "") return [];
		const ptCount = data.reduce((p, c) => {
			const name = getNestedPropValue(c, dimension);
			//var name = c[dimensionStr] != null ? c[dimensionStr] : 'Unknown';
			if (!Object.prototype.hasOwnProperty.call(p, name)) {
				p[name] = 0;
			}
			p[name] += c[getNestedPropValue(c, measure)];
			return p;
		}, {});

		const dataPts = Object.keys(ptCount).map((x) => {
			return [x, ptCount[x], "opacity: 0.8"];
		});

		if (includeFormat) dataPts.unshift([dimension, "Sum", { role: "style" }]);

		return dataPts;
	}
	else {
		const ptCount = data.reduce((p, c) => {
			const name = getNestedPropValue(c, dimension);
			//var name = c[dimensionStr] != null ? c[dimensionStr] : 'Unknown';
			//if (name !== undefined) {
			if (!Object.prototype.hasOwnProperty.call(p, name)) {
				p[name] = 0;
			}
			p[name]++;
			return p;
			//}
		}, {});

		const dataPts = Object.keys(ptCount).map((x) => {
			return [x, ptCount[x], "opacity: 0.8"];
		});

		if (includeFormat) {
			dataPts.unshift([
				dimension,
				measureType === GraphMeasureType.Count ? "Count" : "Sum",
				{ role: "style" },
			]);
		}

		return dataPts;
	}
};


// Iterate through the levels of a prop delineated by a '.'
// Handles the case where there may be arrays in the path
export const getNestedPropValue = (obj: any, prop: string) => {
	const levels = prop.split(".");
	let value: any = obj[camelize(levels[0])] ?? "Unknown";

	for (let i = 1; i < levels.length; ++i) {
		const camelProp = camelize(levels[i]);
		try {
			value = Array.isArray(value)
				? value.map((x) => x[camelProp])
				: value[camelProp];
		} catch {
			return "Unknown";
		}
	}

	if (Array.isArray(value)) value = value.join(", ");

	return value;
};


export const formatKPIValue = (val: any, format: KPIGraphFormat) => {
	if (!val) return "N/A";
	switch (format) {
		case KPIGraphFormat.Number:
			return parseInt(val);
		case KPIGraphFormat.Date:
			return formatDate(val.toString());
		case KPIGraphFormat.Money:
			return toMoney(val.toString());
	}
};