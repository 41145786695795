import * as React from 'react';
import * as cx from 'classnames';
import { MessageBox } from '../../components/MessageBox';
import { ListItem } from '../../interfaces/interfaces';
import { Loader } from '../../components/Loader';
import { SliderCheckbox } from '../../components/SliderCheckbox';

interface TrackerPipelineConfig {
    id: number,
    clientId: number,
    projectMatchField: string,
    milestoneMaps: TrackerPipelineMilestoneMap[]
}

interface TrackerPipelineMilestoneMap {
    trackerPipelineConfigId: number,
    siteVueMilestoneId: number,
    trackerMilestoneId: number,
    milestoneName: string,
    pushUpdates: boolean,
    pullUpdates: boolean
}

interface TrackerMilestone {
    mid: number,
    mShortName: string,
    billable: boolean
}

interface Props { }

interface State {
    clientId: number,
    clients: ListItem[],
    config: TrackerPipelineConfig,
    trackerMilestones: TrackerMilestone[],
    loading: boolean,
    message?: string
}

export const KnownProjectMatchFields = Object.freeze({ ProjectNumber: 'ProjectNumber', ProjectName: 'Name', SiteId: 'SiteId', Wbs1: 'Wbs1' });

export default class TrackerPipelineConfigView extends React.Component<Props, State> {
    state: State = {
        clientId: 0,
        clients: [],
        config: {} as TrackerPipelineConfig,
        trackerMilestones: [],
        loading: false,
        message: undefined
    }

    componentDidMount() {
        this._getClients();
        this._getTrackerMilestones();
    }

    _getConfig = (e: React.ChangeEvent<HTMLSelectElement>) => {        
        const id = parseInt(e.currentTarget.value)

        fetch(`api/TrackerPipeline/Get?clientId=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ config: data, clientId: id })
                else this.setState({ message: data.message, clientId: id })
            })
    }

    _getTrackerMilestones = () => {
        this.setState({ loading: false })
        fetch(`api/TrackerPipeline/TrackerMilestones`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ trackerMilestones: data, loading: false })
                else this.setState({ message: data.message, loading: false })
            })
    }

    _getClients = () => {
        fetch(`api/Client/Get`)
            .then(res => res.json())
            .then(data => this.setState({ clients: data }))
    }

    _changeMatchField = (e: React.ChangeEvent<HTMLSelectElement>) => {
        fetch(`api/TrackerPipeline/UpdateMatchField?id=${this.state.config.id}&field=${e.currentTarget.value}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) { //Do something
                 } //this.setState({ config: data })
                else this.setState({ message: data.message })
            })
    }

    _updateMilestoneMap = (id: number, e: React.ChangeEvent<HTMLSelectElement>) => {        
        fetch(`api/TrackerPipeline/UpdateMap?milestoneId=${id}&trackerMilestoneId=${e.currentTarget.value}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    const config = JSON.parse(JSON.stringify(this.state.config)) as TrackerPipelineConfig
                    config.milestoneMaps = config.milestoneMaps.map(x => {
                        if (x.siteVueMilestoneId === id) x = data;
                        return x
                    })
                    this.setState({ config: config })
                }
            })
    }

    _togglePushUpdates = (id: number) => {
        fetch(`api/TrackerPipeline/TogglePush?milestoneId=${id}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    const config = JSON.parse(JSON.stringify(this.state.config)) as TrackerPipelineConfig
                    config.milestoneMaps = config.milestoneMaps.map(x => {
                        if (x.siteVueMilestoneId === id) x = data;
                        return x
                    })
                    this.setState({ config: config })
                }
            })
    }

    _togglePullUpdates = (id: number) => {
        fetch(`api/TrackerPipeline/TogglePull?milestoneId=${id}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    const config = JSON.parse(JSON.stringify(this.state.config)) as TrackerPipelineConfig
                    config.milestoneMaps = config.milestoneMaps.map(x => {
                        if (x.siteVueMilestoneId === id) x = data;
                        return x
                    })
                    this.setState({ config: config })
                }
            })
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { clients, clientId, config, trackerMilestones } = this.state;
        const trackerMilestoneOpts = (trackerMilestones && trackerMilestones.length) ?
            trackerMilestones.map(x => {
                const text = x.billable ? `$ ${x.mShortName}` : `  ${x.mShortName}`;
                return <option key={x.mid} value={x.mid} className='form-control'>{text}</option>
            })
            : []
        const mapSection = config.milestoneMaps ?
            config.milestoneMaps.map(x => {
                return (
                    <div className='milestone-map-form' key={x.siteVueMilestoneId}>
                        <label className='milestone-map-label'>{x.milestoneName}</label>
                        <div>
                            <label>Tracker Milestone</label>
                            <select className='form-control' onChange={(e) => this._updateMilestoneMap(x.siteVueMilestoneId, e)} defaultValue={x.trackerMilestoneId}>
                                <option value='0' className='form-control'>Select...</option>
                                {trackerMilestoneOpts}
                            </select>
                        </div>
                        <div>
                            <label style={{ display: 'inline-block' }}>Push Changes <span className='fas fa-question' title='Turn on if you want SiteVue to send changes for this milestone to the Tracker'></span></label>
                            <SliderCheckbox selected={x.pushUpdates} fieldName='pushUpdates' dataId={x.trackerPipelineConfigId} onChange={() => this._togglePushUpdates(x.siteVueMilestoneId)} />
                            {/*<label className='switch'>*/}
                            {/*    <input type='checkbox' defaultChecked={x.pushUpdates} />*/}
                            {/*    <span className='slider round' onClick={() => this._togglePushUpdates(x.siteVueMilestoneId)}></span>*/}
                            {/*</label>*/}
                        </div>
                        <div>
                            <label style={{ display: 'inline-block' }}>Pull Updates <span className='fas fa-question' title='Turn on if you want SiteVue to receive changes for this milestone from the Tracker'></span></label>
                            <SliderCheckbox selected={x.pullUpdates} fieldName='pushUpdates' dataId={x.trackerPipelineConfigId} onChange={() => this._togglePullUpdates(x.siteVueMilestoneId)} />
                            {/*<label className='switch'>*/}
                            {/*    <input type='checkbox' defaultChecked={x.pullUpdates} />*/}
                            {/*    <span className='slider round' onClick={() => this._togglePullUpdates(x.siteVueMilestoneId)}></span>*/}
                            {/*</label>*/}
                        </div>
                    </div>
                )
            })
            : []
        return (
            <div className='tracker-pipeline'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                <Loader loading={this.state.loading} />
                <div style={{ maxWidth: '200px' }}>
                    <b>Client</b>
                    <select className='form-control' onChange={this._getConfig} defaultValue={clientId}>
                        <option value='0' className='form-control'>Select...</option>
                        {(clients && clients.length) && clients.map(c => <option key={c.id} value={c.id} className='form-control'>{c.value}</option>)}
                    </select>
                </div>
                {/*config &&
                    <div style={{ maxWidth: '200px' }}>
                        <b>Matching Column <span className='fas fa-question' title='The SiteVue field which will contain the WBS1 text to match a project on the Tracker'></span></b>
                        <select className='form-control' onChange={this._changeMatchField} defaultValue={config.projectMatchField}>
                            <option value={KnownProjectMatchFields.ProjectNumber} className='form-control'>Project Number</option>
                            <option value={KnownProjectMatchFields.ProjectName} className='form-control'>Project Name</option>
                            <option value={KnownProjectMatchFields.SiteId} className='form-control'>Site Id</option>
                        </select>
                    </div>
                */}
                <br/>
                {(config.milestoneMaps && config.milestoneMaps.length) ?
                    <div>
                        <h5>Milestone Mappings</h5>
                        <div className='pipeline-milestone-maps'>
                            {mapSection}
                        </div>
                    </div>
                    : []
                }
            </div>
        )
    }
}