import * as React from 'react';
import { useSelector } from 'react-redux';
import FilterableSelect from '../components/FilterableSelect';
import { MessageBox } from '../components/MessageBox';
import { ListItem, ProjectTask, ProjectTaskAssignee } from '../interfaces/interfaces';
import { ApplicationState } from '../store';

interface Props {
    projectId: number,
    milestones: ListItem[],
    descriptions: string[],
    addCallback: (task: ProjectTask) => void,
    hideAddForm: () => void,
}

export const AddTaskForm = (props: Props) => {

    const [form, setForm] = React.useState({ projectId: props.projectId, assignees: [] as ProjectTaskAssignee[] } as ProjectTask);
    const [message, setMessage] = React.useState(undefined as string | undefined)
    const globalStore = useSelector((s: ApplicationState) => s.global)

    const { milestones, descriptions } = props;
    const xSmallCol = 'my-col-1', // 2
        smallCol = 'my-col-2', // 2
        medCol = 'my-col-3',  // 2
        largeCol = 'my-col-7' // 1

    const _addAssigneeToForm = (id: string) => {
        const user = globalStore.users.find(x => x.userId === id);     
        if (user) {
            // @ts-ignore
            document.querySelector('#assignedToId > input').value = '';
            if (form.assignees !== undefined && form.assignees.findIndex(x => x.userId === user.userId) !== -1) return;
            setForm({ ...form, assignees: form.assignees.concat([{ userId: id, userEmail: user.email, userFullName: user.fullName } as ProjectTaskAssignee]) })
        }
    }

    const _addTask = () => {                    

        fetch(`api/ProjectTask/Add`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form)
        })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    props.addCallback(data);
                    props.hideAddForm();
                    //setForm({ projectId: props.projectId, assignees: [] as ProjectTaskAssignee[] } as ProjectTask);
                }
                else setMessage(data.message);
            })
    }

    return (
        <div key='add' className='team-grid-line add-task-line my-col-20'>
            <MessageBox message={message} clearMessage={() => setMessage(undefined)} />
            <div className={largeCol}>
                <FilterableSelect id='description' items={descriptions.map(x => new ListItem(x, x,))} openField={true} onBlur={(val) => setForm({ ...form, description: val })} />
            </div>
            <div className={medCol}>
                <FilterableSelect id='milestoneId' items={milestones} onChange={(id) => setForm({ ...form, milestoneId: parseInt(id) })} />
            </div>
            <div className={smallCol}><input className='form-control' type='date' name='dueDate' id='dueDate' value={form.dueDate} onChange={(e) => setForm({ ...form, dueDate: e.currentTarget.value })} /></div>
            <div className={smallCol}><input type='checkbox' name='completed' id='completed' disabled /></div>
            <div className={smallCol}><input className='form-control' type='date' name='completeDate' id='completeDate' disabled /></div>
            <div className={medCol}>
                {form.assignees ? form.assignees.map(x => {
                    return (
                        <div className='' style={{ position: 'relative' }} key={x.userEmail}>
                            <span>{x.userFullName}</span>
                            <span className='fas fa-times' style={{ position: 'absolute', right: '5px', top: '0.75em' }} onClick={() => setForm({ ...form, assignees: form.assignees.filter(y => y.userId !== x.userId) })}></span>
                        </div>
                    )}) : []
                }
                <FilterableSelect id='assignedToId' items={globalStore.users.map(x => new ListItem(x.userId, x.fullName))} onChange={_addAssigneeToForm} />
            </div>
            <div className='right-button' style={{ top: '8px' }}>
                <button className='fas fa-check btn btn-sm btn-blue'
                    onClick={_addTask}
                ></button>
                <button className='fas fa-ban btn btn-sm btn-outline-secondary'
                    onClick={props.hideAddForm} title='Cancel'
                ></button>
            </div>
        </div>
    )
}
