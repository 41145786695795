import * as React from 'react';

export const SliderCheckbox = (props: { dataId?: string | number, selected: boolean, fieldName: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, disabled?: boolean }) => {

    const { dataId, selected, fieldName, onChange, disabled } = props;

    return (
        <label className='switch' key={dataId + '_' + selected.toString()}>
            <input type='checkbox' name={fieldName} id={`${fieldName}_${dataId}`} defaultChecked={selected} onChange={onChange} data-id={dataId} disabled={disabled} />
            <span className='slider round'></span>
        </label>
    )
}