import * as React from 'react';
import { IDocumentInfo } from '../interfaces/interfaces';
import { isImage } from '../helpers/misc';

interface Props {
    doc: IDocumentInfo,
    height?: number,
    delete?: (id: number) => void
}

export default class DocIcon extends React.Component<Props, {hovered: boolean}> {
    constructor(props) {
        super(props);
        this.state = {
            hovered: false
        }        
    }

    _delete = () => {
        if (this.props.delete) {
            this.props.delete(this.props.doc.id);
        }
    }

    _hover = () => {        
        this.setState({ hovered: true });
    }

    _hoverEnd = () => {        
        this.setState({ hovered: false });
    }

    render() {
        const { doc, height } = this.props;
        const iconHeight = height || 25;                        
        const slashSplit = doc.filePath.split('/'), fileName = slashSplit[slashSplit.length - 1] // Path is encoded so we need to split on encoded backslash
        const src = isImage(fileName) ? '/img/photoIcon.jpg' : '/img/pdficon.png';
        return (
            <div className='doc-icon' onMouseEnter={this._hover} onMouseLeave={this._hoverEnd} key={doc.id}>
                <a className='response-doc-icon' onClick={(e) => e.stopPropagation()} href={doc.filePath.replace(/#/g, '%23')} target="_blank" title={fileName}>
                    <img style={{ height: iconHeight + 'px' }} src={src} />
                    {/*fileName*/}
                </a>
                {(this.props.delete !== undefined && this.state.hovered) ? <span className='fas fa-times' title='Remove this document' onClick={this._delete}></span> : []}
            </div>
        )
    }
}