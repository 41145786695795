import * as React from 'react';
import { connect } from 'react-redux';
import * as AdminStore from '../store/admin'
import { ApplicationState } from '../store';
import { check_phone_format } from '../helpers/formatters';

interface FormProps {
}

type Props =
    FormProps
    & AdminStore.Store
    & typeof AdminStore.actionCreators

class RegisterForm extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props)
        this.state = {
            companyId: -1,
            clientUser: true,
            vendorUser: false,
        }
    }

    componentDidMount() {

    }

    _save = () => {
        const modalBody = document.querySelector('div#new-user-form') as HTMLDivElement
        if (modalBody) {
            const valid = true;
            const roles: string[] = [];
            const isVendor = (modalBody.querySelector('input[name="isVendor"]') as HTMLInputElement).checked
            const isBrandOrOwner = (modalBody.querySelector('input[name="isBrandOrOwner"]') as HTMLInputElement).checked
            const email = (modalBody.querySelector('input[name="email"]') as HTMLInputElement).value
            const fullName = (modalBody.querySelector('input[name="fullName"]') as HTMLInputElement).value
            const phone = (modalBody.querySelector('input[name="phone"]') as HTMLInputElement).value,
                apiUser = (modalBody.querySelector('input[name="isApiUser"]') as HTMLInputElement).checked

            if (!this.state.vendorUser) {
                const roleChkBxs = (document.querySelectorAll('#user-roles input[type="checkbox"]:checked'));

                [].forEach.call(roleChkBxs, (r: HTMLInputElement) => roles.push(r.value))
            }

            if (valid) {
                this.props.register(this.state.companyId || '', fullName,
                    email, phone, roles, isVendor, isBrandOrOwner, apiUser)
            }
        }
    }

    _changeClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            companyId: (e.currentTarget.querySelector('option:checked') as HTMLOptionElement).value
        })
    }

    _vendorCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            (document.querySelector('input[name="isBrandOrOwner"]') as HTMLInputElement).checked = false
            this.props.getVendors()
            this.setState({ clientUser: false, vendorUser: true })
        }
        else {
            this.props.getClients()
            this.setState({ clientUser: true, vendorUser: false })
        }
    }

    _brandCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            (document.querySelector('input[name="isVendor"]') as HTMLInputElement).checked = false
            this.props.getBrands()
            this.setState({ clientUser: false, vendorUser: false })
        }
        else {
            this.props.getClients()
            this.setState({ clientUser: true })
        }
    }

    _apiCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked) {
            (document.querySelector('#email-form') as HTMLDivElement).style.display = 'none';
            (document.querySelector('#user-roles') as HTMLDivElement).style.display = 'none';
        } else {
            (document.querySelector('#email-form') as HTMLDivElement).style.display = 'inherit';
            (document.querySelector('#user-roles') as HTMLDivElement).style.display = 'inherit';
        }
    }

    render() {
        const companyOpts = this.props.companies && this.props.companies.length ?
            this.props.companies.map(c => <option key={c.id} value={c.id}>{c.value}</option>) :
            []

        return (
            <div>
                <div className="form-header">
                    <h4 className="modal-title">Register User</h4>
                </div>
                <div className="form-body" id="new-user-form">
                    <br />
                    <div className='form-group' style={{ display: 'flex' }}>
                        <div style={{ marginRight: '50px' }}>
                            <label>Vendor User?</label>
                            <br />
                            <input type='checkbox' name='isVendor' onChange={this._vendorCheck} />
                        </div>
                        <div style={{ marginRight: '50px' }}>
                            <label>Brand/Owner User?</label>
                            <br />
                            <input type='checkbox' name='isBrandOrOwner' onChange={this._brandCheck} />
                        </div>
                        <div>
                            <label>API User?</label>
                            <br />
                            <input type='checkbox' name='isApiUser' onChange={this._apiCheck} />
                        </div>
                    </div>
                    <div className='form-group'>

                    </div>
                    <div className='form-group'>
                        <label>Company</label>
                        <select className='form-control' name='clientId'
                            onChange={this._changeClient}>
                            <option value='-1'>Select...</option>
                            <option value='0'>Core States Group</option>
                            {companyOpts}
                        </select>
                    </div>
                    <div className='form-group' id='email-form'>
                        <label>Contact Email</label>
                        <input type='email' key={`email`} name='email' className='form-control' />
                    </div>
                    <div className='form-group'>
                        <label>Full Name</label>
                        <input key={`fullName`} name='fullName' className='form-control' />
                    </div>
                    <div className='form-group'>
                        <label>Phone Number</label>
                        <input type='tel' key={`phone`} name='phone' className='form-control formatted-phone'
                            onKeyUp={check_phone_format} />
                    </div>
                    {!this.state.vendorUser &&
                        <div id='user-roles' className='form-group' key={this.state.userRole || 'reset'}>
                            {this.state.companyId == 0 && <input type="checkbox" name="user-role" value="Admin" />} {this.state.companyId == 0 ? `Admin` : ``} &nbsp;
                            {this.state.companyId == 0 && <input type="checkbox" name="user-role" value="Core SuperUser" />} {this.state.companyId == 0 ? `SuperUser` : ``} &nbsp;
                            {this.state.companyId == 0 && <input type="checkbox" name="user-role" value="CSG PM" />} {this.state.companyId == 0 ? `Project Manager` : ``} &nbsp;
                            {this.state.companyId == 0 && <input type="checkbox" name="user-role" value="Site Super" />} {this.state.companyId == 0 ? `Site Super` : ``} &nbsp;
                            {(this.state.clientUser && this.state.companyId != 0) && <input type="checkbox" name="user-role" value="Client Admin" />} {(this.state.clientUser && this.state.companyId != 0) && `Client Admin`} &nbsp;
                            {(this.state.clientUser && this.state.companyId != 0) && <input type="checkbox" name="user-role" value="Client PM" />} {(this.state.clientUser && this.state.companyId != 0) && `Client PM`} &nbsp;
                        </div>
                    }
                </div>
                <div className="form-footer">
                    <p className='warning-note'>NOTE: Registering a user sends them a confirmation email from a no-reply address. It's advised that you reach out
                        to them so they understand that the email is trusted and that they don't miss it should it end up in their spam folder.</p>
                    <span className="btn btn-sm btn-green" onClick={this._save.bind(this)}>Save</span>
                </div>

            </div>
        )
    }
}

export default connect<AdminStore.Store, typeof AdminStore.actionCreators, FormProps>(
    // @ts-ignore
    (state: ApplicationState) => state.admin,
    AdminStore.actionCreators
)(RegisterForm)