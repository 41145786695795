import * as React from 'react';
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import FilterableSelect from '../components/FilterableSelect';
import { DateRangeFilter, ListItem, ProjectTask } from '../interfaces/interfaces';
import { DateRange } from 'moment-range';
import { camelize } from '../helpers/formatters';
import { SVDateRangePicker } from '../components/SVDateRangePicker';
import { AddTaskForm } from './AddTaskForm';


interface Props {
    projectId?: number,
    clientId?: number,
}

interface State {
    loading: boolean;
    showAddTask: boolean;
    showFilters: boolean;
    editingId?: number;
    milestones: ListItem[];
    users: ListItem[];
    tasks: ProjectTask[];
    descriptions: string[];
    filters: ListItem[],
    dateFilters: DateRangeFilter[],
    sortProp?: string
    sortAsc: boolean;
    message?: string;
}

export default class ProjectTaskGrid extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            showAddTask: false,
            showFilters: false,
            milestones: [],
            users: [],
            tasks: [],
            descriptions: [],
            filters: [],
            sortAsc: true,
            dateFilters: []
        }
    }

    componentDidMount() {
        this._getUsers();
        this._getMilestones()
        this._getTasks();
        this._getDescriptions();
    }

    _getMilestones = () => {
        const { clientId } = this.props;
        if (clientId) {
            fetch(`api/Milestone/GetAll?clientId=${clientId}`)
                // @ts-ignore
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) this.setState({ milestones: data })
                    else this.setState({ message: data.message })
                })
        }
    }

    _getUsers = () => {
        fetch(`api/User/GetAll`)
            .then(res => res.json())
            .then(data => this.setState({ users: data }))
    }

    _getDescriptions = () => {
        const { clientId } = this.props;
        if (clientId) {
            fetch(`api/ProjectTask/Descriptions?clientId=${clientId}`)
                // @ts-ignore
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) this.setState({ descriptions: data })
                    else this.setState({ message: data.message })
                })
        }
    }

    _getTasks = () => {
        const { projectId } = this.props;
        const url = projectId ? `api/ProjectTask/Get?id=${projectId}` : 'api/ProjectTask/Mine';

        fetch(url)
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ tasks: data, loading: false })
                else this.setState({ message: data.message, loading: false })
            })
    }



    _updateField = (field: string, val: any) => {
        const { editingId } = this.state;

        fetch(`api/ProjectTask/Edit?id=${editingId}`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fieldName: field,
                    value: val
                })
            })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    const tasks = JSON.parse(JSON.stringify(this.state.tasks))
                        .map((x: ProjectTask) => {
                            if (x.id === editingId) x = data;
                            return x;
                        })
                    this.setState({ tasks: tasks, loading: false })
                }
                else this.setState({ message: data.message, loading: false })
            })
    }

    _removeTask = (id: number) => {
        fetch(`api/ProjectTask/Delete?id=${id}`, { method: 'DELETE' })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ tasks: data, loading: false })
                else this.setState({ message: data.message, loading: false })
            })
    }

    _addAssignee = (userId: string, taskId: number) => {
        fetch(`api/ProjectTask/AddAssignee?taskId=${taskId}&userId=${userId}`, { method: 'POST' })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ tasks: data, loading: false })
                else this.setState({ message: data.message, loading: false })
            })
    }

    _deleteAssignee = (id: number) => {
        fetch(`api/ProjectTask/DeleteAssignee?id=${id}`, { method: 'DELETE' })
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ tasks: this.state.tasks.map(x => x.id === data.id ? data : x), loading: false })
                else this.setState({ message: data.message, loading: false })
            })
    }

    _applyFilters = (tasks: ProjectTask[]) => {
        const { filters, dateFilters, sortAsc, sortProp } = this.state;

        filters.forEach(x => {
            const fields = x.id.toString().split('|');
            if (fields.length > 1) {
                tasks = tasks.filterByMultiStringProp([...fields], x.value)
            }
            else tasks = tasks.filterByStringProp(fields[0], x.value)
        })

        dateFilters.forEach(x => {
            tasks = tasks.filter((t: ProjectTask) => {
                const dateStrVal = t[camelize(x.dateProp)]
                if (dateStrVal !== null && dateStrVal.length !== 0) {
                    const dateVal = new Date(dateStrVal);
                    //if (typeof (x.range.start)
                    if (dateVal >= x.range.start.toDate() && dateVal <= x.range.end.toDate()) {
                        return true;
                    }
                }
                return false;
            })
        })

        tasks = tasks.filter(x => x !== undefined);

        if (sortProp) {
            tasks = tasks.sort((a, b) => {
                const aVal = a[sortProp], bVal = b[sortProp]
                if (bVal === undefined) return -1;
                if (aVal === bVal) return 0;
                if (sortAsc) return aVal > bVal ? 1 : -1;
                else return aVal > bVal ? -1 : 1;
            })
        }

        return tasks;
    }

    _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const field = e.currentTarget.getAttribute('name') || ''
        let filterExists = false;
        let filters = JSON.parse(JSON.stringify(this.state.filters))
            .map(x => {
                if (x.id === field) {
                    filterExists = true;
                    x.value = val;
                }
                return x;
            })

        if (!filterExists) filters.push({ id: field, value: val } as ListItem)
        filters = filters.filter(x => x !== undefined)
        this.setState({ filters: filters })
    }

    _handleDateFilter = (dateProp: string, range?: DateRange) => {
        let filterExists = false;
        let dateFilters = this.state.dateFilters.slice() // JSON.parse(JSON.stringify(this.state.dateFilters))
            .map((x: DateRangeFilter) => {
                if (x.dateProp === dateProp) {
                    filterExists = true;
                    if (range) {
                        x.range = range;
                        return x;
                    }
                }
                else return x;
            }) as DateRangeFilter[];

        if (!filterExists) dateFilters.push({ dateProp: dateProp, range: range } as DateRangeFilter)
        dateFilters = dateFilters.filter(x => x !== undefined)
        this.setState({ dateFilters: dateFilters })
    }

    _setEditTask = (id?: number) => this.setState({ editingId: id })

    _toggleShowAddTask = () => this.setState({ showAddTask: !this.state.showAddTask });

    _toggleFilters = () => this.setState({ showFilters: !this.state.showFilters })

    _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
        const prop = e.currentTarget.getAttribute('data-for') || undefined;
        this.setState({ sortProp: prop, sortAsc: !this.state.sortAsc })
    }

    _clearFilters = () => this.setState({ filters: [] })

    _clearMessage = () => this.setState({ message: undefined });

    render() {
        const { loading, showAddTask, showFilters, message, milestones, descriptions } = this.state;

        return (
            <div className='grid project-team-grid'>
                {this.renderHeader()}
                {showFilters && this.renderFilterLine()}
                <div className='grid-body custom-scrollbar'>
                    {showAddTask && <AddTaskForm
                        projectId={this.props.projectId || 0}
                        descriptions={descriptions}
                        milestones={milestones}
                        addCallback={(task) => this.setState({ tasks: this.state.tasks.concat([task]) })}
                        hideAddForm={() => this.setState({ showAddTask: false })} />}
                    {this.renderLines()}</div>
                <MessageBox message={message} clearMessage={this._clearMessage} />
                <Loader loading={loading} />
            </div>
        )
    }

    renderHeader() {
        const isProjectSpecific = this.props.projectId !== undefined,
            smallCol = 'my-col-2', // 2
            medCol = isProjectSpecific ? 'my-col-3' : 'my-col-3', // 2 or 3
            largeCol = isProjectSpecific ? 'my-col-7' : 'my-col-4', // 1,
            { sortProp, sortAsc } = this.state;
        const sortIconClass = sortAsc ? 'sort-icon fas fa-arrow-up' : 'sort-icon fas fa-arrow-down'
        const sortIcon = sortProp ? <span className={sortIconClass}></span> : []

        return (
            <div className='team-grid-header my-col-20'>
                <div className={largeCol}><label data-for='description' className='sortable' onClick={this._sort}><b>Description</b> {sortProp === 'description' && sortIcon}</label></div>
                {!isProjectSpecific && <div className={medCol}><label data-for='projectNumber' className='sortable' onClick={this._sort}><b>Project</b> {sortProp === 'projectNumber' && sortIcon}</label></div>}
                <div className={medCol}><label data-for='milestoneName' className='sortable' onClick={this._sort}><b>Milestone</b> {sortProp === 'milestoneName' && sortIcon}</label></div>
                <div className={smallCol}><label data-for='dueDate' className='sortable' onClick={this._sort}><b>Due Date</b> {sortProp === 'dueDate' && sortIcon}</label></div>
                <div className={smallCol}><label data-for='completed' className='sortable' onClick={this._sort}><b>Completed</b> {sortProp === 'completed' && sortIcon}</label></div>
                <div className={smallCol}><label data-for='completeDate' className='sortable' onClick={this._sort}><b>Complete Date</b> {sortProp === 'completeDate' && sortIcon}</label></div>
                <div className={medCol}><label data-for='assignedToName' className='sortable' onClick={this._sort}><b>Assigned To</b> {sortProp === 'assignedToName' && sortIcon}</label></div>
                <div className='right-button'>
                    <button className='fas fa-filter btn btn-sm btn-outline-secondary'
                        onClick={this._toggleFilters}></button>
                    {isProjectSpecific && <button className='fas fa-plus btn btn-sm btn-blue'
                        onClick={this._toggleShowAddTask}
                    ></button>}
                </div>
            </div>
        )
    }

    renderLines() {
        const { tasks, editingId, descriptions, milestones, users } = this.state,
            isProjectSpecific = this.props.projectId !== undefined,
            xSmallCol = 'my-col-1', // 2
            smallCol = 'my-col-2', // 2
            medCol = isProjectSpecific ? 'my-col-3' : 'my-col-3', // 2 or 3
            largeCol = isProjectSpecific ? 'my-col-7' : 'my-col-4' // 1            

        return this._applyFilters(tasks).map(x => {
            const editing = x.id === editingId,
                completeDate = x.completeDate ? x.completeDate.substring(0, 10) : ''
            return (
                <div key={`${x.id}_${x.completeDate}`} className='team-grid-line my-col-20'>
                    <div className={largeCol}>
                        {(isProjectSpecific && editing) ?
                            <FilterableSelect id='description' items={descriptions.map(x => new ListItem(x, x,))} openField={true} onBlur={(val) => this._updateField('description', val)} defaultVal={x.description} /> :
                            <span className='truncate' style={{ display: 'block' }}>{x.description}</span>
                        }
                    </div>
                    {!isProjectSpecific && <div className={medCol}>{x.projectNumber} - {x.projectName}</div>}
                    <div className={medCol}>
                        {(isProjectSpecific && editing) ?
                            <FilterableSelect id='milestoneId' items={milestones} onBlur={(val) => this._updateField('milestoneId', val)} defaultVal={x.milestoneId} /> :
                            x.milestoneName
                        }
                    </div>
                    <div className={smallCol}><input className='form-control' type='date' name='dueDate' defaultValue={x.dueDate.substring(0, 10)} disabled={!editing} onBlur={(e) => this._updateField('dueDate', e.currentTarget.value)} /></div>
                    <div className={smallCol}><input type='checkbox' name='completed' defaultChecked={x.completed} disabled={!editing} onBlur={(e) => this._updateField('completed', e.currentTarget.checked)} /></div>
                    <div className={smallCol}><input className='form-control' type='date' name='completeDate' defaultValue={completeDate} disabled={!editing} onBlur={(e) => this._updateField('completeDate', e.currentTarget.value)} /></div>
                    <div className={medCol}>
                        {x.assignees ? x.assignees.map(y => {
                            return (
                                <div className='' style={{ position: 'relative' }} key={y.userEmail}>
                                    <span>{y.userFullName}</span>
                                    {editing && <span className='fas fa-times' style={{ position: 'absolute', right: '5px', top: '0.75em' }} onClick={() => this._deleteAssignee(y.id)}></span>}
                                </div>
                            )
                        }) : []
                        }
                        {(editing) ?
                            <FilterableSelect id='assignedToId' items={users} onBlur={(val) => this._addAssignee(val, x.id)} /> :
                            []
                        }
                    </div>
                    <div className='right-button' style={{ top: '8px' }}>
                        {editing ?
                            <button className='fas fa-check btn btn-sm btn-blue'
                                onClick={() => this._setEditTask(undefined)}></button>
                            :
                            <button className='fas fa-pencil-alt btn btn-sm btn-outline-secondary'
                                onClick={() => this._setEditTask(x.id)} title='Edit this task'></button>
                        }
                        {isProjectSpecific && <button className='fas fa-times btn btn-sm btn-outline-secondary'
                            onClick={() => this._removeTask(x.id)}
                        ></button>}
                    </div>
                </div>
            )
        })
    }

    renderFilterLine() {
        const isProjectSpecific = this.props.projectId !== undefined,
            smallCol = 'my-col-2', // 2
            medCol = 'my-col-3',  // 2
            largeCol = isProjectSpecific ? 'my-col-7' : 'my-col-4' // 1     

        return (
            <div className='team-grid-line my-col-20'>
                <div className={largeCol}><input name='description' onChange={this._filter} /></div>
                {!isProjectSpecific && <div className={medCol}><input name='projectNumber|projectName' onChange={this._filter} /></div>}
                <div className={medCol}><input name='milestoneName' onChange={this._filter} /></div>
                <div className={smallCol}><SVDateRangePicker onChange={(range) => this._handleDateFilter('dueDate', range)} /></div>
                <div className={smallCol}></div>
                <div className={smallCol}><SVDateRangePicker onChange={(range) => this._handleDateFilter('completeDate', range)} /></div>
                <div className={medCol}><input name='assignedToName' onChange={this._filter} /></div>
                <div className='right-button' style={{ top: '8px' }}>
                    <button className='btn btn-sm btn-outline-secondary' onClick={this._clearFilters}>Clear</button>
                </div>
            </div>
        )
    }
}