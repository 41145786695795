import * as React from 'react';
import { KeyDocViewModel } from '../interfaces/interfaces';
import KeyDocAssigner from './KeyDocAssigner';
import { NoAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';

interface Props {
    projectId: number,
    readonly?: boolean
}

interface State {
    keyDocs: KeyDocViewModel[],
    assigningDocId?: number,
    assigningDocName?: string,
}

export default class KeyDocBox extends React.Component<Props, State> {

    noVendorComponent = NoAuthElement([KnownRoles.Vendor])

    constructor(props) {
        super(props)
        this.state = {
            keyDocs: []
        }
    }

    componentDidMount() {
        this._getProjectKeyDocs(this.props.projectId)
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.projectId != this.props.projectId)
            this._getProjectKeyDocs(nextProps.projectId)
    }

    componentWillUnmount() {
        this.setState({
            keyDocs: [],
            assigningDocId: undefined,
            assigningDocName: undefined
        })
    }

    _getProjectKeyDocs = (id: number) => {
        if (id && id !== 0) {
            fetch(`api/Project/KeyDocs?id=${id}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    this.setState({
                        keyDocs: data
                    })
                });
        }
    }

    _toggleAssignViewer = (id: number, desc: string) => {
        this.setState({
            assigningDocId: id,
            assigningDocName: desc
        })
    }

    _assignKeyDoc = (path: string) => {
        // send fetch to save assignment
        fetch(`api/Project/AssignKeyDoc?id=${this.props.projectId}&keyDocId=${this.state.assigningDocId}&docPath=${path}`,
            { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    keyDocs: data
                })
            })
        this._close()
    }

    _close = () => {
        this.setState({
            assigningDocId: undefined,
            assigningDocName: undefined
        })
    }

    render() {
        const keyDocLines = (this.state.keyDocs && this.state.keyDocs.length) &&
            this.state.keyDocs.sort((a, b) => {
                if (a.fileName.toUpperCase() > b.fileName.toUpperCase()) return 1
                return -1;
            })
                .map(x => this.renderKeyDocLine(x))

        return (
            <div className='key-doc-box'>
                {this.state.assigningDocId &&
                    <KeyDocAssigner projectId={this.props.projectId}
                        assign={this._assignKeyDoc} close={this._close}
                        keyDocName={this.state.assigningDocName || ''}
                    />
                }
                <div>Key Documents</div>
                <hr />
                <div>
                    {keyDocLines}
                </div>
            </div>
        )
    }

    private renderKeyDocLine(doc: KeyDocViewModel) {
        const split = doc.path.split('content')
        let relPath = '/files' + split[split.length - 1];
        if (relPath) relPath = relPath.replace(/\\/g, '/').replace(/#/g, '%23');
        let displayFileName = doc.fileName.substr(0, 45)
        if (doc.fileName.length > 45) displayFileName += '...';

        return (
            <div key={doc.id} className='key-doc-line'>
                <div>{doc.description}: </div>
                <div><a href={relPath} target='_blank'>{displayFileName}</a></div>
                {!this.props.readonly &&
                    <this.noVendorComponent>
                        <span style={{ float: 'right' }}>
                            <a onClick={this._toggleAssignViewer.bind(null, doc.id, doc.description)}>{(doc.path && doc.path.length) ? 're-assign' : 'assign'}</a>
                        </span>
                    </this.noVendorComponent>
                }
            </div>
        )
    }
}