//import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from "redux";
import { AppThunkAction } from "./";
import {
	ProjectVm,
	ErrorAction,
	UserProjectColumn,
	ListItem,
	FilterOption,
} from "../interfaces/interfaces";
import "../helpers/extensions";
import { DateRange } from "moment-range";

export interface Store {
	projects: ProjectVm[];
	userProjectColumns: UserProjectColumn[];
	allProjectProps: ListItem[];
	//unfilteredProjects: ProjectVm[],
	filterOptions?: FilterOption[];
	isLoading: boolean;
	isSaving: boolean;
	message?: string;
}

interface RequestProjectsAction {
	type: "REQUEST_PROJECTS";
}

interface ReceiveProjectsAction {
	type: "RECEIVE_PROJECTS";
	projects: ProjectVm[];
}

interface ReceiveUserColumnsAction {
	type: "RECEIVE_USER_COLUMNS";
	userColumns: UserProjectColumn[];
	loading?: boolean;
}

interface GetAllProjectColumnsAction {
	type: "RECEIVE_ALL_PROJECT_COLUMNS";
	columns: ListItem[];
}

interface AddProjectAction {
	type: "ADD_PROJECT";
	projects: ProjectVm[];
}

interface EditEntryAction {
	type: "EDIT_ENTRY";
	projects: ProjectVm[];
	//unfilteredProjects: ProjectVm[]
}

interface FilterProjectsAction {
	type: "FILTER_PROJECTS";
	//projects: ProjectVm[],
	filterOptions?: FilterOption[];
}

interface ResetAction {
	type: "RESET";
}

interface SavingAction {
	type: "SAVING";
}

interface ClearProjectMessageAction {
	type: "CLEAR_PROJ_MESSAGE";
}

interface ProjectsSavedAction {
	type: "PROJECT_SAVED";
	message: string;
}

type KnownAction =
	| RequestProjectsAction
	| ReceiveProjectsAction
	| AddProjectAction
	| EditEntryAction
	| SavingAction
	| FilterProjectsAction
	| ResetAction
	| ErrorAction
	| ClearProjectMessageAction
	| ProjectsSavedAction
	| ReceiveUserColumnsAction
	| GetAllProjectColumnsAction;

const valueIsChanged = (
	projects: ProjectVm[],
	entryId: number,
	field: string,
	value: string
) => {
	const changed = true;

	return changed;
};

export const actionCreators = {
	getProjects:
		(getComplete?: boolean): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			//if (!getState().projects.projects || !getState().projects.projects.length) {
			fetch(`api/Project/Get?getComplete=${getComplete}`)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) dispatch({ type: "RECEIVE_PROJECTS", projects: data });
					else dispatch({ type: "ERROR", message: data.message });
				});
			dispatch({ type: "REQUEST_PROJECTS" });
			//}
		},
	getAllProjectColumns: (): AppThunkAction<KnownAction> => (dispatch) => {
		fetch(`api/UserProjectColumn/AllProperties`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk)
					dispatch({ type: "RECEIVE_ALL_PROJECT_COLUMNS", columns: data });
				else dispatch({ type: "ERROR", message: data.message });
			});
	},
	getUserColumns: (): AppThunkAction<KnownAction> => (dispatch) => {
		fetch(`api/UserProjectColumn/GetUserColumns`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk)
					dispatch({ type: "RECEIVE_USER_COLUMNS", userColumns: data });
				else dispatch({ type: "ERROR", message: data.message });
			});
	},
	addProject:
		(info: ProjectVm): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Project/Add`, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify(info),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const projects = getState().projects.projects.slice();
						projects.push(data);
						dispatch({ type: "ADD_PROJECT", projects: projects });
					} else {
						dispatch({ type: "ERROR", message: data.message });
					}
				});
			dispatch({ type: "SAVING" });
		},
	addFavorite:
		(id: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Project/Favorite?id=${id}`, { method: "POST" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const projects = getState()
							.projects.projects.slice()
							.map((p) => {
								if (p.id === id) p.userFavorite = true;
								return p;
							});
						//let unfiltered = getState().projects.unfilteredProjects.slice().map(p => {
						//    if (p.projectId === id) p.userFavorite = true;
						//    return p
						//});
						dispatch({ type: "EDIT_ENTRY", projects: projects });
					} else {
						dispatch({ type: "ERROR", message: data.message });
					}
				});
		},
	removeFavorite:
		(id: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Project/RemoveFavorite?id=${id}`, { method: "DELETE" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const projects = getState()
							.projects.projects.slice()
							.map((p) => {
								if (p.id === id) p.userFavorite = false;
								return p;
							});
						//let unfiltered = getState().projects.unfilteredProjects.slice().map(p => {
						//    if (p.projectId === id) p.userFavorite = false;
						//    return p
						//});
						dispatch({ type: "EDIT_ENTRY", projects: projects });
					} else {
						dispatch({ type: "ERROR", message: data.message });
					}
				});
		},
	addColumn:
		(col: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			fetch(`api/UserProjectColumn/AddColumn?propName=${col}`, {
				method: "POST",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({
							type: "RECEIVE_USER_COLUMNS",
							userColumns: data,
							loading: false,
						});
					else dispatch({ type: "ERROR", message: data.message });
				});
			dispatch({ type: "REQUEST_PROJECTS" });
		},
	removeColumn:
		(col: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			fetch(`api/UserProjectColumn/RemoveColumn?propName=${col}`, {
				method: "DELETE",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({
							type: "RECEIVE_USER_COLUMNS",
							userColumns: data,
							loading: false,
						});
					else dispatch({ type: "ERROR", message: data.message });
				});
			dispatch({ type: "REQUEST_PROJECTS" });
		},
	tempReorder:
		(id: string, newPos: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			const oldIdx = getState().projects.userProjectColumns.findIndex(
				(x) => x.projectPropertyName === id
			);
			const shiftingLeft = newPos < oldIdx;
			const newColumns = JSON.parse(
				JSON.stringify(getState().projects.userProjectColumns)
			).map((x: UserProjectColumn) => {
				if (x.columnOrder === oldIdx) x.columnOrder = newPos;
				else {
					if (shiftingLeft && x.columnOrder > newPos && x.columnOrder < oldIdx)
						x.columnOrder += 1;
					else if (
						!shiftingLeft &&
						x.columnOrder > oldIdx &&
						x.columnOrder < newPos
					)
						x.columnOrder += -1;
				}

				return x;
			});

			dispatch({ type: "RECEIVE_USER_COLUMNS", userColumns: newColumns });
		},
	reorderColumns:
		(id: string, newPos: number): AppThunkAction<KnownAction> =>
		(dispatch) => {
			fetch(
				`api/UserProjectColumn/ReorderColumns?propName=${id}&newPosition=${newPos}`,
				{ method: "PUT" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({
							type: "RECEIVE_USER_COLUMNS",
							userColumns: data,
							loading: false,
						});
					else dispatch({ type: "ERROR", message: data.message });
				});
			dispatch({ type: "REQUEST_PROJECTS" });
		},
	addEntry:
		(projectId: number, milestoneId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/AddEntry?projectId=${projectId}&milestoneId=${milestoneId}`,
				{
					method: "POST",
				}
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const projects = getState()
							.projects.projects.slice()
							.map((p) => {
								if (p.id == projectId) p.milestones.push(data);
								return p;
							});
						//let unfiltered = getState().projects.unfilteredProjects.slice().map(p => {
						//    if (p.projectId == projectId)
						//        p.milestones.push(data)
						//    return p
						//});
						//projects.push(data);
						dispatch({ type: "EDIT_ENTRY", projects: projects });
					} else {
						dispatch({ type: "ERROR", message: data.message });
					}
				});
		},
	updateEntry:
		(
			entryId: number,
			field: string,
			value: string
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			if (valueIsChanged(getState().projects.projects, entryId, field, value)) {
				fetch(`api/Milestone/UpdateEntry?entryId=${entryId}`, {
					method: "PUT",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						fieldName: field,
						value: value,
					}),
				})
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							/* We need to update both projects and unfilteredprojects arrays so they're consistent */
							const projects = getState()
								.projects.projects.slice()
								.map((p) => {
									p.milestones = p.milestones.map((m) => {
										if (m.entryId == entryId) m = data;
										return m;
									});
									return p;
								});
							//let unfiltered = getState().projects.unfilteredProjects.slice().map(p => {
							//    p.milestones = p.milestones.map(m => {
							//        if (m.entryId == entryId)
							//            m = data
							//        return m;
							//    })
							//    return p
							//});
							//projects.push(data);
							dispatch({ type: "EDIT_ENTRY", projects: projects });
						} else {
							dispatch({ type: "ERROR", message: data.message });
						}
					});
				dispatch({ type: "SAVING" });
			}
		},
	deleteEntry:
		(pId: number, entryId: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Milestone/DeleteEntry?projectId=${pId}&entryId=${entryId}`, {
				method: "DELETE",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const projects = getState()
							.projects.projects.slice()
							.map((p) => {
								if (p.id == pId) p.milestones = data;
								return p;
							});
						//const unfiltered = getState().projects.unfilteredProjects.slice()
						//    .map(p => {
						//        if (p.projectId == pId)
						//            p.milestones = data;
						//        return p
						//    })
						dispatch({ type: "EDIT_ENTRY", projects: projects });
					} else {
						dispatch({ type: "ERROR", message: data.message });
					}
				});
		},
	toggleMilestones:
		(id: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			const toggled = getState()
				.projects.projects.slice()
				.map((p) => {
					if (p.id == id) p.showMilestones = !p.showMilestones;
					return p;
				});
			dispatch({ type: "RECEIVE_PROJECTS", projects: toggled });
		},
	uploadMsFiles:
		(mId, entryId, form: FormData): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Milestone/Upload?pid=${
					getState().detail.detail.id
				}&mId=${mId}&entryId=${entryId}`,
				{ method: "POST", body: form }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({ type: "PROJECT_SAVED", message: "File(s) Uploaded" });
					else dispatch({ type: "ERROR", message: data.message });
				});
		},
	filter:
		(
			field: string,
			val: string | number | DateRange | null,
			dataType: string
		): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			let filterExists = false;
			let filters = getState().projects.filterOptions
				? getState()
						.projects.filterOptions!.slice()
						.map((x: FilterOption) => {
							if (x.prop === field) {
								filterExists = true;
								if (val) {
									x.value = val;
									return x;
								} else return undefined;
							}
							return x;
						})
				: ([] as FilterOption[]);

			if (!filterExists && val !== null)
				filters.push({
					prop: field,
					value: val,
					dataType: dataType,
				} as FilterOption);

			filters = filters.filter((x) => x !== undefined);
			// @ts-ignore
			dispatch({ type: "FILTER_PROJECTS", filterOptions: filters });
		},
	//filterProjectsGlobal: (filterVal: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
	//    const filtered = getState().projects.unfilteredProjects.slice()
	//        .filterByMultiStringProp(["projectNumber", "internalPM", "externalPM", "brandName", "address", "projectName", "clientName"], filterVal)
	//    dispatch({ type: 'FILTER_PROJECTS', projects: filtered });
	//},
	//filterProjects: (field: string, val: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
	//    let filteredProps = (getState().projects.filteredProps || []).slice();
	//    if (filteredProps && filteredProps.findIndex(x => x.prop == field) !== -1) {
	//        filteredProps[filteredProps.findIndex(x => x.prop == field)].filterVal = val;
	//    }
	//    else {
	//        filteredProps.push({ prop: field, filterVal: val})
	//    }
	//    let filtered = getState().projects.unfilteredProjects.slice()
	//    for (let i = 0; i < filteredProps.length; ++i)
	//        filtered = filtered.filterByStringProp(filteredProps[i].prop, filteredProps[i].filterVal)
	//    dispatch({ type: 'FILTER_PROJECTS', projects: filtered, filteredProps: filteredProps });
	//},
	clearFilters: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "FILTER_PROJECTS", filterOptions: undefined });
	},
	setErrorMessage:
		(err: string): AppThunkAction<KnownAction> =>
		(dispatch) => {
			dispatch({ type: "ERROR", message: err });
		},
	clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "CLEAR_PROJ_MESSAGE" });
	},
};

const unloadedState: Store = {
	projects: [],
	//unfilteredProjects: [],
	userProjectColumns: [],
	allProjectProps: [],
	isLoading: false,
	isSaving: false,
};

//@ts-ignore
export const reducer: Reducer<Store> = (
	state: Store,
	incomingAction: Action
) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "REQUEST_PROJECTS":
			return { ...state, isLoading: true, message: "" };
		case "RECEIVE_PROJECTS":
			return {
				...state,
				unfilteredProjects: action.projects,
				projects: action.projects,
				isLoading: false,
				message: "",
			};
		case "RECEIVE_USER_COLUMNS":
			return {
				...state,
				userProjectColumns: action.userColumns,
				isLoading:
					action.loading !== undefined ? action.loading : state.isLoading,
			};
		case "RECEIVE_ALL_PROJECT_COLUMNS":
			return { ...state, allProjectProps: action.columns };
		case "ADD_PROJECT":
			return {
				...state,
				unfilteredProjects: action.projects,
				projects: action.projects,
				message: "Added Project",
				isSaving: false,
			};
		case "EDIT_ENTRY":
			return {
				...state,
				//unfilteredProjects: action.unfilteredProjects,
				projects: action.projects,
				message: "Saved",
				isSaving: false,
			};
		case "FILTER_PROJECTS":
			return {
				...state,
				//projects: action.projects,
				filterOptions: action.filterOptions,
				message: undefined,
			};
		case "SAVING":
			return { ...state, isSaving: true, message: undefined };
		case "RESET":
			return { ...state, projects: [], message: "" };
		case "CLEAR_PROJ_MESSAGE":
			return { ...state, message: undefined };
		case "ERROR":
			return {
				...state,
				isSaving: false,
				message: action.message,
			};
		case "PROJECT_SAVED":
			return { ...state, message: action.message, isSaving: false };
		default: {
			const exhaustiveCheck: never = action;
		}
	}
	return state || unloadedState;
};
