import * as React from 'react'
import { SubmittalGrid } from './SubmittalGrid';
import { Tabs, TabLink, TabContent } from '../tabs';
import SubmittalPackageGrid from './SubmittalPackageGrid';
import { KnownRoles } from '../auth/auth';
import { MyAuthElement } from '../auth/Authorization';
import { SubmittalCustomFieldsGrid } from './SubmittalCustomFieldsGrid';

interface State {    
}

export default class SubmittalHome extends React.Component<any, State> {
    activeTabStyle = {
        'backgroundColor': 'rgb(0,99,66)',
        'border': 'solid 0.5px rgb(0,56,119)',
        'color': 'white'
    };

    constructor(props) {
        super(props);
        this.state = { loading: false }
    }    

    csgComp = MyAuthElement([KnownRoles.Admin, KnownRoles.CSGPM, KnownRoles.CoreSuperUser])

    render() {        
        return (
            <div> 
                <Tabs name='submittal-tabs' onChange={() => { }}
                    renderActiveTabContentOnly={true} activeLinkStyle={this.activeTabStyle}
                >
                    <div className='tab-links bordered'>
                        <TabLink to='items' component='span'><span>Items</span></TabLink>                        
                        <TabLink to='packages' component='span'><span>Packages</span></TabLink>
                        <this.csgComp componentType='span'><TabLink to='customfields' component='span'><span>Custom Fields</span></TabLink></this.csgComp>
                    </div>
                    <div className='content bordered'>
                        <TabContent for='items'>
                            <SubmittalGrid />                            
                        </TabContent>
                        <TabContent for='packages'>
                            <SubmittalPackageGrid />
                        </TabContent> 
                        <TabContent for='customfields'>                            
                            <SubmittalCustomFieldsGrid />
                        </TabContent> 
                    </div>                    
                </Tabs>                  
            </div>
        )
    }     
}