import * as React from "react";
import Modal from "./Modal";

interface Props {
  notes: string;
  save: any;
  entryId?: number;
  entityName?: string;
  maxDisplayLength?: number;
  disabled?: boolean;
}

interface State {
  editing: boolean;
}

export default class Notepad extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
    };
  }

  _save = () => {
    const textarea = document.querySelector(
      "#notepad-editor textarea"
    ) as HTMLTextAreaElement;
    if (textarea) this.props.save(textarea.value);
    this.setState({ editing: false });
  };

  _toggleEdit = () => {
    this.setState({
      editing: !this.state.editing,
    });
  };

  render() {
    const { editing } = this.state;
    const { notes, maxDisplayLength } = this.props;

    return (
      <div className="notepad">
        {editing && this.renderEditModal()}
        {maxDisplayLength !== 0 && (
          <div className="notepad-static-text truncate">
            {notes}
          </div>
        )}
        <span
          style={{ float: "right", padding: "5px 5px 0px 0px" }}
          className="fas fa-pencil-alt"
          onClick={this._toggleEdit}
          title="View/edit notes"
        ></span>
      </div>
    );
  }

  private renderEditModal() {
    const { entityName, notes } = this.props;

    return (
      <Modal id="notepad-editor" dialogStyle={{ maxWidth: "70%" }}>
        <div className="modal-header">
          <h4>{entityName} Notes</h4>
        </div>
        <div className="modal-body viewer-body">
          <textarea
            className="form-control"
            rows={8}
            maxLength={4096}
            disabled={this.props.disabled}
            defaultValue={notes}
          ></textarea>
        </div>
        <div className="modal-footer">
          {!this.props.disabled && (
            <span className="btn btn-sm btn-green" onClick={this._save}>
              Save
            </span>
          )}
          <span
            className="btn btn-sm btn-default btn-outline-secondary"
            onClick={this._toggleEdit}
          >
            Close
          </span>
        </div>
      </Modal>
    );
  }
}
