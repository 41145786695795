import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import * as DetailStore from "../store/projectDetail";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import {
	ProjectDetailVm,
	MaterialEntryVm,
	MilestoneEntryVm,
	ListItem,
} from "../interfaces/interfaces";
import AttachmentViewer from "../components/AttachmentViewer";
import { Loader } from "../components/Loader";
import { sum } from "../helpers/formulas";
import { toMoney } from "../helpers/formatters";
import UserCard from "../components/UserCard";
import AlertRibbon from "../components/AlertRibbon";
import { NoAuthElement } from "../auth/Authorization";
import { KnownRoles, hasRole } from "../auth/auth";
import * as UserStore from "../store/user";
import KeyDocBox from "../projects/KeyDocBox";
import { MilestoneList } from "../components/MilestoneList";

interface State {
	showAttachments: boolean;
	attchType?: number;
}

interface JoinedProps {
	detail: DetailStore.Store;
	user: UserStore.Store;
}

type Props = JoinedProps &
	typeof DetailStore.actionCreators &
	RouteComponentProps<{ id: string }>;

class SnapshotView extends React.Component<Props, State> {
	noOwnerComponent = NoAuthElement([KnownRoles.Owner]);
	isOwner = hasRole(this.props.user.user, [KnownRoles.Owner]);

	constructor(props: Props) {
		super(props);
		this.state = {
			showAttachments: false,
		};
	}

	componentWillMount() {
		const pId = parseInt(this.props.match.params.id) || 0;
		this.props.getDetail(pId);
	}

	componentWillUnmount() {
		this.props.clear();
	}

	private toggleAttachments = (type?: number) => {
		this.setState({
			showAttachments: !this.state.showAttachments,
			attchType: !this.state.showAttachments && type ? type : undefined,
		});
	};

	public render() {
		const detailSection = this.renderDetailSection(this.props.detail.detail);
		const alertRibbon = (
			<AlertRibbon alerts={this.props.detail.detail.alerts} />
		);
		const milestoneSection = this.props.detail.detail.id ? (
			//@ts-ignore
			<MilestoneList projectId={this.props.detail.detail.id} disabled={true} />
		) : (
			[]
		);

		return (
			<div>
				<Loader loading={this.props.detail.isLoading} />
				{this.state.showAttachments && (
					<AttachmentViewer
						pId={this.props.detail.detail.id}
						//@ts-ignore
						milestoneId={undefined}
						close={this.toggleAttachments}
						title={this.props.detail.detail.projectNumber}
					/>
				)}
				<div className="pageHeader">
					<div className="col-sm-10">
						<p>
							{this.props.detail.detail.projectNumber}{" "}
							<span>
								<Link to={`/project/${this.props.detail.projId}`}>
									Full Detai
								</Link>
							</span>
						</p>
					</div>
					<div className="col-sm-2">
						<span
							className="btn btn-sm btn-default glyphicon glyphicon-picture"
							onClick={this.toggleAttachments.bind(null, 1)}
						></span>
						<span
							className="btn btn-sm btn-default glyphicon glyphicon-file"
							onClick={this.toggleAttachments.bind(null, 2)}
						></span>
					</div>
				</div>
				{alertRibbon}
				<hr />
				<div className="section project-detail">
					<h4>Details</h4>
					{detailSection}
				</div>
				<div className="section project-milestones">
					<h4>Milestones</h4>
					{milestoneSection}
				</div>
			</div>
		);
	}

	private renderDetailSection(detail: ProjectDetailVm) {
		const start =
				detail.startDate && detail.startDate.length
					? new Date(detail.startDate).toISOString().substring(0, 10)
					: "",
			end =
				detail.endDate && detail.endDate.length
					? new Date(detail.endDate).toISOString().substring(0, 10)
					: "";
		const currentCost =
			detail.materials && detail.materials.length
				? detail.materials.map((x) => x.cost).reduce(sum)
				: 0;

		const userEmailSubject = `${detail.projectNumber} ${detail.name} - ${detail.address} ${detail.city}, ${detail.state}`;
		const userEmailBody = `To vue the project, please visit: ${window.location.href}`;

		return (
			<div>
				<div className="col-sm-12 row">
					<div className="col-sm-2 form-group">
						<div className="inline-label">Client:</div>
						<div className="col-sm-9">{detail.clientName}</div>
					</div>
					<div className="col-sm-2 form-group">
						<div className="inline-label">Brand/End User: </div>
						<div className="col-sm-9">{detail.brandName}</div>
					</div>
					<div className="col-sm-2 form-group">
						<div className="inline-label">Project Desc: </div>
						<div className="col-sm-9">{detail.name}</div>
					</div>
					<div className="col-sm-2 form-group">
						<div className="inline-label">Site Id: </div>
						<div className="col-sm-9">{detail.siteId}</div>
					</div>
					<div className="col-sm-2 form-group">
						<div className="inline-label">Budget: </div>
						<div className="col-sm-9">{toMoney(detail.budget)}</div>
					</div>

					<div className="col-sm-2 form-group">
						<div className="inline-label">Cost: </div>
						<div className="col-sm-9">{toMoney(currentCost)}</div>
					</div>
				</div>
				<div className="col-sm-8 flex-row">
					<div className="col-sm-12 row">
						<div className="col-sm-3 form-group">
							<div className="inline-label">Type: </div>
							<div className="col-sm-9">{detail.projectType}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">Start Date: </div>
							<div className="col-sm-9">{start}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">End Date: </div>
							<div className="col-sm-9">{end}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">Status: </div>
							<div className="col-sm-9">{detail.status}</div>
						</div>
					</div>

					<div className=" col-sm-12 row">
						<div className="col-sm-3 form-group">
							<div className="inline-label">Address: </div>
							<div className="col-sm-9">{detail.address}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">City: </div>
							<div className="col-sm-9">{detail.city}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">State: </div>
							<div className="col-sm-9">{detail.state}</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">Zip: </div>
							<div className="col-sm-9">{detail.zip}</div>
						</div>
					</div>
					<div className="col-sm-12 row">
						<div className="col-sm-3 form-group">
							<div className="inline-label">Internal PM: </div>
							<div className="col-sm-9">
								<UserCard
									name="internalPmId"
									defaultValue={detail.internalPmId}
									contacts={this.props.detail.internalPMs}
									emailSubject={userEmailSubject}
									emailBody={userEmailBody}
									disabled={true}
								/>
							</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">Client PM: </div>
							<div className="col-sm-9">
								<UserCard
									name="externalPmId"
									defaultValue={detail.externalPmId}
									contacts={this.props.detail.clientPMs}
									emailSubject={userEmailSubject}
									emailBody={userEmailBody}
									disabled={true}
								/>
							</div>
						</div>
						<div className="col-sm-3 form-group">
							<div className="inline-label">Owner Rep: </div>
							<div className="col-sm-9">
								<UserCard
									name="ownerRepId"
									defaultValue={detail.ownerRepId}
									contacts={this.props.detail.ownerReps}
									emailSubject={userEmailSubject}
									emailBody={userEmailBody}
									disabled={true}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-3">
					<KeyDocBox projectId={this.props.detail.projId} readonly={true} />
				</div>
			</div>
		);
	}
}
//@ts-ignore
export default connect(
	(state: ApplicationState) => {
		return { detail: state.detail, user: state.user };
	},
	DetailStore.actionCreators
	// @ts-ignore
)(SnapshotView) as typeof SnapshotView;
