import React, {
  ForwardedRef,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import FilterableSelect from 'src/components/FilterableSelect';
import {
  ListItem,
  NewListItem,
  QueryFilterCondition,
  SchemaPropertyInfo,
  SchemaTableInfo,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';

const StyledDiv = styled.div({
  display: 'flex',
  width: '100%',
  maxWidth: 'calc(100% - 150px)',
  '& input:invalid': {
    borderColor: '#dc3545',
    ':focus': { boxShadow: '0 0 0 0.25rem rgb(220 53 69 / 25%)' },
  },
});

const StyledRow = styled.div({
  marginBottom: '8px',
});

interface ReportQueryFilterRowProps {
  tables: NewListItem[];
  fields: Record<string, SchemaPropertyInfo[]>;
  operators: ListItem[];
  condition: QueryFilterCondition;
  onRemove: () => void;
  onUpdate: (condition: QueryFilterCondition) => void;
}

export interface ForwardRefFilterProps {
  getState: () => FilterObject;
  isInvalid: boolean;
}

interface FilterObject {
  sourceTableName: string;
  sourceQualifiedAssemblyName: string;
  propertyName: string;
  operator: string;
  value: string;
}

export const ReportQueryFilterRow = forwardRef(
  (
    {
      tables,
      fields,
      operators,
      condition,
      onRemove,
      onUpdate,
    }: ReportQueryFilterRowProps,
    ref: ForwardedRef<ForwardRefFilterProps>
  ) => {
    const [selectedProperty, setSelectedProperty] = useState<string>(
      condition ? condition.propertyName : ''
    );
    const [selectedOperator, setSelectedOperator] = useState<string>(
      condition ? condition.operator : ''
    );
    const [value, setValue] = useState<string>(
      condition ? condition.value : ''
    );

    const defaultTable = useMemo(() => {
      const tableId = tables.find(
        (x) =>
          (
            x.item as {
              tableName: string;
              assemblyQualifiedName: string;
            }
          ).tableName == condition.sourceTableName
      )?.id;

      if (tableId) {
        return tableId.toString();
      } else {
        return '';
      }
    }, [condition, tables]);
    const [selectedTable, setSelectedTable] = useState<string>(defaultTable);

    useImperativeHandle(
      ref,
      () => {
        return {
          getState() {
            return {
              sourceTableName: selectedTableItem?.tableName ?? '',
              sourceQualifiedAssemblyName:
                selectedTableItem?.assemblyQualifiedName ?? '',
              propertyName: selectedProperty,
              operator: selectedOperator,
              value: value,
            };
          },
          isInvalid:
            selectedTable == '' ||
            selectedProperty == '' ||
            selectedOperator == '' ||
            !operators.some((x) => x.value == selectedOperator) ||
            value == '',
        };
      },
      [selectedTable, selectedProperty, selectedOperator, value]
    );

    const selectedTableItem = useMemo(() => {
      return tables.find((x) => x.id == selectedTable)?.item as {
        tableName: string;
        assemblyQualifiedName: string;
      };
    }, [selectedTable]);

    const availableFields = useMemo(() => {
      const f = fields[selectedTable];

      if (f) {
        return f.map((x) => new NewListItem(x.propertyName, x.displayName, x));
      } else {
        return [];
      }
    }, [selectedTable, fields]);

    useEffect(() => {
      const updatedCondition: QueryFilterCondition = {
        ...condition,
        sourceTableName: selectedTableItem?.tableName ?? '',
        sourceQualifiedAssemblyName:
          selectedTableItem?.assemblyQualifiedName ?? '',
        propertyName: selectedProperty,
        operator: selectedOperator,
        value: value,
      };
      onUpdate(updatedCondition);
    }, [
      selectedTable,
      selectedProperty,
      selectedOperator,
      value,
      selectedTableItem,
    ]);

    useEffect(() => {
      setSelectedTable(defaultTable);
      setSelectedProperty(condition.propertyName);
      setSelectedOperator(condition.operator);
      setValue(condition.value);
    }, [condition, defaultTable]);

    return (
      <StyledRow>
        <div className="row">
          <StyledDiv className="col">
            <FilterableSelect
              id={''}
              items={tables}
              onChange={(x) => setSelectedTable(x)}
              required={true}
              defaultVal={defaultTable}
              growUpwards={true}
            ></FilterableSelect>
          </StyledDiv>
          <StyledDiv className="col">
            <FilterableSelect
              id={''}
              items={availableFields}
              onChange={(x) => setSelectedProperty(x)}
              required={true}
              defaultVal={selectedProperty}
              growUpwards={true}
            ></FilterableSelect>
          </StyledDiv>
          <StyledDiv className="col">
            <FilterableSelect
              id={''}
              items={operators}
              onChange={(x) => setSelectedOperator(x)}
              required={true}
              defaultVal={selectedOperator}
              growUpwards={true}
              openField={true}
            ></FilterableSelect>
          </StyledDiv>
          <StyledDiv className="col">
            <input
              required={true}
              onChange={(e) => setValue(e.currentTarget.value)}
              className="form-control"
              value={value}
            ></input>
          </StyledDiv>
          <span
            style={{ borderRadius: '50%' }}
            className=" btn btn-sm btn-round fas fa-trash btn-background-hover"
            title="Delete this field"
            onClick={() => onRemove()}
          ></span>
        </div>
      </StyledRow>
    );
  }
);
