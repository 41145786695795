import * as React from 'react';
import * as Store from '../store/admin';
import { MessageBox } from '../components/MessageBox';
import { ListItem, UserInfo } from '../interfaces/interfaces';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import KPI from '../charts/KPI';

type Props =
    Store.Store
    & typeof Store.actionCreators

interface State {
    clientId: number
    clientUsers?: UserInfo[],
    licenseCount: number
    message?: string
}

class ClientLicenseForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            clientId: 0,
            licenseCount: 0
        }
    }

    _changeClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const opt = (e.currentTarget as HTMLSelectElement).querySelector('option:checked') as HTMLOptionElement;
        if (opt) {
            fetch(`api/Client/GetUsers?id=${opt.value}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            clientUsers: data.users,
                            licenseCount: data.licenseCount,
                            clientId: parseInt(opt.value),
                            message: undefined
                        })
                    }
                    else {
                        this.setState({
                            message: data.message
                        })
                    }
                })
        }
    }

    _save = (e: React.ChangeEvent<HTMLInputElement>) => {
        const chkBox = e.currentTarget as HTMLInputElement
        const userId = e.currentTarget.getAttribute('data-userid')
        const { clientId } = this.state
        fetch(`api/Client/ToggleUserLicense?id=${clientId}&userId=${userId}`,
            {
                method: 'PUT'
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        clientUsers: data,
                        message: 'Saved'
                    })
                }
                else {
                    /* We have to un-do the checking since the boxes won't re-render */
                    chkBox.checked = !chkBox.checked;
                    this.setState({
                        message: data.message
                    })
                }
            })
    }

    _changeLicenseCount = (e: React.FocusEvent<HTMLInputElement>) => {
        const newVal = e.currentTarget.value;
        fetch(`api/Client/LicenseCount?id=${this.state.clientId}&count=${newVal}`,
            {
                method: 'PUT'
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        licenseCount: parseInt(newVal),
                        message: 'Saved'
                    })
                }
                else {
                    this.setState({
                        message: data.message
                    })
                }
            });
    }


    render() {
        const { clientUsers } = this.state;
        const clientOpts = this.props.companies && this.props.companies.length ?
            this.props.companies.map(c => <option key={c.id} value={c.id}>{c.value}</option>) :
            []

        const userInputs = clientUsers ?
            clientUsers.map(x => {
                return (
                    <div>
                        <input key={x.userId + '_' + x.userActivated} type='checkbox' data-userid={x.userId}
                            defaultChecked={x.userActivated} onChange={this._save}
                        />
                        {x.fullName}
                    </div>
                )
            }) : []

        const usedLicenses = clientUsers ? clientUsers.filter(x => x.userActivated).length : 0;

        return (
            <div>                
                <div className="form-header">
                    <h4 className="modal-title">Edit Client Licenses</h4>
                </div>
                <hr />
                <div style={{ display: 'flex' }}>
                    <div className="form-body col-sm-3">
                        <div className='form-group'>
                            <label>Client</label>
                            <select className='form-control' name='clientId'
                                onChange={this._changeClient} style={{ width: '80%' }}>
                                <option value='0'>Select...</option>
                                {clientOpts}
                            </select>
                            <br />
                            <label>License Count</label>
                            <input key={this.state.clientId} type='number'
                                className='form-control' style={{ width: '33%' }}
                                onBlur={this._changeLicenseCount}
                                defaultValue={this.state.licenseCount.toString()} />
                        </div>                        
                    </div>
                    <div className='form-body col-sm-3'>
                        <div className='col-sm-10'>
                            <KPI label='License Usage' value={`${usedLicenses} / ${this.state.licenseCount.toString()}`} />
                        </div>
                    </div>
                    <div className='form-body col-sm-6'>
                        <div className='form-group flex-columns'>
                            <label>Users</label><br />
                            {userInputs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.admin,
    Store.actionCreators
)(ClientLicenseForm)