import * as React from 'react';
import { AppThunkAction } from './index';
import { MetaFieldInfo } from '../components/CustomizableGrid';
import { MetaObjectType } from '../interfaces/enums';
import { DashboardScope } from '../dashboard/DashboardGraphs';

export interface MetaStore {
    allObjects: MetaObject[],    
}

export interface MetaObject {
    name: string,
    type: MetaObjectType,
    fields: MetaFieldInfo[],
}

interface RequestObjectsAction {
    type: 'REQUEST_OBJECTS',
}

interface ReceiveObjectsAction {
    type: 'RECEIVE_OBJECTS',
    objects: MetaObject[],
}

type KnownAction = RequestObjectsAction | ReceiveObjectsAction

export const actionCreators = {
    getAllObjects: (scope: DashboardScope): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (getState().meta.allObjects.length === 0 || getState().graphs.scope !== scope) {
            fetch(`api/meta/objects?scope=${scope}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) dispatch({ type: 'RECEIVE_OBJECTS', objects: data })
                })
        }
    }
}

const unloadedStore: MetaStore = {
    allObjects: []
}


//@ts-ignore
export const reducer: Reducer<MetaStore> = (state: MetaStore, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {        
        case 'REQUEST_OBJECTS':
            return { ...state, }
        case 'RECEIVE_OBJECTS':
            return { ...state, allObjects: action.objects }       
        default:{
            const exhaustiveCheck: never = action;
        }
    }
    return state || unloadedStore;
}