import * as React from "react";
import {
	ProjectVm,
	UserInfo,
	UserProjectColumn,
	PropertyFormatType,
	ListItem,
	SortInfo,
} from "../interfaces/interfaces";
import { MilestoneList } from "../components/MilestoneList";
import { KnownRoles, hasRole } from "../auth/auth";
import { NoAuthElement, MyAuthElement } from "../auth/Authorization";
import { NavLink } from "react-router-dom";
import * as cx from "classnames";
import { formatDate, toMoney, camelize } from "../helpers/formatters";
import Modal from "../components/Modal";

interface MyProps {
	projects: ProjectVm[];
	user: UserInfo;
	columns: UserProjectColumn[];
	allColumns: ListItem[];
	toggleAttachmentViewer: (
		e: React.MouseEvent<any>,
		pId: number,
		mId?: number
	) => void;
	toggleMilestones: (e: React.MouseEvent<HTMLElement>, id: number) => void;
	addFavorite: (e: React.MouseEvent<HTMLElement>, id: number) => void;
	removeFavorite: (e: React.MouseEvent<HTMLElement>, id: number) => void;
	reorderColumns: (id: string, newPos: number) => void;
	tempReorder: (id: string, newPos: number) => void;
	addColumn: (col: string) => void;
	removeColumn: (col: string) => void;
	editingColumns: boolean;
	onSort: (name: string) => void;
}

interface State {
	showAddColumnModal: boolean;
}

export default class ProjectList extends React.Component<MyProps, State> {
	pmComponent = MyAuthElement([
		KnownRoles.Admin,
		KnownRoles.CSGPM,
		KnownRoles.ClientAdmin,
		KnownRoles.ClientPM,
		KnownRoles.CoreSuperUser,
	]);
	noVendorComponent = NoAuthElement([KnownRoles.Vendor]);

	constructor(props) {
		super(props);
		this.state = {
			showAddColumnModal: false,
		};
	}

	_addColumn = () => {
		const sel = document.getElementById("add-column-ddl") as HTMLSelectElement,
			opt = sel.querySelector("option:checked") as HTMLOptionElement;

		if (opt.value !== "") {
			this.props.addColumn(opt.value);
			this.setState({ showAddColumnModal: false });
		}
	};

	_removeColumn = (col: string) => this.props.removeColumn(col);

	_toggleAddColumnModal = () =>
		this.setState({ showAddColumnModal: !this.state.showAddColumnModal });

	render() {
		//const { startFilterGt, endFilterGt, startDateFilter, endDateFilter } = this.state;
		const { projects, columns, editingColumns } = this.props,
			{ showAddColumnModal } = this.state;

		const lines =
			projects && projects.length
				? projects.map((x) => this.renderProjectLine(x))
				: [];

		const gridClass = `p-col-${columns.length}`;

		const selectedHeaders = editingColumns
			? columns.map((x) => {
					return (
						<div
							className={cx(
								gridClass,
								"mobile-hide",
								"project-header-cell",
								"editing"
							)}
							key={x.projectPropertyName}
						>
							{x.columnOrder > 1 && (
								<span
									className="fas fa-long-arrow-alt-left"
									onClick={() =>
										this.props.reorderColumns(
											x.projectPropertyName,
											x.columnOrder - 1
										)
									}
								></span>
							)}
							<span className="truncate header-label">
								{x.propertyDisplayName}
							</span>
							{x.columnOrder < columns.length && (
								<span
									className="fas fa-long-arrow-alt-right"
									onClick={() =>
										this.props.reorderColumns(
											x.projectPropertyName,
											x.columnOrder + 1
										)
									}
								></span>
							)}
							<span
								className="fas fa-times"
								onClick={this._removeColumn.bind(null, x.projectPropertyName)}
								title={`Remove ${x.propertyDisplayName} column`}
							></span>
						</div>
					);
			  })
			: columns.map((x) => (
					<div
						style={{ cursor: "pointer" }}
						onClick={() =>
							this.props.onSort(
								x.projectPropertyName.charAt(0).toLowerCase() +
									x.projectPropertyName.slice(1)
							)
						}
						key={x.projectPropertyName}
						className={cx(gridClass, "mobile-hide", "project-header-cell")}
					>
						<span className="truncate">{x.propertyDisplayName}</span>
					</div>
			  ));

		return (
			<div>
				{showAddColumnModal && this.renderAddColumnModal()}
				<div className="project-table-header col-sm-12">
					<div
						style={{ cursor: "pointer" }}
						onClick={() => this.props.onSort("projectNumber")}
						className={cx(
							"min-110 project-number",
							editingColumns && "editing"
						)}
					>
						Project #
					</div>
					<div
						style={{ cursor: "pointer" }}
						onClick={(e) => this.props.onSort("name")}
						className={cx("min-130 project-name", editingColumns && "editing")}
					>
						Project Name
					</div>
					{selectedHeaders}
					{editingColumns && columns.length < 10 ? (
						<span
							className="btn btn-sm btn-secondary fas fa-plus"
							title="Add column"
							onClick={this._toggleAddColumnModal}
						></span>
					) : (
						[]
					)}
				</div>
				<div className="project-list custom-scrollbar">{lines}</div>
			</div>
		);
	}

	private renderProjectLine(project: ProjectVm) {
		const flagged = project.alerts && project.alerts.length ? true : false,
			{ columns, editingColumns } = this.props;

		const milestoneRows = project.showMilestones ? (
			<MilestoneList
				projectId={project.id}
				projectNumber={project.projectNumber}
				disabled={hasRole(this.props.user, [KnownRoles.Vendor])}
				clientName={project.clientName}
			/>
		) : (
			[]
		);
		const chevronClass = project.showMilestones
				? "fas fa-chevron-down"
				: "fas fa-chevron-right",
			toggleFavIcon = project.userFavorite ? (
				<span
					title="Remove from favorites"
					className="fas fa-star"
					onClick={(e) => this.props.removeFavorite(e, project.id)}
				></span>
			) : (
				<span
					title="Add to favorites"
					className="far fa-star"
					onClick={(e) => this.props.addFavorite(e, project.id)}
				></span>
			);

		const gridClass = `p-col-${columns.length}`;

		const projectColumns = columns.map((x) => {
			return (
				<div
					key={x.projectPropertyName}
					className={cx(gridClass, "mobile-hide", "truncate")}
				>
					{this.getPropValueString(project, x)}
				</div>
			);
		});

		return (
			<div
				className={cx("project-row col-sm-12")}
				key={`${project.projectNumber}_${project.clientName}_${this.props.user.defaultProjectTab}`}
			>
				<NavLink
					to={`/project/${project.id}`}
					className={cx("project-info", flagged ? "alert-border" : "")}
				>
					{flagged && (
						<this.noVendorComponent componentType="span">
							<span
								style={{ float: "left" }}
								title="The project contains alerts"
								className="fas fa-exclamation-triangle mobile-hide"
							></span>
						</this.noVendorComponent>
					)}
					<div
						className={cx(
							"min-110 project-number",
							editingColumns && "editing"
						)}
					>
						{project.projectNumber}
					</div>
					<div
						className={cx("min-130 project-name", editingColumns && "editing")}
					>
						{project.name}
					</div>
					{projectColumns}
					<div className="project-buttons">
						<div className="mobile-flex-2">
							<span
								className="fas fa-folder-open"
								onClick={(e) =>
									this.props.toggleAttachmentViewer(e, project.id)
								}
							></span>
						</div>
						<div className="mobile-float">
							<span
								className={chevronClass}
								onClick={(e) => this.props.toggleMilestones(e, project.id)}
							></span>
							{toggleFavIcon}
						</div>
					</div>
				</NavLink>
				{milestoneRows}
			</div>
		);
	}

	private renderAddColumnModal() {
		const { allColumns, columns } = this.props,
			columnOpts = allColumns
				.filter(
					(x) => columns.findIndex((y) => y.projectPropertyName === x.id) === -1
				)
				.map((x) => (
					<option key={x.id} value={x.id} className="form-control">
						{x.value}
					</option>
				));

		return (
			<Modal>
				<div className="modal-header">
					<h4>Add Project Column</h4>
				</div>
				<div className="modal-body">
					<p>Select a property to add to your columns</p>
					<select id="add-column-ddl" className="form-control">
						<option value="" className="form-control"></option>
						{columnOpts}
					</select>
					<br />
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-blue" onClick={this._addColumn}>
						Save
					</button>
					<button
						style={{ marginLeft: "1%" }}
						className="btn btn-sm btn-outline-secondary"
						onClick={this._toggleAddColumnModal}
					>
						Close
					</button>
				</div>
			</Modal>
		);
	}

	private getPropValueString(
		project: ProjectVm,
		columnInfo: UserProjectColumn
	) {
		let str = project[camelize(columnInfo.projectPropertyName)];
		switch (columnInfo.valueFormatType) {
			case PropertyFormatType.Date:
				str = formatDate(str);
				break;
			case PropertyFormatType.Money:
				str = toMoney(str);
				break;
		}

		return str;
	}
}
