import * as React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import * as cx from "classnames";

import { NotificationItem } from "./NotificationItem";
import { ProcessingNotification } from "src/interfaces/interfaces";
import { ProcessingNotificationActions } from "src/store/processingNotifications";

interface NotificationStreamProps {
  notifications: ProcessingNotification[];
}

export const NotificationStream: React.FC<NotificationStreamProps> = ({
  notifications,
}) => {
  const dispatch = useDispatch();

  const hasNotifications = notifications && notifications.length !== 0;

  const streamBody = (
    <div>
      <div className="stream-tray-banner">
        <b>{notifications.length} Documents(s).</b>
        <div className="banner-links">
          {hasNotifications && (
            <React.Fragment>
              &#8226;
              <a
                onClick={() => {
                  dispatch(
                    ProcessingNotificationActions.deleteAllNotifications()
                  );
                  return false;
                }}
              >
                Dismiss all
              </a>
            </React.Fragment>
          )}
        </div>
      </div>

      {hasNotifications ? (
        notifications.map((x) => (
          <NotificationItem key={x.timestamp} notification={x} />
        ))
      ) : (
        <div className="notification-item">
          <b>No documents to display</b>
        </div>
      )}
    </div>
  );

  return (
    <div
      className={cx(
        "notification-stream",
        "tray",
        !hasNotifications && "empty-stream"
      )}
    >
      {streamBody}
    </div>
  );
};
