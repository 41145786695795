import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import UserPropDisplay from "./UserPropDisplay";
import { ListItem } from "../interfaces/interfaces";
import { LoginMenu } from "../components/api-authorization/LoginMenu";
import Logout from "../components/auth-pages/Logout";
import { NotificationTray } from "../notifications/NotificationTray";
import { ProcessingNotificationTray } from "src/components/ProcessingNotificationTray";

interface State {
  pageTitle: string;
  actions: ListItem[];
}

class HorizontalHeader extends React.Component<
  RouteComponentProps<any>,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: "",
      actions: [],
    };
  }

  componentDidMount() {
    this._updateHeaderFromPage();
  }

  componentDidUpdate() {
    this._updateHeaderFromPage();
  }

  _updateHeaderFromPage = () => {
    const { location } = this.props;
    if (this.state.pageTitle != location.pathname) {
      this.setState({ pageTitle: location.pathname });
    }
  };

  render() {
    return (
      <div className="horizontal-header mobile-hide">
        <div className="logoutForm company-logo">
          <img src="/img/CoreStatesGroup_PrimaryLogo_Inverted.png" />
        </div>
        <div className="right-menu">
          <div className="right-icons">
            <div title="What's New?">
              <NavLink
                to={"/whatsnew"}
                activeClassName=""
                style={{ paddingBottom: "16px !important" }}
              >
                <span
                  style={{ marginRight: "0px", fontSize: "15px" }}
                  className="fas fa-wrench"
                ></span>
              </NavLink>
            </div>
            <div>
              <NotificationTray />
            </div>
            <div>
              <ProcessingNotificationTray />
            </div>
            <div className="greeting-item">
              <NavLink to={"/account-settings"} activeClassName="active">
                <span>
                  Hello <UserPropDisplay prop="fullName" />
                </span>
              </NavLink>
            </div>
            <div>
              <Logout></Logout>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HorizontalHeader);
