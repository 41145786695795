import * as React from 'react'
import classnames from 'classnames'

export const defaultActiveStyle = {
    fontWeight: 'bold',
    borderBottom: '2px solid #333'
}

type TabLinkProps = {
    to: string | number,
    component?: string,
    handleSelect?: (to: string | number, namespace: any) => void,
    onClick?: (e: any) => void,
    children?: JSX.Element,
    isActive?: boolean,
    namespace?: string,
    activeStyle?: object,
    disableInlineStyles?: boolean,
    className?: string,
    activeClassName?: string,
    style?: object
}

export class TabLink extends React.Component<TabLinkProps, any> {
    static displayName = 'TabLink'

    handleClick = (e: any) => {
        this.props.handleSelect!(this.props.to, this.props.namespace)

        if (this.props.onClick) {
            this.props.onClick(e)
        }
    }

    handleKeyPress = (e: any) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();

            this.handleClick(e)
        }
    }

    render() {
        const { to, handleSelect, isActive, namespace, activeStyle, disableInlineStyles, className, activeClassName, style, ...passedProps }
            = this.props

        const _className = className || 'tab-link'
        const _activeClassName = activeClassName || 'tab-link-active'
        const _style = { ...style, ...((isActive && (activeStyle || defaultActiveStyle)) || {}) }
        const componentType = this.props.component || 'button'

        return React.createElement(
            componentType,
            {
                id: `tab-${to}`,
                role: `tab`,
                //'aria-selected': isActive ? 'true' : 'false',
                'aria-controls': `tabpanel-${to}`,
                className: classnames({
                    [_className]: true,
                    [_activeClassName]: isActive
                }),
                style: (!disableInlineStyles && _style) || undefined,
                //...passedProps,
                onKeyPress: this.handleKeyPress,
                onClick: this.handleClick
            },
            this.props.children
        )
    }
}