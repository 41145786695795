import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as UserStore from '../store/user'

interface DisplayProps {
    prop: string;
}

type Props = 
    DisplayProps
    & UserStore.Store

class UserPropDisplay extends React.Component<Props, any> {

    render() {        
        return (
            <span className='user-prop-display'>
                {this.props.user && this.props.user[this.props.prop]}
            </span>        
        )
    }
}

export default connect<UserStore.Store, any, DisplayProps>(
    //@ts-ignore
    (state: ApplicationState) => state.user,
    {}
)(UserPropDisplay) //as typeof UserPropDisplay