import * as React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
    x?: string;
    y?: string;
    height?: string,
    width?: string,
    style?: any
}

export const RectangleLoader = (props: Props) => {
    let { x, y, width, height } = props;

    if (x === undefined) x = '0';
    if (y === undefined) y = '0';
    if (width === undefined) width = '100%';
    if (height === undefined) height = '100%';

    const _style = props.style || {}

    return (
        <ContentLoader
            speed={2}
            interval={0}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor="#ddd"
            foregroundColor="#eee"
            style={_style}
        >
            <rect x={x} y={y} width={width} height={height} />
        </ContentLoader>
    )
}