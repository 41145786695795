import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ApplicationState } from "../store";
import { useSelector } from "react-redux";
import { MessageBox } from "../components/MessageBox";
import { ReportLine } from "../interfaces/interfaces";
import { KnownRoles, hasRole } from "../auth/auth";
import Notepad from "../components/Notepad";

import {
  CustomizableGrid,
  MetaFieldInfo,
} from "src/components/CustomizableGrid";
import { CSharpTypeCode } from "src/interfaces/enums";
import { useDispatch } from "react-redux";
import { actionCreators as ReportActions } from "../store/report";
import { useHistory } from "react-router-dom";

export const RedLines: React.FC = () => {
  const [message, setMessage] = useState("");
  const [reportLineType, setReportLineType] = useState(1);
  const [columns, setColumns] = useState<MetaFieldInfo[]>([]);
  const [headerName, setHeaderName] = useState("");
  const reports = useSelector((s: ApplicationState) => s.report);
  const user = useSelector((s: ApplicationState) => s.user);
  const isVendor: boolean = hasRole(user, [KnownRoles.Vendor]);
  const history = useHistory();
  const dispatch = useDispatch();

  const projectDetails = (id: number) => history.push(`/project/${id}`);

  const cols: MetaFieldInfo[] = [
    {
      displayName: "Project No.",
      propName: "projectNumber",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
    {
      displayName: "Milestone Name",
      propName: "name",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
    {
      displayName: "Due Date",
      propName: "dueDate",
      jsType: "date",
      propType: CSharpTypeCode.DateTime,
    },
    {
      displayName: "Internal PM",
      propName: "internalPM",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
    {
      displayName: "Client PM",
      propName: "externalPM",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
    {
      displayName: "Notes",
      propName: "notes",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
    {
      displayName: "Vendor",
      propName: "vendorName",
      jsType: "string",
      propType: CSharpTypeCode.String,
    },
  ];

  const colsBillable: MetaFieldInfo[] = [
    ...cols.slice(0, 6),
    {
      displayName: "Billable",
      propName: "billable",
      jsType: "boolean",
      propType: CSharpTypeCode.String,
    },
  ];

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    entryId: number
  ) => {
    const ele = e.currentTarget as HTMLInputElement;
    const field = ele.getAttribute("name") || "";
    dispatch(ReportActions.updateLine(entryId, field, ele.value));
  };

  const renderItem = (line: ReportLine) => {
    let bgClass = "default-report-line";
    const dueDate =
        line.dueDate && line.dueDate.length
          ? new Date(line.dueDate)
          : new Date("1/1/2001"),
      dueStr =
        line.dueDate && line.dueDate.length
          ? new Date(line.dueDate).toISOString().substring(0, 10)
          : "";
    if (dueDate) {
      const today = new Date();
      if (dueDate <= today) {
        bgClass = "red-report-line";
      } else if (dueDate <= new Date(today.getTime() + 7 * 86400000)) {
        bgClass = "yellow-report-line";
      }
    }

    return (
      <div
        key={`${reports.lineType}_${line.entryId}_${line.dueDate}_${line.projectId}`}
        className={`col-sm-12 row report-line ${bgClass}`}
      >
        <div className={`col`}>
          <span
            className="detail-link"
            onClick={() => projectDetails(line.projectId)}
          >
            {line.projectNumber}
          </span>
        </div>
        <div className={`col`}>
          {line.entryLink ? (
            <Link to={line.entryLink}>{line.name}</Link>
          ) : (
            line.name
          )}
        </div>
        <div className={`col`}>
          {reports.lineType <= 2 ? (
            <input
              type="date"
              name="dueDate"
              defaultValue={dueStr}
              onBlur={(e) => handleBlur(e, line.entryId)}
              disabled={isVendor}
            />
          ) : (
            <span>{dueStr}</span>
          )}
        </div>
        <div className={`col mobile-hide`}>{line.internalPM}</div>
        <div className={`col mobile-hide`}>{line.externalPM}</div>
        {reports.lineType <= 2 ? (
          <div className={`col mobile-hide`}>
            <Notepad
              notes={line.notes}
              maxDisplayLength={100}
              disabled={isVendor}
              entityName={line.name}
              save={(newNotes: string) =>
                dispatch(
                  ReportActions.updateLine(line.entryId, "notes", newNotes)
                )
              }
            />
          </div>
        ) : (
          []
        )}
        {reports.lineType == 2 && (
          <div className={`col mobile-hide`}>{line.vendorName}</div>
        )}
        {reports.lineType == 1 && (
          <div className={`col mobile-hide`}>
            {line.billable && (
              <span
                style={{ marginLeft: "10px" }}
                className="fas fa-check fa-lg"
              ></span>
            )}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    switch (reports.lineType) {
      case 1:
        setColumns(colsBillable);
        setHeaderName("Milestones");
        break;
      case 2:
        setColumns(cols);
        setHeaderName("Scope Items");
        break;
      case 3:
        setColumns(cols.slice(0, 5));
        setHeaderName("RFIs");
        break;
      case 4:
        setColumns(cols.slice(0, 5));
        setHeaderName("Submittal Items");
        break;
      case 5:
        setColumns(cols.slice(0, 5));
        setHeaderName("Field Reports");
        break;
    }
  }, [reports]);

  useEffect(() => {
    dispatch(ReportActions.get(reportLineType));
  }, [reportLineType]);

  return (
    <div>
      <MessageBox message={message} clearMessage={() => setMessage("")} />
      <div className="pageHeader">Red Line Report</div>
      <div className="section report-section">
        <div>
          <h4 key={headerName}>{headerName}</h4>
          <div>
            <input
              type="radio"
              defaultChecked={true}
              name="report_type"
              onChange={() => setReportLineType(1)}
            />{" "}
            Milestones
            <input
              type="radio"
              name="report_type"
              onChange={() => setReportLineType(2)}
            />{" "}
            Scope Items
            <input
              type="radio"
              name="report_type"
              onChange={() => setReportLineType(5)}
            />{" "}
            Field Reports
            <input
              type="radio"
              name="report_type"
              onChange={() => setReportLineType(3)}
            />{" "}
            RFIs
            <input
              type="radio"
              name="report_type"
              onChange={() => setReportLineType(4)}
            />{" "}
            Submittals
          </div>
        </div>

        <div className="report-table">
          <CustomizableGrid
            columns={columns}
            items={reports.isLoading ? [] : reports.lines}
            mapCallback={renderItem}
            className="report-grid"
            evenColumnSpacing={true}
            redlineSpacing={true}
          />
        </div>
      </div>
    </div>
  );
};
