import * as React from "react";
import { connect } from "react-redux";
import * as ImportStore from "../../store/imports";
import { Tabs, TabLink, TabContent } from "../../tabs";
import { ImportSource, ListItem } from "../../interfaces/interfaces";
import ImportConfigList from "./ImportConfigList";
import { ApplicationState } from "../../store";
import { Loader } from "../../components/Loader";
import { MessageBox } from "../../components/MessageBox";
import Imports from "./ManualImports";
import { getActiveUsers } from "src/services/users";

type Props = ImportStore.ImportStore & typeof ImportStore.actionCreators;

interface State {
	clients: ListItem[];
	users: ListItem[];
}

class ImportHome extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			clients: [],
			users: [],
		};
	}

	componentDidMount() {
		this._getClients();
		this._getUsers();
	}

	_changeClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this._getImports(parseInt(e.currentTarget.value));
	};

	_getImports = (id: number) => {
		this.props.getConfigs(id);
	};

	_getClients = () => {
		fetch(`api/Client/Get`)
			.then((res) => res.json())
			.then((data) => this.setState({ clients: data }));
	};

	_getUsers = async () => {
		const activeData = await getActiveUsers();
		this.setState({ users: activeData });
	};

	render() {
		const { clients } = this.state;
		return (
			<div className="import-home">
				<Loader loading={this.props.loading} />
				<MessageBox
					message={this.props.message}
					clearMessage={this.props.clearMessage}
				/>
				<div style={{ maxWidth: "200px" }}>
					<b>Client</b>
					<select className="form-control" onChange={this._changeClient}>
						<option value="0" className="form-control">
							Select...
						</option>
						{clients &&
							clients.length &&
							clients.map((c) => (
								<option key={c.id} value={c.id} className="form-control">
									{c.value}
								</option>
							))}
					</select>
				</div>
				<Tabs
					name="project-tabs"
					onChange={() => {}}
					renderActiveTabContentOnly={true}
				>
					<div className="tab-links" style={{ paddingLeft: "0px" }}>
						<TabLink to="manual" component="span">
							<span>Manual</span>
						</TabLink>
						<TabLink to="google" component="span">
							<span>Google Sheets</span>
						</TabLink>
					</div>
					<div className="content">
						<TabContent for="manual">
							<Imports
								users={this.state.users}
								clientId={this.props.clientId}
							/>
						</TabContent>
						<TabContent for="google">
							<ImportConfigList
								users={this.state.users}
								source={ImportSource.Google}
							/>
						</TabContent>
					</div>
				</Tabs>
			</div>
		);
	}
}

export default connect(
	(state: ApplicationState) => state.imports,
	ImportStore.actionCreators
)(ImportHome);
