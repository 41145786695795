import React from 'react';
import Chart from 'react-google-charts';
import { Loader } from 'src/components/Loader';
import { IssueVm } from 'src/interfaces/interfaces';

interface IssuePieChartProps {
  issues: IssueVm[];
}

const KnownStatuses = ['Draft', 'Sent', 'In Progress', 'In Review', 'Complete'];

export const IssuePieChart = ({ issues }: IssuePieChartProps) => {
  const data = [
    ['Status', 'Item Count', { role: 'style' }],
    ...KnownStatuses.map((x) => {
      const ct = issues.filter((i) => i.statusName === x).length;
      return [x, ct, null];
    }),
  ];

  const options = {
    title: 'Items by Status',
    pieHole: 0.5,
    is3D: false,
    pieSliceText: 'value',
    chartArea: { left: '25%', width: '65%', height: '75%' },
    colors: ['#f4d053', '#2aaaaa', '#2864e6', '#d9534f', '#303030'],
  };

  return (
    <div>
      <Chart
        key={`status-pie-chart-issues`}
        graphID={`status-pie-chart`}
        chartType="PieChart"
        width={'100%'}
        height={'250px'}
        loader={<Loader loading={true} />}
        data={data}
        options={options}
      />
    </div>
  );
};
