import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Loader } from 'src/components/Loader';
import { DfrPhoto } from 'src/interfaces/interfaces';
import Modal from '../components/Modal';
import PhotoCarousel from '../components/PhotoCarousel';

interface PaginationModalProps {
  pageCount: number;
  onPageChange: (page: number) => void;
  photos: DfrPhoto[];
  onClose: () => void;
  loading: boolean;
}

export const DfrPhotoPaginationModal = ({
  pageCount,
  onPageChange,
  onClose,
  photos,
  loading,
}: PaginationModalProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [carouselIndex, setCarouselIndex] = useState<number | undefined>(
    undefined
  );

  const pageNumbers = useMemo(() => {
    return [...new Array(pageCount)].map((_, index) => {
      if (index == 0 || index == pageCount - 1) {
        return (
          <PaginationItem active={index === currentPage} key={index}>
            <PaginationLink onClick={() => pageChange(index)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        );
      } else if (index == currentPage + 3 || index == currentPage - 3) {
        return (
          <PaginationItem active={index === currentPage} key={index}>
            <PaginationLink onClick={() => pageChange(index)}>
              ...
            </PaginationLink>
          </PaginationItem>
        );
      } else if (index > currentPage + 3 || index < currentPage - 3) {
        //no-op
      } else {
        return (
          <PaginationItem active={index === currentPage} key={index}>
            <PaginationLink onClick={() => pageChange(index)}>
              {index + 1}
            </PaginationLink>
          </PaginationItem>
        );
      }
    });
  }, [currentPage, pageCount]);

  const photoPaths = useMemo(() => {
    return photos.map((x) => {
      return x.filePath;
    });
  }, [photos]);

  const pageChange = useCallback((page: number) => {
    setCurrentPage(page);
    onPageChange(page + 1);
  }, []);

  return (
    <Modal dialogStyle={{ maxWidth: '70%' }} style={{ display: 'flex' }}>
      <Loader loading={loading} />
      <div className="modal-header">
        <h4>{'All Photos'}</h4>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onClose}
        >
          <span style={{ fontSize: '2rem' }} aria-hidden="true">
            &times;
          </span>
        </button>
      </div>
      <div
        className="modal-body custom-scrollbar"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
        }}
      >
        <div className="row">
          {photos.map((x, idx) => {
            return (
              <div
                className="col-3"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingBottom: '14px',
                  maxWidth: 'unset',
                }}
              >
                <img
                  style={{ width: '250px', height: '250px' }}
                  src={x.filePath}
                  onClick={() => setCarouselIndex(idx)}
                />
                <div>{x.date ? new Date(x.date).toDateString() : ''}</div>
                <div>{x.description || ''}</div>
              </div>
            );
          })}
        </div>
        {carouselIndex !== undefined && (
          <PhotoCarousel
            photos={photoPaths}
            startingIndex={carouselIndex}
            close={() => setCarouselIndex(undefined)}
          />
        )}
      </div>
      <div className="modal-footer">
        <Pagination>
          <PaginationItem>
            <PaginationLink
              previous
              onClick={() => {
                if (!(currentPage == 0)) {
                  pageChange(currentPage - 1);
                }
              }}
            >
              Previous
            </PaginationLink>
          </PaginationItem>
          {pageNumbers}
          <PaginationItem>
            <PaginationLink
              next
              onClick={() => {
                if (!(currentPage + 1 == pageCount)) {
                  pageChange(currentPage + 1);
                }
              }}
            >
              Next
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </Modal>
  );
};
