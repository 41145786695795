import * as React from 'react';

export const LiveDocViewer = (props: { docUrl: string }) => {

    const splitLen = props.docUrl.split('.').length,
        extension = splitLen > 1 ? props.docUrl.split('.')[splitLen - 1].toUpperCase() : 'ERR';

    const split = props.docUrl.split('content'),
        relPath = '/files' + split[split.length - 1].replace(/%/g, '%25').replace(/#/g, '%23').replace(/\\/g, '/'),
        fullPath = document.location.protocol + '://' + document.location.host + relPath;

    //fullPath = 'https://csg.coresitevue.com/files/Clients/24/Projects/671/Closeout/Copy of WALMART TRAILER 2023.xlsx';
    let iframePath = fullPath;

    switch (extension) {
        case 'PDF':
        case 'DOC':
        case 'DOCX':
        default:
            iframePath = 'https://docs.google.com/gview?embedded=true&url=' + fullPath;
            break;
    }

    


    return <div className='live-doc-viewer'>
        <div style={{ marginBottom: '5px' }}><a className='btn btn-x-sm btn-outline-secondary' href={fullPath} target="_blank">View in new tab</a></div>
        {extension === 'ERR' ? [] : <iframe style={{ width: '100%', height: '500px' }} src={iframePath}></iframe>}
    </div>
}