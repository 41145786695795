import React from 'react';
import Modal from '../Modal';

interface FileExplorerDeleteModalProps {
  folderName: string;
  onDeleteItem: () => void;
  onToggleDelete: (e: React.MouseEvent<HTMLElement>, name?: string) => void;
}

export const FileExplorerDeleteModal = ({
  folderName,
  onDeleteItem,
  onToggleDelete,
}: FileExplorerDeleteModalProps) => {
  return (
    <Modal>
      <div className="modal-header">
        <h4>Delete Item</h4>
      </div>
      <div className="modal-body">
        <div style={{ fontSize: '14px' }}>
          Are you sure you want to delete {folderName}?
        </div>
        {/*<p><b>Note:</b> This will delete all sub-folders under {folderName}</p>*/}
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-danger" onClick={onDeleteItem}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={(e) => onToggleDelete(e, undefined)}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
