import { DropResult } from '@hello-pangea/dnd';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FileExplorerItem } from 'src/interfaces/interfaces';

export const MAX_WINDOW_COUNT = 2;

export interface WindowsItem {
  id: number;
  item: JSX.Element;
  droppableId?: string;
  title?: string;
}

export interface MoveEvent {
  dragDropResult: DropResult;
  fileExplorerItem?: FileExplorerItem;
  sourceSubPath?: string;
}

export interface WindowsState {
  windowCount: number;
  windows: WindowsItem[];
  moveEvent?: MoveEvent;
  isLoading: boolean;
  shouldWindowsReload: boolean;
}

const initialState: WindowsState = {
  windowCount: 0,
  windows: [],
  isLoading: false,
  shouldWindowsReload: false,
};

const { actions: WindowsActions, reducer: WindowsReducer } = createSlice({
  name: 'window',
  initialState,
  reducers: {
    addWindow(state, action: PayloadAction<WindowsItem>) {
      state.windows.push(action.payload);
      state.windowCount = state.windows.length;
    },
    removeWindow(state, action: PayloadAction<number>) {
      state.windows = state.windows.filter((x) => x.id !== action.payload);
      state.windowCount = state.windows.length;
    },
    createWindow(_state, _action: PayloadAction<WindowsItem>) {},
    setWindows(state, action: PayloadAction<WindowsItem[]>) {
      state.windows = action.payload;
      state.windowCount = state.windows.length;
    },
    setMoveEvent(state, action: PayloadAction<MoveEvent>) {
      state.moveEvent = action.payload;
    },
    clearMoveEvent(state) {
      state.moveEvent = undefined;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setShouldWindowsReload(state, action: PayloadAction<boolean>) {
      state.shouldWindowsReload = action.payload;
    },
  },
});

export { WindowsActions, WindowsReducer };

export default WindowsReducer;
