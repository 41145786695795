import * as React from 'react';
import { CustomField, CustomFieldType } from '../../interfaces/interfaces';
import { CustomFieldsTile } from './CustomFieldsTile';

export const CustomFieldsContext = React.createContext({})

export const CustomFieldHome = () => {
    const [context, setContext] = React.useState([] as CustomField[]);       

    React.useEffect(() => {
        const getFields = async () => {
            fetch(`api/customfield/getall`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setContext(data);
                })
        };
        getFields();
    }, [])

    return (
        <div className='custom-fields-config'>
            <CustomFieldsContext.Provider value={[context, setContext]}>
                <CustomFieldsTile type='RFI' />  
                <CustomFieldsTile type='Submittal' />
            </CustomFieldsContext.Provider>
        </div>
    )
}
