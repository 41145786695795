import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import FilterableSelect from 'src/components/FilterableSelect';
import {
  ListItem,
  NewListItem,
  QuerySelectField,
  SchemaPropertyInfo,
  SchemaTableInfo,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';
import { FormGroupDiv } from '../../styledComponents';

const StyledDiv = styled.div({
  display: 'flex',
  width: '100%',
  maxWidth: 'calc(100% - 150px)',
  '& input:invalid': {
    borderColor: '#dc3545',
    ':focus': { boxShadow: '0 0 0 0.25rem rgb(220 53 69 / 25%)' },
  },
});

const StyledFormGroupDiv = styled(FormGroupDiv)({
  marginBottom: '8px',
});

interface ReportQueryFieldRowProps {
  tables: NewListItem[];
  fields: Record<string, SchemaPropertyInfo[]>;
  field: QuerySelectField;
  onRemove: () => void;
  onUpdate: (field: QuerySelectField) => void;
}

export interface ForwardRefFieldProps {
  getState: () => FieldObject;
  isInvalid: boolean;
}

interface FieldObject {
  sourceTableName: string;
  propertyName: string;
}

export const ReportQueryFieldRow = forwardRef(
  (
    { tables, fields, field, onRemove, onUpdate }: ReportQueryFieldRowProps,
    ref: ForwardedRef<ForwardRefFieldProps>
  ) => {
    const [selectedField, setSelectedField] = useState<string>(
      field ? field.propertyName : ''
    );

    const defaultTable = useMemo(() => {
      const tableId = tables.find(
        (x) =>
          (
            x.item as {
              tableName: string;
              assemblyQualifiedName: string;
            }
          ).tableName == field.sourceTableName
      )?.id;

      if (tableId) {
        return tableId.toString();
      } else {
        return '';
      }
    }, [field, tables]);
    const [selectedTable, setSelectedTable] = useState<string>(defaultTable);

    const availableFields = useMemo(() => {
      const f = fields[selectedTable];

      if (f) {
        return f.map((x) => new NewListItem(x.propertyName, x.displayName, x));
      } else {
        return [];
      }
    }, [selectedTable, fields]);

    const selectedTableItem = useMemo(() => {
      return tables.find((x) => x.id == selectedTable)?.item as {
        tableName: string;
        assemblyQualifiedName: string;
      };
    }, [selectedTable]);

    useImperativeHandle(
      ref,
      () => {
        return {
          getState() {
            return {
              sourceTableName: selectedTableItem?.tableName ?? '',
              propertyName: selectedField,
            };
          },
          isInvalid: selectedTable == '' || selectedField == '',
        };
      },
      [selectedTable, selectedField]
    );

    useEffect(() => {
      const updatedField: QuerySelectField = {
        ...field,
        propertyName: selectedField,
        sourceTableName: selectedTableItem?.tableName ?? '',
      };
      onUpdate(updatedField);
    }, [selectedField, selectedTable, selectedTableItem]);

    useEffect(() => {
      setSelectedTable(defaultTable);
      setSelectedField(field.propertyName);
    }, [defaultTable, field]);

    return (
      <StyledFormGroupDiv className="form-group">
        <StyledDiv>
          <FilterableSelect
            id={'f-select-table'}
            items={tables}
            onChange={(x) => setSelectedTable(x)}
            defaultVal={selectedTable}
            required={true}
          ></FilterableSelect>
        </StyledDiv>
        <StyledDiv>
          <FilterableSelect
            id={'f-select-field'}
            items={availableFields}
            onChange={(x) => setSelectedField(x)}
            required={true}
            defaultVal={selectedField}
          ></FilterableSelect>
        </StyledDiv>

        <span
          style={{ borderRadius: '50%' }}
          className="btn btn-sm btn-round fas fa-trash btn-background-hover"
          title="Delete this field"
          onClick={() => onRemove()}
        ></span>
      </StyledFormGroupDiv>
    );
  }
);
