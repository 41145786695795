import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { SchemaPropertyInfo } from '../../interfaces/interfaces';
import { CustomReportState } from './customReport.reducer';

const customReportState = (state: ApplicationState): CustomReportState =>
  state.customReport;

export const allTablesSelector = createSelector(
  customReportState,
  (state) => state.tables
);

export const allQueriesSelector = createSelector(
  customReportState,
  (state) => state.queries
);

export const relatedTablesSelector = createSelector(
  customReportState,
  (state) => state.relatedTables
);

export const tableFieldsSelector = createSelector(
  customReportState,
  (state) => state.fields
);

export const flatFieldsSelector = createSelector(customReportState, (state) => {
  let f: SchemaPropertyInfo[] = [];
  for (const key in state.fields) {
    f = f.concat([...state.fields[key]]);
  }
  return f;
});

export const reportsSelector = createSelector(
  customReportState,
  (state) => state.reports
);

export const loadedReportSelector = createSelector(
  customReportState,
  (state) => state.loadedReport
);

export const reportsStatusSelector = createSelector(
  customReportState,
  (state) => state.reportsStatus
);

export const queriesStatusSelector = createSelector(
  customReportState,
  (state) => state.reportsStatus
);

export const loadedQuerySelector = createSelector(
  customReportState,
  (state) => state.loadedQuery
);

export const reportResultSelector = createSelector(
  customReportState,
  (state) => state.reportResult
);

export const queriesLoadingSavingSelector = createSelector(
  customReportState,
  (state) => state.queriesStatus == 'loading' || state.queriesStatus == 'saving'
);

export const isAddQuerySuccessSelector = createSelector(
  customReportState,
  (state) => state.isAddQuerySuccess
);
