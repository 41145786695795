import React, { useRef, useState } from 'react';
import { CCedUsersModal } from 'src/components/CCedUsersModal';
import PhotoCarousel from 'src/components/PhotoCarousel';
import {
  ListItem,
  ProjectTeamMember,
  PunchItemStatus,
  PunchItemVm,
} from 'src/interfaces/interfaces';
import { PunchItemCommentModal } from 'src/punchlist/PunchItemCommentModal';
import styled from 'styled-components';
import {
  formatDate,
  formatDateInputFriendly,
  formatDateTime,
} from '../helpers/formatters';
import { WorkRequiredModal } from './WorkRequiredModal';
import { Loader } from 'src/components/Loader';
import FilterableSelect from '../components/FilterableSelect';
import { FileDownloader } from '../components/FileDownloader';

const ContainerDiv = styled.div<{ isWorkItem: boolean }>((props) => ({
  //textAlign: 'center',
  display: 'flex',
  marginLeft: props.isWorkItem ? '0px' : '-15px',
  minWidth: '1200px',
  borderBottom: '1px solid lightgray',
}));

const TitleContainerDiv = styled.div({
  position: 'relative',
  marginBottom: '10px',
  //display: 'flex',
  '& h3': {
    //width: 'calc(100% - 200px)',
    //maxWidth: '750px',
  },
  '& > div': {
    height: '35px',
  },
  '& b': {
    width: '100px',
    display: 'inline-block',
  },
  '& .filterable-select': {
    display: 'inline-flex',
  },
});

const PhotoContainerDiv = styled.div({
  position: 'relative',
  padding: '0px',
  maxWidth: '200px',
  '&:hover span': {
    display: 'inline-block',
  },
});

const EmptyPhotoContainerDiv = styled.div({
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
  maxWidth: '200px',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  padding: '0px',
});

const TrashIcon = styled.span({
  position: 'absolute',
  top: '10px',
  right: 'calc(50% - 10px)',
  display: 'none',
  fontSize: '1.1em',
  backgroundColor: 'white',
  padding: '4px',
  borderRadius: '3px',
  boxShadow: '0px 0px 5px rgba(100, 100, 100, 1)',
});

const RotateIcon = styled.span({
  position: 'absolute',
  top: '10px',
  display: 'none',
  backgroundColor: 'white',
  padding: '4px',
  borderRadius: '3px',
  boxShadow: '0px 0px 5px rgba(100, 100, 100, 1)',
});

const ClockwiseRotateIcon = styled(RotateIcon)`
  && {
    right: 10px;
  }
`;

const CounterclockwiseRotateIcon = styled(RotateIcon)`
  && {
    left: 10px;
    transform: rotateY(180deg);
  }
`;

export interface PunchItemProps {
  item: PunchItemVm;
  users: ProjectTeamMember[];
  categories: string[];
  onAssigneeUpdate: (id: number, value: string) => void;
  onUserIdUpdate: (id: number, value: string) => void;
  onStatusUpdate: (id: number, value: string) => void;
  onCommentAdd: (id: number, value: string) => void;
  onUpdate: (id: number, field: string, value: string) => void;
  onPhotoAdd: (id: number, files: FileList | null) => void;
  onPhotoRotate: (id: number, clockwise: boolean) => void;
  onPhotoDelete: (id: number) => void;
  ccedModalId: number | null;
  onCCModalToggle: (id: number | null) => void;
  onWorkRequiredUpdate: (id: number, value: string) => void;
  showWorkRequiredModal: boolean;
  onWorkRequiredModalClose: () => void;
  isLoading: boolean;
  isWorkItem?: boolean;
}

export const PunchItem: React.FC<PunchItemProps> = ({
  item,
  users,
  categories,
  onAssigneeUpdate,
  onStatusUpdate,
  onUserIdUpdate,
  onCommentAdd,
  onUpdate,
  onPhotoAdd,
  onPhotoDelete,
  onPhotoRotate,
  ccedModalId,
  onCCModalToggle,
  onWorkRequiredUpdate,
  showWorkRequiredModal,
  onWorkRequiredModalClose,
  isLoading,
  isWorkItem = false,
}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState<number | undefined>(
    undefined
  );

  const addPhotoInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Loader loading={isLoading} />
      {showWorkRequiredModal && (
        <WorkRequiredModal
          item={item}
          onWorkRequiredUpdate={onWorkRequiredUpdate}
          onClose={onWorkRequiredModalClose}
        ></WorkRequiredModal>
      )}
      {carouselIndex !== undefined && (
        <PhotoCarousel
          photos={item.photos.map((x) => {
            return x.filePath;
          })}
          startingIndex={carouselIndex}
          close={() => setCarouselIndex(undefined)}
        />
      )}
      <ContainerDiv isWorkItem={isWorkItem} className="container mb-4">
        <div style={{ flexGrow: 1, justifyContent: 'space-between' }}>
          <div className="mb-2" style={{ display: 'flex', height: '520px' }}>
            <div
              style={{
                flexGrow: 1,
                paddingRight: '30px',
                borderRight: '1px solid lightgray',
                maxWidth: '750px',
              }}
            >
              <TitleContainerDiv>
                <h4 className="truncate" title={item.title}>
                  {item.title}
                </h4>
                <div>
                  <b>Created By: </b>
                  {item.creatorName}
                </div>
                <div>
                  <b>Created Date: </b>
                  {formatDateTime(item.createdDate)}
                </div>
                <div>
                  <b>Due Date: </b>
                  {item.status <= PunchItemStatus.InReview && item.canEdit ? (
                    <input
                      style={{ display: 'inline-flex', width: '151px' }}
                      type="date"
                      className="form-control"
                      defaultValue={formatDateInputFriendly(item.dueDate || '')}
                      onChange={(e) =>
                        onUpdate(item.id, 'dueDate', e.currentTarget.value)
                      }
                    />
                  ) : (
                    formatDate(item.dueDate || '')
                  )}
                </div>
                <div>
                  <b>Category: </b>
                  {item.canEdit ? (
                    <FilterableSelect
                      id={`item-category-${item.id}`}
                      items={categories.map((x) => new ListItem(x, x))}
                      openField={true}
                      onChange={(val: string) =>
                        onUpdate(item.id, 'category', val)
                      }
                      defaultVal={item.category}
                    />
                  ) : (
                    item.category
                  )}
                </div>
                <FileDownloader
                  actionPath="api/punchlist/pdf"
                  queryParams={{ ids: item.id }}
                  fileType="pdf"
                  className="btn btn-sm btn-background-hover"
                  inlineStyles={{
                    position: 'absolute',
                    borderRadius: '50%',
                    right: '33px',
                    top: 'calc(7px + 1.75rem)',
                  }}
                />
                <span
                  className="btn btn-sm btn-background-hover fas fa-users"
                  onClick={() => onCCModalToggle(item.id)}
                  title="Edit CCed Users"
                  style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    right: '0px',
                    top: 'calc(7px + 1.75rem)',
                  }}
                ></span>
              </TitleContainerDiv>
              <div
                className="mb-2"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {item.photos.map((x, idx) => {
                  return (
                    <PhotoContainerDiv key={x.id} className="col-4">
                      <img
                        style={{ width: '200px', height: '200px' }}
                        src={x.filePath}
                        className="img-thumbnail"
                        alt={x.description}
                        onClick={() => setCarouselIndex(idx)}
                      />

                      {item.canEdit && (
                        <>
                          <CounterclockwiseRotateIcon
                            className="fas fa-redo counterclockwise"
                            onClick={() => onPhotoRotate(x.id, false)}
                            title="Rotate photo counterclockwise 90 degrees"
                          ></CounterclockwiseRotateIcon>
                          <TrashIcon
                            className="fas fa-trash-alt"
                            title="Delete Photo"
                            onClick={() => onPhotoDelete(x.id)}
                          ></TrashIcon>
                          <ClockwiseRotateIcon
                            className="fas fa-redo clockwise"
                            onClick={() => onPhotoRotate(x.id, true)}
                            title="Rotate photo 90 degrees"
                          ></ClockwiseRotateIcon>
                        </>
                      )}
                    </PhotoContainerDiv>
                  );
                })}
                {item.photos.length < 3 &&
                  (item.canEdit ? (
                    <EmptyPhotoContainerDiv className="img-thumbnail col-4">
                      <span>Add Photo</span>
                      <br />
                      <span
                        className="fas fa-circle-plus fa-4x"
                        style={{ color: 'grey' }}
                        title="Add Photo"
                        onClick={() => addPhotoInputRef.current?.click()}
                      ></span>
                    </EmptyPhotoContainerDiv>
                  ) : (
                    <div className="col-4"></div>
                  ))}
                {item.canEdit && item.photos.length < 2 && (
                  <EmptyPhotoContainerDiv className="img-thumbnail col-4">
                    <span>Add Photo</span>
                    <br />
                    <span
                      className="fas fa-circle-plus fa-4x"
                      style={{ color: 'grey' }}
                      title="Add Photo"
                      onClick={() => addPhotoInputRef.current?.click()}
                    ></span>
                  </EmptyPhotoContainerDiv>
                )}
                <input
                  ref={addPhotoInputRef}
                  type="file"
                  hidden
                  multiple
                  accept="image/png, image/jpeg, image/jpg, image/heic"
                  onChange={(e) => onPhotoAdd(item.id, e.currentTarget.files)}
                />
              </div>
              {
                <textarea
                  defaultValue={item.description}
                  onBlur={(e) =>
                    onUpdate(item.id, 'description', e.currentTarget.value)
                  }
                  rows={3}
                  className="form-control mr-2 mb-2"
                  disabled={item.status !== PunchItemStatus.Draft}
                ></textarea>
                ////: (
                //    <div className="border rounded p-1 mb-2 truncate" style={{ height: '4em', maxWidth: '100%' }}> {item.description} item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description item description item descriptionitem description</div>
                //)
              }
              <div className="row">
                <div className="form-group col-4">
                  <span>
                    <b>Assignee</b>
                  </span>
                  {item.canReassignAndClose ? (
                    <select
                      className="form-control"
                      onChange={(e) =>
                        onAssigneeUpdate(item.id, e.currentTarget.value)
                      }
                      defaultValue={item.assigneeId}
                    >
                      {users.map((x) => {
                        return (
                          <option
                            key={x.id}
                            className={'form-control'}
                            value={x.userId}
                          >
                            {x.userFullName}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <label style={{ width: '100%', marginTop: '8px' }}>
                      {item.assigneeName}
                    </label>
                  )}
                </div>
                <div className="form-group col-4">
                  <span>
                    <b>Ball-in-Court</b>
                  </span>
                  {item.canEdit ? (
                    <select
                      className="form-control"
                      onChange={(e) => {
                        onUserIdUpdate(item.id, e.currentTarget.value);
                      }}
                      defaultValue={item.userId}
                    >
                      {users.map((x) => {
                        return (
                          <option
                            key={x.id}
                            className={'form-control'}
                            value={x.userId}
                          >
                            {x.userFullName}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <label style={{ width: '100%', marginTop: '8px' }}>
                      {item.ballInCourtName}
                    </label>
                  )}
                </div>
                <div className="form-group col-4">
                  <span>
                    <b>Status</b>
                  </span>
                  {item.canEdit ? (
                    <select
                      className="form-control"
                      onChange={(e) => {
                        onStatusUpdate(item.id, e.currentTarget.value);
                      }}
                      disabled={item.status == PunchItemStatus.Draft}
                      value={item.status}
                    >
                      {item.status == PunchItemStatus.Draft && (
                        <option
                          className={'form-control'}
                          value={PunchItemStatus.Draft}
                        >
                          Draft
                        </option>
                      )}
                      {item.status <= PunchItemStatus.Sent && (
                        <option
                          className={'form-control'}
                          value={PunchItemStatus.Sent}
                        >
                          Sent
                        </option>
                      )}
                      <option
                        className={'form-control'}
                        value={PunchItemStatus.InReview}
                      >
                        In Review
                      </option>
                      <option
                        className={'form-control'}
                        value={PunchItemStatus.WorkRequired}
                      >
                        Work Required
                      </option>
                      {item.canReassignAndClose && (
                        <option
                          className={'form-control'}
                          value={PunchItemStatus.Closed}
                        >
                          Closed
                        </option>
                      )}
                      {item.status < PunchItemStatus.WorkRequired && (
                        <option
                          className={'form-control'}
                          value={PunchItemStatus.Void}
                        >
                          Void (removes from project)
                        </option>
                      )}
                    </select>
                  ) : (
                    <label style={{ width: '100%', marginTop: '8px' }}>
                      {item.statusName}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div style={{ paddingLeft: '30px' }}>
              <PunchItemCommentModal
                item={item}
                onClose={() => setShowCommentModal(!showCommentModal)}
                onCommentAdd={onCommentAdd}
              ></PunchItemCommentModal>
            </div>
            <hr />
            {ccedModalId === item.id && (
              <CCedUsersModal
                route="api/punchlist/addcc"
                id={ccedModalId}
                projectId={item.projectId}
                users={item.ccedUsers}
                close={() => onCCModalToggle(null)}
                editable={item.canEdit}
              />
            )}
          </div>
        </div>
      </ContainerDiv>
    </>
  );
};
