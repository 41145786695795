import * as React from 'react'
import { Alert } from '../interfaces/interfaces';

type Props = {
    alerts?: Alert[]
}

export default class AlertRibbon extends React.Component<Props, any> {

    render() {
        const { alerts } = this.props;
        if (!alerts || !alerts.length)
            return (<div></div>)
        else {
            const alertItems = alerts.map(a => this.renderAlertItem(a))
            return (
                <div className='alert-ribbon'>
                    <ul>
                        {alertItems}
                    </ul>
                </div>
            )
        }
    }

    private renderAlertItem(alert: Alert) {
        return <li key={alert.id}>{alert.value}</li>
    }
}