import { AxiosResponse } from "axios";
import { PurchaseOrder } from "src/interfaces/interfaces";
import { api } from "../api";

const addPO = (po: PurchaseOrder) => api.post('api/PurchaseOrder/Create', po);

const updatePO = (id: number | string, field: string, value: any) => api.post(`api/PurchaseOrder/Update?id=${id}`, { fieldName: field, value: value });

const addPOtoProcurement = (poId: number, procurementItemId: number, procurementQuoteId: number) =>
  api.post(`api/Procurement/AddPurchaseOrder?purchaseOrderId=${poId}&procurementItemId=${procurementItemId}&procurementQuoteId=${procurementQuoteId}`);

export const addProcurementPO = async (po: PurchaseOrder, procurementItemId: number, procurementQuoteId: number) => {
  try {
    const addResponse: AxiosResponse<PurchaseOrder> = await addPO(po);
    if (addResponse.status == 200) {
      const { data } = addResponse;
      if (data.id) {
        const addProcurementResponse: AxiosResponse = await addPOtoProcurement(data.id, procurementItemId, procurementQuoteId);
        if (addProcurementResponse.status == 200) {
          return true;
        }
      }
    }
  } catch {
    return false;
  }

  return false
}

export const requestProcurementPO = async (itemId: number, quoteId: number) => {
  try {
    const response: AxiosResponse = await api.post(
      `api/PurchaseOrder/Request?quoteId=${quoteId}&itemId=${itemId}`
    );

    const { data } = response;
    if (response.status == 200) {
      return true;
    } else {
      return data;
    }
  }
  catch {
    return { message: 'Error: An unexpected error occurred.' }; 
  }
}

export const updatePurchaseOrder = async (id: number | string, field: string, value: any) => {
  try {
    const updateResponse: AxiosResponse<PurchaseOrder> = await updatePO(id, field, value);

    if (updateResponse.status == 200) {
      return true;
    }
  } catch {
    return false;
  }

  return false
}