import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FilterableSelect from '../components/FilterableSelect';
import Modal from '../components/Modal';
import { formatDate } from '../helpers/formatters';
import { ListItem, PunchItemPostModel } from '../interfaces/interfaces';
import { ApplicationState } from '../store';
import { punchItemsCategoriesSelect, PunchlistActions } from '../store/punchlist';

const defaultForm: PunchItemPostModel = {
  projectId: 0,
  title: '',
  category: '',
  assigneeId: '',
  description: '',
  dueDate: '',
  send: false,
  files: null,
};

export const PunchItemAddModal = (props: {
  projectId?: number;
  close: () => void;
}) => {
  let { projectId } = props;

  const [form, setForm] = React.useState({
    ...defaultForm,
    projectId: projectId,
  } as PunchItemPostModel);
  const [showBallInCourtSelect, setShowBallInCourtSelect] =
    React.useState(false);

  const punchItemCategories = useSelector(punchItemsCategoriesSelect);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const dispatch = useDispatch();

  const formRef = React.useRef(form);
  formRef.current = form;

  React.useEffect(() => {
    if (!projectId) {
      // try to get the project id from the store if a current project detail is loaded
      projectId = projectStore.projId;
    }
  }, [props.projectId]);

  React.useEffect(() => {
    // If the user toggles off the specific ball-in-court select, we need to make sure the userid is set to the assigneeid
    if (!showBallInCourtSelect) {
      setForm({ ...form, userId: form.assigneeId });
    }
  }, [showBallInCourtSelect]);

  const _save = () => {
    // Do front-end validation
    if (
      form.title === '' ||
      form.description === '' ||
      form.assigneeId === '' ||
      form.userId === '' ||
      form.files === null
    ) {
      // change this to global message box
      alert(
        'Please ensure that all required fields are filled out, including photos'
      );
      return;
    }

    if (form.files && (form.files.length < 1 || form.files.length > 3)) {
      // change this to global message box
      alert('Please upload between 1 and 3 photos');
      return;
    }

    // Dispatch the action with the form to send the model to the server
    dispatch(PunchlistActions.addPunchItem(form));

    // How do we trigger a closing of the modal after a successful save?
    // Also make sure the modal stays open if the save was not successful
    // Most likely some state property in the punchlist store?
  };

  return (
    <Modal contentClass="punch-item-add-form">
      <div className="modal-header">
        <h4>Create New Punch Item</h4>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <div className="form-label">
            <b>Title</b>* &nbsp; <small>(maximum 50 characters)</small>
          </div>
          <input
            className="form-control"
            onChange={(e) => setForm({ ...form, title: e.currentTarget.value })}
            value={form.title}
            maxLength={50}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Category</b>
          </div>
          <FilterableSelect       
            id='new-item-category'
            key='new-item-category'
            items={punchItemCategories.map(x => new ListItem(x, x))}
            onChange={(val) => setForm({ ...formRef.current, category: val })}
            openField={true}
            defaultVal={form.category}            
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Due Date</b>
          </div>
          <input
            className="form-control"
            type="date"
            onBlur={(e) =>
              setForm({ ...form, dueDate: e.currentTarget.value })
            }
            //value={formatDate(form.dueDate || '')}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Description</b>
          </div>
          <textarea
            className="form-control"
            onChange={(e) =>
              setForm({ ...form, description: e.currentTarget.value })
            }
            value={form.description}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Assignee</b>*
          </div>
          <FilterableSelect
            id="punch-item-assignee"
            items={projectStore.detail.teamMembers.map(
              (x) => new ListItem(x.userId, x.userFullName)
            )}
            defaultVal={form.assigneeId}
            onChange={(id) =>
              setForm({
                ...form,
                assigneeId: id,
                userId: showBallInCourtSelect ? form.userId : id,
              })
            }
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Set Different Ball In Court</b>
          </div>
          <div>
            <small style={{ fontSize: '12px' }}>
              By default, the assignee is set as ball-in-court. This can be
              changed now, or by you and the assignee after the item is sent.
            </small>
          </div>
          <label className="switch">
            <input
              onClick={() => setShowBallInCourtSelect(!showBallInCourtSelect)}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        {showBallInCourtSelect && (
          <div className="form-group">
            <div className="form-label">
              <b>Ball In Court</b>*
            </div>
            <FilterableSelect
              id="punch-item-ball-in-court"
              items={projectStore.detail.teamMembers.map(
                (x) => new ListItem(x.userId, x.userFullName)
              )}
              defaultVal={form.userId}
              onChange={(id) => setForm({ ...form, userId: id })}
            />
          </div>
        )}
        <div className="form-group">
          <div className="form-label">
            <b>Send on Save</b>
          </div>
          <div>
            <small style={{ fontSize: '12px' }}>
              If you check this, the punch item will be sent to the assignee
              when you save. Otherwise, it will be saved as a draft and you will
              be able to send it later.
            </small>
          </div>
          <label className="switch">
            <input
              onClick={() => setForm({ ...form, send: !form.send })}
              checked={form.send}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="form-group">
          <div className="form-label">
            <b>Photos</b>*
          </div>
          <input
            type="file"
            multiple
            accept="image/png, image/jpeg, image/jpg, image/heic"
            onChange={(e) => setForm({ ...form, files: e.currentTarget.files })}
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn btn-sm btn-blue" onClick={_save}>
          Save
        </div>
        <div className="btn btn-sm btn-outline-secondary" onClick={props.close}>
          Close
        </div>
      </div>
    </Modal>
  );
};
