import * as React from 'react';
import Modal from './Modal';
import { MessageBox } from './MessageBox';
import { pathItemName, FileShareAccessType, stringifyAccessType } from '../pages/FileShare';

interface Props {
    path: string;
    close: () => void;
}

export const FileShareModal = (props: Props) => {
    const { path, close } = props;
    const [message, setMessage] = React.useState('');
    const [accessType, setAccessType] = React.useState('-1');
    const [emailList, setEmailList] = React.useState('');

    const _save = () => {
        const emails = emailList.split(';'),
            emailsUrl = 'emails=' + emails.join('&emails=')

        fetch(encodeURI(`fileshare/share?relPath=${path}&accessType=${accessType}&${emailsUrl}`), { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) close();
            })
    }

    return (
        <Modal>
            {/*<MessageBox message={message} clearMessage={() => setMessage('')} />*/}
            <div className="modal-header">
                <h4 className="modal-title">Share {pathItemName(path)}</h4>
            </div>
            <div className='modal-body share-modal' style={{ 'height': '250px' }}>
                <p><b>Select Share Access Type </b></p>
                <select className='form-control share-access' value={accessType} onChange={(e) => setAccessType(e.currentTarget.value)}>
                    <option className='form-control' value={-1}></option>
                    <option className='form-control' value={FileShareAccessType.ReadOnly}>{stringifyAccessType(FileShareAccessType.ReadOnly)}</option>
                    <option className='form-control' value={FileShareAccessType.ReadOnlyAllLevels}>{stringifyAccessType(FileShareAccessType.ReadOnlyAllLevels)}</option>
                    {/*<option className='form-control' value={FileShareAccessType.ReadWrite}>{stringifyAccessType(FileShareAccessType.ReadWrite)}</option>
                    <option className='form-control' value={FileShareAccessType.ReadWriteAllLevels}>{stringifyAccessType(FileShareAccessType.ReadWriteAllLevels)}</option>*/}
                </select>
                <br/>
                <b>Enter Emails to Share With</b>
                <p><small>You can enter as many emails as you want; just ensure they are separated by a semi-colon (;)</small></p>
                <textarea className='form-control share-emails' value={emailList} onChange={(e) => setEmailList(e.currentTarget.value)}></textarea>
            </div>
            <div className='modal-footer'>
                <span className="btn btn-sm btn-green" onClick={_save}>Save</span>
                <span className="btn btn-sm btn-outline-secondary" onClick={() => close()}>Close</span>
            </div>
        </Modal>
    )
}