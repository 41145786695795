import * as React from 'react';
import { connect } from 'react-redux'
import { ApplicationState } from '../store';
import * as UserStore from '../store/user'
import { isUserById } from "./auth"

interface AuthEleProps {
    allowedUserIds: string[]
    class?: string,
    children?: React.ReactNode
}

type MyAuthEleProps =
    AuthEleProps
    & UserStore.Store    

class UserIdAuthElement extends React.Component<MyAuthEleProps, any> {
    render() {
        if (isUserById(this.props.user, this.props.allowedUserIds)) {
            return <div className={this.props.class}>{this.props.children}</div>
        }
        return <div></div>
    }
}
//@ts-ignore
export default connect<UserStore.Store, null, AuthEleProps, MyAuthEleProps>(    
    // @ts-ignore
    (state: ApplicationState) => state.user,
    null
    // @ts-ignore
)(UserIdAuthElement) //as typeof UserIdAuthElement