import {
  addTemplate,
  addTemplateEntry,
  copyTemplate,
  getTemplates,
  removeTemplateEntry,
  updateFieldReportSafetyKPIEntry,
  updateTemplate,
  updateTemplateEntry,
} from 'src/services/safetyKPI';
import { AppStartListening } from '../listenerMiddleware';
import { SafetyKPIActions } from './safetyKPI.reducer';
import { SafetyKpiQuantityType } from 'src/interfaces/enums';

export const addSafetyKPIListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      actionCreator: SafetyKPIActions.getTemplates,
      effect: async (action, listenApi) => {
        const response = await getTemplates();
        if (response) {
          listenApi.dispatch(SafetyKPIActions.setTemplates(response));
          listenApi.dispatch(SafetyKPIActions.setStatus('completed'));
        } else {
          listenApi.dispatch(SafetyKPIActions.setStatus('failed'));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.addTemplate,
      effect: async (action, listenApi) => {
        const response = await addTemplate();
        if (response) {
          listenApi.dispatch(SafetyKPIActions.setTemplates(response));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.addTemplateEntry,
      effect: async (action, listenApi) => {
        const response = await addTemplateEntry(action.payload);
        if (response) {
          listenApi.dispatch(SafetyKPIActions.replaceTemplate(response));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.removeTemplateEntry,
      effect: async (action, listenApi) => {
        const response = await removeTemplateEntry(action.payload);
        if (response) {
          listenApi.dispatch(SafetyKPIActions.replaceTemplate(response));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.updateTemplateEntry,
      effect: async (action, listenApi) => {
        const response = await updateTemplateEntry(
          action.payload.id,
          action.payload.info
        );
        if (response) {
          listenApi.dispatch(SafetyKPIActions.replaceTemplate(response));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.updateTemplate,
      effect: async (action, listenApi) => {
        const response = await updateTemplate(
          action.payload.id,
          action.payload.info
        );
        if (typeof response == 'string') {
          listenApi.dispatch({ type: 'CONFIG_ERROR', message: response });
        } else if (response) {
          listenApi.dispatch(SafetyKPIActions.replaceTemplate(response));
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.copyTemplate,
      effect: async (action, listenApi) => {
        const response = await copyTemplate(action.payload);
        if (response) {
          listenApi.dispatch(SafetyKPIActions.setTemplates(response));

          const lastTemplate = response.reduce((a, b) => (a.id > b.id ? a : b));

          listenApi.dispatch(
            SafetyKPIActions.setNewDuplicateTemplate(lastTemplate)
          );
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.updateFieldReportSafetyKPIEntry,
      effect: async (action, listenApi) => {
        const response = await updateFieldReportSafetyKPIEntry(
          action.payload.id,
          action.payload.info
        );
        if (response) {
          const entries = listenApi.getState().dfr.kpiEntries.slice();
          const idx = entries.findIndex((x) => x.id == response.id);
          entries.splice(idx, 1, response);
          listenApi.dispatch({ type: 'UPDATE_KPI', entries: entries });
        }
      },
    }),
    startListening({
      actionCreator: SafetyKPIActions.updateFieldReportSafetyKPIEntryQuantity,
      effect: async (action, listenApi) => {
        // Repeated calls cancel previous ones, no work performed
        // until the specified delay elapses without another call
        // NOTE: This is also basically identical to `takeLatest`.
        // Ref: https://redux-saga.js.org/docs/api#debouncems-pattern-saga-args
        // Ref: https://redux-saga.js.org/docs/api#takelatestpattern-saga-args

        // Cancel any in-progress instances of this listener

        if (
          action.payload.id ==
          listenApi.getOriginalState().safetyKPI.fieldReportEntryUpdateId
        ) {
          listenApi.cancelActiveListeners();
        }

        // Delay before starting actual work
        await listenApi.delay(750);

        const value =
          action.payload.type == SafetyKpiQuantityType.Hours ? 0.25 : 1;
        if (
          isNaN(parseInt(action.payload.info.value)) ||
          +action.payload.info.value % value !== 0
        ) {
          listenApi.dispatch({
            type: 'ERROR_EDIT_DATE_REPORTNAME',
            message: `Error: Please enter a valid amount.${
              action.payload.type == SafetyKpiQuantityType.Hours
                ? ' Hours need to be in multiples of 0.25'
                : ''
            }`,
          });
        } else {
          const response = await updateFieldReportSafetyKPIEntry(
            action.payload.id,
            action.payload.info
          );
          if (response) {
            const entries = listenApi.getState().dfr.kpiEntries.slice();
            const idx = entries.findIndex((x) => x.id == response.id);
            entries.splice(idx, 1, response);
            listenApi.dispatch({ type: 'UPDATE_KPI', entries: entries });
          }
        }
        listenApi.dispatch(SafetyKPIActions.removeFieldReportEntryUpdateId());
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
