import * as React from 'react';
import { formatDateInputFriendly } from '../helpers/formatters';
import { CustomFieldEntry, CustomFieldType } from '../interfaces/interfaces';

interface Props {
    entry: CustomFieldEntry,
    handleChange: (id: number, value: string) => void
}

export const CustomFieldEntryComponent = (props: Props) => {
    const { entry, handleChange } = props;

    const _inputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        handleChange(entry.id, e.currentTarget.value)
    }

    const _selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleChange(entry.id, e.currentTarget.value)
    }

    switch (entry.fieldType) {
        case CustomFieldType.Boolean:
            return <select className='form-control' defaultValue={entry.value.toString()} onChange={_selectChange}>
                <option className='form-control'></option>
                <option className='form-control' value='No'>No</option>
                <option className='form-control' value='Yes'>Yes</option>
            </select>
        case CustomFieldType.DateTime:
            return <input className='form-control' type='date' defaultValue={formatDateInputFriendly((entry.value || '').toString())} onBlur={_inputBlur} />
        case CustomFieldType.String:
        default:
            return <input className='form-control' defaultValue={entry.value.toString()} onBlur={_inputBlur} />
    }    
}