import * as React from 'react';
import { CustomizableGrid, MetaFieldInfo } from '../components/CustomizableGrid';
import { CSharpTypeCode, MetaObjectType } from '../interfaces/enums';
import { UnvettedPartner, UnvettedPartnerStatus, VendorVm } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as globalActions } from '../store/global';
import { KeyVaultActions, partnerRegKeySelector } from '../store/keyvault/index';
import { UnvettedParterDetail } from './UnvettedPartnerDetail';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';

export const UnvettedPartnerList = () => {

  const [partners, setPartners] = React.useState([] as UnvettedPartner[]);
  const [selectedPartnerId, setSelectedPartnerId] = React.useState(null as number | null);
  const apiKey = useSelector(partnerRegKeySelector);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(KeyVaultActions.getPartnerRegistrationKey());
  }, []);

  React.useEffect(() => {
    if (apiKey) {
      // @ts-ignore
      _getPartners(apiKey);
    }
  }, [apiKey])

  const _getPartners = (key: string) => {
    fetch(`${PartnerRegistrationUrl}/api/subform/getallexternal`, {
      //method: 'GET',
      headers: {
        'Authorization': `Bearer ${key}`
      }
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setPartners(data);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  const cols: MetaFieldInfo[] = [
    { displayName: 'Name', propName: 'name', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 4 },
    { displayName: 'Trades', propName: 'trades.tradeName', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 4 },
    { displayName: 'Regions', propName: 'regions.name', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 3 },
    { displayName: 'Labor Type', propName: 'laborType', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 3 },
    { displayName: 'Vendor Type', propName: 'vendorType', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 3 },
    { displayName: 'Status', propName: 'status', jsType: 'string', propType: CSharpTypeCode.Object, columnWidth: 2 },
  ]

  const _lineMap = (partner: UnvettedPartner) => {
    const colWidth = Math.floor(20 / cols.length);
    return (
      <div onClick={() => setSelectedPartnerId(partner.id)} className='col-sm-12 sv-grid-line' key={partner.id}>
        {/*{cols.map(c => {*/}
        {/*    <div className={`my-col-${colWidth}`}>{vendor[c.propName]}</div>*/}
        {/*})}*/}
        <div className={`my-col-${4} sortable truncate`}>{partner.vendorName}</div>
        <div className={`my-col-${4} sortable truncate`}>{partner.trades.map(x => x.tradeName).join(', ').substring(0, 100)}</div>
        <div className={`my-col-${3} sortable truncate`}>{partner.serviceAreas.join(', ').substring(0, 100)}</div>
        <div className='my-col-3 sortable truncate'>{partner.union}</div>
        <div className='my-col-3 sortable truncate'>{partner.vendorType}</div>     
        <div className='my-col-2 sortable truncate'>{PartnerStatusText(partner.status)}</div>     
      </div>
    )
  }

  return (
    <>
      {selectedPartnerId && <UnvettedParterDetail partnerId={selectedPartnerId} close={() => setSelectedPartnerId(null)} />}
      <CustomizableGrid
        type={MetaObjectType.Vendor}
        columns={cols}
        items={partners}
        mapCallback={_lineMap}
        className='vendor-grid'
      />
    </>
  );
}

export const PartnerStatusText = (status: UnvettedPartnerStatus) => {
  switch (status) {
    case UnvettedPartnerStatus.PhaseOneSubmitted: return 'Phase One';
    case UnvettedPartnerStatus.PhaseTwoSent: return 'Forms Sent';
    case UnvettedPartnerStatus.PhaseTwoSubmitted: return 'Forms Submitted';
    case UnvettedPartnerStatus.AllFormsReviewed: return 'Forms Reviewed';
  }
}