import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  PunchItemCommentPostModel,
  PunchItemPostModel,
  PunchItemVm,
  PunchlistTemplateVm,
  UpdateObject,
} from 'src/interfaces/interfaces';

interface ServiceRequestResponse {
  status: 'idle' | 'success' | 'failed';
  message?: string;
}

export interface PunchlistState {
  items: PunchItemVm[];
  status: 'idle' | 'loading' | 'completed';
  projectId: number | undefined;
  ccedModalId: number | null;
  addPunchItemRequest: ServiceRequestResponse;
  addPhotoRequest: ServiceRequestResponse;
  removePhotoRequest: ServiceRequestResponse;
  rotatePhotoRequest: ServiceRequestResponse;
  isItemUpdating: boolean;
  templates: PunchlistTemplateVm[];
  templateStatus: 'idle' | 'loading' | 'completed';
}

const initialState: PunchlistState = {
  items: [],
  status: 'idle',
  templateStatus: 'idle',
  projectId: undefined,
  addPunchItemRequest: {
    status: 'idle',
  },
  ccedModalId: null,
  addPhotoRequest: {
    status: 'idle',
  },
  removePhotoRequest: {
    status: 'idle',
  },
  rotatePhotoRequest: {
    status: 'idle',
  },
  isItemUpdating: false,
  templates: [],
};

const { actions: PunchlistActions, reducer: PunchlistReducer } = createSlice({
  name: 'punchlist',
  initialState,
  reducers: {
    getPunchItems(state, _action: PayloadAction<number>) {
      state.status = 'loading';
    },
    setPunchItems(state, action: PayloadAction<PunchItemVm[]>) {
      state.items = action.payload;
      state.status = 'completed';
    },
    setProjectId(state, action: PayloadAction<number>) {
      state.projectId = action.payload;
    },
    addPunchItem(_state, _action: PayloadAction<PunchItemPostModel>) {},
    handlePunchItemResponse(
      state,
      action: PayloadAction<ServiceRequestResponse>
    ) {
      state.addPunchItemRequest.message = action.payload.message;
      state.addPunchItemRequest.status = action.payload.status;
    },
    updatePunchItem(
      _state,
      _action: PayloadAction<{ id: number; updateObject: UpdateObject }>
    ) {},
    addCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds?: string; emails?: string }>
    ) {},
    removeCCUsers(
      _state,
      _action: PayloadAction<{ id: number; userIds: string }>
    ) {},
    addComment(_state, _action: PayloadAction<PunchItemCommentPostModel>) {},
    addPhoto(
      _state,
      _action: PayloadAction<{ id: number; files: FileList }>
    ) {},
    removePhoto(_state, _action: PayloadAction<number>) {},
    rotatePhoto(
      _state,
      _action: PayloadAction<{ id: number; clockwise: boolean }>
    ) {},
    toggleCCedModal(_state, _action: PayloadAction<number | null>) {
      //state.ccedModalId = action.payload
    },
    setCCedModalId(state, action: PayloadAction<number | null>) {
      state.ccedModalId = action.payload;
    },
    handleAddPhotoResponse(
      state,
      action: PayloadAction<ServiceRequestResponse>
    ) {
      state.addPhotoRequest.message = action.payload.message;
      state.addPhotoRequest.status = action.payload.status;
    },
    handleRemovePhotoResponse(
      state,
      action: PayloadAction<ServiceRequestResponse>
    ) {
      state.removePhotoRequest.message = action.payload.message;
      state.removePhotoRequest.status = action.payload.status;
    },
    handleRotatePhotoResponse(
      state,
      action: PayloadAction<ServiceRequestResponse>
    ) {
      state.rotatePhotoRequest.message = action.payload.message;
      state.rotatePhotoRequest.status = action.payload.status;
    },
    setIsItemLoading(state, action: PayloadAction<boolean>) {
      state.isItemUpdating = action.payload;
    },
    sendPunchItems(_state, _action: PayloadAction<number>) {},
    getTemplates(state, _action) {
      state.templateStatus = 'loading';
    },
    setTemplates(state, action: PayloadAction<PunchlistTemplateVm[]>) {
      state.templates = action.payload;
      state.templateStatus = 'completed';
    },
    addTemplate() {},
    copyTemplate(_state, _action: PayloadAction<number>) {},
    updateTemplate(
      _state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) {},
    addTemplateItem(_state, _action: PayloadAction<number>) {},
    updateTemplateItem(
      state,
      _action: PayloadAction<{ id: number; info: UpdateObject }>
    ) {
      state.templateStatus = 'loading';
    },
    removeTemplateItem(state, _action: PayloadAction<number>) {
      state.templateStatus = 'loading';
    },
    setTemplateStatus(
      state,
      action: PayloadAction<'idle' | 'loading' | 'completed'>
    ) {
      state.templateStatus = action.payload;
    },
  },
});

export { PunchlistActions, PunchlistReducer };

export default PunchlistReducer;
