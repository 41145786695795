import * as React from 'react';
import { ListItem } from '../interfaces/interfaces';
import Modal from './Modal';
import { JoinedList } from './JoinedList';
import { MessageBox } from './MessageBox';

interface Props {
    projectId: number;
    folderPath: string;
    close: () => void;
}

export const FolderSubscriber = (props: Props) => {

    const [team, setTeam] = React.useState([] as ListItem[])
    const [subscribers, setSubscribers] = React.useState([] as ListItem[])
    const [message, setMessage] = React.useState('')

    React.useEffect(() => {
        const getTeam = async () => {
            fetch(`api/ProjectTeam/GetTeam?id=${props.projectId}&asListItem=true`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setTeam(data);
                    else setMessage(data.message);
                })            
        }
        getTeam();
    }, [])

    React.useEffect(() => {
        const getSubscribers = async () => {
            fetch(`api/FolderSubscription/GetFolderSubscriptions?path=${props.folderPath}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setSubscribers(data);
                    else setMessage(data.message);
                })
        }
        getSubscribers();
    }, [])

    const _save = () => {
        const joinedList = document.querySelector('.subscriber-modal .joined-list') as HTMLDivElement,
            sList = joinedList.querySelector('.selected-list') as HTMLSelectElement,
            sOpts = sList.querySelectorAll('option');

        let userIds = '';
        sOpts.forEach((opt) => {
            userIds = userIds + `&userIds=${opt.value}`            
        })

        fetch(`api/FolderSubscription/Update?path=${props.folderPath}${userIds}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (!resOk) setMessage(data.message);
                else props.close();
            })
    }

    const split = props.folderPath.split('/'), folderName = split[split.length - 1];

    return (
        <Modal>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
            <div className="modal-header">
                <h4 className="modal-title">Subscribe users to {folderName}</h4>
            </div>
            <div className='modal-body subscriber-modal' style={{ 'height': '320px' }}>
                <JoinedList selectedItems={subscribers} availableItems={team} title='Subscribers' />
            </div>
            <div className='modal-footer'>
                <span className="btn btn-sm btn-green" onClick={_save}>Save</span>
                <span className="btn btn-sm btn-outline-secondary" onClick={() => props.close()}>Close</span>
            </div>
        </Modal>
    )
}