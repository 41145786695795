/**
 * About:
 * react-beautiful-dnd wrapper to add payloads and callback support on draggables and droppables
 * Source: https://gist.github.com/mikkokaar/5caff07a33f4711aa3dd72a40b4e7a73
 * This file extends components from https://github.com/atlassian/react-beautiful-dnd
 * Addresses the issues from https://github.com/atlassian/react-beautiful-dnd/issues/498
 *
 * Usage:
 * Import react-beautiful-dnd components from this file instead of from react-beautiful-dnd library
 */

import * as React from 'react';
import {
  DragDropContext as DragDropContext_,
  Droppable as Droppable_,
  Draggable as Draggable_,
  DragDropContextProps,
  DraggableProps,
  DroppableProps,
  DragStart,
  ResponderProvided,
  DragUpdate,
  DropResult,
} from '@hello-pangea/dnd';

// Maps

const draggableMap = {};
const droppableMap = {};

// Draggable

export class Draggable extends React.Component<ExtendedDraggableProps> {
  componentDidMount() {
    draggableMap[this.props.draggableId] = this;
  }

  componentWillUnmount() {
    delete draggableMap[this.props.draggableId];
  }

  callHandler = (handler, result) => {
    this.props[handler] && this.props[handler](result);
  };

  getPayload = () => this.props.payload;

  render() {
    return <Draggable_ {...this.props} />;
  }
}

// Droppable

export class Droppable extends React.Component<ExtendedDroppableProps> {
  componentDidMount() {
    droppableMap[this.props.droppableId] = this;
  }

  componentWillUnmount() {
    delete droppableMap[this.props.droppableId];
  }

  callHandler = (handler, result) => {
    this.props[handler] && this.props[handler](result);
  };

  getPayload = () => this.props.payload;

  render() {
    return <Droppable_ {...this.props} />;
  }
}

// DragDropContext

export class DragDropContext extends React.Component<DragDropContextProps> {
  callHandler = (droppableId, handler, result) => {
    droppableMap[droppableId] &&
      droppableMap[droppableId].callHandler(handler, result);
  };

  callHandlerOnDraggable = (draggableId, handler, result) => {
    draggableMap[draggableId] &&
      draggableMap[draggableId].callHandler(handler, result);
  };

  getPayload = (droppableId) => {
    return droppableMap[droppableId] && droppableMap[droppableId].getPayload();
  };

  getDraggablePayload = (draggableId) => {
    return draggableMap[draggableId] && draggableMap[draggableId].getPayload();
  };

  handleEvent = (handler) => (result) => {
    const { source, destination, draggableId } = result;

    if (source) {
      source.payload = this.getPayload(source.droppableId);
    }
    if (destination) {
      destination.payload = this.getPayload(destination.droppableId);
    }

    result.payload = this.getDraggablePayload(draggableId);

    this.callHandlerOnDraggable(draggableId, handler, result);

    if (destination) {
      this.callHandler(destination.droppableId, handler, result);
    }

    if (!destination || destination.droppableId !== source.droppableId) {
      this.callHandler(source.droppableId, handler, result);
    }

    this.props[handler] && this.props[handler](result);
  };

  render() {
    const newProps = {
      ...this.props,
      onBeforeDragStart: this.handleEvent('onBeforeDragStart'),
      onDragStart: this.handleEvent('onDragStart'),
      onDragUpdate: this.handleEvent('onDragUpdate'),
      onDragEnd: this.handleEvent('onDragEnd'),
    };
    return <DragDropContext_ {...newProps} />;
  }
}

interface ExtendedDroppableProps extends DroppableProps {
  payload?: any;
  onBeforeDragStart?(initial: DragStart): void;
  onDragStart?(initial: DragStart, provided: ResponderProvided): void;
  onDragUpdate?(initial: DragUpdate, provided: ResponderProvided): void;
  onDragEnd?(result: DropResult, provided: ResponderProvided): void;
}

interface ExtendedDraggableProps extends DraggableProps {
  payload?: any;
  onBeforeDragStart?(initial: DragStart): void;
  onDragStart?(initial: DragStart, provided: ResponderProvided): void;
  onDragUpdate?(initial: DragUpdate, provided: ResponderProvided): void;
  onDragEnd?(result: DropResult, provided: ResponderProvided): void;
}
