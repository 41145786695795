import * as React from "react";
import {
	ListItem,
	ProjectImportMap,
	MilestoneImportMap,
	ImportConfig,
	ImportSource,
} from "../../interfaces/interfaces";
import { MessageBox } from "../../components/MessageBox";
import { Loader } from "../../components/Loader";
import { SliderCheckbox } from "../../components/SliderCheckbox";
import { JoinedList } from "src/components/JoinedList";
import styled from "styled-components";

interface Props {
	clientId?: string | number;
	users: ListItem[];
}

interface State {
	clientMilestones: ListItem[];
	projectMap: ProjectImportMap;
	milestoneMaps: MilestoneImportMap[];
	eraseValuesOnEmpty: boolean;
	loading: boolean;
	message?: string;
	projectTeamMembers: string[];
}

const JoinedListDiv = styled.div({ marginTop: "10px", marginBottom: "30px" });

const FormBodyDiv = styled.div({ display: "flex", flexDirection: "column" });

export default class Imports extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			projectMap: {} as ProjectImportMap,
			milestoneMaps: [],
			clientMilestones: [],
			eraseValuesOnEmpty: false,
			projectTeamMembers: [],
		};
	}

	componentWillMount() {
		this._getMilestones(this.props.clientId);
	}

	componentWillUpdate(nextProps: Props) {
		if (nextProps.clientId != this.props.clientId) {
			this._getMilestones(nextProps.clientId);
		}
	}

	_getMilestones = (clientId?: number | string) => {
		fetch(`api/Milestone/GetAll?clientId=${clientId}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				this.setState({
					clientMilestones: data,
				});
			});
	};

	_editProjectMap = (e: React.FocusEvent<HTMLInputElement>) => {
		const field = e.currentTarget.name,
			value = e.currentTarget.value;

		const map = this.state.projectMap;
		map[field] = value;

		this.setState({ projectMap: map });
	};

	_editMilestoneMaps = (id: number, e: React.FocusEvent<HTMLInputElement>) => {
		const field = e.currentTarget.name,
			value = e.currentTarget.value;

		let maps = JSON.parse(JSON.stringify(this.state.milestoneMaps));

		if (!maps.length) {
			const newMap = { milestoneId: id };
			newMap[field] = value;
			maps.push(newMap);
		} else {
			const existingMapIdx = maps.findIndex((x) => x.milestoneId === id);
			if (existingMapIdx === -1) {
				const newMap = { milestoneId: id };
				newMap[field] = value;
				maps.push(newMap);
			} else {
				maps = maps.map((x) => {
					if (x.milestoneId === id) x[field] = value;
					return x;
				});
			}
		}

		this.setState({ milestoneMaps: maps });
	};

	_toggleEraseEmptyCells = () =>
		this.setState({ eraseValuesOnEmpty: !this.state.eraseValuesOnEmpty });

	_import = () => {
		this.setState({ loading: true });
		const pCol = (
				document.getElementById("project-number-column") as HTMLInputElement
			).value,
			pKey = (document.getElementById("project-key") as HTMLSelectElement)
				.value,
			rowStart = (document.getElementById("row-start") as HTMLInputElement)
				.value,
			sheetIndex = (document.getElementById("sheet-index") as HTMLInputElement)
				.value,
			files = (document.getElementById("manual-import") as HTMLInputElement)
				.files;

		const importConfig = {
			clientId: parseInt(this.props.clientId!.toString()) || 0,
			active: true,
			milestoneMaps: this.state.milestoneMaps,
			projectMap: this.state.projectMap,
			sheetIndex: parseInt(sheetIndex),
			projectIdColumn: pCol,
			projectKey: pKey,
			rangeStart: rowStart,
			source: ImportSource.Manual,
			eraseValueIfCellNull: this.state.eraseValuesOnEmpty,
		};

		const form = new FormData();
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; ++i)
				form.append("files", files[i], files[i].name);
		}

		form.append(
			"json",
			JSON.stringify({
				config: importConfig,
				userIds: this.state.projectTeamMembers,
			})
		);

		fetch(`api/Import/Manual`, {
			method: "POST",
			body: form,
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					this.setState({ message: "Import Complete", loading: false });
				} else {
					this.setState({ message: data.message, loading: false });
				}
			});
	};

	_clearMessage = () => this.setState({ message: undefined });

	render() {
		//const { clientMilestones } = this.state;
		//const milestoneLines = clientMilestones ?
		//    clientMilestones.map(x => {
		//        return (
		//            <div key={`${x.value}_${x.id}`} className='config-item-line milestone-map' data-id={x.id}>
		//                <b>{x.value}</b> &nbsp;
		//                <input className='form-control' data-milestone-id={x.id} /> <br/>
		//                <p>Enter the corresponding to the column, e.g. B, AC etc. You can leave empty if you don't want to update this milestone.</p>
		//            </div>
		//        )
		//    }) : []

		return (
			<div className="manual-import-form">
				<Loader loading={this.state.loading} />
				<MessageBox
					message={this.state.message}
					clearMessage={this._clearMessage}
				/>
				<div className="">
					<FormBodyDiv className="form-body">
						<div className="form-block">
							<b>Project Key</b>
							<select
								className=""
								id="project-key"
								style={{ height: "24px", width: "151px" }}
							>
								<option className="form-control" value="ProjectNumber">
									Project Number
								</option>
								<option className="form-control" value="SiteId">
									Site Id
								</option>
							</select>
							<br />
							<small>
								The project field you wish to use as the unique identifier for a
								project.
							</small>
						</div>
						<div className="form-block">
							<b>Project Key Column</b>
							<input id="project-number-column" /> <br />
							<small>
								Enter the corresponding to the column, e.g. B, AC etc.
							</small>
						</div>
						<div className="form-block">
							<b>Data Row Start</b>
							<input id="row-start" type="number" /> <br />
							<small>
								The row number where the data actually starts (skipping headers)
							</small>
						</div>
						<div className="form-block">
							<b>Sheet Number</b>
							<input id="sheet-index" type="number" /> <br />
							<small>
								The index of the sheet that contains the data you'd like to
								import.{" "}
								<u>
									IMPORTANT: this index is zero-based, so if targeting the first
									sheet, enter 0, for the second sheet, enter 1, etc.
								</u>
							</small>
						</div>
						<div className="form-block">
							<b>
								Erase On Empty Values{" "}
								<span
									className="fas fa-question"
									title="Indicate whether empty cells should erase or nullify existing values if they find a corresponding data match."
								></span>
							</b>
							<SliderCheckbox
								selected={this.state.eraseValuesOnEmpty}
								fieldName="eraseValuesOnEmpty"
								dataId={new Date().getTime()}
								onChange={() => this._toggleEraseEmptyCells()}
							/>
							{/*<label className='switch'>*/}
							{/*    <input type='checkbox' />*/}
							{/*    <span className='slider round' onClick={this._toggleEraseEmptyCells}></span>*/}
							{/*</label>*/}
						</div>

						{this.renderProjectMapLine()}
						{this.renderMilestoneMaps(this.state.clientMilestones)}
						<JoinedListDiv className={"form-block col-8"}>
							<JoinedList
								title="Project Team Members"
								availableItems={this.props.users}
								selectedItems={{} as ListItem[]}
								onChange={(x) =>
									this.setState({
										projectTeamMembers: x.map((y) => y.id.toString()),
									})
								}
							/>
						</JoinedListDiv>
						<div>
							<input
								style={{ marginLeft: "10px" }}
								id="manual-import"
								type="file"
								accept=".xls,.xlsx"
							/>
							{this.props.clientId ? (
								<button className="btn btn-sm btn-blue" onClick={this._import}>
									Import
								</button>
							) : (
								[]
							)}
						</div>
					</FormBodyDiv>
				</div>
			</div>
		);
	}

	private renderProjectMapLine() {
		return (
			<div className="project-import-map">
				<h5>Project Column Mappings</h5>
				<small>
					Enter the column lettering (A, BB, etc.) corresponding to the column
					that has the data you wish to map to the specified fields below.
				</small>
				<div className="mapping-blocks">
					<div>
						<label>Project Number</label>
						<input name="projectNumberColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Project Name</label>
						<input name="projectNameColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Site Id</label>
						<input name="siteIdColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Project Type</label>
						<input name="projectTypeColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Address</label>
						<input name="addressColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>City</label>
						<input name="cityColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>State</label>
						<input name="stateColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Zip Code</label>
						<input name="zipColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>CM PM</label>
						<input name="internalPmColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Client PM</label>
						<input name="externalPmColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Budget</label>
						<input name="budgetColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>Start Date</label>
						<input name="startDateColumn" onBlur={this._editProjectMap} />
					</div>
					<div>
						<label>End Date</label>
						<input name="endDateColumn" onBlur={this._editProjectMap} />
					</div>
				</div>
			</div>
		);
	}

	private renderMilestoneMaps(milestones: ListItem[]) {
		if (!milestones || !milestones.length) return [];

		const mapSections = milestones.map((x) => {
			return (
				<div className="milestone-map-form" key={x.id}>
					<label className="milestone-map-label">{x.value}</label>
					<div>
						<label>Due Date</label>
						<input
							name="dueColumn"
							onBlur={(e) =>
								this._editMilestoneMaps(parseInt(x.id.toString()), e)
							}
						/>
					</div>
					<div>
						<label>Complete Date</label>
						<input
							name="completeColumn"
							onBlur={(e) =>
								this._editMilestoneMaps(parseInt(x.id.toString()), e)
							}
						/>
					</div>
					<div>
						<label>Notes</label>
						<input
							name="notesColumn"
							onBlur={(e) =>
								this._editMilestoneMaps(parseInt(x.id.toString()), e)
							}
						/>
					</div>
				</div>
			);
		});

		return (
			<div className="milestone-import-maps">
				<h5>Milestone Column Mappings</h5>
				<small>
					Enter the column lettering (A, BB, etc.) corresponding to the column
					that has the data you wish to map to the specified fields below.
				</small>
				<div className="milestone-map-forms">{mapSections}</div>
			</div>
		);
	}
}
