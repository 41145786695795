import { Point } from "google-map-react";
import { WorkRegion } from "../interfaces/interfaces";

export interface MarkerProps {
    key: string,
    lat: number,
    lng: number,
    $hover?: boolean,
    $dimensionKey?: any,
    $getDimensions?: any,
    scale?: number,
    size?: any,
    image?: string,
    onHoverStateChange?: any,
    onCloseClick?: any,
    onMouseAllow?: any
    navigate?: (id: number) => void
    hoveredAtTable?: boolean
    zoom: number
}

export interface ClusterMarkerProps {
    key: string,
    id: string | number,
    lat: number,
    lng: number,
    pointCount: number,
    totalPointCount: number,
    $hover?: boolean
}

export const K_HINT_HTML_DEFAULT_Z_INDEX = 1000000;
const K_SCALE_HOVER = 1;
const K_SCALE_TABLE_HOVER = 1;
const K_SCALE_NORMAL = 0.65;
const K_MIN_CONTRAST = 0.4;
export const K_STICK_SIZE = 10;
export const K_CIRCLE_SIZE = 60;
const K_SCALE_SMALL = 0.3;
const K_SCALE_MEDIUM = 0.45;
const K_BEFORE_AFTER_SCALES = [{ l: 15, scale: K_SCALE_SMALL }, { l: 10, scale: K_SCALE_MEDIUM }];
const K_SCALES_SUM = K_BEFORE_AFTER_SCALES.reduce((sum, el) => el.l + sum, 0);


export const helpers = {

    calcColor: (i: number, a?: number) => {

        return colors[i % 20]; 
       
        /* const r = i * 76 % 256,
            g = (i + 1) * 76 % 256,
            b = (i + 2) * 76 % 256;

        return a ?
            'rgb(' + r.toString() + ', ' + g.toString() + ', ' + b.toString() + ', ' + a.toString() + ')'
            : 'rgb(' + r.toString() + ', ' + g.toString() + ', ' + b.toString() + ')';
            */
    },

    calcHoverContentStyles: (scale: number) => {
        const contrast = K_MIN_CONTRAST + (1 - K_MIN_CONTRAST) * Math.min(scale / K_SCALE_NORMAL, 1);

        return {
            //transform: `scale(${scale} , ${scale}) translate(0px, -100%)` ,
            position: 'absolute',
            transform: 'translate(50%, -100%)',
            //WebkitTransform: `scale(${scale} , ${scale})`,
            WebkitTransform: 'translate(50%, -100%)',
            filter: `contrast(${contrast})`,
            WebkitFilter: `contrast(${contrast})`,
        };
    },

    calcMarkerMarkerStyle: (scale: number, zIndexStyle: any, markerStyle: any, imageStyle: any) => {
        const contrast = K_MIN_CONTRAST + (1 - K_MIN_CONTRAST) * Math.min(scale / K_SCALE_NORMAL, 1);

        return {
            //transform: `scale(${scale} , ${scale}) translate(0px, -100%)` ,
            transform: 'translate(0px, -150%)',
            //WebkitTransform: `scale(${scale} , ${scale})`,
            WebkitTransform: 'translate(0px, -150%)',
            filter: `contrast(${contrast})`,
            WebkitFilter: `contrast(${contrast})`,
            ...markerStyle,
            ...zIndexStyle,
            ...imageStyle
        };
    },

    calcRoundMarkerStyle: (scale: number, zIndexStyle: any, markerStyle: any, imageStyle: any) => {
        const contrast = K_MIN_CONTRAST + (1 - K_MIN_CONTRAST) * Math.min(scale / K_SCALE_NORMAL, 1);

        return {
            //transform: `scale(${scale} , ${scale}) translate(0px, -100%)` ,
            transform: 'translate(-50%, -50%)',
            //WebkitTransform: `scale(${scale} , ${scale})`,
            WebkitTransform: 'translate(-50%, -50%)',
            filter: `contrast(${contrast})`,
            WebkitFilter: `contrast(${contrast})`,
            ...markerStyle,
            ...zIndexStyle,
            ...imageStyle
        };
    },

    calcClusterMarkerStyle: (scale: number, zIndexStyle: any, markerStyle: any, imageStyle: any) => {
        const contrast = K_MIN_CONTRAST + (1 - K_MIN_CONTRAST) * Math.min(scale / K_SCALE_NORMAL, 1);

        return {
            //transform: `scale(${scale} , ${scale}) translate(0px, -100%)` ,
            transform: 'translate(-10px, -100%)',
            //WebkitTransform: `scale(${scale} , ${scale})`,
            WebkitTransform: 'translate(-10px, -100%)',
            filter: `contrast(${contrast})`,
            WebkitFilter: `contrast(${contrast})`,
            ...markerStyle,
            ...zIndexStyle,
            ...imageStyle
        };
    },

    distanceToMouse: (markerPos: Point, mousePos: Point, markerProps?: any | undefined) => {
        const x = markerPos.x;
        // because of marker non symmetric,
        // we transform it central point to measure distance from marker circle center
        // you can change distance function to any other distance measure
        const y = markerProps && markerProps.isRound ?
            markerPos.y - (markerProps.diameter || K_CIRCLE_SIZE) / 2 :
            markerPos.y - K_STICK_SIZE - K_CIRCLE_SIZE / 2;

        // use a coefficient if we want to increase the probability of certain markers to be targeted higher than others
        const distanceCoef = 1; 
        
        return distanceCoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
    },    

    drawRegions: (map, maps, regions, activeId?) => {
        if (map && maps) {
            [].forEach.call(regions, (r: WorkRegion, i: number) => {
                if (r.geoPoints && r.geoPoints.length) {
                    const coords = r.geoPoints.map(p => { return { lat: p.latitude, lng: p.longitude } })
                    // Paths array needs to include the first point as the last point so the final line can be drawn
                    coords.push({ lat: r.geoPoints[0].latitude, lng: r.geoPoints[0].longitude })

                    const color = helpers.calcColor(i)
                    const regionShape = new maps.Polygon({
                        paths: coords,
                        strokeColor: color, //"#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: color, // "#FF0000",
                        fillOpacity: r.id === activeId ? 0.3 : 0.15
                    })

                    regionShape.setMap(map);
                }
            })
        }
    },

    getRealFromTo: (rowFrom: number, rowTo: number, maxVisibleRows: number, totalSize: number) => {
        const addFrom = ((rowFrom + maxVisibleRows + K_SCALES_SUM) > (totalSize - 1))
            ? ((rowFrom + maxVisibleRows + K_SCALES_SUM) - (totalSize - 1)) : 0

        const dadd = K_SCALES_SUM - rowFrom;
        const addTo = dadd >= 0 ? dadd : 0;

        return {
            rowFrom: Math.max(0, rowFrom - K_SCALES_SUM - addFrom),
            rowTo: Math.min(totalSize - 1, rowFrom + maxVisibleRows + K_SCALES_SUM + addTo)
        };
    },

    getMarkerPos: (zoom: number) => {
        const K_MAX_ZOOM = 22;


    }
}

const colors = [
    'rgb(81,112,204)',
    'rgb(142,204,81)',
    'rgb(127,51,51)',
    'rgb(89,51,127)',
    'rgb(89,127,51)',
    'rgb(142,81,204)',
    'rgb(81,204,204)',
    'rgb(204,173,81)',
    'rgb(81,204,112)',
    'rgb(204,81,173)',
    'rgb(127,108,51)',
    'rgb(51,127,70)',
    'rgb(51,127,127)',
    'rgb(204,81,81)',
    'rgb(51,70,127)',
    'rgb(127,51,108)',
    'rgb(204,127,81)',
    'rgb(117,127,51)',
    'rgb(127,79,51)',
    'rgb(188,204,81)'];
