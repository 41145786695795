import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as cx from 'classnames';

import { UserNotification, UserNotificationStatus, actionCreators } from '../store/notifications';
import { ApplicationState } from '../store/index';
import { NotificationStream } from '../notifications/NotificationStream';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

export const MyNotifications = () => {

    const [notifications, setNotifications] = React.useState([] as UserNotification[]);
    const [loading, setLoading] = React.useState(true);

    const dispatch = useDispatch();
    const notificationStore = useSelector((s: ApplicationState) => s.notifications);    

    const _getNotifications = () => {
        fetch(`api/notification/get?showAll=${true}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setNotifications(data);
                setLoading(false);
            })
    }

    React.useEffect(() => {
        _getNotifications();
        //dispatch(actionCreators.getNotifications(true));
    }, [])

    return (
        <div className='my-notifications-page'>
            {loading && <StandardGridLoader />}
            <div className='pageHeader'>
                <h4>My Notification History</h4>
            </div>
            <hr/>
            <NotificationStream notifications={notifications} loading={notificationStore.isLoading} fullPage={true} />
        </div>
    )
}