import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as cx from "classnames";

import { formatDateTime } from "../helpers/formatters";
import { ApplicationState } from "../store";
import { TransmittalDetailModal } from "./TransmittalDetailModal";

interface Props {
	projectId: number | null;
}

export interface Transmittal {
	id: number;
	projectId: number | null;
	number: string;
	title: string;
	message: string;
	userId: string;
	userFullName: string;
	createdDateTime: string;
	sentDateTime: string | null;
	status: TransmittalStatus;
	allowComments: boolean;
	forApproval: boolean;
	recipients: TransmittalRecipient[];
	documents: TransmittalDocument[];
	reviewedCount: number;
}

export enum TransmittalStatus {
	Draft,
	Sent,
	Canceled,
	Approved,
}

export interface TransmittalRecipient {
	transmittalId: number;
	userId: string;
	userEmail: string;
	userFullName: string;
	hasReviewedTransmittal: boolean;
	comments: string;
	commentTimestamp: string;
	isApproved: boolean;
	approvalTimestamp: string;
}

export interface TransmittalDocument {
	transmittalId: number;
	documentAbsolutePath: string;
	fileName: string;
}

export const TransmittalGrid = (props: Props) => {
	const [transmittals, setTransmittals] = React.useState([] as Transmittal[]);
	const [showAdd, setShowAdd] = React.useState(false);
	const [detailId, setDetailId] = React.useState(null as number | null);
	const [baseDirectory, setBaseDirectory] = React.useState("");

	const projectStore = useSelector((s: ApplicationState) => s.detail);

	const _getTransmittals = (projectId: number) => {
		fetch(`api/transmittal/projecttransmittals?projectId=${projectId}`)
			// @ts-ignore
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setTransmittals(data);
				}
			});
	};

	const _fixHeaderWidth = () => {
		const container = document.querySelector(".sv-grid") as HTMLDivElement;
		const headerRow = container.querySelector(
				".sv-grid-header"
			) as HTMLDivElement,
			firstRow = container.querySelector(".sv-grid-line") as HTMLDivElement;
		if (firstRow != null) {
			const diff = headerRow.clientWidth - firstRow.clientWidth;
			headerRow.style.paddingRight = diff + "px";

			// The filter row is inside the header row so we don't need to adjust it
			//if (filterRow != null) filterRow.style.paddingRight = diff + 'px';
		}
	};

	React.useEffect(() => {}, []);

	React.useEffect(() => {
		if (props.projectId) {
			if (props.projectId !== 0) _getTransmittals(props.projectId);
			setBaseDirectory(
				`Clients/${projectStore.detail.clientId}/Projects/${projectStore.detail.id}`
			);
		}
	}, [props.projectId]);

	React.useEffect(() => {
		_fixHeaderWidth();
	}, [transmittals]);

	return (
		<div className="grid sv-grid transmittals-grid">
			{showAdd && (
				<TransmittalDetailModal
					transmittalId={null}
					projectId={props.projectId}
					close={() => {
						setShowAdd(false);
						_getTransmittals(props.projectId || 0);
					}}
					startingDir={baseDirectory}
				/>
			)}
			{detailId && (
				<TransmittalDetailModal
					transmittalId={detailId}
					projectId={props.projectId}
					close={() => {
						setDetailId(null);
						_getTransmittals(props.projectId || 0);
					}}
					startingDir={baseDirectory}
				/>
			)}
			<div className="grid-header sv-grid-header">
				<div className="my-col-2">Number</div>
				<div className="my-col-3">Title</div>
				<div className="my-col-2">Status</div>
				<div className="my-col-2">Creator</div>
				<div className="my-col-2">Sent</div>
				<div className="my-col-4">Recipient(s)</div>
				<div className="my-col-4">File(s)</div>
				<div className="my-col-1 sv-grid-buttons">
					<button
						className="btn btn-x-sm btn-blue fas fa-plus"
						onClick={() => setShowAdd(true)}
					></button>
				</div>
			</div>
			<div className="grid-body custom-scrollbar">
				<div className="sv-grid-body">
					{transmittals.map((x) => {
						const statusClass = getStatusClass(x);
						const docText =
							x.documents.length === 0
								? "None"
								: x.documents.length === 1
								? "1 file"
								: `${x.documents.length} files`;
						const viewProgressBar =
							x.recipients && x.status === TransmittalStatus.Sent
								? buildProgressBar(x)
								: [];
						return (
							<div
								key={x.id}
								className={cx("grid-line sv-grid-line", statusClass)}
								onClick={() => setDetailId(x.id)}
							>
								{viewProgressBar}
								<div className="my-col-2">{x.number}</div>
								<div className="my-col-3 truncate" title={x.title}>
									{x.title}
								</div>
								<div className="my-col-2">{getStatusText(x.status)}</div>
								<div className="my-col-2">{x.userFullName}</div>
								<div className="my-col-2">
									{x.sentDateTime ? formatDateTime(x.sentDateTime) : ""}
								</div>
								<div
									className="my-col-4 truncate"
									title={x.recipients.map((r) => r.userFullName).join(", ")}
								>
									{x.recipients.map((r) => r.userFullName).join(", ")}
								</div>
								<div className="my-col-5">{docText}</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

const getStatusClass = (t: Transmittal) => {
	switch (t.status) {
		case TransmittalStatus.Draft:
			return "left-tab-yellow";
		case TransmittalStatus.Sent:
			if (t.reviewedCount === t.recipients.length) return "left-tab-green";
			else if (t.reviewedCount === 0) return "left-tab-blue";
			else return "half-review";
		case TransmittalStatus.Canceled:
			return "left-tab-red";
	}
};

export const getStatusText = (s: TransmittalStatus) => {
	switch (s) {
		case TransmittalStatus.Draft:
			return "Draft";
		case TransmittalStatus.Sent:
			return "Sent";
		case TransmittalStatus.Canceled:
			return "Canceled";
	}
};

const buildProgressBar = (t: Transmittal) => {
	if (t.reviewedCount === 0 || t.reviewedCount === t.recipients.length)
		return [];
	const colors = t.recipients
		.map((x) =>
			x.hasReviewedTransmittal
				? "rgba(0, 150, 0, 0.7)"
				: "rgba(27, 87, 230, 0.7)"
		)
		.join(", ");
	return (
		<div
			className="transmittal-review-progress-bar"
			style={{ backgroundImage: `linear-gradient(to bottom, ${colors}` }}
			title={`${t.reviewedCount} of ${t.recipients.length} recipients have viewed this transmittal`}
		></div>
	);
};
