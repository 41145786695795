import * as React from 'react';
import GoogleMapReact, { ClickEventValue } from 'google-map-react'
import { BBox } from 'geojson';

import { Map } from './Map';
import { helpers } from './mapHelpers';
import { WorkRegion } from '../interfaces/interfaces';
import { RoundMarker } from './RoundMarker';


interface Props {
    regions: WorkRegion[];
    activeRegionId?: number;
    handleMapClick?: (e: ClickEventValue) => void;
    handleDrag: (childKey, childProps, mouse) => void;
    handleDragEnd: (childKey, childProps, mouse) => void;
    handleRemovePoint: (id: number) => void;
}

interface PolygonRef {
    regionId: number;
    polygon: any;
}

export const RegionEditorMap = (props: Props) => {

    //const [hoveredMarkerId, setHoveredMarkerId] = React.useState(null as number | null)
    const [bounds, setBounds] = React.useState<BBox>(null);
    const [zoom, setZoom] = React.useState(4);
    //const [draggable, setDraggable] = React.useState(true);
    //const [googleApiMap, setGoogleApiMap] = React.useState({ map: null, maps: null } as { map: any, maps: any })
    //const [polygons, setPolygons] = React.useState([] as PolygonRef[]);

    //const hoverContentStyle: any = { ...helpers.calcHoverContentStyles(4), fontSize: '15px' };
    const hoverContentStyle: any = {
        marginTop: '-2.25em', marginLeft: '0.7em', fontSize: '15px', transform: 'translateY(-75%)',
        WebkitTransform: 'translate&(-75%)',
    };

    const markers = props.regions.map((x, i) => {
        return x.geoPoints.map(p => {
            return <RoundMarker bgColor={helpers.calcColor(i, 0.35)}
                borderColor={helpers.calcColor(i, 0.8)}
                key={p.id.toString()}
                id={p.id}
                label=''
                lat={p.latitude}
                lng={p.longitude}
                $hover={true /*hoveredMarkerId === p.id*/}
                hoverContent={<span className='fas fa-times map-marker-hover-content' onClick={(e) => { /*alert(e.currentTarget.tagName);*/ if (e.currentTarget.tagName === 'SPAN') { props.handleRemovePoint(p.id) } }} style={hoverContentStyle}></span>}
                onClick={(e) => { /*e.stopPropagation(); alert('Marker clicked')*/ }}
                zoom={4} />;
        })
    }).reduce((prev, acc) => acc.concat(prev), []);

    return (
        <div style={{ height: '700px' }}>
            <Map
                zoom={4}
                handleChange={(z, b) => {
                    setZoom(z);
                    setBounds([b.nw.lng, b.se.lat, b.se.lng, b.nw.lat])
                }}
                onClick={props.handleMapClick}
                handleDragEnd={(ck, cp, m) => { props.handleDragEnd(ck, cp, m) }}
                handleDrag={props.handleDrag}
                mapMarkers={markers}
                regions={props.regions}
                activeRegionId={props.activeRegionId}
                />
            {/*<GoogleMapReact defaultCenter={centerOfUSA}*/}
            {/*    onChange={({ zoom, bounds }) => {*/}
            {/*        setZoom(zoom);*/}
            {/*        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])*/}
            {/*    }}*/}
            {/*    defaultZoom={4}*/}
            {/*    bootstrapURLKeys={{ key: 'AIzaSyBG1RmbuDUj0EhKyBX_sR3OlAo7ovnn0YU' }}*/}
            {/*    resetBoundsOnResize={true}*/}
            {/*    onChildMouseEnter={_onChildMouseEnter}*/}
            {/*    onChildMouseLeave={_onChildMouseLeave}*/}
            {/*    hoverDistance={20}*/}
            {/*    yesIWantToUseGoogleMapApiInternals*/}
            {/*    onGoogleApiLoaded={({ map, maps }) => _handleApiLoaded(map, maps)}*/}
            {/*    onClick={props.handleMapClick}*/}
            {/*    draggable={draggable}*/}
            {/*    onChildMouseDown={() => setDraggable(false)}*/}
            {/*    onChildMouseUp={(ck, cp, m) => { props.handleDragEnd(ck, cp, m); setDraggable(true) }}*/}
            {/*    onChildMouseMove={props.handleDrag}*/}
            {/*>*/}
            {/*    {markers}*/}
            {/*</GoogleMapReact>*/}
        </div>
    )
}