import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators as procurementActions } from '../store/procurement';
import { ListItem, ProcurementItem, VendorVm } from '../interfaces/interfaces';
import FilterableSelect from '../components/FilterableSelect';
import { ApplicationState } from '../store/index';
import { formatDateInputFriendly, toMoney } from '../helpers/formatters';
import { ProcurementQuoteDetailModal } from './ProcurementQuoteDetail';
import { Modal2 } from '../components/Modal';
import { FieldFilterableSelect, FieldInput, FieldTextArea } from '../components/FieldComponents';
import { VendorsActions, vendorsAndSubsSelector } from '../store/vendors';
import { Loader } from '../components/Loader';

export const AddQuoteComponent = (props: { item: ProcurementItem }) => {
  const [showExisting, setShowExisting] = React.useState(false);
  const [showAddModal, setShowAddmodal] = React.useState(false);
  const dispatch = useDispatch();

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);

  const { item } = props;
  const { selectedItemId, selectedQuoteId } = procurementStore;

  const _addExistingQuote = () => {
    const hiddenInput = document.querySelector(`#add-existing-quote-${item.id} input[name="add-existing-quote-${item.id}"]`) as HTMLInputElement;
    const quoteId = hiddenInput.value;
    dispatch(procurementActions.addExistingQuote(item.id, parseInt(quoteId)));
    setTimeout(() => { setShowExisting(false) }, 250);
  }

  if (!showExisting) {
    return (
      <div className='add-quote-component'>
        <button style={{ marginRight: "12px" }} className='btn btn-x-sm btn-blue' onClick={() => setShowExisting(true)}>+ Existing</button>
        <button className='btn btn-x-sm btn-blue' onClick={() => setShowAddmodal(true)}>+ New</button>
        {showAddModal && <QuoteAddModal projectId={item.projectId} itemId={item.id} close={() => setShowAddmodal(false)} />}
        {/*{(selectedItemId == null && selectedQuoteId != null) ?*/}
        {/*  <ProcurementQuoteDetailModal*/}
        {/*    quoteId={selectedQuoteId}*/}
        {/*    isReviewer={false}*/}
        {/*    editable={true}*/}
        {/*    close={() => dispatch(procurementActions.setSelectedQuote(null))}*/}
        {/*  /> : []*/}
        {/*}*/}
      </div>
    )
  }

  return (
    <div className='reviewer-line add-quote-component' style={{ position: 'relative' }}>
      <FilterableSelect id={`add-existing-quote-${item.id}`} items={procurementStore.quotes.map(x => new ListItem(x.id, `${x.vendorName} - ${toMoney(x.price)}`))} />
      <button className='btn btn-x-sm btn-green' onClick={_addExistingQuote}>Save</button>
      <button className='btn btn-x-sm btn-outline-secondary' onClick={() => setShowExisting(false)}>Cancel</button>
    </div>
  )
}

const QuoteAddModal = (props: { projectId: number, itemId?: number, close: () => void, }) => {
  const { projectId, itemId } = props;

  const dispatch = useDispatch();

  const vendors = useSelector(vendorsAndSubsSelector);

  const [vendorItems, setVendorItems] = React.useState([] as ListItem[])
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    setVendorItems(vendors.map(x => new ListItem(x.id.toString(), x.name)));
  }, [vendors])

  React.useEffect(() => {
    dispatch(VendorsActions.getVendorsAndSubs())
  }, [projectId])

  React.useEffect(() => {
    return () => {
      setSaving(false);
    }
  }, [])

  const [disabled, setDisabled] = React.useState(true);
  const [model, setModel] = React.useState({
    projectId: projectId,
    itemId: itemId,
    vendorId: '',
    vendorNameNoId: '',
    quoteDeliveryDate: '' as string,
    leadTime: '' as string,
    price: 0 as number,
    notes: '' as string,
    fileList: null as FileList | null,
  })

  React.useEffect(() => {
    const d = (model.vendorId === '' && model.vendorNameNoId === '') || model.quoteDeliveryDate === '' || model.leadTime === '' || model.price == 0;
    if (d !== disabled) setDisabled(d);
  }, [model])

  const _update = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.type === 'file' ? (e.currentTarget as HTMLInputElement).files :
      e.currentTarget.type === 'checkbox' ? (e.currentTarget as HTMLInputElement).checked : e.currentTarget.value;

    setModel({ ...model, [field]: value })
  }

  const _save = () => {
    if (!disabled) {
      setSaving(true);
      dispatch(procurementActions.addQuote(model));
      setTimeout(() => {
        props.close();
      }, 1500)
    }
  }

  return <Modal2>
    <Loader loading={saving} />
    <div className='modal-header'><h5>Add New Quote</h5></div>
    <div className='modal-body procurement-form custom-scrollbar'>
      <div className="quote-detail">
        <div className="quote-form">
          <div className="form-group">
            <label>Vendor*</label>
            <FieldFilterableSelect
              id={0}
              field="vendorId"
              backupField="vendorNameNoId"
              canEdit={true}
              items={vendorItems}
              value={model.vendorId == "0" ? model.vendorNameNoId : model.vendorId}
              openField
              update={(f, v, i) => {
                if (f === 'vendorId') setModel({ ...model, vendorId: v, vendorNameNoId: '' })
                else setModel({ ...model, vendorNameNoId: v, vendorId: '0' })
              }}
            />           
          </div>
          <div className="form-group">
            <label>Lead Time*</label>
            <FieldInput
              canEdit={true}
              field="leadTime"
              value={model.leadTime}
              id={0}
              dataType="string"
              update={(e) => setModel({ ...model, leadTime: e.currentTarget.value })}
            />
          </div>
          <div className="form-group">
            <label>Delivery Date*</label>
            <FieldInput
              canEdit={true}
              field="deliveryDate"
              value={formatDateInputFriendly(model.quoteDeliveryDate)}
              id={0}
              dataType="date"
              update={(e) => setModel({ ...model, quoteDeliveryDate: e.currentTarget.value })}
            />
          </div>
          <div className="form-group">
            <label>Price*</label>
            <FieldInput
              canEdit={true}
              field="price"
              value={model.price}
              id={0}
              dataType="money"
              update={_update}
            />
          </div>
          <div className="form-group">
            <label>Notes</label>
            <FieldTextArea
              canEdit={true}
              field="notes"
              value={model.notes}
              id={0}
              update={(e) => setModel({ ...model, notes: e.currentTarget.value })}
              rows={5}
            />
          </div>
          <div className="form-group">
            <label>Documents</label>
            <div style={{ width: "calc(100% - 220px)", maxWidth: '700px' }}>
              <div style={{ flexBasis: "100%", height: "0" }}></div>
              {(
                <div style={{ display: "block" }}>
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.doc,.docx"
                    onChange={(e) => setModel({ ...model, fileList: e.currentTarget.files })}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-blue' disabled={disabled} title={disabled ? 'Please fill out all required fields' : ''} onClick={_save}>Save</button>
      <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
    </div>
  </Modal2>
}