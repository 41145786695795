import * as React from "react";
import * as cx from "classnames";

import { GlobalMessageBox } from "../../components/MessageBox";
import {
	ChecklistDetailVm,
	ChecklistFieldType,
	ChecklistTemplateField,
	ChecklistVm,
} from "../../interfaces/interfaces";
import { DetailModalContext } from "./ChecklistTemplateModal";

interface NewFieldComponentProps {
	sectionId: number;
	templateId: number;
}

export const NewFieldComponent: React.FC<NewFieldComponentProps> = ({
	sectionId,
	templateId,
}) => {
	const [showOptions, setShowOptions] = React.useState(false);
	const api = React.useContext(DetailModalContext);

	const _selectOption = (t: ChecklistFieldType) => {
		api.addField(t, sectionId, templateId);
		setShowOptions(false);
	};

	const body = showOptions ? (
		<div className="new-field-options" style={{ display: "flex" }}>
			<div
				className="new-field-option-box"
				onClick={() => _selectOption(ChecklistFieldType.Text)}
			>
				Text
			</div>
			<div
				className="new-field-option-box"
				onClick={() => _selectOption(ChecklistFieldType.Date)}
			>
				Date
			</div>
			<div
				className="new-field-option-box"
				onClick={() => _selectOption(ChecklistFieldType.Checkbox)}
			>
				Checkbox
			</div>
			<div
				className="new-field-option-box"
				onClick={() => _selectOption(ChecklistFieldType.Range)}
			>
				Range
			</div>
			<div
				className="new-field-option-box"
				onClick={() => _selectOption(ChecklistFieldType.Option)}
			>
				Option
			</div>
		</div>
	) : (
		<span onClick={() => setShowOptions(true)} style={{ cursor: "pointer" }}>
			Add Field
		</span>
	);

	return (
		<div className={cx("new-field-component", showOptions ? "expanded" : "")}>
			<span
				className="fas fa-plus"
				onClick={() => setShowOptions(!showOptions)}
			></span>
			{body}
		</div>
	);
};
