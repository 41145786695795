//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction } from './';
import { AttachmentInfo } from '../interfaces/interfaces';

export interface Store {   
    photos: AttachmentInfo[],
    documents: AttachmentInfo[],
    warningMessage?: any
    message?: string
    loading: boolean
}

interface RequestAction {
    type: 'REQUEST',
}

interface ReceivePhotosAction {
    type: 'RECEIVE_PHOTOS',
    photos: AttachmentInfo[],
    warningMessage?: any
}

interface ReceiveDocumentsAction {
    type: 'RECEIVE_DOCUMENTS',
    documents: AttachmentInfo[],
    warningMessage?: any
}

interface FileExistsAction {
    type: 'FILE_WARNING',
    message: string
}

interface ClearFileExistsAction {
    type: 'CLEAR_FILE_WARNING'
}

interface ErrorAction {
    type: 'ERROR_ATTACHMENTS'
    message: string
}   


interface UploadAction {
    type: 'UPLOADING'
}

interface ClearAction { type: 'CLEAR' }

type KnownAction = RequestAction | ReceivePhotosAction | UploadAction | FileExistsAction
    | ReceiveDocumentsAction | ClearFileExistsAction | ErrorAction | ClearAction

export const actionCreators = {
    getDocuments: (projectId: number, milestoneId?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Attachment/GetDocs?id=${projectId}&milestoneId=${milestoneId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    dispatch({ type: 'RECEIVE_DOCUMENTS', documents: data })
                }
                else 
                    dispatch({ type: 'ERROR_ATTACHMENTS', message: data.message })
            })
    },
    getPhotos: (projectId: number, milestoneId?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        fetch(`api/Attachment/GetPhotos?id=${projectId}&milestoneId=${milestoneId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    dispatch({ type: 'RECEIVE_PHOTOS', photos: data })
                }
                else
                    dispatch({ type: 'ERROR_ATTACHMENTS', message: data.message })
            })
    },
    upload: (pId: number, mId: number, type: number, files: FileList | null): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (files) {
            const fData = new FormData();
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    fData.append('file' + i, files[i], files[i].name)
                }
                fetch(`api/Attachment/Upload?id=${pId}&milestoneId=${mId}&type=${type}`,
                    {
                        method: 'POST',                        
                        body: fData
                    })
                    .then(res => Promise.all([res.ok, res.status, res.json()]))
                    .then(([resOk, resStatus, data]) => {
                        if (resOk) {
                            if (type == 1) dispatch({ type: 'RECEIVE_PHOTOS', photos: data.files, warningMessage: data.message })
                            else dispatch({ type: 'RECEIVE_DOCUMENTS', documents: data.files, warningMessage: data.message })
                        }
                        else {
                            dispatch({ type: 'ERROR_ATTACHMENTS', message: data.message })
                        }
                    });
                dispatch({ type: 'UPLOADING' })
            }
        }
        
    },
    getZip: (type: number, pId: number, mId?: number): AppThunkAction<KnownAction> => () => {
        fetch(`api/Attachment/Zip?type=${type}&pId=${pId}&mId=${mId}`)
    },
    clearWarning: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR_FILE_WARNING' })
    },
    clear: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR' }) 
    }
}

const unloadedState = {
    photos: [],
    documents: [],
    projectId: 0,   
    loading: false
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'REQUEST':
            return { ...state }
        case 'RECEIVE_DOCUMENTS':
            return { ...state, documents: action.documents, warningMessage: action.warningMessage, loading: false }
        case 'RECEIVE_PHOTOS':
            return { ...state, photos: action.photos, warningMessage: action.warningMessage, loading: false }
        case 'FILE_WARNING':
            return { ...state, warningMessage: action.message, isLoading: false }
        case 'CLEAR_FILE_WARNING':
            return { ...state, warningMessage: undefined, isLoading: false }
        case 'UPLOADING':
            return { ...state, loading: true }
        case 'ERROR_ATTACHMENTS':
            return { ...state, message: action.message }
        case 'CLEAR':
            return {...state, photos: [], documents: [], message: undefined, warningMessage: undefined }
        default:{
            const exhaustiveCheck: never = action
        }
    }
    return state || unloadedState;
}