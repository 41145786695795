import * as React from 'react';
import * as ConfigStore from '../store/config'
import { connect } from 'react-redux'
import { ApplicationState } from '../store'
import Modal from './Modal';

interface IncomingProps {
    close: () => any
}

type DialogProps =
    IncomingProps
    & ConfigStore.Store
    & typeof ConfigStore.actionCreators

interface DialogState { }

class NewMilestoneDialog extends React.Component<DialogProps, DialogState> {
    //export const AddItemDialog: React.SFC<DialogProps> = props => {
    constructor(props: DialogProps) {
        super(props);
        this.state = {
        }
    }

    private save = () => {
        const input = document.getElementById('newMilestoneName') as HTMLInputElement;
        if (input) {
            this.props.addMilestone(input.value)
            this.props.close();
        }
    }

    public render() {
        return (
            <Modal id='Add_Milestone_Dialog'>
                <div className="modal-header">
                    <h4 className="modal-title">Create New Milestone</h4>
                </div>
                <div className="modal-body">
                    <input id='newMilestoneName' className="form-control" />
                </div>
                <div className="modal-footer">
                    <span className="btn btn-sm btn-green" onClick={this.save}>Save</span>
                    <span className="btn btn-sm btn-outline-secondary" onClick={this.props.close}>Close</span>
                </div>
            </Modal>
        )
    }
}

export default connect<ConfigStore.Store, typeof ConfigStore.actionCreators, IncomingProps>(
    //@ts-ignore
    (state: ApplicationState) => state.projects,
    ConfigStore.actionCreators
)(NewMilestoneDialog)