import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { MAX_WINDOW_COUNT, WindowsState } from './window.reducer';

const windowsState = (state: ApplicationState): WindowsState => state.windows;

export const windowsSelector = createSelector(
  windowsState,
  (state) => state.windows
);

export const latestIdSelector = createSelector(windowsSelector, (windows) => {
  const lastId = Math.max(0, ...windows.map((x) => x.id));
  return lastId == 1 ? 0 : 1;
});

export const canOpenWindowSelector = createSelector(
  windowsState,
  (state) => state.windowCount < MAX_WINDOW_COUNT
);

export const windowCountSelector = createSelector(
  windowsState,
  (state) => state.windowCount
);

export const moveEventSelector = createSelector(
  windowsState,
  (state) => state.moveEvent
);

export const areWindowsLoadingSelector = createSelector(
  windowsState,
  (state) => state.isLoading
);

export const shouldWindowsReloadSelector = createSelector(
  windowsState,
  (state) => state.shouldWindowsReload
);
