import * as React from 'react';
import cx from 'classnames'
import { useGlobalMessage } from '../helpers/globalDispatches';
import { GlobalMessageBox } from '../components/MessageBox';
import { ComplianceFieldType, VendorComplianceField, VendorComplianceFieldOption } from '../config/global/VendorComplianceConfig';
import DocIcon from '../components/DocIcon';
import { IDocumentInfo } from '../interfaces/interfaces';
import { formatDate, formatDateInputFriendly } from '../helpers/formatters';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store/index';
import { hasRole, KnownRoles } from '../auth/auth';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

export interface VendorComplianceEntry {
    id: number,
    vendorId: number,
    fieldName: string,
    fieldType: ComplianceFieldType,
    entryValue: string,
    options: VendorComplianceFieldOption[],
    expirationDate: string | null,
    expired: boolean,
    attachments: VendorComplianceAttachment[],
    lastModified: string
}

export interface VendorComplianceAttachment {
    id: number,
    filePath: string,
    vendorComplianceEntryId: number,
    uploadedTimestamp: string,
    inactiveTimestamp: string | null,
    expirationDate: string | null,
    //expired: boolean
}

export const VendorComplianceTab = (props: { vendorId: number }) => {

    const [loading, setLoading] = React.useState(true);
    const [complianceEntries, setComplianceEntries] = React.useState([] as VendorComplianceEntry[])
    const updateMessage = useGlobalMessage();
    const userStore = useSelector((s: ApplicationState) => s.user);

    const canEdit = hasRole(userStore.user, [KnownRoles.CoreSuperUser, KnownRoles.Admin])

    React.useEffect(() => {
        _getEntries();
    }, [])

    const _getEntries = () => {
        fetch(`api/Vendor/GetComplianceInfo?id=${props.vendorId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) { setLoading(false); setComplianceEntries(data); }
                else updateMessage(data.message);
            })
    }

    const _updateEntry = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const entryId = e.currentTarget.getAttribute('data-id');
        const value = (e.currentTarget instanceof HTMLInputElement && e.currentTarget.type === 'checkbox') ? e.currentTarget.checked ? 'true' : 'false' : e.currentTarget.value;

        fetch(`api/Vendor/EditComplianceValue?vendorId=${props.vendorId}&entryId=${entryId}&value=${value}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setComplianceEntries(data)
                else updateMessage(data.message);
            })
    }

    const _updateExpiration = (e: React.FocusEvent<HTMLInputElement>) => {
        const entryId = e.currentTarget.getAttribute('data-id');
        const value = e.currentTarget.value;

        fetch(`api/Vendor/ComplianceExpiration?vendorId=${props.vendorId}&entryId=${entryId}&value=${value}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setComplianceEntries(data)
                else updateMessage(data.message);
            })
    }

    const _uploadFile = (e: React.MouseEvent<HTMLButtonElement>) => {
        const fileInput = e.currentTarget.parentElement ? e.currentTarget.parentElement.querySelector('input[type="file"]') as HTMLInputElement : null;
        if (fileInput) {
            const entryId = fileInput ? fileInput.getAttribute('data-id') : 0;

            const form = new FormData();

            //@ts-ignore
            if (fileInput !== null) { form.append('file', fileInput.files[0], fileInput.files[0].name) }

            fetch(`api/Vendor/ComplianceFile?vendorId=${props.vendorId}&entryId=${entryId}`, {
                method: 'PUT',
                body: form
            })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setComplianceEntries(data);
                        fileInput.value = '';
                    }
                    else updateMessage(data.message)
                })
        }
    }

    const _deleteFile = (fileId: number) => {
        fetch(`api/Vendor/DeleteComplianceFile?vendorId=${props.vendorId}&FileId=${fileId}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setComplianceEntries(data)
                else updateMessage(data.message);
            })
    }

    const entries = loading ?
        <StandardGridLoader rowContentHeight={22} /> :
        complianceEntries.map(x => {
            const valueInput =
                x.fieldType == ComplianceFieldType.Checkbox ?
                    <input data-id={x.id} type='checkbox' defaultChecked={x.entryValue === 'true'} onChange={_updateEntry} disabled={!canEdit} /> :
                    x.fieldType == ComplianceFieldType.Date ?
                        <input data-id={x.id} type='date' className='form-control' defaultValue={x.entryValue} onBlur={_updateEntry} disabled={!canEdit} /> :
                        <select data-id={x.id} className='form-control' defaultValue={x.entryValue} onChange={_updateEntry} disabled={!canEdit}>
                            <option className='form-control' value=''></option>
                            {x.options.map(y => <option key={y.id} className='form-control' value={y.id.toString()}>{y.optionName}</option>)}
                        </select>
            return (
                <div className={cx('form-group compliance-entry', x.expired && 'expired')} key={x.id}>
                    <div className='inline-label my-col-4'>{x.fieldName}</div>
                    <div className='my-col-5'>{valueInput}</div>
                    <div className='my-col-3'><label style={{ 'width': '100px', 'lineHeight': '2' }}>Exp Date:</label><input type='date' className='form-control' data-id={x.id} defaultValue={formatDateInputFriendly(x.expirationDate || '')} onBlur={_updateExpiration} disabled={!canEdit} /></div>
                    <div><input type='file' data-id={x.id} /><button className='btn btn-sm btn-blue' onClick={_uploadFile}>Upload</button> </div>
                    {
                        x.attachments.map(y => <DocIcon key={y.id} doc={{ id: y.id, filePath: y.filePath, timestamp: y.uploadedTimestamp } as IDocumentInfo} />)
                    }
                </div>
            )
        })



    return (
        <div className='vendor-compliance-tab'>
            {/*<h5>Compliance</h5>*/}
            <hr />
            {entries}
        </div>
    )


}