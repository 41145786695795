import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { SafetyKPIState } from './safetyKPI.reducer';

const safetyKPIState = (state: ApplicationState): SafetyKPIState =>
  state.safetyKPI;

export const safetyKpiTemplatesSelector = createSelector(
  safetyKPIState,
  (state) => state.templates
);

export const canRetrieveTemplatesSelector = createSelector(
  safetyKPIState,
  (state) => state.templateStatus === 'idle'
);

export const isTemplatesLoadingSelector = createSelector(
  safetyKPIState,
  (state) => state.templateStatus === 'loading'
);

export const newDuplicateTemplateSelector = createSelector(
  safetyKPIState,
  (state) => state.newDuplicateTemplate
);
