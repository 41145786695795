import * as React from 'react'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import * as Dfr from '../store/dfr'
import { MessageBox } from '../components/MessageBox';

interface Props { }

type ListProps = Props
    & Dfr.Store
    & typeof Dfr.actionCreators

class DfrEvent extends React.Component<ListProps, any> {

    saveChanges = (e: React.FormEvent<HTMLInputElement>, field: string, entityId: number) => {
        const typeInput = e.currentTarget.value;
        this.props.updateChanges(entityId, field, typeInput, 4);
    }

    render() {
        const eventList = this.props.event.map((x, i) => {
            return (
                <div className="dfr_rowP_E dfr_header_row col-sm-12" key={x.id}>
                    <div className='col-sm-3 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.company || ""} onBlur={(e) => this.saveChanges(e, 'company', x.id)} disabled={this.props.isLocked} />
                    </div>
                    <div className='col-sm-2 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.name || ""} onBlur={(e) => this.saveChanges(e, 'name', x.id)} disabled={this.props.isLocked} />
                    </div>
                    <div className='col-sm-2 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.eventType || ""} onBlur={(e) => this.saveChanges(e, 'eventType', x.id)} disabled={this.props.isLocked} />
                    </div>
                    <div className='col-sm-4 P_E_E_min-width'>
                        <input className="dfr_Personnel form-control" defaultValue={x.detail || ""} onBlur={(e) => this.saveChanges(e, 'detail', x.id)} disabled={this.props.isLocked} />
                    </div>
                    {!this.props.isLocked && <div className="col-sm-1"><span className="btn fas fa-times" onClick={() => this.props.deleteRow(x.id, 4)}></span></div>}
                </div>
            )
        })

        return (
            <div className='tile'>                
                <h5>Events</h5>
                <hr />
                {this.props.event.length == 0 && this.props.isLocked ? <div><h4>No Events Listed</h4></div> :
                    <div className='col-sm-12 dfr_header_row'>
                        <div className='col-sm-3 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Company
                        </label>
                        </div>
                        <div className='col-sm-2 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Name
                        </label>
                        </div>
                        <div className='col-sm-2 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Event Type
                        </label>
                        </div>
                        <div className='col-sm-4 P_E_E_min-width'>
                            <label className="dfr_label_row ">
                                Detail
                        </label>
                        </div>
                        <div className="col-sm-1">
                            {this.props.isLocked ? [] : <span className="btn btn-sm btn-blue fas fa-plus" onClick={() => this.props.addEvent(this.props.id)}></span>}
                        </div>
                    </div>
                }
                {eventList}
            </div>
        )
    }
}

export default connect<Dfr.Store, typeof Dfr.actionCreators, Props>(
    //@ts-ignore
    (state: ApplicationState) => state.dfr,
    Dfr.actionCreators
)(DfrEvent)