import * as React from 'react';
import { CustomGraphInfo } from '../charts/GraphPart';
import { ListItem } from '../interfaces/interfaces';
import Modal from '../components/Modal';
import { JoinedList } from '../components/JoinedList';
import { MessageBox } from '../components/MessageBox';

interface Props {
    graphInfo: CustomGraphInfo,
    close: (message?: string) => void
}

interface State {
    users: ListItem[],
    message?: string;
}

export default class ShareGraphModal extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            users: []
        }
    }

    componentDidMount() {
        this._getUsers();
    }

    _getUsers = () => {
        fetch(`api/User/GetAll`)
            .then(res => res.json())
            .then(users => this.setState({ users: users }))
    }

    _share = () => {
        const users = document.querySelectorAll('.share-graph-body .selected-list option');
        if (users) {
            const userIds: string[] = [];
            [].forEach.call(users, (user: HTMLOptionElement) => {
                userIds.push(user.value)
            })

            const userIdParam = userIds.map(x => `userIds=${x}`).join('&')

            fetch(`api/DashGraph/Share?id=${this.props.graphInfo.id}&${userIdParam}`, { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.props.close('Graph Shared')
                    }
                    else {
                        this.setState({ message: data.message });
                        this.props.close(data.message)
                    }
                })
        }
    }

    render() {
        const { users } = this.state,
            { graphInfo } = this.props;

        return (
            <React.Fragment>
                <MessageBox message={this.state.message} clearMessage={() => this.setState({ message: undefined })} />
                <Modal dialogStyle={{ maxWidth: '650px' }}>
                    <div className='modal-header'>
                        <h4>Share {graphInfo.title}</h4>
                    </div>
                    <div className='modal-body share-graph-body' style={{ height: '320px' }}>
                        <JoinedList availableItems={users} selectedItems={[]} title={'Users'} />
                    </div>
                    <div className='modal-footer'>
                        <button className='btn btn-sm btn-blue' onClick={this._share}>Share</button>
                        <button className='btn btn-sm btn-outline-secondary' onClick={() => this.props.close()}>Close</button>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}