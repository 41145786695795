import { Component } from 'react';
import * as React from 'react';
import * as download from 'downloadjs';
import { Loader } from './Loader';
import { MessageBox } from './MessageBox';
import * as cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectionSelector,
  ProcessingNotificationActions,
} from 'src/store/processingNotifications';

interface Props {
  actionPath: string;
  fileName?: string;
  fileType: string;
  queryParams: any;
  inlineStyles?: any;
  httpMethod?: string;
  httpBody?: any;
  className?: string;
}

type State = { loading: boolean; message?: string };

export const downloadFileTask = (
  queryParams,
  actionPath,
  httpMethod,
  httpBody
) => {
  let url = actionPath + '?';
  const qString: any[] = [];
  Object.keys(queryParams).forEach((key: any) => {
    if (Array.isArray(queryParams[key])) {
      queryParams[key].forEach((val: any) =>
        qString.push(encodeURIComponent(key) + '=' + encodeURIComponent(val))
      );
    } else
      qString.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      );
  });
  url = url + qString.join('&');

  const fetchTask =
    httpMethod === 'POST' || httpMethod === 'PUT'
      ? fetch(url, {
          credentials: 'same-origin',
          method: httpMethod,
          body: JSON.stringify(httpBody),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
      : fetch(url, { credentials: 'same-origin' });

  return fetchTask;
};

export const getFileName = (header: string | null) => {
  let strFileName = '';
  if (header) strFileName = scrubContentDispositionForFilename(header);

  return strFileName;
};

export const FileDownloader = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLSpanElement>) => {
    const [state, setState] = React.useState({ loading: false } as State);
    const connection = useSelector(connectionSelector);
    const dispatch = useDispatch();

    const _downloadFile = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      const { queryParams, actionPath, httpMethod, httpBody } = props;
      if (queryParams === undefined) {
        return null;
      }
      setState({
        ...state,
        loading: true,
      });

      const fetchTask = downloadFileTask(
        queryParams,
        actionPath,
        httpMethod,
        httpBody
      );

      fetchTask
        .then((res) => {
          return Promise.all([
            res.ok,
            res,
            res.headers.get('Content-Disposition'),
          ]);
        })
        .then(async ([resOk, response, header]) => {
          if (resOk) {
            const data = await response.blob();
            let strFileName = '';
            if (header)
              strFileName = scrubContentDispositionForFilename(header);
            if (strFileName.length === 0) {
              strFileName =
                (props.fileName &&
                  props.fileName.replace(/[.:<>"/\\|?*]/g, '')) ||
                '';
            }
            //@ts-ignore
            if (download(data, strFileName)) {
              if (connection) {
                connection.invoke(
                  'SendDownloadedMessage',
                  props.fileName ?? strFileName
                );
              }
            }

            setState({
              ...state,
              loading: false,
            });
          } else {
            const message = await response.json();
            setState({
              ...state,
              loading: false,
              message:
                message && typeof message.message == 'string'
                  ? message.message
                  : 'Error downloading files',
            });
            dispatch(
              ProcessingNotificationActions.setShowNewNotification({
                show: false,
                isUpload: false,
              })
            );
          }
        });
    };

    const _clearMessage = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation();
      setState({ ...state, message: undefined });
    };

    const title = `Download ${props.fileType}`;
    return (
      <span
        ref={ref}
        style={props.inlineStyles}
        className={cx(
          'fas fa-download',
          props.className || 'btn btn-sm btn-outline-dark'
        )}
        onClick={_downloadFile}
        title={title}
      >
        <MessageBox message={state.message} clearMessage={_clearMessage} />
      </span>
    );
  }
);

const scrubContentDispositionForFilename = (contentDisposition: string) => {
  // filename* should be prefered
  let result = '';
  if (contentDisposition.indexOf('filename*=') !== -1) {
    const split = contentDisposition.split('filename*=');
    result = split[split.length - 1];
    // filename* seems to ship with utc specification in front of the file name
    if (result.indexOf('UTF-') !== -1) {
      const quoteSplit = result.split("''");
      result = quoteSplit[quoteSplit.length - 1];
    }
  } else if (contentDisposition.indexOf('filename=') !== -1) {
    const split = contentDisposition.split('filename=');
    result = split[split.length - 1];
  }

  return decodeURI(result);
};

const downloadFile = () => {};
