import * as React from 'react';
import * as cx from 'classnames';
import { RouteComponentProps } from 'react-router';
import { BackLink } from '../components/BackLink';
import { getSubmittal } from '../services/submittal';
import { SubmittalDetail } from './SubmittalDetail';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { EventHistoryLog } from '../components/EventHistoryLog';

export const SubmittalPage = (props: RouteComponentProps<{ id: string }>) => {

    const [submittalId, setSubmittalId] = React.useState(0);
    const [projectId, setProjectId] = React.useState(0);
    const [projectNumber, setProjectNumber] = React.useState(null as string | null);    
    const [eventLogRenderTimestamp, setTimestamp] = React.useState(new Date().getTime());

    React.useEffect(() => {
        const id = parseInt(props.match.params.id) || 0;
        const get = async () => {
            const res = await getSubmittal(id)
            if (res && res.status === 200) {
                setProjectId(res.data.detail.projectId);
                setProjectNumber(res.data.projectNumber);
            }
        }
        get();
        setSubmittalId(parseInt(props.match.params.id));
    }, [props.match.params.id]);

    return (
        <div id="submittal-page" className="with-logs">
            <BackLink link={`/project/${projectId}#submittals`} />
            {projectNumber ? <h3>{projectNumber}</h3> : <RectangleLoader height='33px' width='250px' style={{ display: 'block' }} />}
            <hr />
            <div style={{ display: "flex" }}>
                <SubmittalDetail id={parseInt(props.match.params.id || '0')} updateCallback={() => setTimestamp(new Date().getTime())} />
                <div
                    className="submittal-logs mobile-hide"
                    style={{ position: "relative" }}
                >
                    <div className="logs-header">History Logs</div>
                    {submittalId !== undefined && submittalId !== 0 ? (
                        <EventHistoryLog key={eventLogRenderTimestamp} objectName="Submittal" objectId={submittalId} />
                    ) : (
                        []
                    )}
                </div>
            </div>
        </div>
    )
}