import * as React from 'react';
import GoogleMapReact, { ClickEventValue } from 'google-map-react'
import { BBox } from 'geojson';

import SimpleMarker, { Marker } from './SimpleMarker';
import { K_CIRCLE_SIZE, helpers } from './mapHelpers';
import useSupercluster from './useSupercluster'
import { MarkerCluster } from './MarkerCluster';
import { WorkRegion } from '../interfaces/interfaces';

//import ProjectMapMarker, { K_SCALE_NORMAL } from './ProjectMapMarker.jsx';

export interface GeoJson {
    type: string,
    properties: any
    geometry: { type: string, coordinates: number[] }
}


interface Props {
    points?: GeoJson[];
    //markers: Marker[],
    center?: {
        lat: number;
        lng: number;
    };
    handleChange: (zoom, bounds) => void;
    zoom: number;
    onMarkerHover?: any;
    navigate?: (id: number) => any;
    hoveredRowKey?: number;
    maxVisibleRows?: number;
    visibleRowFirst?: number;
    visibleRowLast?: number;
    onClick?: (value: ClickEventValue) => void;
    regions?: WorkRegion[];
    activeRegionId?: number;
    mapMarkers?: JSX.Element[];
    markerHoverDistance?: number;
    preventClusters?: boolean;
    handleMapClick?: (e: ClickEventValue) => void;
    handleDrag?: (childKey, childProps, mouse) => void;
    handleDragEnd?: (childKey, childProps, mouse) => void;
}

export const centerOfUSA = {
    lat: 39.828,
    lng: -98.58
}

interface PolygonRef {
    regionId: number;
    polygon: any;
}

export const Map = (props: Props) => {

    // get map bounds
    //const [bounds, setBounds] = React.useState([-1.2411810957931664, 52.61208435908725, -1.0083656811012531, 52.64495957533833,]);
    const [bounds, setBounds] = React.useState<BBox>(null);
    const [zoom, setZoom] = React.useState(props.zoom);
    const [draggable, setDraggable] = React.useState(true);
    const [googleApiMap, setGoogleApiMap] = React.useState({ map: null, maps: null } as { map: any, maps: any })
    const [polygons, setPolygons] = React.useState([] as PolygonRef[]);

    const { points } = props;

    React.useEffect(() => {
        const { map, maps } = googleApiMap;
        if (map && maps && props.regions) {
            _drawRegions(map, maps, props.regions, props.activeRegionId);
        }
    }, [props.regions, googleApiMap, props.activeRegionId])    

    const _drawRegions = (map, maps, regions, activeId?) => {
        if (map && maps) {
            let polyRefs: PolygonRef[] = polygons.slice(); // JSON.parse(JSON.stringify(polygons));
            [].forEach.call(regions, (r: WorkRegion, i: number) => {
                if (r.geoPoints && r.geoPoints.length) {
                    const coords = r.geoPoints.map(p => { return { lat: p.latitude, lng: p.longitude } })
                    // Paths array needs to include the first point as the last point so the final line can be drawn
                    coords.push({ lat: r.geoPoints[0].latitude, lng: r.geoPoints[0].longitude })

                    const color = helpers.calcColor(i)

                    let polygonRef = polygons.find(poly => poly.regionId === r.id);

                    const fillOpacity = 0.15
                    let strokeWeight = 2;

                    if (r.id === activeId) {
                        //fillOpacity = 0.95;
                        strokeWeight = 4;
                    }

                    if (polygonRef === undefined) {
                        polygonRef = {
                            regionId: r.id,
                            polygon: new maps.Polygon({
                                paths: coords,
                                strokeColor: color, //"#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: color, // "#FF0000",
                                fillOpacity: fillOpacity
                            })
                        }

                        polyRefs.push(polygonRef);
                    } else {
                        polygonRef.polygon.setPaths(coords);
                        polygonRef.polygon.setOptions({ strokeWeight: strokeWeight });

                        // @ts-ignore We know polygonRef is not undefined here
                        polyRefs = polyRefs.map(x => x.regionId === r.id ? polygonRef : x);
                    }

                    //console.log(`${coords[0].lat}, ${coords[0].lng}`);

                    polygonRef.polygon.setMap(map);
                }
            })

            setPolygons(polyRefs);
        }
    }

    const _handleApiLoaded = (map, maps) => {
        setGoogleApiMap({ map, maps });
        if (props.regions) _drawRegions(map, maps, props.regions, props.activeRegionId);
    }    

    const _onChildMouseEnter = (key: any, childProps: any) => {
        const markerId = childProps.id
        if (childProps.pointCount === undefined) {                      
            if (props.onMarkerHover) props.onMarkerHover(markerId)
        }
        //else {
        //    // @ts-ignore
        //    const idx = clusters.findIndex(c => c.id === markerId);
        //    if (props.onMarkerHover) props.onMarkerHover(idx)            
        //}
    }

    const _onChildMouseLeave = (/* key, childProps */) => {
        if (props.onMarkerHover) props.onMarkerHover(-1);        
    }

    const _onChildMouseUp = (ck, cp, m) => {
        if (props.handleDragEnd) {
            props.handleDragEnd(ck, cp, m);
            setDraggable(true)
        }
    }

    // get clusters
    //const { clusters, supercluster } = useSupercluster({
    //    points,
    //    bounds,
    //    zoom,
    //    options: { radius: 100, maxZoom: 12 }
    //});

    //const mapMarkers = clusters.map(x => {
    //    const [longitude, latitude] = x.geometry.coordinates;
    //    const { cluster: isCluster, point_count: pointcount, id: id, label: label, bgColor: bgColor } = x.properties;
    //    if (isCluster) {
    //        return <MarkerCluster key={x.id} id={x.id} lat={latitude} lng={longitude}
    //                pointCount={pointcount} totalPointCount={points.length} />
    //    }
    //    return <SimpleMarker key={id} id={id} label={label}
    //        lat={latitude} lng={longitude} navigate={props.navigate}
    //        hoveredAtTable={id == props.hoveredRowKey}
    //        zoom={props.zoom} bgColor={bgColor} />
    //})

    return (
        <div style={{ height: '100%' }}>
            {/*@ts-ignore */}
            <GoogleMapReact defaultCenter={centerOfUSA}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => _handleApiLoaded(map, maps)}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
                    props.handleChange(zoom, bounds);
                }}
                defaultZoom={props.zoom}
                bootstrapURLKeys={{ key: 'AIzaSyBG1RmbuDUj0EhKyBX_sR3OlAo7ovnn0YU' }}
                resetBoundsOnResize={true}
                onChildMouseEnter={_onChildMouseEnter}
                onChildMouseLeave={_onChildMouseLeave}
                //distanceToMouse={true ? undefined : helpers.distanceToMouse}
                distanceToMouse={undefined}
                hoverDistance={props.markerHoverDistance || K_CIRCLE_SIZE}
                onClick={props.handleMapClick}
                draggable={draggable}
                onChildMouseDown={props.handleDragEnd === undefined ? undefined : () => setDraggable(false)}
                onChildMouseUp={_onChildMouseUp}
                onChildMouseMove={props.handleDrag}
            >
                {props.mapMarkers}
            </GoogleMapReact>
        </div>
    )
}