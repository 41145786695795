import * as React from 'react';

import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import { SortInfo, VendorProject, VendorVm } from '../interfaces/interfaces';

export const VendorProjectGrid = (props: { vendor: VendorVm }) => {
    const [sortInfo, setSortInfo] = React.useState({ sortProp: '', sortAsc: false } as SortInfo);
    const [message, setMessage] = React.useState('');

    const _applySort = (projects: VendorProject[]) => {
        const { sortAsc, sortProp } = sortInfo;

        if (sortProp) {
            projects = projects.sort((a, b) => {
                const aVal = a[sortProp], bVal = b[sortProp]
                if (bVal === undefined) return -1;
                if (aVal === bVal) return 0;
                if (sortAsc) return aVal > bVal ? 1 : -1;
                else return aVal > bVal ? -1 : 1;
            })
        }

        return projects;
    }

    const _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
        const prop = e.currentTarget.getAttribute('data-for') || undefined;
        setSortInfo({ sortProp: prop || '', sortAsc: !sortInfo.sortAsc })
    }

    const _header = () => {
        const { sortProp, sortAsc } = sortInfo;
        const sortIconClass = sortAsc ? 'sort-icon fas fa-arrow-up' : 'sort-icon fas fa-arrow-down'
        const sortIcon = sortProp ? <span className={sortIconClass}></span> : []

        return (
            <div className='grid-header team-grid-header my-col-20'>
                <div className='col-sm-12'><label data-for='projectName' className=''>Project {sortProp === 'projectName' && sortIcon}</label></div>                
                <div className='right-button'></div>
            </div>
        )
    }

    const _lines = (props.vendor && props.vendor.vendorProjects) ?
        _applySort(props.vendor.vendorProjects).map(x => (
            <div key={Math.random()} className='team-grid-line my-col-20'>
                <div className='col-sm-12'>{x.projectName}</div>                
                <div className='right-button' style={{ top: '8px' }}></div>
            </div>
        ))    
        : []

    return (
        <div className='grid project-team-grid'>
            {_header()}
            {_lines}
            <MessageBox message={message} clearMessage={() => setMessage('')} />            
        </div>
    )
}