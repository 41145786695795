import * as React from "react";
import { MyAuthElement } from "../auth/Authorization";
import { KnownRoles } from "../auth/auth";
import { ListItem, Activity } from "../interfaces/interfaces";
import { Loader } from "../components/Loader";
import { MessageBox } from "../components/MessageBox";
import { ActivityStream } from "./ActivityStreamComponent";
import KPI from "../charts/KPI";
import { DateRange, extendMoment } from "moment-range";
import * as Moment from "moment";
import {
    SVDateRangePicker,
    CustomRangeOpt,
} from "../components/SVDateRangePicker";
import { MyActivityLoader } from "../loaders/MyActivityLoader";
const moment = extendMoment(Moment);

interface State {
    dateRange: DateRange;
    brands?: ListItem[];
    brandId?: number;
    clients?: ListItem[];
    itemsUpdated?: string;
    filesUploaded?: string;
    activities: Activity[];
    loading?: boolean;
    vendorOnly: boolean;
    message?: string;
}

export default class DashboardComponent extends React.Component<any, State> {
    csgComponent = MyAuthElement([KnownRoles.Admin, KnownRoles.CSGPM]);

    constructor(props) {
        super(props);
        this.state = {
            dateRange: new DateRange(
                moment(new Date()).add(-7, "days"),
                moment(new Date())
            ),
            activities: [],
            vendorOnly: false,
        };
    }

    componentDidMount() {
        //this._getBrands()
        this._getDateStats(this.state.dateRange);
    }

    _getBrands = () => {
        fetch(`api/Brand/Get`)
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({ brands: data });
                } else this.setState({ message: data.message, loading: false });
            });
    };

    _getDateStats = (dateRange: DateRange, vendorOnly?: boolean) => {
        this.setState({ loading: true });

        vendorOnly = vendorOnly !== undefined ? vendorOnly : this.state.vendorOnly;
        const startDate = dateRange ? dateRange.start.toISOString() : "01/01/1971",
            endDate = dateRange ? dateRange.end.toISOString() : "01/01/2199";

        fetch(
            `api/Dashboard/Get?start=${startDate}&end=${endDate}&vendorOnly=${vendorOnly}&projectId=${this.props.projectId}`
        )
            .then((res) => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        itemsUpdated: data.itemsUpdated,
                        filesUploaded: data.filesUploaded,
                        activities: data.activities,
                        dateRange: dateRange,
                        loading: false,
                        vendorOnly:
                            vendorOnly !== undefined ? vendorOnly : this.state.vendorOnly,
                    });
                } else this.setState({ message: data.message, loading: false });
            });
    };

    _handleDateChange = (range) => {
        this._getDateStats(range);
    };

    _toggleVendorOnly = () =>
        this._getDateStats(this.state.dateRange, !this.state.vendorOnly);

    _clearMessage = () => this.setState({ message: undefined });

    render() {
        const { activities, itemsUpdated } = this.state;
        const rfiSubCount = activities.filter(
            (x) => x.type === 5 && x.action.indexOf("Submitted") !== -1
        ).length,
            rfiAcceptCount = activities.filter(
                (x) => x.type === 5 && x.action.indexOf("Accepted") !== -1
            ).length,
            submittalCount = activities.filter(
                (x) => x.type === 6 && x.action.indexOf("Created") !== -1
            ).length,
            submittalAcceptAcount = activities.filter(
                (x) => x.type === 6 && x.action.indexOf("Accepted") !== -1
            ).length,
            dfrCount = activities.filter((x) => x.type === 4).length;

        if (this.state.loading) return <MyActivityLoader />;

        return (
            <div>
                <MessageBox
                    message={this.state.message}
                    clearMessage={this._clearMessage}
                />
                <div className="dashboard-body">
                    <div style={{ display: "flex" }}>
                        <div className="my-col-5 mobile-full-width no-pad">
                            {this.renderFilters()}
                        </div>
                        <div
                            className="my-col-15 mobile-flex-2 no-pad dashboard-kpis"
                            style={{ display: "flex" }}
                        >
                            {itemsUpdated !== undefined && (
                                <div className="my-col-3 mobile-hide">
                                    <KPI label="Field Reports" value={dfrCount.toString()} />
                                </div>
                            )}
                            {itemsUpdated !== undefined && (
                                <div className="my-col-3 mobile-hide">
                                    <KPI
                                        label="RFIs"
                                        title="RFIs Created / Accepted"
                                        value={
                                            rfiSubCount.toString() + " / " + rfiAcceptCount.toString()
                                        }
                                    />
                                </div>
                            )}
                            {itemsUpdated !== undefined && (
                                <div className="my-col-3 mobile-hide">
                                    <KPI
                                        label="Submittals"
                                        title="Submittals Created / Accepted"
                                        value={
                                            submittalCount.toString() +
                                            " / " +
                                            submittalAcceptAcount.toString()
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div>
                        <ActivityStream activities={this.state.activities} />
                    </div>
                </div>
            </div>
        );
    }

    renderFilters() {
        const brandOpts = this.state.brands
            ? this.state.brands.map((x) => <option value={x.id}>{x.value}</option>)
            : [];
        const clientOpts = this.state.clients
            ? this.state.clients.map((x) => <option value={x.id}>{x.value}</option>)
            : [];

        const rangeOpts: [CustomRangeOpt, CustomRangeOpt, CustomRangeOpt] = [
            {
                rangeOpt: "last7",
                label: "Last 7 Days",
                range: new DateRange(moment(new Date()).add(-7, "days"), new Date()),
            } as CustomRangeOpt,
            {
                rangeOpt: "last30",
                label: "Last 30 Days",
                range: new DateRange(moment(new Date()).add(-30, "days"), new Date()),
            } as CustomRangeOpt,
            {
                rangeOpt: "lastMonth",
                label: "Last Month",
                range: moment(moment(new Date()).add(-1, "months")).range("month"),
            } as CustomRangeOpt,
        ];

        return (
            <div className="dashboard-filters">
                <div>
                    <label>Date Range</label>
                    <SVDateRangePicker
                        onChange={this._handleDateChange}
                        rangeOpts={rangeOpts}
                        defaultRange={this.state.dateRange}
                    />
                </div>
                <br />
                <div>
                    <label>Vendor Only</label>
                    <input
                        type="checkbox"
                        style={{ marginLeft: "10px" }}
                        defaultChecked={this.state.vendorOnly}
                        onChange={this._toggleVendorOnly}
                    />
                </div>
            </div>
        );
    }
}
