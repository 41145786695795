import * as React from 'react';

export default () => {

    return (
        <div id="loginForm">
            <h2>Account Confirmed</h2>
            <hr/>
            <div>
                <p>
                    Thank you for confirming your email. Please <a href='/'>click here to log in</a>.
                </p>
            </div>
        </div>
    )
}