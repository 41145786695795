import * as React from 'react';
import cx from 'classnames'
import { MessageBox } from '../../components/MessageBox';

interface Props {

}

interface State {
    projectRoles: ProjectRole[]
    editedId?: number,
    message?: string
}

export interface ProjectRole {
    id: number,
    roleName: string,
    allowMultiple: boolean
}

export default class ProjectRolesForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            projectRoles: []
        }
    }

    componentWillMount() {
        this._getRoles()
    }

    _getRoles = () => {
        fetch(`api/GlobalConfig/ProjectRoles`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({ projectRoles: data })
            })
    }

    private _addRole = () => {
        const input = document.getElementById('newRoleName') as HTMLInputElement;
        const allowMultiple = document.getElementById('role_allowMultiple') as HTMLInputElement
        if (input && allowMultiple) {
            fetch(`api/GlobalConfig/AddRole?name=${input.value}&allowMultiple=${allowMultiple.checked}`,
                { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            projectRoles: data,
                            editedId: undefined
                        })
                        input.value = '';
                        allowMultiple.checked = false;
                    }
                    else this.setState({ message: data.message })
                })
        }
    }

    private _editRole = (id: number, newVal: string, allowMultiple: boolean) => {
        const { projectRoles } = this.state;
        const editing = projectRoles && projectRoles.find(kd => kd.id == id) || {} as ProjectRole;
        if (editing.roleName == newVal) {
            this.setState({ editedId: undefined })
        }
        else if (projectRoles && projectRoles.findIndex(kd => kd.id !== id && kd.roleName.toUpperCase() == newVal.toUpperCase()) !== -1) {
            alert('Error. A role by that name already exists');
        }
        else {
            fetch(`api/GlobalConfig/EditRole?id=${id}&name=${newVal}&allowMultiple=${allowMultiple}`, { method: 'PUT' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            projectRoles: data,
                            editedId: undefined
                        })
                    }
                    else alert('Error: ' + data.message)
                })
        }
    }

    private _saveEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const newVal = (parentDiv.querySelector('input.form-control') as HTMLInputElement).value
        const allowMultiple = (parentDiv.querySelector('input[type="checkbox"]') as HTMLInputElement).checked

        if (newVal && this.state.editedId)
            this._editRole(this.state.editedId, newVal, allowMultiple)
    }

    private _toggleEditRole = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const roleId = parentDiv.getAttribute('data-id');

        if (roleId) {
            this.setState({ editedId: parseInt(roleId) })
        }
    }

    _removeRole = (id: number | string) => {
        fetch(`api/GlobalConfig/DeleteRole?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ projectRoles: data })
                else this.setState({ message: data.message })
            })
    }
   
    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { editedId, projectRoles } = this.state
        const roles = projectRoles ?
            projectRoles.map(x => {
                const editing = x.id == editedId
                return (
                    <div key={`${x.roleName}_${x.id}`} className={cx('config-item-line', editing && 'editing')} data-id={x.id}>
                        {editing ? <input className='form-control' defaultValue={x.roleName} /> : <span>{x.roleName}</span>}
                        {editing ? <input style={{ width: '15px', marginLeft: '10px' }} type='checkbox' defaultChecked={x.allowMultiple} title='Allow multiple users in this role?' /> : []}
                        {!editing && <span className={cx('fas fa-times status-remove', editing && 'editing')}
                            onClick={() => this._removeRole(x.id)}
                        ></span>
                        }
                        {editing ?
                            <span className='fas fa-check status-edit editing'
                                title='Save change'
                                onClick={this._saveEdit}
                            ></span> :
                            <span className='fas fa-edit status-edit'
                                title='Edit status'
                                onClick={this._toggleEditRole}
                            ></span>
                        }
                    </div>
                )
            }) : []

        return (
            <div className='config-form config-flex max-350'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                <div className='config-new-item-form'>
                    <div className="form-header">
                        <h4 className="modal-title">New Role</h4>
                    </div>
                    <div className="form-body">
                        <input id='newRoleName' className="form-control" />
                        <br/>
                        <label>Allow multiple users in this role?</label><input type='checkbox' id='role_allowMultiple' />
                        <div className='form-footer'>
                            <span className="btn btn-sm btn-blue" onClick={this._addRole}>Add</span>
                        </div>
                    </div>
                </div>
                <div className='config-item-list'>
                    <div className="form-header" style={{ marginBottom: '15px' }}>
                        <h4 className="modal-title">Existing Roles</h4>
                    </div>
                    <div className=''>
                        {roles}
                    </div>
                </div>
            </div>
        )
    }
}

