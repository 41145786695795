import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { ProjectShortcut } from 'src/store/global';

interface SidebarMenuButtons {
  item: string | undefined;
  recentProjects: ProjectShortcut[];
  favoriteProjects: ProjectShortcut[];
}

const MenuButton = styled(Button)({
  backgroundColor: '#353536',
  padding: '8px',
  borderRadius: '.5rem',
  width: '100%',
  '&:hover': {
    color: '#ff9e18',
    backgroundColor: '#353536',
  },
  '&:active': {
    backgroundColor: '#353536 !important',
  },
  '&:focus': {
    backgroundColor: '#353536',
  },
  'a.active &': {
    backgroundColor: '#009ade',
  },
  'a.active &:hover': {
    color: '#353536',
  },
});

const RecentProjectDiv = styled.div({
  '& hr': {
    border: 0,
    borderBottom: '1px solid #ffffff !important',
    marginTop: '1rem !important',
  },
  '& h6': {
    marginTop: '36px',
  },
  '& a': {
    color: '#ffffff',
    textDecoration: 'underline',
    marginBottom: '10px',
    fontSize: '14px',
  },
  '& a:hover': {
    color: '#ff9e18 !important',
  },
  '& a.active': {
    color: '#ffffff',
    fontWeight: 'bold',
  },
  '& > div': {
    maxHeight: '250px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const SidebarMenuButtons = ({
  item,
  recentProjects,
  favoriteProjects,
}: SidebarMenuButtons) => {
  const menu = useMemo(() => {
    switch (item) {
      case 'dashboard': {
        return (
          <>
            <h6>Dashboard</h6>
            <hr />
            <NavLink to={'/my-work'} activeClassName="active" title="My Work">
              <MenuButton id={'mywork'}>My Work</MenuButton>
            </NavLink>
            <NavLink
              to={'/my-milestones'}
              activeClassName="active"
              title="My Milestones"
            >
              <MenuButton id={'mymilestones'}>My Milestones</MenuButton>
            </NavLink>
            <NavLink
              to={'/my-checklists'}
              activeClassName="active"
              title="My Checklists"
            >
              <MenuButton id={'mychecklists'}>My Checklists</MenuButton>
            </NavLink>
            <NavLink to={'/my-tasks'} activeClassName="active" title="My Tasks">
              <MenuButton id={'mytasks'}>My Tasks</MenuButton>
            </NavLink>
            <NavLink to={'/activity'} activeClassName="active" title="Activity">
              <MenuButton id={'activity'}>Activity</MenuButton>
            </NavLink>
            <NavLink
              to={'/my-graphs'}
              activeClassName="active"
              title="My Graphs"
            >
              <MenuButton id={'mygraphs'}>My Graphs</MenuButton>
            </NavLink>
          </>
        );
      }

      case 'projects': {
        return (
          <>
            <h6>Projects</h6>
            <hr />
            <NavLink
              to={'/projectlist'}
              activeClassName="active"
              title="Project List"
            >
              <MenuButton id={'projectlist'}>Project List</MenuButton>
            </NavLink>
            {favoriteProjects.length > 0 && (
              <RecentProjectDiv>
                <h6>Favorite Projects</h6>
                <hr />
                <div className='custom-scrollbar'>
                  {favoriteProjects.map((x) => {
                    let text = `${x.projectNumber} - ${x.projectName}`;
                    const appendText = text.length > 32 ? '...' : '';
                    text = text.substring(0, Math.min(text.length, 33));
                    text += appendText;
                    return (
                      <NavLink
                        key={x.projectId}
                        to={`/project/${x.projectId}`}
                        activeClassName="active"
                        title={x.projectNumber + ' - ' + x.projectName}
                      >
                        &bull; {text}
                      </NavLink>
                    );
                  })}
                </div>
              </RecentProjectDiv>
            )}
            {recentProjects.length > 0 && (
              <RecentProjectDiv>
                <h6>Recent Projects</h6>
                <hr />
                <div className='custom-scrollbar'>
                  {recentProjects.map((x) => {
                    let text = `${x.projectNumber} - ${x.projectName}`;
                    const appendText = text.length > 32 ? '...' : '';
                    text = text.substring(0, Math.min(text.length, 33));
                    text += appendText;
                    return (
                      <NavLink
                        key={x.projectId}
                        to={`/project/${x.projectId}`}
                        activeClassName="active"
                        title={x.projectNumber + ' - ' + x.projectName}
                      >
                        &bull; {text}
                      </NavLink>
                    );
                  })}
                </div>
              </RecentProjectDiv>
            )}
          </>
        );
      }
      case 'subcontractors': {
        return (
          <>
            <h6>Subcontractors</h6>
            <hr />
            <NavLink
              to={'/subs'}
              activeClassName="active"
              title="Subcontractors"
            >
              <MenuButton id={'subs-home'}>Home</MenuButton>
            </NavLink>
          </>
        );
      }
      case 'reporting': {
        return (
          <>
            <h6>Reporting</h6>
            <hr />
            <NavLink
              to={'/reporting'}
              activeClassName="active"
              title="Reporting"
            >
              <MenuButton id={'reporting-home'}>Home</MenuButton>
            </NavLink>
          </>
        );
      }
      case 'config': {
        return (
          <>
            <h6>Configuration</h6>
            <hr />
            <NavLink
              to={'/config-client'}
              activeClassName="active"
              title="Client Config"
            >
              <MenuButton>Client Config</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-custom-field'}
              activeClassName="active"
              title="Custom Fields"
            >
              <MenuButton>Custom Fields</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-checklist'}
              activeClassName="active"
              title="Checklists"
            >
              <MenuButton>Checklists</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-kpi'}
              activeClassName="active"
              title="Safety KPIs"
            >
              <MenuButton>Safety KPIs</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-quality-report'}
              activeClassName="active"
              title="Quality Reports"
            >
              <MenuButton>Quality Reports</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-punchlist'}
              activeClassName="active"
              title="Punchlists"
            >
              <MenuButton>Punchlists</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-global'}
              activeClassName="active"
              title="Global Config"
            >
              <MenuButton>Global Config</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-region'}
              activeClassName="active"
              title="Regions"
            >
              <MenuButton>Regions</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-import'}
              activeClassName="active"
              title="Imports"
            >
              <MenuButton>Imports</MenuButton>
            </NavLink>
            <NavLink
              to={'/config-tracker'}
              activeClassName="active"
              title="Tracker"
            >
              <MenuButton>Tracker</MenuButton>
            </NavLink>
          </>
        );
      }

      case 'explorer': {
        return (
          <>
            <h6>SiteVue Explorer</h6>
            <hr />
            <NavLink
              to={'/explorer'}
              activeClassName="active"
              title="SiteVue Explorer"
            >
              <MenuButton id={'explorer-home'}>Home</MenuButton>
            </NavLink>
          </>
        );
      }
      case 'fileshare': {
        return (
          <>
            <h6>Fileshare</h6>
            <hr />
            <NavLink
              to={'/fileshare'}
              activeClassName="active"
              title="Fileshare"
            >
              <MenuButton id={'fileshare-home'}>Home</MenuButton>
            </NavLink>
          </>
        );
      }
      case 'admin': {
        return (
          <>
            <h6>Administration</h6>
            <hr />
            <NavLink
              to={'/admin'}
              activeClassName="active"
              title="Administration"
            >
              <MenuButton id={'admin-home'}>Home</MenuButton>
            </NavLink>
          </>
        );
      }
    }
  }, [item, recentProjects]);
  return <>{menu}</>;
};
