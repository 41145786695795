import {
  HubConnectionState,
  HubConnectionBuilder,
  HubConnection,
} from "@microsoft/signalr";

interface ActionEvent {
  action: string;
  event: (response: any) => void;
}

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    console.log("SignalR connection established");
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.error("SignalR Connection Error: ", err);
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection = (
  connectionHub: string,
  actionEventMap: ActionEvent[]
) => {
  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection = new HubConnectionBuilder()
    .withUrl(connectionHub)
    .withAutomaticReconnect()
    .build();

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log(
      "Connection closed due to error. Try refreshing this page to restart the connection",
      error
    );
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log("Connection lost due to error. Reconnecting.", error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log(
      "Connection reestablished. Connected with connectionId",
      connectionId
    );
  });

  startSignalRConnection(connection);

  actionEventMap.map((item) => {
    connection.on(item.action, (response) => {
      item.event(response);
    });
  });
  return connection;
};
