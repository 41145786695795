import React from 'react';
import { PunchItemVm } from 'src/interfaces/interfaces';
import { ConnectedPunchItem } from './ConnectedPunchItem';

export const PunchlistFocusSection: React.FC<{
  items: PunchItemVm[];
}> = (props: { items: PunchItemVm[] }) => {
  const { items } = props;

  if (items.length === 0) {
    return <div className="mt-3">
      <h5>Good news! There are no items here that require your attention.</h5>
    </div>
  }

  return (
    <div style={{ maxHeight: '70vh' }} className="mt-3">
      {items.map((item) => {
        return (
          <ConnectedPunchItem key={item.id} item={item}></ConnectedPunchItem>
        );
      })}
    </div>
  );
};
