import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../components/Modal';
import { MetaObjectType } from "../interfaces/enums";
import { actionCreators as graphActionCreators } from '../store/graphs'
import { ApplicationState } from '../store/index';

interface Props {
    graphId?: number
    close: () => void
}

export const AddGraphFilterModal = (props: Props) => {

    const [dataSource, setDataSource] = React.useState(0 as MetaObjectType)
    const metaStore = useSelector((s: ApplicationState) => s.meta);
    const graphStore = useSelector((s: ApplicationState) => s.graphs);

    const dispatch = useDispatch();

    const type = metaStore.allObjects ? metaStore.allObjects.find(x => x.type === dataSource) : undefined;
    const fieldOptions = type ?
        type.fields.map(x => <option key={x.propName} className='form-control' value={x.propName}>{x.displayName}</option>)
        : [];

    const _changeSource = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDataSource(parseInt(e.currentTarget.value))
    }

    const _add = () => {
        const field = (document.getElementById('new-filter-dropdown') as HTMLSelectElement).value,
            global = false;// dataSource === MetaObjectType.Project ? (document.getElementById('new-filter-global') as HTMLInputElement).checked : false;

        dispatch(graphActionCreators.addFilter(field, dataSource, global, props.graphId));
        props.close();
    }

    React.useEffect(() => {
        if (props.graphId) {
            const graph = graphStore.graphParts.find(x => x.id === props.graphId);
            // @ts-ignore
            setDataSource(graph.dataType);
        }
    })

    return (
        <Modal dialogStyle={{ maxWidth: '500px' }}>
            <div className='modal-header'>
                <h4>Add Filter</h4>
            </div>
            <div className='modal-body'>
                {!props.graphId && <div className='form-group'>
                    <label><b>Data Source</b></label>
                    <select id='add-graph-data-type' className='form-control' onChange={_changeSource}>
                        <option value='0'></option>
                        {metaStore.allObjects.map(x => <option key={x.type} className='form-control' value={x.type}>{x.name}</option>)}
                    </select>
                </div>
                }
                <div className='form-group'>
                    <label><b>Field</b></label>
                    <select id='new-filter-dropdown' className='form-control'>
                        <option value=''></option>
                        {fieldOptions}
                    </select>
                </div>
                {/*{dataSource === MetaObjectType.Project &&*/}
                {/*    <div className='form-group' title="Will attempt to use the filter to apply to all projects, and filter out any records which aren't linked to matching projects">*/}
                {/*        <label><b>Apply Filter Globally</b></label>*/}
                {/*        <input type='checkbox' id='new-filter-global' />*/}
                {/*    </div>*/}
                {/*}*/}
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-blue' onClick={_add}>Add</button>
                <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
            </div>
        </Modal>
    )
}