import { isAnyOf } from "@reduxjs/toolkit";
import React from "react";
import { AppStartListening } from "../listenerMiddleware";
import { KeyVaultActions } from "./keyvault.reducer";
import { getPartnerRegKeyFromVault } from '../../services/keyvault/index';

export const addKeyVaultListeners = (
	startListening: AppStartListening
) => {
	const listeners = [
		startListening({
			actionCreator: KeyVaultActions.getPartnerRegistrationKey,
			effect: async (action, listenApi) => {
				const key = await getPartnerRegKeyFromVault();
				
				if (key) {					
					listenApi.dispatch(
						KeyVaultActions.setPartnerRegistrationKey({ key: key })
					);
				}
			},
		}),		
	];
	return () => {
		listeners.forEach((unsubscribe) => unsubscribe());
	};
};
