import * as React from 'react';
import * as cx from 'classnames';
import { MessageBox } from '../../components/MessageBox';
import { WorkRegion } from '../../interfaces/interfaces';
import Modal from '../../components/Modal';
import { RegionEditorMap } from '../../maps/RegionEditorMap';
import { ClickEventValue } from 'google-map-react';
import { RoundMarker } from '../../maps/RoundMarker';
import { helpers } from '../../maps/mapHelpers';


export const WorkRegions = () => {

    const [regions, setRegions] = React.useState([] as WorkRegion[])
    const [message, setMessage] = React.useState(undefined as string | undefined)
    const [editingId, setEditingId] = React.useState(null as number | null);
    const [removingId, setRemovingId] = React.useState(null as number | null);

    const getRegions = () => {
        fetch(`api/globalconfig/workregions`)
            .then(res => res.json())
            .then(data => {
                setRegions(data);
            });
    };

    const _addRegion = () => {
        const input = document.getElementById('newRegionName') as HTMLInputElement, name = input.value;
        fetch(`api/globalconfig/workregion?name=${name}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    getRegions();
                    input.value = '';
                }
                else setMessage(data.Message);
            });
    }

    const _addGeoPoint = (e: ClickEventValue) => {
        fetch(`api/globalconfig/regionPoint?regionId=${editingId}&lat=${e.lat}&lng=${e.lng}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    getRegions();
                }
                else setMessage(data.Message);
            });
    }

    const _moveGeoPoint = (key, props, mouse) => {
        const updated = JSON.parse(JSON.stringify(regions)).map((x: WorkRegion) => {
            /*if (x.id === editingId) {*/
                x.geoPoints = x.geoPoints.map(p => {
                    if (p.id == key) {
                        p.latitude = mouse.lat;
                        p.longitude = mouse.lng;
                    }
                    return p;
                })
            //}
            return x;
        })

        //console.log(`${mouse.lat}, ${mouse.lng}`);        

        setRegions(updated);
    }

    const _saveMoveGeoPoint = (key, props, mouse) => {
        fetch(`api/globalconfig/moveregionPoint?pointId=${key}&lat=${mouse.lat}&lng=${mouse.lng}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    getRegions();
                }
                else setMessage(data.Message);
            });
    }

    const _removeGeoPoint = (id) => {
        fetch(`api/globalconfig/regionPoint?pointId=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    getRegions();
                }
                else setMessage(data.Message);
            });
    }

    const _saveEdit = () => {
        const newName = (document.getElementById('editRegionName') as HTMLInputElement).value
        fetch(`api/globalconfig/workregion?id=${editingId}&newName=${newName}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    getRegions();
                    setEditingId(null);
                }
                else setMessage(data.Message);
            });
    }

    const _removeRegion = () => {
        fetch(`api/globalconfig/workregion?id=${removingId}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setRegions(regions.slice().filter(x => x.id !== removingId));
                else setMessage(data.Message);
                setRemovingId(null);
            });
    }

    React.useEffect(() => {
        getRegions();
    }, [])

    const regionItems = regions && regions.length ?
        regions.map(x => {
            const editing = x.id == editingId
            return (
                <div key={`${x.name}_${x.id}`} className={cx('config-item-line', editing && 'editing')} data-id={x.id}>
                    {editing ? <input id='editRegionName' className='form-control' defaultValue={x.name} /> : <span>{x.name}</span>}
                    {!editing && <span className={cx('fas fa-times status-remove', editing && 'editing')}
                        onClick={() => setRemovingId(x.id)}
                    ></span>
                    }
                    {editing ?
                        <span className='fas fa-check status-edit editing'
                            title='Save change'
                            onClick={_saveEdit}
                        ></span> :
                        <span className='fas fa-edit status-edit'
                            title='Edit status'
                            onClick={() => setEditingId(x.id)}
                        ></span>
                    }
                </div>
            )
        }) : []

    const removalModal = removingId ?
        <Modal>
            <div className='modal-header'><h5>Remove Region</h5></div>
            <div className='modal-body'><p><b>Warning:</b> Deleting this region will remove all references to the region used by vendors, subcontractors and other records. Are you sure you want to proceed?</p></div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-danger' onClick={_removeRegion}>Delete</button>
                <button className='btn btn-sm btn-outline-secondary' onClick={() => setRemovingId(null)}>Cancel</button>
            </div>
        </Modal>
        : []

    const hoverContentStyle: any = { ...helpers.calcHoverContentStyles(4), fontSize: '15px' };

    return (

        <div style={{ display: 'flex' }}>
            <div className='config-form' style={{ width: '350px', height: '705px' }}>
                <MessageBox message={message} clearMessage={() => setMessage(undefined)} />
                {removalModal}
                <div className='my-col-20'>
                    <div className="form-header">
                        <h5 className="modal-title">New Region</h5>
                    </div>
                    <div className="form-body">
                        <input id='newRegionName' className="form-control" />
                        <span className="btn btn-sm btn-blue" onClick={_addRegion}>Add</span>
                        <div className='form-footer'>
                            
                        </div>
                    </div>
                </div>
                <div className='my-col-20'>
                    <div className="form-header" style={{ marginBottom: '15px' }}>
                        <h5 className="modal-title">Existing Regions</h5>
                    </div>
                    <div className=''>
                        {regionItems}
                    </div>
                    {/*<br /> <br />*/}
                    {/*<RoundMarker bgColor={helpers.calcColor(1, 0.35)}*/}
                    {/*    borderColor={helpers.calcColor(1, 0.8)}*/}
                    {/*    key={'test'}*/}
                    {/*    id={1}*/}
                    {/*    label=''*/}
                    {/*    lat={0.0}*/}
                    {/*    lng={0.0}*/}
                    {/*    $hover={true}*/}
                    {/*    hoverContent={<span className='fas fa-times map-marker-hover-content' onClick={() => { alert('X clicked') }} style={hoverContentStyle}></span>}*/}
                    {/*    onClick={(e) => { e.stopPropagation(); alert('Marker clicked') }}*/}
                    {/*    zoom={4} />*/}
                </div>
            </div>
            <div style={{ height: '600px', width: 'calc(100% - 360px)' }}>
                <RegionEditorMap
                    regions={regions}
                    handleDrag={_moveGeoPoint}
                    handleDragEnd={_saveMoveGeoPoint}
                    handleMapClick={editingId ? _addGeoPoint : undefined}
                    handleRemovePoint={_removeGeoPoint}
                    activeRegionId={editingId || undefined} />
            </div>
        </div>
    )
}