import React, { useEffect, useRef, useState } from "react";
import {
  ListItem,
  TransferRecordType,
  TransferSingleRecordPostModel,
} from "src/interfaces/interfaces";
import { transferRecord } from "src/services/records";
import { getActiveUsers } from "src/services/users";
import FilterableSelect from "./FilterableSelect";
import { MessageBox } from "./MessageBox";
import Modal from "./Modal";

export interface TransferRecordModalProps {
  type: TransferRecordType;
  onClose: () => void;
  fromUserId: string;
  recordId: number;
  onTransfer: (successMessage: string) => void;
  users: ListItem[];
}

export const TransferRecordModal: React.FC<TransferRecordModalProps> = ({
  type,
  onClose,
  fromUserId,
  recordId,
  onTransfer,
  users,
}) => {
  const [message, setMessage] = useState("");
  const toUserRef = useRef<FilterableSelect>(null);
  const reasonRef = useRef<HTMLTextAreaElement>(null);

  const handleOnTransfer = async () => {
    if (toUserRef.current?.state?.hiddenVal && reasonRef.current?.value) {
      const request: TransferSingleRecordPostModel = {
        fromUserId: fromUserId,
        toUserId: toUserRef.current.state.hiddenVal.toString(),
        recordType: type,
        recordId: recordId,
        reason: reasonRef.current.value,
      };
      const recordUpdated = await transferRecord(request);
      if (recordUpdated) {
        onTransfer(`Sucessfully transferred record.`);
        onClose && onClose();
      } else {
        setMessage(
          "Error: There was an issue transferring this record. Please try again later."
        );
      }
    } else {
      setMessage("Error: Please enter a valid User and Reason");
    }
  };

  return (
    <Modal dialogStyle={{ minWidth: "400px" }} style={{ display: "flex" }}>
      <MessageBox message={message} clearMessage={() => setMessage("")} />
      <div className="modal-header">
        <h4>Transfer Record</h4>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <span>To User</span>
          <FilterableSelect ref={toUserRef} items={users} id="userTo" />
        </div>
        <div className="form-group">
          <span>Reason</span>
          <textarea
            maxLength={80}
            ref={reasonRef}
            className="form-control"
          ></textarea>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-green"
          onClick={() => handleOnTransfer()}
        >
          Transfer
        </button>
        <span className="btn btn-sm btn-outline-secondary" onClick={onClose}>
          Close
        </span>
      </div>
    </Modal>
  );
};
