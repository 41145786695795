import * as React from 'react';
import { ListItem } from '../../interfaces/interfaces';
import cx from 'classnames'
import { MessageBox } from '../../components/MessageBox';

interface Props {
    clientId?: number | string
}

interface State {
    keyDocs: ListItem[]
    editedKeyDocId?: number
    message?: string
}

export default class KeyDocForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            keyDocs: []
        }
    }

    componentDidMount() {
        this._getKeyDocs(this.props.clientId)
    }

    //componentWillReceiveProps(nextProps: Props) {
    //    if (this.props.clientId != nextProps.clientId)
    //        this._getKeyDocs(nextProps.clientId)
    //}

    private _getKeyDocs = (id?: number | string) => {
        fetch(`api/Client/KeyDocs?clientId=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    keyDocs: data
                })
            });
    }

    private _addKeyDoc = (val: string) => {
        if (this.state.keyDocs && this.state.keyDocs.findIndex(kd => kd.value.toUpperCase() == val.toUpperCase()) !== -1) {
            alert('Oops! A Key Document by the name already exists');
        }
        else {
            fetch(`api/Client/KeyDoc?name=${val}&clientId=${this.props.clientId}`, { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            keyDocs: data
                        })
                    }
                    else this.setState({ message: data.message })
                })
        }
    }

    private _editKeyDoc = (id: number, newVal: string) => {
        const editing = this.state.keyDocs && this.state.keyDocs.find(kd => kd.id == id) || {} as ListItem;
        if (editing.value == newVal) {
            this.setState({
                editedKeyDocId: undefined
            })
        }
        if (this.state.keyDocs && this.state.keyDocs.findIndex(kd => kd.id !== id && kd.value.toUpperCase() == newVal.toUpperCase()) !== -1) {
            alert('Oops! A Key Document by the name already exists');
        }
        else {
            fetch(`api/Client/KeyDoc?id=${id}&newVal=${newVal}&clientId=${this.props.clientId}`, { method: 'PUT' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            keyDocs: data,
                            editedKeyDocId: undefined
                        })
                    }
                    else alert('Error: ' + data.message)
                })
        }
    }

    private _toggleEditKeyDoc = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const keyDocId = parentDiv.getAttribute('data-id');

        if (keyDocId) {
            this.setState({
                editedKeyDocId: parseInt(keyDocId)
            })
        }
    }

    private _save = () => {
        const input = document.getElementById('newKeyDoc') as HTMLInputElement;
        if (input) {
            this._addKeyDoc(input.value);
            (document.getElementById('newKeyDoc') as HTMLInputElement).value = '';
        }
    }

    private _saveEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const newVal = (parentDiv.querySelector('input.form-control') as HTMLInputElement).value

        if (newVal && this.state.editedKeyDocId)
            this._editKeyDoc(this.state.editedKeyDocId, newVal)
    }

    private _removeKeyDoc = (id: number | string) => {                      
        fetch(`api/Client/KeyDoc?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    this.setState({
                        keyDocs: this.state.keyDocs.filter(s => s.id != id)
                    })
                }
                else this.setState({ message: data.message })
            })            
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { editedKeyDocId, keyDocs } = this.state
        const keyDocCards = keyDocs ?
            keyDocs.map(x => {
                const editing = x.id == editedKeyDocId
                return (
                    <div key={`${x.value}_${x.id}`} className={cx('config-item-line', editing && 'editing')} data-id={x.id}>
                        {editing ? <input className='form-control' defaultValue={x.value} /> : <span>{x.value}</span>}
                        {!editing && <span className={cx('fas fa-times status-remove', editing && 'editing')}
                            onClick={() => this._removeKeyDoc(x.id)}
                        ></span>}
                        {editing ?
                            <span className='fas fa-check status-edit editing'
                                title='Save change'
                                onClick={this._saveEdit}
                            ></span> :
                            <span className='fas fa-edit status-edit'
                                title='Edit status'
                                onClick={this._toggleEditKeyDoc}
                            ></span>
                        }
                    </div>
                )
            }) : []

        return (
            <div className='project-status-component config-form config-flex max-350'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                <div className='config-new-item-form'>
                    <div className="form-header">
                        <h5 className="modal-title">New Key Doc Name</h5>
                    </div>
                    <div className="form-body">
                        <input id='newKeyDoc' className="form-control" />
                    </div>
                    <div className='form-footer'>
                        <span className="btn btn-sm btn-blue" onClick={this._save}>Add</span>
                    </div>
                </div>
                <div className='config-item-list'>
                    <div className="form-header">
                        <h5 className="modal-title">Existing Key Documents</h5>
                    </div>
                    <div className="">
                        {keyDocCards}
                    </div>
                </div>
            </div>
        )
    }
}