import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { distinct } from '../../helpers/formulas';
import { PunchlistState } from './punchlist.reducer';

const punchlistState = (state: ApplicationState): PunchlistState =>
  state.punchlist;

export const punchItemsSelector = createSelector(
  punchlistState,
  (state) => state.items
);

export const filteredPunchItemsSelector = createSelector(
  punchItemsSelector,
  (items) => {
    return items.filter(
      (item) => item.canReassignAndClose === true || item.canEdit === true
    );
  }
);

export const punchItemsLoadingSelector = createSelector(
  punchlistState,
  (state) => state.status
);

export const punchItemsProjectIdSelector = createSelector(
  punchlistState,
  (state) => state.projectId
);

export const punchItemsCategoriesSelect = createSelector(
  punchlistState,
  (state) => state.items.map((x) => x.category).filter(distinct)
);

export const addPunchItemRequestSelector = createSelector(
  punchlistState,
  (state) => state.addPunchItemRequest
);

export const addPunchItemRequestMessageSelector = createSelector(
  addPunchItemRequestSelector,
  (addPunchItemRequest) => addPunchItemRequest.message
);

export const addPunchItemRequestStatusSelector = createSelector(
  addPunchItemRequestSelector,
  (addPunchItemRequest) => addPunchItemRequest.status
);

export const punchItemCCedModalSelector = createSelector(
  punchlistState,
  (state) => state.ccedModalId
);

export const isItemUpdatingSelector = createSelector(
  punchlistState,
  (state) => state.isItemUpdating
);

export const templatesSelector = createSelector(
  punchlistState,
  (state) => state.templates
);

export const templateStatusSelector = createSelector(
  punchlistState,
  (state) => state.templateStatus
);
