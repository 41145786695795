import * as React from 'react';
import { AddReportForm } from './AddReportForm'

export const AddReportFormButtonWrapper = () => {

  const [showForm, setShowForm] = React.useState(false);

  return (
    <>
      <span className='btn btn-x-sm btn-blue fas fa-plus' onClick={() => setShowForm(true)}></span>
      {showForm && <AddReportForm close={() => setShowForm(false)} /> }
    </>
  )
}