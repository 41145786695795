import { isAnyOf } from '@reduxjs/toolkit';
import { AppStartListening } from '../listenerMiddleware';
import {
  MAX_WINDOW_COUNT,
  MoveEvent,
  WindowsActions,
  WindowsItem,
} from './window.reducer';
import { moveFileExplorerItem } from 'src/services/fileExplorer';

export const addWindowsListeners = (startListening: AppStartListening) => {
  const listeners = [
    startListening({
      predicate: (action, currentState, previousState) => {
        return (
          WindowsActions.createWindow.match(action) &&
          currentState.windows.windowCount < 2
        );
      },
      effect: async (action, listenApi) => {
        const windowsState = listenApi.getOriginalState().windows;
        const count = windowsState.windowCount;
        const lastId = Math.max(0, ...windowsState.windows.map((x) => x.id));
        if (count < MAX_WINDOW_COUNT) {
          const newWindowItem: WindowsItem = {
            id: lastId == 1 ? 0 : 1,
            item: action.payload.item,
            droppableId: action.payload.droppableId,
            title: action.payload.title,
          };
          listenApi.pause;
          listenApi.dispatch(WindowsActions.addWindow(newWindowItem));
        }
      },
    }),
    startListening({
      actionCreator: WindowsActions.clearMoveEvent,
      effect: async (action, listenApi) => {
        listenApi.dispatch(WindowsActions.setShouldWindowsReload(true));

        await listenApi.delay(500);

        listenApi.dispatch(WindowsActions.setShouldWindowsReload(false));
      },
    }),
  ];
  return () => {
    listeners.forEach((unsubscribe) => unsubscribe());
  };
};
