import * as React from 'react';
import * as cx from 'classnames';

import { Submittal, SubmittalStatus, SubmittalDocumentType, SubmittalResponse, SubmittalResponseType, SubmittalDocument } from '../interfaces/interfaces';
import { formatDateTime } from '../helpers/formatters';
import DocIcon from '../components/DocIcon';
import Modal from '../components/Modal';


interface Props { submittal: Submittal }

export const SubmittalResponseSection = (props: Props) => {

    const [showAll, setShowAll] = React.useState(true);
    const [selectedDetail, setSelectedDetail] = React.useState(undefined as SubmittalResponse | undefined);

    const { submittal } = props,
        { status, responses } = submittal

    if (status === SubmittalStatus.Draft || status === SubmittalStatus.Canceled
        || status === SubmittalStatus.SentToSub || !responses.length) {
        return <div></div>
    }

    //const lastResponse = responses.reduce((prev, current) => (new Date(prev.timeStamp) > new Date(current.timeStamp) ? prev : current));

    return (
        <div className='submittal-response-section'>
            {selectedDetail && <ResponseDetailModal response={selectedDetail} close={() => setSelectedDetail(undefined)} />}
            <div className='sv-grid'>
                <div className='sv-grid-header'>
                    <div className='my-col-1'></div>
                    <div className='my-col-1'>Revision</div>
                    {/*<div className='my-col-2'>Response</div>*/}
                    <div className='my-col-3'>Reviewer</div>
                    <div className='my-col-3'>Timestamp</div>
                    <div className='my-col-4'>Comments</div>
                    <div className='my-col-4'>Original Docs</div>
                    <div className='my-col-3'>Response Docs</div>
                    <div className='my-col-1'>
                        <span className={cx('fas', showAll ? 'fa-chevron-down' : 'fa-chevron-up')} title={showAll ? 'Collapse responses' : 'Expand responses'} onClick={() => setShowAll(!showAll)} style={{ float: 'right', marginTop: '8px' }}></span>
                    </div>
                </div>
                <div className={cx('response-list custom-scrollbar sv-grid-body', showAll && 'expanded')}>
                    {responses.map(x => <SubmittalResponseLine response={x} key={x.id} showDetail={() => setSelectedDetail(x)} />)}
                </div>
            </div>
        </div>
    )
}

const SubmittalResponseLine = (props: { response: SubmittalResponse, showDetail: () => void }) => {
    const { response, showDetail } = props;
    const timeStamp = formatDateTime(response.timeStamp),
        comments = response.comment.length ? response.comment : <i>No comment</i>,
        originalDocs = response.documents ? response.documents.filter(x => x.type === SubmittalDocumentType.SubmittalDocument).map(x => <SubmittalDocEle key={x.id} doc={x} />) : [],
        responseDocs = response.documents ? response.documents.filter(x => x.type === SubmittalDocumentType.ResponseDocument).map(x => <SubmittalDocEle key={x.id} doc={x} />) : [];

    return (
        <div className='submittal-response-line' onClick={showDetail}>
            <div className='my-col-1 centered'>{responseTypeIcon(response.type)}</div>
            <div className='my-col-1 centered'>{response.revisionNo}</div>
            {/*<div className='my-col-2'>{responseTypeToText(response.type)}</div>*/}
            <div className='my-col-3'>{response.userFullName}</div>
            <div className='my-col-3'>{timeStamp} UTC</div>
            <div className='my-col-4 truncate' title={response.comment.length ? response.comment : 'No comment'}>{comments}</div>
            <div className='my-col-4 custom-scrollbar' style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', /*borderRight: '1px solid gray'*/ }}>{originalDocs}</div>
            <div className='my-col-4 custom-scrollbar' style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', }}>{responseDocs}</div>
        </div>
    )
}

const SubmittalDocEle = (props: { doc: SubmittalDocument }) => {
    const { doc } = props;
    const fileName = doc.filePath.split('/')[(doc.filePath.split('/').length - 1)]
    return (
        <div className='submittal-response-doc-container' key={doc.id}>
            <DocIcon doc={doc} />
            <div className='truncate centered' title={fileName}>{fileName}</div>
        </div>
    )
}

const ResponseDetailModal = (props: { response: SubmittalResponse, close: () => void }) => {
    const { response, close } = props;
    const timeStamp = formatDateTime(response.timeStamp),
        comments = response.comment.length ? response.comment : <i>No comment</i>,
        originalDocs = response.documents ? response.documents.filter(x => x.type === SubmittalDocumentType.SubmittalDocument).map(x => <SubmittalDocEle key={x.id} doc={x} />) : [],
        responseDocs = response.documents ? response.documents.filter(x => x.type === SubmittalDocumentType.ResponseDocument).map(x => <SubmittalDocEle key={x.id} doc={x} />) : [];

    return <Modal>
        <div className='modal-body response-detail-modal'>
            <div className='form-group'>
                <label><b>Response Type</b></label>
                <div>{responseTypeToText(response.type)}</div>
            </div>
            <div className='form-group'>
                <label><b>Revision Number</b></label>
                <div>{response.revisionNo}</div>
            </div>
            <div className='form-group'>
                <label><b>Reviewer</b></label>
                <div>{response.userFullName}</div>
            </div>
            <div className='form-group'>
                <label><b>Timestamp</b></label>
                <div>{timeStamp} UTC</div>
            </div>
            <div className='form-group'>
                <label><b>Comments</b></label>
                <div>{comments}</div>
            </div>
            <div className='form-group'>
                <label style={{position: 'relative', top: '1.5rem'}}><b>Original Documents</b></label>
                <div className='custom-scrollbar' style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', }}>{originalDocs}</div>                
            </div>
            <div className='form-group'>
                <label style={{ position: 'relative', top: '1.5rem' }}><b>Response Documents</b></label>                
                <div className='custom-scrollbar' style={{ display: 'flex', overflowX: 'auto', overflowY: 'hidden', }}>{responseDocs}</div>
            </div>
        </div>
        <div className='modal-footer'>
            <div className='btn btn-sm btn-outline-secondary' onClick={close}>Close</div>
        </div>
    </Modal >
}

export const responseTypeToText = (type: SubmittalResponseType) => {
    switch (type) {
        case SubmittalResponseType.ApprovedWithExceptions: return 'Approved w/ Exceptions'
        case SubmittalResponseType.NoExceptions: return 'No Exceptions'
        case SubmittalResponseType.Rejected: return 'Rejected'
        case SubmittalResponseType.ReviewAndResubmit: return 'Review and Resubmit'
        case SubmittalResponseType.SubmitSpecifiedItem: return 'Submit Specified Item'
        default: return '';
    }
}

const responseTypeIcon = (type: SubmittalResponseType) => {
    switch (type) {
        case SubmittalResponseType.ApprovedWithExceptions: return <span className='fas fa-file-pen' title={responseTypeToText(type)}></span>
        case SubmittalResponseType.NoExceptions: return <span className='fas fa-check-to-slot' title={responseTypeToText(type)}></span>
        case SubmittalResponseType.Rejected: return <span className='fas fa-file-slash' title={responseTypeToText(type)}></span>
        case SubmittalResponseType.ReviewAndResubmit: return <span className='fas fa-arrows-rotate' title={responseTypeToText(type)}></span>
        case SubmittalResponseType.SubmitSpecifiedItem: return <span className='fas fa-file-import' title={responseTypeToText(type)}></span>
        default: return '';
    }
}
