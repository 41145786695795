//import { fetch, addTask } from 'domain-task';
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction } from './';
import { ReportLine } from '../interfaces/interfaces';

export interface Store {
    lines: ReportLine[],
    unfilteredLines: ReportLine[],
    lineType: number // 1 for milestones, 2 for items/materials
    isLoading: boolean,
    isSaving: boolean,
    message?: string
}

interface RequestLinesAction {
    type: 'REQUEST_LINES',
    lineType: number
}

interface ReceiveLinesAction {
    type: 'RECEIVE_LINES',
    lines: ReportLine[]
}

interface UpdateAction {
    type: 'UPDATE',
    lines: ReportLine[]
}

interface ClearAction {
    type: 'CLEAR',   
}

interface FilterAction {
    type: 'FILTER_LINES',
    lines: ReportLine[]
}

interface ErrorAction {
    type: 'ERROR'
    message: string
}

type KnownAction = RequestLinesAction | ReceiveLinesAction | UpdateAction
    | FilterAction | ErrorAction | ClearAction

export const actionCreators = {
    get: (lineType: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (getState().report.lineType == lineType && getState().report.lines.length) {
            //do something
        }
        else {
            const url = `api/Report/Redline?type=${lineType}`
            fetch(url)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        dispatch({ type: 'RECEIVE_LINES', lines: data })
                    }
                    else dispatch({ type: 'ERROR', message: data.message })
                })
            dispatch({ type: 'REQUEST_LINES', lineType: lineType })
        }
    },
    updateLine: (entryId: number, field: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const url = `api/Report/UpdateEntry?entryId=${entryId}&type=${getState().report.lineType}`
        fetch(url,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fieldName: field,
                    value: value
                })
            })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    //let lines = [...getState().report.lines]                   
                    //lines = lines.map(x => {
                    //    if (x.entryId == entryId) {
                    //        if (field.toUpperCase() == "DUEDATE") x.dueDate = value;
                    //        else if (field.toUpperCase() == "NOTES") x.notes = value;
                    //    }
                    //    return x;
                    //})                    
                    
                    dispatch({ type: 'UPDATE', lines: data })
                }
                else dispatch({ type: 'ERROR', message: data.message })
            })                       
    },
    filter: (prop: string, filterVal: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const filtered = getState().report.unfilteredLines.slice()
            .filterByStringProp(prop, filterVal)           
        dispatch({ type: 'FILTER_LINES', lines: filtered });
    },
    clear: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'CLEAR' })
    },
    clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'ERROR', message: '' })
    }
}

const unloadedState: Store = {
    lines: [],
    unfilteredLines: [],
    lineType: 0,
    isLoading: false,
    isSaving: false,
}

//@ts-ignore
export const reducer: Reducer<Store> = (state: Store, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'REQUEST_LINES':
            return { ...state, lineType: action.lineType, isLoading: true }
        case 'RECEIVE_LINES':
            return { ...state, lines: action.lines, unfilteredLines: action.lines, isLoading: false }
        case 'UPDATE':
            return { ...state, lines: action.lines, message: 'SAVED' }
        case 'FILTER_LINES':
            return { ...state, lines: action.lines }
        case 'ERROR':
            return { ...state, message: action.message }
        case 'CLEAR':
            return { ...state, lines: [], message: undefined }
        default:{
            const exhaustiveCheck: never = action
        }
    }
    return state || unloadedState
}