import React, { useMemo } from 'react';
import { FileExplorer } from './FileExplorer/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  canOpenWindowSelector,
  latestIdSelector,
} from 'src/store/window/window.selectors';
import { WindowsActions } from 'src/store/window/window.reducer';
import { FileExplorerType } from 'src/interfaces/enums';

interface ConnectedFileExplorerProps {
  startingDir: string;
  homeDir?: string;
  breadCrumbStartLevel: number;
  canSubscribeUsers: boolean;
  params: any;
  type?: FileExplorerType;
  isInModal?: boolean;
  droppableId?: string;
  draggableId?: string;
}

export const ConnectedFileExplorer = ({
  startingDir,
  homeDir,
  breadCrumbStartLevel,
  canSubscribeUsers,
  params,
  type,
  isInModal,
  droppableId,
  draggableId,
}: ConnectedFileExplorerProps) => {
  const canOpenFileExplorerWindow = useSelector(canOpenWindowSelector);
  const lastWindowId = useSelector(latestIdSelector);
  const dispatch = useDispatch();

  const onOpenWindow = React.useCallback(() => {
    dispatch(
      WindowsActions.createWindow({
        item: (
          <ConnectedFileExplorer
            key={'window-file-explorer_' + lastWindowId}
            startingDir={startingDir}
            homeDir={homeDir}
            breadCrumbStartLevel={breadCrumbStartLevel}
            canSubscribeUsers={canSubscribeUsers}
            params={params}
            type={type}
            isInModal={true}
            droppableId={'droppable_window-file-explorer_' + lastWindowId}
            draggableId={'draggable_window-file-explorer_' + lastWindowId}
          />
        ),
        droppableId: `droppable_window-file-explorer_${lastWindowId}`,
        id: lastWindowId,
        title: 'File Explorer',
      })
    );
  }, [dispatch, lastWindowId, canOpenFileExplorerWindow]);

  return (
    <>
      <FileExplorer
        startingDir={startingDir}
        homeDir={homeDir}
        breadCrumbStartLevel={breadCrumbStartLevel}
        canSubscribeUsers={canSubscribeUsers}
        params={params}
        type={type}
        canOpenWindow={canOpenFileExplorerWindow}
        onOpenWindow={onOpenWindow}
        isInModal={isInModal}
        droppableId={droppableId}
        draggableId={draggableId}
      ></FileExplorer>
    </>
  );
};
