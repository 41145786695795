import * as React from 'react';
import { ListItem } from '../interfaces/interfaces';
import FilterableSelect from './FilterableSelect';

interface Props {
  id: string;
  filterable?: boolean
  defaultValue?: string;
  onChange?: (id: string) => any;
  fullNames?: boolean;
  style?: any;
}

export const StateDropdown = (props: Props) => {
  const { id, filterable, fullNames, defaultValue, onChange } = props;
  const items = States.map(x => new ListItem(x[0], fullNames ? x[1] : x[0]));

  const _handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(e.currentTarget.value);
  }

  const style = props.style || {}

  if (filterable) {
    return (
      <FilterableSelect id={id} items={items} onChange={onChange} defaultVal={defaultValue} />
    )
  }

  return (
    <select key={defaultValue} id={id} name={id} className='form-control' defaultValue={defaultValue} onChange={_handleChange} style={style}>
      {States.map(x => <option key={x[0]} className='form-control' value={x[0]}>{fullNames ? x[1] : x[0]}</option>)}
    </select>
  )
}

const States = [
  ["", ""],
  ["Alabama", "Alabama"],
  ["Alaska", "Alaska"],
  ["Arkansas", "Arkansas"],
  ["Arizona", "Arizona"],
  ["California", "California"],
  ["Colorado", "Colorado"],
  ["Connecticut", "Connecticut"],
  ["Washigton D.C.", "Washington D.C."],
  ["Delaware", "Delaware"],
  ["Florida", "Florida"],
  ["Georgia", "Georgia"],
  ["Hawaii", "Hawaii"],
  ["Iowa", "Iowa"],
  ["Idaho", "Idaho"],
  ["Illinois", "Illinois"],
  ["Indiana", "Indiana"],
  ["Kansas", "Kansas"],
  ["Kentucky", "Kentucky"],
  ["Lousiana", "Louisiana"],
  ["Massachusetts", "Massachusetts"],
  ["Maryland", "Maryland"],
  ["Maine", "Maine"],
  ["Michigan", "Michigan"],
  ["Minnesota", "Minnesota"],
  ["Missouri", "Missouri"],
  ["Mississippi", "Mississippi"],
  ["Montana", "Montana"],
  ["North Carolina", "North Carolina"],
  ["North Dakota", "North Dakota"],
  ["Nebraska", "Nebraska"],
  ["New Hampshire", "New Hampshire"],
  ["New Jersey", "New Jersey"],
  ["New Mexico", "New Mexico"],
  ["New York", "New York"],
  ["Nevada", "Nevada"],
  ["Ohio", "Ohio"],
  ["Oklahoma", "Oklahoma"],
  ["Oregon", "Oregon"],
  ["Pennsylvania", "Pennsylvania"],
  ["Rhode Island", "Rhode Island"],
  ["South Carolina", "South Carolina"],
  ["South Dakota", "South Dakota"],
  ["Tennessee", "Tennessee"],
  ["Texas", "Texas"],
  ["Utah", "Utah"],
  ["Virginia", "Virginia"],
  ["Vermont", "Vermont"],
  ["Washington", "Washington"],
  ["West Virginia", "West Virginia"],
  ["Wisconsin", "Wisconsin"],
  ["Wyoming", "Wyoming"],
]