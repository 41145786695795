import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export const AdminOperations = () => {

    const dispatch = useDispatch();
    const [connection, setConnection] = React.useState<null | HubConnection>(null);

    React.useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl('/hubs/streams')
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, [])

    React.useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    _append('Connected to Hub');
                    connection.on("ReceiveMessage", (message) => {
                        _append(message);                        
                    })
                })
                .catch((error) => console.log(error));
        }
    }, [connection])

    const _append = (m: string) => {
        const p = document.createElement("p");
        p.style.marginBottom = '2px';
        if (m.toUpperCase().indexOf("ERROR") !== -1) p.style.color = 'red';
        p.append(m);
        (document.querySelector('#streamList') as HTMLDivElement).append(p);
    }

    const _start = () => {        
        _append('Calling method');
        fetch('api/filetest/massmove').then(res => {
            if (res.ok) {
                _append('Method complete');
            }
        });
    }

    const _startMove = () => {
        _append('Calling move method');
        fetch('api/filetest/filemove').then(res => {
            if (res.ok) {
                _append('Method complete');
            }
        });
    }

    return (
        <React.Fragment>
            <button className='btn btn-primary' onClick={(e) => { e.currentTarget.disabled = true; _start(); }}>Update Paths</button>
            <button className='btn btn-primary' onClick={(e) => { e.currentTarget.disabled = true; _startMove(); }}>Move Files</button>
            <div style={{ maxHeight: '600px', overflowY: 'auto' }} id='streamList'>
            </div>
        </React.Fragment>
    )
}