import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const ProjectsLoader = () => (
  <div>
    <ProjectListLoader />
    {/*<div style={{height: '30px'}}></div>*/}
    {/*<ProjectListLoader />*/}
  </div>
)

const ProjectListLoader = () => (
  <ContentLoader
    speed={2}
    interval={0}
    width={'100%'}
    height={800}
    viewBox="0 0 100% 400"
    backgroundColor="#ddd"
    foregroundColor="#eee"
  >
    <rect x='0' y='0' rx='3' ry='3' width='100%' height='2' />

    <rect x="0" y="0" rx="3" ry="3" width="2" height="598" />
    <rect x="0" y="2" rx="3" ry="3" width="100%" height="64" />

    <rect x="0" y="60" rx="3" ry="3" width="1%" height="33" />
    <rect x="7%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="18.25%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="29.5%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="40.75%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="52%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="63.25%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="74.5%" y="60" rx="3" ry="3" width="4.25%" height="33" />
    <rect x="85.75%" y="60" rx="3" ry="3" width="14.25%" height="33" />

    <rect x="0" y="82" rx="3" ry="3" width="100%" height="16" />

    <rect x='1%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='116' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='116' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='123' width='20' r='9' />
    <circle cx='96%' cy='123' width='20' r='9' />
    <circle cx='98%' cy='123' width='20' r='9' />

    <rect x="0.5%" y="152" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='172' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='172' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='179' width='20' r='9' />
    <circle cx='96%' cy='179' width='20' r='9' />
    <circle cx='98%' cy='179' width='20' r='9' />

    <rect x="0.5%" y="208" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='228' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='228' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='235' width='20' r='9' />
    <circle cx='96%' cy='235' width='20' r='9' />
    <circle cx='98%' cy='235' width='20' r='9' />

    <rect x="0.5%" y="264" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='284' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='284' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='291' width='20' r='9' />
    <circle cx='96%' cy='291' width='20' r='9' />
    <circle cx='98%' cy='291' width='20' r='9' />

    <rect x="0.5%" y="320" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='340' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='340' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='347' width='20' r='9' />
    <circle cx='96%' cy='347' width='20' r='9' />
    <circle cx='98%' cy='347' width='20' r='9' />

    <rect x="0.5%" y="376" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='396' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='396' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='403' width='20' r='9' />
    <circle cx='96%' cy='403' width='20' r='9' />
    <circle cx='98%' cy='403' width='20' r='9' />

    <rect x="0.5%" y="432" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='452' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='452' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='459' width='20' r='9' />
    <circle cx='96%' cy='459' width='20' r='9' />
    <circle cx='98%' cy='459' width='20' r='9' />

    <rect x="0.5%" y="488" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='508' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='508' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='515' width='20' r='9' />
    <circle cx='96%' cy='515' width='20' r='9' />
    <circle cx='98%' cy='515' width='20' r='9' />

    <rect x="0.5%" y="544" rx="3" ry="3" width="99.5%" height="2" />

    <rect x='1%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='11.25%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='22.5%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='33.75%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='45%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='56.25%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='67.5%' y='564' rx='3' ry='3' width='9%' height='18' />
    <rect x='78.75%' y='564' rx='3' ry='3' width='9%' height='18' />
    <circle cx='94%' cy='571' width='20' r='9' />
    <circle cx='96%' cy='571' width='20' r='9' />
    <circle cx='98%' cy='571' width='20' r='9' />

    <rect x="0%" y="598" rx="3" ry="3" width="100%" height="2" />

    <rect x="99.9%" y="0" rx="3" ry="3" width="2" height="598" />


  </ContentLoader>
)