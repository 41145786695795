import * as React from 'react'
import DateRangePicker from 'react-daterange-picker'
import { DateRange, extendMoment } from 'moment-range';
import * as cx from 'classnames';

import PointedPopup from './PointedPopup'
import * as Moment from 'moment';
const moment = extendMoment(Moment);

const RangeOptions = Object.freeze({ Next7: 'next7', Next30: 'next30', NextMonth: 'NextMonth', Custom: 'Custom' })

export interface CustomRangeOpt {
    rangeOpt: string,
    label: string
    range: DateRange
}

interface Props {
    onChange: (range?: DateRange) => void;
    rangeOpts?: [CustomRangeOpt, CustomRangeOpt, CustomRangeOpt],
    defaultRange?: DateRange
    className?: string;
}

interface State {
    range?: DateRange;
    rangeOpt?: string;
    rangeOpts: [CustomRangeOpt, CustomRangeOpt, CustomRangeOpt]
}

export const SVDateRangePicker = (props: Props) => {
    const [state, setState] = React.useState({
        range: props.defaultRange,
        rangeOpts: props.rangeOpts || [
            { rangeOpt: 'next7', label: 'Next 7 Days', range: new DateRange(new Date(), moment(new Date).add(7, 'days')) },
            { rangeOpt: 'next30', label: 'Next 30 Days', range: new DateRange(new Date(), moment(new Date).add(30, 'days')) },
            { rangeOpt: 'nextMonth', label: 'Next Month', range: moment(moment(new Date()).add(1, 'months')).range('month') }
        ]
    } as State);

    React.useEffect(() => {
        if (props.defaultRange != state.range) {
            setState({ ...state, range: props.defaultRange })
        }
    }, [props.defaultRange])

    React.useEffect(() => {
        if (state.range === undefined || props.defaultRange != state.range) {
            props.onChange(state.range);
        }
    }, [state.range])

    const _changeStart = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        const { range } = state;
        const newDate = moment(e.currentTarget.value)
        if (newDate.isValid() && newDate.year() > 1970) {
            setState({ 
                ...state, range: moment.range(newDate, range ? range.end : moment(newDate).add(7, 'days'))
            });
        }
    }

    const _changeEnd = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
        const { range } = state;
        const newDate = moment(e.currentTarget.value)
        if (newDate.isValid() && newDate.year() > 1970) {
            setState({
                ...state, range: moment.range(range ? range.start : moment(newDate).subtract(7, 'days'), newDate)
            });
        }
    }

    const _setRange = (rangeOpt: string) => {
        const selectedRange = state.rangeOpts.find(x => x.rangeOpt === rangeOpt)

        if (selectedRange) {
            setState({
                ...state, 
                range: selectedRange.range,
                rangeOpt: rangeOpt
            })
        }
    }

    const _handleDateChange = (range, states) => {
        setState({
            ...state, 
            range: range,
            rangeOpt: RangeOptions.Custom
        })
    }

    const _clearRange = (e: React.MouseEvent<HTMLSpanElement>) => {
        /* we have to determine if the popup is currently open to determine whether to stop propagation of the click event */
        const popup = document.querySelector('.date-range-picker-dialog') as HTMLElement;
        if (!popup) e.stopPropagation();
        setState({ ...state, range: undefined, rangeOpt: undefined })
    }

    const { range, rangeOpt, rangeOpts } = state;
    const startDate = range ? range.start.format('YYYY-MM-DD') : ''
    const endDate = range ? range.end.format('YYYY-MM-DD') : '';

    const optRows = rangeOpts.map(x => {
        return (
            <div key={x.rangeOpt} className={cx('range-row option', rangeOpt === x.rangeOpt && 'active')}
                onClick={() => _setRange(x.rangeOpt)}
            >{x.label}</div>
        )
    })

    const str = dateRangeToString(range);

    const defaultEle =  <div style={{ position: 'relative' }}>
            <input className={cx(props.className || '')} value={str} title={str} readOnly />
            <span className='fas fa-times' onClick={_clearRange}
            ></span>
        </div>    

    return (
        <div className='date-range-picker'>
            <PointedPopup defaultEle={defaultEle} leftAlign={true}>
                <div className='date-range-picker-dialog'>
                    <div className='range-options'>
                        <div className='range-row'><label>DATE RANGE</label></div>
                        {optRows}
                        <div className={cx('range-row option', rangeOpt === RangeOptions.Custom && 'active')}
                        >Custom Range</div>
                        <div className='range-row'>
                            <input className='form-control' type='date' value={startDate} onChange={_changeStart}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                            &nbsp; <span>to</span> &nbsp;
                            <input className='form-control' type='date' value={endDate} onChange={_changeEnd}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </div>
                    </div>
                    <div className='calendar'>
                        <DateRangePicker
                            firstOfWeek={0}
                            value={range}
                            onSelect={_handleDateChange}
                        />
                    </div>
                </div>
            </PointedPopup>
        </div>
    )
}

//export default class SVDateRangePicker extends React.Component<Props, State> {
//    debouncedFn: any;

//    constructor(props) {
//        super(props)        
//        this.state = {
//            rangeOpts: [
//                { rangeOpt: 'next7', label: 'Next 7 Days', range: new DateRange(new Date(), moment(new Date).add(7, 'days')) },
//                { rangeOpt: 'next30', label: 'Next 30 Days', range: new DateRange(new Date(), moment(new Date).add(30, 'days')) },
//                { rangeOpt: 'nextMonth', label: 'Next Month', range: moment(moment(new Date()).add(1, 'months')).range('month') }
//            ]
//        }
//    }

//    componentDidMount() {
//        if (this.props.rangeOpts) this.setState({ rangeOpts: this.props.rangeOpts })
//        if (this.props.defaultRange) this.setState({ range: this.props.defaultRange })
//    }
    
//    componentDidUpdate(prevProps: Props, prevState: State) {
//        // We only want this to run if the component is already loaded and the user has changed the value
//        if ((this.props.defaultRange === undefined || prevState.range !== undefined) && this.state.range !== prevState.range) {
//            this.props.onChange(this.state.range);
//        } else {
//            if (this.props.defaultRange !== prevProps.defaultRange) {
//                this.setState({ range: this.props.defaultRange });
//            }
//        }        
//    }

//    _changeStart = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
//        //e.persist();

//        //this.debouncedFn = debounce(() => {
//        const { range } = this.state;
//        const newDate = moment(e.currentTarget.value)
//        if (newDate.isValid() && newDate.year() > 1970) {
//            this.setState({
//                range: moment.range(newDate, range ? range.end : moment(newDate).add(7, 'days'))
//            });
//        }
//        //}, 1000)
//        //this.debouncedFn();
//    }

//    _changeEnd = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => {
//        const { range } = this.state;
//        const newDate = moment(e.currentTarget.value)
//        if (newDate.isValid() && newDate.year() > 1970) {
//            this.setState({
//                range: moment.range(range ? range.start : moment(newDate).subtract(7, 'days'), newDate)
//            });
//        }
//    }

//    _setRange = (rangeOpt: string) => {
//        const selectedRange = this.state.rangeOpts.find(x => x.rangeOpt === rangeOpt)

//        if (selectedRange) {
//            this.setState({
//                range: selectedRange.range,
//                rangeOpt: rangeOpt
//            })
//        }
//    }

//    _handleDateChange = (range, states) => {
//        this.setState({
//            range: range,
//            rangeOpt: RangeOptions.Custom
//        })
//    }

//    _clearRange = (e: React.MouseEvent<HTMLSpanElement>) => {
//        /* we have to determine if the popup is currently open to determine whether to stop propagation of the click event */
//        const popup = document.querySelector('.date-range-picker-dialog') as HTMLElement;
//        if (!popup) e.stopPropagation();
//        this.setState({ range: undefined, rangeOpt: undefined })
//    }

//    render() {
//        const { range, rangeOpt, rangeOpts } = this.state;
//        const startDate = range ? range.start.format('YYYY-MM-DD') : ''
//        const endDate = range ? range.end.format('YYYY-MM-DD') : '';

//        const optRows = rangeOpts.map(x => {
//            return (
//                <div key={x.rangeOpt} className={cx('range-row option', rangeOpt === x.rangeOpt && 'active')}
//                    onClick={() => this._setRange(x.rangeOpt)}
//                >{x.label}</div>
//            )
//        })

//        return (
//            <div className='date-range-picker'>
//                <PointedPopup defaultEle={this.renderDefaultEle()} leftAlign={true}>
//                    <div className='date-range-picker-dialog'>
//                        <div className='range-options'>
//                            <div className='range-row'><label>DATE RANGE</label></div>
//                            {optRows}
//                            <div className={cx('range-row option', rangeOpt === RangeOptions.Custom && 'active')}
//                            >Custom Range</div>
//                            <div className='range-row'>
//                                <input className='form-control' type='date' value={startDate} onChange={this._changeStart}
//                                    onKeyDown={(e) => e.preventDefault()}
//                                />
//                                &nbsp; <span>to</span> &nbsp;
//                                <input className='form-control' type='date' value={endDate} onChange={this._changeEnd}
//                                    onKeyDown={(e) => e.preventDefault()}
//                                />
//                            </div>
//                        </div>
//                        <div className='calendar'>
//                            <DateRangePicker
//                                firstOfWeek={0}
//                                value={range}
//                                onSelect={this._handleDateChange}
//                            />
//                        </div>
//                    </div>
//                </PointedPopup>
//            </div>
//        )
//    }

//    renderDefaultEle() {
//        const { range } = this.state;
//        const str = dateRangeToString(range);       

//        return <div style={{ position: 'relative' }}>
//            <input className={cx(this.props.className || '')} value={str} title={str} readOnly />
//            <span className='fas fa-times' onClick={this._clearRange}
//            ></span>
//        </div>

//    }
//}

export const dateRangeToString = (range: DateRange | undefined) => {
    const startRange = range ? range.start.format('MM/DD/YYYY').substr(0, 10) : ''
    const endRange = range ? range.end.format('MM/DD/YYYY').substr(0, 10) : '';

    return `${startRange} - ${endRange}`;
}