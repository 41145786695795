import * as React from 'react';
import { MessageBox } from '../../components/MessageBox';
import Modal from '../../components/Modal';

import { CustomField, CustomFieldType } from '../../interfaces/interfaces';
import { CustomFieldsContext } from './CustomFieldHome';


export const CustomFieldsTile = (props: { type: string, controller?: string }) => {
    const { type } = props;
    // @ts-ignore
    const [context, setContext] = React.useContext(CustomFieldsContext);
    const [showAdd, setShowAdd] = React.useState(false);
    const [removedField, setRemovedField] = React.useState(null as CustomField | null);
    const [editFieldId, setEditFieldId] = React.useState(null as number | null)
    const [message, setMessage] = React.useState(undefined as string | undefined)
    
    const fields = context;

    const _add = () => {
        const fieldName = (document.getElementById(`${type}_fieldName`) as HTMLInputElement).value,
            fieldType = (document.getElementById(`${type}_fieldType`) as HTMLSelectElement).value;
        fetch(`api/customfield/add?recordType=${type}&fieldName=${encodeURIComponent(fieldName)}&fieldType=${fieldType}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setContext([...fields, data]);
                    setShowAdd(false);
                }
                else setMessage(data.message);
            })
    }

    const _edit = () => {
        const fieldName = (document.getElementById(`${type}_fieldName`) as HTMLInputElement).value,
            fieldType = (document.getElementById(`${type}_fieldType`) as HTMLSelectElement).value;
        fetch(`api/customfield/edit?recordType=${type}&fieldId=${editFieldId}&fieldName=${encodeURIComponent(fieldName)}&fieldType=${fieldType}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setContext([...fields.filter(x => x.id !== editFieldId), data]);
                    setEditFieldId(null);
                }
                else setMessage(data.message);
            })
    }

    const _remove = () => {
        if (removedField) {
            fetch(`api/customfield/delete?recordType=${type}&id=${removedField.id}`, { method: 'DELETE' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setContext(fields.filter(x => x.id !== removedField.id));
                        setRemovedField(null)
                    }
                })
        }
    }

    const _fieldTypeToString = (type: CustomFieldType) => {
        switch (type) {
            case CustomFieldType.String: return 'String';
            case CustomFieldType.Boolean: return 'Boolean (Yes / No)';
            case CustomFieldType.DateTime: return 'Date';
            default: return 'String';
        }
    }

    const fieldForms = fields
        .filter(x => x.recordType === type)
        .sort((a,b) => a.name > b.name ? 1 : -1)
        .map((x: CustomField) => {
        if (x.id === editFieldId) {
            return (
                <div className='custom-field-line add-line' key={x.id}>
                    <div className='my-col-10'><input className='form-control' id={`${type}_fieldName`} defaultValue={x.name} /></div>
                    <div className='my-col-6'>
                        <select className='form-control' id={`${type}_fieldType`} defaultValue={x.fieldType}>
                            <option className='form-control' value={CustomFieldType.String}>String</option>
                            <option className='form-control' value={CustomFieldType.Boolean}>Boolean (Yes / No)</option>
                            <option className='form-control' value={CustomFieldType.DateTime}>Date</option>
                        </select>
                    </div>                    
                    <div className='buttons'>
                        <button className='btn btn-sm btn-blue' onClick={_edit}>Save</button>
                        <button className='btn btn-sm btn-outline-secondary' onClick={() => setEditFieldId(null)}>Cancel</button>
                    </div>
                </div>
            )
        }
        const fieldType = _fieldTypeToString(x.fieldType);
        return (
            <div className='custom-field-line' key={x.id}>
                <div className='my-col-10'>{x.name}</div>
                <div className='my-col-8'>{fieldType}</div>
                <div className='my-col-2'>
                    <span className='fas fa-edit' onClick={() => { setEditFieldId(x.id); setShowAdd(false) }}></span>
                    <span className='fas fa-times' onClick={() => setRemovedField(x)}></span>
                </div>
            </div>
        )
    })

    const renderAddLine = () => {
        return (
            <div className='custom-field-line add-line'>
                <div className='my-col-10'><input className='form-control' id={`${type}_fieldName`} /></div>
                <div className='my-col-6'>
                    <select className='form-control' id={`${type}_fieldType`}>
                        <option className='form-control' value={CustomFieldType.String}>String</option>
                        <option className='form-control' value={CustomFieldType.Boolean}>Boolean (Yes / No)</option>
                        <option className='form-control' value={CustomFieldType.DateTime}>Date</option>
                    </select>
                </div>
                <div className='buttons'>
                    <button className='btn btn-sm btn-blue' onClick={_add}>Save</button>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowAdd(false)}>Cancel</button>
                </div>
            </div>
        )
    }

    const removedFieldModal = (
        <Modal>
            <div className='modal-header'><h5>Delete {removedField ? removedField.name : ''}</h5></div>
            <div className='modal-body'>
                <p>Are you sure you want to delete this custom field? <u>Doing so will delete all field entries for this field on all {type}s.</u></p>
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-red' onClick={_remove}>Delete</button>
                <button className='btn btn-sm btn-outline-secondary' onClick={() => setRemovedField(null)}>Cancel</button>
            </div>
        </Modal>    
    )

    return (
        <div className='tile config-tile custom-fields-tile'>
            <MessageBox message={message} clearMessage={() => setMessage(undefined)} />
            <div className='tile-header'>
                <h5>{type} Fields</h5>
                <button className='btn btn-sm btn-blue fas fa-plus' onClick={() => { setShowAdd(true); setEditFieldId(null) }}></button>
            </div>
            <hr />
            <div className='custom-field-line header-row'>
                <div className='my-col-10'>
                    <b>Field Name</b>
                </div>
                <div className='my-col-10'>
                    <b>Field Type</b>
                </div>
            </div>
            {fieldForms}
            {showAdd && renderAddLine()}
            {removedField && removedFieldModal}
        </div>
    )
}