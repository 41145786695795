import { isAnyOf } from "@reduxjs/toolkit";
import React from "react";
import { ProcessingNotification } from "src/interfaces/interfaces";
import { AppStartListening } from "../listenerMiddleware";
import { ProcessingNotificationActions } from "./processingNotifications.reducer";

export const addProcessingNotificationListeners = (
	startListening: AppStartListening
) => {
	const listeners = [
		startListening({
			actionCreator: ProcessingNotificationActions.getNotifications,
			effect: (action, listenApi) => {
				const user = listenApi.getState().user.user;
				//read from localstorage
				const notifications = localStorage.getItem(
					user?.userId + "_pNotifications"
				);
				if (notifications !== null) {
					const notes: ProcessingNotification[] = JSON.parse(notifications);
					listenApi.dispatch(
						ProcessingNotificationActions.setNotifications(notes)
					);
				}
			},
		}),
		startListening({
			matcher: isAnyOf(
				ProcessingNotificationActions.addNotification,
				ProcessingNotificationActions.addUploadNotification,
				ProcessingNotificationActions.updateNotification,
				ProcessingNotificationActions.setDownloadedNotification,
				ProcessingNotificationActions.deleteNotification,
				ProcessingNotificationActions.deleteAllNotifications,
				ProcessingNotificationActions.setReadNotification
			),
			effect: (action, listenApi) => {
				const user = listenApi.getState().user.user;
				const notifications =
					listenApi.getState().processingNotifications.notifications;

				localStorage.setItem(
					user?.userId + "_pNotifications",
					JSON.stringify(notifications)
				);
			},
		}),
		startListening({
			actionCreator: ProcessingNotificationActions.addNotification,
			effect: async (action, listenApi) => {
				listenApi.dispatch(
					ProcessingNotificationActions.setShowNewNotification({
						show: true,
						isUpload: false,
					})
				);
				if (
					await listenApi.condition(
						ProcessingNotificationActions.setDownloadedNotification.match
					)
				) {
					listenApi.dispatch(
						ProcessingNotificationActions.setShowNewNotification({
							show: false,
							isUpload: false,
						})
					);
				}
			},
		}),
		startListening({
			actionCreator: ProcessingNotificationActions.addUploadNotification,
			effect: async (action, listenApi) => {
				listenApi.dispatch(
					ProcessingNotificationActions.setShowNewNotification({
						show: true,
						isUpload: true,
					})
				);
				if (
					await listenApi.condition(
						ProcessingNotificationActions.updateNotification.match
					)
				) {
					listenApi.dispatch(
						ProcessingNotificationActions.setShowNewNotification({
							show: false,
							isUpload: true,
						})
					);
				}
			},
		}),
	];
	return () => {
		listeners.forEach((unsubscribe) => unsubscribe());
	};
};
