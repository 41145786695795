import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const GraphsLoader = () => {

    return (
        <div>
            <FilterHeaderLoader />
            <div className='graphs-container'>
                <GraphPartLoader />
                <GraphPartLoader />
            </div>
        </div>
    )
}

const FilterHeaderLoader = () => (
    <ContentLoader
        speed={2}
        interval={0}
        width={'100%'}
        height={72}
        //viewBox="0 0 100% 70"
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >
        <rect x='0' y='0' rx='3' ry='3' width='3' height='72' />
        <rect x='0' y='0' rx='3' ry='3' width='100%' height='9' />
        <rect x='0' y='9' rx='3' ry='3' width='92.5%' height='23' />
        <rect x='98.5%' y='9' rx='3' ry='3' width='1.5%' height='23' />
        <rect x='0' y='32' rx='3' ry='3' width='100%' height='8' />
        <rect x='0' y='40' rx='3' ry='3' width='92.5%' height='23' />
        <rect x='98.5%' y='40' rx='3' ry='3' width='1.5%' height='23' />
        <rect x='0' y='63' rx='3' ry='3' width='100%' height='9' />
        <rect x='99.8%' y='0' rx='3' ry='3' width='3' height='72' />
    </ContentLoader>
)

const GraphPartLoader = () => (
    <ContentLoader
        speed={2}
        interval={0}
        width={'100%'}
        height={500}
        //viewBox="0 0 100% 500"
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >         
        <rect x='0' y='0' rx='3' ry='3' width='2' height='500' />
        <rect x='0' y='0' rx='3' ry='3' width='100%' height='2' />

        <rect x='15' y='10' rx='3' ry='3' width='200' height='30' />
        <rect x='90%' y='10' rx='3' ry='3' width='8%' height='20' />

        <rect x='15' y='45' rx='3' ry='3' width='70' height='15' />
        <rect x='15' y='63' rx='3' ry='3' width='25%' height='28' />
        <rect x='29%' y='45' rx='3' ry='3' width='70' height='15' />
        <rect x='29%' y='63' rx='3' ry='3' width='25%' height='28' />
        <rect x='56%' y='45' rx='3' ry='3' width='70' height='15' />
        <rect x='56%' y='63' rx='3' ry='3' width='25%' height='28' />

        <rect x='15' y='135' rx='3' ry='3' width='96%' height='321' />

        <rect x='99.7%' y='0' rx='3' ry='3' width='2' height='500' />
        <rect x='0' y='498' rx='3' ry='3' width='100%' height='2' />      
    </ContentLoader>
)