import * as React from 'react';
import { connect } from 'react-redux';
import * as AdminStore from '../store/admin'
import { ApplicationState } from '../store';
import { MessageBox } from '../components/MessageBox';
import { UserInfo } from '../interfaces/interfaces';

interface State {
  user: UserInfo;
}

type Props =
    AdminStore.Store
    & typeof AdminStore.actionCreators

class ChangePasswordView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            user: {
                userId: '', email: '', fullName: '', phoneNumber: '', roles: [], userActivated: false, subscriptions: [], defaultProjectTab: '', defaultDashboardTab: '',
            authenticationInfo: { smsVerified: false, twoFactorEnabled: false, emailConfirmed: false }, isSidebarPinned: false
            }
      }

    }

    componentWillMount() {
        fetch(`api/User/Get`)
            .then(res => res.json() as Promise<UserInfo>)
            .then(data => this.setState({
                user: data
            }))
    }

    componentWillUpdate(prevProps: Props) {
        if (!prevProps.message && (this.props.message || '').toUpperCase() == "SAVED") {
            const inputs = document.querySelectorAll('div#change-pass-form input')
            Array.prototype.forEach.call(inputs, (input: HTMLInputElement) => {
                input.value = '';
            });
        }
    }

    _showPass = (e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
        e.currentTarget.classList.remove('fa-eye-open')
        e.currentTarget.classList.add('fa-eye-close')
        const input = (e.currentTarget.parentElement as HTMLDivElement).querySelector('input[type="password"]') as HTMLInputElement
        input.type = 'text';
    }

    _hidePass = (e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
        e.currentTarget.classList.remove('fa-eye-close')
        e.currentTarget.classList.add('fa-eye-open')
        const input = (e.currentTarget.parentElement as HTMLDivElement).querySelector('input[type="text"]') as HTMLInputElement
        input.type = 'password';
    }

    _save = () => {
        const modalBody = document.querySelector('div#change-pass-form') as HTMLDivElement
        if (modalBody && this.state.user.userId) {
            const valid = true;
            const inputs = modalBody.querySelectorAll('input');
            const old = inputs[0].value;
            const newpass = inputs[1].value;
            const newpass2 = inputs[2].value            

            if (valid) {
                this.props.changePw(this.state.user.email, old, newpass, newpass2)
            }
        }
    }

    render() {

        return (
            <div className='col-sm-2'>
                <MessageBox message={this.props.message} clearMessage={this.props.clearMessage} />
                <div className="form-header">
                    <h4 className="modal-title">Change Password</h4>
                </div>
                <div className="form-body" id="change-pass-form">
                    <br />
                    <div className='form-group'>
                        <label>Current Password</label>
                        <input key={`oldpass`} type='password' className='form-control' />
                        <span className='show-pass-toggle fas fa-eye-open'
                            onMouseDown={this._showPass} onTouchStart={this._showPass}
                            onMouseUp={this._hidePass} onTouchEnd={this._hidePass}
                        ></span>
                    </div>
                    <div className='form-group'>
                        <label>New Password</label>
                        <input key={`newpass`} type='password' className='form-control' />
                        <span className='show-pass-toggle fas fa-eye-open'
                            onMouseDown={this._showPass} onTouchStart={this._showPass}
                            onMouseUp={this._hidePass} onTouchEnd={this._hidePass}
                        ></span>
                    </div>
                    <div className='form-group'>
                        <label>Re-type New Password</label>
                        <input key={`newpass2`} type='password' className='form-control' />
                        <span className='show-pass-toggle fas fa-eye-open'
                            onMouseDown={this._showPass} onTouchStart={this._showPass}
                            onMouseUp={this._hidePass} onTouchEnd={this._hidePass}
                        ></span>
                    </div>
                </div>
                <div className="form-footer">
                    <span className="btn btn-sm btn-green" onClick={this._save.bind(this)}>Save</span>
                </div>

            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.admin,
    AdminStore.actionCreators
// @ts-ignore
)(ChangePasswordView)