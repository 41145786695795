import React from 'react';
import { ExplorerItemType } from '../../interfaces/enums';
import { FileExplorerItem } from '../../interfaces/interfaces';
import Modal from '../Modal';

interface FileExplorerMoveItemModalProps {
  copiedForMovingItem: FileExplorerItem | undefined;
  subPath: string | undefined;
  onMoveItem: () => void;
  onToggleMovingModal: () => void;
}

export const FileExplorerMoveItemModal = ({
  copiedForMovingItem,
  subPath,
  onMoveItem,
  onToggleMovingModal,
}: FileExplorerMoveItemModalProps) => {
  if (!copiedForMovingItem) return <div></div>;

  const isFile = copiedForMovingItem.type === ExplorerItemType.File;
  const headerText = isFile ? 'Move File' : 'Move Folder';

  return (
    <Modal>
      <div className="modal-header">
        <h4>{headerText}</h4>
      </div>
      <div className="modal-body">
        <div style={{ fontSize: '14px' }}>
          Are you sure you want to move {copiedForMovingItem.name} to {subPath}?
        </div>
        <p>
          <b>Note:</b> This will remove {copiedForMovingItem.name} from it's
          current location
        </p>
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-blue" onClick={onMoveItem}>
          Move
        </button>
        <button
          className="btn btn-sm btn-secondary"
          onClick={onToggleMovingModal}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
