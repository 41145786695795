import * as React from 'react';
import { useSelector } from 'react-redux';
import { FilterOption, PunchItemVm } from '../interfaces/interfaces';
import { mockPunchItem, mockPunchItem2, mockPunchItem3, mockPunchItem4 } from '../services/punchlist/mocks';
import { ApplicationState } from '../store';
import { PunchlistCharts } from './PunchlistCharts';
import { PunchlistGrid } from './PunchlistGrid';

export const PunchlistSummary = (props: {items: PunchItemVm[]}) => {

    const { items } = props;
    const [filters, setFilters] = React.useState([] as FilterOption[]);

    let filteredItems = items.slice();
    filters.forEach((f) => {
        switch (f.dataType) {
            case "number":
                filteredItems = filteredItems.filter((x) => x[f.prop] >= f.value);
                break;
            case "boolean":
                filteredItems = filteredItems.filter((x) => x[f.prop] === Boolean(f.value));
                break;
            case "nested":
                filteredItems = filteredItems.filterByNestedProp(f.prop, f.value.toString());
                break;
            case "date":
                filteredItems = filteredItems.filter((x) => {
                    if (f.value === "") return true;
                    if (!x[f.prop]) return false;

                    const dateValue = new Date(x[f.prop]);
                    //@ts-ignore
                    return dateValue >= (f.value).start.toDate() && dateValue <= (f.value).end.toDate();
                })
                break;
            case "string":
            default:
                filteredItems = filteredItems.filterByStringProp(f.prop, f.value.toString());
                break;
        }
    });


    return <div className='punchlist-summary-page'>
        <PunchlistCharts items={filteredItems} />
        <br />
        <PunchlistGrid items={filteredItems} filterCallback={(options: FilterOption[]) => setFilters(options)} />
    </div>
}