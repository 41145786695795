import * as React from 'react';
import { connect } from 'react-redux';
import * as AdminStore from '../../store/admin'
import { MessageBox } from '../../components/MessageBox';
import { ApplicationState } from '../../store';

interface FormProps {    
}

//interface State {
//    clientId: number,
//    clientEmail: string,
//    clientCode: string,
//    clientContact: string,
//}

type Props =
    FormProps
    & AdminStore.Store
    & typeof AdminStore.actionCreators

class EditClientForm extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props)
        this.state = {           
        }
    }

    componentWillMount() {
        this.props.getClients()
    }

    _getClients = () => {
        fetch(`api/Client/Get`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    clients: data
                })
            });
    }

    _save = () => {
        const modalBody = document.querySelector('div#edit-client-form') as HTMLDivElement
        if (modalBody) {
            const valid = true;
            const inputs = modalBody.querySelectorAll('input');
                       
            if (valid) {
                this.props.editClient(this.props.clientId || 0, inputs[1].value, inputs[2].value)                
            }
        }
    }

    _changeClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const opt = (e.currentTarget as HTMLSelectElement).querySelector('option:checked') as HTMLOptionElement;
        if (opt) {
            this.props.getClientInfo(opt.value)
        }
    }

    render() {
        const clientOpts = this.props.companies && this.props.companies.length ?
            this.props.companies.map(c => <option key={c.id} value={c.id}>{c.value}</option>) :
            []
        return (
            <div className='config-form edit-client'>  
                <MessageBox message={this.props.message} clearMessage={this.props.message} />
                <div className="form-header">
                    <h4 className="modal-title">Edit Client</h4>
                </div>
                <div className="form-body" id="edit-client-form">
                    <div className='form-group'>                                
                        <label>Client</label>                                                                
                        <select className='form-control' name='clientId'
                            onChange={this._changeClient}>
                            <option value='0'>Select...</option>
                            {clientOpts}
                        </select>                                                                
                    </div>                    
                    <div className='form-group'>                                
                        <label>Code</label>
                        <input key={this.props.clientCode} name='code' maxLength={3} className='form-control'
                            defaultValue={this.props.clientCode} disabled
                        />
                    </div>
                    <div className='form-group'>
                        <label>Contact Name</label>
                        <input key={this.props.clientContact} name='contactName' className='form-control'
                            defaultValue={this.props.clientContact}
                        />
                    </div>
                    <div className='form-group'>
                        <label>Contact Email</label>
                        <input key={this.props.clientEmail} name='contactEmail' className='form-control'
                            defaultValue={this.props.clientEmail}
                        />
                    </div>
                </div>
                <div className="form-footer">
                    <span className="btn btn-sm btn-green" onClick={this._save.bind(this)}>Save</span>
                </div>                            
            </div>
        )
    }
}

export default connect<AdminStore.Store, typeof AdminStore.actionCreators, FormProps>(
    //@ts-ignore
    (state: ApplicationState) => state.admin,
    AdminStore.actionCreators
)(EditClientForm)