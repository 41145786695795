import { AppThunkAction } from "./index";
import { MetaObjectType } from "../interfaces/enums";
import {
	ChecklistDetail,
	ChecklistDetailVm,
	ChecklistFieldEntry,
	DFR,
	ListItem,
	MilestoneEntryVm,
	ProjectVm,
	RFI,
	Submittal,
	VendorVm,
} from "../interfaces/interfaces";
import { Action, Reducer, ActionCreator } from "redux";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface GraphDataStore {
	projects: ProjectVm[];
	vendors: VendorVm[];
	rfis: RFI[];
	submittals: Submittal[];
	fieldReports: DFR[];
	milestones: MilestoneEntryVm[];
	checklists: ChecklistDetail[];
	checklistEntries: ChecklistFieldEntry[];
	serverFilters?: GraphDataFilters;
	loading: boolean;
	message?: string;
}

export interface GraphDataFilters {
	projectId?: number;
	userId?: string;
}

interface RequestDataAction {
	type: "REQUEST_GRAPH_DATA";
	serverFilters?: GraphDataFilters;
}

interface ReceiveProjectsAction {
	type: "RECEIVE_GRAPH_PROJECTS";
	projects: ProjectVm[];
}

interface ReceiveVendorsAction {
	type: "RECEIVE_GRAPH_VENDORS";
	vendors: VendorVm[];
}

interface ReceiveMilestonesAction {
	type: "RECEIVE_GRAPH_MILESTONES";
	milestones: MilestoneEntryVm[];
}
interface ReceiveFieldReportsAction {
	type: "RECEIVE_GRAPH_FIELD_REPORTS";
	fieldReports: DFR[];
}

interface ReceiveRFIsAction {
	type: "RECEIVE_GRAPH_RFIS";
	rfis: RFI[];
}

interface ReceiveSubmittalsAction {
	type: "RECEIVE_GRAPH_SUBMITTALS";
	submittals: Submittal[];
}

interface ReceiveChecklistsAction {
	type: "RECEIVE_GRAPH_CHECKLISTS";
	checklists: ChecklistDetail[];
}

interface ReceiveChecklistEntriesAction {
	type: "RECEIVE_GRAPH_CHECKLIST_ENTRIES";
	checklistEntries: ChecklistFieldEntry[];
}

interface GraphDataErrorAction {
	type: "GRAPH_DATA_ERROR";
	message: string;
}

type KnownAction =
	| RequestDataAction
	| ReceiveProjectsAction
	| ReceiveVendorsAction
	| ReceiveMilestonesAction
	| ReceiveFieldReportsAction
	| ReceiveRFIsAction
	| ReceiveSubmittalsAction
	| ReceiveChecklistsAction
	| ReceiveChecklistEntriesAction
	| GraphDataErrorAction;

export const actionCreators = {
	getProjects: createAsyncThunk(
		"graphData/getProjects",
		async (getComplete?: boolean) => {}
	),
	getVendors: createAsyncThunk("graphData/getVendors", async () => {}),
	getMilestones: createAsyncThunk(
		"graphData/getMilestones",
		async (serverFilters: GraphDataFilters) => {}
	),
	getFieldReports: createAsyncThunk(
		"graphData/getFieldReports",
		async (serverFilters: GraphDataFilters) => {}
	),
	getRfis: createAsyncThunk(
		"graphData/getRfis",
		async (serverFilters: GraphDataFilters) => {}
	),
	getSubmittals: createAsyncThunk(
		"graphData/getSubmittals",
		async (serverFilters: GraphDataFilters) => {}
	),
	getChecklists: createAsyncThunk(
		"graphData/getChecklists",
		async (serverFilters: GraphDataFilters) => {}
	),
	getChecklistEntries: createAsyncThunk(
		"graphData/getChecklistEntries",
		async (serverFilters: GraphDataFilters) => {}
	),

	clearMessage: () => (dispatch) =>
		dispatch({ type: "GRAPH_DATA_ERROR", message: undefined }),
};

const unloadedStore: GraphDataStore = {
	projects: [],
	vendors: [],
	rfis: [],
	submittals: [],
	fieldReports: [],
	milestones: [],
	checklists: [],
	checklistEntries: [],
	serverFilters: {},
	loading: true,
};

export const reducer: Reducer<GraphDataStore> = (
	state = unloadedStore,
	action: KnownAction
) => {
	switch (action.type) {
		case "REQUEST_GRAPH_DATA":
			return { ...state, serverFilters: action.serverFilters, loading: true };
		case "RECEIVE_GRAPH_PROJECTS":
			return { ...state, projects: action.projects, loading: false };
		case "RECEIVE_GRAPH_VENDORS":
			return { ...state, vendors: action.vendors, loading: false };
		case "RECEIVE_GRAPH_MILESTONES":
			return { ...state, milestones: action.milestones, loading: false };
		case "RECEIVE_GRAPH_FIELD_REPORTS":
			return { ...state, fieldReports: action.fieldReports, loading: false };
		case "RECEIVE_GRAPH_RFIS":
			return { ...state, rfis: action.rfis, loading: false };
		case "RECEIVE_GRAPH_SUBMITTALS":
			return { ...state, submittals: action.submittals, loading: false };
		case "RECEIVE_GRAPH_CHECKLISTS":
			return { ...state, checklists: action.checklists, loading: false };
		case "RECEIVE_GRAPH_CHECKLIST_ENTRIES":
			return {
				...state,
				checklistEntries: action.checklistEntries,
				loading: false,
			};
		case "GRAPH_DATA_ERROR":
			return { ...state, message: action.message, loading: false };
		default: {
			const exhaustiveCheck: never = action;
		}
	}
	return state;
};
