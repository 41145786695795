import * as React from 'react';
import * as cx from 'classnames';
import styled from 'styled-components';
import {
  CustomReportUser,
  CustomReportVm,
  ListItem,
  NewListItem,
  ReportColumn,
  ReportUserVm,
} from '../../interfaces/interfaces';
import { formatDate } from '../../helpers/formatters';
import { useDispatch, useSelector } from 'react-redux';
import {
  allQueriesSelector,
  loadedQuerySelector,
  loadedReportSelector,
  reportResultSelector,
  reportsStatusSelector,
} from '../../store/customReport/customReport.selectors';
import FilterableSelect from '../../components/FilterableSelect';
import { StandardGridLoader } from '../../loaders/StandardGridLoader';
import { ApplicationState } from '../../store';
import { ReportQueryForm } from '../ReportQueryForm';
import { CustomReportActions } from '../../store/customReport/customReport.reducer';
import { Loader } from '../../components/Loader';
import { TableDataDownloadLink } from '../../components/TableDataDownloadLink';
import { FlexedDiv } from '../../styledComponents';
import { FormGroupDiv as FGroupDiv } from '../../styledComponents';
import { SliderCheckbox } from '../../components/SliderCheckbox';
import { DropResult } from '@hello-pangea/dnd';
import { Draggable, Droppable } from '../../components/DragDrop';
import { DragAndDropDotsIcon } from 'src/components/icons/DragAndDropDotsIcon';

const ConfigurationHeaderDiv = styled.div({
  position: 'relative',
  display: 'flex',
  borderBottom: '2px solid lightgray',
  '& h5': {
    marginBottom: '0px',
  },
});

const ReportTitleDiv = styled(FlexedDiv)`
  padding-bottom: 5px;
  '& .btn-x-sm': {
    height: 1em;
    line-height: 1;
  }
`;

const ReportTitleInput = styled.input({
  maxWidth: '300px',
  fontSize: '1.2em !important',
  display: 'block',
  width: '100%',
  height: 'calc(1.5em + 0.75rem - 10px)',
  padding: '4px 6px',
  fontWeight: '400',
  lineHeight: '1.5',
  backgroundClip: 'padding-box',
  border: '1px solid #ced4da',
  borderRadius: '0.25rem',
  transition:
    'border-color 0.15s ease -in -out, box - shadow 0.15s ease -in -out',
  marginRight: '8px',
});

const ReportModifiedInfoDiv = styled.div({
  position: 'absolute',
  right: '0px',
  bottom: '5px',
  fontSize: '11px',
  //float: 'right',
  display: 'flex',
  height: '22px',
  '> *': {
    marginRight: '6px',
  },
  '> button:last-of-type': {
    marginRight: '0px',
  },
  '& .btn-short': {
    padding: '0.15rem 0.4rem',
  },
});

const ConfigurationBodyDiv = styled.div({
  padding: '15px 1px',
});

const VerticalPaddedFlexedDiv = styled(FlexedDiv)`
  padding-bottom: 8px;
  margin-left: -8px;
`;

const defaultUser: ReportUserVm = {
  id: 0,
  userId: '',
  userEmail: '',
  fullName: '',
  permissionsLevel: 'Viewer',
  recordId: 0,
};

const defaultColumn: ReportColumn = {
  id: 0,
  aggregationFunction: '',
  countDistinct: false,
  displayName: '',
  format: '',
  formula: '',
  order: 0,
  postfix: '',
  prefix: '',
  propertyName: '',
  reportId: 0,
  variables: {},
  isKeyColumn: true,
};

const FormGroupDiv = styled(FGroupDiv)({
  '& div.value-label': {
    lineHeight: '2',
  },
});

export const CustomReportConfiguration = (props) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [editTitle, setEditTitle] = React.useState(false);
  const [editQuery, setEditQuery] = React.useState(false);
  const [savePending, setSavePending] = React.useState(false);
  const [localReport, setLocalReport] = React.useState(
    undefined as CustomReportVm | undefined
  );
  const [showModal, setShowModal] = React.useState(false);
  const [queryId, setQueryId] = React.useState<number | undefined>(undefined);

  const status = useSelector(reportsStatusSelector);
  const dataQueries = useSelector(allQueriesSelector);
  const report = useSelector(loadedReportSelector);
  const query = useSelector(loadedQuerySelector);
  const reportResult = useSelector(reportResultSelector);
  const users = useSelector((s: ApplicationState) => s.global.users);
  const dispatch = useDispatch();

  const usePrevious = (value) => {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousReportState = usePrevious(localReport);

  React.useEffect(() => {
    dispatch(CustomReportActions.getAllQueries());
  }, []);

  React.useEffect(() => {
    setLocalReport(report);
  }, [report]);

  React.useEffect(() => {
    if (localReport) {
      if (query === undefined || query.id !== localReport.dataQueryId) {
        dispatch(CustomReportActions.getQuery(localReport.dataQueryId));
      }
    }
  }, [localReport?.dataQueryId]);

  React.useEffect(() => {
    if (previousReportState !== undefined) {
      setSavePending(true);
    }
  }, [localReport]);

  const _removeColumn = (index: number) => {
    if (localReport) {
      setLocalReport({
        ...localReport,
        columns: localReport.columns.slice().filter((x, i) => i !== index),
      });
    }
  };

  const _removeUser = (index: number) => {
    if (localReport) {
      setLocalReport({
        ...localReport,
        reportUsers: localReport.reportUsers
          .slice()
          .filter((x, i) => i !== index),
      });
    }
  };

  const _run = () => {
    if (report && report.isRunnable) {
      dispatch(CustomReportActions.runReport(report.id));
    }
  };

  const _save = () => {
    if (localReport) {
      dispatch(CustomReportActions.updateReport(localReport));
    }
  };

  const _updateUsers = (user: ReportUserVm, index: number) => {
    if (localReport) {
      if (index === -1) {
        setLocalReport({
          ...localReport,
          reportUsers: localReport.reportUsers.concat([user]),
        });
      } else {
        setLocalReport({
          ...localReport,
          reportUsers: localReport.reportUsers.map((x, i) =>
            i === index ? user : x
          ),
        });
      }
    }
  };

  const _updateColumns = (column: ReportColumn, index: number) => {
    if (localReport) {
      if (index === -1) {
        setLocalReport({
          ...localReport,
          columns: localReport.columns.concat([column]),
        });
      } else {
        setLocalReport({
          ...localReport,
          columns: localReport.columns.map((x, i) =>
            i === index ? column : x
          ),
        });
      }
    }
  };

  const _onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const foundColumn = localReport?.columns.find((x) => x.id == +draggableId);
    if (foundColumn) {
      const newColumns = Array.from(localReport?.columns ?? []);
      newColumns.splice(source.index, 1);
      newColumns.splice(destination.index, 0, foundColumn);

      const newArray = newColumns.map((x, idx) => {
        return { ...x, order: idx + 1 };
      });
      if (localReport) {
        setLocalReport({ ...localReport, columns: newArray });
      }
    }
  };

  if (!localReport || status === 'loading') return <StandardGridLoader />;

  return (
    <>
      {showModal && (
        <ReportQueryForm
          queryId={queryId}
          onClose={() => setShowModal(false)}
        ></ReportQueryForm>
      )}
      <div
        className={cx('custom-report-configuration', collapsed && 'collapsed')}
      >
        {status === 'saving' && <Loader loading={true} />}
        <ReportValidationMessage report={localReport} />
        <ConfigurationHeaderDiv>
          <ReportTitleDiv>
            {!editTitle ? (
              <>
                <span>
                  <h5 style={{ marginBottom: '0px' }}>
                    {localReport.reportName}
                  </h5>
                </span>
                <span
                  className="fas fa-edit"
                  onClick={() => setEditTitle(true)}
                ></span>
              </>
            ) : (
              <>
                <ReportTitleInput
                  defaultValue={localReport.reportName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setLocalReport({
                      ...localReport,
                      reportName: e.currentTarget.value,
                    })
                  }
                />
                <button
                  className="btn btn-x-sm btn-outline-secondary"
                  style={{ marginRight: '3px', height: '2em', lineHeight: '1' }}
                  onClick={() => setEditTitle(false)}
                >
                  Confirm
                </button>
                {/*<button className='btn btn-x-sm btn-outline-secondary' onClick={() => setEditTitle(false)}>Cancel</button>*/}
              </>
            )}
            <ReportModifiedInfoDiv>
              <span style={{ marginTop: '7px' }}>
                Last modified by <b>{localReport.lastModifiedByName}</b> on{' '}
                <b>{formatDate(localReport.lastModifiedTimestamp)}</b>
              </span>
              {localReport.canEdit && savePending && (
                <span
                  className="btn btn-x-sm btn-green btn-short"
                  onClick={_save}
                >
                  Save
                </span>
              )}
              <button
                className="btn btn-x-sm btn-outline-secondary btn-short"
                onClick={_run}
                disabled={!report?.isRunnable}
                title={
                  report?.isRunnable
                    ? 'Run report'
                    : 'Report is not currently runnable. Please check validation messages and correct the report configuration.'
                }
              >
                Run
              </button>
              {report && reportResult && reportResult.data.length ? (
                <TableDataDownloadLink
                  data={reportResult.data}
                  columns={report.columns.map((x) => {
                    return { type: 'string', label: x.displayName };
                  })}
                  filename={report.reportName}
                  className="btn btn-x-sm btn-outline-secondary"
                />
              ) : (
                []
              )}
              <button
                // style={{ position: 'absolute', right: '0px', bottom: '1px' }}
                className={cx(
                  'btn btn-x-sm btn-round btn-background-hover fas',
                  collapsed ? 'fa-chevron-up' : 'fa-chevron-down'
                )}
                onClick={() => setCollapsed(!collapsed)}
              ></button>
            </ReportModifiedInfoDiv>
          </ReportTitleDiv>
        </ConfigurationHeaderDiv>
        <div className="configuration-body custom-scrollbar">
          <ConfigurationBodyDiv>
            <FormGroupDiv>
              <div className="inline-label">
                <b>Created By</b>
              </div>
              <div className="value-label">{localReport.creatorName}</div>
            </FormGroupDiv>
            <FormGroupDiv>
              <div className="inline-label">
                <b>Report Type</b>
              </div>
              <div className="value-label">{localReport.reportTypeName}</div>
            </FormGroupDiv>
            <FormGroupDiv>
              <div className="inline-label">
                <b>Data Query</b>
              </div>
              <FlexedDiv>
                {editQuery ? (
                  <FilterableSelect
                    id="data-query-select"
                    defaultVal={localReport.dataQueryId}
                    items={dataQueries.map(
                      (x) => new ListItem(x.id, x.queryName)
                    )}
                    onChange={(id) => {
                      setLocalReport({
                        ...localReport,
                        dataQueryId: parseInt(id),
                      });
                    }}
                  />
                ) : (
                  <div className="value-label">
                    {localReport.dataQuery?.queryName}
                  </div>
                )}
                &nbsp; &nbsp;
                <button
                  className="btn btn-x-sm btn-outline-secondary"
                  onClick={() => setEditQuery(!editQuery)}
                >
                  {editQuery ? 'Confirm' : 'Change'}
                </button>{' '}
                &nbsp; &nbsp;
                {localReport.dataQueryId !== 0 && (
                  <>
                    <button
                      className="btn btn-x-sm btn-outline-secondary"
                      onClick={() => {
                        setQueryId(localReport.dataQuery?.id);
                        setShowModal(true);
                      }}
                    >
                      Edit Query
                    </button>{' '}
                    &nbsp; &nbsp;
                  </>
                )}
                <button
                  className="btn btn-x-sm btn-blue"
                  onClick={() => {
                    setQueryId(0);
                    setShowModal(true);
                  }}
                >
                  Create New Query
                </button>
              </FlexedDiv>
            </FormGroupDiv>
          </ConfigurationBodyDiv>
          <ConfigurationHeaderDiv>
            <h5>Users</h5>
          </ConfigurationHeaderDiv>
          <ConfigurationBodyDiv>
            <div className="">
              <VerticalPaddedFlexedDiv className="">
                <div className="my-col-4">
                  <b>User</b>
                </div>
                <div className="my-col-4">
                  <b>Permissions</b>
                </div>
              </VerticalPaddedFlexedDiv>
              <div className="sv-grid-body">
                {localReport.reportUsers.map((x, i) => {
                  return (
                    <VerticalPaddedFlexedDiv
                      className=""
                      style={{ cursor: 'default' }}
                      key={i}
                    >
                      <div className="my-col-4">
                        {x.id !== 0 ? (
                          <span style={{ lineHeight: '2.25' }}>
                            {x.fullName}
                          </span>
                        ) : (
                          <FilterableSelect
                            id={`report-user-select_${Math.random()}`}
                            items={users
                              .filter(
                                (u) =>
                                  u.userId === x.userId ||
                                  !localReport.reportUsers.some(
                                    (y) => y.userId === u.userId
                                  )
                              )
                              .map((x) => new ListItem(x.userId, x.fullName))}
                            onChange={(id) =>
                              _updateUsers({ ...x, userId: id }, i)
                            }
                            defaultVal={x.userId}
                          />
                        )}
                      </div>
                      <div className="my-col-4">
                        <select
                          className="form-control"
                          defaultValue={x.permissionsLevel}
                          onChange={(e) =>
                            _updateUsers(
                              { ...x, permissionsLevel: e.currentTarget.value },
                              i
                            )
                          }
                          disabled={!localReport.canEdit}
                        >
                          <option value={'Editor'}>Editor</option>
                          <option value={'Viewer'}>Viewer</option>
                        </select>
                      </div>
                      <div className="my-col-1">
                        {localReport.canEdit &&
                        localReport.reportUsers.length > 1 ? (
                          <span
                            className="btn btn-sm btn-background-hover btn-round fas fa-trash"
                            onClick={() => _removeUser(i)}
                          ></span>
                        ) : (
                          []
                        )}
                      </div>
                    </VerticalPaddedFlexedDiv>
                  );
                })}
              </div>
            </div>
            {localReport.canEdit && (
              <button
                style={{ margin: '0px' }}
                className="btn btn-x-sm btn-blue"
                onClick={() =>
                  _updateUsers({ ...defaultUser, recordId: localReport.id }, -1)
                }
              >
                Add User
              </button>
            )}
          </ConfigurationBodyDiv>
          <ConfigurationHeaderDiv>
            <h5>Columns</h5>
          </ConfigurationHeaderDiv>
          <ConfigurationBodyDiv>
            <div className="">
              <VerticalPaddedFlexedDiv className="">
                <div
                  style={{
                    opacity: 0,
                  }}
                >
                  <DragAndDropDotsIcon />
                </div>
                <div className="my-col-3">
                  <b>Property</b>
                </div>
                <div className="my-col-2">
                  <b>Display Name</b>
                </div>
                <div className="my-col-2">
                  <b>Aggregation Function</b>
                </div>
                <div className="my-col-2">
                  <b>Count Distinct</b>
                </div>
                <div className="my-col-3">
                  <b>Formula</b>
                </div>
                <div className="my-col-3">
                  <b>Format</b>
                </div>
                <div className="my-col-2">
                  <b>Prefix</b>
                </div>
                <div className="my-col-2">
                  <b>Postfix</b>
                </div>
                <div className="my-col-1"></div>
              </VerticalPaddedFlexedDiv>
              <Droppable
                type="ReportColumn"
                onDragEnd={(result) => _onDragEnd(result)}
                droppableId={'local-report-columns'}
              >
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="sv-grid-body"
                  >
                    {localReport.columns
                      .slice()
                      .sort((a, b) => {
                        return a.order > b.order ? 1 : -1;
                      })
                      .map((x, i) => {
                        return (
                          <Draggable
                            draggableId={x.id.toString()}
                            index={i}
                            key={`${i}_${x.id}`}
                          >
                            {(provided) => (
                              <VerticalPaddedFlexedDiv
                                className=""
                                key={`${i}_${x.id}`}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                              >
                                <div
                                  {...provided.dragHandleProps}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <DragAndDropDotsIcon />
                                </div>

                                <div className="my-col-3">
                                  <FilterableSelect
                                    id={`report-user-select_${Math.random()}`}
                                    defaultVal={x.propertyName}
                                    items={
                                      query
                                        ? query.selectFields.map(
                                            (x) =>
                                              new ListItem(
                                                x.propertyName,
                                                `${x.propertyName}`
                                              )
                                          )
                                        : []
                                    }
                                    onChange={(id) =>
                                      _updateColumns(
                                        { ...x, propertyName: id },
                                        i
                                      )
                                    }
                                    readonly={!localReport.canEdit}
                                    growUpwards={true}
                                  />
                                </div>
                                <div className="my-col-2">
                                  <input
                                    className="form-control"
                                    value={x.displayName}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          displayName: e.currentTarget.value,
                                        },
                                        i
                                      )
                                    }
                                    disabled={!localReport.canEdit}
                                  />
                                </div>
                                <div className="my-col-2">
                                  <select
                                    className="form-control"
                                    value={x.aggregationFunction}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          aggregationFunction:
                                            e.currentTarget.value,
                                          formula: '',
                                        },
                                        i
                                      )
                                    }
                                    disabled={!localReport.canEdit}
                                  >
                                    <option
                                      className="form-control"
                                      value={''}
                                    ></option>
                                    <option
                                      className="form-control"
                                      value={'Avg'}
                                    >
                                      Average
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'Count'}
                                    >
                                      Count
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'Max'}
                                    >
                                      Max
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'Min'}
                                    >
                                      Min
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'Sum'}
                                    >
                                      Sum
                                    </option>
                                  </select>
                                </div>
                                <div
                                  className="my-col-2"
                                  style={{ paddingTop: '0.45em' }}
                                >
                                  <SliderCheckbox
                                    onChange={() =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          countDistinct: !x.countDistinct,
                                        },
                                        i
                                      )
                                    }
                                    selected={x.countDistinct}
                                    disabled={x.aggregationFunction !== 'Count'}
                                    fieldName="countDistinct"
                                  />
                                </div>
                                <div className="my-col-3">
                                  <input
                                    className="form-control"
                                    value={x.formula}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          formula: e.currentTarget.value,
                                        },
                                        i
                                      )
                                    }
                                    disabled={
                                      !localReport.canEdit ||
                                      x.aggregationFunction != ''
                                    }
                                  />
                                </div>
                                <div className="my-col-3">
                                  <select
                                    className="form-control"
                                    value={x.format}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          format: e.currentTarget.value,
                                        },
                                        i
                                      )
                                    }
                                    disabled={!localReport.canEdit}
                                  >
                                    <option
                                      className="form-control"
                                      value={''}
                                    ></option>
                                    <option
                                      className="form-control"
                                      value={'C2'}
                                    >
                                      Currency
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'MM/yy'}
                                    >
                                      Date: MM/yy
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'MMM yy'}
                                    >
                                      Date: Month Year
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'MM/dd/yy'}
                                    >
                                      Date: MM/dd/yy
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'MMM dd yyyy'}
                                    >
                                      Date: Month dd yyyy
                                    </option>
                                    <option
                                      className="form-control"
                                      value={'P'}
                                    >
                                      Percentage
                                    </option>
                                  </select>
                                </div>
                                <div className="my-col-2">
                                  <input
                                    className="form-control"
                                    value={x.prefix}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          prefix: e.currentTarget.value,
                                        },
                                        i
                                      )
                                    }
                                    disabled={!localReport.canEdit}
                                  />
                                </div>
                                <div className="my-col-2">
                                  <input
                                    className="form-control"
                                    value={x.postfix}
                                    onChange={(e) =>
                                      _updateColumns(
                                        {
                                          ...x,
                                          postfix: e.currentTarget.value,
                                        },
                                        i
                                      )
                                    }
                                    disabled={!localReport.canEdit}
                                  />
                                </div>
                                <div className="my-col-1">
                                  {localReport.canEdit &&
                                  localReport.columns.length > 1 ? (
                                    <span
                                      className="btn btn-sm btn-background-hover btn-round fas fa-trash"
                                      onClick={() => _removeColumn(i)}
                                    ></span>
                                  ) : (
                                    []
                                  )}
                                </div>
                              </VerticalPaddedFlexedDiv>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            {localReport.canEdit && (
              <button
                style={{ margin: '0px' }}
                className="btn btn-x-sm btn-blue"
                onClick={() =>
                  _updateColumns(
                    {
                      ...defaultColumn,
                      reportId: localReport.id,
                      order: localReport.columns.length + 1,
                    },
                    -1
                  )
                }
              >
                Add Column
              </button>
            )}
          </ConfigurationBodyDiv>
          <ConfigurationBodyDiv></ConfigurationBodyDiv>
        </div>
      </div>
    </>
  );
};

export const ReportValidationMessage = (props: { report: CustomReportVm }) => {
  const { report } = props;

  const { validationMessage } = report;

  if (!validationMessage || validationMessage.length === 0) return <div></div>;

  return (
    <div className="report-validation-message">
      {validationMessage.map((x) => (
        <div>{x}</div>
      ))}
    </div>
  );
};
