import React, { useRef, useState } from 'react';
import Modal from 'src/components/Modal';
import { SafetyKpiTemplate, UpdateObject } from 'src/interfaces/interfaces';
import { SafetyKPITemplateEntriesSection } from './SafetyKPITemplateEntriesSection';
import styled from 'styled-components';

interface SafetyKPITemplateModalProps {
  template: SafetyKpiTemplate;
  onModalClose: () => void;
  onEntryUpdate: (id: number, info: UpdateObject) => void;
  onTemplateUpdate: (id: number, info: UpdateObject) => void;
  onEntryRemove: (id: number) => void;
  onTemplateCopy: (id: number) => void;
}

const ModalBodyDiv = styled.div`
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;
const ModalHeaderDiv = styled.div``;

const StyledInput = styled.input({
  height: 'unset !important',
  width: '350px',
});

const StyledH4 = styled.h4({
  marginBottom: '3px',
});

const StyledDiv = styled.div({
  display: 'flex',
});

export const SafetyKPITemplateModal: React.FC<SafetyKPITemplateModalProps> = ({
  template,
  onModalClose,
  onEntryUpdate,
  onTemplateUpdate,
  onEntryRemove,
  onTemplateCopy,
}) => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Modal>
      <ModalHeaderDiv className="modal-header">
        {!isEditingTitle ? (
          <>
            <StyledDiv>
              <StyledH4>{template.name}</StyledH4>
              <span
                style={{
                  fontSize: 'unset',
                  position: 'unset',
                  marginLeft: '10px',
                }}
                className="fas fa-edit"
                onClick={() => setIsEditingTitle(true)}
              ></span>
            </StyledDiv>
            <span
              onClick={() => onTemplateCopy(template.id)}
              className="btn btn-sm btn-outline-secondary"
              title="Copy Template"
              style={{ fontSize: '12px' }}
            >
              <span className="fa fa-clipboard"></span>
              {' '}
              Copy
            </span>
          </>
        ) : (
          <>
            <StyledInput
              ref={inputRef}
              defaultValue={template.name}
              className="form-control"
            />
            <button
              className="btn btn-sm btn-blue"
              onClick={(e) => {
                onTemplateUpdate(template.id, {
                  fieldName: 'name',
                  value: inputRef.current?.value ?? '',
                });
                setIsEditingTitle(false);
              }}
              style={{
                marginRight: '8px',
                marginLeft: '8px',
              }}
            >
              Save
            </button>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setIsEditingTitle(false)}
            >
              Cancel
            </button>
          </>
        )}
      </ModalHeaderDiv>
      <ModalBodyDiv className="modal-body custom-scrollbar">
        <SafetyKPITemplateEntriesSection
          template={template}
          onEntryUpdate={onEntryUpdate}
          onEntryRemove={onEntryRemove}
        ></SafetyKPITemplateEntriesSection>
      </ModalBodyDiv>
      <div className="modal-footer">
        <span
          className="btn btn-sm btn-outline-secondary"
          onClick={onModalClose}
        >
          Close
        </span>
      </div>
    </Modal>
  );
};
