import * as React from 'react';
import { RFI, RFIQuestionDocument, RFIResponseDocument, RFIResponseStatus } from '../interfaces/interfaces';
import { RFIResponseCard } from './RFIResponseCard';
import * as cx from 'classnames';
import { isImage } from '../helpers/misc';
import { formatDateTime } from '../helpers/formatters';

interface Props {
    id: number
}

export const RFIReissueHistory = (props: Props) => {
    const [rfi, setRfi] = React.useState({} as RFI);
    const [collapsed, setCollapsed] = React.useState(true);

    React.useEffect(() => {
        const getRfi = async () => {
            fetch(`api/RFI/Detail?id=${props.id}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setRfi(data.rfi)
                })
        };
        getRfi();
    }, [])

    return (
        <div className={cx('rfi-reissue-history', collapsed && 'collapsed')}>
            <div className='header'>
                Reissued History
                <span className={cx('toggle-reissue-history fas', collapsed ? 'fa-plus' : 'fa-minus')} onClick={() => setCollapsed(!collapsed)}></span>
            </div>
            {(rfi.id && !collapsed) && 
                <div className='history'>
                    {RenderRfiQuestion(rfi)}
                    {RenderResponseCards(rfi)}
                    {RenderClosingComment(rfi)}
                    {RenderClosingLine(rfi)}
                </div>
            }
        </div>
    )
}

const RenderResponseCards = (rfi: RFI) => {
    const cards = rfi.responses
        .filter(x => x.status === RFIResponseStatus.Reissued)
        .map(x => {
            return (
                <div key={x.id}>
                    <RFIResponseCard response={x} locked={true} isSubmitter={false} />
                </div>
            )
        });

    return <div className='rfi-responses'>{cards}</div>
}

const RenderRfiQuestion = (rfi: RFI) => (
    <div className='rfi-question'>
        <div className='response-name-timestamp'>
            <span>{rfi.userFullName} - {formatDateTime(rfi.date)}</span>
        </div>
        {rfi.description &&
            <div className='rfi-response-body'>
                <div style={{ padding: '0px 45px 10px 0px' }}>{rfi.description}</div>
                {(rfi.questionDocuments && rfi.questionDocuments.length) ? <div className='rfi-doc-icons'>{rfi.questionDocuments.map(x => RenderDocIcon(x))}</div> : []}
            </div>
        }
    </div>
)

const RenderClosingLine = (record: RFI) => {
    const rfiDocs = record.responseDocuments && record.responseDocuments.map(x => RenderDocIcon(x))

    return (
        <div className='rfi-closing-line approved'>
            <span className='col-sm-9'>Accepted on {formatDateTime(record.approvedTimestamp)}</span>
            <div className='rfi-doc-icons'>
                {rfiDocs}
            </div>
        </div>
    )
}

const RenderClosingComment = (record: RFI) => {
    if (record.approvalComment) {
        return (
            <div className='closing-comment'>
                <div className='response-name-timestamp'>
                    <span>{record.userFullName} - {formatDateTime(record.approvedTimestamp)}</span>
                </div>
                <div className='rfi-response-body'>
                    {record.approvalComment}
                </div>
            </div>
        )
    }
    return <div></div>
}

const RenderDocIcon = (doc: RFIResponseDocument | RFIQuestionDocument) => {
    const split = doc.filePath.split('content');
    const relPath = '/files' + split[split.length - 1].replace(/#/g, '%23');
    const slashSplit = relPath.split('/'), fileName = slashSplit[slashSplit.length - 1]
    const src = isImage(fileName) ? '/img/photoIcon.jpg' : '/img/pdficon.png';
    return (
        <a key={doc.id} className='response-doc-icon' href={relPath} target="_blank" title={fileName}>
            <img style={{ height: '25px' }} src={src} /> <br />
            {/*fileName*/}
        </a>
    )
}