import { AxiosError, AxiosResponse } from 'axios';
import {
  DailyFieldReportTemplate,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { api } from '../api';

export const getTemplates = async () => {
  try {
    const response: AxiosResponse<DailyFieldReportTemplate[]> = await api.get(
      `api/DailyFieldReportTemplate/GetTemplates`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const addTemplate = async () => {
  try {
    const response: AxiosResponse<DailyFieldReportTemplate[]> = await api.post(
      `api/DailyFieldReportTemplate/AddTemplate`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const copyTemplate = async (id: number) => {
  try {
    const response: AxiosResponse<DailyFieldReportTemplate[]> = await api.post(
      `api/DailyFieldReportTemplate/CopyTemplate?templateId=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const editTemplate = async (id: number, info: UpdateObject) => {
  try {
    const response: AxiosResponse<DailyFieldReportTemplate> = await api.put(
      `api/DailyFieldReportTemplate/EditTemplate?id=${id}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};
