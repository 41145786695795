import React, { useCallback, useEffect, useState } from 'react';
import {
  ProjectTeamMember,
  PunchItemCommentPostModel,
  PunchItemStatus,
  PunchItemVm,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  PunchlistActions,
  punchItemsLoadingSelector,
  punchItemCCedModalSelector,
  isItemUpdatingSelector,
  punchItemsCategoriesSelect,
} from 'src/store/punchlist';
import { PunchItem } from './PunchItem';
import { ApplicationState } from 'src/store';
import { actionCreators as projectActions } from '../store/projectDetail';
import { StandardGridLoader } from 'src/loaders/StandardGridLoader';
import { CCedUsersModal } from '../components/CCedUsersModal';

export interface ConnectedPunchItemProps {
  item: PunchItemVm;
  isWorkItem?: boolean;
}

export const ConnectedPunchItem: React.FC<ConnectedPunchItemProps> = ({
  item,
  isWorkItem = false,
}) => {
  const dispatch = useDispatch();

  const project = useSelector((s: ApplicationState) => s.detail.detail);
  const punchItemsLoading = useSelector(punchItemsLoadingSelector) == 'loading';
  const punchItemCategories = useSelector(punchItemsCategoriesSelect);
  const ccedModalId = useSelector(punchItemCCedModalSelector);
  const [showWorkRequiredModal, setShowWorkRequiredModal] = useState(false);
  const isUpdating = useSelector(isItemUpdatingSelector);

  //const [showCcedUsersModal, setShowCcedUsersModal] = React.useState(false);

  useEffect(() => {
    if (!project || item.projectId !== project.id) {
      dispatch(projectActions.getDetail(item.projectId));
    }
  }, [project]);

  const onAssigneeUpdate = useCallback(
    (id: number, value: string) => {
      const info: UpdateObject = {
        fieldName: 'assigneeId',
        value: value,
      };
      dispatch(
        PunchlistActions.updatePunchItem({
          id: id,
          updateObject: info,
        })
      );
    },
    [item, dispatch]
  );

  const onUserIdUpdate = useCallback(
    (id: number, value: string) => {
      const info: UpdateObject = {
        fieldName: 'userId',
        value: value,
      };
      dispatch(
        PunchlistActions.updatePunchItem({
          id: id,
          updateObject: info,
        })
      );
    },
    [item, dispatch]
  );

  const onStatusUpdate = useCallback(
    (id: number, value: string) => {
      if (value !== PunchItemStatus.WorkRequired.toString()) {
        const info: UpdateObject = {
          fieldName: 'status',
          value: value,
        };
        dispatch(
          PunchlistActions.updatePunchItem({
            id: id,
            updateObject: info,
          })
        );
      } else {
        setShowWorkRequiredModal(true);
      }
    },
    [item, dispatch]
  );

  const onCommentAdd = useCallback(
    (id: number, value: string) => {
      const commentObject: PunchItemCommentPostModel = {
        id: id,
        comment: value,
      };
      dispatch(PunchlistActions.addComment(commentObject));
    },
    [item, dispatch]
  );

  const onWorkRequiredUpdate = useCallback(
    (id: number, value: string) => {
      onCommentAdd(item.id, value);
      dispatch(
        PunchlistActions.updatePunchItem({
          id: id,
          updateObject: {
            fieldName: 'status',
            value: PunchItemStatus.WorkRequired.toString(),
          },
        })
      );
      //onStatusUpdate(id, PunchItemStatus.WorkRequired.toString());
      setShowWorkRequiredModal(false);
    },
    [item, dispatch]
  );

  const onUpdate = useCallback(
    (id: number, field: string, value: string) => {
      const info: UpdateObject = {
        fieldName: field,
        value: value,
      };
      dispatch(
        PunchlistActions.updatePunchItem({
          id: id,
          updateObject: info,
        })
      );
    },
    [item, dispatch]
  );
 
  const onFileAdd = useCallback(
    (id: number, files: FileList | null) => {
      if (files) {
        dispatch(
          PunchlistActions.addPhoto({
            id: id,
            files: files,
          })
        );
      }
    },
    [item, dispatch]
  );

  const onPhotoRotate = useCallback(
    (id: number, clockwise: boolean) => {
      dispatch(PunchlistActions.rotatePhoto({ id, clockwise }))
    },
    [item, dispatch]
  )

  const onPhotoDelete = useCallback(
    (id: number) => {
      dispatch(PunchlistActions.removePhoto(id));
    },
    [item, dispatch]
  );

  const onCCModalToggle = useCallback(
    (id: number | null) => {
      dispatch(PunchlistActions.toggleCCedModal(id));
    },
    [item, dispatch]
  );

  return (
    <>
      {project.teamMembers && !punchItemsLoading ? (
        <>
          <PunchItem
            key={item.id}
            item={item}
            categories={punchItemCategories}
            users={project.teamMembers}
            onAssigneeUpdate={onAssigneeUpdate}
            onUserIdUpdate={onUserIdUpdate}
            onStatusUpdate={onStatusUpdate}
            onCommentAdd={onCommentAdd}
            onUpdate={onUpdate}
            onPhotoAdd={onFileAdd}
            onPhotoDelete={onPhotoDelete}
            onPhotoRotate={onPhotoRotate}
            isWorkItem={isWorkItem}
            ccedModalId={ccedModalId}
            onCCModalToggle={onCCModalToggle}
            showWorkRequiredModal={showWorkRequiredModal}
            onWorkRequiredUpdate={onWorkRequiredUpdate}
            onWorkRequiredModalClose={() => setShowWorkRequiredModal(false)}
            isLoading={isUpdating}
          />
        </>
      ) : (
        <>
          <StandardGridLoader
            rowContentHeight={350}
            rowCount={1}
            rowPadding={4}
          />
          <StandardGridLoader
            rowContentHeight={70}
            rowCount={1}
            rowPadding={3}
          />
        </>
      )}
    </>
  );
};
