import * as React from 'react';

import { Modal2 as Modal } from '../components/Modal';
import { TransmittalStatus } from './TransmittalGrid';
import { TransmittalDetail } from './TransmittalDetail';

interface Props {
    projectId: number | null,
    transmittalId: number | null,
    startingDir: string,
    close: () => void
}

export interface TransmittalPostModel {
    title: string;
    projectId: number | null;
    message: string;
    recipientIds: string[];
    documentPaths: string[];
    send: boolean;

    sentDateTime: string | null;
    userId: string;
    sendable: boolean;
    status: TransmittalStatus;
    allowComments: boolean;
    forApproval: boolean;
}

export const TransmittalDetailModal = (props: Props) => {    

    return (
        <Modal modalClass='transmittal-modal'>
            <TransmittalDetail
                transmittalId={props.transmittalId}
                projectId={props.projectId}
                close={props.close}
                startingDir={props.startingDir}
                inModal={true}
            />
        </Modal>
    )
}