
import * as React from 'react';
import { Modal2 } from '../components/Modal';
import { UnvettedPartner, UnvettedPartnerStatus } from '../interfaces/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { partnerRegKeySelector } from '../store/keyvault';
import { TabContent, TabLink, Tabs } from '../tabs';
import { actionCreators as globalActions } from '../store/global';
import { F40Detail, F40FormGroup, F40FormRow, F40FormSection } from './F40Detail';
import { UnvettedPartnerLinks } from './UnvettedPartnerLinks';
import { PartnerRegistrationUrl } from './PartnerRegistrationConstants';
import { FinancialsFormComponent } from './FinancialInfo';
import { KnownRoles, hasRole } from '../auth/auth';
import { VendorsActions } from '../store/vendors/index';
import { ApplicationState } from '../store/index';
import { Loader } from '../components/Loader';

export const UnvettedParterDetail = (props: { partnerId: number, close: () => void }) => {

  const [partner, setPartner] = React.useState(null as UnvettedPartner | null);
  const [loading, setLoading] = React.useState(false);
  const [showMoveModal, setShowMoveModal] = React.useState(false);
  const apiKey = useSelector(partnerRegKeySelector);
  const userStore = useSelector((s: ApplicationState) => s.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.partnerId) _getPartner(props.partnerId);
  }, [props.partnerId])

  const _getPartner = (id: number) => {
    fetch(`${PartnerRegistrationUrl}/api/subform/FormExternal?vendorId=${id}`, {
      //method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiKey}`
      }
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setPartner(data);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }

  const _finalizeRegistration = () => {
    setLoading(true);
    fetch(`api/Vendor/AddFromRegistration?id=${props.partnerId}`, { method: 'POST' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          dispatch(VendorsActions.getVendorsAndSubs());
          _sendFinalizationToAccounting();
          dispatch(globalActions.updateMessage(`Registration for ${partner?.vendorName} finalized.`));
          props.close();
          setLoading(false);
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
          setLoading(false);
        }
      })
  }

  const _sendFinalizationToAccounting = () =>
  {
    const url = `${PartnerRegistrationUrl}/api/subform/EmailFinalization?vendorId=${props.partnerId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      },
    })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          return;
        }
        else {
          dispatch(globalActions.updateMessage(data.message));
        }
      })
  }


  return <Modal2 dialogStyle={{ maxWidth: '85%' }}>
    <Loader loading={loading} />
    <div className='modal-header'><h4>{partner ? partner.vendorName : ''}</h4></div>
    <div className='modal-body custom-scrollbar' style={{ maxHeight: '78vh', overflowY: 'auto' }}>
      {partner && <UnvettedPartnerLinks partnerId={props.partnerId} links={partner.sentLinks} />}
      {showMoveModal && <Modal2 dialogStyle={{ marginTop: '38px' }}>
        <div className='modal-header'><h5 style={{ marginBottom: '0px' }}>{partner?.vendorName} Registration Completion</h5></div>
        <div className='modal-body'>
          <div style={{ fontSize: '13px' }}>Are you sure you want to move <b>{partner?.vendorName}</b> to the official subcontractors list? This will remove them from the registration process.</div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-sm btn-green' onClick={_finalizeRegistration}>Confirm</button>
          <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowMoveModal(false)}>Close</button>
        </div>
      </Modal2>}
      <Tabs name='vendor-tabs' onChange={(t: string) => { }}
        renderActiveTabContentOnly={true}>
        <div className='tab-links left-align'>
          <TabLink to='general' component='span'><span>General Info</span></TabLink>
          {/*<TabLink to='list-2' component='span'><span>Subcontractors</span></TabLink>*/}
          <TabLink to='f40' component='span'><span>CSQF / F40</span></TabLink>
          <TabLink to='financials' component='span'><span>Financials</span></TabLink>
        </div>
        <div className='content'>
          <TabContent for='general'>
            {partner ? <UnvettedPartnerGeneralInfo partner={partner} /> : <h4 style={{ textAlign: 'center' }}>No detail record found</h4>}
          </TabContent>
          <TabContent for='f40'>
            {partner ? <F40Detail partner={partner} refresh={() => _getPartner(partner?.id)} /> : <h4 style={{ textAlign: 'center' }}>No detail record found</h4>}
          </TabContent>
          <TabContent for='financials'>
            {partner ? <FinancialsFormComponent partner={partner} refresh={() => _getPartner(partner?.id)} /> : <h4 style={{ textAlign: 'center' }}>No detail record found</h4>}
          </TabContent>
        </div>
      </Tabs>
    </div>
    <div className='modal-footer'>
      {(hasRole(userStore.user, [KnownRoles.SubsAdmin]) && partner?.status === UnvettedPartnerStatus.AllFormsReviewed) ? <button className='btn btn-sm btn-green' onClick={() => setShowMoveModal(true)}>Finalize Registration</button> : []}
      <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
    </div>
  </Modal2>
}

const UnvettedPartnerGeneralInfo = (props: { partner: UnvettedPartner }) => {
  const { partner } = props;

  const w9 = partner.attachments.find(x => x.type === 'W9');

  return <div className='f40-form'>
    <F40FormSection title='General Information'>
      <F40FormRow>
        <F40FormGroup label='Company Name' width='100%'>
          <input disabled value={partner.vendorName} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label='Address' width='33%'>
          <input disabled value={partner.physicalAddress} /></F40FormGroup>
        <F40FormGroup label='City' width='33%'>
          <input disabled value={`${partner.physicalCity}`} /></F40FormGroup>
        <F40FormGroup label='State/Province' width='33%'>
          <input disabled value={`${partner.physicalState}`} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label='Zip/Postal Code' width='33%'>
          <input disabled value={partner.physicalZip} /></F40FormGroup>
        <F40FormGroup label='Country' width='33%'>
          <input disabled value={partner.physicalCountry} /></F40FormGroup>
        <F40FormGroup label='Phone' width='33%'>
          <input disabled value={partner.businessPhone} /></F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <p><b>Market Sectors</b></p>
        <F40FormGroup label='Distributed Energy' width='33%'>
          <input type='checkbox' disabled name='distributedEnergyMarket' checked={partner.distributedEnergyMarket} />
        </F40FormGroup>
        <F40FormGroup label='EV' width='33%'>
          <input disabled type='checkbox' name='evMarket' checked={partner.evMarket} />
        </F40FormGroup>
        <F40FormGroup label='Retail/Commercial' width='33%'>
          <input disabled type='checkbox' name='retailMarket' checked={partner.retailMarket} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label='EVITP Certified' width='33%'>
          <input disabled type='checkbox' name='evitpCertified' checked={partner.evitpCertified} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <b>Trades</b>
        <ul>
          {partner.trades.map(x => (
            <li>{x.tradeName}</li>
          ))}
        </ul>
      </F40FormRow>
      <F40FormRow>
        <b>Service Areas</b>
        <ul>
          {partner.serviceAreas.map(x => (
            <li>{x}</li>
          ))}
        </ul>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label="Contact Name" width={'33%'}>
          <input disabled value={partner.contactName} />
        </F40FormGroup>
        <F40FormGroup label="Contact Email" width={'33%'}>
          <input disabled value={partner.contactEmail} />
        </F40FormGroup>
        <F40FormGroup label="Contact Title" width={'33%'}>
          <input disabled value={partner.contactTitle} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label="Business Phone" width={'33%'}>
          <input disabled value={partner.businessPhone} />
        </F40FormGroup>
        <F40FormGroup label="Alternative Phone" width={'33%'}>
          <input disabled value={partner.alternativePhone} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label="Years In Business" width={'33%'}>
          <input disabled value={partner.yearsInBusiness} />
        </F40FormGroup>
        <F40FormGroup label="# of Employees" width={'33%'}>
          <input disabled value={partner.numberOfEmployees} />
        </F40FormGroup>
        <F40FormGroup label="Do you have a written Safety Policy" width={'33%'}>
          <input disabled type='checkbox' checked={partner.hasSafetyPolicy} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup noFlex width={'33%'}>
          <div style={{ display: 'inline-block', width: '100%' }}><b>Mod Rate Last 3 Years</b></div>
          <input disabled value={partner.modRate3Years[0]} style={{ display: 'inline-block', marginBottom: '7px' }} />
          <input disabled value={partner.modRate3Years[1]} style={{ display: 'inline-block', marginBottom: '7px' }} />
          <input disabled value={partner.modRate3Years[2]} style={{ display: 'inline-block', marginBottom: '7px' }} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label="Vendor Type" width={'33%'}>
          <input disabled value={partner.vendorType} />
        </F40FormGroup>
        <F40FormGroup label="Labor Status" width={'33%'}>
          <input disabled value={partner.union} />
        </F40FormGroup>
        <F40FormGroup label="General Description of Services" width={'33%'}>
          <textarea disabled value={partner.notes} rows={3} />
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <F40FormGroup label="W9" width={'100%'} noFlex>
          {w9 ? <div><a href={convertFilePathToPartnerRegistrationSite(w9.filePath)} target="_blank">{w9.fileName}</a></div> : 'No W9 found'}
        </F40FormGroup>
      </F40FormRow>
      <F40FormRow>
        <div className="">
          <div style={{ width: '100%' }}>
            <strong>Federal Certifications</strong>
            <br /><br/>
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Small Business Enterprise (SBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Small Business Enterprise (SBE)</label>
              {federalCertLink(partner, 'SBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Minority Owned Business Enterprise (MBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Minority Owned Business Enterprise (MBE)</label>
              {federalCertLink(partner, 'MBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Woman Owned Business Enterprise (WBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Woman Owned Business Enterprise (WBE)</label>
              {federalCertLink(partner, 'WBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Veteran Owned Business Enterprise (VBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Veteran Owned Business Enterprise (VBE)</label>
              {federalCertLink(partner, 'VBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'People with Disabilities (USBLN)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>People with Disabilities (USBLN)</label>
              {federalCertLink(partner, 'USBLN')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'HUB Zone Business Enterprise (HZBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>HUB Zone Business Enterprise (HZBE)</label>
              {federalCertLink(partner, 'HZBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Small Disadvantaged Busniess Enterprise (DBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Small Disadvantaged Busniess Enterprise (DBE)</label>
              {federalCertLink(partner, 'DBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>
              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Service-Disabled Veteran Owned Business Enterprise (DVBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Service-Disabled Veteran Owned Business Enterprise (DVBE)</label>
              {federalCertLink(partner, 'DVBE')}
            </div>
            <br />
            <div style={{ display: 'inline-flex', alignItems: 'baseline', width: '100%' }}>

              <input
                type="checkbox"
                disabled
                checked={
                  partner
                    ? partner.federationCertifications.includes(
                      'Lesbian, Gay, Bisexual and/or Transgender Owned Business Enterprise (LGBTBE)'
                    )
                    : false
                }
              />
              &nbsp; <label className='cert-label'>Lesbian, Gay, Bisexual and/or Transgender Owned Business
              Enterprise (LGBTBE)</label>
              {federalCertLink(partner, 'LGBTBE')}
            </div>
            <br />
          </div>
        </div>
      </F40FormRow>
    </F40FormSection>
  </div>

}

export const convertFilePathToPartnerRegistrationSite = (path: string) => {
  let split = path.split('wwwroot/wwwroot/');
  if (split.length === 1) split = path.split('wwwroot\\wwwroot\\');
  if (split.length === 1) return path;

  return `http://partner-registration.core-states.com/${split[1].replace('\\', '/')}`;
}

const federalCertLink = (partner: UnvettedPartner, certType: string) => {
  const file = partner.attachments.find(x => x.type === certType);

  if (file) {
    return <div><a href={convertFilePathToPartnerRegistrationSite(file.filePath)} target="_blank">{file.fileName}</a></div>
  }

  return [];
}