import * as React from 'react';
import { connect } from 'react-redux';
import * as AdminStore from '../../store/admin'
import { MessageBox } from '../../components/MessageBox';
import { ApplicationState } from '../../store';
import { check_phone_format } from '../../helpers/formatters';

type Props =
    & AdminStore.Store
    & typeof AdminStore.actionCreators

class NewClientForm extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props)
        this.state = {
            createClientUser: false
        }
    }

    _save = () => {
        const modalBody = document.querySelector('div#new-client-form') as HTMLDivElement
        if (modalBody) {
            let valid = true;
            const inputs = modalBody.querySelectorAll('input');
            if (!inputs[0].value || !inputs[0].value.length) {
                alert('Client Name cannot be empty')
                valid = false
            }
            if (!inputs[1].value || inputs[1].value.length != 3) {
                alert('Client Code must be three characters long')
                valid = false
            }
            if (valid) {
                if (this.state.createClientUser) {
                    this.props.addClientWithUser(inputs[0].value, inputs[1].value, inputs[3].value,
                        inputs[4].value, inputs[5].value)
                    //this.props.close()
                }
                else {
                    this.props.addClient(inputs[0].value, inputs[1].value)
                }
            }
        }
    }

    _toggleCreateClientUser = () => {
        this.setState({
            createClientUser: !this.state.createClientUser
        })
    }

    render() {
        return (
            <div className='config-form add-client'>
                <MessageBox message={this.props.message} clearMessage={this.props.clearMessage} />
                <div className="form-header">
                    <h4 className="modal-title">Add New Client</h4>
                </div>
                <div className="form-body" id="new-client-form">
                    <div className='form-group'>
                        <label>Name</label>
                        <input name='name' className='form-control' />
                    </div>
                    <div className='form-group'>
                        <label>Code</label>&nbsp;<small>Used for auto-generating project numbers</small>
                        <input name='code' maxLength={3} className='form-control' />
                    </div>
                    <div className='form-group'>
                        <label>Create Associated User? </label> &nbsp;
                        <input name='createUser' type='checkbox' defaultChecked={this.state.createClientUser}
                            onChange={this._toggleCreateClientUser}
                        />
                    </div>
                    {this.state.createClientUser &&
                        <div>
                            <div className='form-group'>
                                <label>Contact Name</label>
                                <input name='contactName' className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>Contact Email</label>
                                <input name='contactEmail' className='form-control' />
                            </div>
                            <div className='form-group'>
                                <label>Phone Number</label>
                                <input type='tel' name='phone' className='form-control' onKeyUp={check_phone_format} />
                            </div>
                        </div>
                    }
                </div>

                <div className="form-footer to-bottom">
                    {this.state.createClientUser &&
                        <p className='warning-note'>NOTE: Adding a new client registers a new user with the supplied contact information. Registering a user sends them a confirmation email from a no-reply address. It's advised that you reach out
                        to them so they understand that the email is trusted and that they don't miss it should it end up in their spam folder.</p>
                    }
                    <span className="btn btn-sm btn-green" onClick={this._save}>Save</span>
                </div>
            </div>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.admin,
    AdminStore.actionCreators
// @ts-ignore
)(NewClientForm)