import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { StandardGridLoader } from './StandardGridLoader';

export const ProjectDetailLoader = () => (
    <div className='project-detail'>
        <SectionHeaderLoader />
        <DetailSectionLoader />
        <br />
        <SectionHeaderLoader />
        <StandardGridLoader rowContentHeight={25} />
    </div>
)

export const ProjectNumberLoader = () => (
    <ContentLoader
        speed={2}
        interval={0}
        gradientRatio={0.5}
        width={300}
        height={35}
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >
        <rect x='0' y='0' rx='2' ry='2' width='300' height='35' />
    </ContentLoader>    
)

export const DetailSectionLoader = () => (
    <div style={{ display: 'flex' }}>
        <div className='row detail-form col-sm-9 flex-row'>
            {Array.from({ length: 18 }, (v, k) => k + 1).map(x => <DetailLineLoader key={x} />)}
        </div>
        <div className='col-sm-3 mobile-full-width'>
            <ContentLoader
                speed={2}
                interval={0}
                gradientRatio={0.5}
                height={500}
                width={'100%'}
                backgroundColor="#ddd"
                foregroundColor="#eee"
            >
                <rect x='0' y='0' rx='2' ry='2' width='100%' height='209' />
                <rect x='0' y='230' rx='2' ry='2' width='100%' height='209' />
            </ContentLoader>
        </div>
    </div>
)

const DetailLineLoader = () => (
    <div className='form-group'>
        <ContentLoader
            speed={2}
            interval={0}
            gradientRatio={0.5}
            height={40}
            width={'100%'}
            backgroundColor="#ddd"
            foregroundColor="#eee"
        >
            <rect x='0' y='0' rx='2' ry='2' width='100%' height='35' />
        </ContentLoader>
    </div>
)

const SectionHeaderLoader = () => (

    <ContentLoader
        speed={2}
        interval={0}
        gradientRatio={0.5}
        height={64}
        width={'100%'}
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >
        <rect x='0' y='0' width='100%' height='44' />
    </ContentLoader>
)

