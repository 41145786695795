import { AxiosError, AxiosResponse } from 'axios';
import { PunchlistTemplateVm, UpdateObject } from 'src/interfaces/interfaces';
import { api } from '../api';

export const getTemplates = async () => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm[]> = await api.get(
      `api/PunchlistTemplate/GetTemplates`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const addTemplate = async () => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm[]> = await api.post(
      `api/PunchlistTemplate/AddTemplate`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const copyTemplate = async (templateId: number) => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm[]> = await api.post(
      `api/PunchlistTemplate/CopyTemplate?templateId=${templateId}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const updateTemplate = async (
  templateId: number,
  info: UpdateObject
) => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm> = await api.put(
      `api/PunchlistTemplate/UpdateTemplate?templateId=${templateId}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const addTemplateItem = async (templateId: number) => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm> = await api.post(
      `api/PunchlistTemplate/AddTemplateItem?templateId=${templateId}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const updateTemplateItem = async (id: number, info: UpdateObject) => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm> = await api.put(
      `api/PunchlistTemplate/UpdateTemplateItem?id=${id}`,
      info
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};

export const removeTemplateItem = async (id: number) => {
  try {
    const response: AxiosResponse<PunchlistTemplateVm> = await api.delete(
      `api/PunchlistTemplate/RemoveTemplateItem?id=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (err: any) {
    const error = err as AxiosError;

    if (error.response && error.response.data) {
      if ('message' in error.response.data) {
        return error.response.data['message'] as string;
      } else {
        return error.response.data as string;
      }
    }
  }
};
