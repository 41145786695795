import * as React from 'react';
import * as cx from 'classnames';

import { ExplorerItemType } from '../interfaces/enums';
import Modal from '../components/Modal';
import { distinct } from '../helpers/formulas';


interface FileSelectorItem {
    path: string,
    name: string,
    type: ExplorerItemType,
    expanded: boolean,
    items: FileSelectorItem[]
}

interface FileSelectorProps {
    startingDir: string,
    selectedFiles: string[],
    updateSelected: (s: string[]) => void,
    close: () => void
}

export const TransmittalFileSelector = (props: FileSelectorProps) => {

    const [items, setItems] = React.useState([] as FileSelectorItem[])
    const [checkedFolders, setCheckedFolders] = React.useState([] as string[])

    const _folderSelectAll = (folderPath: string, select: boolean) => {
        let newSelected: string[] = [];

        const selectedFolder = findFolder(items, folderPath);

        if (selectedFolder) {
            newSelected = selectedFolder.items.filter(x => x.type === ExplorerItemType.File).map(x => x.path);

            if (select) {
                props.updateSelected(props.selectedFiles.concat(newSelected).filter(distinct))
                setCheckedFolders(checkedFolders.concat([folderPath]));
            } else {
                props.updateSelected(props.selectedFiles.filter(x => newSelected.indexOf(x) === -1))
                setCheckedFolders(checkedFolders.filter(x => x !== folderPath))
            }
        }
    }

    const _getItems = (path: string) => {
        fetch(`api/fileexplorer/get?subPath=${path}`)
            // @ts-ignore
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    if (items.length === 0) setItems(data.items)
                    else {
                        const newItems = recursiveUpdate(items, path, 'items', data.items);
                        setItems(newItems);
                    }
                }
            })
    }

    const _toggleExpand = (folder: FileSelectorItem) => {
        if (!folder.expanded && (folder.items === undefined || folder.items.length === 0)) {
            _getItems(folder.path);
        }
        const newItems = recursiveUpdate(items, folder.path, 'expanded', !folder.expanded)
        setItems(newItems);
    }

    const _toggleSelect = (itemPath: string, select: boolean) => {
        if (select) {
            props.updateSelected(props.selectedFiles.concat([itemPath]))
        } else {
            props.updateSelected(props.selectedFiles.filter(x => x !== itemPath));
        }
    }

    React.useEffect(() => {
        _getItems(props.startingDir);
    }, [props.startingDir])

    const recursiveMapToJSX = (items: FileSelectorItem[], iter: number) => {
        const jsx: JSX.Element[] = items ? items.map(item => {
            // @ts-ignore
            const checked = props.selectedFiles.findIndex(x => x === item.path) !== -1 || checkedFolders.findIndex(x => x === item.path) !== -1;
            const line = item.type === ExplorerItemType.File ?
                <div>
                    <div className='item-row' key={checked.toString()}>
                    <input type='checkbox' defaultChecked={checked} onChange={() => _toggleSelect(item.path, !checked)} />
                    <span className='fas fa-file'></span>
                        <span>{item.name}</span>
                    </div>
                </div>
                :
                <div>
                    <div className='item-row'>
                        <span className={cx('fas', item.expanded ? 'fa-chevron-down' : 'fa-chevron-right')} onClick={() => _toggleExpand(item)}></span>
                        {(item.expanded || (item.items && item.items.length > 0)) ? <input type='checkbox' defaultChecked={checked} onChange={() => _folderSelectAll(item.path, !checked)} /> : []}
                        <span className='fas fa-folder-open'></span>
                        <span>{item.name}</span>
                    </div>
                    {item.expanded ? recursiveMapToJSX(item.items, iter+1) : []}
                </div>
            return <div key={item.path} className='selector-line' style={{ marginLeft: `${iter === 0 ? 0 : 20}px` }}>{line}</div>

        }) : [];
        return jsx;
    }

    return <Modal modalClass='transmittal-file-selector triple-top-margin'>
        <div className='modal-header'><h5>Select Transmittal Files</h5></div>
        <div className='modal-body'>
            {recursiveMapToJSX(items, 0)}
        </div>
        <div className='modal-footer'>
            <button className='btn btn-sm btn-outline-primary' onClick={props.close}>Done</button>
            <button className='btn btn-sm btn-outline-secondary' onClick={() => { props.updateSelected([]); props.close() }}>Clear All</button>
        </div>
    </Modal>
}

/*
 * Recursively search through all items, find the targeted one and set the targeted property value 
 */
const recursiveUpdate = (items: FileSelectorItem[], itemPath: string, prop: string, value: any) => {
    items = items.map(x => {
        if (x.path == itemPath) x[prop] = value;
        else if (x.items && x.items.length) x.items = recursiveUpdate(x.items, itemPath, prop, value);
        return x;
    })

    return items;
}

/*
 * Recursively search for the selected folder given the folder path
 */
export const findFolder = (items: FileSelectorItem[], folderPath: string): FileSelectorItem | null => {
    // @ts-ignore
    const folder = items.find(x => folderPath.indexOf(x.path) !== -1);
    if (folder) {
        if (folder.path === folderPath) return folder
        else return findFolder(folder.items, folderPath)
    }
    return null
}