import * as React from 'react'
import { helpers, K_HINT_HTML_DEFAULT_Z_INDEX }  from './mapHelpers';
import cx from 'classnames';
import { getLuminance } from '../helpers/formulas';

export interface Marker {
    key: string,
    title: string,
    address: string,
    description?: string,
    url?: string,
    lat: number,
    lng: number
}

interface MarkerProps {
    //marker: Marker,
    key: string,
    id: string | number,
    lat: number,
    lng: number,
    label: string,
    bgColor: string,
    $hover?: boolean,
    $dimensionKey?: any,
    $getDimensions?: any,
    scale?: number,
    size?: any,
    image?: string,
    onHoverStateChange?: any,
    onCloseClick?: any,
    onMouseAllow?: any
    navigate?: (id: any) => void
    hoveredAtTable?: boolean
    zoom: number
}

export default class SimpleMarker extends React.Component<MarkerProps, any> {
    constructor(props: MarkerProps) {
        super(props);
    }

    private _toDetail = (e: React.FormEvent<HTMLDivElement>) => {
        if (this.props.navigate)
            this.props.navigate(this.props.id || 0)
    }

    render() {        
        const { bgColor, $hover } = this.props;
        
        const hoverClass = $hover === true || this.props.hoveredAtTable ?
            'marker-hover' : ''

        const bgLuminance = getLuminance(bgColor), fontColor = bgLuminance > 186 ? '#000000' : '#ffffff'

        const imageStyle = {            
            backgroundColor: bgColor,
            color: fontColor
        };

        const zIndexStyle = {
            zIndex: Math.round((this.props.scale || 1) * 10000) + (this.props.$hover ? K_HINT_HTML_DEFAULT_Z_INDEX : 0)
        };

        const pointerStyles = {
            borderColor: `transparent transparent transparent ${bgColor}`
        }

        const markerStyle = helpers.calcMarkerMarkerStyle(this.props.scale || 1, zIndexStyle, {}, imageStyle)

        const labelText = this.props.label ?
            this.props.label.length > 19 ?
                this.props.label.substr(0, 17) + '...' : this.props.label :
            'Unknown';

        return (
            <div style={markerStyle} className={cx('simple-marker tri-right btm-left', hoverClass)} onClick={this._toDetail}>
                <div>
                    {labelText}
                </div>
                <span className='marker-pointer btm-left' style={pointerStyles}></span>
            </div>
        )
    }
}