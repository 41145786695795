import { createSelector } from "@reduxjs/toolkit";
import { ApplicationState } from "..";
import { KeyVaultState } from "./keyvault.reducer";

const keyvaultState = (
  state: ApplicationState
): KeyVaultState => state.keyvault;

export const partnerRegKeySelector = createSelector(
  keyvaultState,
  (state) => state.partnerRegKey
);
