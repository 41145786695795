import * as React from 'react';
import { Loader } from './Loader';
import { MessageBox } from './MessageBox';
import HoverImagePreview from './HoverImagePreview';
import Modal from './Modal';
import PhotoCarousel from './PhotoCarousel';
import { FolderSubscriber } from './FolderSubscriber';
import { FileExplorerLoader } from '../loaders/FileExplorerLoader';
import { FileDownloader } from './FileDownloader';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import { FileShareModal } from './FileShareModal';
import {
  FolderPermissionLevel,
  FolderPermissionsModal,
} from './FolderPermissionsModal';
import styled from 'styled-components';

const FileExplorerPaths = Object.freeze({
  Get: 'api/fileexplorer/get',
  Upload: 'api/fileexplorer/upload',
  Add: 'api/fileexplorer/addfolder',
  Edit: 'api/fileexplorer/editfolder',
  Delete: 'api/fileexplorer/deleteitem',
  MoveItem: 'api/fileexplorer/moveitem',
  CopyItem: 'api/fileexplorer/copy',
});

interface Props {
  startingDir: string;
  homeDir?: string;
  breadCrumbStartLevel: number;
  canSubscribeUsers: boolean;
  params: any;
  type?: FileExplorerType;
  canOpenWindow?: boolean;
  onOpenWindow?: () => void;
  isInModal?: boolean;
}

export enum FileExplorerType {
  Global,
  Project,
  Client,
  Vendor,
}

interface State {
  items: FileExplorerItem[];
  permissions: FolderPermissionLevel;
  subPath?: string;
  searchView: boolean;
  previewPath?: string;
  subscribeFolderPath?: string; // used for rendering the modal component for subscribing others to a folder
  permissionFolderPath?: string;
  addToFolderName?: string;
  editFolderName?: string;
  deleteItemName?: string;
  hoveredName?: string;
  copiedForMovingItem?: FileExplorerItem;
  showMovingModal: boolean;
  carouselIndex?: number;
  sharePath?: string;
  loading: boolean;
  saving: boolean;
  message?: string;
}

export interface FileExplorerItem {
  name: string;
  path: string;
  type: ExplorerItemType;
  permissions: FolderPermissionLevel;
  isUserSubscribed: boolean;
}

export enum ExplorerItemType {
  File,
  Folder,
}

const lockedFolders = [
  'Field Reports',
  'Milestones',
  'RFI-',
  'SUB-',
  'Checklists',
];
const extendedLockFolders = lockedFolders.concat([
  'RFIs',
  'Submittals',
  'Procurement',
  'Quotes',
]);

const AddWindowButton = styled.button`
  margin-right: 16px;
`;

const AddFolderButton = styled.button`
  margin-right: 12px;
  border-width: 1px;
  border-color: gray;
`;

const FileExplorerActionsDiv = styled.div({
  float: 'right',
  '& i': {
    marginRight: '15px',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
});

const ExplorerContainerDiv = styled.div<{ isInModal: boolean | undefined }>(
  (props) => ({
    width: props.isInModal ? '100%' : 'calc(100% - 235px)',
    padding: props.isInModal ? undefined : '15px',
  })
);

const FileExplorerDiv = styled.div<{ isInModal: boolean | undefined }>(
  (props) => ({
    border: props.isInModal ? 'unset' : undefined,
    padding: props.isInModal ? 0 : undefined,
  })
);

/**
 * @deprecated
 */
export default class FileExplorer extends React.Component<Props, State> {
  superUserPmComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CoreSuperUser,
    KnownRoles.CSGPM,
  ]);

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      permissions: FolderPermissionLevel.None,
      subPath: this.props.startingDir,
      searchView: false,
      showMovingModal: false,
      loading: false,
      saving: false,
    };
  }

  homeDir =
    this.props.homeDir !== undefined
      ? this.props.homeDir
      : this.props.startingDir;

  componentDidMount() {
    this._searchItems(this.props.startingDir);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.startingDir != this.props.startingDir ||
      prevProps.breadCrumbStartLevel != this.props.breadCrumbStartLevel
    ) {
      this._searchItems(this.props.startingDir);
    }
  }

  _buildUrl = (path, params, subPath) => {
    let url = path + '?';
    const qString: any[] = [];
    Object.keys(params).forEach((key: any) => {
      if (Array.isArray(params[key])) {
        params[key].forEach((val: any) =>
          qString.push(encodeURIComponent(key) + '=' + encodeURIComponent(val))
        );
      } else
        qString.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        );
    });
    url = url + qString.join('&');
    if (subPath) url = url + `&subPath=${subPath}`;

    return url;
  };

  _searchItems = (subPath?: string, searchText?: string) => {
    this.setState({ loading: true, subPath: subPath });
    let url = this._buildUrl(FileExplorerPaths.Get, {}, subPath);
    if (searchText) url = url + `&searchText=${searchText}`;
    fetch(url, { credentials: 'same-origin' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const searchView = searchText !== undefined && searchText.length > 0;
          this.setState({
            items: data.items,
            permissions: data.permissions,
            loading: false,
            searchView: searchView,
          });
        } else this.setState({ loading: false, message: data.message });
      });
  };

  _filterItems = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value;

    this._searchItems(this.state.subPath, searchText);
  };

  _crumbNav = (path: string) => {
    this._searchItems(path);
    const searchInput = document.querySelector(
      '.entry-search'
    ) as HTMLInputElement;
    searchInput.value = '';
  };

  _showPreview = (path?: string) => this.setState({ previewPath: path });

  _fileHover = (path?: string, name?: string) => {
    this._showPreview(path);

    this.setState({ hoveredName: name });
  };

  _folderHover = (name?: string) => {
    this.setState({ hoveredName: name, message: undefined });
  };

  _toggleAdd = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    this.setState({ addToFolderName: name });
  };

  _toggleEdit = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    this.setState({ editFolderName: name });
  };

  _toggleDelete = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    this.setState({ deleteItemName: name });
  };

  _toggleFileDelete = (e: React.MouseEvent<HTMLElement>, name?: string) => {
    e.stopPropagation();
    this.setState({ deleteItemName: name });
  };

  _copyForMoving = (
    e: React.MouseEvent<HTMLElement>,
    item?: FileExplorerItem
  ) => {
    e.stopPropagation();
    this.setState({ copiedForMovingItem: item });
  };

  _copyToCloseout = (
    e: React.MouseEvent<HTMLElement>,
    item: FileExplorerItem
  ) => {
    e.stopPropagation();
    const url = this._buildUrl(
      FileExplorerPaths.CopyItem,
      {},
      this.props.startingDir + '/Closeout'
    );
    fetch(encodeURI(url), {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify(item),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ message: 'Item successfully copied' });
        } else this.setState({ saving: false, message: data.message });
      });
  };

  _toggleSubscriptionModal = (e?, path?: string) => {
    if (e) e.stopPropagation();
    this.setState({ subscribeFolderPath: path });
  };

  _togglePermissionsModal = (e?, path?: string) => {
    if (e) e.stopPropagation();
    this.setState({ permissionFolderPath: path });
  };

  _toggleMovingModal = () =>
    this.setState({ showMovingModal: !this.state.showMovingModal });

  _openFileTab = (path) => window.open(path, '_blank');

  _clearMessage = () => this.setState({ message: undefined });

  _upload = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ saving: true });
    const { subPath } = this.state,
      input = e.currentTarget,
      files = input.files;
    const url = this._buildUrl(FileExplorerPaths.Upload, {}, subPath);
    const form = new FormData();
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; ++i)
        form.append('files', files[i], files[i].name);
    }
    fetch(url, { credentials: 'same-origin', method: 'POST', body: form })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ saving: false });
          input.value = '';
          this._searchItems(subPath);
        } else this.setState({ saving: false, message: data.message });
      });
  };

  _addFolder = () => {
    const { subPath, addToFolderName } = this.state;
    const newSubPath = subPath
      ? addToFolderName
        ? subPath + '/' + addToFolderName
        : subPath
      : addToFolderName;
    let url = this._buildUrl(FileExplorerPaths.Add, {}, newSubPath);
    const newFolder = (
        document.getElementById('add-folder-input') as HTMLInputElement
      ).value,
      inherit = (
        document.getElementById('inherit-permissions') as HTMLInputElement
      ).checked;
    url =
      url +
      '&newFolder=' +
      encodeURIComponent(newFolder) +
      `&inheritPermissions=${inherit}`;
    fetch(url, { credentials: 'same-origin', method: 'POST' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ addToFolderName: undefined });
          this._searchItems(subPath);
        } else this.setState({ saving: false, message: data.message });
      });
  };

  _editFolder = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const { subPath, editFolderName } = this.state;

    let url = this._buildUrl(FileExplorerPaths.Edit, {}, subPath);
    const newName = (
      document.getElementById('edit-folder-input') as HTMLInputElement
    ).value;
    url = url + '&oldName=' + encodeURIComponent(editFolderName!);
    url = url + '&newName=' + encodeURIComponent(newName);
    fetch(encodeURI(url), { credentials: 'same-origin', method: 'PUT' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this._searchItems(subPath);
        else this.setState({ saving: false, message: data.message });
      });
  };

  _deleteItem = () => {
    const { subPath, deleteItemName } = this.state;

    const delSubPath = subPath
      ? subPath + '/' + deleteItemName
      : deleteItemName;
    const url = this._buildUrl(
      FileExplorerPaths.Delete,
      {},
      encodeURIComponent(delSubPath!)
    );
    fetch(encodeURI(url), { credentials: 'same-origin', method: 'DELETE' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        this.setState({ deleteItemName: undefined });
        if (resOk) this._searchItems(subPath);
        else this.setState({ saving: false, message: data.message });
      });
  };

  _moveItem = () => {
    const { subPath, copiedForMovingItem } = this.state;
    if (copiedForMovingItem) {
      const url = this._buildUrl(FileExplorerPaths.MoveItem, {}, subPath);
      fetch(encodeURI(url), {
        credentials: 'same-origin',
        method: 'PUT',
        body: JSON.stringify(copiedForMovingItem),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            this.setState({
              copiedForMovingItem: undefined,
              showMovingModal: false,
            });
            this._searchItems(subPath);
          } else this.setState({ saving: false, message: data.message });
        });
    }
  };

  _subscribe = (e, path: string) => {
    e.stopPropagation();
    const { subPath } = this.state;
    const url = this._buildUrl(`api/FolderSubscription/SubscribeMe`, {}, path);
    fetch(encodeURI(url), { method: 'POST' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ message: 'Subscribed!' });
          this._searchItems(subPath);
        } else this.setState({ saving: false, message: data.message });
      });
  };

  _unsubscribe = (e, path: string) => {
    e.stopPropagation();
    const { subPath } = this.state;
    const url = this._buildUrl(
      `api/FolderSubscription/UnsubscribeMe`,
      {},
      path
    );
    fetch(encodeURI(url), { method: 'DELETE' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ message: 'Unsubscribed!' });
          this._searchItems(subPath);
        } else this.setState({ saving: false, message: data.message });
      });
  };

  _toggleCarousel = (idx?: number) => this.setState({ carouselIndex: idx });

  _toggleShareModal = (path?: string) => this.setState({ sharePath: path });

  render() {
    const { params, breadCrumbStartLevel } = this.props;
    const {
        items,
        subPath,
        message,
        loading,
        saving,
        previewPath,
        deleteItemName,
        addToFolderName,
        copiedForMovingItem,
        showMovingModal,
        carouselIndex,
        subscribeFolderPath,
        permissionFolderPath,
        sharePath,
      } = this.state,
      pathSplit = subPath ? subPath.split('/') : [''],
      crumbCount = pathSplit.length - (breadCrumbStartLevel + 1),
      locked =
        extendedLockFolders.findIndex((x) => pathSplit.indexOf(x) !== -1) !==
        -1,
      adding = addToFolderName === '',
      crumbs =
        crumbCount > 0
          ? pathSplit
              .slice(breadCrumbStartLevel + 1, pathSplit.length)
              .map((x, i) => {
                const crumbPath = pathSplit
                  .slice(0, breadCrumbStartLevel + i + 2)
                  .join('/');
                return (
                  <span
                    key={i}
                    className="crumb"
                    onClick={() => this._crumbNav(crumbPath)}
                  >
                    {' '}
                    {x}
                    {i !== crumbCount - 1 ? (
                      <span className="fas fa-chevron-right"></span>
                    ) : (
                      []
                    )}
                  </span>
                );
              })
          : [];

    const canAddOrUpload =
      !locked && this.state.permissions === FolderPermissionLevel.Write;
    //breadCrumbStartLevel === 0 ?
    //    pathSplit.length > 1 :
    //    true :
    //false;

    const itemLines = items ? this.renderLines(items, locked) : [];

    return (
      <FileExplorerDiv
        isInModal={this.props.isInModal}
        className="file-explorer"
      >
        <MessageBox message={message} clearMessage={this._clearMessage} />
        <Loader loading={saving} />
        {carouselIndex !== undefined && (
          <PhotoCarousel
            startingIndex={carouselIndex}
            close={() => this._toggleCarousel()}
            photos={items
              .filter(
                (x) =>
                  x.type === ExplorerItemType.File &&
                  x.name.indexOf('.pdf') === -1
              )
              .map((x) => x.path)}
          />
        )}
        <ExplorerContainerDiv
          isInModal={this.props.isInModal}
          className="explorer-container"
        >
          <div className="file-breadcrumb-trail">
            <span className="fas fa-folder-open"></span>
            <span
              className="crumb"
              onClick={() => this._crumbNav(this.homeDir)}
            >
              {' '}
              Home
              {subPath && subPath.length ? (
                <span className="fas fa-chevron-right"></span>
              ) : (
                []
              )}
            </span>
            {crumbs}
            <FileExplorerActionsDiv>
              {this.props.canOpenWindow && !this.props.isInModal && (
                <AddWindowButton onClick={this.props.onOpenWindow}>
                  Open Window
                </AddWindowButton>
              )}
              {this.props.canOpenWindow && this.props.isInModal && (
                <i
                  onClick={this.props.onOpenWindow}
                  className="fa-solid fa-up-right-from-square fa-xl"
                ></i>
              )}
              <input
                className="entry-search mobile-hide"
                placeholder="Search"
                onChange={this._filterItems}
              />
            </FileExplorerActionsDiv>
          </div>
          <div className="file-explorer-list custom-scrollbar">
            <div style={{ marginBottom: '5px' }}>
              {loading && <FileExplorerLoader />}
              <div>
                {!loading && itemLines}
                {adding && (
                  <div
                    className="new-folder-line"
                    style={{ marginLeft: '4px' }}
                  >
                    <input id="add-folder-input" /> &nbsp;
                    <div title="Inherit the permissions from the parent folder">
                      <span>Inherit permissions?</span>{' '}
                      <input type="checkbox" id="inherit-permissions" />
                    </div>
                    <button
                      title="Save"
                      className="btn btn-sm btn-blue fas fa-check"
                      onClick={this._addFolder}
                    ></button>
                    <button
                      title="Cancel"
                      className="btn btn-sm btn-outline-secondary fas fa-ban"
                      onClick={(e) => this._toggleAdd(e, undefined)}
                    ></button>
                  </div>
                )}
              </div>
            </div>
            {!locked && copiedForMovingItem && (
              <button
                className="btn btn-sm btn-blue fas fa-paste"
                title="Move copied item here"
                onClick={this._toggleMovingModal}
              ></button>
            )}
            {canAddOrUpload && (
              <AddFolderButton onClick={(e) => this._toggleAdd(e, '')}>
                Add Folder
              </AddFolderButton>
            )}
            {!locked && canAddOrUpload && (
              <input
                type="file"
                multiple
                onChange={this._upload}
                style={{ display: 'inline-block' }}
              />
            )}
          </div>
        </ExplorerContainerDiv>
        {!this.props.isInModal && (
          <div className="hover-preview-container mobile-hide">
            <div className="hover-title">Preview</div>
            <HoverImagePreview
              previewPath={previewPath}
              style={{ position: 'absolute' }}
            />
          </div>
        )}
        {deleteItemName && this.renderDeleteModal(deleteItemName)}
        {/*deleteFileName && this.renderDeleteFileModal(deleteFileName)*/}
        {showMovingModal && this.renderMoveItemModal()}
        {sharePath && (
          <FileShareModal path={sharePath} close={this._toggleShareModal} />
        )}
        {subscribeFolderPath && (params.pId || params.id) && (
          <FolderSubscriber
            folderPath={subscribeFolderPath}
            projectId={params.id}
            close={() => this._toggleSubscriptionModal()}
          />
        )}
        {permissionFolderPath && (
          <FolderPermissionsModal
            relPath={permissionFolderPath}
            close={() => this._togglePermissionsModal()}
          />
        )}
      </FileExplorerDiv>
    );
  }

  private renderLines(items: FileExplorerItem[], locked: boolean) {
    const { searchView, hoveredName, addToFolderName, editFolderName } =
      this.state;
    const subPath = this.state.subPath || '';
    if (searchView) {
      let i = 0;
      return items.map((x) => {
        const j = i;
        const icon = <span className="fas fa-file"></span>,
          display = x.path.split('/').join(' > '),
          enter = () => this._fileHover(x.path, x.name),
          leave = () => this._fileHover(undefined),
          hovered = hoveredName === x.name,
          click =
            x.name.indexOf('.pdf') === -1
              ? () => this._toggleCarousel(j)
              : () => this._openFileTab(encodeURI(x.path));

        if (x.name.indexOf('.pdf') === -1) i++;

        return (
          <div
            key={x.name}
            className="file-explorer-line search-result"
            onClick={click}
            onMouseEnter={enter}
            onMouseLeave={leave}
          >
            <div>
              {icon}
              <span className="item-name" onClick={click}>
                {display}
              </span>
              {hovered && (
                <div className="hover-options">
                  <FileDownloader
                    actionPath="api/FileExplorer/Download"
                    queryParams={{ filePath: encodeURI(x.path) }}
                    fileType="file"
                  />
                </div>
              )}
            </div>
          </div>
        );
      });
    } else {
      let i = 0;
      return items.map((x) => {
        const j = i;
        const isLocked =
            locked ||
            extendedLockFolders.indexOf(x.name) !== -1 ||
            (x.type === ExplorerItemType.Folder &&
              x.permissions !== FolderPermissionLevel.Write) ||
            (x.type === ExplorerItemType.File &&
              extendedLockFolders.some((f) => subPath.indexOf(f) !== -1)),
          icon =
            x.type === ExplorerItemType.Folder ? (
              <span className="fas fa-folder-open"></span>
            ) : (
              <span className="fas fa-file"></span>
            ),
          //path = subPath ? subPath + '/' + x.name : x.name,
          click =
            x.type === ExplorerItemType.Folder
              ? () => this._searchItems(x.path)
              : x.name.indexOf('.pdf') === -1
              ? () => this._toggleCarousel(j)
              : () => this._openFileTab(encodeURI(x.path)),
          enter =
            x.type === ExplorerItemType.File
              ? () => this._fileHover(x.path, x.name)
              : () => this._folderHover(x.name),
          leave =
            x.type === ExplorerItemType.File
              ? () => this._fileHover()
              : () => this._folderHover(undefined),
          hovered = hoveredName === x.name,
          adding = addToFolderName === x.name,
          editing = editFolderName === x.name;

        if (x.type === ExplorerItemType.File && x.name.indexOf('.pdf') === -1)
          i++;

        return (
          <div key={x.name}>
            <div
              className="file-explorer-line"
              onClick={click}
              onMouseEnter={enter}
              onMouseLeave={leave}
            >
              <div>
                {icon}
                {editing ? (
                  <div className="edit-folder-line">
                    <input
                      id="edit-folder-input"
                      defaultValue={x.name}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <button
                      title="Save"
                      className="btn btn-sm btn-blue fas fa-check"
                      onClick={(e) => this._editFolder(e)}
                    ></button>
                    <button
                      title="Cancel"
                      className="btn btn-sm btn-outline-secondary fas fa-ban"
                      onClick={(e) => this._toggleEdit(e, undefined)}
                    ></button>
                  </div>
                ) : (
                  x.name
                )}
              </div>
              {hovered && (
                <div className="hover-options">
                  {/*{(!isLocked && canSubscribeUsers && x.type === ExplorerItemType.Folder && x.name !== "RFIs" && x.name !== "Submittals") ?*/}
                  {/*    <span title='Subscribe users to this folder' className='fas fa-user-friends btn' onClick={(e) => this._toggleSubscriptionModal(e, x.path)}></span>*/}
                  {/*    : []*/}
                  {/*}*/}
                  {!isLocked &&
                  x.type === ExplorerItemType.Folder &&
                  x.name !== 'RFIs' &&
                  x.name !== 'Submittals' ? (
                    x.isUserSubscribed ? (
                      <span
                        title="Unsubscribe from this folder"
                        className="fas fa-eye-slash btn"
                        onClick={(e) => this._unsubscribe(e, x.path)}
                      ></span>
                    ) : (
                      <span
                        title="Subscribe to this folder"
                        className="fas fa-eye btn"
                        onClick={(e) => this._subscribe(e, x.path)}
                      ></span>
                    )
                  ) : (
                    []
                  )}
                  {this.props.startingDir.indexOf('/Projects/') !== -1 &&
                    x.path.indexOf('/Closeout') === -1 && (
                      <span
                        title="Copy to closeout folder"
                        className="fas fa-book btn"
                        onClick={(e) => this._copyToCloseout(e, x)}
                      ></span>
                    )}
                  <span
                    title="View folder permissions"
                    className="fas fa-user-friends btn"
                    onClick={(e) => this._togglePermissionsModal(e, x.path)}
                  ></span>
                  {x.permissions === FolderPermissionLevel.Write && (
                    <this.superUserPmComp
                      componentType="span"
                      class="fas fa-share-alt btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        this._toggleShareModal(x.path);
                      }}
                    ></this.superUserPmComp>
                  )}
                  {!isLocked && x.type === ExplorerItemType.Folder && (
                    <span
                      title="Add subfolder"
                      className="fas fa-plus btn"
                      onClick={(e) => this._toggleAdd(e, x.name)}
                    ></span>
                  )}
                  {!isLocked && x.type === ExplorerItemType.Folder && (
                    <span
                      title="Edit"
                      className="fas fa-edit btn"
                      onClick={(e) => this._toggleEdit(e, x.name)}
                    ></span>
                  )}
                  {!isLocked && (
                    <span
                      title="Copy to Move"
                      className="fas fa-copy btn"
                      onClick={(e) => this._copyForMoving(e, x)}
                    ></span>
                  )}
                  {x.type === ExplorerItemType.File && (
                    <FileDownloader
                      actionPath="api/FileExplorer/Download"
                      queryParams={{ filePath: encodeURI(x.path) }}
                      fileType=""
                    />
                  )}
                  {x.type === ExplorerItemType.Folder && (
                    <FileDownloader
                      actionPath="api/FileExplorer/Zip"
                      queryParams={{ subPath: encodeURI(x.path) }}
                      fileType="zip"
                    />
                  )}
                  {!isLocked && (
                    <span
                      title="Delete"
                      className="fas fa-trash btn"
                      onClick={
                        x.type === ExplorerItemType.Folder
                          ? (e) => this._toggleDelete(e, x.name)
                          : (e) => this._toggleFileDelete(e, x.name)
                      }
                    ></span>
                  )}
                </div>
              )}
            </div>
            {adding && (
              <div className="new-folder-line">
                <input id="add-folder-input" /> &nbsp;
                <div title="Inherit the permissions from the parent folder">
                  <span>Inherit permissions?</span>{' '}
                  <input type="checkbox" id="inherit-permissions" />
                </div>
                <button
                  title="Save"
                  className="btn btn-sm btn-blue fas fa-check"
                  onClick={this._addFolder}
                ></button>
                <button
                  title="Cancel"
                  className="btn btn-sm btn-outline-secondary fas fa-ban"
                  onClick={(e) => this._toggleAdd(e, undefined)}
                ></button>
              </div>
            )}
          </div>
        );
      });
    }
  }

  private renderDeleteModal(folderName: string) {
    return (
      <Modal>
        <div className="modal-header">
          <h4>Delete Item</h4>
        </div>
        <div className="modal-body">
          <div style={{ fontSize: '14px' }}>
            Are you sure you want to delete {folderName}?
          </div>
          {/*<p><b>Note:</b> This will delete all sub-folders under {folderName}</p>*/}
        </div>
        <div className="modal-footer">
          <button className="btn btn-sm btn-danger" onClick={this._deleteItem}>
            Delete
          </button>
          <button
            className="btn btn-sm btn-outline-secondary"
            onClick={(e) => this._toggleDelete(e, undefined)}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }

  private renderMoveItemModal() {
    const { copiedForMovingItem, subPath } = this.state;
    if (!copiedForMovingItem) return <div></div>;
    const isFile = copiedForMovingItem.type === ExplorerItemType.File;
    const headerText = isFile ? 'Move File' : 'Move Folder';

    return (
      <Modal>
        <div className="modal-header">
          <h4>{headerText}</h4>
        </div>
        <div className="modal-body">
          <div style={{ fontSize: '14px' }}>
            Are you sure you want to move {copiedForMovingItem.name} to{' '}
            {subPath}?
          </div>
          <p>
            <b>Note:</b> This will remove {copiedForMovingItem.name} from it's
            current location
          </p>
        </div>
        <div className="modal-footer">
          <button className="btn btn-sm btn-blue" onClick={this._moveItem}>
            Move
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={this._toggleMovingModal}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
