import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardGraphs, DashboardScope } from '../dashboard/DashboardGraphs';
import { ApplicationState } from '../store';

export const ProjectOverview = () => {

    const userStore = useSelector((s: ApplicationState) => s.user);
    const detailStore = useSelector((s: ApplicationState) => s.detail);
    const dispatch = useDispatch();

    return (
        <div className='project-overview section'>
            <DashboardGraphs scope={DashboardScope.Project} />
        </div>
    )
}