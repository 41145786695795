import * as React from 'react';
import Modal from '../components/Modal';
import { formatDate, formatDateInputFriendly, formatDateTime } from '../helpers/formatters';
import { useGlobalMessage } from '../helpers/globalDispatches';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

export interface ApiUser {
    id: string,
    name: string,
    key: string,
    keyExp: string,
    keyLastAccessed: string,
}

export const ApiUserManagement = () => {
    const [users, setUsers] = React.useState([] as ApiUser[]);
    const [editUserId, setEditUserId] = React.useState(null as string | null);
    const [viewKeyId, setViewKeyId] = React.useState(null as string | null);
    const [generateKeyId, setGenerateKeyId] = React.useState(null as string | null);
    const [loading, setLoading] = React.useState(true);
    const updateMessage = useGlobalMessage();

    const _getUsers = () => {
        fetch(`api/User/ApiUsers`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setUsers(data);
                else updateMessage(data.message);
                setLoading(false);
            })
    }

    const _copyKey = (key: string) => {
        // @ts-ignore
        navigator.clipboard.writeText(key).then(
            () => alert('Key copied!'), //updateMessage('Key copied!'),
            () => updateMessage('Error: unable to copy key')
        )
    }

    const _update = (e: React.FocusEvent<HTMLInputElement>) => {
        fetch(`api/Admin/UpdateKey?apiUserId=${editUserId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ fieldName: 'expirationDate', value: e.currentTarget.value })
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) _getUsers();
                else updateMessage(data.message);                
            })
    }

    const _generateKey = () => {
        fetch(`api/Admin/GenerateKey?apiUserId=${generateKeyId}`, { method: 'POST' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    _getUsers();
                    setViewKeyId(null);
                    setGenerateKeyId(null);
                }
                else updateMessage(data.message);
            })
    }

    React.useEffect(() => { _getUsers(); }, []);

    const lines = loading ?
        <StandardGridLoader /> :
        users.map(x => {
            const editing = x.id === editUserId,
                viewingKey = x.id === viewKeyId
            return (
                <div className='sv-grid-line no-hover' key={x.id}>
                    <div className='my-col-5'>{x.name}</div>
                    <div className='my-col-8'>
                        {x.key.length ? viewingKey ? x.key : maskedKey(x.key) : <i>No key found for this user</i>}
                        {x.key.length ? <span className='far fa-clipboard' onClick={() => _copyKey(x.key)} title='Copy key to clipboard'></span> : []}
                        {/*{x.key.length ? viewingKey ? <span className='far fa-eye-slash' title='View key' onClick={() => setViewKeyId(null)}></span> : <span className='far fa-eye' title='Hide key' onClick={() => setViewKeyId(x.id)}></span> : []}*/}
                    </div>
                    <div className='my-col-3'>{
                        editing ?
                            <input name='expirationDate' className='form-control' type='date' defaultValue={formatDateInputFriendly(x.keyExp)} onBlur={_update} /> :
                            formatDate(x.keyExp)
                    }</div>
                    <div className='my-col-3'>{formatDateTime(x.keyLastAccessed)}</div>
                    <div className='my-col-1 sv-grid-buttons'>
                        {x.key.length ?
                            <span className='fas fa-sync' onClick={() => setGenerateKeyId(x.id)} title='Generate new key for user'></span> :
                            <span className='fas fa-plus' onClick={() => setGenerateKeyId(x.id)} title='Generate new key for user'></span>
                        }
                        {editing ?
                            <div className='fas fa-check' onClick={() => setEditUserId(null)} title='Done editing' /> :
                            <div className='fas fa-edit' onClick={() => setEditUserId(x.id)} title='Edit this API user' />
                        }
                    </div>
                </div>
            )
        })

    const generateKeyModal = generateKeyId ?
        <Modal>
            <div className='modal-header'><h5>Generate API Key</h5></div>
            <div className='modal-body'>
                <div>Generate a new unique key for this API user. <u>Warning: This will invalidate the previous key for this user, if one exists. Once overwritten, a previous key will not be retrievable.</u></div>
            </div>
            <div className='modal-footer'>
                <div className='btn btn-sm btn-blue' onClick={_generateKey}>Generate Key</div>
                <div className='btn btn-sm btn-outline-secondary' onClick={() => setGenerateKeyId(null)}>Close</div>
            </div>
        </Modal>
        : []

    return (
        <div className='api-key-grid sv-grid'>
            <div className='sv-grid-header'>
                <div className='my-col-5'>User</div>
                <div className='my-col-8'>Key</div>
                <div className='my-col-3'>Key Exp</div>
                <div className='my-col-4'>Last Access</div>
            </div>
            {lines}
            {generateKeyModal}
        </div>
    )
}

const maskedKey = (key: string) => key.length ? key.substr(0, 4) + '*'.repeat(15) : '';