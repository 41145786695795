import * as React from "react";
import { connect } from "react-redux";
import * as cx from "classnames";
import {
	ImportConfig,
	ImportSource,
	ProjectImportMap,
	MilestoneImportMap,
	RunFrequency,
	ListItem,
} from "../../interfaces/interfaces";
import * as ImportStore from "../../store/imports";
import { formatDateTime } from "../../helpers/formatters";
import { ApplicationState } from "../../store";
import Modal from "../../components/Modal";
import { SliderCheckbox } from "../../components/SliderCheckbox";
import { ImportConfigItem } from "./ImportConfigItem";

interface MyProps {
	source: ImportSource;
	users: ListItem[];
}

interface State {
	editConfigId?: number;
}

type Props = MyProps &
	ImportStore.ImportStore &
	typeof ImportStore.actionCreators;

class ImportConfigList extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_toggleEditConfigId = (id?: number) => this.setState({ editConfigId: id });

	_editConfigField = (
		e:
			| React.FocusEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const field = e.currentTarget.name,
			value = e.currentTarget.value;

		this.props.updateConfig(this.state.editConfigId!, field, value);
	};

	//_editConfigField = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
	//    const field = e.currentTarget.name,
	//        value = e.currentTarget.value;

	//    this.props.updateConfig(this.state.editConfigId!, field, value)
	//}

	_editProjectMap = (id: number, e: React.FocusEvent<HTMLInputElement>) => {
		const field = e.currentTarget.name,
			value = e.currentTarget.value;

		this.props.updateProjectMap(id, field, value);
	};

	_editMilestoneMap = (id: number, e: React.FocusEvent<HTMLInputElement>) => {
		const field = e.currentTarget.name,
			value = e.currentTarget.value;

		this.props.updateMilestoneMap(id, field, value);
	};

	_toggleSwitch = (id: number, field: string, onOff: boolean) =>
		this.props.updateConfig(id, field, onOff ? "false" : "true");

	//_toggleConfigActive = (id: number, active: boolean) => this.props.updateConfig(id, 'active', active ? 'false' : 'true')

	//_toggleUseHeaderRowActive = (id: number, useHeader: boolean) => this.props.updateConfig(id, 'useHeaderRow', useHeader ? 'false' : 'true')

	//_toggleEraseEmptyCells = (id: number, erase: boolean) => this.props.updateConfig(id, 'eraseValueIfCellNull', erase ? 'false' : 'true')

	render() {
		const { importConfigs } = this.props,
			{ editConfigId } = this.state;
		const configLines =
			importConfigs && importConfigs.length
				? importConfigs
						.filter((c) => c.source === ImportSource.Google)
						.map((c) => this.renderImportConfig(c))
				: [];

		return (
			<div key={this.props.clientId} className="import-config-list">
				{this.renderListHeader()}
				{configLines}
				{editConfigId !== undefined ? this.renderEditModal(editConfigId) : []}
			</div>
		);
	}

	private renderListHeader() {
		const { source } = this.props;
		if (source === ImportSource.Google) {
			return (
				<div className="import-config-header google">
					<div className="col-sm-2">
						<label>Company</label>
					</div>
					<div className="col-sm-3">
						<label>Sheet Id</label>
					</div>
					<div className="col-sm-2">
						<label>Frequency</label>
					</div>
					<div className="col-sm-2">
						<label>Last Run</label>
					</div>
					<div className="col-sm-1">
						<label>Active</label>
					</div>
					<div className="col-sm-2">
						{this.props.clientId !== 0 && (
							<span
								className="fas fa-plus"
								onClick={() => this.props.addConfig(source)}
								title="Add new import configuration"
							></span>
						)}
					</div>
				</div>
			);
		}
		return <div></div>;
	}

	private renderImportConfig(config: ImportConfig) {
		return (
			<ImportConfigItem
				config={config}
				toggleSwitch={this._toggleSwitch}
				toggleEditConfigId={this._toggleEditConfigId}
				renderMilestoneMaps={this.renderMilestoneMaps}
				renderProjectMapLine={this.renderProjectMapLine}
				deleteConfig={this.props.deleteConfig}
				runImport={this.props.runImport}
				toggleExpandMaps={this.props.toggleExpandMaps}
				users={this.props.users}
			/>
		);
	}

	private renderProjectMapLine(map: ProjectImportMap) {
		return (
			<div className="project-import-map">
				<h5>Project Column Mappings</h5>
				<small>
					Enter the column lettering (A, BB, etc.) corresponding to the column
					that has the data you wish to map to the specified fields below.
				</small>
				<div className="mapping-blocks">
					<div>
						<label>Project Number</label>
						<input
							name="projectNumberColumn"
							defaultValue={map.projectNumberColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Project Name</label>
						<input
							name="projectNameColumn"
							defaultValue={map.projectNameColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Site Id</label>
						<input
							name="siteIdColumn"
							defaultValue={map.siteIdColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Project Type</label>
						<input
							name="projectTypeColumn"
							defaultValue={map.projectTypeColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Address</label>
						<input
							name="addressColumn"
							defaultValue={map.addressColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>City</label>
						<input
							name="cityColumn"
							defaultValue={map.cityColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>State</label>
						<input
							name="stateColumn"
							defaultValue={map.stateColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Zip</label>
						<input
							name="zipColumn"
							defaultValue={map.zipColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Internal PM</label>
						<input
							name="internalPmColumn"
							defaultValue={map.internalPmColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Client PM</label>
						<input
							name="externalPmColumn"
							defaultValue={map.externalPmColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Budget</label>
						<input
							name="budgetColumn"
							defaultValue={map.budgetColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>Start Date</label>
						<input
							name="startDateColumn"
							defaultValue={map.startDateColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
					<div>
						<label>End Date</label>
						<input
							name="endDateColumn"
							defaultValue={map.endDateColumn}
							onBlur={(e) => this._editProjectMap(map.id, e)}
						/>
					</div>
				</div>
			</div>
		);
	}

	private renderMilestoneMaps(maps: MilestoneImportMap[]) {
		if (!maps || !maps.length) return [];

		const mapSections = maps.map((x) => {
			return (
				<div className="milestone-map-form" key={x.id}>
					<label className="milestone-map-label">{x.milestoneName}</label>
					<div>
						<label>Due Date</label>
						<input
							name="dueColumn"
							defaultValue={x.dueColumn}
							onBlur={(e) => this._editMilestoneMap(x.id, e)}
						/>
					</div>
					<div>
						<label>Complete Date</label>
						<input
							name="completeColumn"
							defaultValue={x.completeColumn}
							onBlur={(e) => this._editMilestoneMap(x.id, e)}
						/>
					</div>
					<div>
						<label>Notes</label>
						<input
							name="notesColumn"
							defaultValue={x.notesColumn}
							onBlur={(e) => this._editMilestoneMap(x.id, e)}
						/>
					</div>
				</div>
			);
		});

		return (
			<div className="milestone-import-maps">
				<h5>Milestone Column Mappings</h5>
				<small>
					Enter the column lettering (A, BB, etc.) corresponding to the column
					that has the data you wish to map to the specified fields below.
				</small>
				<div className="milestone-map-forms">{mapSections}</div>
			</div>
		);
	}

	private renderEditModal(configId: number) {
		const config = this.props.importConfigs.find(
			(x) => x.id === configId
		) as ImportConfig;
		const activeKey = config.active ? 1 : 0;
		return (
			<Modal modalClass="edit-import-config-modal">
				<h5 className="modal-header">Edit Import</h5>
				<div className="modal-body">
					<div className="form-group">
						<label>
							Service Account Email{" "}
							<span
								className="fas fa-question"
								title="The account or service account SiteVue will read the data on behalf of. The targeted Google Sheet must be shared with this account."
							></span>
						</label>
						<input
							className="form-control"
							name="serviceAccountEmail"
							defaultValue={config.serviceAccountEmail}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Private Key{" "}
							<span
								className="fas fa-question"
								title="The private key for the service account which will be accessing the Google Sheet."
							></span>
						</label>
						<textarea
							className="form-control"
							name="privateKey"
							defaultValue={config.privateKey}
							onBlur={this._editConfigField}
							rows={4}
						></textarea>
					</div>
					<div className="form-group">
						<label>
							Sheet Id{" "}
							<span
								className="fas fa-question"
								title="The unique ID of the Google Sheet. This can be found in the URL of the sheet, immediately following 'spreadsheets/d/'."
							></span>
						</label>
						<input
							className="form-control"
							name="sheetId"
							defaultValue={config.sheetId}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Sheet Name{" "}
							<span
								className="fas fa-question"
								title="The name of the sheet (tab) in the document that contains the data you wish to import."
							></span>
						</label>
						<input
							className="form-control"
							name="sheetName"
							defaultValue={config.sheetName}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Range Start{" "}
							<span
								className="fas fa-question"
								title="The cell (column letter and row number) where the relevant data starts. For example: A3. Note: If using the header row to map columns, make sure the range includes that row"
							></span>
						</label>
						<input
							className="form-control"
							name="rangeStart"
							defaultValue={config.rangeStart}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Range End{" "}
							<span
								className="fas fa-question"
								title="The letter of the column where the data ends."
							></span>
						</label>
						<input
							className="form-control"
							name="rangeEnd"
							defaultValue={config.rangeEnd}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Use Header Row{" "}
							<span
								className="fas fa-question"
								title="Indicate if you wish to use the header row on the spreadsheet to predict any mapping. For the matching on headers to work, the header text must exactly match the field name in SiteVue, as displayed in the Project Column Mappings (i.e. 'Project No.' will not match 'Project Number')"
							></span>
						</label>
						<SliderCheckbox
							selected={config.useHeaderRow}
							fieldName="useHeaderRow"
							dataId={config.id}
							onChange={() =>
								this._toggleSwitch(
									config.id,
									"useHeaderRow",
									config.useHeaderRow
								)
							}
						/>
						{/*<label className='switch' key={activeKey}>*/}
						{/*    <input type='checkbox' defaultChecked={config.useHeaderRow} />*/}
						{/*    <span className='slider round' onClick={() => this._toggleSwitch(config.id, 'useHeaderRow', config.useHeaderRow)}></span>*/}
						{/*</label>*/}
					</div>
					{config.useHeaderRow ? (
						<div className="form-group">
							<label>
								Header Row{" "}
								<span
									className="fas fa-question"
									title="The number the header row sits on."
								></span>
							</label>
							<input
								className="form-control"
								name="headerRowIndex"
								defaultValue={config.headerRowIndex || ""}
								onBlur={this._editConfigField}
							></input>
						</div>
					) : (
						[]
					)}
					<div className="form-group">
						<label>
							Project Key Type{" "}
							<span
								className="fas fa-question"
								title="The project field that you wish to use to uniquely identify a project in the spreadsheet."
							></span>
						</label>
						<select
							name="projectKey"
							className="form-control"
							onChange={this._editConfigField}
							defaultValue={config.projectKey}
						>
							<option className="form-control" value="ProjectNumber">
								Project Number
							</option>
							<option className="form-control" value="SiteId">
								Site Id
							</option>
						</select>
					</div>
					<div className="form-group">
						<label>
							Project Key Column{" "}
							<span
								className="fas fa-question"
								title="The letter of the column that contains the project key."
							></span>
						</label>
						<input
							className="form-control"
							name="projectIdColumn"
							defaultValue={config.projectIdColumn}
							onBlur={this._editConfigField}
						></input>
					</div>
					<div className="form-group">
						<label>
							Erase On Empty Values{" "}
							<span
								className="fas fa-question"
								title="Indicate whether empty cells should erase or nullify existing values if they find a corresponding data match."
							></span>
						</label>
						<SliderCheckbox
							selected={config.eraseValueIfCellNull}
							fieldName="eraseValueIfCellNull"
							dataId={config.id}
							onChange={() =>
								this._toggleSwitch(
									config.id,
									"eraseValueIfCellNull",
									config.eraseValueIfCellNull
								)
							}
						/>
						{/*<label className='switch' key={activeKey}>*/}
						{/*    <input type='checkbox' defaultChecked={config.eraseValueIfCellNull} />*/}
						{/*    <span className='slider round' onClick={() => this._toggleSwitch(config.id, 'eraseValueIfCellNull', config.eraseValueIfCellNull)}></span>*/}
						{/*</label>*/}
					</div>
					<div className="form-group">
						<label>Active</label>
						<SliderCheckbox
							selected={config.active}
							fieldName="active"
							dataId={config.id}
							onChange={() =>
								this._toggleSwitch(config.id, "active", config.active)
							}
						/>
						{/*<label className='switch' key={activeKey}>*/}
						{/*    <input type='checkbox' defaultChecked={config.active} />*/}
						{/*    <span className='slider round' onClick={() => this._toggleSwitch(config.id, 'active', config.active)}></span>*/}
						{/*</label>*/}
					</div>
					<div className="form-group">
						<label>Run Frequency Pt 1</label>
						<select
							name="frequencyType"
							className="form-control"
							onChange={this._editConfigField}
							defaultValue={config.frequencyType}
						>
							<option className="form-control" value={RunFrequency.hourly}>
								Hourly
							</option>
							<option className="form-control" value={RunFrequency.daily}>
								Daily
							</option>
							<option className="form-control" value={RunFrequency.weekly}>
								Weekly
							</option>
						</select>
					</div>
					<div className="form-group">
						<label>Run Frequency Pt 2</label>
						<input
							className="form-control"
							name="frequencyValue"
							defaultValue={config.frequencyValue}
							onBlur={this._editConfigField}
						></input>
					</div>
				</div>
				<div className="modal-footer">
					<button
						className="btn btn-sm btn-blue"
						onClick={() => this._toggleEditConfigId(undefined)}
					>
						Done
					</button>
				</div>
			</Modal>
		);
	}

	private displayFriendlyFrequency(config: ImportConfig) {
		if (config.frequencyType === RunFrequency.daily) return "Daily";
		else if (config.frequencyType === RunFrequency.weekly) return "Weekly";
		else return "Hourly";
	}
}

export default connect<
	ImportStore.ImportStore,
	typeof ImportStore.actionCreators,
	MyProps
>(
	// @ts-ignore
	(state: ApplicationState) => state.imports,
	ImportStore.actionCreators
)(ImportConfigList);
