import * as React from "react";
import * as ProjectStore from "../store/projects";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { ListItem, ProjectVm, ContactCard } from "../interfaces/interfaces";
import { check_money_format } from "../helpers/formatters";
import { MyAuthElement } from "../auth/Authorization";
import { KnownRoles } from "../auth/auth";
import FilterableSelect from "../components/FilterableSelect";
import { JoinedList } from "../components/JoinedList";
import Modal from "../components/Modal";

interface State {
	clients: ListItem[];
	brands: ListItem[];
	projectTypes: ListItem[];
	serviceLevels: ListItem[];
	clientPMs: ListItem[];
	internalPMs: ListItem[];
	selectedTeam: ListItem[];
}

interface FormProps {
	close: () => any;
}

type Props = FormProps &
	ProjectStore.Store &
	typeof ProjectStore.actionCreators;

class NewProjectForm extends React.Component<Props, State> {
	csgComponent = MyAuthElement([
		KnownRoles.Admin,
		KnownRoles.CSGPM,
		KnownRoles.CoreSuperUser,
	]);

	constructor(props: Props) {
		super(props);
		this.state = {
			clients: [],
			brands: [],
			projectTypes: [],
			serviceLevels: [],
			clientPMs: [],
			internalPMs: [],
			selectedTeam: [],
		};
	}

	componentWillMount() {
		this._getDDOptions(0);
		this._getClients();
		this._getInternalPMs();
	}

	_getDDOptions = (clientId: number) => {
		fetch(`api/Client/NewProjectOpts?clientId=${clientId}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				const pmItems = data.clientPMs
					? data.clientPMs.map((x: ContactCard) => {
							return { id: x.userId, value: x.name } as ListItem;
					  })
					: [];
				this.setState({
					brands: data.brands,
					projectTypes: data.types,
					serviceLevels: data.levels,
					clientPMs: pmItems,
				});
			});
	};

	_getClients = () => {
		fetch(`api/Client/Get`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				this.setState({ clients: data });
			});
	};

	_getInternalPMs = () => {
		fetch(`api/User/GetInUserCompany`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				this.setState({ internalPMs: data });
			});
	};

	_handleClientChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selected = e.currentTarget.querySelector(
			"option:checked"
		) as HTMLOptionElement;
		if (selected) {
			this._getDDOptions(parseInt(selected.value));
		}
	};

	_handleCopyTeamChange = (id: string | number) => {
		fetch(`api/ProjectTeam/GetTeam?id=${id}&asListItem=${true}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) this.setState({ selectedTeam: data });
			});
	};

	private save = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.currentTarget.disabled = true;
		e.currentTarget.innerText = "Saving";
		const form = document.getElementById("newProjectForm") as HTMLDivElement;
		if (form) {
			const projectNumber = (
				form.querySelector('input[name="projectNumber"]') as HTMLInputElement
			).value;
			const clientId = parseInt(
				(
					form.querySelector(
						'select[name="clientId"] option:checked'
					) as HTMLOptionElement
				).value || "0"
			);
			const brandId =
				parseInt(
					(
						form.querySelector(
							'select[name="brandId"] option:checked'
						) as HTMLOptionElement
					).value || "0"
				) || 0;
			const pName =
				(form.querySelector('input[name="projectName"]') as HTMLInputElement)
					.value || "";
			const siteId =
				(form.querySelector('input[name="siteId"]') as HTMLInputElement)
					.value || "";
			const intPm =
				(
					form.querySelector(
						'#internalPM-select input[name="internalPM-select"]'
					) as HTMLInputElement
				).value || "";
			const extPm =
				(
					form.querySelector(
						'#externalPM-select input[name="externalPM-select"]'
					) as HTMLInputElement
				).value || "";
			const address =
				(form.querySelector('input[name="address"]') as HTMLInputElement)
					.value || "";
			const city =
				(form.querySelector('input[name="city"]') as HTMLInputElement).value ||
				"";
			const state =
				(form.querySelector('input[name="state"]') as HTMLInputElement).value ||
				"";
			const zip =
				(form.querySelector('input[name="zip"]') as HTMLInputElement).value ||
				"";
			const budget =
				parseInt(
					(form.querySelector('input[name="budget"]') as HTMLInputElement)
						.value || "0"
				) || 0;
			const serviceLevelId =
				parseInt(
					(
						form.querySelector(
							'select[name="svcLvl"] option:checked'
						) as HTMLOptionElement
					).value || "0"
				) || 0;
			const projectTypeId =
				parseInt(
					(
						form.querySelector(
							'select[name="projectType"] option:checked'
						) as HTMLOptionElement
					).value || "0"
				) || 0;
			const siteSuperId =
				(
					form.querySelector(
						'#site-super-select input[name="site-super-select"]'
					) as HTMLOptionElement
				).value || "0";
			const selMembers = form.querySelectorAll(".selected-list>option");
			const copyTeamId = (
				form.querySelector(
					'#copy-project-team input[name="copy-project-team"]'
				) as HTMLInputElement
			).value;
			const teamMemberIds: string[] = [];
			[].forEach.call(selMembers, (opt: HTMLOptionElement) => {
				teamMemberIds.push(opt.value);
			});

			if (
				clientId == 0 ||
				/*brandId == 0 ||*/ pName == "" ||
				siteId == "" ||
				intPm == "" ||
				/*extPm == '' ||*/ address == "" ||
				city == "" ||
				state == "" ||
				zip ==
					"" /*|| budget == 0 || serviceLevelId == 0 || projectTypeId == 0*/
			) {
				alert(
					"Oops, it appears you haven't filled out all fields. Please enter a value for all fields before submitting"
				);
				e.currentTarget.disabled = false;
				e.currentTarget.innerText = "Add";
			} else {
				// @ts-ignore
				const info = {
					projectNumber,
					clientId: clientId,
					brandId,
					name: pName,
					siteId,
					internalPmId: intPm,
					externalPmId: extPm,
					address,
					city,
					state,
					zip,
					serviceLevelId,
					projectTypeId,
					budget,
					siteSuperId,
					teamMemberIds,
					copyTeamId,
				} as ProjectVm;

				this.props.addProject(info);
				//this.props.close()
			}
		}
	};

	public render() {
		const brandOpts = this.state.brands
			? this.state.brands.map((x) => (
					<option key={x.id} value={x.id}>
						{x.value}
					</option>
			  ))
			: [];
		const clientOpts = this.state.clients
			? this.state.clients.map((x) => (
					<option key={x.id} value={x.id}>
						{x.value}
					</option>
			  ))
			: [];
		const typeOpts = this.state.projectTypes
			? this.state.projectTypes.map((x) => (
					<option key={x.id} value={x.id}>
						{x.value}
					</option>
			  ))
			: [];
		const levelOpts = this.state.serviceLevels
			? this.state.serviceLevels.map((x) => (
					<option key={x.id} value={x.id}>
						{x.value}
					</option>
			  ))
			: [];

		return (
			<Modal modalClass="add-project-modal">
				<div className="modal-header">
					<h4 className="modal-title">Add New Project</h4>
				</div>
				<div className="modal-body no-overflow" style={{ width: "100%" }}>
					<div id="newProjectForm">
						<div className="form-group">
							<p>
								<small>* denotes a required field</small>
							</p>
							<div className="row">
								<div className="col-sm-6">
									Project Number:
									<input className="form-control" name="projectNumber" />
								</div>
								<this.csgComponent class="col-sm-6">
									Client*:
									<select
										className="form-control"
										name="clientId"
										onChange={this._handleClientChange}
									>
										<option> Select... </option>
										{clientOpts}
									</select>
								</this.csgComponent>
							</div>
							<div className="row">
								<div className="col-sm-6">
									Brand / End User:
									<select className="form-control" name="brandId">
										<option> Select... </option>
										{brandOpts}
									</select>
								</div>

								<div className="col-sm-6">
									Service Level:
									<select className="form-control" name="svcLvl">
										<option> Select... </option>
										{levelOpts}
									</select>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									Type:
									<select className="form-control" name="projectType">
										<option> Select... </option>
										{typeOpts}
									</select>
								</div>
								<div className="col-sm-6">
									Project Name/Description*:
									<input className="form-control" name="projectName" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									Site Id*:
									<input className="form-control" name="siteId" />
								</div>
								<div className="col-sm-6">
									Internal PM*:
									<FilterableSelect
										id="internalPM-select"
										items={this.state.internalPMs}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									Site Super:
									<FilterableSelect
										id="site-super-select"
										items={this.state.internalPMs}
									/>
								</div>
								<div className="col-sm-6">
									Client PM:
									<FilterableSelect
										id="externalPM-select"
										items={this.state.clientPMs}
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									Budget:
									<input type="number" className="form-control" name="budget" />
								</div>
								<div className="col-sm-6">
									Address*:
									<input className="form-control" name="address" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-6">
									City*:
									<input className="form-control" name="city" />
								</div>
								<div className="col-sm-2">
									State*:
									<input className="form-control" name="state" />
								</div>
								<div className="col-sm-3">
									Zip*:
									<input className="form-control" name="zip" />
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12">
									Project Team Members:
									<div style={{ padding: "3px" }}>
										<JoinedList
											availableItems={this.state.internalPMs}
											selectedItems={this.state.selectedTeam}
											title=""
											onChange={(items) =>
												this.setState({ selectedTeam: items })
											}
										/>
									</div>
								</div>
								<div className="col-sm-6" style={{ marginTop: "5px" }}>
									Copy Team From Project:
									<FilterableSelect
										id="copy-project-team"
										items={this.props.projects.map(
											(p) => new ListItem(p.id, `${p.name} (${p.clientName})`)
										)}
										onChange={this._handleCopyTeamChange}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button
						className="btn btn-sm btn-blue"
						onClick={this.save}
						disabled={this.props.isLoading}
					>
						Add
					</button>
					<span
						className="btn btn-sm btn-outline-secondary"
						onClick={this.props.close}
					>
						Close
					</span>
				</div>
			</Modal>
		);
	}
}

export default connect<
	ProjectStore.Store,
	typeof ProjectStore.actionCreators,
	FormProps
>(
	//@ts-ignore
	(state: ApplicationState) => state.projects,
	ProjectStore.actionCreators
)(NewProjectForm);
