import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';

interface State {
    email: string;
    submitted: boolean;
    errMessage?: string;
}

interface Props {

}

export default class ForgotPassword extends React.Component<Props, State> {
    constructor(props) {        
        super(props)
        this.state = {
            email: '',
            submitted: false
        }
    }

    _updateEmail = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ email: e.currentTarget.value })

    _request = () => {
        const { email } = this.state;

        fetch(`account/forgotpassword`,
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: email })
            }
        )
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (!resOk) this.setState({ errMessage: data.message })
                else this.setState({ submitted: true })
            })
    }

    render() {
        const { email, submitted, errMessage } = this.state;
        console.log("ForgotPassword loaded");

        if (!submitted) {
            return (
                <div id='loginForm' className='login-page'>
                    <h2>Reset Password</h2>
                    <div className='row col-md-9'>
                        <hr />
                        {errMessage &&
                            <div className='error-message'>
                                <p className='danger text-danger'>{errMessage}</p>
                            </div>
                        }
                        <div className='form-group'>
                            <label>Email</label>
                            <input className='form-control' type='email'
                                onChange={this._updateEmail} value={email} />
                        </div>
                        <div className='form-group'>
                            <button className='btn btn-outline-secondary' onClick={this._request}>Submit</button>
                        </div>
                        <div className='form-group'>
                            <p>Click <a href='/'>here</a> to return to the login page.</p>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="loginForm">
                    <h2>Password reset requested</h2>
                    <hr />
                    <p>Please check your email for further instructions to recover your password. Click <a href='/login'>here</a> to return to the login page.</p>
                </div>
            )
        }
    }
}