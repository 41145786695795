import * as React from 'react';

import Modal from '../components/Modal';
import { VendorInfoForm } from './VendorInfoForm';

interface Props {
    id: number;
    vendorName: string;
    showModal?: boolean;
}

export const VendorInfoModal = (props: Props) => {
    const { id, vendorName } = props;

    const [showModal, setShowModal] = React.useState(props.showModal || false);

    React.useEffect(() => {
        setShowModal(Boolean(props.showModal));
    }, [props.showModal])

    return (
        <div className='client-info-link'>
            <span onClick={() => setShowModal(true)}>{vendorName}</span>
            {showModal &&
                <Modal modalClass='vendor-info-modal' dialogStyle={{ maxWidth: '700px' }}>
                    <div className='modal-body'>
                        { // @ts-ignore 
                            <VendorInfoForm id={id} />
                        }
                    </div>
                    <div className='modal-footer'>
                        <span className='btn btn-sm btn-outline-secondary' onClick={() => setShowModal(false)}>Close</span>
                    </div>
                </Modal>
            }
        </div>
    )

}