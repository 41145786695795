import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileDownloader } from "../components/FileDownloader";
import FilterableSelect from "../components/FilterableSelect";
import { formatDateTime } from "../helpers/formatters";
import { ApplicationState } from "../store/index";
import { TransmittalPostModel } from "./TransmittalDetailModal";
import { TransmittalFileSelector } from "./TransmittalFileSelector";
import { Transmittal, TransmittalStatus } from "./TransmittalGrid";
import { ListItem } from "../interfaces/interfaces";
import { getFileNameFromPath } from "../helpers/misc";
import { getStatusText } from "./TransmittalGrid";
import { actionCreators as globalActionCreators } from "../store/global";
import { TransmittalRecipientComment } from "./TransmittalRecipientComment";
import { MessageBox } from "../components/MessageBox";
import { FilterableSelectContainer } from "src/components/FilterableSelectContainer";
import $ from "jquery";

interface Props {
	projectId: number | null;
	transmittalId: number | null;
	startingDir?: string;
	inModal: boolean;
	close?: () => void;
}

export const TransmittalDetail = (props: Props) => {
	const [postModel, setPostModel] = React.useState({
		title: "",
		projectId: props.projectId,
		message: "",
		recipientIds: [],
		documentPaths: [],
		sentDateTime: null,
		send: false,
		sendable: false,
		userId: "",
		allowComments: false,
		forApproval: false,
		status: 0,
	} as TransmittalPostModel);
	const [transmittal, setTransmittal] = React.useState({} as Transmittal);
	const [showFileSelector, setShowFileSelector] = React.useState(false);
	const [message, setMessage] = React.useState(undefined as string | undefined);
	const [bodyScroll, setBodyScroll] = React.useState(false);
	const recipientRef = React.useRef<HTMLDivElement>(null);

	const globalStore = useSelector((s: ApplicationState) => s.global);
	const userStore = useSelector((s: ApplicationState) => s.user);
	const dispatch = useDispatch();

	// @ts-ignore
	const userItems = globalStore.users
		.filter((x, i) => postModel.recipientIds.indexOf(x.userId) === -1)
		.map((x) => new ListItem(x.userId, x.fullName));
	const isSender =
		userStore.user !== undefined && postModel.userId === userStore.user.userId;
	const isRecipient =
		userStore.user !== undefined &&
		postModel.recipientIds.indexOf(userStore.user.userId) !== -1;
	const editable =
		props.transmittalId === null ||
		(postModel.status === TransmittalStatus.Draft && isSender);
	const receipt =
		isRecipient && transmittal.recipients
			? transmittal.recipients.find(
					//@ts-ignore
					(x) => x.recipientId === userStore.user.userId
			  )
			: undefined;

	const _approve = () => {
		if (props.transmittalId) {
			fetch(`api/transmittal/approve?id=${props.transmittalId}`, {
				method: "PUT",
			})
				// @ts-ignore
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setTransmittal({ ...data });
						setPostModel({
							...data,
							recipientIds: data.recipients.map((x) => x.recipientId),
							documentPaths: data.documents.map((x) => x.documentAbsolutePath),
						});
					} else {
						setMessage(data.message);
					}
				});
		}
	};

	const _cancel = () => {
		if (props.transmittalId) {
			fetch(`api/transmittal/cancel?id=${props.transmittalId}`, {
				method: "PUT",
			})
				// @ts-ignore
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setTransmittal({ ...data });
						setPostModel({
							...data,
							recipientIds: data.recipients.map((x) => x.recipientId),
							documentPaths: data.documents.map((x) => x.documentAbsolutePath),
						});
					} else {
						setMessage(data.message);
					}
				});
		}
	};

	const _changeUserSelect = (id: string) => {
		if (id !== "") {
			setPostModel({
				...postModel,
				recipientIds: postModel.recipientIds.concat([id]),
			});
			const filterableSelectInputs = document.querySelectorAll(
				"#add-recipient input"
			);
			[].forEach.call(filterableSelectInputs, (i: HTMLInputElement) => {
				i.value = "";
			});
		}
	};

	const _getTransmittalDetail = (id: number) => {
		fetch(`api/transmittal/detail?id=${id}`)
			// @ts-ignore
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setTransmittal({ ...data });
					setPostModel({
						...data,
						recipientIds: data.recipients.map((x) => x.userId),
						documentPaths: data.documents.map((x) => x.documentAbsolutePath),
					});
				} else {
					setMessage(data.message);
				}
			});
	};

	const _verifySendable = () => {
		const sendable =
			postModel.title.length > 0 &&
			postModel.recipientIds.length > 0 &&
			postModel.documentPaths.length > 0 &&
			editable;
		if (postModel.sendable !== sendable) {
			setPostModel({ ...postModel, sendable: sendable });
		}
	};

	const _saveComment = () => {
		const comment = (
			document.getElementById("recipient-comment") as HTMLTextAreaElement
		).value;
		fetch(
			`api/transmittal/comment?transmittalId=${
				props.transmittalId
			}&comment=${encodeURIComponent(comment)}`,
			{ method: "PUT" }
		)
			// @ts-ignore
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setTransmittal({ ...data });
				} else {
					setMessage(data.message);
				}
			});
	};

	const _send = () => {
		if (postModel.sendable) {
			const model = { ...postModel, send: true } as TransmittalPostModel;
			// If we're looking at an existing transmittal save the changes and send the transmittal
			if (props.transmittalId) {
				_saveEdit(model);
			}
			// Otherwise we need to post a new transmittal and then send it
			else {
				_saveNew(model);
			}
		}
	};

	const _saveNew = (model: TransmittalPostModel) => {
		fetch(`api/transmittal/add`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				title: model.title,
				projectId: model.projectId,
				message: model.message,
				recipientIds: model.recipientIds,
				documentPaths: model.documentPaths,
				send: model.send,
				allowComments: model.allowComments,
				forApproval: model.forApproval,
			}),
		})
			// @ts-ignore
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk && props.close) props.close();
				else setMessage(data.message);
			});
	};

	const _saveEdit = (model?: TransmittalPostModel) => {
		if (model === undefined) model = postModel;
		fetch(`api/transmittal/edit?id=${props.transmittalId}`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(model),
		})
			// @ts-ignore
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk && props.close) props.close();
				else setMessage(data.message);
			});
	};

	//React.useEffect(() => {
	//    if (!globalStore.users || !globalStore.users.length) {
	//        dispatch(globalActionCreators.getUsers());
	//    }
	//}, [])

	React.useEffect(() => {
		_verifySendable();
	}, [postModel.title, postModel.recipientIds, postModel.documentPaths]);

	React.useEffect(() => {
		if (props.transmittalId) {
			_getTransmittalDetail(props.transmittalId);
		}
	}, [props.transmittalId]);

	const header = props.inModal ? (
		<h5>{props.transmittalId ? "Transmittal Detail" : "Create Transmittal"}</h5>
	) : (
		<h4>Transmittal Detail</h4>
	);

	const handleTop = React.useCallback(
		(ref: HTMLDivElement | null) => {
			if ($(".modal-body")) {
				$(".modal-body").click();
			}
			ref?.querySelector("input")?.blur();
			if (ref) {
				return ref.getBoundingClientRect().top + ref.offsetHeight + "px";
			}
			return "";
		},

		[bodyScroll, recipientRef.current]
	);

	const handleWidth = React.useCallback(
		(ref: HTMLDivElement | null) => {
			if (ref) {
				return (ref.lastChild as HTMLDivElement)?.offsetWidth + "px";
			}
			return "";
		},
		[bodyScroll, recipientRef.current]
	);

	return (
		<React.Fragment>
			<MessageBox
				message={message}
				clearMessage={() => setMessage(undefined)}
			/>
			<div className="modal-header">{header}</div>
			<div
				onScroll={() => setBodyScroll(!bodyScroll)}
				className="modal-body transmittal-detail custom-scrollbar"
			>
				{showFileSelector && props.startingDir && (
					<TransmittalFileSelector
						startingDir={props.startingDir}
						selectedFiles={postModel.documentPaths}
						updateSelected={(s) =>
							setPostModel({ ...postModel, documentPaths: s })
						}
						close={() => setShowFileSelector(false)}
					/>
				)}
				{editable && (
					<div style={{ marginBottom: "1em" }}>
						<small>* indicates a required field</small>
					</div>
				)}
				<div className="form-group">
					<b>Title{editable && "*"}</b>
					{editable ? (
						<input
							className="form-control"
							value={postModel.title}
							onChange={(e) =>
								setPostModel({ ...postModel, title: e.currentTarget.value })
							}
						/>
					) : (
						<span>{postModel.title}</span>
					)}
				</div>
				<div className="form-group">
					<b>Status</b>
					<span>{getStatusText(postModel.status)}</span>
				</div>
				{transmittal.status > TransmittalStatus.Draft && (
					<div className="form-group">
						<b>Sender</b>
						<span>{transmittal.userFullName}</span>
					</div>
				)}
				<div className="form-group">
					<b>Recipients{editable && "*"}</b>
					<FilterableSelectContainer
						top={handleTop(recipientRef.current)}
						ulWidth={handleWidth(recipientRef.current)}
						ref={recipientRef}
						className="recipient-list"
					>
						{postModel.recipientIds.map((x) => {
							// @ts-ignore
							const userName = globalStore.users.find(
								(u) => u.userId === x
							)?.fullName;
							const recipient = transmittal.recipients
								? transmittal.recipients.find((y) => y.userId === x)
								: undefined;
							const reviewed = recipient
								? recipient.hasReviewedTransmittal
								: false;
							return (
								<React.Fragment key={x}>
									<div className="recipient-line" key={x}>
										<span>{userName}</span>
										{reviewed && (
											<span
												className="fas fa-check"
												style={{ color: "rgba(0, 150, 0, 0.9)" }}
												title="User has viewed this transmittal"
											></span>
										)}
										{editable && (
											<span
												className="fas fa-times"
												onClick={() =>
													setPostModel({
														...postModel,
														recipientIds: postModel.recipientIds.filter(
															(u) => u !== x
														),
													})
												}
											></span>
										)}
										{recipient && recipient.approvalTimestamp ? (
											<div>
												<small>
													Approved on{" "}
													{formatDateTime(recipient.approvalTimestamp)}
												</small>
											</div>
										) : (
											[]
										)}
									</div>
								</React.Fragment>
							);
						})}
						{editable && (
							<FilterableSelect
								key={new Date().getTime()}
								id="add-recipient"
								items={userItems}
								onChange={_changeUserSelect}
							/>
						)}
					</FilterableSelectContainer>
				</div>
				<div className="form-group">
					<b>Files{editable && "*"}</b>
					<div className="file-list">
						{postModel.documentPaths.map((x) => {
							// @ts-ignore
							const fileName = getFileNameFromPath(x);
							return (
								<div className="file-line" key={x}>
									{fileName}
								</div>
							);
						})}
						{editable && (
							<button
								className="btn btn-x-sm btn-outline-primary"
								onClick={() => setShowFileSelector(true)}
							>
								Select Files
							</button>
						)}
						{postModel.status === TransmittalStatus.Sent && (
							<span title="Download a zip of the transmittal files">
								<FileDownloader
									actionPath={`api/transmittal/downloadzip`}
									fileType="zip"
									queryParams={{ id: props.transmittalId }}
								/>
							</span>
						)}
					</div>
				</div>
				<div className="form-group">
					<b>Message</b>
					{editable ? (
						<textarea
							className="form-control"
							value={postModel.message}
							onChange={(e) =>
								setPostModel({ ...postModel, message: e.currentTarget.value })
							}
							rows={4}
						></textarea>
					) : (
						<span>{postModel.message}</span>
					)}
				</div>
				{editable && (
					<div
						className="form-group"
						title="Allow the recipients to add comments to the transmittal header record. Will not modify the transmitted documents."
					>
						<b>Allow Comments</b>
						{
							<input
								type="checkbox"
								style={{ marginTop: "0.65em" }}
								defaultChecked={transmittal.allowComments}
								disabled={!editable}
								onChange={(e) =>
									setPostModel({
										...postModel,
										allowComments: e.currentTarget.checked,
									})
								}
							/>
						}
					</div>
				)}
				{editable && (
					<div
						className="form-group"
						title="Request that the recipients provide approval for the transmittal once they have reviewed it."
					>
						<b>For Approval</b>
						{
							<input
								type="checkbox"
								style={{ marginTop: "0.65em" }}
								defaultChecked={transmittal.forApproval}
								disabled={!editable}
								onChange={(e) =>
									setPostModel({
										...postModel,
										forApproval: e.currentTarget.checked,
									})
								}
							/>
						}
					</div>
				)}
				{postModel.status > TransmittalStatus.Draft && (
					<div className="form-group">
						<b>Sent At</b>
						<span>{formatDateTime(postModel.sentDateTime || "")}</span>
					</div>
				)}
				{props.transmittalId &&
				isSender &&
				postModel.status !== TransmittalStatus.Canceled ? (
					<div className="form-group">
						<span
							className="btn btn-x-sm btn-red fas fa-trash-alt"
							onClick={_cancel}
							title="Cancel this transmittal"
						></span>
					</div>
				) : (
					[]
				)}
				{/*
                    // @ts-ignore */}
				{transmittal.forApproval &&
				receipt !== undefined &&
				!receipt.isApproved &&
				transmittal.status === TransmittalStatus.Sent ? (
					<div className="form-group">
						<button className="btn btn-sm btn-green" onClick={_approve}>
							Approve
						</button>
					</div>
				) : (
					[]
				)}
				{/*
                    // @ts-ignore */}
				{receipt !== undefined &&
				(receipt.comments == null || receipt.comments.length === 0) &&
				transmittal.allowComments ? (
					<div className="form-group">
						<b>Comments</b>
						{/* 
                         // @ts-ignore */}
						<textarea
							className="form-control"
							id="recipient-comment"
							defaultValue={receipt.comments}
							rows={2}
						/>
						<button className="btn btn-sm btn-blue" onClick={_saveComment}>
							Save
						</button>
					</div>
				) : (
					[]
				)}
				{transmittal.status > TransmittalStatus.Draft &&
				transmittal.allowComments ? (
					<div className="transmittal-comments">
						<h5>All Comments</h5>
						{transmittal.recipients.map((x) => (
							<TransmittalRecipientComment recipient={x} />
						))}
					</div>
				) : (
					[]
				)}
			</div>

			<div className="modal-footer">
				{postModel.sendable && (
					<button className="btn btn-sm btn-blue" onClick={_send}>
						Send
					</button>
				)}
				{props.transmittalId === null && (
					<button
						className="btn btn-sm btn-green"
						onClick={() => _saveNew(postModel)}
					>
						Save Draft
					</button>
				)}
				{props.transmittalId !== null && editable && (
					<button className="btn btn-sm btn-green" onClick={() => _saveEdit()}>
						Save
					</button>
				)}
				{props.close && (
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={props.close}
					>
						Close
					</button>
				)}
			</div>
		</React.Fragment>
	);
};
