import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '../Loader';

export default () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  function _logout() {
    setLoading(true);
    dispatch({ type: 'FAILED_AUTH ' });
    dispatch({ type: 'CLEAR_USER' });
    window.location.href = '/account/logout';
  }

  return (
    <div onClick={_logout}>
      <Loader loading={loading} />
      <span className="fas fa-sign-out-alt"></span>&nbsp; Logout
    </div>
  );
};
