import * as React from 'react';
import * as DetailStore from '../store/projectDetail';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { ListItem, MilestoneEntryVm } from '../interfaces/interfaces'
import Modal from './Modal';

interface State {
    materials: ListItem[],
    showNewMatDialog: boolean,
    milestones: ListItem[]
}

interface DialogProps {
    //projectId: number
    pId: number
    clientId?: number | string
    allowCreate?: boolean
    close: () => any
}

type SelectorProps =
    DialogProps & typeof DetailStore.actionCreators

class MaterialSelector extends React.Component<SelectorProps, State> {
    //export const AddItemDialog: React.SFC<DialogProps> = props => {
    constructor(props: SelectorProps) {
        super(props);
        this.state = {
            materials: [],
            showNewMatDialog: false,
            milestones: []
        }
    }

    componentWillMount() {
        this._getMilestones(this.props.pId)
    }

    //componentWillUpdate() {
    //    this.getMaterials()
    //}

    private _getMilestones = (pId?: number | string) => {
        fetch(`api/Milestone/ForProject?id=${pId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                const dataItems = (data as MilestoneEntryVm[]).map(x => {
                    return { id: x.milestoneId, value: x.milestoneName } as ListItem
                })
                this.setState({
                    milestones: dataItems
                })
            });
    }

    private save = () => {
        const sel = document.getElementById('milestoneSelector') as HTMLSelectElement;
        const opt = sel.querySelector('option:checked') as HTMLOptionElement;
        const name = document.getElementById('workItemName') as HTMLInputElement;
        //const chkbx = document.getElementById('includeInstallBox') as HTMLInputElement        
        if (opt && name) {
            if (opt.value !== '0' && name.value.length) {
                this.props.addMaterialEntry(name.value, parseInt(opt.value))
                this.props.close()
            }
            else {
                alert('Error: You must select a milestone and enter a name for the work item')
            }
        }
    }

    public render() {
        const opts = this.state.milestones ?
            this.state.milestones.map(i => <option key={i.id} value={i.id}>{i.value}</option>)
            : []
        return (            
            <Modal dialogStyle={{maxWidth: '450px'}}>
                    <div className="modal-header">
                        <h4 className="modal-title">Add Work Item</h4>
                    </div>
                    <div className="modal-body">
                        <p>Work Item Name</p>
                        <input className='form-control' id='workItemName' />
                        <br />
                        <p>Linked Milestone</p>
                        <select className='form-control' id='milestoneSelector' key={opts.length.toString()}>
                            <option value="0"></option>
                            {opts}
                        </select>
                    </div>
                    <div className="modal-footer">
                        <span className="btn btn-sm btn-green" onClick={this.save.bind(this)}>Save</span>
                        <span className="btn btn-sm btn-outline-secondary" onClick={this.props.close}>Close</span>
                    </div>
                </Modal>
        )
    }
}

export default connect<DetailStore.Store, typeof DetailStore.actionCreators, DialogProps>(
    //@ts-ignore
    (state: ApplicationState) => state.detail,
    DetailStore.actionCreators,
)(MaterialSelector) //as typeof KeyMilestoneStartModal