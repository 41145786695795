import * as React from 'react';
import DefaultFolderForm from './DefaultFolders';
import EditClient from './EditClient';
import AddClient from './AddClient';
import ProjectRolesForm from './ProjectRoles';
import { ProjectLinksForm } from './ProjectLinks';
import { VendorComplianceConfig } from './VendorComplianceConfig';
import { WorkRegions } from './WorkRegions';

interface State {    
    loading: boolean
}


export default class GlobalConfig extends React.Component<any, State> {
    constructor(props) {
        super(props)
        this.state = {           
            loading: false
        }
    }  

    render() {
        
        return (
            <div className='config-tab-content'>                
                <br />
                <div className='config-tab-tiles'>                    
                    <div className='config-tab-tile'>
                        <AddClient />
                    </div>
                    <div className='config-tab-tile'>
                        {/*@ts-ignore */}
                        <EditClient  />
                    </div> 
                    <div className='config-tab-tile'>
                        <DefaultFolderForm controller='GlobalConfig' getAction='DefaultFolders'
                            postAction='AddFolder' putAction='EditFolder'
                            deleteAction='DeleteFolder' rootPath='{Project Number}'
                            params={{}}
                            headerLabel='Default Project Folder Structure'
                        />
                    </div>
                    <div className='config-tab-tile'>
                        <ProjectRolesForm />
                    </div>
                    <div className='config-tab-tile'>
                        <ProjectLinksForm />
                    </div>
                    {/*<div className='config-tab-tile'>*/}
                    {/*    <WorkRegions />*/}
                    {/*</div>*/}
                    <div className='config-tab-tile' style={{width: '100%' }}>
                        <VendorComplianceConfig />
                    </div>                    
                </div>                
            </div>
        )
    }
}