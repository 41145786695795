import { Submittal, SubmittalStatus } from "../interfaces/interfaces";
import { lPad } from "./formatters";

export const hideGanttTooltip = () => {
	const chart = document.querySelector(
		".gantt-container.no-tooltip"
	) as HTMLDivElement;
	if (chart) {
		chart.addEventListener("mouseover", mutationCallback.bind(null, chart));
	}
};

export const clearTooltipListener = () => {
	const chart = document.querySelector(
		".gantt-container.no-tooltip"
	) as HTMLDivElement;
	if (chart) {
		chart.removeEventListener("mouseover", mutationCallback);
	}
};

const mutationCallback = (chart) => {
	const observer = new MutationObserver((nodes) => {
		Array.prototype.forEach.call(nodes, (node) => {
			if (node.addedNodes.length > 0) {
				Array.prototype.forEach.call(node.addedNodes, (addedNode) => {
					if (
						addedNode.tagName === "rect" &&
						addedNode.getAttribute("fill") === "white"
					) {
						//addedNode.setAttribute('fill', 'transparent')
						//addedNode.setAttribute('stroke', 'transparent')

						const labelNodes =
							addedNode.parentNode.getElementsByTagName("text");
						const removedY = labelNodes[3].getAttribute("y");
						labelNodes[3].setAttribute("fill", "transparent");
						labelNodes[4].setAttribute("fill", "transparent");
						if (labelNodes[5]) labelNodes[5].setAttribute("y", removedY);
						if (labelNodes[6]) labelNodes[6].setAttribute("y", removedY);
						if (labelNodes[7])
							labelNodes[7].setAttribute(
								"y",
								(parseInt(removedY) + 30).toString()
							);

						if (labelNodes.length > 7) addedNode.setAttribute("height", "146");
						else addedNode.setAttribute("height", "116");
						//addedNode.setAttribute('height', (parseInt(addedNode.getAttribute('height')) -30).toString())
						//Array.prototype.forEach.call(addedNode.parentNode.getElementsByTagName('text'), (label) => {
						//    label.setAttribute('fill', 'transparent')
						//})
					}
				});
			}
		});
	});
	observer.observe(chart, {
		childList: true,
		subtree: true,
	});
};

export const submittalIsFinished = (submittal: Submittal) => {
	return (
		submittal.status == SubmittalStatus.Approved ||
		submittal.status == SubmittalStatus.Canceled ||
		submittal.status == SubmittalStatus.Rejected
	);
};

export const submittalIsUnsent = (submittal: Submittal) => {
	return (
		submittal.status === SubmittalStatus.Draft ||
		submittal.status === SubmittalStatus.CreatorReview ||
		submittal.status === SubmittalStatus.SentToSub
	);
};

export const handleKeyPress = (s: string, c: number, shift: boolean) => {
	// If they hit shift just ignore it
	if (c === 16) return s;
	// If they hit backspace (8) remove a character
	if (c === 8) s = s.length ? s.substr(0, s.length - 1) : "";
	else if (!shift && c === 187) s = s + "=";
	// Characters like period, comma, hyphen.
	else if ((c >= 48 && c <= 57) || (c >= 187 && c <= 191)) {
		if (shift) s = s + specialCharMap[c];
		else {
			if (c >= 187 && c <= 191) s = s + String.fromCharCode(c - 144);
			else s = s + String.fromCharCode(c);
		}
	}
	// If they hit a valid number or letter, append it
	else if ((c >= 58 && c <= 90) || (c >= 97 && c <= 122))
		s = s + String.fromCharCode(c).toLowerCase();
	// If they hit space, add a space
	else if (c === 32) s = s + " ";

	return s;
};

export const getViewportSize = () => {
	const width = window.innerWidth;
	const height = window.innerHeight;

	return { width, height };
};

export const hideFooter = () => {
	const footer = document.getElementsByTagName("footer")[0];
	if (footer) footer.setAttribute("style", "visibility:hidden");
};

export const showFooter = () => {
	const footer = document.getElementsByTagName("footer")[0];
	if (footer) footer.setAttribute("style", "visibility:visible");
};

export const isImage = (fileName: string) => {
	const imageExtensions = [
		"jpg",
		"jpeg",
		"jpe",
		"png",
		"bmp",
		"jiff",
		"tiff",
		"heif",
		"heic",
	];
	const split = fileName.split("."),
		fileExt = split[split.length - 1];
	return imageExtensions.indexOf(fileExt.toLowerCase()) !== -1;
};

export const debounce = (func: any, waitFor: number) => {
	let timeout = 0;

	const debounced = (...args: any[]) => {
		clearTimeout(timeout);
		//@ts-ignore
		timeout = setTimeout(() => func(...args), waitFor);
	};

	return debounced;
};

const specialCharMap = [
	...Array(48),
	")",
	"!",
	"@",
	"#",
	"$",
	"%",
	"^",
	"&",
	"*",
	"(",
	...Array(129),
	"+",
	"<",
	"_",
	">",
	"?",
];

export const cleanDateString = (dateStr: string) => {
	const d = new Date(dateStr);

	return `${lPad((d.getMonth() + 1).toString(), 2, "0")}-${lPad(
		d.getDate().toString(),
		2,
		"0"
	)}-${d.getFullYear()} ${d.getHours()}:${lPad(
		d.getMinutes().toString(),
		2,
		"0"
	)}`;
};

export const scrollToBottom = (selector: string) => {
	const ele = document.querySelector(selector);
	if (ele) ele.scrollTop = ele.scrollHeight;
};

export const getFileNameFromPath = (path: string) => {
	if (path.indexOf("\\") !== -1) {
		return path.split("\\")[path.split("\\").length - 1];
	} else {
		return path.split("/")[path.split("/").length - 1];
	}
};
