import * as React from 'react';
import { DateRange } from 'moment-range';

import { camelize, pascalizeAndSeparate } from '../helpers/formatters';
import { ListItem, DateRangeFilter, ProjectVm, OrderedListItem, FilterOption } from '../interfaces/interfaces';

import { MessageBox } from '../components/MessageBox';
import { actionCreators as graphActionCreators } from '../store/graphs';
import { actionCreators as graphDataActionCreators, GraphDataFilters } from '../store/graphDataStore';
import { actionCreators as metaActionCreators } from '../store/metaStore';
import { GraphPart, CustomGraphInfo } from '../charts/GraphPart';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { GraphsLoader } from '../loaders/MyGraphsLoader';
import Modal from '../components/Modal';
import { MetaObjectType, metaObjectTypeToString } from '../interfaces/enums';
import { AddGraphFilterModal } from './AddGraphFilterModal';

interface State {
    graphParts: CustomGraphInfo[];
    userFilters: UserFilter[];
    filterOptions: ListItem[];
    showAddFilterModal: boolean;
    allDimensions: ListItem[];
    message?: string;
}

export interface UserFilter {
    id: number;
    field: string;
    label: string;
    defaultValue: any;
    dataSource: MetaObjectType;
    global: boolean;
    graphId: number | null;
}

export enum DashboardScope {
    Global,
    Project,
    Client
}

type Props = { scope: DashboardScope }; // ProjectStore.Store & typeof ProjectStore.actionCreators

export const DashboardGraphs = (props: Props) => {

    const [showAddGraphModal, setShowAddGraphModal] = React.useState(false);
    const [showAddFilterModal, setShowAddFilterModal] = React.useState(false);
    //const [filterOptions, setFilterOptions] = React.useState([] as FilterOption[]);
    const [initTime, setInitTime] = React.useState(new Date().getTime())

    const graphStore = useSelector((s: ApplicationState) => s.graphs);
    const metaStore = useSelector((s: ApplicationState) => s.meta);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(metaActionCreators.getAllObjects(props.scope));
        dispatch(graphActionCreators.getUserGraphsAndFilters(props.scope));
        if (props.scope === DashboardScope.Global) dispatch(graphDataActionCreators.getProjects()); // We need to load projects for filters to work. This may be changed later if we do datatype-specific filters
    }, [])

    const _addGraph = () => {
        const type = (document.getElementById('add-graph-data-type') as HTMLSelectElement).value
        dispatch(graphActionCreators.addGraph(parseInt(type)));
        setShowAddGraphModal(false);
    }

    const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const field = e.currentTarget.getAttribute('name') || '';
        const dataSource = e.currentTarget.getAttribute('data-source') || '';
        const filter = graphStore.userFilters.find(x => camelize(x.field) === field && x.dataSource === parseInt(dataSource));
        // @ts-ignore
        dispatch(graphActionCreators.updateFilters(field, val, filter.dataSource, filter.global));
    }

    const _graphSelectCallback = (field: string, value: string, dataSource: MetaObjectType) => {
        field = camelize(field);
        dispatch(graphActionCreators.updateFilters(field, value, dataSource, false));

        const input = document.querySelector(`.header input[name="${field}"]`) as HTMLInputElement

        if (input) input.value = value;
    }

    const _clearFilters = () => {
        const filterInputs = document.querySelectorAll('.header .user-filter input');
        if (filterInputs) {
            [].forEach.call(filterInputs, (input: HTMLInputElement) => {
                input.value = ''
            })
        }
        dispatch(graphActionCreators.clearFilters());
    }

    const _saveDefaultFilters = () => {
        const inputs = document.querySelectorAll('.header .filters .user-filter input');
        const values: { filterId, dataSource, field, defaultValue }[] = [];
        // @ts-ignore
        inputs.forEach((i: HTMLInputElement) => {
            values.push({ filterId: i.getAttribute('data-id'), dataSource: i.getAttribute('data-source'), field: i.name, defaultValue: i.value })
        })

        dispatch(graphActionCreators.updateDefaultFilterValues(values));
    }

    const { graphParts, userFilters, loading, message } = graphStore;

    if (loading) return <div className='dashboard-graphs'><GraphsLoader /></div>

    const addGraphModal = <Modal dialogStyle={{ maxWidth: '500px' }}>
        <div className='modal-header'><h4>Add Graph</h4></div>
        <div className='modal-body'>
            <div className='form-group'>
                <label><b>Data Source</b></label>
                <select id='add-graph-data-type' className='form-control'>
                    {metaStore.allObjects.map(x => <option key={x.type} className='form-control' value={x.type}>{x.name}</option>)}
                </select>
            </div>
        </div>
        <div className='modal-footer'>
            <button className='btn btn-sm btn-blue' onClick={_addGraph}>Add</button>
            <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowAddGraphModal(false)}>Cancel</button>
        </div>
    </Modal>

    const graphs = (graphParts && graphParts.length) ? graphParts.map(x => {        
        return (
            <GraphPart key={`${x.id}_${initTime}`} graphInfo={x} filterCallback={_graphSelectCallback} />
        )
    }) : [];

    const filterInputs = userFilters ?
        userFilters.map(x => {
            return (
                <div key={x.id} className='user-filter'>
                    <label className='truncate' title={`${pascalizeAndSeparate(x.label)} (${metaObjectTypeToString(x.dataSource)})`}><b>{x.label} ({metaObjectTypeToString(x.dataSource)})</b></label>
                    <span className='fas fa-times' onClick={() => dispatch(graphActionCreators.deleteFilter(x.id))} title='Delete Filter'></span>
                    <input className='form-control' name={camelize(x.field)} data-id={x.id} data-source={x.dataSource} onChange={_filter} defaultValue={x.defaultValue} />
                </div>
            )
        }) : []

    const activeFilters = graphStore.filterOptions ?
        graphStore.filterOptions
            .filter(x => {
                if (!userFilters || !userFilters.length) return true;
                return userFilters.findIndex(f => camelize(f.field) === camelize(x.prop)) === -1;
            })
            .map(x => {
                return (
                    <div key={x.prop} className='active-filter'>
                        <label className='truncate'><b title={`${pascalizeAndSeparate(x.prop)} (${metaObjectTypeToString(x.dataSource)})`}>{pascalizeAndSeparate(x.prop)} ({metaObjectTypeToString(x.dataSource)})</b></label>
                        <div>{x.value.toLocaleString()}</div>
                        <span className='fas fa-times' onClick={() => _graphSelectCallback(x.prop, '', x.dataSource)}></span>
                    </div>
                )
            }) : []

    const header = props.scope === DashboardScope.Global ?
        <div className='header'>
            <span style={{ fontSize: '1.6em', marginTop: '0.5em' }}>Filters</span>
            <div className='filters'>
                {filterInputs}
                {activeFilters}
                <div>
                    {(filterInputs.length || activeFilters.length) ? <button className='btn btn-sm btn-outline-secondary' onClick={_saveDefaultFilters} title='Apply current input filters as default values for each time you return to this page'>Set Defaults</button> : []}
                    {(filterInputs.length || activeFilters.length) ? <button className='btn btn-sm btn-outline-secondary' onClick={_clearFilters}>Clear</button> : []}
                </div>
            </div>
            <div className='header-buttons'>
                <button className='btn btn-sm btn-blue' onClick={() => setShowAddFilterModal(true)}>Add Filter</button>
                <button className='btn btn-sm btn-blue' onClick={() => setShowAddGraphModal(true)}>Add Graph</button>
            </div>
        </div>
        : 
        <div className='small-header'>
            <h4>Graphs
                <button className='btn btn-sm btn-white' onClick={() => setShowAddGraphModal(true)}>Add Graph</button>
            </h4>
        </div>

    return (
        <div className='dashboard-graphs' >
            <MessageBox message={message} clearMessage={() => dispatch(graphActionCreators.clearMessage)} />
            {header}
            <div className='graphs-container custom-scrollbar' style={{ overflowY: 'auto', maxHeight: '70vh'}}>
                {graphs}
            </div>
            {showAddGraphModal ? addGraphModal : []}
            {showAddFilterModal ? <AddGraphFilterModal close={() => setShowAddFilterModal(false)} /> : []}
        </div>
    )
}