import * as React from 'react';

import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import { SortInfo, UserInfo, VendorProject, VendorVm } from '../interfaces/interfaces';

export const VendorUserGrid = (props: { id: number }) => {
    const [sortInfo, setSortInfo] = React.useState({ sortProp: '', sortAsc: false } as SortInfo);
    const [users, setUsers] = React.useState([] as UserInfo[]);
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        const getUsers = async () => {
            fetch(`api/Vendor/Users?id=${props.id}`)
                .then(res => res.json())
                .then(data => setUsers(data));
        }
        getUsers();
    }, [])

    const _applySort = (users: UserInfo[]) => {
        const { sortAsc, sortProp } = sortInfo;

        if (sortProp) {
            users = users.sort((a, b) => {
                const aVal = a[sortProp], bVal = b[sortProp]
                if (bVal === undefined) return -1;
                if (aVal === bVal) return 0;
                if (sortAsc) return aVal > bVal ? 1 : -1;
                else return aVal > bVal ? -1 : 1;
            })
        }

        return users;
    }

    const _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
        const prop = e.currentTarget.getAttribute('data-for') || undefined;
        setSortInfo({ sortProp: prop || '', sortAsc: !sortInfo.sortAsc })
    }

    const _header = () => {
        const { sortProp, sortAsc } = sortInfo;
        const sortIconClass = sortAsc ? 'sort-icon fas fa-arrow-up' : 'sort-icon fas fa-arrow-down'
        const sortIcon = sortProp ? <span className={sortIconClass}></span> : []

        return (
            <div className='grid-header team-grid-header my-col-20'>
                <div className='col-sm-3'><label data-for='fullName' className='sortable' onClick={_sort}>Name {sortProp === 'fullName' && sortIcon}</label></div>
                <div className='col-sm-3'><label data-for='email' className='sortable' onClick={_sort}>Email {sortProp === 'email' && sortIcon}</label></div>
                <div className='col-sm-3'><label data-for='phoneNumber' className='sortable' onClick={_sort}>Phone {sortProp === 'phoneNumber' && sortIcon}</label></div>
                <div className='col-sm-3'></div>
                <div className='right-button'></div>
            </div>
        )
    }

    const _lines = _applySort(users).map(x => (
        <div key={Math.random()} className='team-grid-line my-col-20'>
            <div className='col-sm-3'>{x.fullName}</div>
            <div className='col-sm-3'>{x.email}</div>
            <div className='col-sm-3'>{x.phoneNumber}</div>
            <div className='col-sm-3'></div>
            <div className='right-button' style={{ top: '8px' }}></div>
        </div>
    ))

    return (
        <div className='grid project-team-grid'>
            {_header()}
            {_lines}
            <MessageBox message={message} clearMessage={() => setMessage('')} />
        </div>
    )
}