
import {
  ProcessingNotification,
  ProcessingStatus,
  VendorVm,
} from "src/interfaces/interfaces";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface VendorsState {
  vendorsAndSubs: VendorVm[]
}

export const initialState: VendorsState = {
  vendorsAndSubs: [] as VendorVm[],
};

const {
  actions: VendorsActions,
  reducer: VendorsReducer,
} = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    getVendorsAndSubs() { },
    setVendorsAndSubcs(
      state,
      action: PayloadAction<{ records: VendorVm[] }>
    ) {
      state.vendorsAndSubs = action.payload.records;
    },
  },
});

export { VendorsActions, VendorsReducer };

export default VendorsReducer;
