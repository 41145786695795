import React from 'react';
import { SafetyKpiCategory, SafetyKpiQuantityType } from 'src/interfaces/enums';
import {
  SafetyKpiTemplateEntry,
  UpdateObject,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';

interface SafetyKPITemplateEntryProps {
  onEntryUpdate: (id: number, info: UpdateObject) => void;
  onEntryRemove: (id: number) => void;
  entry: SafetyKpiTemplateEntry;
}

const StyledDiv = styled.div({
  display: 'flex',
  width: '100%',
  '& .inline-label': {
    lineHeight: 2,
    width: '150px',
  },
  '& input': {
    maxWidth: 'calc(100% - 180px)',
  },
  '& label': {
    alignSelf: 'center',
    marginRight: '16px',
  },
  '& input[type="radio"]': {
    marginLeft: 0,
    marginRight: '8px',
  },
});

const RadioContainerDiv = styled.form({
  width: '100%',
  maxWidth: 'calc(100% - 150px)',
  alignSelf: 'center',
});

const ContainerDiv = styled.div({
  border: '1px solid lightgray',
  borderRadius: '2px',
  padding: '10px',
  marginBottom: '15px',
  '& .form-group:last-of-type': {
    marginBottom: 0,
  },
});

export const SafetyKPITemplateEntry: React.FC<SafetyKPITemplateEntryProps> = ({
  onEntryUpdate,
  onEntryRemove,
  entry,
}) => {
  return (
    <ContainerDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Title</div>
        <input
          required
          className="form-control"
          onBlur={(e) =>
            onEntryUpdate(entry.id, {
              fieldName: 'title',
              value: e.currentTarget.value,
            })
          }
          defaultValue={entry.title}
        />
        <span
          style={{ borderRadius: '50%' }}
          className="btn btn-sm fas fa-trash-alt btn-background-hover"
          title="Delete this entry"
          onClick={() => onEntryRemove(entry.id)}
        ></span>
      </StyledDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Category</div>
        <RadioContainerDiv>
          <input
            type="radio"
            name="kpi-category"
            value={SafetyKpiCategory.Proactive}
            defaultChecked={entry.category === SafetyKpiCategory.Proactive}
            onChange={(e) =>
              onEntryUpdate(entry.id, {
                fieldName: 'category',
                value: e.currentTarget.value,
              })
            }
          />
          <label htmlFor="kpi-category">Proactive</label>
          <input
            type="radio"
            name="kpi-category"
            value={SafetyKpiCategory.Reactive}
            defaultChecked={entry.category === SafetyKpiCategory.Reactive}
            onChange={(e) =>
              onEntryUpdate(entry.id, {
                fieldName: 'category',
                value: e.currentTarget.value,
              })
            }
          />
          <label htmlFor="kpi-category">Reactive</label>
          <input
            type="radio"
            name="kpi-category"
            value={SafetyKpiCategory.Other}
            defaultChecked={entry.category === SafetyKpiCategory.Other}
            onChange={(e) =>
              onEntryUpdate(entry.id, {
                fieldName: 'category',
                value: e.currentTarget.value,
              })
            }
          />
          <label htmlFor="kpi-category">Other</label>
        </RadioContainerDiv>
      </StyledDiv>
      <StyledDiv className="form-group">
        <div className="inline-label">Quantity Type</div>
        <RadioContainerDiv>
          <input
            type="radio"
            name="kpi-quantity"
            value={SafetyKpiQuantityType.Hours}
            defaultChecked={entry.quantityType === SafetyKpiQuantityType.Hours}
            onChange={(e) =>
              onEntryUpdate(entry.id, {
                fieldName: 'quantityType',
                value: e.currentTarget.value,
              })
            }
          />
          <label htmlFor="kpi-category">Hours</label>
          <input
            type="radio"
            name="kpi-quantity"
            value={SafetyKpiQuantityType.Count}
            defaultChecked={entry.quantityType === SafetyKpiQuantityType.Count}
            onChange={(e) =>
              onEntryUpdate(entry.id, {
                fieldName: 'quantityType',
                value: e.currentTarget.value,
              })
            }
          />
          <label htmlFor="kpi-category">Count</label>
        </RadioContainerDiv>
      </StyledDiv>
    </ContainerDiv>
  );
};
