import React from 'react';
import { SafetyKpiTemplate, UpdateObject } from 'src/interfaces/interfaces';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SafetyKPIActions } from 'src/store/safetyKPI/safetyKPI.reducer';
import { SafetyKpiCategory } from 'src/interfaces/enums';
import { SafetyKPITemplateEntry } from './SafetyKPITemplateEntry';

interface SafetyKPITemplateEntriesSectionProps {
  template: SafetyKpiTemplate;
  onEntryUpdate: (id: number, info: UpdateObject) => void;
  onEntryRemove: (id: number) => void;
}

const StyledButton = styled.button`
  margin-top: 15px;
  padding: 6px 0px;
  border-radius: 2px;
  width: 112px;
  font-size: 12px;
`;

export const SafetyKPITemplateEntriesSection: React.FC<
  SafetyKPITemplateEntriesSectionProps
> = ({ template, onEntryUpdate, onEntryRemove }) => {
  const dispatch = useDispatch();

  return (
    <>
      {template.entries.map((entry) => {
        return (
          <SafetyKPITemplateEntry
            key={entry.id}
            entry={entry}
            onEntryUpdate={onEntryUpdate}
            onEntryRemove={onEntryRemove}
          />
        );
      })}
      <StyledButton
        className="btn btn-sm btn-blue"
        onClick={async () =>
          dispatch(SafetyKPIActions.addTemplateEntry(template.id))
        }
      >
        Add KPI
      </StyledButton>
    </>
  );
};
