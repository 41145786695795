import { api } from '../api';
import { AxiosError, AxiosResponse } from 'axios';
import { VendorVm } from 'src/interfaces/interfaces';

export const getVendorsAndSubs = async () => {
  try {
    const response: AxiosResponse<VendorVm[]> = await api.get(
      `api/vendor/get`,
      {
        //header: {
        //  'Metadata': 'true'
        //}
      }
    );

    if (response.status == 200) {
      return response.data;
    } else {
      console.log(response.status + ' ' + response.data);
    }
  } catch {
    return undefined;
  }

  return undefined;
};
