import * as React from 'react';
import { BBox } from 'geojson';
import { VendorVm, WorkRegion } from '../interfaces/interfaces';
import { centerOfUSA, GeoJson, Map } from '../maps/Map';
import * as cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import useSupercluster from '../maps/useSupercluster';
import { MarkerCluster } from '../maps/MarkerCluster';
import SimpleMarker from '../maps/SimpleMarker';
import { VendorInfoModal } from './VendorInfoModal';
import { CustomizableGrid, MetaFieldInfo } from '../components/CustomizableGrid';
import { CSharpTypeCode, MetaObjectType } from '../interfaces/enums';

export const VendorsMap = (props) => {

  const [loaded, setLoaded] = React.useState(false);
  const [vendors, setVendors] = React.useState([] as VendorVm[]);
  const [filterVal, setFilterVal] = React.useState('');
  const [hoveredId, setHoveredId] = React.useState(0);
  const [clickedId, setClickedId] = React.useState(0);
  const [firstVisibleRow, setFirstVisibleRow] = React.useState(0);
  const [lastVisibleRow, setLastVisibleRow] = React.useState(15);
  const [regions, setRegions] = React.useState([] as WorkRegion[])
  const [bounds, setBounds] = React.useState<BBox>(null);
  const [zoom, setZoom] = React.useState(4);

  const getRegions = () => {
    fetch(`api/globalconfig/workregions`)
      .then(res => res.json())
      .then(data => {
        setRegions(data);
      });
  };

  React.useEffect(() => {
    const getVendors = async () => {
      fetch(`api/Vendor/Get`)
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) setVendors(data);
          setLoaded(true);
        })
    };
    getVendors();
    getRegions();
  }, [])

  const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterVal(e.currentTarget.value);
  }

  const _tableRowMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget) {
      setHoveredId(parseInt(e.currentTarget.getAttribute('data-key') || '0'))
    }
  }

  const _tableRowMouseLeave = (e: React.MouseEvent<HTMLElement>) => setHoveredId(-1)

  const mapPoints = vendors && vendors.map(v => ({
    type: "Feature",
    properties: {
      cluster: false, id: v.id, label: v.name, bgColor: '#005a78'
    },
    geometry: {
      type: "Point",
      coordinates: [v.longitude, v.latitude]
    }
  } as GeoJson))
  // : [{ type: 'Point', properties: { cluster: false, id: 1, label: '' }, geometry: { type: 'Point', coordinates: [centerOfUSA.lng, centerOfUSA.lat] } } as GeoJson]

  const renderMapTableRow = (vendor: VendorVm) => {
    const hoverClass = hoveredId === vendor.id ?
      'map-table-row-hovered' : ''

    return (
      <div className={cx('grid-line', hoverClass)} key={vendor.id} style={{ padding: '8px', margin: '0px 2px' }}>
        <div className='my-col-7 sortable truncate'>
          <VendorInfoModal id={vendor.id} vendorName={vendor.name} showModal={clickedId === vendor.id} />
        </div>
        <div className='my-col-6 sortable truncate'>{vendor.trades.map(x => x.tradeName).join(', ')}</div>
        <div className='my-col-6 sortable truncate'>{vendor.regions.map(x => x.name).join(', ')}</div>
      </div>
      /*
      <NavLink to={`/sub/${vendor.id}`} className={cx('grid-line', hoverClass)} key={vendor.id}
          data-key={vendor.id.toString()}
          onMouseEnter={_tableRowMouseEnter}
          onMouseLeave={_tableRowMouseLeave}               
      >
          <div className='vendor-info'>
              <div><b>{vendor.name}</b></div> &nbsp;&nbsp;
              <div>{vendor.city}, {vendor.state}</div>
          </div>
      </NavLink> */
    )
  }

  // get clusters
  const { clusters, supercluster } = useSupercluster({
    points: mapPoints,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 12 }
  });

  const mapMarkers = clusters.map(x => {
    const [longitude, latitude] = x.geometry.coordinates;
    const { cluster: isCluster, point_count: pointcount, id: id, label: label, bgColor: bgColor } = x.properties;
    if (isCluster) {
      return <MarkerCluster key={x.id} id={x.id} lat={latitude} lng={longitude}
        pointCount={pointcount} totalPointCount={mapPoints.length} />
    }
    return <SimpleMarker key={id} id={id} label={label}
      lat={latitude} lng={longitude}
      hoveredAtTable={id === hoveredId}
      zoom={zoom} bgColor={bgColor}
      navigate={(id) => setClickedId(id)}
    />
  })

  //const tableHoveredIndex = -1 // figure out how to get table hoverered index or 0
  const tableRows = loaded ?
    vendors && vendors.filter(x => x.name.toUpperCase().indexOf(filterVal.toUpperCase()) !== -1).map(v => renderMapTableRow(v)) :
    <StandardGridLoader rowContentHeight={30} rowPadding={7} />

  const cols: MetaFieldInfo[] = [
    { displayName: 'Name', propName: 'name', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 7 },
    { displayName: 'Trades', propName: 'trades.tradeName', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 6 },
    { displayName: 'Regions', propName: 'regions.name', jsType: 'nested', propType: CSharpTypeCode.Object, columnWidth: 6 },
  ]

  return (
    <div style={{ height: '75vh', display: 'flex' }}>
      <div style={{ minWidth: '200px', width: '49%', marginRight: '1%' }}>
        <CustomizableGrid type={MetaObjectType.Vendor} columns={cols} items={vendors} mapCallback={renderMapTableRow} className='vendor-grid' />
      </div>
      <div style={{ height: '100%', width: '50%' }} className='mobile-full-width'>
        <Map
          mapMarkers={mapMarkers}
          handleChange={(z, b) => {
            setZoom(z);
            setBounds([b.nw.lng, b.se.lat, b.se.lng, b.nw.lat])
          }}
          zoom={4}
          hoveredRowKey={hoveredId}
          maxVisibleRows={15}
          visibleRowFirst={firstVisibleRow}
          visibleRowLast={lastVisibleRow}
          onMarkerHover={(id) => setHoveredId(id)}
          regions={regions}
        />
      </div>
    </div>
  )
}