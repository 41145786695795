import * as React from 'react';
import * as cx from 'classnames';

import { K_HINT_HTML_DEFAULT_Z_INDEX, helpers, ClusterMarkerProps } from './mapHelpers';

const SIZE_MULTIPLIER = 50;

export const MarkerCluster = (props: ClusterMarkerProps) => {

    const hoverClass = props.$hover === true ? 'cluster-hover' : ''

    //const imageStyle = {       
    //    backgroundColor: 'rgb(0,90,120)'
    //};

    const sizedPadding = 5 + ((SIZE_MULTIPLIER * props.pointCount) / props.totalPointCount) / 2;
    let verticalPadding = sizedPadding;

    let digitCount = 0, temp = props.pointCount;
    while (temp > 1) {
        ++digitCount;
        temp = temp / 10;
    }

    if (digitCount > 2) {
        // Larger numbers stretch the cluster so we need to adjust the vertical paddings to keep the cluster circular
        verticalPadding += (digitCount - 2) * 5;
    }

    const sizedStyle = {
        //width: `${14 + (SIZE_MULTIPLIER * props.pointCount) / props.totalPointCount}px`,
        //height: `${14 + (SIZE_MULTIPLIER * props.pointCount) / props.totalPointCount}px`,
        //width: '1em',
        //height: '1em',
        padding: `${verticalPadding}px ${sizedPadding}px`
    }

    const zIndexStyle = {
        zIndex: Math.round((/*props.scale ||*/ 1) * 10000) + (props.$hover ? K_HINT_HTML_DEFAULT_Z_INDEX : 0)
    };    

    const markerStyle = helpers.calcClusterMarkerStyle(/*props.scale ||*/ 1, zIndexStyle, sizedStyle, /*imageStyle*/ {})

    return (
        <div key={props.key} style={markerStyle} className={cx('marker-cluster', hoverClass)}>
            <div style={{width: '100%', height: '1em', minWidth: '1em' }} onClick={() => { }}>
                {props.pointCount}
            </div>
        </div>
    )
}