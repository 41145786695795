import * as React from 'react'
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import { SubmittalPackage, SubmittalPackageStatus } from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import * as cx from 'classnames'
import { NavLink } from 'react-router-dom';
import { submittalIsFinished } from '../helpers/misc';
import AddPackageModal from './AddPackageModal';
import { StandardGridLoader } from '../loaders/StandardGridLoader';

interface Props { }

interface State {
    projectId: number,
    packagesUnfiltered: SubmittalPackage[],
    packages: SubmittalPackage[],
    showFilters: boolean,
    showAddModal: boolean,
    loading: boolean,
    saving: boolean,
    message?: string
}

export default class SubmittalPackageGrid extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            projectId: 0,
            packagesUnfiltered: [],
            packages: [],
            showAddModal: false,
            showFilters: false,
            loading: true,
            saving: false,
        }
    }

    componentDidMount() {
        const split = window.location.href.split('/');
        const id = parseInt(split[split.length - 1]) || 0
        this._getAll(id);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.packages !== prevState.submittals || this.state.showFilters) {
            this.fixHeaderWidth();
        }
    }

    fixHeaderWidth = () => {
        const container = document.querySelector('.submittal-grid') as HTMLDivElement;
        const headerRow = container.querySelector('.submittal-grid-header') as HTMLDivElement,
            filterRow = container.querySelector('.submittal-grid-filter') as HTMLDivElement,
            firstRow = container.querySelector('a.striped-row') as HTMLDivElement
        if (firstRow != null) {
            const diff = headerRow.clientWidth - firstRow.clientWidth + 10;
            headerRow.style.width = firstRow.clientWidth + 10 + 'px';

            if (filterRow != null) filterRow.style.width = firstRow.clientWidth + 10 + 'px';
        }
    }

    _getAll = (id: number) => {        
        fetch(`api/SubmittalPackage/All?projectId=${id}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ projectId: id, packages: data, packagesUnfiltered: data, loading: false });
                else this.setState({ projectId: id, message: data.message, loading: false })
            })
    }

    _add = (item: SubmittalPackage) => {
        const newItems = this.state.packagesUnfiltered.slice();
        newItems.unshift(item);

        this.setState({
            packagesUnfiltered: newItems,
            packages: newItems,
            showAddModal: false
        })
    }

    _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value.toUpperCase();
        const prop = e.currentTarget.getAttribute('name') || ''
        const filtered = this.state.packagesUnfiltered.slice()
            .filterByStringProp(prop, val)

        this.setState({ packages: filtered })
    }

    _filterOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const opt = e.currentTarget.querySelector('option:checked') as HTMLOptionElement
        const prop = e.currentTarget.getAttribute('name') || ''

        const filtered = this.state.packagesUnfiltered.slice()
            .filterByStringProp(prop, opt.value.toUpperCase())

        this.setState({ packages: filtered })
    }

    _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
        const prop = e.currentTarget.getAttribute('data-for');
    }

    _toggleFilters = () => this.setState({ showFilters: !this.state.showFilters })

    _toggleAddModal = () => this.setState({ showAddModal: !this.state.showAddModal })

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { packages, showAddModal } = this.state;
        return (
            <div>
                {this.renderGrid(packages)}
                {showAddModal &&
                    <AddPackageModal projectId={this.state.projectId}
                        close={this._toggleAddModal} addCallback={this._add} />
                }
            </div>
        )
    }

    private renderGrid(packages: SubmittalPackage[]) {
        const rows = packages ?
            packages.map(x => {
                let statusClass = '', statusText = '';
                switch (x.status) {
                    case SubmittalPackageStatus.Created: statusClass = 'left-tab-yellow'; statusText = 'Draft'; break;
                    case SubmittalPackageStatus.Sent: statusClass = 'left-tab-blue'; statusText = 'Sent'; break;
                    case SubmittalPackageStatus.Complete: statusClass = 'left-tab-green'; statusText = 'Complete'; break;
                }               

                return (
   
                        <NavLink to={`/package/${x.id}`} key={x.id} className={cx('col-sm-12 striped-row', statusClass)} >
                            <div className='col-sm-1 min-130'>{x.packageNumber}</div>
                            <div className='col-sm-2 min-220'>{x.title}</div>                                                        
                            <div className='col-sm-1 mobile-hide'>{statusText}</div>
                            <div className='col-sm-1 mobile-hide'>{x.userFullName}</div>
                            <div className='col-sm-1 mobile-hide'>{x.submittals.filter(x => !submittalIsFinished(x)).length}</div>
                            <div className='col-sm-1 mobile-hide'>{x.submittals.length}</div>
                            <div className='col-sm-4 mobile-hide'>{formatDate(x.completedTimestamp)}</div>
                        </NavLink>
          
                )
            })
            : []
        return (
            <div className='grid submittal-grid'>
                <Loader loading={this.state.saving} />
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                {this.renderGridHeader()}
                {this.state.showFilters && this.renderFilterRow()}
                {this.state.loading && <div style={{ width: '100%' }}><StandardGridLoader rowCount={14} rowPadding={14} rowContentHeight={18} /></div>}
                <div className='grid-body custom-scrollbar'>{rows}</div>
            </div>
        )
    }

    private renderGridHeader() {
        return (
            <div className='col-sm-12 submittal-grid-header row'>
                <div className='col-sm-1 min-130 sortable'><label onClick={this._sort} data-for='packageNumber'>Package #</label></div>
                <div className='col-sm-2 min-220'><label>Title</label></div>                               
                <div className='col-sm-1 mobile-hide'><label>Status</label></div>
                <div className='col-sm-1 mobile-hide sortable'><label onClick={this._sort} data-for='userFullName'>Created By</label></div>
                <div className='col-sm-1 mobile-hide'><label>Items Remaining</label></div>
                <div className='col-sm-1 mobile-hide'><label>Total Items</label></div>
                <div className='col-sm-4 mobile-hide'><label>Complete Date</label></div>
                <div className='col-sm-1 min-110'>
                    <span className='btn btn-sm btn-blue fas fa-plus' onClick={this._toggleAddModal}></span>
                    <span className='btn btn-sm btn-outline-secondary fas fa-filter' onClick={this._toggleFilters}></span>
                </div>
            </div>
        )
    }

    private renderFilterRow() {
        return (
            <div className='col-sm-12 submittal-grid-filter'>
                <div className='col-sm-1 min-130'><input name='packageNumber' className='form-control' onChange={this._filter} /></div>
                <div className='col-sm-2 min-220'><input name='title' className='form-control' onChange={this._filter} /></div>
                <div className='col-sm-1 mobile-hide'>
                    <select name='status' className='form-control' onChange={this._filterOnSelect}>
                        <option value=''>Select...</option>
                        <option value='0'>Draft</option>
                        <option value='1'>Sent</option>
                        <option value='2'>Complete</option>
                    </select>
                </div>
                <div className='col-sm-1 mobile-hide'><input name='userFullName' className='form-control' onChange={this._filter} /></div>
                <div className='col-sm-offset-1 col-sm-1 mobile-hide'>{/*<input name='approvedTimestamp' className='form-control' type='date' onChange={this._filter} />*/}</div>
            </div>
        )
    }
}