import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CustomReportVm,
  ReportDataQuery,
  ReportResult,
  SchemaPropertyInfo,
  SchemaTableInfo,
} from 'src/interfaces/interfaces';
import { CustomReportType } from '../../interfaces/enums';

export interface CustomReportState {
  queries: ReportDataQuery[];
  reports: CustomReportVm[];
  loadedReport: CustomReportVm | undefined;
  loadedQuery: ReportDataQuery | undefined;
  tables: SchemaTableInfo[];
  relatedTables: Record<string, SchemaTableInfo[]>;
  fields: Record<string, SchemaPropertyInfo[]>;
  availableReportFields: Record<string, SchemaPropertyInfo[]>;
  reportResult: ReportResult | null;
  queriesStatus: 'idle' | 'loading' | 'saving';
  reportsStatus: 'idle' | 'loading' | 'saving' | 'running';
  isAddQuerySuccess: boolean;
}

const initialState: CustomReportState = {
  queries: [],
  reports: [],
  loadedReport: undefined,
  loadedQuery: undefined,
  tables: [],
  relatedTables: {},
  fields: {},
  availableReportFields: {},
  reportResult: null,
  queriesStatus: 'idle',
  reportsStatus: 'idle',
  isAddQuerySuccess: false,
};

const { actions: CustomReportActions, reducer: CustomReportReducer } =
  createSlice({
    name: 'customReport',
    initialState,
    reducers: {
      getAllReports(state) {
        state.reportsStatus = 'loading';
      },
      setAllReports(state, action: PayloadAction<CustomReportVm[]>) {
        state.reports = action.payload;
        state.reportsStatus = 'idle';
      },
      createReport(
        state,
        _action: PayloadAction<{
          reportName: string;
          reportType: CustomReportType;
        }>
      ) {
        state.reportsStatus = 'saving';
      },
      addReport(state, action: PayloadAction<CustomReportVm>) {
        state.reports = [...state.reports, action.payload];
        state.reportsStatus = 'idle';
      },
      runReport(state, _action: PayloadAction<number>) {
        state.reportsStatus = 'running';
      },
      setReportResults(state, action: PayloadAction<ReportResult>) {
        state.reportResult = action.payload;
        state.reportsStatus = 'idle';
      },
      updateReport(state, _action: PayloadAction<CustomReportVm>) {
        state.reportsStatus = 'saving';
      },
      getLoadedReport(state, action: PayloadAction<number>) {
        state.reportsStatus = 'loading';
      },
      setLoadedReport(state, action: PayloadAction<CustomReportVm>) {
        state.loadedReport = action.payload;
        state.reportsStatus = 'idle';
      },
      getAllTables() {},
      setAllTables(state, action: PayloadAction<SchemaTableInfo[]>) {
        state.tables = action.payload;
      },
      getRelatedTables(_state, _action: PayloadAction<string>) {},
      addRelatedTables(
        state,
        action: PayloadAction<Record<string, SchemaTableInfo[]>>
      ) {
        state.relatedTables = { ...state.relatedTables, ...action.payload };
      },
      getFields(_state, _action: PayloadAction<string>) {},
      addFields(
        state,
        action: PayloadAction<Record<string, SchemaPropertyInfo[]>>
      ) {
        state.fields = { ...state.fields, ...action.payload };
      },
      getAllQueries(state) {
        state.queriesStatus = 'loading';
      },
      setAllQueries(state, action: PayloadAction<ReportDataQuery[]>) {
        state.queries = action.payload;
      },
      getQuery(state, _action: PayloadAction<number>) {
        state.queriesStatus = 'loading';
      },
      addQuery(state, _action: PayloadAction<ReportDataQuery>) {
        state.queriesStatus = 'saving';
      },
      addQueryToAll(state, action: PayloadAction<ReportDataQuery>) {
        state.queries = [...state.queries, action.payload];
      },
      updateQuery(state, _action: PayloadAction<ReportDataQuery>) {
        state.queriesStatus = 'saving';
      },
      setLoadedQuery(state, action: PayloadAction<ReportDataQuery>) {
        state.loadedQuery = action.payload;
      },
      setQueriesStatus(
        state,
        action: PayloadAction<'idle' | 'loading' | 'saving'>
      ) {
        state.queriesStatus = action.payload;
      },

      setIsSuccessfulAddQuery(state, action: PayloadAction<boolean>) {
        state.isAddQuerySuccess = action.payload;
      },
    },
  });

export { CustomReportActions, CustomReportReducer };

export default CustomReportReducer;
