import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import * as AdminStore from "../store/admin";
import { Tabs, TabLink, TabContent } from "../tabs";
import RegisterForm from "./AccountRegister";
import EditUserForm from "./EditUser";
import { MessageBox } from "../components/MessageBox";
import { ApplicationState } from "../store";
import ClientLicenseForm from "./ClientLicenseForm";
import { ApiUserManagement } from "./ApiUserManagement";
import { LoginPolicies } from "./LoginSettings";
import { AuthorizationComponent, MyAuthElement } from "../auth/Authorization";
import { AdminOperations } from "./AdminOperations";
import { KnownRoles } from "../auth/auth";
import { RecordTransfer } from "./RecordTransfer";

type Props = AdminStore.Store &
  typeof AdminStore.actionCreators &
  RouteComponentProps<any>;

class AdminHome extends React.Component<Props, any> {
  activeTabStyle = {
    backgroundColor: "rgb(0,99,66)",
    border: "solid 0.5px rgb(0,56,119)",
    color: "white",
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    this.props.getClients();
  }

  superAdminComponent = MyAuthElement([KnownRoles.Admin]); //, ['aa146a3c-1f88-4fb5-9164-6dc01bb9e593']);

  public render() {
    return (
      <div>
        <MessageBox
          message={this.props.message}
          clearMessage={this.props.clearMessage}
        />
        <div className="pageHeader">
          <p>Administration</p>
        </div>
        <Tabs
          name="admin-tabs"
          onChange={(tab: string) => {}}
          activeLinkStyle={this.activeTabStyle}
          renderActiveTabContentOnly={true}
        >
          <div className="tab-links bordered">
            <TabLink to="newUser" component="span">
              <span>New User</span>
            </TabLink>
            <TabLink to="editUser" component="span">
              <span>Edit User</span>
            </TabLink>
            <TabLink to="apiMgmt" component="span">
              <span>API Keys</span>
            </TabLink>
            <TabLink to="licenses" component="span">
              <span>Licenses</span>
            </TabLink>
            <TabLink to="loginPolicies" component="span">
              <span>Login Policies</span>
            </TabLink>
            <TabLink to="adminOperations" component="span">
              <span>Special Ops</span>
            </TabLink>
            <TabLink to="recordTransfer" component="span">
              <span>Record Transfer</span>
            </TabLink>
          </div>
          <div className="content extra-padding bordered">
            <TabContent for="newUser">
              <div className="col-sm-3">
                {/* @ts-ignore */}
                <RegisterForm />
              </div>
            </TabContent>
            <TabContent for="editUser">
              <div className="col-sm-3">
                <EditUserForm />
              </div>
            </TabContent>
            <TabContent for="apiMgmt">
              <ApiUserManagement />
            </TabContent>
            <TabContent for="licenses">
              <div className="col-sm-9">
                <ClientLicenseForm />
              </div>
            </TabContent>
            <TabContent for="loginPolicies">
              <div className="">
                <LoginPolicies />
              </div>
            </TabContent>
            <TabContent for="adminOperations">
              <AdminOperations />
            </TabContent>
            <TabContent for="recordTransfer">
              <div className="col-sm-5">
                <RecordTransfer />
              </div>
            </TabContent>
          </div>
        </Tabs>
      </div>
    );
  }
}

//@ts-ignore
export default connect(
  (state: ApplicationState) => state.admin,
  AdminStore.actionCreators
  // @ts-ignore
)(AdminHome) as typeof AdminHome;
