import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { BackLink } from "../components/BackLink";
import { ApplicationState } from "../store";

import { actionCreators } from "../store/dfr";
import { actionCreators as userActions } from "../store/user";

import DfrMain from "./DfrMain";

export const DfrPage = (props: RouteComponentProps<{ id: string }>) => {
	const dispatch = useDispatch();
	const dfrStore = useSelector((s: ApplicationState) => s.dfr);
	const userStore = useSelector((s: ApplicationState) => s.user);

	React.useEffect(() => {
		if (props.match.params.id && props.match.params.id !== "0") {
			dispatch(actionCreators.getDfr(parseInt(props.match.params.id)));
		}
	}, [props.match.params.id]);

	return (
		<div className="dfr-page">
			<BackLink link={`/project/${dfrStore.projectId}#dfr`} />
			{/*
            @ts-ignore */}
			<DfrMain
				dfrId={parseInt(props.match.params.id)}
				deleteCallback={() =>
					props.history.push(`/project/${dfrStore.projectId}#dfr`)
				}
				currentUser={userStore.user}
			/>
		</div>
	);
};
