import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const MyActivityLoader = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={700}
        //viewBox="0 0 100% 700"
        backgroundColor="#ddd"
        foregroundColor="#eee"
    >
        <rect x='0' y='10' rx='3' ry='3' width='200' height='137' />
        <rect x='26%' y='10' rx='3' ry='3' width='11%' height='137' />
        <rect x='38%' y='10' rx='3' ry='3' width='11%' height='137' />
        <rect x='50%' y='10' rx='3' ry='3' width='11%' height='137' />

        <rect x="0" y="196" rx="3" ry="3" width="2" height="430" />
        <rect x="0" y="196" rx="3" ry="3" width="100%" height="17" />
        <rect x="0" y="196" rx="3" ry="3" width="2%" height="33" />

        <rect x="10%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        <rect x="26.33%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        <rect x="42.67%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        <rect x="59%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        <rect x="75.33%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        <rect x="91.67%" y="203" rx="3" ry="3" width="8.33%" height="33" />
        
        <rect x="0" y="227" rx="3" ry="3" width="100%" height="17" />

        <rect x="2%" y="261" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="261" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="261" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="261" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="261" rx="3" ry="3" width="14.33%" height="15" />        
        <rect x="83.67%" y="261" rx="3" ry="3" width="14.33%" height="15" />        

        <rect x="0" y="296" rx="3" ry="3" width="100%" height="2" />

        <rect x="2%" y="316" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="316" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="316" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="316" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="316" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="316" rx="3" ry="3" width="14.33%" height="15" /> 

        <rect x="0" y="351" rx="3" ry="3" width="100%" height="2" />  

        <rect x="2%" y="371" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="371" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="371" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="371" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="371" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="371" rx="3" ry="3" width="14.33%" height="15" />

        <rect x="0" y="406" rx="3" ry="3" width="100%" height="2" />  

        <rect x="2%" y="426" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="426" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="426" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="426" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="426" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="426" rx="3" ry="3" width="14.33%" height="15" />

        <rect x="0" y="461" rx="3" ry="3" width="100%" height="2" />  

        <rect x="2%" y="481" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="481" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="481" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="481" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="481" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="481" rx="3" ry="3" width="14.33%" height="15" />

        <rect x="0" y="516" rx="3" ry="3" width="100%" height="2" /> 

        <rect x="2%" y="536" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="536" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="536" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="536" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="536" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="536" rx="3" ry="3" width="14.33%" height="15" />

        <rect x="0" y="571" rx="3" ry="3" width="100%" height="2" />

        <rect x="2%" y="591" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="18.33%" y="591" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="34.67%" y="591" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="51%" y="591" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="67.33%" y="591" rx="3" ry="3" width="14.33%" height="15" />
        <rect x="83.67%" y="591" rx="3" ry="3" width="14.33%" height="15" />

        <rect x="99.9%" y="206" rx="3" ry="3" width="2" height="430" />

        <rect x="0" y="626" rx="3" ry="3" width="100%" height="2" />


    </ContentLoader>
)