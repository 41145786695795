import * as React from 'react';
import { ListItem } from '../../interfaces/interfaces';
import cx from 'classnames'
import { MessageBox } from '../../components/MessageBox';

interface Props {
    addBrand: any
    clientId: string | number
}

interface State {
    clientBrands: ListItem[]
    editedBrandId?: number
    message?: string
}

export default class BrandForm extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            clientBrands: []
        }
    }

    componentDidMount() {
        this._getClientBrands(this.props.clientId)
    }

    componentDidUpdate(nextProps: Props) {
        if (nextProps.clientId != this.props.clientId)
            this._getClientBrands(nextProps.clientId)
    }

    _getClientBrands = (clientId?: number | string) => {
        fetch(`api/Brand/Get?clientId=${clientId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    clientBrands: data
                })
            })
    }

    private _save = () => {
        const input = document.getElementById('newBrandName') as HTMLInputElement;
        const code = document.getElementById('brandcode') as HTMLInputElement;
        if (!code || !code.value || code.value.length !== 3) {
            alert('Brand Code must be three characters long');
        }
        else if (input) {
            this.props.addBrand(input.value, code.value, this.props.clientId)
            setTimeout(() => {
                this._getClientBrands(this.props.clientId)
            }, 500)
            input.value = ''
            code.value = ''
        }
    }

    private _editBrand = (id: number, newVal: string) => {
        const editing = this.state.clientBrands && this.state.clientBrands.find(kd => kd.id == id) || {} as ListItem;
        if (editing.value == newVal) {
            this.setState({
                editedBrandId: undefined
            })
        }
        else if (this.state.clientBrands && this.state.clientBrands.findIndex(kd => kd.id !== id && kd.value.toUpperCase() == newVal.toUpperCase()) !== -1) {
            alert('Oops! A brand by that name already exists');
        }
        else {
             fetch(`api/Brand/Edit?id=${id}&newVal=${newVal}&clientId=${this.props.clientId}`, { method: 'PUT' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        this.setState({
                            clientBrands: data,
                            editedBrandId: undefined
                        })
                    }
                    else alert('Error: ' + data.message)
                })
        }
    }

    private _saveEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const newVal = (parentDiv.querySelector('input.form-control') as HTMLInputElement).value

        if (newVal && this.state.editedBrandId)
            this._editBrand(this.state.editedBrandId, newVal)
    }

    private _toggleEditBrand = (e: React.MouseEvent<HTMLSpanElement>) => {
        const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
        const brandId = parentDiv.getAttribute('data-id');

        if (brandId) {
            this.setState({
                editedBrandId: parseInt(brandId)
            })
        }
    }

    _removeBrand = (id: number | string) => {
        fetch(`api/Brand/Delete?id=${id}&clientId=${this.props.clientId}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ clientBrands: data })
                else this.setState({ message: data.message })
            })
    }

    _clearMessage = () => this.setState({ message: undefined })

    render() {
        const { editedBrandId, clientBrands } = this.state
        const brandCards = clientBrands ?
            clientBrands.map(x => {
                const editing = x.id == editedBrandId
                return (
                    <div key={`${x.value}_${x.id}`} className={cx('config-item-line', editing && 'editing')}
                        data-id={x.id}>
                        {editing ? <input className='form-control' defaultValue={x.value} /> : <span>{x.value}</span>}
                        {!editing && <span className={cx('fas fa-times status-remove', editing && 'editing')}
                            onClick={() => this._removeBrand(x.id)}
                        ></span>
                        }
                        {editing ?
                            <span className='fas fa-check status-edit editing'
                                title='Save change'
                                onClick={this._saveEdit}
                            ></span> :
                            <span className='fas fa-edit status-edit'
                                title='Edit status'
                                onClick={this._toggleEditBrand}
                            ></span>
                        }
                    </div>
                )
            }) : []

        return (
            <div className='config-form config-flex max-350'>
                <MessageBox message={this.state.message} clearMessage={this._clearMessage} />
                <div className='config-new-item-form'>
                    <div className="form-header">
                        <h5 className="modal-title">Create New Brand</h5>
                    </div>
                    <div className="form-body">
                        <div className='form-group'>
                            <label>Brand Name</label>
                            <input id='newBrandName' className="form-control" />
                        </div>
                        <div className='form-group'>
                            <label>Brand Code</label>
                            <input id='brandcode' className="form-control" maxLength={3} />
                        </div>
                    </div>
                    <div className='form-footer'>
                        <span className="btn btn-sm btn-blue" onClick={this._save}>Add</span>
                    </div>
                </div>
                <div className='config-item-list'>
                    <div className="form-header">
                        <h5 className="modal-title">Existing Brands</h5>
                    </div>
                    <div className=''>
                        {brandCards}
                    </div>
                </div>
            </div>
        )
    }
}