import React, { useCallback } from 'react';
import { windowsSelector } from 'src/store/window/window.selectors';
import { useSelector } from 'react-redux';
import { DraggableModal } from './DraggableModal';
import { useDispatch } from 'react-redux';
import { WindowsActions } from 'src/store/window/window.reducer';
import { DragDropContext, DropResult } from '@hello-pangea/dnd';

export const GlobalWindows = () => {
  const dispatch = useDispatch();
  const openWindows = useSelector(windowsSelector);

  const onModalClose = (id: number) => {
    dispatch(WindowsActions.removeWindow(id));
  };

  return (
    <>
      {openWindows.map((x) => {
        return (
          <DraggableModal
            key={x.id}
            onClose={() => onModalClose(x.id)}
            title={x.title}
          >
            {x.item}
          </DraggableModal>
        );
      })}
    </>
  );
};
