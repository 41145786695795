import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { formatDateTime } from '../helpers/formatters';
import { RFIReissueRequest } from '../interfaces/interfaces';

interface Props {
    projectId: number
}

export const ReissueRequestGrid = (props: Props) => {
    const [requests, setRequests] = React.useState([] as RFIReissueRequest[]);

    React.useEffect(() => {
        const getRequests = async () => {
            fetch(`api/rfi/reissuerequests?projectId=${props.projectId}`)
                .then(res => res.json())
                .then(data => setRequests(data));
        };
        getRequests();
    }, [props.projectId]);

    if (requests && requests.length) {
        const grid = RenderGrid(requests);

        return (
            <div className='reissue-requests'>
                <div className='' style={{ fontSize: '18px' }}>Reissue Requests</div>
                <div className='grid reissue-request-grid'>
                    <div className='grid-header'>
                        <div className='my-col-5 mobile-hide'>Reason</div>
                        <div className='my-col-4 mobile-full-width'>RFI Number</div>
                        <div className='my-col-4 mobile-hide'>RFI Subject</div>
                        <div className='my-col-4 mobile-hide'>Requester</div>
                        <div className='my-col-3 mobile-hide'>Request Date</div>
                    </div>
                    {grid}
                </div>
            </div>
        )
    }

    return <div></div>
}

const RenderGrid = (requests: RFIReissueRequest[]) => (
    requests.map(x => {
        return (
            <NavLink key={x.id} to={`/reissuerequest/${x.id}`} className='grid-line reissue-request-line'>
                <div className='my-col-5 mobile-hide'>{x.reason}</div>
                <div className='my-col-4 mobile-full-width'>{x.rfiNumber}</div>
                <div className='my-col-4 mobile-hide'>{x.rfiSubject}</div>
                <div className='my-col-4 mobile-hide'>{x.userFullName}</div>
                <div className='my-col-3 mobile-hide'>{formatDateTime(x.createdTimestamp)}</div>
            </NavLink>
        )
    })
)