import * as React from 'react';
import { connect } from 'react-redux';
import { MessageBox } from '../components/MessageBox';

export interface UserNotificationSetting {
    type: number;
    typeName: string;
    active: boolean;
}

export const MyNotificationSettings = () => {

    const [state, setState] = React.useState({ userId: '', settings: [] as UserNotificationSetting[] });

    const _getSettings = () => {
        fetch(`api/notification/settings`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setState(data);
                }
            })
    }

    const _toggleSetting = (type: number, active: boolean) => {
        fetch(`api/notification/setting?type=${type}&active=${active}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setState(data);
                }
            })
    }

    React.useEffect(() => {
        _getSettings();
    }, [])

    return <div>
        <h4 className='modal-title'>Notification Settings</h4>
        {state.settings.map(x => {
            return <div style={{ display: 'flex', padding: '5px 0px', marginLeft: '-9px' }}>
                <div className='my-col-4'>{x.typeName}</div>
                <div className='my-col-16'><input type='checkbox' defaultChecked={x.active} onChange={() => _toggleSetting(x.type, !x.active)} /></div>
            </div>
        })}
    </div>
}

////interface MyProps {

////}

////type Props =     
////    NotificationStore.Store
////    & typeof NotificationStore.actionCreators

////interface State {

////}

////class NotificationSettingsView extends React.Component<Props, State> {
////    constructor(props: Props) {
////        super(props)
////        this.state = {

////        }
////    }

////    componentWillMount() {
////        this.props.getTypes()
////        this.props.getUserPrefs()
////    }

////    _save = () => {
////        let prefs: NotificationPreference[] = []
////        const grid = document.getElementById('notification-pref-grid') as HTMLDivElement
////        const rows = grid.querySelectorAll('.pref-row');
////        Array.prototype.forEach.call(rows, (row: HTMLDivElement) => {
////            const id = (row.querySelector('input[type="hidden"]') as HTMLInputElement).value
////            const type = (row.querySelector('.pref-type > label') as HTMLLabelElement).innerText//.replace(/ /g,'')
////            const days = (row.querySelector('.pref-days > input') as HTMLInputElement).value
////            const enabled = (row.querySelector('input[type="checkbox"]') as HTMLInputElement).checked
////            prefs.push({ id: parseInt(id), type: type, daysOut: parseInt(days) || 0, enabled: enabled })
////        })

////        this.props.save(prefs);
////    }

////    public render() {
////        const grid = this.buildPreferenceLines()
////        return (
////            <div id='notification-pref'>
////                <p>The below options provide notifications via email based on upcoming due-dates for any of said record type which you have access to.</p>
////                <MessageBox message={this.props.message} clearMessage={this.props.clearMessage} />
////                <div id='notification-pref-grid' className='col-sm-12'>
////                    <div className='col-sm-12'>
////                        <div className='col-sm-2'><label>Notification Type</label></div>
////                        <div className='col-sm-1'><label title='Number of days from the due date after which you will receive a notification'>Days Out </label></div>
////                        <div className='col-sm-1'><label>Enabled?</label></div>
////                    </div>
////                    <br/>
////                    <hr />
////                    {grid}
////                </div>
////                <div className='col-sm-12 btn-row'>
////                    <span className='btn btn-sm btn-green' onClick={this._save}>Save</span>
////                </div>
////            </div>    
////        )
////    }

////    buildPreferenceLines() {
////        const { preferences, types } = this.props;
////        let setTypes = preferences.map(x => x.type);
////        setTypes = setTypes.concat(types.filter(t => setTypes.indexOf(t) === -1))

////        let lines: JSX.Element[] = [];

////        setTypes.forEach(t => {
////            const pIdx = preferences.findIndex(p => p.type === t)
////            if (pIdx === -1) {
////                // No preference exists, we need an empty line
////                lines.push(
////                    <div key={t} className='col-sm-12 pref-row'>                        
////                        <input type='hidden' value='-1' />
////                        <div className='col-sm-2 pref-type'><label>{t.splitOnCase()}</label></div>
////                        <div className='col-sm-1 pref-days'><input type='number' className='form-control' name='daysOut' /></div>
////                        <div className='col-sm-1'><input type='checkBox' name='enabled' /></div>
////                    </div>
////                )
////            }
////            else {
////                const pref = preferences[pIdx]
////                lines.push(
////                    <div key={pref.id} className='col-sm-12 pref-row'>    
////                        <input type='hidden' defaultValue={(pref.id || -1).toString()} />
////                        <div className='col-sm-2 pref-type'><label>{t.splitOnCase()}</label></div>
////                        <div className='col-sm-1 pref-days'><input type='number' className='form-control' name='daysOut' defaultValue={pref.daysOut.toString()} /></div>
////                        <div className='col-sm-1'><input type='checkBox' name='enabled' defaultChecked={pref.enabled} /></div>
////                    </div>
////                )
////            }
////        })

////        return (<div>{lines}</div>)
////    }
////}

////export default connect<NotificationStore.Store, typeof NotificationStore.actionCreators, {}>(
////    // @ts-ignore
////    (state: ApplicationState) => state.notifications,
////    NotificationStore.actionCreators
////// @ts-ignore
////)(NotificationSettingsView) //as typeof NotificationSettingsView