import * as React from 'react';
import * as cx from 'classnames';
import { TabLink } from '.';

export const defaultActiveStyle = {
    fontWeight: 'bold',
    borderBottom: '2px solid #333'
}

type TabGroupProps = {
    groupName: string,
    children: JSX.Element[],
    groupText?: any;
    selectedTabText?: string,
    isActive?: boolean,
    activeStyle?: object,
    disableInlineStyles?: boolean,
    className?: string,
    activeClassName?: string,
    style?: object
}

export const TabGroup = (props: TabGroupProps) => {

    const [hovered, setHovered] = React.useState(false);

    const { groupName, children, groupText, selectedTabText, isActive, activeStyle, disableInlineStyles, className, activeClassName, style } = props;

    const _className = className || 'tab-group'
    const _activeClassName = activeClassName || 'tab-group-active'
    const _style = { ...style, ...((isActive && (activeStyle || defaultActiveStyle)) || {}) }
    const _selectedTabText = selectedTabText ? "" + selectedTabText : "";

    return <div
        id={`tabgroup-${groupName}`}
        role='tablist'
        aria-controls={`tabgrouppanel-${groupName}`}
        className={cx({
            [_className]: true,
            [_activeClassName]: isActive
        })}
        style={(!disableInlineStyles && _style) || undefined}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
        <div className='group-label'>{(groupText || groupName)}{_selectedTabText}</div>
        {hovered && <div className='group-links'>
            {/*<div className='tab-group-pointer'></div>*/}
            {children}
        </div>}
    </div>   
}

TabGroup.displayName = 'TabGroup';