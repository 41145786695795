import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { GlobalMessageBox, MessageBox } from "../components/MessageBox";
import { SortIcon } from "../components/SortIcon";
import { ProcurementItemStatus } from "../interfaces/enums";
import {
  FilterOption,
  ListItem,
  ProcurementItem,
  ProjectDetailVm,
  SortInfo,
} from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { ApplicationState } from "../store/index";

import { actionCreators as procurementActions } from "../store/procurement";
import { ProcurementItemLine } from "./ProcurementItemLine";
import { Modal2 } from "../components/Modal";
import { FieldInput } from "../components/FieldComponents/index";
import { formatDateInputFriendly } from "../helpers/formatters";
import FilterableSelect from "../components/FilterableSelect";

export const ProcurementGrid = (props: { project: ProjectDetailVm }) => {
  const [sortInfo, setSortInfo] = React.useState({
    sortProp: "",
    sortAsc: false,
  } as SortInfo);
  const [showFilters, setShowFilters] = React.useState(false);
  const [filterOpts, setFilterOpts] = React.useState([] as FilterOption[]);

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!props.project.id) return;
    dispatch(procurementActions.getItems(props.project.id));
    dispatch(procurementActions.getQuotes(props.project.id));
  }, [props.project.id]);

  const _handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const prop = e.currentTarget.name,
      value = e.currentTarget.value,
      dataType = e.currentTarget.getAttribute("data-type");

    _updateFilter({ dataType: "string", prop: prop, value: value });
  };

  const _updateFilter = (f: FilterOption) => {
    let filterExists = false;
    let newFilters = JSON.parse(JSON.stringify(filterOpts)).map(
      (x: FilterOption) => {
        if (x.prop === f.prop) {
          filterExists = true;
          x = f;
        }
        return x;
      }
    );

    if (!filterExists) newFilters.push(f);

    newFilters = newFilters.filter((x) => x !== undefined);

    setFilterOpts(newFilters);
  };

  const filteredItems = (items: ProcurementItem[]) => {
    let result = items.slice();
    filterOpts.forEach((f) => {
      switch (f.dataType) {
        case "number":
          result = result.filter((x) => x[f.prop] >= f.value);
          break;
        case "boolean":
          result = result.filter((x) => x[f.prop] === Boolean(f.value));
          break;
        case "nested":
          result = result.filterByNestedProp(f.prop, f.value.toString());
          break;
        case "string":
        default:
          result = result.filterByStringProp(f.prop, f.value.toString());
          break;
      }
    });

    result = result.filter((x) => x !== undefined);

    result = result.filter((x) => x.status !== ProcurementItemStatus.Canceled);

    return result;
  };

  const items = procurementStore.loading ? (
    <StandardGridLoader rowContentHeight={25} />
  ) : (
    filteredItems(procurementStore.procurementItems)
      .sort((a, b) => {
        const aVal = a[sortInfo.sortProp],
          bVal = b[sortInfo.sortProp];
        if (!bVal) return -1;
        if (!aVal) return 1;
        if (aVal === bVal) return 0;
        if (sortInfo.sortAsc) return aVal > bVal ? 1 : -1;
        else return aVal > bVal ? -1 : 1;
      })
      .map((x) => (
        <ProcurementItemLine project={props.project} key={x.id} item={x} />
      ))
  );

  const sortIcon = <SortIcon sortInfo={sortInfo} />;

  const filterRow = (
    <div className="grid-filter sv-grid-filter sv-grid-header">
      <div className="my-col-4 small-pad">
        <input
          className="form-control"
          name="itemDescription"
          onChange={_handleFilter}
        />
      </div>
      <div className="my-col-3 small-pad">
        <input
          className="form-control"
          name="procurementManager"
          onChange={_handleFilter}
        />
      </div>
      <div className="my-col-2 small-pad">
        <input
          className="form-control"
          name="rfqDueDate"
          type="date"
          data-type="date"
          onChange={_handleFilter}
        />
      </div>
      {/*<div className='my-col-1 small-pad'><input className='form-control' name='onSiteDueDate' type='date' data-type='date' onChange={_handleFilter} /></div>*/}
      <div className="my-col-2 small-pad">
        <input
          className="form-control"
          name="onSiteDueDate"
          data-type="date"
          onChange={_handleFilter}
        />
      </div>
      <div className="my-col-2 small-pad">
        <input
          className="form-control"
          name="budget"
          onChange={_handleFilter}
        />
      </div>
      <div className="my-col-4 small-pad"></div>
      <div className="my-col-3 small-pad"></div>
      {/*<div className="my-col-1 small-pad"></div>*/}
    </div>
  );

  const gridHeader = (
    <React.Fragment>
      <div className="grid-header sv-grid-header">
        <div
          className="my-col-4 small-pad sortable"
          onClick={() =>
            setSortInfo({
              sortProp: "itemDescription",
              sortAsc: !sortInfo.sortAsc,
            })
          }
        >
          Item Description {sortInfo.sortProp === "itemDescription" && sortIcon}
        </div>
        <div
          className="my-col-3 small-pad sortable"
          onClick={() =>
            setSortInfo({
              sortProp: "procurementManager",
              sortAsc: !sortInfo.sortAsc,
            })
          }
        >
          Procurement Manager{" "}
          {sortInfo.sortProp === "procurementManager" && sortIcon}
        </div>
        <div
          className="my-col-2 small-pad sortable"
          onClick={() =>
            setSortInfo({ sortProp: "rfqDueDate", sortAsc: !sortInfo.sortAsc })
          }
        >
          RFQ Due Date {sortInfo.sortProp === "rfqDueDate" && sortIcon}
        </div>
        <div
          className='my-col-2 small-pad sortable'
          onClick={() => setSortInfo({ sortProp: 'onSiteDueDate', sortAsc: !sortInfo.sortAsc })}
        >
          On-site Due Date {sortInfo.sortProp === 'onSiteDueDate' && sortIcon}
        </div>
        <div
          className="my-col-2 small-pad sortable"
          onClick={() =>
            setSortInfo({ sortProp: "budget", sortAsc: !sortInfo.sortAsc })
          }
        >
          Budget {sortInfo.sortProp === "budget" && sortIcon}
        </div>
        <div className="my-col-3 small-pad">Quotes</div>
        <div className="my-col-3 small-pad">Reviewers</div>
        <div className="my-col-1 small-pad header-buttons">
          <button
            className="btn btn-sm btn-secondary-outline fas fa-filter"
            onClick={() => setShowFilters(!showFilters)}
          ></button>
          <button
            className="btn btn-x-sm btn-blue fas fa-plus"
            onClick={() => dispatch(procurementActions.toggleAddModal())}
          ></button>
        </div>
      </div>
      {showFilters && filterRow}
    </React.Fragment>
  );


  return (
    <div className="grid sv-grid procurement-grid">
      <GlobalMessageBox />
      <MessageBox
        message={procurementStore.message}
        clearMessage={() => dispatch(procurementActions.clearMessage)}
      />
      {procurementStore.showAddModal && <ProcurementAddModal projectId={props.project.id} />}
      {gridHeader}
      <div className="grid-body sv-grid-body">{items}</div>
    </div>
  );
};

const ProcurementAddModal = (props: { projectId: number }) => {
  const { projectId } = props;

  const dispatch = useDispatch();
  const projectStore = useSelector((s: ApplicationState) => s.detail)

  const [disabled, setDisabled] = React.useState(true);
  const [model, setModel] = React.useState({
    projectId: projectId,
    description: '',
    procurementManagerId: '',
    poManagerId: '',
    rfqDueDate: null as string | null,
    reviewDueDate: null as string | null,
    onSiteDueDate: null as string | null,
    budget: 0.00,
    notes: '',
    reviewerIds: [] as string[]
  })

  React.useEffect(() => {
    const d = model.description === '' || model.procurementManagerId === '' || model.poManagerId === '' || model.rfqDueDate === null || model.onSiteDueDate === null || model.budget == 0;
    if (d !== disabled) setDisabled(d);
  }, [model])

  const _save = () => {
    if (!disabled) {
      dispatch(procurementActions.addItem(model));
    }
  }

  return <Modal2>
    <div className='modal-header'><h5>Add Procurement Item</h5></div>
    <div className='modal-body procurement-form custom-scrollbar'>
      <div className="form-group">
        <label>Description*</label>
        <input className='form-control'
          value={model.description}
          onChange={(e) => setModel({ ...model, description: e.currentTarget.value })}
        />
      </div>
      <div className="form-group">
        <label>Procurement Manager*</label>
        <FilterableSelect id='proc-item-mgr'
          items={projectStore.detail.teamMembers.map(x => new ListItem(x.userId, x.userFullName))}
          onChange={(id) => setModel({ ...model, procurementManagerId: id })}
        />
      </div>
      <div className="form-group">
        <label>PO Manager*</label>
        <FilterableSelect id='po-item-mgr'
          items={projectStore.detail.teamMembers.map(x => new ListItem(x.userId, x.userFullName))}
          onChange={(id) => setModel({ ...model, poManagerId: id })}
        />
      </div>
      <div className="form-group">
        <label>RFQ Due Date*</label>
        <input className='form-control'
          type='date'
          value={formatDateInputFriendly(model.rfqDueDate || '')}
          onChange={(e) => setModel({ ...model, rfqDueDate: e.currentTarget.value })}
        />
      </div>
      <div className="form-group">
        <label>Review Due Date</label>
        <input className='form-control'
          type='date'
          value={formatDateInputFriendly(model.reviewDueDate || '')}
          onChange={(e) => setModel({ ...model, reviewDueDate: e.currentTarget.value })}
        />
      </div>
      <div className="form-group">
        <label>On-site Due Date*</label>
        <input className='form-control'
          type='date'
          value={formatDateInputFriendly(model.onSiteDueDate || '')}
          onChange={(e) => setModel({ ...model, onSiteDueDate: e.currentTarget.value })}
        />
      </div>
      <div className="form-group">
        <label>Budget*</label>
        <input className='form-control'
          type='number'
          value={model.budget}
          onChange={(e) => setModel({ ...model, budget: parseInt(e.currentTarget.value) })}
        />
      </div>
      <div className="form-group">
        <label>Notes</label>
        <textarea className='form-control'
          rows={2}
          value={model.notes}
          onChange={(e) => setModel({ ...model, notes: e.currentTarget.value })}
        />
      </div>
      <div className='form-group'>
        <label>Reviewers</label>
        <div style={{ width: 'calc(100% - 220px)', display: 'block' }}>
          {model.reviewerIds.map((x) => {
            if (!x || x === '') return [];
            return <div key={x} className="reviewer-line">
              <span style={{ width: '200px'}}>{projectStore.detail.teamMembers.find(u => u.userId === x)?.userFullName}{" "}</span>
              <span
                className="fas fa-trash-alt"
                style={{margin: '0.5em'}}
                onClick={() =>
                  setModel({
                    ...model,
                    reviewerIds: model.reviewerIds.filter(r => r !== x)
                  })
                }
              ></span>
            </div>
          })}
          <FilterableSelect
            id='add-item-reviewer'
            key={model.reviewerIds.length}
            items={projectStore.detail.teamMembers.filter(x => model.reviewerIds.indexOf(x.userId) === -1).map(x => new ListItem(x.userId, x.userFullName))}
            onChange={(id) => {
              (document.querySelector('#add-item-reviewer input') as HTMLInputElement).value = ''
              setModel({
                ...model,
                reviewerIds: model.reviewerIds.concat([id])
              });
            }}
          />
        </div>
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-blue' disabled={disabled} title={disabled ? 'Please fill out all required fields' : ''} onClick={_save}>Save</button>
      <button className='btn btn-sm btn-outline-secondary' onClick={() => dispatch(procurementActions.toggleAddModal())}>Close</button>
    </div>
  </Modal2>
}
