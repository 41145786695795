import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Tabs, TabLink, TabContent } from '../tabs/index';
import { AccountSettingss } from './AccountSettings';
import ChangePassword from './ChangePassword';
import { MyNotificationSettings } from './NotificationSettings';
import UserReportSettingsView from './Reports';

type Props =        
     RouteComponentProps;

export default class AccountManagementHome extends React.Component<Props, any> {

    public render() {
        return (
            <div>                               
                <div className='pageHeader'>
                    <p>Account Management</p>
                </div>
                <hr />
                <div className='col-sm-12'>
                    <Tabs name='acc-mgmt-tabs' onChange={(tab: string) => { }}>
                        <div className='tab-links'>
                            <TabLink to='info' component='span'><span>Info</span></TabLink>
                            {/*<TabLink to='reports' component='span'><span>Reports</span></TabLink>*/}
                            <TabLink to='password' component='span'><span>Password</span></TabLink>
                            <TabLink to='notification-settings' component='span'><span>Notification Settings</span></TabLink>
                        </div>
                        <div className='content'>
                            <TabContent for='info'>
                                <div>
                                    <AccountSettingss />
                                </div>
                            </TabContent>                            
                            <TabContent for='reports'>
                                <div>
                                    <UserReportSettingsView />
                                </div>
                            </TabContent>        
                            <TabContent for='password'>
                                <div>
                                    <ChangePassword />
                                </div>
                            </TabContent>
                            <TabContent for='notification-settings'>
                                <div>
                                    <MyNotificationSettings />
                                </div>
                            </TabContent>
                        </div>
                    </Tabs>
                </div>
            </div>    
        )
    }
}