import * as React from 'react';
import { OrderedListItem } from '../../interfaces/interfaces';
import cx from 'classnames';
import { MessageBox } from '../../components/MessageBox';
import { DropResult } from '@hello-pangea/dnd';
import { Draggable, Droppable } from '../../components/DragDrop';

import { useRef } from 'react';
import { DragAndDropDotsIcon } from '../../components/icons/DragAndDropDotsIcon';

interface Props {
  addStatus: any;
  reorderStatus: any;
  editStatus: any;
  clientId?: number | string;
}

interface State {
  projectStatuses: OrderedListItem[];
  editedStatusId?: string;
  message?: string;
}

export const ProjectStatusForm = (props: Props) => {
  const [state, setState] = React.useState({ projectStatuses: [] } as State);
  const editIdRef = useRef('' as string | undefined);

  React.useEffect(() => {
    _getProjectStatuses(props.clientId);
  }, [props.clientId]);

  React.useEffect(() => {
    editIdRef.current = state.editedStatusId;
  }, [state.editedStatusId]);

  const _clearMessage = () => setState({ ...state, message: undefined });

  const _getProjectStatuses = (id?: number | string) => {
    fetch(`api/Client/ProjectStatuses?clientId=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setState({
          ...state,
          editedStatusId: editIdRef.current,
          projectStatuses: data,
        });
      });
  }; //, [state.editedStatusId]);

  const _removeStatus = (id: number | string) => {
    fetch(`api/Client/ProjectStatus?id=${id}`, { method: 'DELETE' })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk)
          setState({
            ...state,
            projectStatuses: state.projectStatuses.filter((s) => s.id != id),
          });
        else setState({ ...state, message: data.message });
      });
  };

  const _save = () => {
    const input = document.getElementById('newStatusName') as HTMLInputElement;
    if (input) {
      props.addStatus(input.value, props.clientId);
      setTimeout(() => {
        _getProjectStatuses(props.clientId || '0');
      }, 500);
      (document.getElementById('newStatusName') as HTMLInputElement).value = '';
    }
  };

  const _saveEdit = (e: React.MouseEvent<HTMLSpanElement>) => {
    const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
    const newVal = (
      parentDiv.querySelector('input.form-control') as HTMLInputElement
    ).value;

    if (newVal && state.editedStatusId)
      props.editStatus(state.editedStatusId, newVal, props.clientId);
    setTimeout(() => {
      setState({
        ...state,
        editedStatusId: undefined,
      });
      _getProjectStatuses(props.clientId || '0');
    }, 500);
  };

  const _toggleEditStatus = (e: React.MouseEvent<HTMLSpanElement>) => {
    const parentDiv = e.currentTarget.parentElement as HTMLDivElement;
    const statusId = parentDiv.getAttribute('data-id');

    if (statusId) {
      setState({
        ...state,
        editedStatusId: statusId,
      });
    }
  };

  const _handleDragEnd = React.useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;

      if (!destination) return;

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return;

      // Update the state locally to preserve the ordering while we save. If the ordering fails the correct order will be restored after the API calls
      const foundItem = state.projectStatuses.find((x) => x.id == +draggableId);
      if (foundItem) {
        const newItems = Array.from(state.projectStatuses);
        newItems.splice(source.index - 1, 1);
        newItems.splice(destination.index - 1, 0, foundItem);

        setState({ ...state, projectStatuses: newItems });
      }

      props.reorderStatus(source.index, destination.index, props.clientId);

      setTimeout(() => {
        _getProjectStatuses(props.clientId || '0');
      }, 3750);
    },
    [props.clientId, state.projectStatuses]
  );

  const { editedStatusId, projectStatuses } = state;
  const statusCards = projectStatuses
    ? projectStatuses.map((x, i) => {
        const editing = x.id == editedStatusId;
        i++;
        return (
          <Draggable
            draggableId={x.id.toString()}
            index={i}
            key={`${x.value}_${x.order}_${editing}`}
          >
            {(provided) => (
              <div
                {...provided.draggableProps}
                ref={provided.innerRef}
                className={cx('config-item-line')}
                data-id={x.id.toString()}
              >
                <div
                  {...provided.dragHandleProps}
                  style={{ display: 'inline-block' }}
                >
                  <DragAndDropDotsIcon />
                </div>
                {editing ? (
                  <input className="form-control" defaultValue={x.value} />
                ) : (
                  <span>{x.value}</span>
                )}
                {!editing && (
                  <span
                    className={cx(
                      'fas fa-times status-remove',
                      editing && 'editing'
                    )}
                    onClick={() => _removeStatus(x.id)}
                  ></span>
                )}
                {editing ? (
                  <span
                    className="fas fa-check status-edit editing"
                    title="Save change"
                    onClick={_saveEdit}
                  ></span>
                ) : (
                  <span
                    className="fas fa-edit status-edit"
                    title="Edit status"
                    onClick={_toggleEditStatus}
                  ></span>
                )}
              </div>
            )}
          </Draggable>
        );
      })
    : [];

  return (
    <div className="project-status-component config-form config-flex max-350">
      <MessageBox message={state.message} clearMessage={_clearMessage} />
      <div className="config-new-item-form">
        <div className="form-header">
          <h5 className="modal-title">New Status Name</h5>
        </div>
        <div className="form-body">
          <input id="newStatusName" className="form-control" />
        </div>
        <div className="form-footer">
          <span className="btn btn-sm btn-blue" onClick={_save}>
            Add
          </span>
        </div>
      </div>
      <div className="config-item-list">
        <div className="form-header">
          <h5 className="modal-title">Existing Project Statuses</h5>
        </div>
        <div className="">
          <Droppable
            type={'Status'}
            onDragEnd={(result) => _handleDragEnd(result)}
            droppableId="status-form"
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {statusCards}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};
