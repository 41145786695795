import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import * as cx from 'classnames';

import * as RFIStore from '../store/rfi';
import { ApplicationState } from '../store/index';
import { distinct } from '../helpers/formulas';
import Modal from '../components/Modal';
import { FilterOption, RFI, RFIStatus } from '../interfaces/interfaces';
import { CustomFieldEntryComponent } from '../components/CustomFieldEntryComponent';

type Props = RFIStore.Store & typeof RFIStore.actionCreators

const RFICustomFieldsGrid = (props: Props) => {

    const { rfis, customFields } = props;
    const [selectedRFIId, setSelectedRFIId] = React.useState(null as number | null);
    const [showFilters, setShowFilters] = React.useState(false);
    const [filterOpts, setFilterOpts] = React.useState([] as FilterOption[])
    React.useEffect(() => {
        props.getCustomFields();
    }, [])

    const _updateField = (fieldId: number, value: string) => {              
        props.updateCustomField(selectedRFIId || 0, fieldId.toString(), value);
    }


    const _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const prop = e.currentTarget.getAttribute('name') || ''
        props.filter(prop, val)
    }

    const _customFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        const prop = e.currentTarget.getAttribute('name') || ''

        // First remove the old filter entry, if it existed
        const newOpts = [...filterOpts.filter(f => f.prop !== prop)]

        // Now that the old one is removed, we can add the filter on that field back, if it's not an empty string
        if (val !== '') {
            newOpts.push({ prop: prop, value: val } as FilterOption);
        }

        setFilterOpts(newOpts);
    }

    const _applyFilters = (rfis: RFI[]) => {
        if (!customFields || customFields.length === 0 || filterOpts.length === 0) return rfis;

        const ids: number[][] = [];

        [].forEach.call(filterOpts, (f) => {
            //@ts-ignore
            const entries = customFields.filter(x => x.fieldName === f.prop && x.value.toString().toLowerCase().indexOf(f.value.toLowerCase()) !== -1);
            ids.push(entries.map(x => x.recordId));
        })

        // We got all the ids that matched at least one filter, now we need to insersect all the arrays to get the ids in all arrays (i.e. matched all filters)
        const intersectedIds = ids.reduce((a, b) => b.filter(Set.prototype.has, new Set(a)));

        return rfis.filter(r => intersectedIds.findIndex(i => r.id === i) !== -1);
    }

    const RFICustomFieldsModal = () => {
        const rfi = rfis.find(x => x.id === selectedRFIId);
        const recordFields = customFields.filter(f => f.recordId === selectedRFIId);
        const fields = recordFields ? recordFields.map(x => (
            <div className='custom-field-row' key={x.id}>
                <div className='col-sm-4'><label title={x.fieldName} className='truncate'>{x.fieldName}</label></div>
                <div className='col-sm-8'>
                    <CustomFieldEntryComponent entry={x} handleChange={_updateField} />
                </div>
            </div>
        )) : []

        return (
            <Modal>
                <div className='modal-header'>
                    <h5>{rfi ? rfi.rfiNumber : ''}</h5>
                </div>
                <div className='modal-body'>
                    {fields}
                </div>
                <div className='modal-footer'>
                    <button className='btn btn-sm btn-outline-secondary' onClick={() => { setTimeout(() => { }, 100); setSelectedRFIId(null) }}>Done</button>
                </div>
            </Modal>
        )
    }

    const lines = _applyFilters(rfis).map(r => {
        let statusClass = '', statusText = '';
        switch (r.status) {
            case 0: statusClass = 'left-tab-yellow'; statusText = 'Awaiting Response'; break;
            case 1: statusClass = 'left-tab-blue'; statusText = 'Answered'; break;
            case 2: statusClass = 'left-tab-green'; statusText = 'Accepted'; break;
            case RFIStatus.Reissued: statusText = 'Reissued'; statusClass = 'left-tab-purple'; break;
            case RFIStatus.ReissueRequested: statusText = 'Reissue Requested'; statusClass = 'left-tab-purple'; break;
            case RFIStatus.Canceled: statusText = 'Canceled'; statusClass = 'left-tab-red'; break;
        }
        const recordFields = customFields.filter(f => f.recordId === r.id);
        const fieldCells = recordFields ?
            recordFields.map(x => {
                //@ts-ignore
                return <div className='col-sm-1 truncate' title={x.value.toString()}>{x.value}</div>
            })
            : []

        return (
            <div style={{ display: 'flex' }} className={cx('col-sm-12 striped-row', statusClass)} key={r.id} onClick={() => setSelectedRFIId(r.id)}>
                <div className='col-sm-1 min-110'>{r.rfiNumber}</div>
                {fieldCells}
            </div>
        )
    })

    const headerFields = customFields.map(x => x.fieldName).filter(distinct);
    const headerCells = headerFields ? headerFields.map(x => <div className='col-sm-1 truncate' title={x} key={x}>{x}</div>) : []
    const header = (
        <div className='rfi-grid-header' style={{ paddingBottom: '7px' }}>
            <div className='col-sm-1 min-110'>RFI #</div>
            {headerCells}
        </div>
    )

    const fieldFilterBoxes = headerFields ? headerFields.map(x => <div className='col-sm-1'><input name={x} className='form-control' onChange={_customFilter} /></div>) : []

    const filterLine = (
        <div className='col-sm-12 rfi-grid-filter' style={{ display: 'flex', paddingLeft: '6px' }}>
            <div className='col-sm-1 min-130'><input name='rfiNumber' className='form-control' onChange={_filter} /></div>
            {fieldFilterBoxes}
        </div>
    )


    return (
        <div style={{ position: 'relative' }}>
            <span className='btn btn-sm btn-outline-secondary fas fa-filter' onClick={() => setShowFilters(!showFilters)} style={{ position: 'absolute', top: '0px', right: '0px', fontSize: '11px' }}></span>
            <div className='grid rfi-grid custom-fields' style={{ overflowX: 'auto' }}>
                {header}
                {showFilters && filterLine}
                <div className='grid-body custom-scrollbar'>{lines}</div>
                {selectedRFIId && RFICustomFieldsModal()}
            </div>
        </div>
    )
}

export default connect(
    (state: ApplicationState) => state.rfi,
    RFIStore.actionCreators
    // @ts-ignore
)(RFICustomFieldsGrid)

