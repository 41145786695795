import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '../index';
import { RFQState } from './rfqs.reducer';

const rfqState = (state: ApplicationState): RFQState => state.rfqs;




export const rfqStatusSelector = createSelector(
  rfqState,
  (state) => state.status
);

export const isIssuesLoadingSelector = createSelector(
  rfqStatusSelector,
  (status) => status == 'loading'
);


export const isIssuesSavingSelector = createSelector(
  rfqStatusSelector,
  (status) => status == 'saving'
)
