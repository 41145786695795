import React, { useState, PropsWithChildren, useEffect } from 'react';
import { NavMenu } from './NavMenu';
import CopyrightModal from '../components/CopyrightModal';
import HorizontalHeader from './HorizontalHeader';
import { GlobalWindows } from 'src/components/GlobalWindows';
import { Sidebar } from './Sidebar';
import { useWindowDimensions } from 'src/helpers/customhooks';
import { UpdateAnnouncement } from '../interfaces/interfaces';
import { UpdateAnnouncementModal } from '../components/UpdateAnnouncementModal';

export const Layout = ({ children }: PropsWithChildren) => {
  const [showCopyright, setShowCopyright] = useState(false);
  const { height, width } = useWindowDimensions();

  const toggleCopyright = () => {
    setShowCopyright(!showCopyright);
  };

  const [updateAnnouncements, setUpdateAnnouncements] = useState([] as UpdateAnnouncement[]);

  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    fetch(`api/announcement/getuser`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk && data.length) {
          setUpdateAnnouncements(data);
          setShowUpdateModal(true);
        }
      })
  }, [])    

  const _dismissAnnouncement = (id: number) => {
    fetch(`api/announcement/dismiss?announcementId=${id}`, { method: 'POST' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setUpdateAnnouncements(data);
          if (!(data as UpdateAnnouncement[]).length) {
            setShowUpdateModal(false);
          }
        }
      })
  }

  const _dismissAllAnnouncements = () => {
    fetch(`api/announcement/dismissAll`, { method: 'POST' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setShowUpdateModal(false);
      })
  }

  return (
    <div className="container-fluid">
      <div className="mobile-block" style={{ display: 'flex' }}>
        {width < 768 ? <NavMenu /> : <Sidebar />}
        <div className="app-body">
          <GlobalWindows />

          <HorizontalHeader />
          <div
            id={'app-content'}
            className="col-sm-12 custom-scrollbar"
            style={{ overflowY: 'auto' }}
          >
            {children}
          </div>
        </div>
        <footer id="footer">
          <hr />
          Copyright stuff{' '}
          <span className="link" onClick={toggleCopyright}>
            here
          </span>
        </footer>
      </div>
      {showCopyright && <CopyrightModal close={toggleCopyright} />}
      {showUpdateModal && <UpdateAnnouncementModal
        announcements={updateAnnouncements}
        close={() => setShowUpdateModal(false)}
        dismiss={_dismissAnnouncement}
        dismissAll={_dismissAllAnnouncements}
      />}
    </div>
  );
};
