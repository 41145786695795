import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store/index";

import { actionCreators } from '../store/global'

export const useGlobalMessage = () => {
    const dispatch = useDispatch();
    return (message?: string) => {        
        if (message) return dispatch(actionCreators.updateMessage(message))
        else return dispatch(actionCreators.clearMessage());
    }
}