import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import { CSharpTypeCode } from 'src/interfaces/enums';
import {
  DailyFieldReportTemplate,
  UpdateObject,
} from 'src/interfaces/interfaces';
import {
  currentTemplateSelector,
  dfrIsLoadingSelector,
  dfrTemplatesSelector,
} from 'src/store/dfr/dfr.selectors';
import { QualityReportModal } from './QualityReportModal';
import { formatDate } from 'src/helpers/formatters';

const columns: MetaFieldInfo[] = [
  {
    displayName: 'Name',
    propName: 'name',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Created By',
    propName: 'userFullName',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Last Modified Date',
    propName: 'lastModifiedTimestamp',
    jsType: 'date',
    propType: CSharpTypeCode.DateTime,
  },
];

export const QualityReportHome = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const templates = useSelector(dfrTemplatesSelector);
  const currentTemplate = useSelector(currentTemplateSelector);
  const isLoading = useSelector(dfrIsLoadingSelector);

  const dispatch = useDispatch();

  const onRowClick = (template: DailyFieldReportTemplate) => {
    dispatch({
      type: 'SET_TEMPLATE_ID',
      id: template.id,
    });
    setIsModalVisible(true);
  };

  const renderItem = useCallback(
    (template: DailyFieldReportTemplate) => {
      return (
        <div
          onClick={() => onRowClick(template)}
          className="sv-grid-line"
          key={template.id}
        >
          <div className="col">{template.name}</div>
          <div className="col">{template.userFullName}</div>
          <div className="col">
            {new Date(template.lastModifiedTimestamp).toLocaleString()}
          </div>
        </div>
      );
    },
    [templates]
  );

  useEffect(() => {
    dispatch({
      type: 'REQUEST_TEMPLATES',
    });
  }, []);

  const copyTemplate = () => {
    dispatch({
      type: 'COPY_TEMPLATE',
    });
    setIsModalVisible(false);
  };

  const addTemplate = useCallback(() => {
    dispatch({
      type: 'ADD_TEMPLATE',
    });
  }, []);

  const updateTemplate = useCallback((update: UpdateObject) => {
    dispatch({
      type: 'SET_TEMPLATE_UPDATE',
      update: update,
    });

    dispatch({
      type: 'EDIT_TEMPLATE',
    });
  }, []);

  const addItem = useCallback(
    (field: string) => {
      if (currentTemplate) {
        let items = '';
        switch (field) {
          case 'topics': {
            items = currentTemplate.topics
              ? currentTemplate.topics +
                '|Topic ' +
                (currentTemplate.topicList.length + 1)
              : 'Topic 1';
            break;
          }
          case 'equipment': {
            items = currentTemplate.equipment
              ? currentTemplate.equipment +
                '|Equipment Item ' +
                (currentTemplate.equipmentList.length + 1)
              : 'Equipment Item 1';
            break;
          }
          case 'photoDescriptions': {
            items = currentTemplate.photoDescriptions
              ? currentTemplate.photoDescriptions +
                '|Photo ' +
                (currentTemplate.photoDescriptionList.length + 1)
              : 'Photo 1';
            break;
          }
          default: {
            break;
          }
        }

        dispatch({
          type: 'SET_TEMPLATE_UPDATE',
          update: { fieldName: field, value: items },
        });

        dispatch({
          type: 'EDIT_TEMPLATE',
        });
      }
    },
    [currentTemplate]
  );

  return (
    <>
      {currentTemplate && isModalVisible && (
        <QualityReportModal
          template={currentTemplate}
          onTemplateCopy={() => copyTemplate()}
          onModalClose={() => {
            dispatch({
              type: 'SET_TEMPLATE_ID',
              id: null,
            });
            setIsModalVisible(false);
          }}
          onTemplateUpdate={(update) => updateTemplate(update)}
          onListAdd={(fieldName: string) => addItem(fieldName)}
          isLoading={isLoading}
        />
      )}
      <CustomizableGrid
        items={templates ?? []}
        columns={columns}
        hideLoadingWhenEmpty={true}
        mapCallback={renderItem}
        evenColumnSpacing={true}
        addCallback={addTemplate}
      />
    </>
  );
};
