import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ApplicationState } from '../store'
import { useDispatch, useSelector } from 'react-redux'
import * as Dfr from '../store/dfr'
import { actionCreators } from '../store/dfr';
import { KnownRoles } from '../auth/auth'
import { Clipboard } from '../components/clipboard';
import { LiveDocViewer } from '../components/LiveDocViewer'
import UserIdAuthElement from '../auth/UserIdAuthElement'
import Modal from '../components/Modal'

interface Props {

}

export const DfrExternalFile = (props: Props) => {

    const [showChangeModal, setShowChangeModal] = React.useState(false);
    const dispatch = useDispatch();
    const dfrStore = useSelector((s: ApplicationState) => s.dfr);

    const hasFile = dfrStore.externalFile && dfrStore.externalFile.filePath !== '';

    const _changeFile = () => {
        const fileInput = document.getElementById('external-file-input') as HTMLInputElement,
            file = fileInput.files ? fileInput.files[0] : null;

        if (file) {
            dispatch(actionCreators.addExternalFile(dfrStore.id, file))
            fileInput.value = '';
        }

        setShowChangeModal(false);
    }

    const changeModal = <Modal>
        <div className='modal-header'><h5>Swap Report File</h5></div>
        <div className='modal-body'>Are you sure you want to change out the report file? Doing so will delete the previous file</div>
        <div className='modal-footer'>
            <button className='btn btn-sm btn-blue' onClick={_changeFile}>Submit</button>
            <button className='btn btn-sm btn-outline-secondary' onClick={() => setShowChangeModal(false)}>Cancel</button>
        </div>
    </Modal>

    return <div className='dfr-external-file'>
        {showChangeModal && changeModal}
        <UserIdAuthElement allowedUserIds={[dfrStore.userId]}>
            <input type='file' id='external-file-input' onChange={hasFile ? () => setShowChangeModal(true) : _changeFile} style={{ marginBottom: '5px' }} />
        </UserIdAuthElement>
        <LiveDocViewer docUrl={(dfrStore.externalFile || { filePath: '' }).filePath} />
    </div>
}