import React, { useRef, useState } from 'react';
import { Loader } from 'src/components/Loader';
import Modal from 'src/components/Modal';
import { PunchlistTemplateVm, UpdateObject } from 'src/interfaces/interfaces';
import { StandardGridLoader } from 'src/loaders/StandardGridLoader';
import styled from 'styled-components';
import { PunchlistTemplateItemRow } from './PunchlistTemplateItemRow';

interface PunchlistTemplateModalProps {
  template: PunchlistTemplateVm;
  onModalClose: () => void;
  onTemplateUpdate: (id: number, update: UpdateObject) => void;
  onTemplateItemAdd: (id: number) => void;
  onTemplateItemUpdate: (itemId: number, update: UpdateObject) => void;
  onTemplateCopy: (id: number) => void;
  onTemplateItemRemove: (itemId: number) => void;
  isLoading: boolean;
  isCreator: boolean;
}

const ModalBodyDiv = styled.div`
  max-height: calc(100vh - 180px);
  overflow-y: auto;
`;

const StyledInput = styled.input({
  height: 'unset !important',
  width: '350px',
  marginBottom: '16px',
});

const StyledH4 = styled.h4({
  marginBottom: '3px',
});

const StyledDiv = styled.div({
  display: 'flex',
});

export const PunchlistTemplateModal = ({
  template,
  onModalClose,
  onTemplateUpdate,
  onTemplateItemAdd,
  onTemplateItemUpdate,
  onTemplateCopy,
  onTemplateItemRemove,
  isLoading,
  isCreator,
}: PunchlistTemplateModalProps) => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal>
      <div className="modal-header">
        {!isEditingTitle ? (
          <>
            <StyledDiv>
              <StyledH4>{template.title}</StyledH4>
              {isCreator && (
                <span
                  style={{
                    fontSize: 'unset',
                    position: 'unset',
                    marginLeft: '10px',
                  }}
                  className="fas fa-edit"
                  onClick={() => setIsEditingTitle(true)}
                ></span>
              )}
            </StyledDiv>
            <div style={{ alignSelf: 'flex-end' }}>
              Created by: {template.userFullName}
            </div>
            {isCreator && (
              <span
                onClick={() => onTemplateCopy(template.id)}
                className="btn btn-sm btn-outline-secondary"
                title="Copy Template"
                style={{ fontSize: '12px' }}
              >
                <span className="fa fa-clipboard"></span>
                Copy
              </span>
            )}
          </>
        ) : (
          <>
            <StyledInput
              ref={inputRef}
              defaultValue={template.title}
              className="form-control"
            />
            <button
              className="btn btn-sm btn-blue"
              onClick={(e) => {
                onTemplateUpdate(template.id, {
                  fieldName: 'title',
                  value: inputRef.current?.value ?? '',
                });
                setIsEditingTitle(false);
              }}
              style={{
                marginRight: '8px',
                marginLeft: '8px',
              }}
            >
              Save
            </button>
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setIsEditingTitle(false)}
            >
              Cancel
            </button>
          </>
        )}
      </div>
      <ModalBodyDiv className="modal-body custom-scrollbar">
        <>
          {template.items &&
            template.items.map((x) => (
              <PunchlistTemplateItemRow
                key={x.id}
                item={x}
                onItemUpdate={onTemplateItemUpdate}
                onItemRemove={onTemplateItemRemove}
                isDisabled={!isCreator}
              ></PunchlistTemplateItemRow>
            ))}
          {isCreator && (
            <button
              className="btn btn-sm btn-blue"
              onClick={() => {
                onTemplateItemAdd(template.id);
              }}
              style={{ maxWidth: '100px' }}
            >
              Add Item
            </button>
          )}
        </>
      </ModalBodyDiv>
      <div className="modal-footer">
        <span
          className="btn btn-sm btn-outline-secondary"
          onClick={onModalClose}
        >
          Close
        </span>
      </div>
    </Modal>
  );
};
