import * as React from 'react';
import { ApplicationState } from '../store';
import { connect, useSelector } from 'react-redux';
import * as Dfr from '../store/dfr';
import * as User from '../store/user';
import { Loader } from '../components/Loader';
import { FileDownloader } from '../components/FileDownloader';
import PhotoCarousel from '../components/PhotoCarousel';
import { UserInfo } from 'src/interfaces/interfaces';

interface State {
  src: string;
  carouselIndex?: number;
  photoList: string[];
}

interface Props {
  currentUser?: UserInfo;
}

type ListProps = Props & Dfr.Store & typeof Dfr.actionCreators;

class DfrPhotos extends React.Component<ListProps, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      src: '',
      photoList: [],
    };
  }

  onSubmit = () => {
    // checking to see if there are any files in the input
    const fileInput = document.getElementById(
      'dfr_fileUpload'
    ) as HTMLInputElement;
    const Value_filePath = fileInput.files;

    // if there are no photos ready for upload
    if (Value_filePath![0] != null) {
      this.props.addPhoto(this.props.id, Value_filePath);
    }
    // removes the value in the input so that you won't be able to upload it again
    fileInput.value = '';
    this.setState({ photoList: [] });
  };

  imageOnClick = (idx?: number) => {
    this.setState({ carouselIndex: idx });
  };

  // to get the photo file name so that we can put it into state and display it after the user uploads a photo.
  uploadBtnToDisplayFiles = () => {
    const uploadedPhoto = document.getElementById(
      'dfr_fileUpload'
    ) as HTMLInputElement;
    const photoArray = [] as string[];
    for (let i = 0; i < uploadedPhoto.files!.length; i++) {
      photoArray.push(uploadedPhoto.files![i].name);
    }

    this.setState({ photoList: photoArray });
  };

  updatePhoto = (id: number, photoId: number, files: FileList | null) => {
    if (files) {
      this.props.updatePhotoImage(id, photoId, files);
    }
  };

  saveChanges = (
    e: React.FormEvent<HTMLInputElement>,
    field: string,
    entityId: number
  ) => {
    const typeInput = e.currentTarget.value;
    this.props.updateChanges(entityId, field, typeInput, 3);
  };

  _rotatePhoto = (id: number, clockwise: boolean) => {
    this.props.rotatePhoto(id, clockwise);
  };

  render() {
    const { carouselIndex } = this.state;
    const { photos } = this.props;
    const photoPaths = photos.map((x) => {
      return x.filePath;
    });
    const user = this.props.currentUser;

    const savedPhotos = photos.map((x, i) => {
      return (
        <div key={x.id} className="dfr_photoBorder tile">
          {!this.props.isLocked && !x.isNonRemovable && (
            <span
              className="fas fa-times"
              onClick={() => this.props.deleteRow(x.id, 3)}
            ></span>
          )}
          <div className="rotate-icons">
            <span
              className="fas fa-redo counterclockwise"
              onClick={() => this._rotatePhoto(x.id, false)}
              title="Rotate photo counterclockwise 90 degrees"
            ></span>
            <span
              className="fas fa-redo clockwise"
              onClick={() => this._rotatePhoto(x.id, true)}
              title="Rotate photo 90 degrees"
            ></span>
          </div>
          <div>
            <>
              <img
                className="dfr_ImgTag"
                src={x.filePath}
                alt={''}
                onClick={
                  x.filePath == '' ? undefined : () => this.imageOnClick(i)
                }
              />
              {x.isNonRemovable && (
                <input
                  type="file"
                  onChange={(e) =>
                    this.updatePhoto(x.dfrId, x.id, e.currentTarget.files)
                  }
                  accept=".jpg, .jpeg"
                  capture
                />
              )}
            </>
          </div>
          <p>
            <a href={x.filePath} target="_blank">
              {x.fileName}
            </a>
            {x.includeForClient && <span className="fas fa-check"></span>}
          </p>
          <input
            className="form-control"
            onBlur={(e) => this.saveChanges(e, 'description', x.id)}
            disabled={this.props.isLocked}
            defaultValue={x.description || ''}
          />
        </div>
      );
    });

    const displayUploadedPhotoName = this.state.photoList.map((x, i) =>
      i == 0 ? <label key={i}>{x}</label> : <label key={i}>, {x}</label>
    );
    return (
      <div>
        {photos.length == 0 && this.props.isSubmitted ? (
          <div>
            <h4>No Photos Added</h4>
          </div>
        ) : (
          ''
        )}
        <div>
          <div className="dfr_upload_btn_wrapper">
            {this.props.isLocked && user?.userId !== this.props.pmId ? (
              ''
            ) : (
              <input
                id="dfr_fileUpload"
                className="dfr_fileUpload"
                type="file"
                onChange={this.uploadBtnToDisplayFiles}
                accept=".jpg, .jpeg"
                multiple
                capture
              />
            )}
            {this.props.isLocked && user?.userId !== this.props.pmId ? (
              ''
            ) : (
              <label htmlFor="dfr_fileUpload" className="btn btn-blue">
                Upload
              </label>
            )}
            {this.props.isLocked && user?.userId !== this.props.pmId ? (
              ''
            ) : (
              <span
                id="dfr_save_photo_btn"
                className="btn btn-sm btn-green"
                onClick={this.onSubmit}
              >
                Save
              </span>
            )}
            <span id="dfr_photo_download_btn">
              <FileDownloader
                actionPath={`api/DailyFieldReport/Download`}
                fileType="ZIP"
                queryParams={{ id: this.props.id }}
              />
            </span>
            <div>{displayUploadedPhotoName}</div>
          </div>

          <div className="dfr_photoParentDiv">{savedPhotos}</div>
        </div>
        {carouselIndex !== undefined && (
          <PhotoCarousel
            photos={photoPaths}
            startingIndex={carouselIndex}
            close={() => this.imageOnClick()}
          />
        )}
      </div>
    );
  }
}

export default connect<Dfr.Store, typeof Dfr.actionCreators, Props>(
  //@ts-ignore
  (state: ApplicationState) => state.dfr,
  Dfr.actionCreators
)(DfrPhotos);
