import * as React from 'react';
import { Modal2 } from './Modal';
import { CustomizableGrid, MetaFieldInfo } from './CustomizableGrid';
import { CSharpTypeCode } from '../interfaces/enums';
import { formatDate, formatDateInputFriendly } from '../helpers/formatters';
import FilterableSelect from './FilterableSelect';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store/index';
import { actionCreators as globalActions } from '../store/global';
import { ListItem } from '../interfaces/interfaces';

export const CMKpiReportModal = (props: { close: () => void }) => {

  const [filters, setFilters] = React.useState({
    startDate: '',
    pm: '',
    status: ''
  })

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([] as string[][]);

  const globalStore = useSelector((s: ApplicationState) => s.global);
  const dispatch = useDispatch();

  const cols: MetaFieldInfo[] = [
    { displayName: 'PM', propName: 'pm', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 2 },
    { displayName: 'Project #', propName: 'projectNumber', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Project Name', propName: 'projectName', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 2 },
    { displayName: 'Status', propName: 'status', jsType: 'date', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Start Date', propName: 'startDate', jsType: 'date', propType: CSharpTypeCode.DateTime, columnWidth: 1 },
    { displayName: 'End Date', propName: 'endDate', jsType: 'date', propType: CSharpTypeCode.DateTime, columnWidth: 1 },
    { displayName: 'Site Safety Plan', propName: 'siteSafetyPlan', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Kick Off Mtg', propName: 'kickOffMtg', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Safety Report', propName: 'safetyReport', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Project Documents Uploaded', propName: 'projectDocumentsUploaded', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Invoicing Milestones Used', propName: 'invoicingMilestonesUsed', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'QC Checklist Complete', propName: 'qcChecklistComplete', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Completed Under Budgeted Days', propName: 'completedUnderBudgetedDays', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Punchlist Complete', propName: 'punchlistComplete', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'Project Closed Out', propName: 'projectClosedOut', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'GMP Over 20%', propName: 'gmpOver20', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
    { displayName: 'YES Score', propName: 'yesScore', jsType: 'string', propType: CSharpTypeCode.String, columnWidth: 1 },
  ]

  const _runReport = () => {
    let params = '';
    for (const key in filters) {
      params += `${key}=${filters[key]}&`
    }

    params = params.slice(0, -1);
    const url = `api/Report/cmkpi?${params}`

    fetch(url)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, result]) => {
        if (!resOk) dispatch(globalActions.updateMessage(result.message));
        else setData(result);
      })
  }

  const _lineMap = React.useCallback(
    (rowData: string[], index: number) => {
      return (
        <div

          className='col-sm-12 sv-grid-line'
          key={index}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <div className="my-col-2">{rowData[cols[0].propName]}</div>
          <div className="my-col-1">{rowData[cols[1].propName]}</div>
          <div className="my-col-2">{rowData[cols[2].propName]}</div>
          <div className="my-col-1">{rowData[cols[3].propName]}</div>
          <div className="my-col-1">{formatDate(rowData[cols[4].propName])}</div>
          <div className="my-col-1">{formatDate(rowData[cols[5].propName])}</div>
          <div className="my-col-1">{rowData[cols[6].propName]}</div>
          <div className="my-col-1">{rowData[cols[7].propName]}</div>
          <div className="my-col-1">{rowData[cols[8].propName]}</div>
          <div className="my-col-1">{rowData[cols[9].propName]}</div>
          <div className="my-col-1">{rowData[cols[10].propName]}</div>
          <div className="my-col-1">{rowData[cols[11].propName]}</div>
          <div className="my-col-1">{rowData[cols[12].propName]}</div>
          <div className="my-col-1">{rowData[cols[13].propName]}</div>
          <div className="my-col-1">{rowData[cols[14].propName]}</div>
          <div className="my-col-1">{rowData[cols[15].propName]}</div>
          <div className="my-col-1">{rowData[cols[16].propName]}</div>
        </div>
      )
    },
    [data])

  return <Modal2 dialogStyle={{ width: '90vw', maxWidth: '90vw' }} modalClass='cm-kpi-report'>
    <div className='modal-header'><h4>CM KPI Report</h4></div>
    <div className='modal-body' style={{ maxHeight: '70vh' }}>
      <div>
        <div style={{ display: 'flex' }}>
          <div className='form-group'>
            <b>{`Start Date (>=)`}</b>
            <input
              className='form-control'
              type='date'
              value={formatDateInputFriendly(filters.startDate)}
              onChange={(e) => setFilters({ ...filters, startDate: e.currentTarget.value })}
            />
          </div>
          <div className='form-group'>
            <b>PM</b>
            <FilterableSelect
              items={globalStore.users.map(x => new ListItem(x.userId, x.fullName))}
              id='cm_pm_select'
              defaultVal={filters.pm}
              onChange={(id) => setFilters({ ...filters, pm: id })}
            />
          </div>
          <div className='form-group'>
            <b>Project Status</b>
            <select className='form-control' onChange={(e) => setFilters({ ...filters, status: e.currentTarget.value })} value={filters.status}>
              <option className='form-control' value='Active'>Active</option>
              <option className='form-control' value='Complete'>Complete</option>
              <option className='form-control' value=''>All</option>
            </select>
          </div>
          <button className='btn btn-x-sm btn-outline-secondary clear-filters-button' onClick={() => setFilters({ pm: '', startDate: '', status: '' })}>Clear</button>
        </div>
        <div>
          <button className='btn btn-sm btn-green' onClick={_runReport}>Run</button>
        </div>
      </div>
      <hr />
      <div>
        <CustomizableGrid
          columns={cols}
          items={data}
          mapCallback={_lineMap}
          className='custom-reporting-grid'
          loading={loading}
          hideLoadingWhenEmpty={true}
          hideFiltering={true}
        />
      </div>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</button>
    </div>
  </Modal2>
}