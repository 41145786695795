//import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import {
  ProjectDetailVm,
  MaterialEntryVm,
  ErrorAction,
  ResetAction,
  ListItem,
  ContactCard,
  KeyDocViewModel,
} from "../interfaces/interfaces";
import { ClearDfrsAction } from './dfr';

export interface Store {
  projId: number;
  detail: ProjectDetailVm;
  brands: ListItem[];
  clientPMs: ContactCard[];
  internalPMs: ContactCard[];
  ownerReps: ContactCard[];
  projectTypes: ListItem[];
  projectStatuses: ListItem[];
  divisions: ListItem[];
  vendors: ListItem[];
  editProjectNumber: boolean;
  isLoading: boolean;
  isSaving: boolean;
  message?: string;
}

interface RequestDetailAction {
  type: "REQUEST_DETAIL";
  projId: number;
  //detail: ProjectDetailVm;
}

interface ReceiveDetailAction {
  type: "RECEIVE_DETAIL";
  detail: ProjectDetailVm;
  brands?: ListItem[];
  clientPMs?: ContactCard[];
  internalPMs?: ContactCard[];
  projectTypes?: ListItem[];
  vendors?: ListItem[];
  ownerReps?: ListItem[];
  projectStatuses?: ListItem[];
  divisions?: ListItem[];
}

interface UpdateFieldAction {
  type: "UPDATE_FIELD";
  detail: ProjectDetailVm;
}

interface AddMaterialEntryAction {
  type: "ADD_MATERIAL_ENTRY";
  detail: ProjectDetailVm;
}

interface RemoveEntryAction {
  type: "REMOVE_ENTRY";
  detail: ProjectDetailVm;
}

/* Use this just to clear out the message so the new saved message can appear */
interface SavingAction {
  type: "SAVING_DETAIL";
}

interface DetailSaveAction {
  type: "DETAIL_SAVED";
  message: string;
}

interface ToggleEditProjectNumberAction {
  type: "TOGGLE_EDIT_PROJECT_NUMBER";
}

type KnownAction =
  | RequestDetailAction
  | ReceiveDetailAction
  | UpdateFieldAction
  | AddMaterialEntryAction
  | RemoveEntryAction
  | SavingAction
  | ResetAction
  | DetailSaveAction
  | ToggleEditProjectNumberAction
  | ErrorAction;

export const actionCreators = {
  getDetail:
    (id: number): AppThunkAction<KnownAction | ClearDfrsAction> => (dispatch, getState) => {
      fetch(`api/Project/Detail?id=${id}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({
              type: "RECEIVE_DETAIL",
              detail: data.detail,
              brands: data.ddOpts.brands,
              projectTypes: data.ddOpts.types,
              clientPMs: data.ddOpts.clientPMs,
              internalPMs: data.internalPMs,
              ownerReps: data.ownerReps,
              vendors: data.ddOpts.vendors,
              projectStatuses: data.ddOpts.projectStatuses,
              divisions: data.ddOpts.divisions,
            });
          } else dispatch({ type: "ERROR", message: data.message });
        });
      dispatch({
        type: "REQUEST_DETAIL",
        projId: id,
        //detail: { id: id } as ProjectDetailVm,
      });
      if (getState().detail.projId !== id) {
        dispatch({ type: 'CLEAR_DFR_LIST' })
      }
    },
  updateField:
    (field: string, value: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const pId = getState().detail.detail.id;
        fetch(`api/Project/Update?id=${pId}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fieldName: field,
            value: value,
          }),
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              dispatch({ type: "UPDATE_FIELD", detail: data });
            } else dispatch({ type: "ERROR", message: data.message });
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  updateProjectNumber:
    (newNumber: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(
          `api/Project/UpdateProjectNumber?id=${getState().detail.projId
          }&newNumber=${newNumber}`,
          { method: "PUT" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const detail = Object.assign({}, getState().detail.detail);
              detail.projectNumber = newNumber;
              dispatch({ type: "UPDATE_FIELD", detail: detail });
            } else {
              dispatch({ type: "ERROR", message: data.message });
            }
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  updateNotes:
    (e: React.FocusEvent<HTMLTextAreaElement>): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const body = {
          entityId: getState().detail.projId,
          text: e.currentTarget.value,
        };
        fetch(`api/Project/Notes`, {
          method: "PUT",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const detail = Object.assign({}, getState().detail.detail);
              detail.notes = data;
              dispatch({ type: "UPDATE_FIELD", detail: detail });
            } else {
              dispatch({ type: "ERROR", message: data.message });
            }
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  addMaterialEntry:
    (name: string, linkedMSId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const pId = getState().detail.detail.id;
        fetch(
          `api/Material/AddEntry?projectId=${pId}&milestoneId=${linkedMSId}&name=${name}`,
          {
            method: "POST",
          }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newDetail = Object.assign({}, getState().detail.detail);
              newDetail.materials = data;
              dispatch({ type: "ADD_MATERIAL_ENTRY", detail: newDetail });
            } else dispatch({ type: "ERROR", message: data.message });
          });
      },
  updateMatEntry:
    (
      entryId: number,
      field: string,
      value: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/Material/UpdateEntry?entryId=${entryId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ fieldName: field, value: value }),
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newDetail = getState().detail.detail;
              newDetail.materials = newDetail.materials
                .map((m) => {
                  if (m.entryId == entryId) m = data;
                  return m;
                })
                .sort((a: MaterialEntryVm, b: MaterialEntryVm) => {
                  if (a.poDate === b.poDate) {
                    if (a.dueDate === b.dueDate)
                      return a.materialName > b.materialName ? -1 : 1;
                    //return (new Date(a.dueDate) > new Date(b.dueDate)) ? -1 : 1;
                    return 0;
                  }
                  if (!a.poDate || !a.poDate.length)
                    return b.poDate && b.poDate.length ? 1 : 0;
                  if (!b.poDate || !b.poDate.length) return -1;
                  return new Date(a.poDate) >= new Date(b.poDate) ? 1 : -1;
                });
              dispatch({ type: "UPDATE_FIELD", detail: newDetail });
            } else dispatch({ type: "ERROR", message: data.message });
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  addLink:
    (url: string, display: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const pId = getState().detail.detail.id;
        fetch(
          `api/Project/Link?id=${pId}&url=${encodeURI(
            url
          )}&displayName=${display}`,
          { method: "POST" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              dispatch({ type: "UPDATE_FIELD", detail: data });
            } else dispatch({ type: "ERROR", message: data.message });
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  editLink:
    (id: number, url: string, display: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const pId = getState().detail.detail.id;
        fetch(
          `api/Project/EditLink?id=${id}&pId=${pId}&newUrl=${encodeURI(
            url
          )}&displayName=${display}`,
          { method: "PUT" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              dispatch({ type: "UPDATE_FIELD", detail: data });
            } else dispatch({ type: "ERROR", message: data.message });
          });
        dispatch({ type: "SAVING_DETAIL" });
      },
  newMaterial:
    (name: string, milestoneId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/Material/Post?name=${name}&milestoneId=${milestoneId}`, {
          method: "POST",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              //do something
            } else dispatch({ type: "ERROR", message: data.message });
          });
      },
  deleteEntry:
    (entryId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(
          `api/Material/DeleteEntry?projectId=${getState().detail.detail.id
          }&entryId=${entryId}`,
          {
            method: "DELETE",
          }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newDetail = Object.assign({}, getState().detail.detail);
              newDetail.materials = newDetail.materials.filter(
                (m) => m.entryId !== entryId
              );
              dispatch({ type: "RECEIVE_DETAIL", detail: newDetail });
            } else dispatch({ type: "ERROR", message: data.message });
          });
      },
  beginCloseout: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(
      `api/ProjectCloseout/BeginCloseout?projectId=${getState().detail.projId}`,
      {
        method: "PUT",
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const newDetail = getState().detail.detail;
          newDetail.closeoutStarted = true;
          dispatch({ type: "UPDATE_FIELD", detail: newDetail });
        } else dispatch({ type: "ERROR", message: data.message });
      });
  },
  pause:
    (notes: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(
          `api/Project/Pause?id=${getState().detail.detail.id}&notes=${notes}`,
          { method: "PUT" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newDetail = getState().detail.detail;
              newDetail.latestPause = data;
              dispatch({ type: "UPDATE_FIELD", detail: newDetail });
            } else dispatch({ type: "ERROR", message: data.message });
          });
      },
  resume:
    (notes: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(
          `api/Project/Resume?id=${getState().detail.detail.id}&notes=${notes}`,
          { method: "PUT" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newDetail = getState().detail.detail;
              newDetail.latestPause = undefined;
              dispatch({ type: "UPDATE_FIELD", detail: newDetail });
            } else dispatch({ type: "ERROR", message: data.message });
          });
      },
  toggleEditProjectNumber: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "TOGGLE_EDIT_PROJECT_NUMBER" });
  },
  clear: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "RESET" });
  },
  clearMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "ERROR", message: "" });
  },
};

const unloadedState: Store = {
  projId: 0,
  detail: {} as ProjectDetailVm,
  brands: [],
  clientPMs: [],
  internalPMs: [],
  ownerReps: [],
  projectTypes: [],
  projectStatuses: [],
  vendors: [],
  divisions: [],
  editProjectNumber: false,
  isLoading: false,
  isSaving: false,
};

//@ts-ignore
export const reducer: Reducer<Store> = (
  state: Store,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_DETAIL":
      return {
        ...state,
        projId: action.projId,
        //detail: action.detail,
        isLoading: true,
      };
    case "RECEIVE_DETAIL":
      return {
        ...state,
        detail: action.detail,
        brands: action.brands || [],
        projectTypes: action.projectTypes || [],
        projectStatuses: action.projectStatuses || [],
        clientPMs: action.clientPMs || [],
        internalPMs: action.internalPMs || [],
        vendors: action.vendors || [],
        divisions: action.divisions || state.divisions,
        isLoading: false,
      };
    case "UPDATE_FIELD":
      return {
        ...state,
        detail: action.detail,
        message: "Saved",
        editProjectNumber: false,
        isSaving: false,
      };
    case "ADD_MATERIAL_ENTRY":
      return { ...state, detail: action.detail };
    case "REMOVE_ENTRY":
      return { ...state, detail: action.detail };
    case "SAVING_DETAIL":
      return { ...state, message: undefined, isSaving: true };
    case "RESET":
      return { ...state, detail: {} as ProjectDetailVm, message: undefined };
    case "ERROR":
      return {
        ...state,
        message: action.message,
        isLoading: false,
        isSaving: false,
      };
    case "DETAIL_SAVED":
      return {
        ...state,
        message: action.message,
        isLoading: false,
        isSaving: false,
      };
    case "TOGGLE_EDIT_PROJECT_NUMBER":
      return { ...state, editProjectNumber: !state.editProjectNumber };
    default: {
      const exhaustiveCheck: never = action;
    }
  }
  return state || unloadedState;
};
