import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { UserInfo } from '../interfaces/interfaces'

export interface ProjectShortcut {
  projectId: number,
  lastAccessed: string,
  projectNumber: string,
  projectName: string,
}

export interface GlobalStore {
  users: UserInfo[],
  favoriteProjects: ProjectShortcut[],  
  recentProjects: ProjectShortcut[],
  message?: string
}

interface UpdateMessage {
  type: 'UPDATE_MESSAGE_ACTION',
  message?: string
}

interface GetFavoriteProjectsAction {
  type: 'GET_FAVORITE_PROJECTS',
  projects: ProjectShortcut[],
}

interface GetRecentProjectsAction {
  type: 'GET_RECENT_PROJECTS',
  projects: ProjectShortcut[],
}

interface GetUsersAction {
  type: 'GET_USERS_ACTION',
  users: UserInfo[]
}

type KnownAction = UpdateMessage | GetUsersAction | GetFavoriteProjectsAction | GetRecentProjectsAction

export const actionCreators = {
  getFavoriteProjects: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(`api/project/favorites`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) dispatch({ type: 'GET_FAVORITE_PROJECTS', projects: data })
      })
  },
  getRecentProjects: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(`api/project/recent`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) dispatch({ type: 'GET_RECENT_PROJECTS', projects: data })
      })
  },
  getUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    if (getState().user.user !== undefined && !getState().global.users || !getState().global.users.length) {
      fetch(`api/User/GetAllInfos?activeOnly=true`)
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({ type: 'GET_USERS_ACTION', users: data })
          }
          else {
            dispatch({ type: 'UPDATE_MESSAGE_ACTION', message: data.message })
          }
        })

    }
  },
  updateMessage: (message: string): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'UPDATE_MESSAGE_ACTION', message: message })
  },
  clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'UPDATE_MESSAGE_ACTION', message: undefined })
  }
}

const unloadedState: GlobalStore = {
  users: [],
  favoriteProjects: [] as ProjectShortcut[],
  recentProjects: [] as ProjectShortcut[],

}

//@ts-ignore
export const reducer: Reducer<GlobalStore> = (state: GlobalStore, incomingAction: Action) => {
  const action = incomingAction as KnownAction
  switch (action.type) {
    case 'UPDATE_MESSAGE_ACTION':
      return { ...state, message: action.message }
    case 'GET_FAVORITE_PROJECTS':
      return { ...state, favoriteProjects: action.projects }
    case 'GET_RECENT_PROJECTS':
      return { ...state, recentProjects: action.projects }
    case 'GET_USERS_ACTION':
      return { ...state, users: action.users }
    default: {
      const exhaustiveCheck: never = action
    }
  }
  return state || unloadedState
}