import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import { Loader } from 'src/components/Loader';
import { CSharpTypeCode } from 'src/interfaces/enums';
import { PunchlistTemplateVm, UpdateObject } from 'src/interfaces/interfaces';
import { ApplicationState } from 'src/store/index';
import {
  PunchlistActions,
  templatesSelector,
  templateStatusSelector,
} from 'src/store/punchlist/index';
import { PunchlistTemplateModal } from './PunchlistTemplateModal';

const columns: MetaFieldInfo[] = [
  {
    displayName: 'Name',
    propName: 'title',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Created By',
    propName: 'userFullName',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Last Modified Date',
    propName: 'lastModifiedTimestamp',
    jsType: 'date',
    propType: CSharpTypeCode.DateTime,
  },
];

export const PunchlistHome = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentTemplateId, setCurrentTemplateId] = useState<
    number | undefined
  >(undefined);
  const templates = useSelector(templatesSelector);
  const templateStatus = useSelector(templateStatusSelector);
  const userStore = useSelector((s: ApplicationState) => s.user);

  const dispatch = useDispatch();

  const currentTemplate = useMemo(() => {
    return templates.find((x) => x.id == currentTemplateId);
  }, [currentTemplateId, templates]);

  const isCreator = useMemo(() => {
    return userStore.user
      ? userStore.user.userId === currentTemplate?.userId
      : false;
  }, [currentTemplate, userStore]);

  const onRowClick = (template: PunchlistTemplateVm) => {
    setCurrentTemplateId(template.id);
    setIsModalVisible(true);
  };

  const renderItem = useCallback(
    (template: PunchlistTemplateVm) => {
      return (
        <div
          onClick={() => onRowClick(template)}
          className="sv-grid-line"
          key={template.id}
        >
          <div className="col">{template.title}</div>
          <div className="col">{template.userFullName}</div>
          <div className="col">
            {new Date(template.lastModifiedTimestamp).toLocaleString()}
          </div>
        </div>
      );
    },
    [templates]
  );

  const onModalClose = useCallback(() => {
    setIsModalVisible(false);
    setCurrentTemplateId(undefined);
  }, [currentTemplateId, isModalVisible]);

  const onTemplateUpdate = useCallback((id: number, update: UpdateObject) => {
    dispatch(PunchlistActions.updateTemplate({ id: id, info: update }));
  }, []);

  const onTemplateItemAdd = useCallback((id: number) => {
    dispatch(PunchlistActions.addTemplateItem(id));
  }, []);

  const onTemplateItemUpdate = useCallback(
    (id: number, update: UpdateObject) => {
      dispatch(PunchlistActions.updateTemplateItem({ id: id, info: update }));
    },
    []
  );

  const onTemplateCopy = useCallback((id: number) => {
    dispatch(PunchlistActions.copyTemplate(id));
  }, []);

  const onTemplateItemRemove = useCallback((id: number) => {
    dispatch(PunchlistActions.removeTemplateItem(id));
  }, []);

  useEffect(() => {
    if (templateStatus == 'idle') {
      dispatch(PunchlistActions.getTemplates);
    }
  }, [templateStatus]);

  return (
    <>
      <Loader loading={templateStatus == 'loading'}></Loader>
      {isModalVisible && currentTemplate && (
        <PunchlistTemplateModal
          template={currentTemplate}
          onModalClose={onModalClose}
          onTemplateUpdate={onTemplateUpdate}
          onTemplateItemAdd={onTemplateItemAdd}
          onTemplateItemUpdate={onTemplateItemUpdate}
          onTemplateCopy={onTemplateCopy}
          onTemplateItemRemove={onTemplateItemRemove}
          isLoading={templateStatus == 'loading'}
          isCreator={isCreator}
        />
      )}
      <CustomizableGrid
        items={templates ?? []}
        columns={columns}
        hideLoadingWhenEmpty={true}
        mapCallback={renderItem}
        evenColumnSpacing={true}
        addCallback={() => dispatch(PunchlistActions.addTemplate())}
        loading={templateStatus == 'loading'}
      />
    </>
  );
};
