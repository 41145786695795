import * as React from 'react';
import ContentLoader from 'react-content-loader';

export const FileExplorerLoader = () => {
    
    const rows = Array.from({ length: 10 }, (v, k) => k + 1).map((x, i) => {
        const y = (i * 29) + 5,
            width = i % 2 == 0 ? 200 : i % 3 === 0 ? 250 : 300
        return <rect key={x} x='25' y={y} rx='2' ry='2' width={width} height='19' />
    })

    const icons = Array.from({ length: 10 }, (v, k) => k + 1).map((x, i) => {
        const y = (i * 29) + 5            
        return <rect key={x} x='0' y={y} rx='2' ry='2' width='19' height='19' />
    })

    return (
        <ContentLoader
            speed={2}
            interval={0}
            gradientRatio={0.5}
            width={'100%'}
            height={300}
            backgroundColor="#ddd"
            foregroundColor="#eee"
        >
            {icons}
            {rows}
        </ContentLoader>
    )
}