import * as React from 'react';
import { RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import * as UserStore from '../store/user';
import { Tabs, TabLink, TabContent } from '../tabs';
import { VendorInfoForm } from './VendorInfoForm';
import { VendorVm } from '../interfaces/interfaces';
import { VendorProjectGrid } from './VendorProjectGrid';
import { VendorUserGrid } from './VendorUserGrid';
import { VendorComplianceTab } from './VendorCompliance';
import { FileExplorer } from '../components/FileExplorer';
import { FileExplorerType } from '../interfaces/enums';
import { hasRole, isUserById, KnownRoles } from '../auth/auth';
import { VendorChecklistGrid } from './VendorChecklistGrid';
import { RectangleLoader } from '../loaders/RectangleLoader';
import { ConnectedFileExplorer } from 'src/components/ConnectedFileExplorer';
import { BackLink } from '../components/BackLink';

interface State {
  vendorId: number,
  vendorName: string,
  vendorInfo: VendorVm,
  defaultTab?: string
}

type Props =
   RouteComponentProps<{ id: string }>;

export const VendorDetail = () => {

  //class VendorDetail extends React.Component<Props, State> {

  const params = useParams();
  const location = useLocation();
  console.log(params);

  // @ts-ignore
  const [state, setState] = React.useState({ vendorId: parseInt(params.id), defaultTab: location.hash.substr(1), loading: true, vendorInfo: {} as VendorVm });

  const userStore = useSelector((s: ApplicationState) => s.user);

  React.useEffect(() => {
    _getVendorInfo();
  }, [])

  const _getVendorInfo = () => {
    fetch(`api/Vendor/Detail?id=${state.vendorId}`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setState({ ...state, vendorInfo: data, loading: false, });
      })
  }

  const { vendorId, vendorInfo, loading } = state;
  const { user } = userStore;
  return (
    <div className='vendor-detail'>
      <BackLink link={`/subs`} />
      <div className='pageHeader'>
        {loading ? <RectangleLoader height='40px' width='300px' /> : vendorInfo.name}
      </div>
      <Tabs name='vendor-tabs' onChange={(t: string) => { }}
        recallTab={state.defaultTab} renderActiveTabContentOnly={true}>
        <div className='tab-links'>
          <TabLink to='info' component='span'><span>Info</span></TabLink>
          <TabLink to='users' component='span'><span>Users</span></TabLink>
          <TabLink to='projects' component='span'><span>Projects</span></TabLink>
          <TabLink to='compliance' component='span'><span>Compliance</span></TabLink>
          <TabLink to='checklists' component='span'><span>Checklists</span></TabLink>
          <TabLink to='explorer' component='span'><span>File Explorer</span></TabLink>
        </div>
        <div className='content'>
          <TabContent for='info'>
            {/* We can force the VendorInfoForm to wait for the info prop instead of making its own call by NOT passing the id prop */}
            <VendorInfoForm info={vendorInfo} key={vendorId} />
          </TabContent>
          <TabContent for='users'>
            <VendorUserGrid id={state.vendorId} />
          </TabContent>
          <TabContent for='projects'>
            <VendorProjectGrid vendor={vendorInfo} />
          </TabContent>
          <TabContent for='compliance'>
            <VendorComplianceTab vendorId={state.vendorId} />
          </TabContent>
          <TabContent for='explorer'>
            <ConnectedFileExplorer
              key={state.vendorId}
              startingDir={`Vendors/${state.vendorId}`}
              breadCrumbStartLevel={2}
              canSubscribeUsers={hasRole(user, [KnownRoles.Admin, KnownRoles.CoreSuperUser])}
              params={{ id: state.vendorId }}
              type={FileExplorerType.Vendor}
              droppableId={'vendor-detail-droppable'}
              draggableId={'vendor-detail-draggable'}
            />
          </TabContent>
          <TabContent for='checklists'>
            <VendorChecklistGrid vendorId={state.vendorId} />
          </TabContent>
        </div>
      </Tabs>
    </div>
  )
}

////@ts-ignore
//export default connect(
//  (state: ApplicationState) => state.user,
//  null
//  // @ts-ignore
//)(VendorDetail) as typeof VendorDetail
