import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from '../components/CustomizableGrid';
import { Modal2 } from '../components/Modal';
import { formatDate } from '../helpers/formatters';
import { CSharpTypeCode } from '../interfaces/enums';
import {
  FilterOption,
  PunchItemStatus,
  PunchItemVm,
  UpdateObject,
} from '../interfaces/interfaces';
import { mockPunchItem2 } from '../services/punchlist/mocks';
import { ApplicationState } from '../store/index';
import { ConnectedPunchItem } from './ConnectedPunchItem';
import { PunchItem } from './PunchItem';
import * as cx from 'classnames';
import {
  PunchlistActions,
  punchItemsLoadingSelector,
} from '../store/punchlist/index';

export const PunchlistGrid = (props: {
  items: PunchItemVm[];
  filterCallback?: (f: FilterOption[]) => void;
}) => {
  const { items, filterCallback } = props;
  const [detailId, setDetailId] = React.useState(null as null | number);
  const [modalShouldAnimate, setModalShouldAnimate] = React.useState(true);
  const prevDetailId = React.useRef(null as null | number);
  const loading = useSelector(punchItemsLoadingSelector) === 'loading';
  const dispatch = useDispatch();

  React.useEffect(() => {
    //('Animations: ' + modalShouldAnimate ? 'true' : 'false');

    if (!modalShouldAnimate && prevDetailId.current === null)
      setModalShouldAnimate(true);

    prevDetailId.current = detailId;

    const timer = setTimeout(() => {
      setModalShouldAnimate(false);
    }, 1);

    return () => {
      clearTimeout(timer);
    };
  }, [detailId]);

  const columns: MetaFieldInfo[] = [
    {
      displayName: '',
      propName: '',
      jsType: '',
      propType: CSharpTypeCode.Object,
      columnWidth: 1,
    },
    {
      displayName: 'Title',
      propName: 'title',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 4,
    },
    {
      displayName: 'Category',
      propName: 'category',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 3,
    },
    {
      displayName: 'Created',
      propName: 'createdDate',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 3,
    },
    {
      displayName: 'Status',
      propName: 'statusName',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 3,
    },
    {
      displayName: 'Ball In Court',
      propName: 'ballInCourtName',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 3,
    },
    {
      displayName: 'Assignee',
      propName: 'assigneeName',
      jsType: 'string',
      propType: CSharpTypeCode.String,
      columnWidth: 3,
    },
    //{ displayName: 'Rating', propName: 'averageRating', jsType: 'number', propType: CSharpTypeCode.Decimal },
  ];

  const sendPunchItem = React.useCallback(
    (id: number) => {
      const info: UpdateObject = {
        fieldName: 'status',
        value: PunchItemStatus.Sent.toString(),
      };
      dispatch(
        PunchlistActions.updatePunchItem({
          id: id,
          updateObject: info,
        })
      );
    },
    [dispatch]
  );

  const _lineMap = (item: PunchItemVm) => {
    return (
      <div
        onClick={() => setDetailId(item.id)}
        className="sv-grid-line"
        key={item.id}
      >
        {/*{cols.map(c => {*/}
        {/*    <div className={`my-col-${colWidth}`}>{item[c.propName]}</div>*/}
        {/*})}*/}
        <div className="my-col-1 sortable truncate">
          {item.comments.length ? (
            <span
              className="fas fa-comment"
              title="There are comments on this item"
            ></span>
          ) : (
            []
          )}
        </div>
        <div className="my-col-4 sortable truncate">{item.title}</div>
        <div className="my-col-3 sortable truncate">{item.category}</div>
        <div className="my-col-3 sortable truncate">
          {formatDate(item.createdDate)}
        </div>
        <div className="my-col-3 sortable truncate">{item.statusName}</div>
        <div className="my-col-3 sortable truncate">{item.ballInCourtName}</div>
        <div className="my-col-3 sortable truncate">{item.assigneeName}</div>
      </div>
    );
  };

  const DetailModal = () => {
    const item = items.find((x) => x.id === detailId);

    if (item) {
      return (
        <Modal2
          dialogStyle={{ minWidth: '1150px' }}
          modalClass={cx(modalShouldAnimate ? '' : 'no-animation')}
        >
          <div className="modal-header">
            <h4>Item Details</h4>
          </div>
          <div className="modal-body">
            <ConnectedPunchItem key={item.id} item={item} />
          </div>
          <div className="modal-footer">
            {item.status == PunchItemStatus.Draft && (
              <button
                className="btn btn-sm btn-green"
                onClick={() => sendPunchItem(item.id)}
              >
                Send
              </button>
            )}
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={() => setDetailId(null)}
            >
              Close
            </button>
          </div>
        </Modal2>
      );
    }
    return <div></div>;
  };

  return (
    <div className="punchlist-grid">
      {detailId && <DetailModal key={detailId} />}
      <CustomizableGrid
        items={items}
        columns={columns}
        mapCallback={_lineMap}
        filterCallback={filterCallback}
        loading={loading}
        hideLoadingWhenEmpty={true}
      />
    </div>
  );
};
