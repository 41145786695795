//import { fetch, addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import {
  Weather,
  Equipment,
  Personnel,
  DfrPhoto,
  DFR,
  ErrorAction,
  SaveAction,
  DfrEvent,
  FieldReportExternalFile,
  FieldReportSafetyKpiEntry,
  DailyFieldReportTemplate,
  UpdateObject,
} from '../interfaces/interfaces';

export interface Store {
  dfrs: DFR[];
  unfilteredDfrs: DFR[];
  projectNumber: string;
  projectId: number;
  latitude: number;
  longitude: number;

  date: string;
  reportName: string;
  userId: string;
  userFullName: string;
  approverName: string;
  projectName: string;
  event: DfrEvent[];
  isBadWeather: boolean;
  visitors: string;
  weather: Weather;
  comment: string;
  formalDescription: string;
  temperaturesTaken: boolean;
  covidPoliciesObserved: boolean;
  hasNoIncident: boolean;
  covidComments: string;
  equipment: Equipment[];
  personnel: Personnel[];
  photos: DfrPhoto[];
  pmApproved: boolean;
  generalSuperApproved: boolean;
  creatorApproved?: boolean;
  isLocked: boolean;
  isSubmitted: boolean;
  isLoading: boolean;
  isSaving: boolean;
  message?: string;
  id: number; // from url
  pmId: string;
  creatorId: string;
  generalSuperId: string;
  previousId?: number;
  nextId?: number;
  isExternal: boolean;
  externalFile: FieldReportExternalFile | null;
  kpiEntries: FieldReportSafetyKpiEntry[];
  templates: DailyFieldReportTemplate[];
  currentTemplateId: number | null;
  currentTemplateUpdate: UpdateObject | null;
  dailyFieldReportTemplateId?: number;
}

interface RequestDfrAction {
  type: 'REQUEST_DFRS';
}

interface ReceiveAllDfrAction {
  type: 'RECEIVE_DFRS';
  dfrs: DFR[];
  unfilteredDfrs: DFR[];
  projectId: number;
  pmId: string;
}

interface ReceiveSelectedDfrAction {
  type: 'RECEIVE_SELECTED_DFR';
  projectId?: number;
  projectNumber?: string;
  projectName?: string;
  reportName?: string;
  userId?: string;
  userFullName?: string;
  approverName?: string;
  latitude?: number;
  longitude?: number;
  previousId?: number;
  nextId?: number;
  date?: string;
  weather?: Weather;
  comment?: string;
  formalDescription?: string;
  temperaturesTaken?: boolean;
  covidPoliciesObserved?: boolean;
  hasNoIncident?: boolean;
  covidComments?: string;
  equipment?: Equipment[];
  event?: DfrEvent[];
  personnel?: Personnel[];
  photos?: DfrPhoto[];
  visitors?: string;
  pmApproved?: boolean;
  generalSuperApproved?: boolean;
  creatorApproved?: boolean;
  isLocked?: boolean;
  isSubmitted?: boolean;
  message?: string;
  id?: number;
  pmId?: string;
  generalSuperId?: string;
  creatorId?: string;
  isExternal?: boolean;
  externalFile?: FieldReportExternalFile;
  kpiEntries?: FieldReportSafetyKpiEntry[];
  dailyFieldReportTemplateId?: number;
}

interface AddDfrAction {
  type: 'ADD_DFR';
  dfrs: DFR[];
  unfilteredDfrs: DFR[];
  date: string;
}

interface AddPersonnelDfrAction {
  type: 'ADD_PERSONNEL_DFR';
  personnel: Personnel[];
}

interface AddEquipmentDfrAction {
  type: 'ADD_EQUIPMENT_DFR';
  equipment: Equipment[];
}

interface AddEventDfrAction {
  type: 'ADD_EVENT_DFR';
  event: DfrEvent[];
}

interface ReceievePhotosAction {
  type: 'RECEIVE_DFR_PHOTO';
  photos: DfrPhoto[];
}

interface UpdateDFRAction {
  type: 'UPDATE_DFR';
  temperaturesTaken?: boolean;
  covidPoliciesObserved?: boolean;
  covidComments?: string;
  visitors?: string;
  comment?: string;
  formalDescription?: string;
  isBadWeather?: boolean;
}

interface LockingAction {
  type: 'SUBMIT_DFR';
  isSubmitted: boolean;
  message: string;
  weather: Weather;
}

interface ApproveAction {
  type: 'APPROVE_DFR';
  isLocked: boolean;
  message: string;
  isLoading: boolean;
}

interface SavingAction {
  type: 'SAVING_DFR';
}

interface DeletePersonnelDfrAction {
  type: 'DELETE_PERSONNEL_DFR_ROW';
  personnel: Personnel[];
}

interface DeleteEquipmentDfrAction {
  type: 'DELETE_EQUIPMENT_DFR_ROW';
  equipment: Equipment[];
}

interface DeleteEventDfrAction {
  type: 'DELETE_EVENT_DFR_ROW';
  event: DfrEvent[];
}

interface DeletePhotoDfrAction {
  type: 'DELETE_PHOTO_DFR_ROW';
  photos: DfrPhoto[];
}
interface AddingLoaderAction {
  type: 'ADDING_LOADING_DFR';
}

interface UpdateRowsAction {
  type: 'UPDATE_ROW';
  equipment?: Equipment[];
  personnel?: Personnel[];
  photos?: DfrPhoto[];
  event?: DfrEvent[];
}

interface RejectAction {
  type: 'REJECT_DFR';
  message: string;
}

interface ClearAction {
  type: 'CLEAR_DFR';
}

interface EditDateAndReportNameAction {
  type: 'EDIT_DATE_REPORTNAME';
  date: string;
  reportName: string;
}

interface ERROREditDateAndReportNameAction {
  type: 'ERROR_EDIT_DATE_REPORTNAME';
  message: string;
}

interface ToggleLoadingAction {
  type: 'TOGGLE_LOADING';
}

interface ToggleSavingAction {
  type: 'TOGGLE_SAVING';
}

interface UpdateKPIEntryAction {
  type: 'UPDATE_KPI';
  entries: FieldReportSafetyKpiEntry[];
}

interface RequestTemplatesAction {
  type: 'REQUEST_TEMPLATES';
}

interface SetTemplatesAction {
  type: 'SET_TEMPLATES';
  templates: DailyFieldReportTemplate[];
}

interface AddTemplateAction {
  type: 'ADD_TEMPLATE';
}

interface CopyTemplateAction {
  type: 'COPY_TEMPLATE';
}

interface EditTemplateAction {
  type: 'EDIT_TEMPLATE';
}

interface SetTemplateIdAction {
  type: 'SET_TEMPLATE_ID';
  id: number | null;
}

interface SetTemplateUpdateAction {
  type: 'SET_TEMPLATE_UPDATE';
  update: UpdateObject | null;
}

export interface ClearDfrsAction {
  type: 'CLEAR_DFR_LIST';
}

//interface BACKTODFRLISTFROMDFRMAIN_RESETAction {
//    type: 'BACKTODFRLISTFROMDFRMAIN_RESET'
//}

type KnownAction =
  | RequestDfrAction
  | ReceiveAllDfrAction
  | AddDfrAction
  | SavingAction
  | ReceiveSelectedDfrAction
  | AddPersonnelDfrAction
  | AddEquipmentDfrAction
  | DeletePersonnelDfrAction
  | DeleteEquipmentDfrAction
  | ReceievePhotosAction
  | DeletePhotoDfrAction
  | ErrorAction
  | SaveAction
  | UpdateDFRAction
  | LockingAction
  | AddingLoaderAction
  | UpdateRowsAction
  | AddEventDfrAction
  | DeleteEventDfrAction
  | ApproveAction
  | RejectAction
  | ClearAction
  | EditDateAndReportNameAction
  | ERROREditDateAndReportNameAction
  | ToggleLoadingAction
  | ToggleSavingAction
  | ClearDfrsAction
  | UpdateKPIEntryAction
  | RequestTemplatesAction
  | SetTemplatesAction
  | AddTemplateAction
  | EditTemplateAction
  | CopyTemplateAction
  | SetTemplateIdAction
  | SetTemplateUpdateAction;

export const actionCreators = {
  toggleLoading: (): AppThunkAction<KnownAction> => (dispatch) =>
    dispatch({ type: 'TOGGLE_LOADING' }),
  toggleSaving: (): AppThunkAction<KnownAction> => (dispatch) =>
    dispatch({ type: 'TOGGLE_SAVING' }),
  error:
    (message: string): AppThunkAction<KnownAction> =>
    (dispatch) =>
      dispatch({ type: 'ERROR_EDIT_DATE_REPORTNAME', message: message }),
  getAllProjectDfrs:
    (projectId: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/GetAll?projectId=${projectId}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({
              type: 'RECEIVE_DFRS',
              dfrs: data.dfrs,
              unfilteredDfrs: data.dfrs,
              pmId: data.pmId,
              projectId: projectId,
            });
          } else {
            // error message
          }
        });
      dispatch({ type: 'REQUEST_DFRS' });
    },
  getDfr:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      if (id !== getState().dfr.id) {
        fetch(`api/DailyFieldReport/GetDfr?id=${id}`)
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            //formatt date to MM/dd/yyyy
            const date = data.date.split('T')[0];
            const fd = date.replace(/-/g, '/');
            if (resOk) {
              dispatch({
                type: 'RECEIVE_SELECTED_DFR',
                date: fd,
                reportName: data.reportName,
                userFullName: data.userFullName,
                approverName: data.approverName,
                userId: data.userId,
                projectName: data.projectName,
                weather: data.weather,
                comment: data.comment,
                formalDescription: data.formalDescription,
                temperaturesTaken: data.temperaturesTaken,
                covidPoliciesObserved: data.covidPoliciesObserved,
                hasNoIncident: data.hasNoIncident,
                covidComments: data.covidComments,
                equipment: data.equipment,
                event: data.event,
                personnel: data.personnels,
                photos: data.photos,
                pmApproved: data.pmApproved,
                generalSuperApproved: data.generalSuperApproved,
                creatorApproved: data.creatorApproved,
                isLocked: data.isLocked,
                isSubmitted: data.submitted,
                visitors: data.visitors,
                id: id,
                projectNumber: data.projectNumber,
                projectId: data.projectId,
                pmId: data.pmId,
                generalSuperId: data.generalSuperId,
                creatorId: data.creatorId,
                latitude: data.latitude,
                longitude: data.longitude,
                previousId: data.previousId,
                nextId: data.nextId,
                isExternal: data.isExternal,
                externalFile: data.externalFile || null,
                kpiEntries: data.kpiEntries,
                dailyFieldReportTemplateId: data.dailyFieldReportTemplateId,
              });
            } else {
              //warning message
              dispatch({ type: 'ERROR', message: data.message });
            }
          });
        dispatch({ type: 'REQUEST_DFRS' });
      }
    },

  submit:
    (id: number, callback?: () => void): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: 'TOGGLE_LOADING' });

      fetch(`api/DailyFieldReport/Submit?id=${id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({
              type: 'SUBMIT_DFR',
              isSubmitted: true,
              message: 'Submitted',
              weather: data,
            });
            if (callback) callback();
          } else {
            // warning message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
    },
  lock:
    (
      id: number,
      photoIds: number[],
      callback?: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/Approve?id=${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(photoIds),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({
              type: 'RECEIVE_SELECTED_DFR',
              photos: data.photos,
              isLocked: data.isLocked,
              pmApproved: data.pmApproved,
              generalSuperApproved: data.generalSuperApproved,
              creatorApproved: data.creatorApproved,
              message: 'Saved',
            });
            if (callback) callback();
          } else {
            // warning message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      dispatch({ type: 'TOGGLE_LOADING' });
    },
  reject:
    (id: number, reason: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/Reject?id=${id}&reason=${reason}`, {
        method: 'PUT',
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            //Do something
          } else dispatch({ type: 'ERROR', message: data.message });
        });
      dispatch({ type: 'SAVING_DFR' });
    },
  addDfr:
    (
      projectId: number,
      selectedDate: string,
      reportName: string,
      isExternal: boolean,
      templateId?: number,
      assignee?: string,
      dueDate?: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      if (selectedDate == '') {
        dispatch({ type: 'ERROR', message: 'Error: Please select a date' });
      } else {
        fetch(
          `api/DailyFieldReport/Add?id=${projectId}&date=${selectedDate}&reportName=${reportName}&isExternal=${isExternal}${
            templateId ? '&templateId=' + templateId : ''
          }${assignee ? '&userId=' + assignee : ''}
          ${dueDate ? '&dueDate=' + dueDate : ''}`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            //getState().dfr.id = data.id;
            if (resOk) {
              const dfrs = getState().dfr.unfilteredDfrs.slice();
              dfrs.unshift(data);
              dispatch({
                type: 'ADD_DFR',
                dfrs: dfrs,
                unfilteredDfrs: dfrs,
                date: selectedDate,
              });
            } else {
              // error message - could not add dfr
              dispatch({ type: 'ERROR', message: data.message });
            }
          });
        dispatch({ type: 'SAVING_DFR' });
      }
    },

  addPersonnel:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/AddPersonnel?id=${id}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            const addP = getState().dfr.personnel.slice();
            addP.push(data);
            dispatch({ type: 'ADD_PERSONNEL_DFR', personnel: addP });
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      //dispatch({ type: 'ADDING_LOADING_DFR' });
    },

  addEquipment:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/AddEquipment?id=${id}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            const addE = getState().dfr.equipment.slice();
            addE.push(data);
            dispatch({ type: 'ADD_EQUIPMENT_DFR', equipment: addE });
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      //dispatch({ type: 'ADDING_LOADING_DFR' });
    },

  addEvent:
    (id: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/AddEvent?id=${id}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: id,
        }),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            const addE = getState().dfr.event.slice();
            addE.push(data);
            dispatch({ type: 'ADD_EVENT_DFR', event: addE });
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      //dispatch({ type: 'ADDING_LOADING_DFR' });
    },
  addExternalFile:
    (id: number, file: File): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const form = new FormData();

      form.append(
        new Date(file.lastModified).toLocaleString(),
        file,
        file.name.replace(/#/g, '')
      );
      fetch(`api/DailyFieldReport/AddExternalFile?id=${id}`, {
        method: 'POST',
        body: form,
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({
              type: 'RECEIVE_SELECTED_DFR',
              externalFile: data.externalFile,
            });
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      //dispatch({ type: 'ADDING_LOADING_DFR' });
    },
  addPhoto:
    (id: number, files: FileList | null): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const formData = new FormData();

      if (files)
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formData.append(
              new Date(files[i].lastModified).toLocaleString(),
              files[i],
              files[i].name.replace(/#/g, '')
            );
          }
        }

      fetch(`api/DailyFieldReport/AddPhoto?id=${id}`, {
        method: 'POST',
        body: formData,
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({ type: 'RECEIVE_DFR_PHOTO', photos: data });
          } else {
            //error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      dispatch({ type: 'REQUEST_DFRS' });
    },
  rotatePhoto:
    (id: number, clockwise: boolean): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(
        `api/DailyFieldReport/Rotate?photoId=${id}&clockwise=${clockwise}`,
        {
          method: 'PUT',
        }
      )
        .then((res) => Promise.all([res.ok, res.json() as Promise<DfrPhoto>]))
        .then(([resOk, data]) => {
          if (resOk) {
            const photos = getState()
              .dfr.photos.slice()
              .map((x) => {
                if (x.id === id) {
                  x.filePath = data.filePath;
                  x.fileName = data.fileName;
                }
                return x;
              });

            dispatch({ type: 'RECEIVE_DFR_PHOTO', photos: photos });
          } else {
            alert('Error encountered when trying to rotate photo');
          }
        });
    },
  updateChanges:
    (
      entityId: number,
      fieldName: string,
      value: string,
      type: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(
        `api/DailyFieldReport/Update?id=${
          getState().dfr.id
        }&entityId=${entityId}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fieldName: fieldName,
            type: type,
            value: value,
          }),
        }
      )
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            switch (type) {
              case 1:
                dispatch({ type: 'UPDATE_ROW', equipment: data.result });
                break;
              case 2:
                dispatch({ type: 'UPDATE_ROW', personnel: data.result });
                break;
              case 3:
                dispatch({ type: 'UPDATE_ROW', photos: data.result });
                break;
              case 4:
                dispatch({ type: 'UPDATE_ROW', event: data.result });
                break;
            }
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
    },
  updatePhotoImage:
    (
      id: number,
      entityId: number,
      files: FileList | null
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const formData = new FormData();

      if (files)
        if (files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            formData.append(
              new Date(files[i].lastModified).toLocaleString(),
              files[i],
              files[i].name.replace(/#/g, '')
            );
          }
        }

      fetch(
        `api/DailyFieldReport/UpdatePhotoImage?id=${id}&entityId=${entityId}`,
        {
          method: 'PUT',
          body: formData,
        }
      )
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            dispatch({ type: 'RECEIVE_DFR_PHOTO', photos: data });
          } else {
            //error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
      dispatch({ type: 'REQUEST_DFRS' });
    },
  updateDFR:
    (value: string | boolean, fieldname: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/UpdateDFR?id=${getState().dfr.id}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          value: value,
          fieldName: fieldname,
        }),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            switch (fieldname) {
              case 'temperaturesTaken':
                dispatch({
                  type: 'UPDATE_DFR',
                  temperaturesTaken: Boolean(value),
                });
                break;
              case 'covidPoliciesObserved':
                dispatch({
                  type: 'UPDATE_DFR',
                  covidPoliciesObserved: Boolean(value),
                });
                break;
              case 'comment':
                dispatch({ type: 'UPDATE_DFR', comment: value.toString() });
                break;
              case 'formalDescription':
                dispatch({
                  type: 'UPDATE_DFR',
                  formalDescription: value.toString(),
                });
                break;
              case 'visitor':
                dispatch({ type: 'UPDATE_DFR', visitors: value.toString() });
                break;
              case 'covidComments':
                dispatch({
                  type: 'UPDATE_DFR',
                  covidComments: value.toString(),
                });
                break;
              case 'badWeather':
                if (value == 'bad')
                  dispatch({ type: 'UPDATE_DFR', isBadWeather: true });
                else dispatch({ type: 'UPDATE_DFR', isBadWeather: false });
                break;
            }
          } else {
            // error message
            dispatch({ type: 'ERROR', message: data.message });
          }
        });
    },

  editDateAndReportName:
    (date: string, reportName: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      console.log('hello', reportName);
      const replaceSlashWithDash = date.replace(/-/g, '/');
      const id = getState().dfr.id;
      const projectId = getState().dfr.projectId;
      fetch(
        `api/DailyFieldReport/EditTitleAndDate?id=${id}&date=${date}&reportName=${reportName}&projectId=${projectId}`,
        { method: 'PUT' }
      )
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          console.log('dfr.ts editDateAndReportName()', resOk, data);
          if (resOk) {
            dispatch({
              type: 'EDIT_DATE_REPORTNAME',
              date: replaceSlashWithDash,
              reportName: reportName,
            });
          } else {
            dispatch({
              type: 'ERROR_EDIT_DATE_REPORTNAME',
              message: data.message,
            });
          }
        });
    },

  deleteRow:
    (entityId: number, type: number): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      fetch(`api/DailyFieldReport/Delete?entityId=${entityId}&type=${type}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk && data) {
            switch (type) {
              case 1: {
                //equipment
                const deleteE = getState()
                  .dfr.equipment.filter((f) => f.id != entityId)
                  .slice();
                dispatch({
                  type: 'DELETE_EQUIPMENT_DFR_ROW',
                  equipment: deleteE,
                });
                break;
              }
              case 2: {
                //personnel
                const deleteP = getState()
                  .dfr.personnel.filter((f) => f.id !== entityId)
                  .slice();
                dispatch({
                  type: 'DELETE_PERSONNEL_DFR_ROW',
                  personnel: deleteP,
                });
                break;
              }
              case 3: {
                //photo
                const deletePhoto = getState()
                  .dfr.photos.filter((f) => f.id != entityId)
                  .slice();
                dispatch({ type: 'DELETE_PHOTO_DFR_ROW', photos: deletePhoto });
                break;
              }
              case 4: {
                // event
                const deleteEvent = getState()
                  .dfr.event.filter((f) => f.id != entityId)
                  .slice();
                dispatch({ type: 'DELETE_EVENT_DFR_ROW', event: deleteEvent });
                break;
              }
            }
          } else {
            // error message
          }
        });
    },
  getTemplates: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'REQUEST_TEMPLATES' });
  },
  clear: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'CLEAR_DFR' });
  },
  clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'ERROR', message: '' });
  },
};

const unloadedState: Store = {
  dfrs: [],
  unfilteredDfrs: [],
  projectNumber: '',
  projectId: 0,
  latitude: 0,
  longitude: 0,
  pmApproved: false,
  generalSuperApproved: false,
  creatorApproved: false,
  isLocked: false,
  isSubmitted: false,
  date: '',
  temperaturesTaken: false,
  covidPoliciesObserved: false,
  hasNoIncident: false,
  covidComments: '',
  projectName: '',
  userId: '',
  userFullName: '',
  approverName: '',
  reportName: '',
  event: [],
  isBadWeather: false,
  visitors: '',
  weather: {} as Weather,
  comment: '',
  formalDescription: '',
  equipment: [],
  personnel: [],
  photos: [],
  isLoading: false,
  isSaving: false,
  id: 0,
  pmId: '',
  generalSuperId: '',
  creatorId: '',
  isExternal: false,
  externalFile: null,
  kpiEntries: [],
  templates: [],
  currentTemplateId: null,
  currentTemplateUpdate: null,
  dailyFieldReportTemplateId: undefined,
};

//@ts-ignore
export const reducer: Reducer<Store> = (
  state: Store,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'REQUEST_DFRS':
      return { ...state, isLoading: true, message: '' };
    case 'RECEIVE_DFRS':
      return {
        ...state,
        dfrs: action.dfrs,
        unfilteredDfrs: action.unfilteredDfrs,
        projectId: action.projectId,
        pmId: action.pmId,
        isLoading: false,
      };
    case 'ADD_DFR':
      return {
        ...state,
        dfrs: action.dfrs,
        unfilteredDfrs: action.unfilteredDfrs,
        date: action.date,
        isSaving: false,
      };
    case 'RECEIVE_SELECTED_DFR':
      return {
        ...state,
        date: action.date || state.date,
        reportName: action.reportName || state.reportName,
        projectName: action.projectName || state.projectName,
        userId: action.userId || state.userId,
        userFullName: action.userFullName || state.userFullName,
        approverName: action.approverName || state.approverName,
        weather: action.weather || state.weather,
        comment: action.comment || state.comment,
        formalDescription: action.formalDescription || state.formalDescription,
        temperaturesTaken: action.temperaturesTaken || state.temperaturesTaken,
        covidPoliciesObserved:
          action.covidPoliciesObserved || state.covidPoliciesObserved,
        hasNoIncident: action.hasNoIncident || state.hasNoIncident,
        covidComments: action.covidComments || state.covidComments,
        equipment: action.equipment || state.equipment,
        event: action.event || state.event,
        personnel: action.personnel || state.personnel,
        photos: action.photos || state.photos,
        visitors: action.visitors || state.visitors,
        isLoading: false,
        isLocked:
          action.isLocked !== undefined ? action.isLocked : state.isLocked,
        pmApproved:
          action.pmApproved !== undefined
            ? action.pmApproved
            : state.pmApproved,
        generalSuperApproved:
          action.generalSuperApproved !== undefined
            ? action.generalSuperApproved
            : state.generalSuperApproved,
        creatorApproved:
          action.creatorApproved !== undefined
            ? action.creatorApproved
            : state.creatorApproved,
        isSubmitted:
          action.isSubmitted !== undefined
            ? action.isSubmitted
            : state.isSubmitted,
        id: action.id || state.id,
        projectId: action.projectId || state.projectId,
        projectNumber: action.projectNumber || state.projectNumber,
        latitude: action.latitude || state.latitude,
        longitude: action.longitude || state.longitude,
        pmId: action.pmId || state.pmId,
        generalSuperId: action.generalSuperId || state.generalSuperId,
        creatorId: action.creatorId || state.creatorId,
        previousId: action.previousId,
        nextId: action.nextId,
        isExternal:
          action.isExternal !== undefined
            ? action.isExternal
            : state.isExternal,
        externalFile: action.externalFile || state.externalFile,
        kpiEntries: action.kpiEntries,
        dailyFieldReportTemplateId:
          action.dailyFieldReportTemplateId || state.dailyFieldReportTemplateId,
      };
    case 'ADD_PERSONNEL_DFR':
      return {
        ...state,
        personnel: action.personnel,
        isSaving: false,
      };
    case 'ADD_EQUIPMENT_DFR':
      return {
        ...state,
        equipment: action.equipment,
        isSaving: false,
      };
    case 'UPDATE_ROW':
      return {
        ...state,
        equipment: action.equipment || state.equipment,
        personnel: action.personnel || state.personnel,
        photos: action.photos || state.photos,
        event: action.event || state.event,
      };
    case 'DELETE_PERSONNEL_DFR_ROW':
      return {
        ...state,
        personnel: action.personnel,
        isSaving: false,
      };
    case 'DELETE_EQUIPMENT_DFR_ROW':
      return {
        ...state,
        equipment: action.equipment,
        isSaving: false,
      };
    case 'DELETE_EVENT_DFR_ROW':
      return {
        ...state,
        event: action.event,
        isSaving: false,
      };
    case 'RECEIVE_DFR_PHOTO':
      return {
        ...state,
        photos: action.photos,
        isLoading: false,
        message: '',
      };
    case 'DELETE_PHOTO_DFR_ROW':
      return {
        ...state,
        photos: action.photos,
        isSaving: false,
      };
    case 'SAVE':
      return {
        ...state,
        message: action.message,
        isSaving: false,
      };
    case 'SAVING_DFR':
      return {
        ...state,
        isSaving: true,
        message: undefined,
      };
    case 'ERROR':
      return {
        ...state,
        message: action.message,
        isLoading: false,
        isSaving: false,
      };
    case 'UPDATE_DFR':
      return {
        ...state,
        comment: action.comment || state.comment,
        formalDescription: action.formalDescription || state.formalDescription,
        isBadWeather: action.isBadWeather || state.isBadWeather,
        visitors: action.visitors || state.visitors,
        temperaturesTaken: action.temperaturesTaken || state.temperaturesTaken,
        covidPoliciesObserved:
          action.covidPoliciesObserved || state.covidPoliciesObserved,
        covidComments: action.covidComments || state.covidComments,
        isSaving: false,
      };
    case 'SUBMIT_DFR':
      return {
        ...state,
        isSubmitted: action.isSubmitted,
        message: action.message,
        weather: action.weather,
        isLoading: false,
      };
    case 'ADDING_LOADING_DFR':
      return {
        ...state,
        isLoading: true,
      };
    case 'ADD_EVENT_DFR':
      return {
        ...state,
        event: action.event,
        isSaving: false,
      };
    case 'APPROVE_DFR':
      return {
        ...state,
        isLoading: action.isLoading,
        message: action.message,
        isLocked: action.isLocked,
      };
    case 'CLEAR_DFR':
      return unloadedState;
    case 'REJECT_DFR':
      return {
        ...state,
        isSubmitted: false,
        isLoading: false,
        message: action.message,
      };
    case 'EDIT_DATE_REPORTNAME':
      return {
        ...state,
        reportName: action.reportName,
        date: action.date,
      };
    case 'ERROR_EDIT_DATE_REPORTNAME':
      return {
        ...state,
        message: action.message,
        isLoading: false,
      };
    case 'TOGGLE_LOADING':
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case 'TOGGLE_SAVING':
      return { ...state, isSaving: !state.isSaving };
    case 'UPDATE_KPI':
      return { ...state, kpiEntries: action.entries };
    case 'REQUEST_TEMPLATES':
      return { ...state };
    case 'SET_TEMPLATES':
      return { ...state, templates: action.templates };
    case 'ADD_TEMPLATE':
      return { ...state };
    case 'EDIT_TEMPLATE':
      return {
        ...state,
      };
    case 'COPY_TEMPLATE':
      return { ...state };
    case 'SET_TEMPLATE_ID':
      return { ...state, currentTemplateId: action.id };
    case 'SET_TEMPLATE_UPDATE':
      return { ...state, currentTemplateUpdate: action.update };
    case 'CLEAR_DFR_LIST':
      return unloadedState;
    default: {
      const exhaustiveCheck: never = action;
    }
  }
  return state || unloadedState;
};
