import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import * as Dfr from '../dfr';

const dfrState = (state: ApplicationState): Dfr.Store => state.dfr;

export const dfrTemplatesSelector = createSelector(
  dfrState,
  (state) => state.templates
);

export const dfrIdSelector = createSelector(dfrState, (state) => state.id);

export const dfrPhotosSelector = createSelector(
  dfrState,
  (state) => state.photos
);

export const dfrIsLockedSelector = createSelector(
  dfrState,
  (state) => state.isLocked
);

export const dfrPMIdSelector = createSelector(dfrState, (state) => state.pmId);

export const dfrIsSubmittedSelector = createSelector(
  dfrState,
  (state) => state.isSubmitted
);

export const dfrIsLoadingSelector = createSelector(
  dfrState,
  (state) => state.isLoading
);

export const currentTemplateIdSelector = createSelector(
  dfrState,
  (state) => state.currentTemplateId
);

export const currentTemplateSelector = createSelector(
  dfrTemplatesSelector,
  currentTemplateIdSelector,
  (templates, currentId) => templates.find((x) => x.id == currentId)
);
