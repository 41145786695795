import * as React from 'react';
import { ListItem, DfrPhoto } from '../interfaces/interfaces';
import { Loader } from '../components/Loader';
import Modal from '../components/Modal';
import PhotoCarousel from '../components/PhotoCarousel';

interface State {
  reports: ListItem[]
  photos: DfrPhoto[]
  carouselIndex?: number
  isLoading: boolean
}

interface Props {
  projectId: number,
  close: any
}

export default class DfrSearcher extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      reports: [],
      photos: [],
      isLoading: false
    }
  }


  _search = () => {
    const searchText = document.getElementById('dfr_search_text') as HTMLInputElement
    if (searchText.value.length < 2) {
      alert("Please enter at least three characters before searching")
    }
    else {
      this._callSearch(searchText.value);

    }
  }

  _callSearch = (value: string) => {
    this.setState({
      isLoading: true
    })
    fetch(`api/DailyFieldReport/Search?projectId=${this.props.projectId}&filterText=${value}`)
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({
            reports: data.reports,
            photos: data.photos,
            isLoading: false
          })
        }
        else {
          alert("Error retrieving search")
        }
      })
  }

  imageOnClick = (idx?: number) => {
    this.setState({ carouselIndex: idx })
  }

  render() {
    const { carouselIndex, photos, isLoading } = this.state;
    const photoPaths = photos.map(x => {
      return x.filePath;
      const sfp = x.filePath.split("\\content\\");
      return "files\\" + sfp[sfp.length - 1];
    })

    return (
      <Modal id='dfr_searcher'>
        <Loader loading={isLoading} />
        <div className='modal-header'>
          <h4>Field Report Search</h4>
        </div>
        <div className='modal-body'>
          <div className='search-line'>
            <input className='form-control'
              id='dfr_search_text'
              placeholder='Enter search term'
            />
            <div className='btn btn-sm btn-info' style={{ lineHeight: '1.6' }}
              onClick={this._search}
            >Search</div>
          </div>
          <br />
          <div>
            {photos.length ?
              <div className='photo-section'>
                <h5>Photos</h5>
                <hr />
                {this.renderPhotoResults()}
              </div>
              : []
            }
          </div>
          <br />
          {this.state.reports.length ?
            <div className='report-section'>
              <h5>Reports</h5>
              <hr />
              {this.renderReportResults()}
            </div>
            : []
          }
          {carouselIndex !== undefined &&
            <PhotoCarousel photos={photoPaths} startingIndex={carouselIndex} close={() => this.imageOnClick()} />
          }
        </div>
        <div className='modal-footer'>
          <span className='btn btn-sm btn-outline-secondary'
            onClick={this.props.close}>
            Close
          </span>
        </div>
      </Modal>
    )
  }

  private renderPhotoResults() {

    const photos = this.state.photos ?
      this.state.photos.map((x, i) => {

        return (
          <div className='photo-box' style={{height: '200px'}}>
            <img alt="" src={x.filePath} onClick={() => this.imageOnClick(i)} />
            <br />
            <a href={`/dfr/${x.dfrId}#photos`} target="_blank">{x.description}</a>
          </div>
        )
      })
      : []

    return <div>{photos}</div>
  }

  private renderReportResults() {

    const reports = this.state.reports ?
      this.state.reports.map(x => {
        return <a href={`/dfr/${x.id}`} target="_blank">{x.value}</a>
      })
      : []

    return <div>{reports}</div>
  }
}