import * as React from 'react';
import { hasRole, KnownRoles } from '../../auth/auth';

import Modal from '../../components/Modal';
import { UserInfo } from '../../interfaces/interfaces';
import { ClientInfoForm } from './ClientInfoForm';

interface Props {
    id: number,
    clientName: string,
    user?: UserInfo
}

export const ClientInfoModal = (props: Props) => {
    const { id, clientName, user } = props;

    const [showModal, setShowModal] = React.useState(false);

    const canEdit = hasRole(user, [KnownRoles.Admin, KnownRoles.CSGPM, KnownRoles.CoreSuperUser]);
    //const canEdit = hasRole(user, [KnownRoles.ClientPM]);

    return (
        <div className='client-info-link'>
            <span onClick={() => setShowModal(true)}>{clientName}</span>
            {showModal &&
                <Modal modalClass='client-info-modal' dialogStyle={{maxWidth: '700px'}}>
                    <div className='modal-body'>
                        <ClientInfoForm id={id} canEdit={canEdit} />
                    </div>
                    <div className='modal-footer'>
                        <span className='btn btn-sm btn-outline-secondary' onClick={() => setShowModal(false)}>Close</span>
                    </div>
                </Modal>
            }
        </div>
    )    
}