import * as React from 'react';
import { useSelector } from 'react-redux';
import { hasRole, KnownRoles } from '../auth/auth';
import { ListItem } from '../interfaces/interfaces';
import { StandardGridLoader } from '../loaders/StandardGridLoader';
import { ApplicationState } from '../store/index';
import FilterableSelect from './FilterableSelect';
import { Modal2 as Modal } from './Modal';

interface Props {
    relPath: string;
    close: () => void,
}

export interface FolderUserPermission {
    folderPath: string,
    folderName: string,
    userId: string,
    userName: string,
    permissionsLevel: FolderPermissionLevel,
    isFolderLocked: boolean
}

export enum FolderPermissionLevel {
    None,
    Read,
    Share,
    Write
}

export const FolderPermissionsModal = (props: Props) => {

    const [permissions, setPermissions] = React.useState([] as FolderUserPermission[]);
    const [loading, setLoading] = React.useState(true);
    const [isLocked, setIsLocked] = React.useState(true);
    const [editRights, setEditRights] = React.useState(null as { userId: string, level: FolderPermissionLevel } | null)
    const globalStore = useSelector((s: ApplicationState) => s.global);
    const userStore = useSelector((s: ApplicationState) => s.user);

    const _addUser = () => {
        const select = document.querySelector('#add-user-permission input[name="add-user-permission"]') as HTMLInputElement;
        if (select.value === '') alert('Please select a user to add');
        else {
            fetch(`api/FileExplorer/AddUserPermission?subPath=${props.relPath}&userId=${select.value}`, { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setPermissions(data);
                        setLoading(false);
                    }
                })
        }
    }

    const _getPermissions = () => {
        fetch(`api/FileExplorer/GetFolderUsers?subPath=${props.relPath}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setPermissions(data.users);
                    setIsLocked(data.isLocked);
                    setLoading(false);
                } else {
                    alert(data.message);
                }
            })
    }

    const _removeUser = (userId: string) => {
        fetch(`api/FileExplorer/DeleteUserPermission?subPath=${props.relPath}&userId=${userId}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setPermissions(permissions.filter(x => x.userId !== userId));
                    setLoading(false);
                } else {
                    alert(data.message);
                }
            })
    }

    const _toggleWriteAccess = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userId = e.currentTarget.getAttribute('data-user-id')
        let level = parseInt(e.currentTarget.getAttribute('data-permission-level') || '1');
        if (!e.currentTarget.checked) --level;
        fetch(`api/FileExplorer/EditUserPermission?subPath=${props.relPath}&userId=${userId}&level=${level}&cascade=${false}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    setPermissions(data);
                    setEditRights(null);
                    setLoading(false);
                } else {
                    alert(data.message);
                    setEditRights(null);
                }
            })
    }

    const folderName = props.relPath.split('/')[props.relPath.split('/').length - 1];

    //@ts-ignore
    const permissionIdx = userStore.user && permissions.length ? permissions.findIndex(p => p.userId === userStore.user.userId) : -1;
    const currentUserPermission = permissionIdx !== -1 ? permissions[permissionIdx].permissionsLevel : FolderPermissionLevel.None
    const hasWritePermissions = userStore.user ?
        !isLocked && (hasRole(userStore.user, [KnownRoles.Admin, KnownRoles.CoreSuperUser]) || currentUserPermission === FolderPermissionLevel.Write) :
        false;
    const hasSharePermissions = currentUserPermission >= FolderPermissionLevel.Share || hasRole(userStore.user, [KnownRoles.Admin, KnownRoles.CoreSuperUser]);

    const remainingUsers = permissions.length ?
        globalStore.users.filter(x => permissions.map(p => p.userId).indexOf(x.userId) == -1) : globalStore.users;

    React.useEffect(() => {
        _getPermissions();
    }, [])

    return (
        <Modal>
            <div className='modal-header'>
                <h5>Permissions for {folderName}</h5>
            </div>
            <div className='modal-body' style={{ maxHeight: '50vh', overflowY: 'auto' }}>
                {/*{editRights !== null ?*/}
                {/*    <Modal modalClass='triple-top-margin half-width'>*/}
                {/*        <div className='modal-body'><span>Do you want to cascade these permissions? This will apply the selected permission change to all folders beneath the current folder.</span></div>*/}
                {/*        <div className='modal-footer'>*/}
                {/*            <button className='btn btn-sm btn-outline-primary' onClick={() => _toggleWriteAccess(true)}>Yes</button>*/}
                {/*            <button className='btn btn-sm btn-outline-danger' onClick={() => _toggleWriteAccess(false)}>No</button>*/}
                {/*            <button className='btn btn-sm btn-outline-secondary' onClick={() => setEditRights(null)}>Cancel</button>*/}
                {/*        </div>*/}
                {/*    </Modal>*/}
                {/*    : []*/}
                {/*}*/}
                <div className='sv-grid'>
                    <div className='sv-grid-header'>
                        <div className='my-col-10'><b>User Name</b></div>
                        <div className='my-col-3'><b>Read</b></div>
                        <div className='my-col-3'><b>Share</b></div>
                        <div className='my-col-4'><b>Write</b></div>
                    </div>
                    <div className='sv-grid-body'>
                        {permissions.map(x => {
                            const canWrite = x.permissionsLevel === FolderPermissionLevel.Write;
                            const canShare = x.permissionsLevel >= FolderPermissionLevel.Share;
                            return (
                                <div key={x.userId} className='sv-grid-line'>
                                    <div className='my-col-10'>{x.userName}</div>
                                    <div className='my-col-3'><span className='fas fa-check'></span></div>
                                    <div className='my-col-3'>
                                        {hasSharePermissions ?
                                            <input type='checkbox' defaultChecked={canShare} data-user-id={x.userId} data-permission-level={FolderPermissionLevel.Share} onChange={_toggleWriteAccess} /> :
                                            (canShare) ? <span className='fas fa-check'></span> : <span></span>
                                        }
                                    </div>
                                    <div className='my-col-3'>
                                        {hasWritePermissions ?
                                            <input type='checkbox' defaultChecked={canWrite} data-user-id={x.userId} data-permission-level={FolderPermissionLevel.Write} onChange={_toggleWriteAccess} /> :
                                            (canWrite && !x.isFolderLocked) ? <span className='fas fa-check'></span> : <span></span>
                                        }
                                    </div>
                                    <div className='my-col-1'>
                                        {hasWritePermissions ?
                                            <span className='fas fa-times' title='Remove permissions for this user' onClick={() => _removeUser(x.userId)}></span>
                                            : []
                                        }
                                    </div>
                                </div>
                            )
                        })}
                        {hasWritePermissions ?
                            <div className='sv-grid-line'>
                                <div className='my-col-10'>
                                    <FilterableSelect key={remainingUsers.length} id='add-user-permission' items={remainingUsers.map(x => new ListItem(x.userId, x.fullName))} />
                                </div>
                                <div className='my-col-10'>
                                    <button className='btn btn-sm btn-blue' onClick={_addUser}>Add</button>
                                </div>
                            </div>
                            : []
                        }
                    </div>
                </div>
            </div>
            <div className='modal-footer'>
                <div className='btn btn-sm btn-outline-secondary' onClick={props.close}>Close</div>
            </div>
        </Modal>
    )
}