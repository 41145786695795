import React, { useState } from "react";
import { SliderCheckbox } from "src/components/SliderCheckbox";
import { formatDateTime } from "src/helpers/formatters";
import {
	ImportConfig,
	ImportSource,
	ListItem,
	MilestoneImportMap,
	ProjectImportMap,
	RunFrequency,
} from "src/interfaces/interfaces";
import * as cx from "classnames";
import {
	actionCreators,
	KnownAction as ImportKnownAction,
} from "../../store/imports";
import styled from "styled-components";
import { JoinedList } from "src/components/JoinedList";
import { AppThunkAction } from "src/store";

interface ImportConfigItemProps {
	config: ImportConfig;
	toggleSwitch: (id: number, field: string, onOff: boolean) => void;
	toggleEditConfigId: (id?: number) => void;
	renderProjectMapLine: (map: ProjectImportMap) => JSX.Element;
	renderMilestoneMaps: (maps: MilestoneImportMap[]) => JSX.Element | never[];
	runImport: (
		id: number,
		userIds: string[]
	) => AppThunkAction<ImportKnownAction>;
	deleteConfig: (id: number) => AppThunkAction<ImportKnownAction>;
	toggleExpandMaps: (id: number) => AppThunkAction<ImportKnownAction>;
	users: ListItem[];
}

const JoinedListDiv = styled.div({ marginTop: "10px", marginBottom: "50px" });

export const ImportConfigItem: React.FC<ImportConfigItemProps> = ({
	config,
	toggleSwitch,
	toggleEditConfigId,
	renderProjectMapLine,
	renderMilestoneMaps,
	runImport,
	deleteConfig,
	toggleExpandMaps,
	users,
}) => {
	const [projectTeamMembers, setProjectTeamMembers] = useState<string[]>([]);

	let importInfo: JSX.Element;

	const displayFriendlyFrequency = (config: ImportConfig) => {
		if (config.frequencyType === RunFrequency.daily) return "Daily";
		else if (config.frequencyType === RunFrequency.weekly) return "Weekly";
		else return "Hourly";
	};

	switch (config.source) {
		case ImportSource.Google:
			importInfo = (
				<div className="info google-info">
					<div className="col-sm-2">{config.companyName}</div>
					<div className="col-sm-3 truncate">{config.sheetId}</div>
					<div className="col-sm-2">{displayFriendlyFrequency(config)}</div>
					<div className="col-sm-2">
						{formatDateTime(config.lastRunDateTime)}
					</div>
					<div className="col-sm-1">
						<SliderCheckbox
							selected={config.active}
							fieldName="active"
							dataId={config.id}
							onChange={() => toggleSwitch(config.id, "active", config.active)}
						/>
						{/*<label className='switch' key={activeKey}>*/}
						{/*    <input type='checkbox' defaultChecked={config.active} />*/}
						{/*    <span className='slider round' onClick={() => this._toggleSwitch(config.id, 'active', config.active)}></span>*/}
						{/*</label>*/}
					</div>
					<div className="col-sm-2 config-icons">
						<span
							className="fas fa-play"
							onClick={() => runImport(config.id, projectTeamMembers)}
							title="Run this import job"
						></span>
						<span
							className="fas fa-pencil-alt"
							onClick={() => toggleEditConfigId(config.id)}
							title="Edit the configuration for this import"
						></span>
						<span
							className="fas fa-trash-alt"
							title="Delete this import configuration"
							onClick={() => deleteConfig(config.id)}
						></span>
						<span
							className={cx(
								"fas",
								config.expandProjectMap ? "fa-chevron-down" : "fa-chevron-up"
							)}
							title="Toggle import mappings"
							onClick={() => toggleExpandMaps(config.id)}
						></span>
					</div>
				</div>
			);
			break;
		default:
			importInfo = <div></div>;
			break;
	}

	const projectMapLine = config.expandProjectMap
			? renderProjectMapLine(config.projectMap)
			: [],
		milestoneMaps = config.expandProjectMap
			? renderMilestoneMaps(config.milestoneMaps)
			: [];

	return (
		<div key={config.id} className="import-config-line">
			{importInfo}
			{projectMapLine}
			{milestoneMaps}
			{config.expandProjectMap && (
				<JoinedListDiv className={"form-block col-8"}>
					<JoinedList
						title="Project Team Members"
						availableItems={users}
						selectedItems={{} as ListItem[]}
						onChange={(x) =>
							setProjectTeamMembers(x.map((y) => y.id.toString()))
						}
					/>
				</JoinedListDiv>
			)}
		</div>
	);
};
