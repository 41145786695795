import React, { useMemo, useRef, useState } from 'react';
import { Modal2 } from 'src/components/Modal';
import { IssueStatus } from 'src/interfaces/enums';
import {
  IssueDisposition,
  IssueVm,
  ListItem,
  NCRPostModel,
  UpdateObject,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';
import { ConnectedIssueDetail } from './ConnectedIssueDetail';
import { IssueNCRModal } from './IssueNCRModal';

export const ModalBodyDiv = styled.div({
  maxHeight: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '& .form-group:not(.joined-list)': {
    display: 'flex',
    width: '100%',
    lineHeight: 1,
  },
  '& .inline-label': { width: '150px', lineHeight: 2 },
  '& input, & select, & textarea': {
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select': {
    width: '100%',
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select input': {
    maxWidth: '100%',
  },
  '& .joined-list': {
    height: '100%',
  },
});

const StyledInput = styled.input({
  height: 'unset !important',
  width: '350px',
  marginBottom: '16px',
});

const StyledH4 = styled.h4({
  marginBottom: '3px',
});

const StyledDiv = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

interface IssueModalProps {
  issue: IssueVm;
  contributingFactors: ListItem[];
  dispositions: IssueDisposition[];
  onIssueUpdate: (update: UpdateObject) => void;
  onClose: () => void;
  onSendReport: (issueId: number, ncr: NCRPostModel) => void;
}

export const IssueDetailModal = ({
  onClose,
  onIssueUpdate,
  issue,
  contributingFactors,
  dispositions,
  onSendReport,
}: IssueModalProps) => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [showNCRModal, setShowNCRModal] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const availableDispositions = useMemo(() => {
    return dispositions.filter((x) => x.issueTypeId == issue.typeId);
  }, [issue, dispositions]);

  return (
    <>
      <Modal2 dialogStyle={{ width: '800px' }}>
        {showNCRModal && (
          <IssueNCRModal
            issue={issue}
            onModalClose={() => setShowNCRModal(false)}
            contributingFactors={contributingFactors}
            dispositions={availableDispositions}
            onSendReport={onSendReport}
          ></IssueNCRModal>
        )}
        <div className="modal-header">
          {isEditingTitle ? (
            <>
              <StyledInput
                ref={inputRef}
                defaultValue={issue.title}
                className="form-control"
              />
              <div>
                <button
                  className="btn btn-sm btn-blue"
                  onClick={(e) => {
                    onIssueUpdate({
                      fieldName: 'title',
                      value: inputRef.current?.value ?? '',
                    });
                    setIsEditingTitle(false);
                  }}
                  style={{
                    marginRight: '8px',
                    marginLeft: '8px',
                  }}
                >
                  Save
                </button>
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => setIsEditingTitle(false)}
                >
                  Cancel
                </button>
              </div>
            </>
          ) : (
            <>
              <StyledDiv>
                <StyledH4>{issue.title}</StyledH4>
                {issue.canEdit && (
                  <span
                    style={{
                      fontSize: 'unset',
                      position: 'unset',
                      marginLeft: '10px',
                    }}
                    className="fas fa-edit"
                    onClick={() => setIsEditingTitle(true)}
                  ></span>
                )}
              </StyledDiv>
              {issue.status > IssueStatus.Draft && (
                <button
                  className="btn btn-sm btn-outline-secondary"
                  onClick={() => setShowNCRModal(true)}
                >
                  Generate NCR
                </button>
              )}
            </>
          )}
        </div>
        <ModalBodyDiv className="modal-body custom-scrollbar">
          <ConnectedIssueDetail />
        </ModalBodyDiv>
        <div className="modal-footer">
          <span className="btn btn-sm btn-outline-secondary" onClick={onClose}>
            Close
          </span>
        </div>
      </Modal2>
    </>
  );
};
