import * as React from 'react';
import { SketchPicker } from 'react-color'
import { MessageBox } from '../../components/MessageBox';
import { RecordNotes } from '../../components/RecordNotes';

import { ClientVm } from '../../interfaces/interfaces';
import GeneralConfigForm from './GeneralConfigForm';

interface Props {
    id: number,
    canEdit: boolean
}

export const ClientInfoForm = (props: Props) => {
    const { id, canEdit } = props;        

    const [info, setInfo] = React.useState({} as ClientVm);
    const [showPicker, setShowPicker] = React.useState(false);
    const [message, setMessage] = React.useState('');

    React.useEffect(() => {
        const getInfo = async () => {
            fetch(`api/Client/Detail?id=${id}`)
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) setInfo(data);
                    else setMessage(data.message);
                })
        }
        getInfo();
    }, [])

    const _updateColor = (color) => {        
        fetch(`api/Client/Color?id=${id}&hex=${encodeURIComponent(color.hex)}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setInfo({ ...info, color: color.hex })
                else alert('Error: ' + data.message)
            })
    }

    const _updateInfo = (e: React.FocusEvent<HTMLInputElement>) => {
        const prop = e.currentTarget.name,
            val = e.currentTarget.value;
        const model = JSON.parse(JSON.stringify(info)) as ClientVm;
        model[prop] = val;

        fetch(`api/Client/Edit?id=${id}`,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(model)
            }
        )
            .then(res => Promise.all([res.json()]))
            .then(([data]) => {
                //setInfo({ ...info, ...model });
                setMessage(data.message)
            })
    }

    const _updateNotes = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        const body = {
            entityId: id,
            text: e.currentTarget.value
        };
        fetch(`api/Client/Notes`, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setInfo({ ...info, notes: data });                
                else setMessage(data.message);                
            })
    }

    const _toggleColorPicker = () => {
        if (canEdit) setShowPicker(!showPicker);
    }

    const { color, name, contactName, contactEmail, notes } = info;

    return (
        <div className='client-info-form'>
            <MessageBox message={message} clearMessage={() => setMessage('')} />
            <h5>{name}</h5>
            <hr/>
            <div className='form-group'>
                <div className='inline-label'>Contact Name</div>
                <input className='form-control' name='contactName' defaultValue={contactName} disabled={!canEdit} onBlur={_updateInfo} />
            </div>
            <div className='form-group'>
                <div className='inline-label'>Contact Email</div>
                <input className='form-control' name='contactEmail' defaultValue={contactEmail} disabled={!canEdit} onBlur={_updateInfo} />
            </div>
            <div className='form-group'>
                <div className='inline-label' style={{ position: 'relative' }}>Color<span className='fas fa-question' title='Used for styles such as markers on the project map'></span></div>
                <div className='color-block' style={{ backgroundColor: color || '#005a78' }} onClick={_toggleColorPicker}></div>
                {showPicker && <SketchPicker color={color || '005a78'} onChangeComplete={_updateColor} />}
            </div>
            <RecordNotes notes={notes} label='Notes' update={_updateNotes} canEdit={canEdit} />    
        </div>
    )
}