import * as React from 'react'
import * as AttachmentStore from '../store/attachments'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import {FileExplorer} from './FileExplorer';
import Modal from './Modal';

interface ViewerProps {
    //type: number, // 1 for photos, 2 for documents
    pId: number,
    milestoneId: number,
    title: string
    close: () => any,
    allowUpload?: boolean
}

interface State {
    folderPath: string,
    selectedIds: number[],
    selectedAll: boolean
}

type Props =
    ViewerProps
    & AttachmentStore.Store
    & typeof AttachmentStore.actionCreators

class MilestoneAttachmentViewer extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            folderPath: '',
            selectedIds: [],
            selectedAll: false
        }
    }

    componentDidMount() {
        this._getMilestoneFolderPath(this.props.milestoneId);
    }

    componentWillUnmount() {
        this.props.clear()
    }

    _getMilestoneFolderPath = (entryId: number) => {
        fetch(`api/Milestone/FolderPath?entryId=${entryId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ folderPath: data.path })
            })
    }

    private close = () => {
        this.props.close();
    }

    _adjustSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photoId = e.currentTarget.value;
        let newIds = this.state.selectedIds.slice();
        if (e.currentTarget.checked) newIds.push(parseInt(photoId))
        else newIds = newIds.filter(x => x !== parseInt(photoId))

        this.setState({ selectedIds: newIds });
    }

    public render() {
        const { pId, milestoneId } = this.props;

        const title = `${this.props.title} Files`;
       
        const explorerActionPath = milestoneId ? 'api/FileExplorer/MilestoneDir' : 'api/FileExplorer/ProjectDir',
            explorerParams = { pId: pId, milestoneId: milestoneId },
            breadCrumbStartLevel = 4

        return (
            <Modal modalClass='attachmentViewer' dialogClass='attachmentViewer'>
                <div className="modal-header">
                    <h4 className="modal-title">{title}</h4>
                </div>
                <div className='modal-body viewer-body' style={{ 'minHeight': '10vh', 'maxHeight': '50vh' }}>
                    <FileExplorer                        
                        startingDir={this.state.folderPath}
                        breadCrumbStartLevel={breadCrumbStartLevel}
                        canSubscribeUsers={false}
                        params={explorerParams}
                    />
                </div>
                <div className='modal-footer'>
                    <span className="btn btn-sm btn-outline-secondary btn-outline-secondary" onClick={this.close.bind(this)}>Close</span>
                </div>
                {this.props.warningMessage && this.renderWarningModal()}
            </Modal>            
        )
    }

    private renderWarningModal() {
        return (
            <Modal style={{ marginTop: '10vh' }}>
                <div className='modal-header'>
                    <h4>Warning</h4>
                </div>
                <div className='modal-body'>
                    <div dangerouslySetInnerHTML={{ __html: this.props.warningMessage || "<p></p>" }} />
                </div>
                <div className='modal-footer'>
                    <span className="btn btn-sm btn-outline-secondary btn-outline-secondary" onClick={this.props.clearWarning}>Close</span>
                </div>
            </Modal>
        )
    }
}

export default connect<AttachmentStore.Store, typeof AttachmentStore.actionCreators, ViewerProps>(
    //@ts-ignore
    (state: ApplicationState) => state.attachments,
    AttachmentStore.actionCreators
)(MilestoneAttachmentViewer)