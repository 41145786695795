import * as React from 'react';
import cx from 'classnames'
import { useGlobalMessage } from '../../helpers/globalDispatches';
import { GlobalMessageBox } from '../../components/MessageBox';
import Modal from '../../components/Modal';

interface Props { }

interface State {
    fields: VendorComplianceField[]
    editedId?: number,
    showOptionModal: boolean
}

export interface VendorComplianceField {
    id: number,
    fieldName: string,
    type: ComplianceFieldType,
    required: boolean,
    options: VendorComplianceFieldOption[]
}

export enum ComplianceFieldType {
    Checkbox,
    Date,
    Option
}

export interface VendorComplianceFieldOption {
    id: number,
    vendorComplianceFieldId: number,
    optionName: string
}

export interface VendorComplianceConfigApi {
    addOption: (opt: VendorComplianceFieldOption) => void,
    editOption: (id: number, newName: string) => void,
    deleteOption: (id: number) => void,
    closeOptionModal: () => void,
}

export const VendorComplianceConfigContext = React.createContext({} as VendorComplianceConfigApi)

export const VendorComplianceConfig = (props: Props) => {

    const [state, setState] = React.useState({ fields: [] as VendorComplianceField[], editedId: undefined, showOptionModal: false } as State)
    const updateMessage = useGlobalMessage();

    React.useEffect(() => {
        _getFields()
    }, []);

    const _getFields = () => {
        fetch(`api/GlobalConfig/VendorCompliance`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setState({ ...state, fields: data })
                else updateMessage(data.message);
            })
    }

    const _addField = () => {
        const input = document.getElementById('newFieldName') as HTMLInputElement;
        const type = document.getElementById('newFieldType') as HTMLSelectElement;
        const required = document.getElementById('newFieldRequired') as HTMLInputElement;
        if (input && type) {
            fetch(`api/GlobalConfig/VendorCompliance?name=${input.value}&type=${type.value}&required=${required.checked}`,
                { method: 'POST' })
                .then(res => Promise.all([res.ok, res.json()]))
                .then(([resOk, data]) => {
                    if (resOk) {
                        setState({ ...state, fields: data, editedId: undefined })
                        input.value = '';
                        type.value = '-1'
                    }
                    else {
                        updateMessage(data.message)
                    }
                })
        }
    }

    const _editField = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        const field = e.currentTarget.name,
            val = e.currentTarget.type ==='checkbox' ? e.currentTarget.checked : e.currentTarget.value;

        fetch(`api/GlobalConfig/VendorCompliance?id=${state.editedId}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ fieldName: field, value: val }) })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) {
                    // TODO: replace the edited line with the returned data
                    setState({ ...state, fields: data })
                    //setState({ ...state, fields: data, editedId: undefined })
                }
                else updateMessage(data.message)
            })
    }

    const _removeField = (id: number | string) => {
        fetch(`api/GlobalConfig/VendorCompliance?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setState({ ...state, fields: data })
                else updateMessage(data.message)
            })
    }

    const _addFieldOption = (opt: VendorComplianceFieldOption) => {
        fetch(`api/GlobalConfig/AddFieldOption`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(opt) })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setState({ ...state, fields: data })
                else updateMessage(data.message)
            })
    }

    const _editFieldOption = (id: number, newName: string) => {
        fetch(`api/GlobalConfig/EditFieldOption?id=${id}`, { method: 'PUT', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ id: id, optionName: newName }) })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setState({ ...state, fields: data })
                else updateMessage(data.message)
            })
    }

    const _deleteFieldOption = (id: number) => {
        fetch(`api/GlobalConfig/DeleteFieldOption?id=${id}`, { method: 'DELETE' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) setState({ ...state, fields: data })
                else updateMessage(data.message)
            })
    }

    const api = {
        addOption: _addFieldOption,
        editOption: _editFieldOption,
        deleteOption: _deleteFieldOption,
        closeOptionModal: () => setState({ ...state, showOptionModal: false })
    } as VendorComplianceConfigApi


    const { editedId, fields } = state
    const editField = editedId ? fields.find(x => x.id === editedId) : null;

    const roles = fields && fields.length ?
        fields.map(x => {
            const editing = x.id == editedId
            return (
                <div key={`${x.fieldName}_${x.id}`} className={cx('config-item-line', editing && 'editing')} data-id={x.id} style={{ display: 'flex' }}>
                    <div style={{ width: '50%' }}>{editing ? <input className='form-control' name='fieldName' defaultValue={x.fieldName} onBlur={_editField} /> : <span>{x.fieldName}</span>}</div>
                    <div className='col-sm-2'>{complianceTypeToText(x.type)}</div>
                    <div className='col-sm-4'>{editing ? <span>Required <input type='checkbox' name='required' onChange={_editField} defaultChecked={x.required} style={{width: '13px'}}/></span> : x.required && <span>Required</span>}</div>
                    {(editing && x.type === ComplianceFieldType.Option) ? <span className={cx('fas fa-list')}
                        onClick={() => setState({ ...state, showOptionModal: true })}
                        title='Edit field options'
                    ></span> : []}
                    {editing ?
                        <span className='fas fa-check status-edit'
                            title='Save changes'
                            onClick={() => setState({ ...state, editedId: undefined })}
                        ></span> :
                        <span className='fas fa-edit status-edit'
                            title='Edit field'
                            onClick={() => setState({ ...state, editedId: x.id })}
                        ></span>
                    }
                    <span className={cx('fas fa-times status-remove')}
                        onClick={() => _removeField(x.id)}
                    ></span>
                </div>
            )
        }) : []

return (
    <div className='config-form max-350'>
        <VendorComplianceConfigContext.Provider value={api}>
            <h4>Vendor Compliance Fields</h4>
            <GlobalMessageBox />
            <div className='config-flex'>
                <div className='config-new-item-form'>
                    <div className="form-header">
                        <h5 className="modal-title">New Field</h5>
                    </div>
                    <div className="form-body">
                        <div className='form-group'>
                            <div className='inline-label'>Field Name</div>
                            <input id='newFieldName' className="form-control" />
                        </div>
                        <div className='form-group'>
                            <div className='inline-label'>Type</div>
                            <select id='newFieldType' className='form-control'>
                                {/*<option className='form-control' value={-1}></option>*/}
                                <option className='form-control' value={ComplianceFieldType.Checkbox}>Checkbox</option>
                                <option className='form-control' value={ComplianceFieldType.Date}>Date</option>
                                <option className='form-control' value={ComplianceFieldType.Option}>Option</option>
                            </select>
                        </div>
                        <div className='form-group'>
                            <div className='inline-label'>Required</div>
                            <input id='newFieldRequired' type='checkbox' />
                        </div>
                        <div className='form-footer'>
                            <span className="btn btn-sm btn-blue" onClick={_addField}>Add</span>
                        </div>
                    </div>
                </div>
                <div className='config-item-list'>
                    <div className="form-header" style={{ marginBottom: '15px' }}>
                        <h5 className="modal-title">Existing Fields</h5>
                    </div>
                    <div className=''>
                        {roles}
                    </div>
                </div>
            </div>
            {(state.showOptionModal && editField) && <ComplianceFieldOptionsModal field={editField} />}
        </VendorComplianceConfigContext.Provider>
    </div>
)
}

const complianceTypeToText = (t: ComplianceFieldType) => {
    switch (t) {
        case ComplianceFieldType.Checkbox: return 'Checkbox';
        case ComplianceFieldType.Date: return 'Date';
        case ComplianceFieldType.Option: return 'Option';
    }
}

export const ComplianceFieldOptionsModal = (props: { field: VendorComplianceField }) => {
    const api = React.useContext(VendorComplianceConfigContext);

    const _add = () => {
        const input = document.getElementById('add-option-input') as HTMLInputElement;
        api.addOption({ vendorComplianceFieldId: props.field.id, optionName: input.value } as VendorComplianceFieldOption)
        input.value = '';
    }

    return (
        <Modal modalClass='field-option-modal'>
            <div className='modal-header'><h5>Edit <u>{props.field.fieldName}</u> Options</h5></div>
            <div className='modal-body' style={{ 'maxHeight': '50vh', 'overflowY': 'auto' }}>
                {
                    props.field.options.map((x, i) => {
                        return (
                            <div className='form-group' key={x.id} style={{ display: 'flex' }}>
                                <div className='inline-label'>{i + 1}.</div>
                                <input className='form-control' defaultValue={x.optionName} onBlur={(e) => api.editOption(x.id, e.currentTarget.value)} />
                                <span className='fas fa-times' title='Remove option' onClick={() => api.deleteOption(x.id)}></span>
                            </div>
                        )
                    })
                }
                <div className='form-group add-option' style={{ display: 'flex' }}>
                    <div className='inline-label'>{props.field.options.length + 1}.</div>
                    <input className='form-control' id='add-option-input' />
                    <button className='btn btn-sm btn-blue' onClick={_add}>Add</button>
                </div>
            </div>
            <div className='modal-footer'>
                <button className='btn btn-sm btn-outline-secondary' onClick={api.closeOptionModal}>Close</button>
            </div>
        </Modal>
    )
}

