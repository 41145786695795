export const sum = (a: number, b: number) => {
    return a + b;
}

export const getLuminance = (hex: string) => {

    const cleaned = hex.replace(/#/g, '');

    const r = parseInt(cleaned.substr(0, 2), 16), g = parseInt(cleaned.substr(2, 2), 16), b = parseInt(cleaned.substr(4, 2), 16)

    return (r * 0.299 + g * 0.587 + b * 0.114)
}

export const addDays = (date: Date, days: number) => {
    const utcOffset = new Date().getTimezoneOffset()

    if (date.getHours() + (utcOffset / 60) >= 21) ++days; // If after 5 eastern

    const result = new Date();
    result.setDate(date.getDate() + days);

    return result;
}

export const addWorkDays = (date: Date, days: number) => {
    const utcOffset = new Date().getTimezoneOffset()

    if (date.getHours() + (utcOffset / 60) >= 21) ++days; // If after 5 eastern

    const result = new Date();
    result.setDate(date.getDate());

    while (days > 0) {
        result.setDate(result.getDate() + 1)

        const dayOfWeek = result.getDay();

        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
            // If the day of the week is not Saturday or Sunday, we know we've added a business day so we should reduce remaining count needed
            --days;
        }
    }

    return result;
}

export const distinct = (val, idx, self) => {
    return self.indexOf(val) === idx;
}