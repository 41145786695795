import React, { useEffect, useState } from "react";
import { FieldInput } from "src/components/FieldComponents";
import {
	ChecklistDetailVm,
	ChecklistTemplateSection,
} from "src/interfaces/interfaces";
import { addSection } from "src/services/checklists";
import styled from "styled-components";
import { ChecklistSection } from "./ChecklistSection";

interface NewChecklistSectionComponentProps {
	checklist: ChecklistDetailVm;
}

const StyledButton = styled.button`
	margin-top: 15px;
	padding: 6px 0px;
	border-radius: 2px;
	width: 112px;
	font-size: 12px;
`;

export const NewChecklistSectionComponent: React.FC<
	NewChecklistSectionComponentProps
> = ({ checklist }) => {
	const [checklistItem, setChecklistItem] = useState<ChecklistDetailVm>();

	useEffect(() => {
		setChecklistItem(checklist);
	}, [checklist]);

	return (
		<>
			{checklistItem &&
				checklistItem.sections.length > 0 &&
				checklistItem.sections.map((section) => {
					return (
						<ChecklistSection
							key={section.id}
							section={section}
							onUpdate={setChecklistItem}
							sectionCount={checklistItem.sections.length}
						></ChecklistSection>
					);
				})}
			<StyledButton
				className="btn btn-sm btn-blue"
				onClick={async () => {
					if (checklistItem) {
						const newSection: ChecklistTemplateSection = {
							title: "",
							subtitle: "",
							order: checklistItem.sections.length + 1,
							checklistTemplateId: checklist.id,
							fields: [],
							isOriginal: true,
						};
						const newChecklist = await addSection(newSection);
						if (newChecklist) {
							setChecklistItem(newChecklist);
						}
					}
				}}
			>
				Add Section
			</StyledButton>
		</>
	);
};
