import * as React from 'react';
import * as ProjectStore from '../store/projects';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { ListItem, MilestoneEntryVm } from '../interfaces/interfaces'
import NewMilestoneDialog from './NewMilestoneDialog';
import Modal from './Modal';

interface State {
    milestones: ListItem[],
    showNewMsDialog: boolean
}

interface DialogProps {
    //projectId: number
    projectId: number
    newEntry: (pId: number, mId: number) => void
    close: () => any

}

type SelectorProps =
    DialogProps
    & ProjectStore.Store
    & typeof ProjectStore.actionCreators

class MilestoneSelector extends React.Component<SelectorProps, State> {
    //export const AddItemDialog: React.SFC<DialogProps> = props => {
    constructor(props: SelectorProps) {
        super(props);
        this.state = {
            milestones: [],
            showNewMsDialog: false
        }
    }

    componentWillMount() {
        this.getMilestones()
    }

    //componentWillUpdate() {
    //    this.getMilestones()
    //}

    private getMilestones = () => {
        fetch(`api/Milestone/Get?id=${this.props.projectId}`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({
                    milestones: data,
                    showNewMsDialog: false
                })
            });
    }

    private toggleNewMsDialog = () => {
        if (this.state.showNewMsDialog) {
            this.getMilestones()
        }
        else {
            this.setState({
                showNewMsDialog: !this.state.showNewMsDialog
            })
        }
    }

    private save = () => {
        const sel = document.getElementById('milestoneSelector') as HTMLSelectElement;
        const opt = sel.querySelector('option:checked') as HTMLOptionElement;
        if (opt) {
            this.props.newEntry(this.props.projectId, parseInt(opt.value))
        }
    }

    public render() {
        const opts = (this.state.milestones && this.state.milestones.length) ? this.state.milestones.map(i => {
            return <option key={i.id} value={i.id}>{i.value}</option>
        }) : []
        return (
            this.state.showNewMsDialog ?
                <NewMilestoneDialog close={this.toggleNewMsDialog} /> :
                <Modal dialogStyle={{width: '400px'}}>
                    <div className="modal-header">
                        <h4 className="modal-title">Select Milestone To Add</h4>
                    </div>
                    <div className="modal-body">
                        <p>Select Milestone</p>
                        <select className='form-control' id='milestoneSelector'>
                            <option value="0"></option>
                            {opts}
                        </select>
                        <br />
                        {/*<span className='btn btn-sm btn-blue' onClick={this.toggleNewMsDialog}>Create Milestone</span>*/}
                    </div>
                    <div className="modal-footer">
                        <span className="btn btn-sm btn-green" onClick={this.save.bind(this)}>Save</span>
                        <span className="btn btn-sm btn-outline-secondary" onClick={this.props.close}>Close</span>
                    </div>
                </Modal>
        )
    }
}

export default connect<ProjectStore.Store, typeof ProjectStore.actionCreators, DialogProps>(
    //@ts-ignore
    (state: ApplicationState) => state.projects,
    ProjectStore.actionCreators,
)(MilestoneSelector) //as typeof KeyMilestoneStartModal