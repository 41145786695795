import * as React from "react";

import { GlobalMessageBox } from "../../components/MessageBox";
import {
	ChecklistDetailVm,
	ChecklistFunction,
	ChecklistVm,
	ListItem,
} from "../../interfaces/interfaces";
import { DetailModal } from "./ChecklistTemplateModal";
import { typeToText } from "../../checklists/ChecklistGrid";
import { getActiveUsers } from "src/services/users";
import {
	addFunction,
	canEditChecklist,
	copyChecklistTemplate,
	editChecklistFunction,
	getChecklistFunctions,
} from "src/services/checklists";
import { StandardGridLoader } from "src/loaders/StandardGridLoader";

export interface ChecklistHomeApi {
	toggleEditId: (id: number | null) => void;
	refreshLists: () => void;
	checklists: ChecklistVm[];
}

export const ChecklistContext = React.createContext({} as ChecklistHomeApi);

export const ChecklistHome = () => {
	const [editListId, setEditListId] = React.useState<number | null>(null);
	const [checklists, setChecklists] = React.useState<ChecklistVm[]>([]);
	const [users, setUsers] = React.useState<ListItem[]>([]);
	const [checklist, setChecklist] = React.useState<ChecklistDetailVm>();
	const [checklistFunctions, setChecklistFunctions] = React.useState<
		ListItem[]
	>([]);
	const [loading, setLoading] = React.useState<boolean>(false);

	const _getLists = () => {
		setLoading(true);
		fetch(`api/ChecklistConfig/Get`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setChecklists(data);
				setLoading(false);
			});
	};

	const _getUsers = async () => {
		const activeData = await getActiveUsers();
		setUsers(activeData);
	};

	const _getFunctions = async () => {
		const functions = await getChecklistFunctions();
		if (functions) {
			setChecklistFunctions(functions);
		}
	};

	const _addFunction = async (type: string) => {
		const functions = await addFunction(type);
		if (functions) {
			setChecklistFunctions(functions);
		}
	};

	const _editChecklistFunction = async (
		listId: number,
		checklistId: number
	) => {
		const checklist = await editChecklistFunction(listId, checklistId);
		if (checklist) {
			setChecklist(checklist);
		}
	};

	const _handleTemplateCopy = async (templateId: number) => {
		setLoading(true);
		const newChecklist = await copyChecklistTemplate(templateId);
		if (newChecklist) {
			_getLists();
			setChecklist(newChecklist);
			setEditListId(newChecklist.id);
		} else {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		_getLists();
		_getUsers();
		_getFunctions();
	}, []);

	const api = {
		toggleEditId: (id) => setEditListId(id),
		refreshLists: _getLists,
		checklists: checklists,
	} as ChecklistHomeApi;

	const _add = () => {
		fetch(`api/ChecklistConfig/Add`, { method: "POST" })
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setEditListId(data.id);
					setChecklist(data);
				}
			});
	};

	const gridLines = checklists.map((x) => {
		return (
			<div
				key={x.id}
				className="sv-grid-line"
				onClick={async () => {
					const canEdit = await canEditChecklist(x.id);
					if (canEdit) {
						if (canEdit.result) {
							setChecklist(canEdit.checklist);
							setEditListId(x.id);
						} else {
							alert(
								`You do not have permission to edit this template. Please contact ${
									canEdit.checklist.userFullName ?? "your system administrator"
								} for assistance.`
							);
						}
					}
				}}
			>
				<div className="col-sm-3">{x.title}</div>
				<div className="col-sm-2">{typeToText(x.type)}</div>
				<div className="col-sm-3">{x.clients}</div>
				<div className="col-sm-4">{x.roles}</div>
			</div>
		);
	});

	return (
		<div className="checklist-home">
			<ChecklistContext.Provider value={api}>
				<div className="sv-grid">
					<div className="sv-grid-header">
						<div className="col-sm-3">Title</div>
						<div className="col-sm-2">Type</div>
						<div className="col-sm-3">Clients</div>
						<div className="col-sm-4">Roles</div>
						<div style={{ top: "-5px" }} className="sv-grid-header-buttons">
							<div
								className="btn btn-sm btn-blue fas fa-plus"
								onClick={_add}
							></div>
						</div>
					</div>
					{loading ? (
						<StandardGridLoader rowContentHeight={22} />
					) : (
						<div className="sv-grid-body">{gridLines}</div>
					)}
				</div>
				{editListId && checklist && (
					<DetailModal
						checklistFunctions={checklistFunctions}
						chosenChecklist={checklist}
						users={users}
						id={editListId}
						onFunctionAdd={_addFunction}
						onEditChecklistFunction={_editChecklistFunction}
						checklists={checklists}
						onChecklistInherit={_getLists}
						onChecklistCopy={_handleTemplateCopy}
					/>
				)}
			</ChecklistContext.Provider>
		</div>
	);
};
