import React from 'react';
import { formatDate, formatDateInputFriendly, toMoney } from 'src/helpers/formatters';
import { ListItem } from 'src/interfaces/interfaces';
import FilterableSelect from '../FilterableSelect';
import { SliderCheckbox } from '../SliderCheckbox';

export const FieldInput = (props: { id: number; field: string; value: any; canEdit: boolean; update: (e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => void, dataType?: string }) => {
  const { id, field, canEdit, update } = props;
  let { value, dataType } = props;
  switch (dataType) {
    case 'date': value = canEdit ? formatDateInputFriendly(value) : formatDate(value); break;
    case 'money': value = canEdit ? value : toMoney(value); dataType = 'number'; break;
    default: break;
  }

  return canEdit ? dataType != "checkbox" ?
    <input className='form-control' type={dataType} data-id={id} name={field} defaultValue={value} onBlur={update} /> :
    <SliderCheckbox fieldName={field} selected={value} onChange={update} dataId={id} />
    //(<label className='switch'>
    //    <input data-id={id} name={field} onChange={update} type={dataType} />
    //    <span className='slider round'></span>
    //</label>)
    : value;
}

export const FieldTextArea = (props: { id: number; field: string; value: any; canEdit: boolean; rows?: number; update: (e: React.FocusEvent<HTMLTextAreaElement>) => void }) => {
  const { id, field, value, canEdit, update, rows } = props;

  return canEdit ?
    <textarea className='form-control' data-id={id} name={field} defaultValue={value} onBlur={update} rows={rows}></textarea>
    : <div style={{width: 'calc(100% - 220px)'} }>{value}</div>;
}

export const FieldSelect = (props: { id: number; field: string; value: any; canEdit: boolean; items: ListItem[]; update: (e: React.ChangeEvent<HTMLSelectElement>) => void }) => {
  const { id, field, value, canEdit, items, update } = props;
  return canEdit ?
    <select className='form-control' data-id={id} name={field} defaultValue={value} onChange={update}>
      {items.map(x => <option key={x.id} className='form-control' value={x.id}>{x.value}</option>)}
    </select>
    //@ts-ignore
    : items.find(x => x.id === value).value;
}

export const FieldFilterableSelect = (props: { id: number; field: string; value: any; canEdit: boolean; items: ListItem[]; update: (field, value, id) => void, openField?: boolean, backupField?: string, style?: React.CSSProperties }) => {
  const { id, field, value, canEdit, items, update, openField, backupField, style } = props;

  const localUpdate = (v: string) => {
    update(field, v, id);
  }

  const localBlurUpdate = (v: string) => {
    update(backupField || field, v, id);
  }

  return canEdit ?
    <FilterableSelect id={`${field}_select_${id}`} style={style} defaultVal={value} items={items} onChange={localUpdate} openField={openField} onBlur={openField ? localBlurUpdate : undefined} />
    //@ts-ignore
    : <span>{(items.find(x => x.id === value) || { value: '' }).value}</span>;
}