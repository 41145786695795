import * as React from 'react'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import * as Dfr from '../store/dfr'

interface State { }
interface Props { }

type ListProps = Props
    & Dfr.Store
    & typeof Dfr.actionCreators

class DfrVisitor extends React.Component<ListProps, State> {
    constructor(props: any) {
        super(props);       
    }
  
    userVisitorInput = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        this.props.updateDFR(e.currentTarget.value, "visitors");
    }   

    render() {
        const { visitors } = this.props;
        return (
            <div id="dfr_visitor_wrapper" className="tile col-sm-6" style={{ marginBottom: '10px' }}>
                <h5 id="dfr_visitor_label">Visitors</h5>
                <textarea id="dfr_visitor_input" className='form-control' defaultValue={visitors || ""} onBlur={this.userVisitorInput} disabled={this.props.isLocked} />
            </div>
        )
    }
}

export default connect<Dfr.Store, typeof Dfr.actionCreators, Props>(
    //@ts-ignore
    (state: ApplicationState) => state.dfr,
    Dfr.actionCreators
)(DfrVisitor)