import * as React from "react";
import {
	ChecklistStatus,
	ChecklistType,
	ListItem,
} from "../interfaces/interfaces";

import { ApplicationState } from "../store/index";
import { useSelector } from "react-redux";
import Modal from "../components/Modal";
import FilterableSelect from "../components/FilterableSelect";
import { ProjectRole } from "../config/global/ProjectRoles";
import { ChecklistGrid, ChecklistGridProps } from "../checklists/ChecklistGrid";

export const VendorChecklistGrid = (props: { vendorId: number }) => {
	const { vendorId } = props;

	const [templateOptions, setTemplateOptions] = React.useState(
		[] as ListItem[]
	);
	const [showAddModal, setShowAddModal] = React.useState(false);
	const [timestamp, setTimestamp] = React.useState(
		new Date().getMilliseconds()
	);
	//const [selectedListRoleInfo, setSelectedListRoleInfo] = React.useState({ restricted: false, roles: [] as ProjectRole[] })

	const userStore = useSelector((s: ApplicationState) => s.user);
	const globalStore = useSelector((s: ApplicationState) => s.global);

	const _getUserChecklistTypes = () => {
		fetch(`api/VendorChecklist/GetTemplates`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setTemplateOptions(data);
			});
	};

	const _add = () => {
		const templateId = (
				document.querySelector(
					'#checklist-template input[name="checklist-template"]'
				) as HTMLInputElement
			).value,
			dueDate = (
				document.querySelector("#checklist-duedate") as HTMLInputElement
			).value;

		if (!templateId || templateId === "0") {
			alert("Please select a template from the list.");
			return;
		}

		fetch(
			`api/VendorChecklist/Add?recordId=${vendorId}&listId=${templateId}&dueDate=${dueDate}`,
			{ method: "POST" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setTimestamp(new Date().getMilliseconds());
				}
				setShowAddModal(false);
			});
	};

	const gridProps = {
		parentRecordId: vendorId,
		renderTimestamp: timestamp,
		toggleAddModal: () => setShowAddModal(!showAddModal),
		type: ChecklistType.Vendor,
	} as ChecklistGridProps;

	React.useEffect(() => {
		_getUserChecklistTypes();
	}, [vendorId]);

	const userItems = globalStore.users.map(
		(x) => new ListItem(x.userId, x.fullName)
	);

	const addModal = templateOptions.length ? (
		<Modal>
			<div className="modal-header">
				<h5>Create Checklist</h5>
			</div>
			<div className="modal-body">
				<div className="form-group">
					<div className="inline-label">Checklist Template*</div>
					<FilterableSelect items={templateOptions} id="checklist-template" />
				</div>
				<div className="form-group">
					<div className="inline-label">Due Date</div>
					<input id="checklist-duedate" className="form-control" type="date" />
				</div>
			</div>
			<div className="modal-footer">
				<div className="btn btn-sm btn-blue" onClick={_add}>
					Create
				</div>
				<div
					className="btn btn-sm btn-outline-secondary"
					onClick={() => setShowAddModal(false)}
				>
					Close
				</div>
			</div>
		</Modal>
	) : (
		<Modal>
			<div className="modal-header">
				<h5>Create Checklist</h5>
			</div>
			<div className="modal-body">
				<div className="">
					No checklist templates were found for vendors. Please use the
					configuration portal to set up vendor checklists.
				</div>
			</div>
			<div className="modal-footer">
				<div
					className="btn btn-sm btn-outline-secondary"
					onClick={() => setShowAddModal(false)}
				>
					Close
				</div>
			</div>
		</Modal>
	);

	return (
		<div className="project-checklists">
			<ChecklistGrid {...gridProps} />
			{showAddModal && addModal}
		</div>
	);
};
