import React, { useState, useRef } from 'react';
import Modal from '../components/Modal';
import { PunchItemVm } from 'src/interfaces/interfaces';
import { formatDateTime } from 'src/helpers/formatters';
import styled from 'styled-components';

interface PunchItemCommentModalProps {
  item: PunchItemVm;
  onClose: () => void;
  onCommentAdd: (id: number, value: string) => void;
}

const CommentDiv = styled.div({
  padding: '10px',
  backgroundColor: 'rgb(230, 230, 230, 0.4)',
  borderRadius: '2px',
  textAlign: 'left',
});
const CommentNameTimestampDiv = styled.div({
  textAlign: 'right',
});

const NewCommentDiv = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  borderRadius: '2px',
});

const ModalFooterDiv = styled.div<{ canEdit: boolean }>((props) => ({
  justifyContent: props.canEdit ? 'space-between' : 'flex-end',
  borderTop: 'none',
  paddingTop: 0,
}));

const ModalBodyDiv = styled.div({
  height: '403.5px',
  marginBottom: '10px',
  overflowY: 'auto',
});

export const PunchItemCommentModal: React.FC<PunchItemCommentModalProps> = ({
  item,
  onCommentAdd,
  onClose,
}) => {
  const [comment, setComment] = useState('');

  return (
    <div style={{ width: '300px' }}>
      <div>
        <h5>Comments</h5>
      </div>
      <ModalBodyDiv className="custom-scrollbar">
        <>
          {item.comments.length ? (
            item.comments.map((comment) => {
              return (
                <div key={comment.id} className="mb-2">
                  <CommentNameTimestampDiv>
                    {comment.userFullName} -{' '}
                    <i>{formatDateTime(comment.timestamp)}</i>
                  </CommentNameTimestampDiv>
                  <CommentDiv>{comment.comment}</CommentDiv>
                </div>
              );
            })
          ) : (
            <h6>No comments have been left yet</h6>
          )}
        </>
      </ModalBodyDiv>
      <ModalFooterDiv canEdit={item.canEdit} className="">
        <>
          <NewCommentDiv className="new-comment">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <label style={{ marginBottom: '2px', fontSize: '1.1em' }}>
                Add Comment
              </label>
              <textarea
                disabled={!item.canEdit}
                defaultValue={
                  !item.canEdit
                    ? 'This item is closed for comments.'
                    : undefined
                }
                onChange={(e) => setComment(e.currentTarget.value)}
                className="form-control mr-2"
              ></textarea>
            </div>
            <button
              className="btn btn-sm btn-blue fas fa-paper-plane"
              title="Add comment"
              aria-hidden="true"
              onClick={() => onCommentAdd(item.id, comment)}
              disabled={!item.canEdit}
            ></button>
            <span className="sr-only">Add comment</span>
          </NewCommentDiv>
        </>
      </ModalFooterDiv>
    </div>
  );
};
