const dash_key = 189,
    delete_key = [8, 46],
    direction_key = [33, 34, 35, 36, 37, 38, 39, 40];

function do_phone_format(ele: any, restore: any) {
    let new_number,
        selection_start = ele.selectionStart,
        selection_end = ele.selectionEnd
        const number = ele.value.replace(/\D/g, '');

    // auto-add dashes
    if (number.length > 2) {
        new_number = number.substring(0, 3) + '-';
        if (number.length === 4 || number.length === 5) {
            new_number += number.substring(3);
        }
        else if (number.length > 5) {
            new_number += number.substring(3, 6) + '-';
        }
        if (number.length > 6) {
            new_number += number.substring(6);
        }
    }
    else {
        new_number = number;
    }

    ele.value = new_number.length > 12 ? new_number.substring(0, 12) : new_number;

    // put cursor back where it was
    if (new_number.slice(-1) === '-' && restore === false
        && (new_number.length === 8 && selection_end === 7)
        || (new_number.length === 4 && selection_end === 3)) {
        selection_start = new_number.length;
        selection_end = new_number.length;
    }
    else if (restore === 'revert') {
        selection_start--;
        selection_end--;
    }

    ele.setSelectionRange(selection_start, selection_end);
}

const check_for_numpad = (code: number) => {
    return (code >= 96 && code <= 105) ?
        code - 48 :
        code;
}

export const check_phone_format = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const field = e.currentTarget,
        key_code = check_for_numpad(e.keyCode),
        key_string = String.fromCharCode(key_code),
        selection_end = field.selectionEnd || 0;
    let press_delete = false;
    
    console.log(key_code)
    console.log(key_string)

    if (delete_key.indexOf(key_code) > -1)
        press_delete = true;

    if (key_string.match(/^\d+$/) || press_delete)
        do_phone_format(field, press_delete);
    else if (direction_key.indexOf(key_code) > -1) {
        // do nothing
    }
    else if (key_code === dash_key) {
        if (selection_end === field.value.length) {
            field.value = field.value.slice(0, -1);
        }
        else {
            field.value = field.value.substring(0, selection_end - 1) + field.value.substring(selection_end);
            field.selectionEnd = selection_end - 1;
        }
    }
    else {
        e.preventDefault();
        do_phone_format(field, 'revert');
    }
}

export const check_money_format = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const field = e.currentTarget
    const amount = field.value.replace(/\$/g, '').replace(/,/g, '');

    field.value = toMoney(parseFloat(amount));
}

export const toMoney = (valNo: number | string, pts?: number) => {
    valNo = typeof (valNo) === 'number' ? valNo : parseInt(valNo);
    let n = (Math.round(valNo * 100) / 100).toString();
        const decimals = pts || 0, d = ".", t = ",", s = valNo < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(decimals))),
        m = parseInt(i);
        let j: any;
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + "$" + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (decimals ? d + Math.abs(valNo - m).toFixed(decimals).slice(2) : "");
}

export const formatDate = (date: string) => {
    if (!date || !date.length) return "";
    const myDate = new Date(date);
    return `${myDate.getMonth()+1}/${myDate.getDate()}/${myDate.getFullYear()}`
}

export const formatDateTime = (date: string) => {
    if (!date || !date.length) return "";
    const myDate = new Date(date);
    const hour = myDate.getHours() === 12 ? 12 : myDate.getHours() % 12, amPm = myDate.getHours() > 12 ? 'PM' : 'AM'
    return `${myDate.getMonth() + 1}/${myDate.getDate()}/${myDate.getFullYear()} ${hour}:${lPad(myDate.getMinutes().toString(), 2, '0')} ${amPm}`
}

export const formatDateInputFriendly = (date: string) => {
    const str = date && date.length > 0 && Date.parse(date) ? new Date(date).toISOString().substring(0, 10) : ""
    return str;
}

export const lPad = (str: string, num: number, char: string) => {
    while (str.length < num) {
        str = char + str;
    }

    return str;
}

/* Convert any string to camel-case */
export const camelize = (str) => {
    if (!str) return str;
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export const pascalizeAndSeparate = (str) => {
    if (!str) return str;
    const withSpaces = str.replace(/([A-Z])/g, " $1");
    const result = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);    

    return result;
}