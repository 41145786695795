import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CcedUser, ListItem } from '../interfaces/interfaces';
import { ApplicationState } from '../store';
import { PunchlistActions } from '../store/punchlist';
import { JoinedList } from './JoinedList';
import Modal from './Modal';

export const CCedUsersModal = (props: { route: string, id: number, users: CcedUser[], close: () => any, editable: boolean, projectId?: number }) => {

  const projectStore = useSelector((s: ApplicationState) => s.detail);
	const globalStore = useSelector((s: ApplicationState) => s.global);
	const dispatch = useDispatch();

  const [availableUsers, setAvailableUsers] = React.useState([] as ListItem[])

  React.useEffect(() => {
    if (props.projectId) {
      if (projectStore) {
        setAvailableUsers(projectStore.detail.teamMembers.map(x => new ListItem(x.userId, x.userFullName)));
      }
      else {
        // this is an error of some sort, need to handle this case gracefully
      }
    }
    else {
      setAvailableUsers(globalStore.users.map(x => new ListItem(x.userId, x.fullName)));
    }
	}, [props.projectId])

	const _addUsers = (selected: ListItem[]) => {
		const userIds = selected.map(x => x.id).join(';');
		dispatch(PunchlistActions.addCCUsers({ id: props.id, userIds, emails: '' }))
	}

	const _removeUsers = (selected: ListItem[]) => {
		const userIds = selected.map(x => x.id).join(';');
		dispatch(PunchlistActions.removeCCUsers({ id: props.id, userIds }))
	}

	return <Modal dialogStyle={{width: '650px', marginTop: '2.75em' }}>
		<div className="modal-body">
			<JoinedList
				title="CCed Users"
				disabled={!props.editable}
				availableItems={availableUsers.filter(
					(x) => props.users.findIndex((y) => y.userId === x.id) === -1
				)}
				selectedItems={props.users.map(
					(x) => new ListItem(x.userId, x.userFullName)
				)}
				onAdd={_addUsers}
				onRemove={_removeUsers}
			/>
			<br />
		</div>
		<div className="modal-footer">
			<button
				className="btn btn-sm btn-outline-secondary"
				onClick={props.close}
			>
				Close
			</button>
		</div>
	</Modal>
}