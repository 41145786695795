import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomizableGrid,
  MetaFieldInfo,
} from 'src/components/CustomizableGrid';
import { GlobalMessageBox } from 'src/components/MessageBox';
import { CSharpTypeCode } from 'src/interfaces/enums';
import { IssueVm, ListItem } from 'src/interfaces/interfaces';
import { ApplicationState } from 'src/store';
import {
  contributingFactorsSelector,
  dispositionsSelector,
  isIssuesLoadingSelector,
  IssuesActions,
  issuesSelector,
  issueTypesSelector,
  rootCausesSelector,
  selectedIssueSelector,
} from 'src/store/issues';
import { NCRActions } from 'src/store/ncrs';
import styled from 'styled-components';
import { IssueAddModal } from './IssueAddModal';
import { IssueCharts } from './IssueCharts';
import { IssueDetailModal } from './IssueDetailModal';

const columns: MetaFieldInfo[] = [
  {
    displayName: 'Title',
    propName: 'title',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Status',
    propName: 'status',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Assignee',
    propName: 'userId',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Type',
    propName: 'type.name',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
  {
    displayName: 'Contributing Factor',
    propName: 'contributingFactor.name',
    jsType: 'string',
    propType: CSharpTypeCode.String,
  },
];

const StyledCustomizableGrid = styled(CustomizableGrid)({
  '& .sv-grid-body': {
    maxHeight: '40vh',
  },
});

export const IssueHome = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const issues = useSelector(issuesSelector);
  const selectedIssue = useSelector(selectedIssueSelector);
  const issueTypes = useSelector(issueTypesSelector);
  const rootCauses = useSelector(rootCausesSelector);
  const contributingFactors = useSelector(contributingFactorsSelector);
  const dispositions = useSelector(dispositionsSelector);

  const issuesLoading = useSelector(isIssuesLoadingSelector);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const dispatch = useDispatch();

  const cFactorListItems = useMemo(() => {
    return contributingFactors.map((x) => new ListItem(x.name, x.name));
  }, [contributingFactors]);

  const onRowClick = (issue: IssueVm) => {
    dispatch(IssuesActions.setSelectedIssueId(issue.id));
  };

  const renderItem = useCallback((issue: IssueVm) => {
    return (
      <div
        onClick={() => onRowClick(issue)}
        className="sv-grid-line"
        key={issue.id}
      >
        <div className="col">{issue.title}</div>
        <div className="col">{issue.statusName}</div>
        <div className="col">{issue.assigneeName}</div>
        <div className="col">{issue.type?.name}</div>
        <div className="col">{issue.contributingFactor?.name}</div>
      </div>
    );
  }, []);

  const addIssue = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  useEffect(() => {
    dispatch(IssuesActions.getIssues(projectStore.projId));
  }, [projectStore.projId]);

  useEffect(() => {
    dispatch(IssuesActions.getConfig());
    dispatch(IssuesActions.setSelectedIssueId(undefined));
  }, []);

  return (
    <>
      <GlobalMessageBox />
      {isModalVisible && (
        <IssueAddModal
          onClose={() => setIsModalVisible(false)}
          onIssueCreate={(issue) => {
            dispatch(IssuesActions.createIssue(issue));
            setIsModalVisible(false);
          }}
          projectId={projectStore.projId}
          issueTypes={issueTypes}
          contributingFactors={contributingFactors}
          rootCauses={rootCauses}
          teamMembers={projectStore.detail.teamMembers}
        />
      )}
      {selectedIssue && (
        <IssueDetailModal
          onIssueUpdate={(updateObject) =>
            dispatch(
              IssuesActions.updateIssue({
                id: selectedIssue.id,
                info: updateObject,
              })
            )
          }
          onClose={() => dispatch(IssuesActions.setSelectedIssueId(undefined))}
          onSendReport={(issueId, report) =>
            dispatch(
              NCRActions.sendReport({ issueId: issueId, report: report })
            )
          }
          issue={selectedIssue}
          contributingFactors={cFactorListItems}
          dispositions={dispositions}
        ></IssueDetailModal>
      )}
      <IssueCharts loading={issuesLoading} issues={issues} />
      <br />
      <StyledCustomizableGrid
        items={issues ?? []}
        columns={columns}
        hideLoadingWhenEmpty={true}
        mapCallback={renderItem}
        evenColumnSpacing={true}
        addCallback={addIssue}
        loading={issuesLoading}
      />
    </>
  );
};
