import * as React from "react";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { MessageBox } from "../components/MessageBox";
import * as RFIStore from "../store/rfi";
import { Loader } from "../components/Loader";
import {
  RFI,
  RFIQuestionDocument,
  RFIStatus,
  UserProjectNotifications,
} from "../interfaces/interfaces";
import RFIAddModal from "./RFIAddModal";
import { formatDate } from "../helpers/formatters";
import * as cx from "classnames";
import { NavLink } from "react-router-dom";
import {
  FileDownloader,
} from "../components/FileDownloader";
import Modal from "../components/Modal";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { ReissueRequestGrid } from "./ReissueRequestGrid";
import { Tabs, TabContent, TabLink } from "../tabs/index";
import RFICustomFieldsGrid from "./RFICustomFieldsGrid";
import { MyAuthElement } from "../auth/Authorization";
import { KnownRoles } from "../auth/auth";
import DocIcon from "../components/DocIcon";

type Props = RFIStore.Store & typeof RFIStore.actionCreators;

type State = {
  showRFIForm: boolean;
  showFilters: boolean;
  showReportModal: boolean;
  rfiDetail?: RFI;
  loading: boolean;
  userNotifications: UserProjectNotifications;
  sortProp?: string;
  sortOrder: string;
  message?: string;
};

class RFIHome extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showRFIForm: false,
      showFilters: false,
      showReportModal: false,
      rfiDetail: undefined,
      sortOrder: "DESC",
      loading: true,
      userNotifications: {} as UserProjectNotifications,
    };
  }

  componentDidMount() {
    const split = window.location.href.split("/");
    const id = parseInt(split[split.length - 1]) || 0;
    this.props.request(id);
    this._getUserNotifications(id);
  }

  csgComp = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
  ]);

  _getUserNotifications = (id: number) => {
    fetch(`api/ProjectNotification/Get?id=${id}`)
      .then((res) => res.json())
      .then((data) => this.setState({ userNotifications: data }));
  };

  _toggleWatchNotifications = () => {
    fetch(`api/ProjectNotification/RFIs?id=${this.props.projectId}`, {
      method: "PUT",
    })
      .then((res) => res.json())
      .then((data) => this.setState({ userNotifications: data }));
  };

  _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const prop = e.currentTarget.getAttribute("name") || "";
    this.props.filter(prop, val);
  };

  _filterOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const opt = e.currentTarget.querySelector(
      "option:checked"
    ) as HTMLOptionElement;
    const prop = e.currentTarget.getAttribute("name") || "";
    this.props.filter(prop, opt.value);
  };

  componentWillUnmount() {
    this.props.clearMessage();
  }

  _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
    const prop = e.currentTarget.getAttribute("data-for") || undefined;
    const sortOrder = this.state.sortOrder === "DESC" ? "ASC" : "DESC";
    this.setState({ sortProp: prop, sortOrder: sortOrder });
  };

  _toggleRFIDetail = (id?: number) => {
    if (id) {
      const rfi = this.props.rfis.find((x) => x.id == id);
      this.setState({ rfiDetail: rfi });
    } else {
      this.setState({ rfiDetail: undefined });
    }
  };

  _toggleFilters = () =>
    this.setState({ showFilters: !this.state.showFilters });

  _toggleReportModal = () =>
    this.setState({ showReportModal: !this.state.showReportModal });

  render() {
    const { showReportModal, sortOrder, sortProp } = this.state;
    let { rfis } = this.props;
    if (sortProp) {
      rfis = rfis.sort((a, b) => {
        let aVal = a,
          bVal = b;
        sortProp.split(".").forEach((x) => {
          (aVal = aVal[x]), (bVal = bVal[x]);
        });
        if (bVal === undefined) return -1;
        if (sortOrder === "DESC") return aVal > bVal ? -1 : 1;
        else return aVal > bVal ? 1 : -1;
      });
    }
    const rfiGrid = this.renderGrid(this.props.rfis);

    return (
      <div>
        <Loader loading={this.props.saving} />
        <MessageBox
          message={this.props.message}
          clearMessage={this.props.clearMessage}
        />
        {showReportModal && <ReportModal projectId={this.props.projectId} close={this._toggleReportModal} /> }
        <ReissueRequestGrid projectId={this.props.projectId} />
        <div style={{ marginLeft: "-15px" }}>
          <Tabs
            name="rfi-tabs"
            onChange={() => { }}
            renderActiveTabContentOnly={true}
            activeLinkStyle={{
              backgroundColor: "rgb(0,99,66)",
              border: "solid 0.5px rgb(0,56,119)",
              color: "white",
            }}
          >
            <div className="tab-links bordered">
              <TabLink to="items" component="span">
                <span>Items</span>
              </TabLink>
              <this.csgComp componentType="span">
                <TabLink to="customfields" component="span">
                  <span>Custom Fields</span>
                </TabLink>
              </this.csgComp>
            </div>
            <div className="content bordered">
              <TabContent for="items">{rfiGrid}</TabContent>
              <TabContent for="customfields">
                <RFICustomFieldsGrid />
              </TabContent>
            </div>
          </Tabs>
        </div>
        {this.props.showRFIForm && (
          <RFIAddModal
            type="RFI"
            save={this.props.addRFI}
            close={this.props.toggleRFIForm}
            projectId={this.props.projectId}
          />
        )}
      </div>
    );
  }

  renderGridHeader() {
    const { sortOrder, sortProp, userNotifications } = this.state;
    const sortIconClass =
      sortOrder === "DESC"
        ? "sort-icon fas fa-arrow-down"
        : "sort-icon fas fa-arrow-up";
    const sortIcon = sortProp ? <span className={sortIconClass}></span> : [];
    return (
      <div className="col-sm-12 rfi-grid-header">
        <div className="col-sm-1 min-110 sortable">
          <label onClick={this._sort} data-for="rfiNumber">
            RFI # {sortProp === "rfiNumber" && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={this._sort} data-for="date">
            Submit Date {sortProp === "date" && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={this._sort} data-for="dueDate">
            Due Date {sortProp === "dueDate" && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={this._sort} data-for="status">
            Status {sortProp === "status" && sortIcon}
          </label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={this._sort} data-for="requesterName">
            Submitted By {sortProp === "requesterName" && sortIcon}
          </label>
        </div>
        <div className="col-sm-2 mobile-hide sortable">
          <label onClick={this._sort} data-for="reviewers.userFullName">
            Reviewer(s) {sortProp === "reviewers.userFullName" && sortIcon}
          </label>
        </div>
        <div className="col-sm-2 min-85">
          <label>Subject</label>
        </div>
        <div className="col-sm-1 mobile-hide">
          <label>Documents</label>
        </div>
        <div className="col-sm-1 mobile-hide sortable">
          <label onClick={this._sort} data-for="approvedTimestamp">
            Accepted Date {sortProp === "approvedTimestamp" && sortIcon}
          </label>
        </div>
        <div
          className="col-sm-1 min-130"
          style={{
            display: "flex",
            position: "absolute",
            right: "0px",
            top: "5px",
          }}
        >
          <span
            className="btn btn-sm btn-blue fas fa-plus"
            onClick={this.props.toggleRFIForm}
          ></span>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-filter"
            onClick={this._toggleFilters}
          ></span>
          <span
            className="btn btn-sm btn-outline-secondary fas fa-download"
            title="Download Pdf"
            onClick={this._toggleReportModal}
          ></span>
          <span
            className={cx(
              "btn btn-sm btn-outline-secondary far",
              userNotifications.watchRFIs ? "fa-eye-slash" : "fa-eye"
            )}
            title={
              userNotifications.watchRFIs
                ? "Stop automatically being CCed on RFIs for this project"
                : "Automatically be CCed on all RFIs on this project"
            }
            onClick={this._toggleWatchNotifications}
            style={{ fontSize: "11px" }}
          ></span>
        </div>
      </div>
    );
  }

  renderGrid(items: RFI[]) {
    const rows = items.length ? items.map(x => {
      const reviewers = x.reviewers && x.reviewers.length ?
        x.reviewers.map(x => x.userFullName).join(', ')
        : ''
      let statusClass = '', statusText = '';
      switch (x.status) {
        case 0: statusClass = 'left-tab-yellow'; statusText = 'Awaiting Response'; break;
        case 1: statusClass = 'left-tab-blue'; statusText = 'Answered'; break;
        case 2: statusClass = 'left-tab-green'; statusText = 'Accepted'; break;
        case RFIStatus.Reissued: statusText = 'Reissued'; statusClass = 'left-tab-purple'; break;
        case RFIStatus.ReissueRequested: statusText = 'Reissue Requested'; statusClass = 'left-tab-purple'; break;
        case RFIStatus.Canceled: statusText = 'Canceled'; statusClass = 'left-tab-red'; break;
      }
      const docs = x.questionDocuments && x.questionDocuments.length ?
        x.questionDocuments.map(doc => <DocIcon key={x.id} doc={doc} />) :
        []

      return (
        <div
          key={x.id}
          className={cx("col-sm-12 striped-row", statusClass)}
        >
          <NavLink to={`/rfi/${x.id}`} className="row">
            <div className="col-sm-1 min-110">
              {x.rfiNumber}{" "}
              {x.late ? (
                <span
                  title="This RFI is past due"
                  className="fas fa-exclamation-triangle"
                ></span>
              ) : (
                []
              )}
            </div>
            <div className="col-sm-1 mobile-hide">{formatDate(x.date)}</div>
            <div className="col-sm-1 mobile-hide">
              {formatDate(x.dueDate)}
            </div>
            <div className="col-sm-1 mobile-hide">{statusText}</div>
            <div className="col-sm-1 mobile-hide">{x.userFullName}</div>
            <div className="col-sm-2 mobile-hide">{reviewers}</div>
            <div className="col-sm-2 min-179">{x.subject}</div>
            <div className="col-sm-1 mobile-hide">
              <div style={{ display: "flex" }}>{docs}</div>
            </div>
            <div className="col-sm-2 mobile-hide">
              {formatDate(x.approvedTimestamp)}
            </div>
          </NavLink>
        </div>
      );
    })
      : [];

    return (
      <div className='grid rfi-grid'>
        {this.renderGridHeader()}
        {this.state.showFilters && this.renderFilterLine()}
        {this.props.loading && <div className=''><StandardGridLoader rowContentHeight={20} rowPadding={21} /></div>}
        <div className='grid-body custom-scrollbar'>{rows}</div>
      </div>
    )
  }

  renderFilterLine() {
    return (
      <div className="col-sm-12 rfi-grid-filter" style={{ display: "flex" }}>
        <div className="col-sm-1 min-130">
          <input
            name="rfiNumber"
            className="form-control"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="date"
            className="form-control"
            type="date"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="dueDate"
            className="form-control"
            type="date"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-1 mobile-hide">
          <select
            name="status"
            className="form-control"
            onChange={this._filterOnSelect}
          >
            <option value="">Select...</option>
            <option value="0">Awaiting Response</option>
            <option value="1">Answered</option>
            <option value="2">Accepted</option>
            <option value="4">Reissued</option>
            <option value="5">Reissue Requested</option>
          </select>
        </div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="requesterName"
            className="form-control"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-2 mobile-hide">
          <input
            name="reviewers"
            className="form-control"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-2 min-220">
          <input
            name="subject"
            className="form-control"
            onChange={this._filter}
          />
        </div>
        <div className="col-sm-1"></div>
        <div className="col-sm-1 mobile-hide">
          <input
            name="approvedTimestamp"
            className="form-control"
            type="date"
            onChange={this._filter}
          />
        </div>
      </div>
    );
  }

  renderDocIcon(doc: RFIQuestionDocument) {
    const split = doc.filePath.split("content");
    const relPath = "/files" + split[split.length - 1].replace(/#/g, "%23");
    const slashSplit = relPath.split("/"),
      fileName = slashSplit[slashSplit.length - 1];
    return (
      <a
        key={doc.id}
        className="response-doc-icon"
        href={relPath}
        target="_blank"
        title={fileName}
      >
        <img style={{ height: "25px" }} src={"/img/pdficon.png"} /> <br />
        {/*fileName*/}
      </a>
    );
  }
}


export default connect(
  (state: ApplicationState) => state.rfi,
  RFIStore.actionCreators
  // @ts-ignore
)(RFIHome);

const ReportModal = (props: { projectId: number, close: () => void }) => {

  const [form, setForm] = React.useState({ startDate: '', endDate: '', valid: false })

  React.useEffect(() => {
    if (form.startDate && form.endDate && new Date(form.startDate) <= new Date(form.endDate)) {
      if (!form.valid) setForm({ ...form, valid: true });
    } else {
      if (form.valid) setForm({ ...form, valid: false });
    }
  }, [form.startDate, form.endDate])

  return (
    <Modal>
      <div className="modal-header">
        <h4>RFI Reports</h4>
      </div>
      <div className="modal-body">
        <b>Compiled RFI Printouts (PDF)</b>
        <p>
          <small>Select a date range to pull RFIs for</small>
        </p>
        <div style={{ display: "flex" }}>
          <div
            className="form-group"
            style={{ width: "50%", paddingRight: "20px" }}
          >
            <label>Start Date*</label>
            <input type="date" className="form-control" value={form.startDate} onChange={(e) => setForm({ ...form, startDate: e.currentTarget.value })} />
          </div>
          <div className="form-group" style={{ width: "50%" }}>
            <label>End Date*</label>
            <input type="date" className="form-control" value={form.endDate} onChange={(e) => setForm({ ...form, endDate: e.currentTarget.value })} />
          </div>
        </div>
        {form.valid && <FileDownloader
          actionPath="api/report/rfirangepdf"
          queryParams={{ id: props.projectId, start: form.startDate, end: form.endDate }}
          fileType="PDF"
        />
        }        
        <hr style={{ borderColor: "gray" }} />
        <b>Logs (Excel)</b> &nbsp;
        <br />
        {/*<p><small>Saves to the project's 'Logs' folder</small></p>*/}
        <FileDownloader
          actionPath="api/rfi/log"
          queryParams={{ id: props.projectId }}
          fileType="Excel Log"
        />
      </div>
      <div className="modal-footer">
        <button
          style={{ marginLeft: "1%" }}
          className="btn btn-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
}
