import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ApplicationState } from '../store'
import { connect } from 'react-redux'
import * as Dfr from '../store/dfr'
import { MyAuthElement } from '../auth/Authorization'
import { KnownRoles } from '../auth/auth'
import { Clipboard } from '../components/clipboard';

const descPlaceholder = 'Topic Suggestions: \n - Tailgate meeting topic & attendees \n - Work day start / end times \n - Inspections completed / scheduled \n - Detailed work descriptions \n - Safety incidents, near misses etc.';
const internalDescPlaceholder = 'Topic Suggestions: \n - Quality / Scheduling issues \n - Unscheduled Delays \n - Potential changes in scope \n - Subcontractor management issues \n - Site-related material / tool needs'

interface State {
    //comment: string
}

interface Props { }

type ListProps = Props
    & Dfr.Store
    & typeof Dfr.actionCreators
    & RouteComponentProps<any>

class DfrDescription extends React.Component<ListProps, State> {
    csgComponent = MyAuthElement([KnownRoles.Admin, KnownRoles.CSGPM, KnownRoles.CoreSuperUser, KnownRoles.SiteSuper])

    constructor(props: any) {
        super(props);
    }

    _handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        const field = e.currentTarget.getAttribute('data-name') || '',
            value = e.currentTarget.checked;
        this.props.updateDFR(value, field)
    }

    _textareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const field = e.currentTarget.getAttribute('data-name') || '',
            value = e.currentTarget.value;
        this.props.updateDFR(value, field)
    }

    breakLineToNewLineConverter = (str: string) => str.replace(/<br\/>/g, "\n");

    render() {
        const tempCheckNeeded = new Date(this.props.date) <= new Date("2021-06-15");
        const tileStyle = { minHeight: '350px', width: 'calc(50% - 6px)' }
        const checkBoxStyle:React.CSSProperties = { margin: '5px', position: 'relative', top: '2px' }
        return (
            <div>
                {(new Date(this.props.date) >= new Date("2020-11-20") && new Date(this.props.date) < new Date("2024-05-18")) && // New checkboxes went into place on the 20th so we won't expect prior reports to have checks
                    <div className='tile' style={{ fontSize: '14px' }}>
                        <h5>COVID-19 Compliance Reporting</h5>
                        <b style={{ marginLeft: '5px' }}>Have you filled out your on-premises authorization form, if applicable? <a href='https://intranet.core-eng.com/covid-on-premise-authorization/'>Link to form</a>.</b>
                        <br />                        
                        {tempCheckNeeded && <input type='checkbox' data-name='temperaturesTaken' disabled={this.props.isSubmitted} onChange={this._handleCheckBox} style={checkBoxStyle} defaultChecked={this.props.temperaturesTaken} />}
                        {tempCheckNeeded && <b style={{ color: 'rgb(200, 50, 50)' }}>Have all on-site workers' and visitors' temperatures been taken and recorded below 100.4 F? If no, please explain in the comments below.</b>}
                        {tempCheckNeeded && <br />}
                        <input type='checkbox' data-name='covidPoliciesObserved' disabled={this.props.isSubmitted} onChange={this._handleCheckBox} style={checkBoxStyle} defaultChecked={this.props.covidPoliciesObserved} />
                        <b style={{ color: 'rgb(200, 50, 50)' }}>Were all CSCS Covid policies, including distancing, PPE and equipment sanitization, followed in accordance with company policy? If no, please explain in the comments below.</b>
                        <br /><br />
                        <b>Comments</b>
                        <textarea className='form-control mobile-full-width'
                            data-name='covidComments'
                            rows={2}
                            onBlur={this._textareaChange}
                            disabled={this.props.isSubmitted}
                            defaultValue={this.breakLineToNewLineConverter(this.props.covidComments) || ""}
                        />
                    </div>                    
                }
                <div className='tile' style={{ fontSize: '14px' }}>
                    <input type='checkbox' data-name='hasNoIncident' disabled={this.props.isSubmitted} onChange={this._handleCheckBox} style={checkBoxStyle} defaultChecked={this.props.hasNoIncident} />
                    <b style={{ color: 'rgb(200, 50, 50)' }}>There were no incidents, near misses, personal injuries or accidents on the job site.</b>
                    <br />
                </div>
                <div style={{ display: 'flex' }} className='mobile-no-flex'>
                    <div className='tile mobile-full-width' style={{ ...tileStyle, marginRight: '12px' }}>
                        <h5 style={{ position: 'relative' }}>
                            Formal Description
                            <Clipboard selector='textarea[data-name="formalDescription"]' style={{ position: 'absolute', top: '-3px', right: '0px', fontSize: '12px', zIndex: 5 }} />
                        </h5>
                        <div>
                            <textarea
                                className="form-control dfr_textarea mobile-full-width"
                                data-name='formalDescription'
                                onBlur={this._textareaChange}
                                placeholder={this.props.isSubmitted ? '' : descPlaceholder}
                                defaultValue={this.breakLineToNewLineConverter(this.props.formalDescription) || ""}
                                disabled={this.props.isLocked}
                                rows={16}
                            />
                        </div>
                    </div>
                    {/* 
                        //@ts-ignore */}
                    <this.csgComponent class='tile mobile-full-width' style={tileStyle}>
                        <h5 style={{ position: 'relative' }}>
                            General Description (internal use)
                            <Clipboard selector='textarea[data-name="comment"]' style={{ position: 'absolute', top: '-3px', right: '0px', fontSize: '12px', zIndex: 5 }} />
                        </h5>
                        <div>
                            <textarea
                                className="form-control dfr_textarea mobile-full-width"
                                data-name='comment'
                                onBlur={this._textareaChange}
                                placeholder={this.props.isSubmitted ? '' : internalDescPlaceholder}
                                defaultValue={this.breakLineToNewLineConverter(this.props.comment) || ""}
                                disabled={this.props.isLocked}
                                rows={16}
                            />
                        </div>
                    </this.csgComponent>
                </div>
                <div className='tile mobile-full-width'>
                    <h5>Weather</h5>
                    <hr />
                    <div id="weatherBody" className="row">
                        {this.props.weather != null ? this.renderWeatherInfo() : <div className="mobile-full-width"><h4>Note: weather will be pulled after report is submitted</h4></div>}
                    </div>
                </div>
            </div>
        )
    }

    private renderWeatherInfo = () => {
        //const styleInset = { 'margin': '1%' }
        //const styleOutset = { 'margin': '1%' }
        const w = this.props.weather;
        return (
            <div style={{ width: '100%' }}>
                <div className="row">
                    <div className="col-sm-2 form-group">
                        <label><b>Low</b></label>
                        <p>{w.low ? Math.round(w.low) : '-'} &#x2109; </p>
                    </div>
                    <div className="col-sm-2 form-group">
                        <label><b>High</b></label>
                        <p>{w.high ? Math.round(w.high) : '-'} &#x2109; </p>
                    </div>
                    {/*</div>
                <div className="row">*/}
                    <div className="col-sm-2 form-group">
                        <label><b>Precip %</b></label>
                        <p>{w.precipPct}%</p>
                    </div>
                    <div className="col-sm-2 form-group">
                        <label><b>Precip Amount</b></label>
                        <p>{w.precipAmt == null ? 0 : w.precipAmt} in.</p>
                    </div>
                    {/*</div>
                <div className='row'>*/}
                    <div className='col-sm-2 form-group'>
                        <label><b>Wind Speed</b></label>
                        <p>{w.windSpeed} mph</p>
                    </div>
                    <div className='col-sm-2 form-group'>
                        <label><b>Wind Direction</b></label>
                        <p>{w.windDirection}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 form-group" style={{ paddingLeft: '10px' }}>
                        <label><b>Weather Summary</b></label>
                        <p>{w.summary}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect<Dfr.Store, typeof Dfr.actionCreators, Props>(
    //@ts-ignore
    (state: ApplicationState) => state.dfr,
    Dfr.actionCreators
)(DfrDescription)