import React from 'react';

interface NotificationAlertProps {
  isUpload: boolean;
  onClose: () => void;
}

export const NotificationAlert: React.FC<NotificationAlertProps> = ({
  isUpload,
  onClose,
}) => {
  return (
    <div
      style={{
        left: '70%',
        display: 'flex',
        height: '40px',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'fixed',
        zIndex: 9999,
      }}
      className={'notification-stream tray'}
    >
      <b>
        {isUpload ? 'New Upload Processing: ' : 'New Document Downloading: '}
      </b>
      <div
        style={{
          height: '15px',
          width: '200px',
        }}
        className="progress"
      >
        <div
          className={
            'progress-bar progress-bar-striped progress-bar-animated bg-success'
          }
          role="progressbar"
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: '100%' }}
        ></div>
      </div>
      <span
        className="fas fa-times"
        title="Dismiss this notification"
        onClick={onClose}
      ></span>
    </div>
  );
};
