import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NCR, NCRPostModel } from 'src/interfaces/interfaces';

export interface NCRState {
  status: 'idle' | 'loading' | 'saving' | 'completed';
}

export const initialState: NCRState = {
  status: 'idle',
};

const { actions: NCRActions, reducer: NCRReducer } = createSlice({
  name: 'ncrs',
  initialState,
  reducers: {
    sendReport(
      _state,
      _action: PayloadAction<{ issueId: number; report: NCRPostModel }>
    ) {},
    setStatus(
      state,
      action: PayloadAction<'idle' | 'loading' | 'saving' | 'completed'>
    ) {
      state.status = action.payload;
    },
  },
});

export { NCRActions, NCRReducer };

export default NCRReducer;
