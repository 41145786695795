import { Action, Reducer, ActionCreator } from "redux";
import { AppThunkAction } from "./index";
import { ImportConfig, ImportSource } from "../interfaces/interfaces";

export interface ImportStore {
	clientId: number;
	importConfigs: ImportConfig[];
	loading: boolean;
	message?: string;
}

interface RequestImportConfigsAction {
	type: "REQUEST_IMPORT_CONFIGS";
	clientId: number;
}

interface ReceiveImportConfigsAction {
	type: "RECEIVE_IMPORT_CONFIGS";
	configs: ImportConfig[];
}

interface SavingImportsAction {
	type: "SAVE_IMPORTS";
}

interface ImportErrorAction {
	type: "IMPORT_ERROR";
	message: string;
}

interface ImportDoneAction {
	type: "IMPORT_DONE";
	message: string;
}

interface ClearImportMessageAction {
	type: "CLEAR_IMPORT_MESSAGE";
}

export type KnownAction =
	| RequestImportConfigsAction
	| ReceiveImportConfigsAction
	| SavingImportsAction
	| ImportDoneAction
	| ImportErrorAction
	| ClearImportMessageAction;

export const actionCreators = {
	getConfigs:
		(clientId: number): AppThunkAction<KnownAction> =>
		(dispatch) => {
			fetch(`api/Import/GetConfigs?clientId=${clientId}`)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk)
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: data });
					else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "REQUEST_IMPORT_CONFIGS", clientId: clientId });
		},
	addConfig:
		(source: ImportSource): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(
				`api/Import/NewConfig?clientId=${
					getState().imports.clientId
				}&source=${source}`,
				{ method: "POST" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const configs = JSON.parse(
							JSON.stringify(getState().imports.importConfigs)
						).concat(data);
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	updateConfig:
		(id: number, field: string, value: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Import/UpdateConfig?id=${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ fieldName: field, value: value }),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const configs = JSON.parse(
							JSON.stringify(getState().imports.importConfigs)
						).map((x) => {
							if (x.id === id) x = data;
							return x;
						});
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	updateProjectMap:
		(id: number, field: string, value: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Import/ProjectMap?id=${id}&field=${field}&value=${value}`, {
				method: "PUT",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const configs = JSON.parse(
							JSON.stringify(getState().imports.importConfigs)
						).map((x: ImportConfig) => {
							if (x.projectMap.id === id) x.projectMap = data;
							return x;
						});
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	updateMilestoneMap:
		(id: number, field: string, value: string): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Import/MilestoneMap?id=${id}&field=${field}&value=${value}`, {
				method: "PUT",
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const configs = JSON.parse(
							JSON.stringify(getState().imports.importConfigs)
						).map((c: ImportConfig) => {
							c.milestoneMaps = c.milestoneMaps.map((m) => {
								if (m.id === id) m = data;
								return m;
							});
							return c;
						});
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	deleteConfig:
		(id: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Import/DeleteConfig?id=${id}`, { method: "DELETE" })
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						const configs = JSON.parse(
							JSON.stringify(getState().imports.importConfigs)
						).filter((x: ImportConfig) => x.id !== id);
						dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	runImport:
		(id: number, userIds: string[]): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			fetch(`api/Import/Run?id=${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(userIds),
			})
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						dispatch({ type: "IMPORT_DONE", message: "Import complete" });
					} else dispatch({ type: "IMPORT_ERROR", message: data.message });
				});
			dispatch({ type: "SAVE_IMPORTS" });
		},
	toggleExpandMaps:
		(id: number): AppThunkAction<KnownAction> =>
		(dispatch, getState) => {
			const configs = JSON.parse(
				JSON.stringify(getState().imports.importConfigs)
			).map((c: ImportConfig) => {
				if (c.id === id) c.expandProjectMap = !c.expandProjectMap;
				return c;
			});
			dispatch({ type: "RECEIVE_IMPORT_CONFIGS", configs: configs });
		},
	clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: "CLEAR_IMPORT_MESSAGE" });
	},
};

const unloadedState = {
	clientId: 0,
	importConfigs: [],
};

//@ts-ignore
export const reducer: Reducer<ImportStore> = (
	state: ImportStore,
	incomingAction: Action
) => {
	const action = incomingAction as KnownAction;
	switch (action.type) {
		case "SAVE_IMPORTS":
			return { ...state, message: undefined, loading: true };
		case "REQUEST_IMPORT_CONFIGS":
			return { ...state, clientId: action.clientId, loading: true };
		case "RECEIVE_IMPORT_CONFIGS":
			return { ...state, importConfigs: action.configs, loading: false };
		case "IMPORT_DONE":
			return { ...state, message: action.message, loading: false };
		case "IMPORT_ERROR":
			return { ...state, message: action.message, loading: false };
		case "CLEAR_IMPORT_MESSAGE":
			return { ...state, message: undefined, loading: false };
		default: {
			const exhaustiveCheck: never = action;
		}
	}

	return state || unloadedState;
};
