import React, { useEffect, useMemo, useState } from 'react';
import FilterableSelect from 'src/components/FilterableSelect';
import { JoinedList } from 'src/components/JoinedList';
import { Modal2 } from 'src/components/Modal';
import {
  IssueContributingFactor,
  IssuePostModel,
  IssueRootCause,
  IssueType,
  ListItem,
  ProjectTeamMember,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';
import { JoinedListDiv } from './IssueNCRModal';

const ModalBodyDiv = styled.div({
  maxHeight: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '& .form-group:not(.joined-list)': {
    display: 'flex',
    width: '100%',
    lineHeight: 1,
  },
  '& .inline-label': { width: '150px', lineHeight: 2 },
  '& input, & select, & textarea': {
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select': {
    width: '100%',
    maxWidth: 'calc(100% - 150px)',
  },
  '& .filterable-select input': {
    maxWidth: '100%',
  },
  '& .joined-list': {
    height: '100%',
  },
});

const StyledH4 = styled.h4({
  marginBottom: '3px',
});

interface IssueModalProps {
  onIssueCreate: (issue: IssuePostModel) => void;
  onClose: () => void;
  projectId: number;
  issueTypes: IssueType[];
  rootCauses: IssueRootCause[];
  contributingFactors: IssueContributingFactor[];
  teamMembers: ProjectTeamMember[];
}

export const IssueAddModal = ({
  onClose,
  onIssueCreate,
  projectId,
  issueTypes,
  rootCauses,
  contributingFactors,
  teamMembers,
}: IssueModalProps) => {
  const [localIssue, setLocalIssue] = useState<IssuePostModel>({
    title: '',
    typeId: 0,
    contributingFactorId: null,
    description: '',
    ccedUsers: [],
    responsibleParty: '',
    rootCauseId: null,
    timelineImpact: 0,
    projectId: projectId,
    userId: '',
    sendToAssignee: false,
    fileList: null,
  });

  const canSubmit = useMemo(() => {
    if (
      localIssue.title == '' ||
      localIssue.description == '' ||
      localIssue.typeId <= 0 ||
      localIssue.userId == ''
    ) {
      return false;
    }
    return true;
  }, [localIssue]);

  const availableCFs = useMemo(() => {
    return contributingFactors.filter(
      (x) => x.issueTypeId == localIssue.typeId
    );
  }, [localIssue.typeId]);

  const availableRootCauses = useMemo(() => {
    return rootCauses.filter((x) => x.issueTypeId == localIssue.typeId);
  }, [localIssue.typeId]);

  const availableTeamMembers = useMemo(() => {
    return teamMembers
      .filter((x) => !localIssue.ccedUsers.includes(x.userId))
      .map((y) => new ListItem(y.userId, y.userFullName));
  }, [localIssue.ccedUsers]);

  const selectedTeamMembers = useMemo(() => {
    return localIssue.ccedUsers.map(
      (x) =>
        new ListItem(
          x,
          teamMembers.find((y) => y.userId == x)?.userFullName ?? ''
        )
    );
  }, [localIssue.ccedUsers]);

  const setFiles = (e: React.ChangeEvent<HTMLInputElement>) => {};

  useEffect(() => {
    setLocalIssue((prevState) => ({
      ...prevState,
      contributingFactorId: null,
      rootCauseId: null,
    }));
  }, [localIssue.typeId]);

  return (
    <Modal2 dialogStyle={{ width: '800px' }}>
      <div className="modal-header">
        <StyledH4>Create New Issue</StyledH4>
      </div>
      <ModalBodyDiv className="modal-body custom-scrollbar">
        <div className="form-group">
          <div className="inline-label">Title*</div>
          <input
            required
            className="form-control"
            onBlur={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                title: value,
              }));
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Type*</div>
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                typeId: +value,
              }));
            }}
          >
            <option className="form-control" value={0}></option>
            {issueTypes.map((x) => {
              return (
                <option className="form-control" value={x.id}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <div className="inline-label">Contributing Factor</div>
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                contributingFactorId: value ? +value : null,
              }));
            }}
          >
            <option className="form-control" value={undefined}></option>
            {availableCFs.map((x) => {
              return (
                <option className="form-control" value={x.id}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <div className="inline-label">Description*</div>
          <textarea
            required
            className="form-control"
            onBlur={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                description: value,
              }));
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Assigned*</div>
          <FilterableSelect
            required
            onChange={(x) => {
              setLocalIssue((prevState) => ({
                ...prevState,
                userId: x,
              }));
            }}
            id={'assignee'}
            items={teamMembers.map(
              (x) => new ListItem(x.userId, x.userFullName)
            )}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Responsible Party</div>
          <input
            required
            className="form-control"
            onBlur={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                responsibleParty: value,
              }));
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Root Cause</div>
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                rootCauseId: value ? +value : null,
              }));
            }}
          >
            <option className="form-control" value={undefined}></option>
            {availableRootCauses.map((x) => {
              return (
                <option className="form-control" value={x.id}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <div className="inline-label">Timeline Impact (Days)</div>
          <input
            required
            type={'number'}
            className="form-control"
            onBlur={(e) => {
              const value = e.currentTarget.value;
              setLocalIssue((prevState) => ({
                ...prevState,
                timelineImpact: +value,
              }));
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Attachments</div>
          <div style={{ width: 'calc(100% - 150px)', paddingTop: '6px' }}>
            <input
              type="file"
              multiple
              onChange={(e) => {
                const files = e.currentTarget.files;
                setLocalIssue((prevState) => ({
                  ...prevState,
                  fileList: files,
                }));
              }}
            />
          </div>
        </div>
        <JoinedListDiv className="form-group">
          <div className="inline-label">CCed Users</div>
          <JoinedList
            title=""
            availableItems={availableTeamMembers}
            selectedItems={selectedTeamMembers}
            onChange={(items) => {
              setLocalIssue((prevState) => ({
                ...prevState,
                ccedUsers: items.map((x) => x.id.toString()),
              }));
            }}
          />
        </JoinedListDiv>
        <div>
          <div className="form-label">
            <b>Send on Save</b>
          </div>
          <div>
            <small style={{ fontSize: '12px' }}>
              If you check this, the issue will be sent to the assignee when you
              save. Otherwise, it will be saved as a draft and you will be able
              to send it later.
            </small>
          </div>
          <label className="switch">
            <input
              onClick={() =>
                setLocalIssue((prevState) => ({
                  ...prevState,
                  sendToAssignee: !prevState.sendToAssignee,
                }))
              }
              checked={localIssue.sendToAssignee}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
      </ModalBodyDiv>
      <div className="modal-footer">
        <button
          disabled={!canSubmit}
          className="btn btn-sm btn-blue"
          onClick={() => onIssueCreate(localIssue)}
        >
          Create
        </button>
        <button className="btn btn-sm btn-outline-secondary" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal2>
  );
};
