import * as React from 'react'
import { RFIResponseComment } from '../interfaces/interfaces'
import { formatDate } from '../helpers/formatters';

interface Props {
    responseId?: number,
    open: boolean
    locked?: boolean
    comments: RFIResponseComment[]
    addComment: (c: string, id?: number) => void
}

interface State { }

export default class RFICommentStream extends React.Component<Props, State> {

    _addComment = (e: React.MouseEvent<HTMLButtonElement>) => {
        const parent = e.currentTarget.parentElement as HTMLDivElement;
        const textArea = parent.getElementsByTagName('textarea')[0] as HTMLTextAreaElement
        if (textArea) {
            this.props.addComment(textArea.value, this.props.responseId)
            textArea.value = '';
        }
    }

    render() {
        const { comments, open } = this.props
        const commentEles = (comments && comments.length) ? comments.map(x => {
            return (
                <div key={x.id} className='rfi-response-comment'>
                    <div className='response-name-timestamp'>
                        <span>{x.userFullName} - {formatDate(x.timestamp)}</span>
                    </div>
                    <div className='rfi-response-body'>
                        <span>{x.comment}</span>
                    </div>
                </div>
            )
        }) : [] // open ? [] : <div style={{ marginLeft: '50%' }}>No comments to display</div>
        return (
            <div className='response-comments-list'>
                {commentEles}
                {open ?
                    <div className='new-comment'>
                        <textarea className='form-control' id='new-response-comment'></textarea>
                        <button className='btn btn-sm btn-blue fas fa-paper-plane' onClick={this._addComment} title='Post comment'></button>
                    </div>
                    : []
                }
            </div>
        )
    }
}